<template>
  <div class="reports list">
    <div
      v-if="!reports.length && !hasBlockingRequests"
      class="alert alert-warning text-center"
    >
      <h2 class="mt-1 mb-2">No reports found</h2>
      <p class="mb-1">for the selected month or search</p>
    </div>

    <table v-else class="table table-hover">
      <thead>
        <tr>
          <th class="date">Date</th>
          <th class="ref">Ref</th>
          <th class="type">Type</th>
          <th class="address">Address</th>
          <th class="exported" v-if="!isDataentry">Exported</th>
          <th class="report">Report</th>
          <th class="invoice" v-if="!isDataentry">Invoice</th>
          <th
            v-if="isAdministrator || isCustomer || isDataentry"
            class="actions"
          >
            <span class="sr-only">Actions</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(report, $index) in reports" :key="$index">
          <td>{{ actProperty.formatDateForDisplay(report.date) }}</td>
          <td>{{ report.ref }}</td>
          <td>{{ actProperty.formatReportType(report.type, dictionary) }}</td>
          <td v-html="actProperty.formatAddress(report.address)"></td>
          <td v-if="!isDataentry">
            <span
              v-if="report.exportedAt"
              v-html="actProperty.formatDatetimeForDisplay(report.exportedAt)"
            ></span>
          </td>
          <td>
            <div
              class="btn-group"
              role="group"
              aria-label="Download Report PDFs"
            >
              <!-- New generate report buttons to get around the pdf timeout issues -->
              <ProgressButton
                @click="actProperty.copyDownloadButton(report, 'report')"
                @dblclick="generateReportPdf(report, $event)"
                class="btn btn-outline-primary"
                :title="actProperty.formatReportType(report.type, dictionary)"
              >
                <i class="far fa-file-pdf"></i>
              </ProgressButton>

              <ProgressButton
                v-if="report.type == 'checkout'"
                @click="actProperty.copyDownloadButton(report, 'summary')"
                @dblclick="generateReportSummaryPdf(report, $event)"
                :disabled="!isCOSMActive(report)"
                class="btn btn-outline-primary"
                title="Check-Out Summary"
              >
                <i class="far fa-file-pdf"></i>
              </ProgressButton>
            </div>
          </td>
          <td v-if="!isDataentry">
            <ProgressButton
              v-if="report.totalFees()"
              @click="actProperty.copyDownloadButton(report, 'invoice')"
              @dblclick="downloadInvoice(report, $event)"
              class="btn btn-outline-primary"
              title="Invoice"
            >
              <i class="far fa-file-pdf"></i>
              {{ actProperty.formatCurrency(`${report.totalFees()}`) }}
            </ProgressButton>
          </td>
          <td v-if="isAdministrator || isCustomer" class="text-left">
            <div class="btn-group" role="group" aria-label="Actions">
              <button
                v-if="isReportEditable(report)"
                class="btn"
                :class="{
                  'btn-outline-danger': !report.locked,
                  'btn-outline-success': report.locked,
                }"
                @click="toggleLock(report)"
              >
                <i v-if="report.locked" class="fas fa-lock"></i>
                <i v-else class="fas fa-unlock"></i>
              </button>
              <button
                v-if="!isCustomer"
                class="btn btn-outline-primary"
                data-toggle="modal"
                data-target="#report-merge-modal"
                title="Merge"
                @click="setMergeSource(report)"
              >
                <i class="far fa-clone"></i>
              </button>
              <button
                class="btn btn-outline-primary"
                @click="showDepositoryPropertyListModal(report)"
                title="Depositary"
                :disabled="!report.customer.depository.authToken || !report.attachments.report"
                :key="report.attachments.report"
                :class="{
                  'btn-outline-success':
                    report.depositorydeductionssubmitdate &&
                    report.attachments.reportuploaddate,
                  'amber-color':
                    ((!report.depositorydeductionssubmitdate &&
                    report.attachments.reportuploaddate) 
                    || (report.depositorydeductionssubmitdate &&
                    !report.attachments.reportuploaddate)),
                  'grey-color' : !report.customer.depository.authToken || !report.attachments.report,
                }"
              >
                <strong>D</strong>
              </button>
              <button
                v-if="isReportEditable(report)"
                class="btn btn-outline-primary"
                @click="gotoReport(report)"
                title="View"
              >
                <i class="fas fa-eye"></i>
              </button>
            </div>
          </td>

          <td v-if="isDataentry" class="text-right">
            <div class="btn-group" role="group" aria-label="Actions">
              <button
                class="btn btn-outline-primary"
                @click="gotoReport(report)"
                title="View"
              >
                <i class="fas fa-eye"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <ReportPagination
      v-if="isDataentry"
      :total="reports.length"
      :current="reportsState.currentPage"
      :limit="reportsState.pageLimit"
      @pageReports="doReportsPaging"
    />

    <ReportPagination
      v-else
      :total="reportsState.monthlyReportCount"
      :current="reportsState.currentPage"
      :limit="reportsState.pageLimit"
      @pageReports="doReportsPaging"
    />

    <ul
      v-if="isAdministrator && reportsState.monthlyFeesTotal"
      class="fee-totals list-inline"
    >
      <li class="list-inline-item">Monthly Total</li>
      <li class="list-inline-item">
        <span>Reports:</span>
        {{ reportsState.monthlyReportCount!.toLocaleString() }}
      </li>
      <li class="list-inline-item">
        <span>Fees:</span>
        {{ actProperty.formatCurrency(`${reportsState.monthlyFeesTotal}`) }}
      </li>
    </ul>

    <button
      v-if="(isAdministrator || isCustomer) && reports.length"
      @click="downloadCSV()"
      class="btn btn-outline-secondary"
      :disabled="downloadingCsv"
    >
      <i class="fas fa-file"></i> Download CSV
      <span
        v-if="downloadingCsv"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </button>

    <button
      v-if="isAdministrator && reports.length"
      @click="downloadFeedbackCSV()"
      class="btn btn-outline-secondary"
      :disabled="downloadingFeedbackCsv"
    >
      <i class="fas fa-file"></i> Download Feedback CSV
      <span
        v-if="downloadingFeedbackCsv"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </button>

    <!-- Modals -->
    <ReportMergeModal ref="mergeReportModal" />
    <DepositoryPropertyListModal
      ref="depositoryPropertyListModal"
      @depositorysubmitsuccess="onDepositorysubmitsuccess"
    />
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import 'bootstrap';
import { ref, computed, onMounted, inject, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { DepositoryTenancy, Dictionary, DictionaryReportType, Report, ReportTypeDictionary, Room, Section, Type } from '@/models';
import { ReportsState } from '@/store/reports/types';
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';
import sanitize from 'sanitize-filename';
import { checkitem } from '@/utilities';
import { useToast } from "vue-toastification";
import ProgressButton from "@/components/ProgressButton.vue";
import ReportPagination from "@/components/report/ReportPagination.vue";
import ReportMergeModal from "@/components/modals/ReportMergeModal.vue";
import DepositoryPropertyListModal from "@/components/modals/DepositoryPropertylistModal.vue";

const store = useStore();
const router = useRouter();
const route = useRoute();
const toasted = useToast();

const actProperty: any = inject('actProperty');

const reportsState = computed(() => store.state.reports as ReportsState);

const dictionary = computed((): Dictionary => store.getters['dictionary/current']);
const hasBlockingRequests = computed(() => store.getters['app/hasBlockingRequests']);
const isAdministrator = computed(() => store.getters['auth/isAdministrator']);
const companyName = computed(() => store.getters['auth/companyName']);
const isCustomer = computed(() => store.getters['auth/isCustomer']);
const isDataentry = computed(() => store.getters['auth/isDataentry']);
const reports = computed((): Report[] => store.getters['reports/list']);
const statereport = computed(() => store.getters['reports/current']);
const monthlyFeesTotal = computed(() => store.getters['reports/monthlyFeesTotal']);

const downloadInvoiceAction = (report: Report): Promise<any> => {
  return store.dispatch('reports/downloadInvoice', report);
};

const generateReportPdfAction = (report: Report): Promise<any> => {
  return store.dispatch('reports/generateReportPdf', report);
};

const generateReportSummaryPdfAction = (report: Report): Promise<any> => {
  return store.dispatch('reports/generateReportSummaryPdf', report);
};

const downloadCSVAction = (month: string | null): Promise<any> => {
  return store.dispatch('reports/downloadCSV', month);
};

const downloadFeedbackCSVAction = (month: string | null): Promise<any> => {
  return store.dispatch('reports/downloadFeedbackCSV', month);
};

const setReportDeep = (payload: { path: string; data: any }): Promise<any> => {
  return store.dispatch('reports/setReportDeep', payload);
}

const toggleReportLock = (report: Report): Promise<Report> => {
  return store.dispatch('reports/toggleReportLock', report);
};

const setCurrentPage = (page: number): Promise<any> => {
  return store.dispatch('reports/setCurrentPage', page);
};

const removeRequest = (id: string): void => {
  store.commit('app/removeRequest', id);
};

const setMergeSource = (report: Report): void => {
  store.commit('reports/setMergeSource', report);
};

const setReports = (reports: Report[]): void => {
  store.commit('reports/setReports', reports);
};

const downloadingCsv = ref(false);
const downloadingFeedbackCsv = ref(false);

// Methods
const isReportEditable = (report: Report) => {
  if (isAdministrator.value) return true;
    if (report && actProperty.isFactoryreport(report.type)) {
      if (
        report.companyName === companyName.value ||
        report.companyName.startsWith(companyName.value)
      ) {
        return true;
      }
    }
    return false;
};

const isCOSMActive = (report: Report) => {
  let result = true;
  if (report && report.customer && report.customer.reportsettings) {
    result =
      report &&
      report.customer &&
      report.customer.reportsettings.cosummaryactive;
  }
  return result;
}

const gotoReport = (report: Report) => {
  if (isDataentry.value)
      router.push({ name: "dereport", params: { id: report.id } });
    else router.push({ name: "report", params: { id: report.id } });
};

const doReportsPaging = (page: number): void => {
  setCurrentPage(page);
  const queryParams = { ...route.query };
  queryParams.page = page + ""; // hack to coerce to string
  if (isDataentry.value)
    router.push({ path: "dereports", query: queryParams });
  else router.push({ path: "reports", query: queryParams });
}

const downloadInvoice = (report: Report, requestId: string): void => {
  downloadInvoiceAction(report)
    .then((key: string) => {
      actProperty.downloadAttachment(key);
      toasted.success(`Invoice ${report.ref} PDF downloaded`);
    })
    .finally(() => removeRequest(requestId))
    .catch((err: any) => actProperty.displayError(err));
}

const generateReportPdf = (report: Report, requestId: string): void => {
  generateReportPdfAction(report)
    .then((key: string) => {
      actProperty.downloadAttachment(key);
      toasted.success(`Report ${report.ref} PDF downloaded`);
      nextTick(() => {
        setReports([...reports.value]);
      });
    })
    .finally(() => removeRequest(requestId))
    .catch((err: any) => actProperty.displayError(err));
}

const generateReportSummaryPdf = (report: Report, requestId: string): void => {
  generateReportSummaryPdfAction(report)
    .then((key: string) => {
      actProperty.downloadAttachment(key);
      toasted.success(`Report ${report.ref} Summary PDF downloaded`);
    })
    .finally(() => removeRequest(requestId))
    .catch((err: any) => actProperty.displayError(err));
}

const toggleLock = (report: Report) => {
  toggleReportLock(report)
    .then((result: Report) => {
      // Update the current list of reports
      const reps = reports.value.map((x: Report) =>
        x.ref === result.ref ? result : x
      );
      setReports(reps);
      toasted.success(
        `${report.ref} ${result.locked ? "Locked" : "Unlocked"}`
      );
    })
    .catch((err: any) => actProperty.displayError(err));
  }

const downloadCSV = async () => {
  try {
      let month = route.query.month ? route.query.month : null;

      if (!month) {
        month = moment().format("YYYY-MM");
      }

      if (!month || typeof month !== "string") {
        toasted.error("CSV download failed. Please try again.");
      } else {
        downloadingCsv.value = true;
        downloadCSVAction(month)
          .then((results: any) => {
            let filename = "reports";
            if (route.query.search) {
              filename = sanitize(route.query.search as string);
            } else if (route.query.month) {
              filename = sanitize(route.query.month as string);
            }

            const csvExporter = new ExportToCsv({
              filename,
              useKeysAsHeaders: true,
            });

            results.forEach((r: any) => {
              if (dictionary.value && dictionary.value.reporttypes) {
                const reporttype = dictionary.value.reporttypes.find(
                  (t: DictionaryReportType) => t.slug === r.Type
                );
                if (reporttype) {
                  r.Type = reporttype.displayname;
                }
              }
            });

            if (isCustomer.value) {
              results
                .filter((r: any) => r.Type === "property visit")
                .forEach((r: any) => {
                  delete r.Company;
                  delete r.Branch;
                  delete r.Region;
                  delete r.Template;
                  delete r["Works/Purchase order Number"];
                  delete r["Fees + VAT"];
                  delete r["AC/Client Code"];
                  delete r["Nominal Code"];
                  delete r["Email"];
                });
            }
            csvExporter.generateCsv(results);

            toasted.success(
              "CSV downloaded. Please check your downloads folder."
            );
          })
          .finally(() => {
            downloadingCsv.value = false;
          })
          .catch((err: any) => actProperty.displayError(err));
      }
  } catch (err: any) {
    console.error(err);
    toasted.error("CSV download failed. Please try again.");
  }
};

const downloadFeedbackCSV = () => {
  try {
    let month = route.query.month ? route.query.month : null;

    if (!month) {
      month = moment().format("YYYY-MM");
    }

    if (!month || typeof month !== "string") {
      toasted.error("Feedback CSV download failed. Please try again.");
    } else {
      downloadingFeedbackCsv.value = true;
      downloadFeedbackCSVAction(month)
        .then((results: any) => {
          if (results && results.length) {
            let filename = "feedbackreports";
            if (route.query.search) {
              filename = sanitize(route.query.search as string);
            } else if (route.query.month) {
              filename = sanitize(route.query.month as string);
            }

            const csvExporter = new ExportToCsv({
              filename,
              useKeysAsHeaders: true,
            });

            csvExporter.generateCsv(results);

            toasted.success(
              "Feedback CSV downloaded. Please check your downloads folder."
            );
          } else {
            toasted.error("There are no reports in feedback list");
          }
        })
        .finally(() => {
          downloadingFeedbackCsv.value = false;
        })
        .catch((err: any) => actProperty.displayError(err));
    }
  } catch (err: any) {
    console.error(err);
    toasted.error("CSV download failed. Please try again.");
  }
}

const depositoryPropertyListModal = ref(null);
const showDepositoryPropertyListModal = (report: Report) => {
  const modalComponent = depositoryPropertyListModal.value;
  if (modalComponent && modalComponent.modalElement) {
    modalComponent.init(report)
    $(modalComponent.modalElement).show();
  }
};

const unlinkTenancy = () => {
  setReportDeep({
    path: "depositorytenancy",
    data: new DepositoryTenancy(),
  });
};

const roomswithdeductions = computed(() => {
  return statereport.value?.rooms?.filter((room: Room) => checkroom(room)) || [];
});

const checkroom = (room: Room) => {
  const result = room.sections.filter((section) => checksection(room, section));
  return result.length > 0;
};

const checksection = (room: Room, section: Section) => {
  const result = section.types.filter((type) => checktype(room, section, type));
  return result.length > 0;
};

const checktype = (room: Room, section: Section, type: Type) => {
  const result = type.items.filter((item) => checkitem(room, section, type, item));
  return result.length > 0;
};

const isCategoryFourRoom = (room: Room) => {
  const currentroomtype = room.type;
  const found = dictionary.value.rooms.findIndex(
    (dr) => dr.category === 4 && dr.slug === currentroomtype
  );
  return found >= 0;
};

const getDButtonVisible = (report: Report) => {
  return report.customer?.depository?.authToken && report.attachments?.report;
};

const onDepositorysubmitsuccess = (id: string) => {
  reports.value.forEach((report) => {
    if(report.id === id) {
      const datestamp = moment().utc().format("YYYY-MM-DD[T]HH:mm:ss.SSSZ");
      report.depositorydeductionssubmitdate = datestamp;
      report.attachments.reportuploaddate = datestamp;
    }
  });
};

onMounted(() => {
  // Equivalent to mounted() lifecycle hook in Vue 2
});
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
tr {
  cursor: pointer;
}
.btn.disabled {
  cursor: not-allowed;
}

th {
  &.date {
    width: 7rem;
  }
  &.ref {
    width: 6rem;
  }
  &.type {
    width: 8rem;
  }
  &.exported {
    width: 9rem;
  }
  &.report {
    width: 7rem;
  }
  &.invoice {
    width: 9rem;
  }
  &.actions {
    width: 10rem;
  }
}

.fee-totals {
  font-weight: 500;
  span {
    font-weight: normal;
  }
}

.green-color {
  color: #28a745;
}

.amber-color {
  color: orange;
}

.grey-color {
  color: rgb(136, 136, 136);
}
</style>
