<template>
  <div class="card bg-light booking-section">
    <h3 class="card-header booking-section-header">Others</h3>
    <div class="card-body">
      <div class="row">
        <div class="col-md-3">
          <booking-notes :field="'pinotes'"
            :documents="booking.pinotesdocuments" 
            :documentsfield="'pinotesdocuments'"
            @showdocument="showpinotesDocument" />
        </div>
        <div class="col-md-3">
          <booking-notes :field="'internalnotes'" 
            :documents="booking.internalnotesdocuments" 
            :documentsfield="'internalnotesdocuments'"
            @showdocument="showInternalnotesDocument" />
        </div>
        <div class="col-md-3">
          <booking-notes :field="'titlenotes'" :fixedsize="true"/>
        </div>
        <div class="col-md-3">
          <booking-notes :field="'longtermnotes'" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" v-if="booking.cancelled">
          <booking-notes :field="'cancelnotes'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineEmits } from 'vue';
import { useStore } from 'vuex';
import BookingNotes from "@/components/diary/BookingNotes.vue";
import { Booking } from "@/models";

const store = useStore();
const booking = computed((): Booking => store.getters['diary/booking']);
const emit = defineEmits(['showinternalnotesdocument', 'showpinotesDocument'])
const showInternalnotesDocument = (index:number) => {
  emit("showinternalnotesdocument",index);
}
const showpinotesDocument = (index:number) => {
  emit("showpinotesDocument",index);
}
</script>


<style scoped lang="scss">
</style>