import _castArray from 'lodash/castArray';
import _get from 'lodash/get';

import { Base, Section } from '@/models';
import { toCamelCase } from '@/utilities';

export class Room extends Base {

  public name: string;
  public type: string; // from DictionaryRoom.slug
  public sections: Section[];

  /**
   * Constructor initialises the variables from the class Room
   * @param data - the data to construct a type with all properties of <Room>
   */
  public constructor(data?: Partial<Room>) {
    super(data);
    data = toCamelCase(data);

    this.name = _get(data, 'name', '');
    this.type = _get(data, 'type', ''); // from DictionaryRoom.slug
    this.sections = _castArray(_get(data, 'sections', [])).map((x: Partial<Section>) => new Section(x));
  }

}
