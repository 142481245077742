<template>
  <div class="auth complete-new-password">

    <div class="form-group row">
      <label class="col-sm-3 col-form-label">New Password</label>
      <div class="col-sm-6">
        <input type="password" class="form-control" v-model="password" />
        <small id="password-help" class="form-text text-muted">
          Min. 8 chars with at least 1 uppercase letter, lowercase letter and digit.
        </small>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-3 col-form-label">Confirm Password</label>
      <div class="col-sm-6">
        <input type="password" class="form-control" v-model="passwordConfirm" />
        <small id="password-confirm-help" class="form-text text-muted" v-if="passwordConfirm.length">
          <span v-if="password === passwordConfirm" class="text-success">Passwords match</span>
          <span v-else class="text-danger">Passwords DO NOT match</span>
        </small>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-6 offset-sm-3">
        <button class="btn btn-primary" @click="updatePassword()" :disabled="(password.length < 8 || password !== passwordConfirm)">
          Update my password
        </button>
      </div>
    </div>

  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Auth from '@aws-amplify/auth';
import { useToast } from "vue-toastification";

const store = useStore();
const router = useRouter();
const toasted = useToast();

const cognitoUser = computed(() => store.getters['auth/cognitoUser']);
const password = ref('');
const passwordConfirm = ref('');

const updatePassword = async () => {
  try {
    await Auth.completeNewPassword(cognitoUser.value, password.value, {});
    router.push({ name: 'reports' });
  } catch (err: any) {
    toasted.error(err.message);
  }
};

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
