<template>
    <div class="undo-toast">
        Booking deleted.Click to undo
        <!-- <button type="button" class="btn btn-outline-secondary mr-auto" data-dismiss="modal"
            style="color: white;background-color: #6c757d;    margin-left: 5px;">
            click the undo
        </button> -->
    </div>
</template>  
<script setup lang="ts">
</script>