import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { Base, TextValueObject } from '@/models';
import { toCamelCase } from '@/utilities';

export class DistanceMatrixElement extends Base {

  public status: string;
  public distance: TextValueObject;
  public duration: TextValueObject;
  public durationInTraffic: TextValueObject;

  public constructor(data?: Partial<DistanceMatrixElement>) {
    super(data);
    data = toCamelCase(data);

    this.status = _get(data, 'status', '');
    this.distance = new TextValueObject(_get(data, 'distance'));
    this.duration = new TextValueObject(_get(data, 'duration'));
    this.durationInTraffic = new TextValueObject(_get(data, 'durationInTraffic'));
  }

  get durationseconds(): number {
    if (this.durationInTraffic?.value > 0)
      return this.durationInTraffic?.value;
    else
      return this.duration?.value;
  }

  public static getSample() {
    const element = new DistanceMatrixElement();
    element.status = "OK";
    element.distance = TextValueObject.getSample(100);
    element.duration = TextValueObject.getSample(60);
    element.durationInTraffic = TextValueObject.getSample(10);
    return element;
  }
}