import { snakeCase } from 'lodash';
import _get from 'lodash/get';
import _castArray from 'lodash/castArray';
import { copyInto } from '@/utilities';
import { Base } from '../_base.class';

export class QCRule extends Base{

    public email: string;
    public includeinvoice: boolean;
    public includesummary: boolean;
    public ccbookedby: boolean;
    public issuetoll: boolean;
    public issuetott: boolean;

  /**
   * Create an object from a JSON representation
   * @param data
   * @return QCRule
   */
  public static parse(data?: Partial<QCRule>) {
    const obj = new QCRule;
    copyInto(obj, data);
    return obj;
  }

  /**
   * Constructor
   */
  public constructor(data?: Partial<QCRule>) {
    super(data)
    this.email = _get(data, 'email', '');
    this.includeinvoice = _get(data, 'includeinvoice', true);
    this.includesummary = _get(data, 'includesummary', false);
    this.ccbookedby = _get(data, 'ccbookedby', true);
    this.issuetoll = _get(data, 'issuetoll', false);
    this.issuetott = _get(data, 'issuetott', false);
  }

}
