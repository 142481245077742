<template>
  <div @focusout="closeDropdown">
    <singleselect-text
        class="statusdtopdown"
        v-show="changestatusreportid === report.id"
        :ref="'destatusdropdown-' + report.id"
        v-model="report.dataentrystatus"
        :options="reportstatuslist"
        @select="onSelectReportStatus(report.id, $event)"
      ></singleselect-text>
    <div 
      v-show="changestatusreportid != report.id" 
      class="statustext"
      :class="statusclass"
      @click="changeStatusReport()"
      >{{ report.dataentrystatus ? report.dataentrystatus : 'New'  }}</div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, toRefs, defineEmits, defineProps } from 'vue'
import { useStore } from 'vuex'
import { Report } from '@/models';

const store = useStore()

const props = defineProps<{
  report: Report;
  changestatusreportid: string;
}>()

const emit = defineEmits(['statusreportselected'])
const dynamicRefs = ref<{ [key: string]: HTMLElement | undefined }>({});

const { report, changestatusreportid } = toRefs(props)

const reportstatuslist: string[] = ['Not Acknowledged', 'Acknowledged', 'In Progress', 'Upload Complete'];

const updateReportDataentryStatus = async (payload: { id: string | undefined; status: string }) => {
  await store.dispatch('reports/updateReportDataentryStatus', payload)
}

const changeStatusReport = () => {
  if (report.value?.booking?.cancelled) return
  emit('statusreportselected', report.value.id)
  
  const dropdown = dynamicRefs.value[`destatusdropdown-${report.value.id}`] as any;
  if (dropdown) {
    dropdown.activate();
  }
}

const onSelectReportStatus = async (id: string | undefined, val: string) => {
  await updateReportDataentryStatus({ id, status: val })
  emit('statusreportselected', '')
}

const closeDropdown = () => {
  emit('statusreportselected', '')
}

const statusclass = computed(() => {
  let c = ''
  if (report.value.dataentrystatus === '') {
    c = 'emptystatus'
  } else if (report.value.dataentrystatus === 'Not Acknowledged') {
    c = 'notacknowledged'
  } else if (report.value.dataentrystatus === 'Acknowledged') {
    c = 'acknowledged'
  } else if (report.value.dataentrystatus === 'In Progress') {
    c = 'inprogress'
  } else if (report.value.dataentrystatus === 'Upload Complete') {
    c = 'uploadcomplete'
  }
  return c
})
</script>


<style scoped lang="scss">
.statustext {
  text-align: center;
}
.emptystatus {
  color: rgb(255, 255, 255);
  background-color: tomato;
}
.notacknowledged {
  color: white;
  background-color: tomato;
}
.acknowledged {
  color: white;
  background-color: rgb(110, 206, 248);
}
.inprogress {
  color: white;
  background-color: orange
}
.uploadcomplete {
  color: rgb(255, 255, 255);
  background-color: rgb(112, 179, 12)
}

</style>

<style lang="scss">
.statusdtopdown > .multiselect__content-wrapper {
  width: fit-content; 
}
</style>