import _get from 'lodash/get';

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

export class Job extends Base {

  public id: string;
  public type: string;
  public payload: {};
  public completed: boolean;

  /**
   * Constructor initialises the variables from the class Job
   * @param data - the data to construct a type with all properties of <Job>
   */
  public constructor(data?: Partial<Job>) {
    super(data);
    data = toCamelCase(data);

    this.id = _get(data, 'id', '');
    this.type = _get(data, 'type', '');
    this.payload = _get(data, 'payload', {});
    this.completed = _get(data, 'completed', false);
  }

}
