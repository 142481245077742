<template>
  <div class="report">
    <h1>Conversion Matrix</h1>

    <div class="row">
      <div class="col-2"></div>
      <div class="col-2"><i class="fas fa-square onedaycutoff"></i> Yes if on booking day</div>
      <div class="col-2"><i class="fas fa-square morethanonedaycutoff"></i> Yes if after the booking day</div>
      <div class="col-2"><i class="fas fa-square twodaycutoff"></i> Yes if booking day or next day</div>
      <div class="col-2"><i class="fas fa-square morethantwodaycutoff"></i> Yes if after 2 days of booking</div>
      <div class="col-2"><i class="fas fa-square statusseen"></i> Yes if status seen</div>
    </div>
    <div class="card-body p-0 matrix-container">
      <table>
        <thead>
          <tr>
            <th style="z-index: 10"></th>
            <th
              v-for="(group, $groupindex) in conversionmatrix.rulegroups"
              :key="$groupindex"
              class="rotate"
            >
              <div>
                <span>{{ group.name }}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-for="(section, $sectionindex) in conversionmatrix.sections" :key="$sectionindex">
          <tr >
            <td class='font-weight-bold'>{{ section.name }}</td>
          </tr>
          <tr v-for="(field, $fieldindex) in section.fieldlist" :key="$fieldindex">
            <td>{{ field }}</td>
            <td class="templatecell" v-for="(rulegroup, $groupindex) in conversionmatrix.rulegroups"
              :key="$groupindex">
              <div>
                <span v-if="selected[rulegroup.name] && selected[rulegroup.name][`${section.name}/${field}`]" @click="unselect(rulegroup.name, section.name, field)">
                <i class="far fa-check-circle fa-2x selected" 
                  :class="{'onedaycutoff': onedaycutoff[rulegroup.name][`${section.name}/${field}`], 'morethanonedaycutoff': morethanonedaycutoff[rulegroup.name][`${section.name}/${field}`], 'twodaycutoff': twodaycutoff[rulegroup.name][`${section.name}/${field}`], 'morethantwodaycutoff': morethantwodaycutoff[rulegroup.name][`${section.name}/${field}`],  'statusseen': statusseen[rulegroup.name][`${section.name}/${field}`]}"></i>
                </span>
                <span v-else @click="select(rulegroup.name, section.name, field)"><i class="far fa-check-circle fa-2x unselected"></i></span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Bottom navbar -->
    <nav class="navbar fixed-bottom navbar-dark bg-primary">
      <div class="container-fluid">
        <div class="mr-auto"></div>
        <div class="ml-auto text-light">
          <button class="btn btn-light" @click.prevent="save()">
            <i class="fas fa-save"></i> Save
          </button>
        </div>
      </div>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { inject, ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

import conversionmatrix from "../assets/json/conversionmatrix.json";

const store = useStore();
const toasted = useToast();
const actProperty: any = inject('actProperty');
const getSysParam = (key: string) => store.dispatch('app/getSysParam', key);
const updateSysParam = (key: string, value: string) => store.dispatch('app/updateSysParam', {key, value});

let selected = ref({});
let conversionkeys: string[] = [];
let onedaycutoff = ref({});
let morethanonedaycutoff = ref({});
let twodaycutoff = ref({});
let morethantwodaycutoff = ref({});
let statusseen = ref({});

onMounted(async () => {
  for(let k = 0; k < conversionmatrix.rulegroups.length; k++) {
    let rg: string = conversionmatrix.rulegroups[k].name;
    selected.value[rg] = {};
    onedaycutoff.value[rg] = {};
    morethanonedaycutoff.value[rg] = {};
    twodaycutoff.value[rg] = {};
    morethantwodaycutoff.value[rg] = {};
    statusseen.value[rg] = {};
    for(let i = 0; i < conversionmatrix.sections.length; i++) {
      let s: any = conversionmatrix.sections[i];
      for(let j = 0; j < s.fieldlist.length; j++) {
        let field = s.fieldlist[j];
        selected.value[rg][`${s.name}/${field}`] = false;
        onedaycutoff.value[rg][`${s.name}/${field}`] = false;
        morethanonedaycutoff.value[rg][`${s.name}/${field}`] = false;
        twodaycutoff.value[rg][`${s.name}/${field}`] = false;
        morethantwodaycutoff.value[rg][`${s.name}/${field}`] = false;
        statusseen.value[rg][`${s.name}/${field}`] = false;
      }
    }
  }
  const matrixvalue = await getSysParam('conversionmatrix');
  if(conversionkeys) {
    conversionkeys = matrixvalue.split(',');
    for(let i = 0; i < conversionkeys.length; i++) {
      const rgkey = conversionkeys[i].substring(0,1);
      const fldkey = conversionkeys[i].substring(1);
      const rg: string  = keytorulegroup(rgkey);
      const fld: string = keytofield(Number(fldkey));
      if(rg && fld) {
        selected.value[`${rg}`][`${fld}`] = true;
      }
    }
  }

  for(let k = 0; k < conversionmatrix.onedaycutoff.length; k++) {
    let pair: string = conversionmatrix.onedaycutoff[k];
    const rgkey = pair.substring(0,1);
    const fldkey = pair.substring(1);
    const rg: string  = keytorulegroup(rgkey);
    const fld: string = keytofield(Number(fldkey));
    onedaycutoff.value[`${rg}`][`${fld}`] = true;
  }
  for(let k = 0; k < conversionmatrix.morethanonedaycutoff.length; k++) {
    let pair: string = conversionmatrix.morethanonedaycutoff[k];
    const rgkey = pair.substring(0,1);
    const fldkey = pair.substring(1);
    const rg: string  = keytorulegroup(rgkey);
    const fld: string = keytofield(Number(fldkey));
    morethanonedaycutoff.value[`${rg}`][`${fld}`] = true;
  }
  for(let k = 0; k < conversionmatrix.twodaycutoff.length; k++) {
    let pair: string = conversionmatrix.twodaycutoff[k];
    const rgkey = pair.substring(0,1);
    const fldkey = pair.substring(1);
    const rg: string  = keytorulegroup(rgkey);
    const fld: string = keytofield(Number(fldkey));
    twodaycutoff.value[`${rg}`][`${fld}`] = true;
  }
  for(let k = 0; k < conversionmatrix.morethantwodaycutoff.length; k++) {
    let pair: string = conversionmatrix.morethantwodaycutoff[k];
    const rgkey = pair.substring(0,1);
    const fldkey = pair.substring(1);
    const rg: string  = keytorulegroup(rgkey);
    const fld: string = keytofield(Number(fldkey));
    morethantwodaycutoff.value[`${rg}`][`${fld}`] = true;
  }
  for(let k = 0; k < conversionmatrix.statusseen.length; k++) {
    let pair: string = conversionmatrix.statusseen[k];
    const rgkey = pair.substring(0,1);
    const fldkey = pair.substring(1);
    const rg: string  = keytorulegroup(rgkey);
    const fld: string = keytofield(Number(fldkey));
    statusseen.value[`${rg}`][`${fld}`] = true;
  }
});

const fieldnames = computed(()=> {
  let list: any[] = [];
  conversionmatrix.sections.forEach((s: any) => {
    list.push({name: s.name,header: true});
    s.fieldlist.forEach((f: any) => {
      list.push({name:f, header: false});
    })
  });
  return list;
})

const select = (rulegroup: string, sectionname: string, fieldname: string) => {
  selected.value[rulegroup][`${sectionname}/${fieldname}`] = true;
  const rgkey = rulegrouptokey(rulegroup);
  const fldkey = fieldtokey(sectionname, fieldname);
  conversionkeys.push(`${rgkey}${fldkey}`);
}
const unselect = (rulegroup: string, sectionname: string, fieldname: string) => {
  selected.value[rulegroup][`${sectionname}/${fieldname}`] = false;
  const rgkey = rulegrouptokey(rulegroup);
  const fldkey = fieldtokey(sectionname, fieldname);
  const index = conversionkeys.findIndex((k: string) => k === `${rgkey}${fldkey}`);
  if(index >= 0) conversionkeys.splice(index, 1);
}

const alpha = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
const rulegrouptokey = (rulegroup: string) => {
  const index = conversionmatrix.rulegroups.findIndex((rg: any) => rg.name === rulegroup);
  return alpha[index];
}
const keytorulegroup = (key: string) => {
  let rg = '';
  const index = alpha.findIndex((a) => a === key);
  if(index >= 0) {
    rg = conversionmatrix.rulegroups[index].name;
  }
  return rg;
}

const fieldtokey = (sectionname: string, fieldname: string) => {
  let index = -1;
  for(let i = 0; i < conversionmatrix.sections.length; i++) {
    let s: any = conversionmatrix.sections[i];
    for(let j = 0; j < s.fieldlist.length; j++) {
      let field = s.fieldlist[j];
      index++;
      if(s.name === sectionname && field === fieldname) return index;
    }
  }
  return index;
}

const keytofield = (key: number) => {
  let val = '';
  let index = -1;
  for(let i = 0; i < conversionmatrix.sections.length; i++) {
    let s: any = conversionmatrix.sections[i];
    for(let j = 0; j < s.fieldlist.length; j++) {
      let field = s.fieldlist[j];
      index++;
      if(index >= key) return `${s.name}/${field}`;
    }
  }
  return val;
}

const save = async () => {
  await updateSysParam('conversionmatrix', conversionkeys.join(','))
  .then(() => toasted.success("Conversion matrix updated"))
    .catch((err: any) => actProperty.displayError(err));
} 
</script>

<style scoped lang="scss">
table {
  border-collapse: collapse;
  width: 100%;
}

table.dictionarytable {
  margin: 0 0 0px;
  table-layout: fixed;
}

th {
  font-size: 14px;
  font-weight: bold;
}
th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}

th.rotate,
td.rotate {
  /* Something you can count on */
  height: 350px;
  white-space: nowrap;
}

th.rotate > div,
td.rotate > div {
  transform: 
    /* Magic Numbers */ translate(10px, 160px)
    /* 90 is really 360 - 90 */ rotate(270deg);
  width: 30px;
}
th.rotate > div > span,
td.rotate > div > span {
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
table {
  position: relative;
  width: auto;
  height: auto;
  border-collapse: collapse;

  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    white-space: nowrap;
  }

  thead th {
    position: sticky;
    top: 0;
    background-color: white; // Ensure visibility over other rows
    z-index: 3; // Higher z-index for header row
  }

  th:first-child, td:first-child {
    position: sticky;
    left: 0;
    background-color: white; // Ensure visibility over other columns
    z-index: 2; // Ensure it's under the top-left cell but above other cells
  }

  th:first-child {
    z-index: 4; // Top-left cell needs the highest z-index
  }
}

.matrix-container {
  overflow: auto;
  max-height: 73vh; // Adjust height as needed to enable vertical scrolling
}


.legend {
  div:nth-child(1) i {
    color: #c0c0c0;
  }
  div:nth-child(2) i {
    color: #b39ed9;
  }
  div:nth-child(3) i {
    color: #fabf40;
  }
  div:nth-child(4) i {
    color: #7eb249;
  }
  div:nth-child(5) i {
    color: #7e5bbf;
  }
  div:nth-child(6) i {
    color: #1b277c;
  }
}

.selected {
  color: #7eb249;
}
.unselected {
  color: #b4b4b4;
}
.onedaycutoff {
  color: #e4aea3;
}
.morethanonedaycutoff {
  color: #c13c24;
}
.twodaycutoff {
  color: #8dc4ce;
}
.morethantwodaycutoff {
  color: #05525f;
}
.statusseen {
  color: #e04bcc;
}
</style>