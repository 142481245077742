<template>
  <div class="customers list">
    <div
      v-if="!customers.length && !hasBlockingRequests"
      class="alert alert-warning text-center"
    >
      <h2 class="mt-1 mb-2">No customers found</h2>
      <p class="mb-1">
        beginning with &ldquo;<strong>{{
          (route.params.search as string).toUpperCase()
        }}</strong
        >&rdquo;
      </p>
    </div>

    <table v-else class="table table-hover">
      <thead>
        <th>Company</th>
        <th>Branch</th>
        <th>AC Client Code</th>
        <th>Nominal Code</th>
        <th>Region</th>
        <th width="7%"><span class="sr-only">Actions</span></th>
      </thead>
      <tbody>
        <tr
          v-for="customer in sortedcustomers"
          :key="customer.id"
          @click="edit(customer)"
        >
          <td>{{ customer.companyName }}</td>
          <td>{{ customer.branchName }}</td>
          <td>{{ customer.acClientCode }}</td>
          <td>{{ customer.nominalCode }}</td>
          <td>{{ getRegion(customer.office) }}</td>
          <td class="text-right">
            <ul class="list-unstyled mb-0">
              <li class="list-item" @click.stop>
                <button
                  class="btn btn-outline-danger"
                  @click="remove(customer)"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, inject } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useToast } from "vue-toastification";
import { Customer, Office } from "@/models";
import { sortCustomerList } from "@/utilities";

const store = useStore();
const router = useRouter();
const route = useRoute();
const toasted = useToast();

const actProperty: any = inject('actProperty');

const hasBlockingRequests = computed(() => store.getters['app/hasBlockingRequests']);
const customers = computed(() => store.getters['customers/list']);
const offices = computed(() => store.getters['offices/getOffices']);

const sortedcustomers = computed(() => sortCustomerList(customers.value));

const edit = (customer: Customer) => {
  if (customer.hasOwnProperty("id")) {
    router.push({ name: "customer", params: { id: customer.id } });
  } else {
    actProperty.displayError("Error");
  }
};

const remove = async (customer: Customer) => {
  actProperty
    .confirmPrompt()
    .then(() => deleteCustomer(customer))
    .then(() => toasted.success("Customer deleted"))
    .catch((err: any) => actProperty.displayError(err));
};
const deleteCustomer = async (customer: Customer) => {
  await store.dispatch('customers/deleteCustomer', customer);
};

const getRegion = (id: string): string => {
  let office = offices.value.find((o: Office) => o.id == id);
  return office ? office.name : "";
};

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
