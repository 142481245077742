<template>
  <div class="list">
    <div
      v-if="!users.length && !hasBlockingRequests"
      class="alert alert-warning text-center"
    >
      <h2 class="mt-1 mb-2">No users found</h2>
    </div>

    <table v-else class="table table-hover">
      <thead>
        <th width="30%">Username</th>
        <th width="10%">Office</th>
        <th width="30%">Customer Name</th>
        <th width="20%">Confirmation Status</th>
        <th width="10%">Status</th>
      </thead>

      <tbody>
        <tr v-for="user in users" :key="user.Username" @click="edit(user)">
          <td>{{ user.Username }}</td>
          <td>{{ findUserACTOffice(user) }}</td>
          <td>{{ user.findAttributeValue("custom:company_name") }}</td>
          <td>{{ user.UserStatus }}</td>
          <td v-if="user.Enabled">
            <div class="status-enabled chip">
              <i class="fas fa-check-circle"></i> Enabled
            </div>
          </td>
          <td v-else>
            <div class="status-disabled chip">
              <i class="fas fa-minus-circle"></i> Disabled
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Office, CognitoUser } from '@/models';

const store = useStore();
const router = useRouter();

// Accessing getters using the Composition API
const hasBlockingRequests = computed((): boolean => store.getters['app/hasBlockingRequests']);
const getOffices = computed((): Office[] => store.getters['offices/getOffices']);
const users = computed((): CognitoUser[] => store.getters['sysadmin/list']);

const edit = (user: any) => { // Replace 'any' with 'CognitoUser' if the type is imported
  router.push({
    name: "usermanagement",
    params: { sub: user.findAttributeValue("sub") },
  });
};

const findUserACTOffice = (user: any): string | undefined => { // Replace 'any' with 'CognitoUser' if the type is imported
  let office = getOffices.value.find(
    (office) => office.id === user.findAttributeValue("custom:office_id")
  );
  return office ? office.name : undefined;
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.status-enabled {
  color: green;
}
.status-disabled {
  color: gray;
}
.chip {
  display: inline-block;
  padding: 0 10px;
  height: 35px;
  font-size: 14px;
  line-height: 35px;
  border-radius: 20px;
  background-color: #f1f1f1;
  margin: 2px 0px 0px 0px;
}
.tabble td {
  padding: 0.15rem;
  vertical-align: middle;
}
</style>
