import _get from 'lodash/get';

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

/**
 * Interface defines the syntax properties which the Address class must follow
 */
interface AddressInterface {
  name: string;
  line1: string;
  line2?: string;
  town: string;
  county?: string;
  postcode: string;
  country?: string;
  phone?: string;
  email?: string;
}

/**
 * Address Class implements AddressInterface - therefore must follow the structure provided by the interface.
 */
export class Address extends Base implements AddressInterface {
  /**
 * A string defining the first name of an address
 */
  public name: string;
  /**
   * A string defining the first line of an address
   */
  public line1: string;

  /**
   * A string defining the second line of an address
   */
  public line2: string;

  /**
   * A string defining the town
   */
  public town: string;

  /**
   * A string defining the county
   */
  public county: string;

  /**
   * A string defining the postcode
   */
  public postcode: string;

  /**
   * A string defining the country
   */
  public country: string;

  public phone: string;
  public email: string;

  get displayaddress() {
    return `${this.name.trim() ? this.name.trim() + ", " : ""}${this.line1}${this.line2.trim() ? ", " + this.line2.trim() : ""}${this.town.trim() ? ", " + this.town.trim() : ""}${this.postcode.trim() ? ", " + this.postcode.trim() : ""}`;
  }

  get addressPreview() {
    let lines = [];
    if (this.name.trim().length > 0) lines.push(this.name.trim());
    if (this.line1.trim().length > 0) lines.push(this.line1.trim());
    if (this.line2.trim().length > 0) lines.push(this.line2.trim());
    if (this.town.trim().length > 0) lines.push(this.town.trim());
    if (this.postcode.trim().length > 0) lines.push(this.postcode.trim());
    return lines.join(", ");
  }

  /**
   * Constructor initialises the variables from the class Address
   * @param data - the data to construct a type with all properties of <Address>
   */
  public constructor(data?: Partial<Address>) {
    super(data);
    data = toCamelCase(data);
    this.name = _get(data, 'name', '');
    this.line1 = _get(data, 'line1', '');
    this.line2 = _get(data, 'line2', '');
    this.town = _get(data, 'town', '');
    this.county = _get(data, 'county', '');
    this.postcode = _get(data, 'postcode', '');
    this.country = _get(data, 'country', '');
    this.phone = _get(data, 'phone', '');
    this.email = _get(data, 'email', '');
  }

  get isempty() {
    return !this.line1 && !this.line2 && !this.town && !this.postcode;
  }

  isMatching = (another: Address) => {
    if(!another) return false;
    return this.line1 === another.line1 && this.line2 === another.line2 && this.town === another.town && this.postcode === another.postcode;
  }

}
