import _castArray from 'lodash/castArray';
import _get from 'lodash/get';

import { Base, Note, Photo, SameAsCheckin } from '@/models';
import { toCamelCase } from '@/utilities';

export class Condition extends Base {
  /**
   * Extends photo.model
   * Stores the uploaded image src and upload timestamp
   */
  public photos: Photo[];

  /**
   * Extends note.model
   * Stores list of condition notes
   */
  public notes: Note[];

  /**
   * Extends same-as-checkin.model
   * optional - checks whether SameAsCheckin is true or false
   */
  public sameAsCheckin?: SameAsCheckin;

  /**
   * Constructor initialises the variables from the class Condition
   * @param data - the data to construct a type with all properties of <Condition>
   */
  public constructor(data?: Partial<Condition>) {
    super(data);
    data = toCamelCase(data);
    
    this.photos = _castArray(_get(data, 'photos', [])).map((x: Partial<Photo>) => new Photo(x));
    this.notes = _castArray(_get(data, 'notes', [])).map((x: Partial<Note>) => new Note(x));

    if (_get(data, 'sameAsCheckin')) {
      this.sameAsCheckin = new SameAsCheckin(_get(data, 'sameAsCheckin'));
    }
  }

}
