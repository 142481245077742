<template>
  <div>
    <h2>Members Summary</h2>
    <div class="row">
      <div
        class="col-md-9 dx-item-content dx-toolbar-item-content"
        style="min-width: 350px"
      >
        <div role="group" class="dx-buttongroup dx-widget" tabindex="0">
          <div class="dx-buttongroup-wrapper dx-widget dx-collection">
            <div
              class="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-icon dx-item dx-buttongroup-item dx-item-content dx-buttongroup-item-content dx-buttongroup-first-item dx-shape-standard dx-scheduler-navigator-previous"
              role="button"
              aria-label="chevronprev"
              @click="prevdate"
            >
              <div class="dx-button-content">
                <i class="dx-icon dx-icon-chevronprev"></i>
              </div>
            </div>
            <div
              class="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-text dx-item dx-buttongroup-item dx-item-content dx-buttongroup-item-content dx-shape-standard dx-scheduler-navigator-caption"
              role="button"
              aria-label=""
            >
              <DxDateBox
                v-model="currentdate"
                display-format="EEEE dd MMMM yyyy"
                type="date"
                picker-type="calendar"
                apply-value-mode="instantly"
                class="datebox"
                :calendarOptions="{ firstDayOfWeek: 1 }"
              />
            </div>
            <div
              class="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-icon dx-item dx-buttongroup-item dx-item-content dx-buttongroup-item-content dx-shape-standard dx-scheduler-navigator-next"
              role="button"
              aria-label="chevronnext"
              @click="nextdate"
            >
              <div class="dx-button-content">
                <i class="dx-icon dx-icon-chevronnext"></i>
              </div>
            </div>
            <div
              class="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-icon dx-item dx-buttongroup-item dx-item-content dx-buttongroup-item-content dx-buttongroup-last-item dx-shape-standard today-button"
              role="button"
              aria-label="today"
              @click="today"
            >
              <div class="dx-button-content">
                <i class="far fa-calendar-check"></i> Today
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <table class="table table-hover">
      <thead>
        <th>QC Members</th>
        <th>Working</th>
        <th>Set daily target</th>
        <th>Capacity</th>
        <th>Allocate</th>
        <th>Review</th>
        <th style="width: 250px !important;">Summary</th>
        <th>Issued</th>
        <th>Outstanding</th>
      </thead>
      <tbody>
        <tr v-for="(row, $rowindex) in qcdailymembertarget" :key="$rowindex">
          <td>{{ row.qcmember?.name }}</td>
          <td>
             <input type="checkbox" v-model="row.working" :checked="row.working"
              @change="editTargetData(row, $rowindex)" />  
           </td> 
          <td>
            <input
              v-model.lazy="row.dailytarget"
              type="number"
              class="form-control height-43"
              @input="validateInput(row)"
              @change="editTargetData(row, $rowindex)"
            />
          </td>
          <td>{{ row.capacity }}</td>
          <td>{{ row.allocate }}</td>
          <td>{{ "test" }}</td>
          <td>Cosoc - {{  row.cosoc }} , CI - {{  row.ci }} , PV - {{  row.pv }}, Other - {{  row.other }}</td>
          <td>{{ row.issued }}</td>
          <td>{{ row.outstanding }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { DxDateBox } from "devextreme-vue/date-box";
import { useStore } from "vuex";
import moment from "moment-timezone";
import { useToast } from "vue-toastification";
import Ably from "ably";
import { Booking, Qc, Qcmember } from "@/models";
import { Qcmemberdailytarget } from "@/models/qcmemberdailytarget.model";

const route = useRoute();
const router = useRouter();
const store = useStore();
const _currentdate = computed(() => store.getters["diary/currentdate"]);

const getBookingsforQCMemberList = (
  payload?: { date?: string },
  p0?: () => void
) => {
  return store.dispatch("diary/getBookingsforQCMemberList", payload);
};
const qcnemberlist = ref([]);
const bookinglist = ref([]);
const qcdailymembertarget = ref([]);

const setCurrentdate = (currentdate: Date) => {
  store.commit("diary/setCurrentdate", currentdate);
};

const getBookingsforQCSummary = (
  payload?: { date?: string },
  p0?: () => void
) => {
  return store.dispatch("diary/getBookingsforQCSummary", payload);
};

const getQcDailyTarget = (payload?: { date?: string }, p0?: () => void) => {
  return store.dispatch("diary/getQcDailyTarget", payload);
};

const updateDailyTarget = async (qcdailymembertarget: Qcmemberdailytarget) => {
  return await store.dispatch("diary/updateDailyTarget", qcdailymembertarget);
}



class MemberSummaryData {
  id?: string = '';
  date?: string = '';
  working?: boolean = false;
  dailytarget?: number = 0;
  qcmember: Qcmember;
  capacity: number = 0;
  allocate: number = 0;
  review: string = '';
  issued: number = 0;
  outstanding: number = 0;
  inventory?: number;
  cosoc: number;
  ci: number;
  other: number;
  pv: number;
}

const currentdate = computed({
  get: () => _currentdate.value,
  set: (val) => {
    // Assuming setCurrentdate is an adapted method for Vue 3
    setCurrentdate(val);
  },
});

const prevdate = () => {
  currentdate.value = moment(currentdate.value)
    .utc()
    .subtract(1, "days")
    .toDate();
  //getBookingsforQCSummary({ date: formatDate(currentdate.value) }, () => {});
};

const nextdate = () => {
  currentdate.value = moment(currentdate.value).utc().add(1, "days").toDate();
  //getBookingsforQCSummary({ date: formatDate(currentdate.value) }, () => {});
};

const today = () => {
  currentdate.value = moment().utc().toDate();
  //getBookingsforQCSummary({ date: formatDate(currentdate.value) }, () => {});
};

const formatDate = (date: Date) => {
  return moment(date).format("DD-MM-YYYY");
};

onMounted(async () => {
  commonFunction();
});

const commonFunction = async () => {
  await getBookingsforQCMemberList({ date: "" }, () => {}).then(
    (t: Qcmember[]) => {
      qcnemberlist.value = t;
      getBookingsforQCSummary(
        { date: formatDate(currentdate.value) },
        () => {}
      ).then((bookings: Booking[]) => {
        bookinglist.value = bookings;
      });
      getQcDailyTarget({ date: formatDate(currentdate.value) }, () => {}).then(
        (qcdata: Qcmemberdailytarget[]) => {
          qcnemberlist.value.forEach((f: Qcmember, $index) => {
            let data = new MemberSummaryData();
            let index = qcdata.findIndex(
              (q: Qcmemberdailytarget) => q.qcmember.id === f.id
            );
            if (index === -1) {
              data.qcmember = f;
            } else {             
              data.qcmember = qcdata[index].qcmember;
              data.id = qcdata[index].id;
              data.working = qcdata[index].working;
              data.dailytarget = qcdata[index].dailytarget;
            }
            // capacity 
            if(data.dailytarget !== 0){
              data.allocate = bookinglist.value.filter((b: Booking) => {
                 return f.id ===  b.qc?.qcmember?.id;
              })?.length;
              data.capacity = data.dailytarget - data.allocate;
            }
            data.issued = bookinglist.value.filter((b: Booking) => {
                 return f.id ===  b.qc?.qcmember?.id && b.issued;
              })?.length;
            data.outstanding = bookinglist.value.filter((b: Booking) => {
                return f.id ===  b.qc?.qcmember?.id && !b.issued;
            })?.length;
            const jobtypeArray = ["inventory", "checkin", "soc", "property visit", "checkout"];
            data.cosoc = bookinglist.value.filter((b: Booking) => {
              return b.jobtype === ('checkout' || b.jobtype === 'soc') && f.id ===  b.qc?.qcmember?.id;
            })?.length;
            data.ci = bookinglist.value.filter((b: Booking) => {
              return b.jobtype === 'checkin' && f.id ===  b.qc?.qcmember?.id;
            })?.length;
            data.inventory = bookinglist.value.filter((b: Booking) => {
              return b.jobtype === 'inventory' && f.id ===  b.qc?.qcmember?.id;
            })?.length;
            data.pv = bookinglist.value.filter((b: Booking) => {
              return b.jobtype === 'property visit' && f.id ===  b.qc?.qcmember?.id;
            })?.length;
            data.other = bookinglist.value.filter((b: Booking) => {
              return !jobtypeArray.includes(b.jobtype) && f.id ===  b.qc?.qcmember?.id;
            })?.length;
                      qcdailymembertarget.value.push(data);
          });
        }
      );
    }
  );
};

const editTargetData = (row: MemberSummaryData, index: number) => {
  let data = new Qcmemberdailytarget();
  data.id = row.id;
  data.date = currentdate.value;
  data.dailytarget = row.dailytarget;
  data.working = row.working;
  data.qcmember = row.qcmember;
    
  updateDailyTarget(data).then((b) => {
    let index = qcdailymembertarget.value.findIndex(
      (q: MemberSummaryData) => q.qcmember.id === b.qcmember.id
    );
    if (index !== -1) {
      qcdailymembertarget.value[index].id = b.id;
      qcdailymembertarget.value[index].date = b.date;
      qcdailymembertarget.value[index].dailytarget = b.dailytarget;
      qcdailymembertarget.value[index].working = b.working;
      qcdailymembertarget.value[index].qcmember = b.qcmember;
      qcdailymembertarget.value[index].capacity = b.dailytarget - qcdailymembertarget.value[index].allocate;
    }
  });
};
const validateInput = (row) => {
  // if (numberInput.value === '-1' || numberInput.value.includes('e')) {
  //   numberInput.value = 0;
  // }
};
</script>

<style scoped lang="scss"></style>
