// globalEventBus.js
import { reactive, watchEffect, ref } from "vue";
import { v4 as uuidv4 } from "uuid";
import { event } from "jquery";

const events = reactive({});

export function emitEvent(eventName: any, data?: any) {
  events[eventName] = data ?? uuidv4();
}

export function useEvent(eventName: any, handler: any) {
  watchEffect(() => {
    if (events.hasOwnProperty(eventName)) {
      handler(events[eventName]);
      delete events[eventName];
    }
  });
}
