import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { Base } from '@/models';
import { toCamelCase, omitDeep } from '@/utilities';
export class Remotecommand extends Base {

    public id: string;
    public idcopy: string;
    public userid: string;
    public command: string;
    public response: string;
    public status: string;
    public deleted: boolean;
    public createdAt: string;
    public updatedAt: string;

    /**
     * Constructor initialises the variables from the class Remotecommand
     * @param data - the data to construct a type with all properties of <Remotecommand>
     */
    public constructor(data?: Partial<Remotecommand>) {
        super(data);
            data = toCamelCase(data);

        this.id = _get(data, 'id', '');
        this.idcopy = _get(data, 'id', '');
        this.userid = _get(data, 'userid', '');
        this.command = _get(data, 'command', '');
        this.response = _get(data, 'response', '');
        this.status = _get(data, 'status', '');
        this.deleted = _get(data, 'deleted', false);
        this.createdAt = _get(data, 'createdAt', '');
        this.updatedAt = _get(data, 'updatedAt', '');
    }

    /**
     * Convert class instance back into a plain old JSON object
     * and recursively remove all uuids we've add for Vue.
     *
     * @return {Object}
     */
    public toObject() {
    return omitDeep({ ...this }, ['_uuid']);
    }
}
