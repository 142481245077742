import _get from 'lodash/get';

import { Photo } from '@/models';

export class LinkedPhoto extends Photo {

  public linkedphotos: Photo[]

  /**
   * Constructor initialises the variables from the class Photo
   * @param data - the data to construct a type with all properties of <Photo>
   */
  public constructor(photo: Photo) {
    super(photo);
    this.linkedphotos = [];
  }

  public addLinkedphoto(photo: Photo) {
    this.linkedphotos.push(photo);
  }

}
