import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { Base, DistanceMatrixElement } from '@/models';
import { toCamelCase } from '@/utilities';

export class DistanceMatrixRow extends Base {

  public elements: DistanceMatrixElement[];
  public status: string = '';

  public constructor(data?: Partial<DistanceMatrixRow>) {
    super(data);
    data = toCamelCase(data);
    this.elements = _castArray(_get(data, 'elements', [])).map((e: Partial<DistanceMatrixElement>) => new DistanceMatrixElement(e));
  }

  public static getSample(destinationAddresses: any[]) {
    let row = new DistanceMatrixRow();
    row.elements = [];
    if(destinationAddresses?.length) {
      destinationAddresses.forEach(() => {
        row.elements.push(DistanceMatrixElement.getSample())
      })
    }
    return row;
  }

}