import { MutationTree } from "vuex";
import { OfficesState } from "./types";
import { Office } from "@/models/office.model";

/**
 * Mutations
 *
 * The only way to actually change state in a Vuex store is by committing a mutation.
 * - Vuex mutations are very similar to events: each mutation has a string type and a handler.
 * - The handler function is where we perform actual state modifications - it receives the state as the first argument:
 * - You cannot directly call a mutation handler.
 * - Think of it more like event registration: "When a mutation with type X is triggered, call this handler."
 * - To invoke a mutation handler, you need to call store.commit with its type
 * - e.g. `store.commit('setCustomers', customer)`
 */
export const mutations: MutationTree<OfficesState> = {
  /**
   * Set the offices list
   *
   * @param {OfficesState} state
   * @param {Office[]} offices
   * @returns void
   */
  setOffices(state: OfficesState, offices: Office[]): void {
    state.offices = offices;
  },

  /**
   * Set the current Office ID
   *
   * @param {OfficesState} state
   * @param {string} officeId
   * @returns void
   */
  setCurrentOfficeId(state: OfficesState, officeId: string): void {
    state.currentOfficeId = officeId;
  },
};
