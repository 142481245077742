import { copyInto } from '@/utilities';

export class DictionaryCategory {

    public order: number;
    public types: { [k: string]: number };

    /**
     * Create an object from a JSON representation
     * @param data
     * @return Report
     */
    public static parse(data: object) {
        const obj = new DictionaryCategory;
        copyInto(obj, data, false);
        return obj;
    }

    /**
     * Constructor
     */
    public constructor() {
        order: -1;
        this.types = {};
    }
}
