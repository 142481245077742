<template>
  <div
    class="file-upload"
    :class="{ drag: isDragging }"
    @dragover.stop.prevent="onDragOver"
    @dragleave.stop.prevent="onDragLeave"
    @drop.stop.prevent="onDrop"
  >
    <div>
      <FloorplanComponent
        v-for="(fp, $index) in floorplan"
        :key="actProperty.hashObj(fp)"
        :floorplan="fp"
        @delete="deleteFloorplan($index)"
        @click.stop="onClick($index)"
      />
    </div>

    <span v-if="uploadingCount"><i class="fas fa-spinner fa-spin"></i></span>

    <label v-if="!uploadingCount && (!floorplan || !floorplan.length)">
      <span class="btn btn-sm btn-outline-secondary floorplan-upload-button">
        <div v-if="buttontext">{{ buttontext }}</div>
        <div v-else>
          <i class="fas fa-plus"></i>
          Upload
        </div>
      </span>
      <input
        type="file"
        accept="application/pdf,image/jpeg,image/png"
        multiple
        ref="file"
        v-on:change="onFileInputChange"
      />
    </label>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, onMounted, inject, defineProps,defineEmits,PropType } from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import _ from "lodash";
import moment from "moment-timezone";
import sanitize from "sanitize-filename";
import slugify from "slugify";
import { Storage } from 'aws-amplify'
import { Document } from "@/models";
import FloorplanComponent from "@/components/floorplan/Floorplan.vue";

const props = defineProps({
  path: {type: String as PropType<string>, default: ''},
  floorplan: {type: Object as PropType<Document[]>, default: []},
  buttontext: {type: String as PropType<string>, default: 'Document'},
  entity: {type: String as PropType<string>, default: 'customer'},
  readonly: { type: Boolean as PropType<boolean>, required: false, default: false }
});

const emit = defineEmits(['clicked'])
const { path, floorplan, buttontext } = props;
const store = useStore();
const toasted = useToast();
const actProperty: any = inject('actProperty');

// Refs for reactive properties
const file = ref(null);
const isDragging = ref(false);
const uploadingCount = ref(0);

const deviceKey = computed((): string => store.getters['auth/deviceKey']);
const propertyspec = computed((): string => store.getters['diary/propertyspec']);

// Methods converted to anonymous functions
const setPropertyspecDeep = async (payload: { path: string; data: any; }) => {
  await store.dispatch('diary/setPropertyspecDeep', payload);
};
const validtypes = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'image/jpg'
];

/**
 * Delete a Floorplan.
 *
 * Note this only removes the Floorplan reference from the data in Vuex.
 * It DOES NOT delete the actual file from file storage (e.g. from FTP/S3).
 *
 * @param {number} index Index of Floorplan to delete
 */
const deleteFloorplan = async (index: number) => {
  actProperty.confirmPrompt().then(() => {
    // Update floorplans array
    floorplan.splice(index, 1);

    // Update Vuex
    setPropertyspecDeep({ path: props.path, data: floorplan })
      .then(() => toasted.success("Floorplan removed"))
      .catch((err: any) => actProperty.displayError(err));
  }).catch(e => {});
};

const uploadFiles = async (files: FileList | null) => {
  try {
    if (null === files || 0 === files.length) {
      return;
    }

    uploadingCount.value = 0;

    // S3 options
    const options: any = {
      contentType: "application/pdf",
      customPrefix: {
        public: "",
        protected: "",
        private: "",
      },
      level: "public",
    };

    for (let i = 0; i < files.length; i++) {
      let file: File | null = files.item(i);

      if (file === null) {
        continue;
      }

      const { name, type, lastModified } = file;

      if (validtypes.indexOf(type) < 0) {
        actProperty.displayError(`${sanitize(name)} is not a valid type.`);
        continue;
      }

      // Increase uploadingCount (displays loading spinner)
      ++uploadingCount.value;

      // Add timestamp to make each device upload unique, and sanitise input filename
      let deviceKeyPrefix = `${deviceKey.value}`;
      if (!deviceKey.value) deviceKeyPrefix = `customerfloorplans`;
      else deviceKeyPrefix = `${deviceKey.value}`;
      const s3Filepath = `${deviceKeyPrefix}/${moment().format(
        "x"
      )}/${slugify(sanitize(name))}`;

      if (file?.type) {
        options.contentType = file.type;
      }

      // Save the File to S3
      await Storage.put(s3Filepath, file, options)
        .then(({ key }: any) => {
          if (!key || !key.length) {
            --uploadingCount.value;
            throw Error("Could not determine uploaded URL");
          }

          // Create the Document from the S3 response
          const floorplan = new Document({
            src: actProperty.s3Origin(key),
            createdAt: actProperty.datetimeToUTC(lastModified),
          });

          // Add Document to floorplans array
          const floorplans = _.get(propertyspec.value, path).concat([
            floorplan,
          ]);

          // Update Vuex
          setPropertyspecDeep({ path: path, data: floorplans });

          --uploadingCount.value;
        })
        .catch((error: Error) => {
          // Adjust uploadingCount and rethrow error
          --uploadingCount.value;
          throw error;
        });
    }
  } catch (error) {
    actProperty.displayError(error);
  }
};

// Lifecycle hooks
onMounted(() => {
  uploadingCount.value = 0;
});

// Event handlers
const onDragOver = (event: DragEvent) => {
  isDragging.value = true;
};

const onClick = (index:number) =>{
  emit('clicked', index);
}

const onDragLeave = (event: MouseEvent) => {
  if (event.currentTarget === event.target) {
    isDragging.value = false;
  }
};

const onDrop = (event: DragEvent) => {
  isDragging.value = false;
  const files: FileList | null = event.dataTransfer?.files || null;
  uploadFiles(files);
};

const onFileInputChange = (event: Event) => {
  const files = _.get(file.value, "files", null);
  uploadFiles(files);
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/bootstrap/_variables.scss";

.drag * {
  pointer-events: none;
}

.file-upload {
  border: 2px dashed rgba(0, 0, 0, 0);
  border-radius: 0.2rem;
  box-sizing: border-box;
  width: 100%;
  &.drag {
    background-color: $info-semi-opaque;
    border-color: $info;
  }
}

label {
  margin-bottom: 0;
  input[type="file"] {
    display: none;
  }
}
</style>