<template>
  <div class="modal" data-keyboard="true" tabindex="-1" role="dialog" :id="`${name}-modal`"
    :aria-labelledby="`${name}-modal-label`" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="`${name}-modal-label`">{{ title }}</h5>
          <button id="close" type="button" class="close" data-dismiss="modal" aria-label="Close"
            @click.prevent="hide(false)">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div>
            <textarea class="form-control" rows="20" v-model.lazy="accessissues"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
const store = useStore();
import $ from 'jquery';
import { defineProps, defineExpose } from 'vue';
const _accessissues = computed(() => store.getters['diary/accessissues']);
const setBookingDeep = async (payload: {
  path: string;
  data: any;
}) => {
  await store.dispatch('diary/setBookingDeep', payload);
}
const props = defineProps<{
  name: string;
  title?: string;
  notes?: string;
}>();

const accessissues = computed({
  get: () => _accessissues.value,
  set: (data: string) => {
    setBookingDeep({ path: 'accessissues', data });
  }
});

const show = () => {
  if ($(`#${props.name}-modal` + 'Backdrop').length == 0) {
    const backdropDiv = $(`<div class="modal-backdrop fade show" id="${props.name}-modalBackdrop"></div>`);
    $('body').append(backdropDiv);
    $(`#${props.name}-modal`).show();
  }
}
const hide = async (val: boolean) => {
  if ($(`#${props.name}-modal`).length > 0) {
    $(`#${props.name}-modal` + 'Backdrop').remove();
    $(`#${props.name}-modal`).hide();
  }
};

defineExpose({ show, hide });
</script>

<style scoped>
.miscnotes-textarea {
  resize: none;
}
</style>