import { snakeCase, get as _get, castArray as _castArray} from 'lodash';
import { copyInto, toCamelCase } from '@/utilities';
import { Base, Contact, ReportDeliverySetting, OpeningHours } from '@/models';

export class Policies extends Base {

  public smokecoalarms: boolean;
  public safetycleats: boolean;
  public summaryemailafterco: boolean;
  public accessconfirm: boolean;
  public uniquenotes: string;

  public phone: string;
  public emergencyphone: string;
  public contactlist: Contact[];

  public inspectioninabs: boolean;
  public invciflex: boolean;
  public invciflexam: boolean;
  public cosocflex: boolean;

  public onsitefeedbackto: ReportDeliverySetting;
  public complianceformto: ReportDeliverySetting;
  public cocleaningfeedbackto: ReportDeliverySetting;

  public confirmaccess: boolean;
  public keyreleasereq: boolean;

  public openinghours: OpeningHours[];

  public donotcontactttforinvci: boolean;
  public donotcontactttforcosoc: boolean;
  public longtermpinotes: string;



  /**
   * Create an object from a JSON representation
   * @param data
   * @return CustomerAccountSettings
   */
  public static parse(data?: Partial<Policies>) {
    const obj = new Policies;
    copyInto(obj, data);

    obj.smokecoalarms = _get(data, 'smokecoalarms', false);
    obj.safetycleats = _get(data, 'safetycleats', false);
    obj.summaryemailafterco = _get(data, 'summaryemailafterco', false);
    obj.accessconfirm = _get(data, 'accessconfirm', false);

    obj.phone = _get(data, 'phone', '');
    obj.emergencyphone = _get(data, 'emergencyphone', '');
    obj.contactlist = _castArray(_get(data, "contactlist", [])).map(
      (x: Partial<Contact>) => new Contact(x)
    );

    obj.inspectioninabs = _get(data, 'inspectioninabs', false);
    obj.invciflex = _get(data, 'invciflex', false);
    obj.invciflexam = _get(data, 'invciflexam', false);
    obj.cosocflex = _get(data, 'cosocflex', false);
  
    obj.onsitefeedbackto = new ReportDeliverySetting(_get(data, 'onsitefeedbackto', {}));
    obj.complianceformto = new ReportDeliverySetting(_get(data, 'complianceformto', {}));
    obj.cocleaningfeedbackto = new ReportDeliverySetting(_get(data, 'cocleaningfeedbackto', {}));

    obj.confirmaccess = _get(data, 'confirmaccess', true);
    obj.keyreleasereq = _get(data, 'keyreleasereq', false);

    var openinghours = _get(data, "openinghours", []);
    if(openinghours.length === 0)
      openinghours = OpeningHours.default();
    else if(openinghours.length === 6) 
      openinghours.push(new OpeningHours);
    obj.openinghours = openinghours.map(
      (x: Partial<OpeningHours>) => new OpeningHours(x)
    );

    obj.uniquenotes = _get(data, 'uniquenotes', '');

    obj.donotcontactttforinvci = _get(data, 'donotcontactttforinvci', false);
    obj.donotcontactttforcosoc = _get(data, 'donotcontactttforcosoc', false);
    obj.longtermpinotes = _get(data, 'longtermpinotes', '');

    
    return obj;
  }

  /**
   * Constructor
   */
  public constructor(data?: Partial<Policies>) {
    super(data);
    data = toCamelCase(data);

    this.smokecoalarms = _get(data, 'smokecoalarms', false);
    this.safetycleats = _get(data, 'safetycleats', false);
    this.summaryemailafterco = _get(data, 'summaryemailafterco', false);
    this.accessconfirm = _get(data, 'accessconfirm', false);

    this.phone = _get(data, 'phone', '');
    this.emergencyphone = _get(data, 'emergencyphone', '');
    this.contactlist = _castArray(_get(data, "contactlist", [])).map(
      (x: Partial<Contact>) => new Contact(x)
    );

    this.inspectioninabs = _get(data, 'inspectioninabs', false);
    this.invciflex = _get(data, 'invciflex', false);
    this.invciflexam = _get(data, 'invciflexam', false);
    this.cosocflex = _get(data, 'cosocflex', false);
  
    this.onsitefeedbackto = new ReportDeliverySetting(_get(data, 'onsitefeedbackto', {}));
    this.complianceformto = new ReportDeliverySetting(_get(data, 'complianceformto', {}));
    this.cocleaningfeedbackto = new ReportDeliverySetting(_get(data, 'onsicocleaningfeedbacktotefeedbackto', {}));

    this.confirmaccess = _get(data, 'confirmaccess', true);
    this.keyreleasereq = _get(data, 'keyreleasereq', false);

    this.openinghours = _castArray(_get(data, "openinghours", OpeningHours.default())).map(
      (x: Partial<OpeningHours>) => new OpeningHours(x)
    );
    if(this.openinghours.length === 6) 
      this.openinghours.push(new OpeningHours);

    this.uniquenotes = _get(data, 'uniquenotes', '');

    this.donotcontactttforinvci = _get(data, 'donotcontactttforinvci', false);
    this.donotcontactttforcosoc = _get(data, 'donotcontactttforcosoc', false);
    this.longtermpinotes = _get(data, 'longtermpinotes', '');
  }

}
