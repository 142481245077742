<template>
  <div class="customer">
    <h3>Price List</h3>
    <DocumentsComponent :key="getPricelistDocs.length" :documents="getPricelistDocs" :path="'pricelist'" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import DocumentsComponent from "@/components/document/Documents.vue";

const store = useStore();
const customer = computed(() => store.getters['customers/current']);
const getPricelistDocs = computed(() => customer.value.pricelist);

</script>

<style scoped lang="scss">
</style>