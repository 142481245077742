<template>
    <div>
        <h2>Allocate</h2>
        <table class="table table-hover">
        <thead>
            <th>Date</th>
            <th>Address</th>
            <th>Ref</th>
            <th>Item</th>
            <th>Client</th>
            <th>PI</th>
            <th>Preffered PI</th>
            <th>QC Continuity</th>
            <th>Assign</th>
        </thead>
        <tbody>
            <tr>
                <td>{{ "test" }}</td>
                <td>{{ "test" }}</td>
                <td>{{ "test" }}</td>
                <td>{{ "test" }}</td>
                <td>{{ "test" }}</td>
                <td>{{ "test" }}</td>
                <td>{{ "test" }}</td>
                <td>{{ "test" }}</td>
                <td><input type="checkbox"></td>
            </tr>
        </tbody>
        </table>
    </div>
</template>

<script lang="ts" setup>
import { computed, inject, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Auth from "@aws-amplify/auth";
import { useStore } from 'vuex';
import moment from "moment-timezone";
import { useToast } from "vue-toastification";
import Ably from "ably";

const route = useRoute();
const router = useRouter();

onMounted(() => {
});

</script>


<style scoped lang="scss">

</style>