<template>
  <!-- "Loading" Modal -->
  <div class="modal fade modal-full" :class="{show: hasBlockingRequests}" id="loading-modal" data-keyboard="false" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <i class="fas fa-circle-notch fa-spin"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const hasBlockingRequests = computed(() => store.getters['app/hasBlockingRequests']);
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#loading-modal {
  z-index: 2147483647;
}
.modal-full {
//   min-width: 100%;
//   margin: 0;
background-color: rgba(255,255,255, 0.75)
}
// .modal-full .modal-content {
//   min-height: 100vh;
// }
.modal-content,
.modal-body {
  border: none;
  background: none;
}
.modal.show {
  display: block;
}
.fa-spin {
  font-size: 8rem;
  // color: #343a40;
}
</style>
