import { Module } from 'vuex';
import { RootState } from '../types';
import { SysAdminState } from './types';
import { CognitoUser } from '@/models/cognitouser.model';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

/**
 * Default state
 */
export const state: SysAdminState = {
    list: [],
    updates: [],
    current: new CognitoUser(),
    groups: [],
};

/**
 * Use namespaced Vuex modules
 */
const namespaced: boolean = true;

/**
 * Module export
 */
const sysadmin: Module<SysAdminState, RootState> = {
    state,
    getters,
    mutations,
    actions,
    namespaced,
};

export default sysadmin;
