import { Office } from '@/models';
import _ from 'lodash';
import Vue from 'vue';
import { MutationTree } from 'vuex';
import { AuthState } from './types';

/**
 * Mutations
 *
 * The only way to actually change state in a Vuex store is by committing a mutation.
 * - Vuex mutations are very similar to events: each mutation has a string type and a handler.
 * - The handler function is where we perform actual state modifications - it receives the state as the first argument:
 * - You cannot directly call a mutation handler.
 * - Think of it more like event registration: "When a mutation with type X is triggered, call this handler."
 * - To invoke a mutation handler, you need to call store.commit with its type e.g. `store.commit('setUser', user)`
 */
export const mutations: MutationTree<AuthState> = {

  /**
   * Set the Cognito User
   *
   * @param state AuthState
   * @param report Report
   * @returns void
   */
  setCognitoUser(state: AuthState, cognitoUser: any): void {
    // console.log('setCognitoUser', cognitoUser);
    state.cognitoUser = cognitoUser;
  },

};
