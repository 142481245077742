<template>
  <div class="row">
    <div class="col-sm-2" />
    <div class="col-sm-10">
      <fieldset>
        <legend>Address</legend>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">C/o</label>
          <div class="col-sm-6">
            <input type="text" class="form-control" v-model.lazy="customerCo" />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label"
            >Address <span class="sr-only">Line 1</span></label
          >
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              v-model.lazy="customerAddressLine1"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label"
            ><span class="sr-only">Address Line 2</span></label
          >
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              v-model.lazy="customerAddressLine2"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">City</label>
          <div class="col-sm-3">
            <input
              type="text"
              class="form-control"
              v-model.lazy="customerAddressTown"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">County</label>
          <div class="col-sm-3">
            <input
              type="text"
              class="form-control"
              v-model.lazy="customerAddressCounty"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Postcode</label>
          <div class="col-sm-2">
            <input
              type="text"
              class="form-control"
              v-model.lazy="customerAddressPostcode"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Phone</label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              v-model.lazy="customerPhone"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Email</label>
          <div class="col-sm-6">
            <input
              type="text"
              class="form-control"
              v-model.lazy="customerEmail"
            />
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CustomerDictionary } from '@/models/customerdictionary.model';
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const customerdictionary = computed(() => store.getters['customerdictionary/current'] as CustomerDictionary);

const setCustomerDictionaryDeep = async (payload: { path: string; data: any }) => {
  await store.dispatch('customerdictionary/setCustomerDictionaryDeep', payload);
};

const customerCo = computed({
  get: () => customerdictionary.value.customer.co,
  set: (data: string) => setCustomerDictionaryDeep({ path: "customer.co", data }),
});

const customerAddressLine1 = computed({
  get: () => customerdictionary.value.customer.address.line1,
  set: (data: string) => setCustomerDictionaryDeep({ path: "customer.address.line1", data }),
});

const customerAddressLine2 = computed({
  get: () => customerdictionary.value.customer.address.line2,
  set: (data: string) => setCustomerDictionaryDeep({ path: "customer.address.line2", data }),
});

const customerAddressTown = computed({
  get: () => customerdictionary.value.customer.address.town,
  set: (data: string) => setCustomerDictionaryDeep({ path: "customer.address.town", data }),
});

const customerAddressCounty = computed({
  get: () => customerdictionary.value.customer.address.county,
  set: (data: string) => setCustomerDictionaryDeep({ path: "customer.address.county", data }),
});

const customerAddressPostcode = computed({
  get: () => customerdictionary.value.customer.address.postcode,
  set: (data: string) => setCustomerDictionaryDeep({ path: "customer.address.postcode", data }),
});

const customerPhone = computed({
  get: () => customerdictionary.value.customer.address.phone,
  set: (data: string) => setCustomerDictionaryDeep({ path: "customer.address.phone", data }),
});

const customerEmail = computed({
  get: () => customerdictionary.value.customer.address.email,
  set: (data: string) => setCustomerDictionaryDeep({ path: "customer.address.email", data }),
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.no-padding {
  padding: 0%;
}

.error-message {
  color: #dc3545;
}
</style>