import _get from 'lodash/get';

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

export class Constituent extends Base {

  public label: string;
  public value: number;

  /**
   * Constructor initialises the variables from the class Constituent
   * @param data - the data to construct a type with all properties of <Constituent>
   */
  public constructor(data?: Partial<Constituent>) {
    super(data);
    data = toCamelCase(data);

    this.label = _get(data, 'label', '');
    this.value = _get(data, 'value', 1);
  }

}
