// // Uncomment if we ever need to import the dictionary from last-saved JSON
import jsonData from './json/customerdictionary.json';

import _ from 'lodash';
import _cloneDeepWith from 'lodash/cloneDeepWith';
import { v4 as uuidv4 } from 'uuid';

import Vue from 'vue';
import { API } from 'aws-amplify';

import { ActionTree, ActionContext } from 'vuex';
import { CustomerDictionaryState } from './types';
import { RootState, } from '../types';
import {
  CustomerDictionary, DictionaryType, DictionaryItem,
  DictionaryConditionMatrix, Compliancequestion, Maintenanceflag,
  ReportTypeDictionary, DictionaryRoom
} from '@/models';

/**
 * Actions
 *
 * Actions are similar to mutations, the differences being that:
 * - Instead of mutating the state, actions commit mutations.
 * - Actions can contain arbitrary asynchronous operations.
 * - Actions are triggered with the store.dispatch method e.g. `store.dispatch('getSettings')`
 */
export const actions: ActionTree<CustomerDictionaryState, RootState> = {

  /**
   * Gets the Customer Dictionary
   * 
   * - The API returns the most recent revision of the Customer Dictionary
   * @param {CustomerDictionaryState} store 
   * @returns CustomerDictionary
   */
  async getCustomerDictionary(store: ActionContext<CustomerDictionaryState, any>, queryStringParameters?: { reporttype: string, companyname: string, branchname: string }): Promise<CustomerDictionary> {
    // Because we are making multiple calls to this endpoint each one needs a unique ID
    const requestId = `getCustomerDictionary-${uuidv4()}`;
    const init = {
      queryStringParameters: _.pickBy(_.pick(queryStringParameters, ['companyname', 'branchname'])),
    };
    store.commit('app/addRequest', requestId, { root: true });

    return API.get('RestAPI', '/customerdictionary', init)
      .then((data) => CustomerDictionary.parse(data))
      .then((customerdictionary: CustomerDictionary) => {
        store.commit('setCustomerDictionary', customerdictionary);
        if (queryStringParameters?.companyname === "default")
          store.commit('setDefaultDictionary', customerdictionary);
        if (queryStringParameters && queryStringParameters.reporttype && queryStringParameters.reporttype != 'property visit') {
          // For each  bespoke report, dictionary is mapped inside customerdictionary -> reporttypedictionaries collection
          if (customerdictionary) {
            let reporttypedictionary = customerdictionary.reporttypedictionaries.find((d: ReportTypeDictionary) => d.reporttype === queryStringParameters.reporttype);
            if (!reporttypedictionary) {
              // If disctionary is not present for given report type
              // we will need to initialise it using the default dictionary
              let defaultreporttypedictionary = store.state.defaultdictionary.reporttypedictionaries.find((d: ReportTypeDictionary) => d.reporttype === queryStringParameters.reporttype);
              if (!defaultreporttypedictionary)
                defaultreporttypedictionary = new ReportTypeDictionary;
              reporttypedictionary = defaultreporttypedictionary;
              reporttypedictionary.reporttype = queryStringParameters.reporttype;
              reporttypedictionary.rooms = _cloneDeepWith(defaultreporttypedictionary.rooms);
              reporttypedictionary.categories = _cloneDeepWith(defaultreporttypedictionary.categories);
              reporttypedictionary.sections = _cloneDeepWith(defaultreporttypedictionary.sections);
              reporttypedictionary.compliance_list = _cloneDeepWith(defaultreporttypedictionary.compliance_list);
              reporttypedictionary.maintenance_flags = _cloneDeepWith(defaultreporttypedictionary.maintenance_flags);
              reporttypedictionary.report_configuration = _cloneDeepWith(defaultreporttypedictionary.report_configuration);
              store.commit('addReportTypeDictionary', reporttypedictionary);
            }
            store.commit('setCurrentDictionary', reporttypedictionary);
          }
        }
        else {
          // For PV report continue using root customerdictionary
          store.commit('setCurrentDictionary', customerdictionary);
        }
        return customerdictionary;
      })
      .finally(() => store.commit('app/removeRequest', requestId, { root: true }));
  },

  /**
   * Add a CustomerDictionary
   * 
   * - Adds an entire record, when we GET we return the most recent and retain the rest for posterity
   * @param {CustomerDictionaryState} store 
   * @param queryStringParameters.reorder boolean
   * @returns CustomerDictionary
   */
  async addCustomerDictionary(store: ActionContext<CustomerDictionaryState, any>, queryStringParameters?: { reorder: boolean }): Promise<CustomerDictionary> {
    const customerdictionary = store.state.customerdictionary;
    let cloneddictionary = customerdictionary;

    if (customerdictionary.company_name != 'default') {
      cloneddictionary = new CustomerDictionary();
      cloneddictionary.rooms = customerdictionary.rooms;
      cloneddictionary.sections = customerdictionary.sections;
      cloneddictionary.categories = customerdictionary.categories;
      cloneddictionary.compliance_list = customerdictionary.compliance_list;
      cloneddictionary.maintenance_flags = customerdictionary.maintenance_flags;
      cloneddictionary.company_name = customerdictionary.company_name;
      cloneddictionary.branch_name = customerdictionary.branch_name;
      cloneddictionary.report_configuration = customerdictionary.report_configuration;
      cloneddictionary.customer = customerdictionary.customer;
      cloneddictionary.reporttypedictionaries = customerdictionary.reporttypedictionaries;
    }

    const init = {
      body: cloneddictionary.toJSON(),
      queryStringParameters: _.pickBy(_.pick(queryStringParameters, ['reorder'])),
    };
    store.commit('app/addRequest', 'getCustomerDictionary', { root: true });
    return API.post('RestAPI', `/customerdictionary`, init)
      .then((data) => CustomerDictionary.parse(data))
      .then((customerdictionary: CustomerDictionary) => {
        store.commit('resetUnsavedChanges');
        return customerdictionary;
      })
      .finally(() => store.commit('app/removeRequest', 'getCustomerDictionary', { root: true }));
  },

  /**
   * Sets any deep dictionary property and increment the "unsaved changes" count
   * 
   * @param {CustomerDictionaryState} store 
   * @param data
   * @returns void
   */
  setCustomerDictionaryDeep(store: ActionContext<CustomerDictionaryState, any>, data: any) {
    store.commit('setCustomerDictionaryDeep', data);
    store.commit('addUnsavedChange');
  },

  /**
   * Add Type and increment the "unsaved changes" count
   * 
   * @param {CustomerDictionaryState} store 
   * @param {DictionaryType} type
   * @returns void
   */
  addType(store: ActionContext<CustomerDictionaryState, any>, type: DictionaryType) {
    store.commit('addType', type);
    store.commit('addUnsavedChange');
  },

  /**
   * Update Type and increment the "unsaved changes" count
   * 
   * @param {CustomerDictionaryState} store 
   * @param {DictionaryType} type
   * @returns void
   */
  updateType(store: ActionContext<CustomerDictionaryState, any>, type: DictionaryType) {
    store.commit('updateType', type);
    store.commit('addUnsavedChange');
  },

  /**
   * Remove Type and increment the "unsaved changes" count
   * 
   * @param {CustomerDictionaryState} store
   * @param {DictionaryType} type
   * @returns void
   */
  removeType(store: ActionContext<CustomerDictionaryState, any>, type: DictionaryType) {
    store.commit('removeType', type);
    store.commit('addUnsavedChange');
  },

  /**
   * Add Item and increment the "unsaved changes" count
   * 
   * @param {CustomerDictionaryState} store
   * @param {DictionaryItem} item
   * @returns void
   */
  addItem(store: ActionContext<CustomerDictionaryState, any>, item: DictionaryItem) {
    store.commit('addItem', item);
    store.commit('addUnsavedChange');
  },

  /**
   * Update Item and increment the "unsaved changes" count
   * 
   * @param {CustomerDictionaryState} store
   * @param {DictionaryItem} item
   * @returns void
   */
  updateItem(store: ActionContext<CustomerDictionaryState, any>, item: DictionaryItem) {
    store.commit('updateItem', item);
    store.commit('addUnsavedChange');
  },

  /**
   * Remove Item and increment the "unsaved changes" count
   * 
   * @param {CustomerDictionaryState} store
   * @param {DictionaryItem} item
   * @returns void
   */
  removeItem(store: ActionContext<CustomerDictionaryState, any>, item: DictionaryItem) {
    store.commit('removeItem', item);
    store.commit('addUnsavedChange');
  },

  /**
   * Set the Colours of the currently selected Item
   * 
   * @param {CustomerDictionaryState} store
   * @param {string[]} value
   * @returns void
   */
  setColours(store: ActionContext<CustomerDictionaryState, any>, value: string[]) {
    // Set the Colours for the currently selected Item
    store.commit('setItemProperty', {
      property: 'colours',
      value,
    });
    // Set the Colours for the "Misc" Item, which populates select boxes
    store.commit('setMiscItemProperty', {
      property: 'colours',
      value: _.uniq(store.getters.allColours.concat(value)),
    });
    store.commit('addUnsavedChange');
  },

  /**
   * Set the Conditions (Additional) of the currently selected Item
   * 
   * @param {CustomerDictionaryState} store
   * @param {string[]} conditions
   * @returns void
   */
  setConditions(store: ActionContext<CustomerDictionaryState, any>, conditions: string[]) {
    // Set the Conditions for the currently selected Item
    store.commit('setItemConditions', {
      property: 'additional',
      value: conditions,
    });
    store.commit('setMiscItemConditions', _.uniq(store.getters.allConditions.concat(conditions)));
    store.commit('addUnsavedChange');
  },

  /**
   * Set the complete Condition Matrix of the currently selected Item
   * 
   * @param {CustomerDictionaryState} store
   * @param {DictionaryConditionMatrix} matrix
   * @returns void
   */
  setConditionMatrix(store: ActionContext<CustomerDictionaryState, any>, matrix: DictionaryConditionMatrix[]) {
    store.commit('setItemConditions', {
      property: 'matrix',
      value: matrix,
    });
    store.commit('addUnsavedChange');
  },

  /**
   * Set a value of the Condition Matrix of the currently selected Item
   * 
   * @param {CustomerDictionaryState} store
   * @param {String} path
   * @param {String} value
   * @returns void
   */
  setConditionMatrixValue(store: ActionContext<CustomerDictionaryState, any>, payload: { path: string, value: string }) {
    let matrix = store.getters.activeConditionMatrix;
    store.commit('setItemConditions', {
      property: 'matrix',
      value: _.set(matrix, payload.path, payload.value),
    });
    store.commit('setMiscItemConditions', Object.assign([], store.getters.allConditions.concat([payload.value])));
    store.commit('addUnsavedChange');
  },

  /**
   * Set the Constituents of the currently selected Item
   * 
   * @param {CustomerDictionaryState} store
   * @param {string[]} value
   * @returns void
   */
  setConstituents(store: ActionContext<CustomerDictionaryState, any>, value: string[]) {
    store.commit('setItemProperty', {
      property: 'constituents',
      value,
    });
    // Set the Constituents for the "Misc" Item, which populates select boxes
    store.commit('setMiscItemProperty', {
      property: 'constituents',
      value: _.uniq(store.getters.allConstituents.concat(value)),
    });
    store.commit('addUnsavedChange');
  },

  /**
   * Set the Makes of the currently selected Item
   * 
   * @param {CustomerDictionaryState} store
   * @param {string[]} value
   * @returns void
   */
  setMakes(store: ActionContext<CustomerDictionaryState, any>, value: string[]) {
    store.commit('setItemProperty', {
      property: 'makes',
      value,
    });
    // Set the Makes for the "Misc" Item, which populates select boxes
    store.commit('setMiscItemProperty', {
      property: 'makes',
      value: _.uniq(store.getters.allMakes.concat(value)),
    });
    store.commit('addUnsavedChange');
  },

  /**
   * Set company name
   * 
   * @param {CustomerDictionaryState} store
   * @param {string[]} value
   * @returns void
   */
  setCompanyname(store: ActionContext<CustomerDictionaryState, any>, value: string) {
    store.commit('updateCompanyname', value);
    store.commit('addUnsavedChange');
  },

  /**
   * Set branch name
   * 
   * @param {CustomerDictionaryState} store
   * @param {string[]} value
   * @returns void
   */
  setBranchname(store: ActionContext<CustomerDictionaryState, any>, value: string) {
    store.commit('updateBranchname', value);
    store.commit('addUnsavedChange');
  },

  /**
   *  Reset all back to initial state
   */
  resetAll(store: ActionContext<CustomerDictionaryState, any>) {
    store.commit('resetAll');
  },

  /**
   *  Add to room list
   */
  addToRooms(store: ActionContext<CustomerDictionaryState, any>, room: DictionaryRoom): void {
    store.commit('addToRooms', room);
    store.commit('addUnsavedChange');
  },

  /**
   * Remove room from room list
   * 
   * @param state 
   * @param payload 
   */
  removeFromRooms(store: ActionContext<CustomerDictionaryState, any>, room: DictionaryRoom): void {
    store.commit('removeFromRooms', room);
    store.commit('addUnsavedChange');
  },

  /**
   * Set room list collection
   * @param store
   * @param data
   */
  setRoomList(store: ActionContext<CustomerDictionaryState, any>, data): void {
    store.commit('setRoomList', data);
    store.commit('addUnsavedChange');
  },


  /**
   * Set compliance list collection
   * @param store
   * @param data
   */
  setComplianceList(store: ActionContext<CustomerDictionaryState, any>, data): void {
    store.commit('setComplianceList', data);
    store.commit('addUnsavedChange');
  },

  /**
   *  Add to compliance question list
   */
  addToComplianceList(store: ActionContext<CustomerDictionaryState, any>, question: Compliancequestion): void {
    store.commit('addToComplianceList', question);
  },

  /**
   * Remove question from compliance question list
   * 
   * @param state 
   * @param payload 
   */
  removeFromComplianceList(store: ActionContext<CustomerDictionaryState, any>, question: Compliancequestion): void {
    store.commit('removeFromComplianceList', question);
  },

  /**
   * Set maintenanceflags collection
   * @param store
   * @param data
   */
  setMaintenanceflags(store: ActionContext<CustomerDictionaryState, any>, data): void {
    store.commit('setMaintenanceflags', data);
    store.commit('addUnsavedChange');
  },

  /**
   *  Add to maintenance flag list
   */
  addToMaintenanceflags(store: ActionContext<CustomerDictionaryState, any>, question: Maintenanceflag): void {
    store.commit('addToMaintenanceflags', question);
    store.commit('addUnsavedChange');
  },

  /**
   * Remove flag from maintenance flag list
   * 
   * @param state 
   * @param payload 
   */
  removeFromMaintenanceflags(store: ActionContext<CustomerDictionaryState, any>, flag: Maintenanceflag): void {
    store.commit('removeFromMaintenanceflags', flag);
    store.commit('addUnsavedChange');
  },

  /**
   * Sets any deep customerdictionary property and increments the "unsaved changes" count
   * @param store
   * @param data
   */
  setCustomerdictionaryDeep(store: ActionContext<CustomerDictionaryState, any>, data): void {
    store.commit('setCustomerdictionaryDeep', data);
    store.commit('addUnsavedChange');
  },

  /**
   * Set TNC for given customer
   * @param store
   * @param data 
   */
  setTNC(store: ActionContext<CustomerDictionaryState, any>, data): void {
    store.commit('setTNC', data);
    store.commit('addUnsavedChange');
  },

  /**
   * Set primarycolour for given customer
   * @param store
   * @param data 
   */
  setPrimarycolour(store: ActionContext<CustomerDictionaryState, any>, data): void {
    store.commit('setPrimarycolour', data);
    store.commit('addUnsavedChange');
  },

  /**
   * Set secondarycolour for given customer
   * @param store
   * @param data 
   */
  setSecondarycolour(store: ActionContext<CustomerDictionaryState, any>, data): void {
    store.commit('setSecondarycolour', data);
    store.commit('addUnsavedChange');
  },
};
