<template>
  <nav>
    <ul class="pagination">
      <li
        v-for="(link, $index) in links"
        :key="$index"
        class="page-item page-link"
        :class="{ selected: link === selected }"
        @click="filter(link)"
      >
        {{ link.toUpperCase() }}
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import { defineEmits, onMounted, ref } from 'vue';
import locations from "@/assets/json/locations.json";

const links = ref<string[]>([]);
const selected = ref<string>('');

const emit = defineEmits(['filter'])

const filter = (starts_with: string) => {
  selected.value = starts_with;
  emit('filter', starts_with);
};

onMounted(() => {
  if (locations.length) {
    let set = new Set(
      locations.map((l: any) => String(l.code).substring(0, 1).toLowerCase())
    );
    let unsorted = Array.from(set);
    links.value = unsorted.sort((code1: string, code2: string) =>
      code1.localeCompare(code2)
    );
    filter(links.value[0]);
  }
});
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.selected {
  z-index: 1;
  color: #fff;
  background-color: #253746;
  border-color: #253746;
}
</style>
