import { snakeCase } from 'lodash';
import { copyInto } from '@/utilities';
import { DictionaryConditionMatrix } from '@/models';

export class DictionaryItem {

  public id: string;
  public colours: string[];
  public conditions: { matrix: DictionaryConditionMatrix[], additional: string[] };
  public constituents: string[];
  public makes: string[];
  public name: string;
  public slug: string;

  public fixflocategory: string;
  public fixflosubcategory: string;

  /**
   * Create an object from a JSON representation
   * @param data
   * @return Report
   */
  public static parse(data: object) {
    const obj = new DictionaryItem;

    copyInto(obj, data);

    if (!obj.slug) {
      obj.slug = snakeCase(obj.name);
    }

    return obj;
  }

  /**
   * Constructor
   */
  public constructor() {
    this.colours = [];
    this.conditions = {
      matrix: [
        new DictionaryConditionMatrix,
        new DictionaryConditionMatrix,
        new DictionaryConditionMatrix,
      ],
      additional: [],
    };
    this.constituents = [];
    this.makes = [];
    this.name = '';
    this.slug = '';
    this.fixflocategory = '';
    this.fixflosubcategory = '';
  }

}
