import _ from 'lodash';
import Vue from 'vue';

import _snakeCase from 'lodash/snakeCase';

import { MutationTree } from 'vuex';
import { CustomersState } from './types';
import { Customer } from '@/models/customer.model';

/**
 * Mutations
 *
 * The only way to actually change state in a Vuex store is by committing a mutation.
 * - Vuex mutations are very similar to events: each mutation has a string type and a handler.
 * - The handler function is where we perform actual state modifications - it receives the state as the first argument:
 * - You cannot directly call a mutation handler.
 * - Think of it more like event registration: "When a mutation with type X is triggered, call this handler."
 * - To invoke a mutation handler, you need to call store.commit with its type
 * - e.g. `store.commit('setCustomers', customer)`
 */
export const mutations: MutationTree<CustomersState> = {

  /**
   * Set the report currently being viewed/edited
   *
   * @param state CustomersState
   * @param customer Customer
   * @returns void
   */
  setCurrentCustomer(state: CustomersState, customer: Customer): void {
    state.current = customer;
  },

  /**
   * Set the customers list
   *
   * @param state CustomersState
   * @param customer Customer
   * @returns void
   */
  setCustomers(state: CustomersState, customers: Customer[]): void {
    state.list = customers;
  },

  /**
   * Set the currently edited/viewed customer
   * 
   * @param state CustomersState
   * @param customer Customer
   * @returns void
   */
  setCustomer(state: CustomersState, customer: Customer): void {
    state.current = customer;
  },

  /**
   * Set the currently edited/viewed customer
   * 
   * @param state CustomersState
   * @param payload.path string
   * @param payload.data 
   * @returns void
   */
  setCustomerDeep(state: CustomersState, payload: { path: string, data: any }): void {
    _.set(state.current, payload.path, payload.data);
  },

  /**
   * Adds a Establishment
   */
  addEstablishment(state: CustomersState, establishment: Customer): void {
    let establishments: Customer[] = state.current.establishments;
    if (!establishments)
      establishments = [];
    _.set(state.current, `establishments`, establishments.concat(new Customer(establishment)));
  },

  /**
   * Remove Establishment from the given list
   * 
   * @param state 
   * @param flag 
   */
  removeEstablishment(state: CustomersState, establishment: Customer): void {
    let establishments: Customer[] = state.current.establishments;
    if (establishments) {
      let index = establishments.findIndex((e) => e.id == establishment.id);
      if (index >= 0) {
        establishments.splice(index, 1);
        _.set(state.current, `establishments`, establishments);
      }
    }
  },


  /**
   * ++ Unsaved changes
   * 
   * @param state CustomersState
   * @param element string
   * @returns void
   */
  addUnsavedChange(state: CustomersState, element: string): void {
    state.updates = [...new Set(state.updates.concat([element]))];
  },

  /**
   * Reset Unsaved changes
   * 
   * @param state CustomersState
   * @returns void
   */
  resetUnsavedChanges(state: CustomersState): void {
    state.updates = [];
  },
};
