<template>
  <div class="form-group row ml-4">
    <div class="col-sm-3">
      <legend>
        <span class="subtitle">Logo</span>
      </legend>
      <table class="table table-sm table-borderless">
        <!-- Logo Image -->
        <Logos
          :photos="getLogos()"
          :key="`logo${getLogoKey()}`"
          :path="'report_configuration.logo'"
          :target="'customerdictionary'"
        />
      </table>
    </div>
    <div class="col-sm-3">
      <legend>
        <span class="subtitle">Colours</span>
      </legend>

      <div class="form-group">
        <div class="row">
          <div class="col-sm-8">
            <label class="col-form-label">Primary colour</label>
          </div>
        </div>
        <div class="row">
           <div class="col-sm-6 d-flex align-items-center">
                <input type="color" v-model="primarycolour" class="form-control" style="width: 75px; margin-right: 2px;">
                <input type="text"  v-model.lazy="primarycolour" class="form-control" style="width: 100px;">
            </div>
        </div>

        <div class="row">
          <div class="col-sm-8">
            <label class="col-form-label">Secondary colour</label>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 d-flex align-items-center">
            <input type="color" v-model="secondarycolour" class="form-control" style="width: 75px; margin-right: 2px;">
            <input type="text"  v-model.lazy="secondarycolour" class="form-control" style="width: 100px;">
          </div>
        </div>

        <div class="row">
          <div class="col-sm-8">
            <label class="col-form-label">Background colour</label>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 d-flex align-items-center">
            <input type="color" v-model="backgroundcolour" class="form-control" style="width: 75px; margin-right: 2px;">
            <input type="text"  v-model.lazy="backgroundcolour" class="form-control" style="width: 100px;">
          </div>
        </div>

        <div class="row">
          <div class="col-sm-8">
            <label class="col-form-label"
              >Frontpage company address colour</label
            >
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 d-flex align-items-center">
            <input type="color" v-model="frontpagetextcolour" class="form-control" style="width: 75px; margin-right: 2px;">
            <input type="text"  v-model.lazy="frontpagetextcolour" class="form-control" style="width: 100px;">
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-3">
      <fieldset>
        <legend>Switches</legend>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label">Show meters</label>
          <div class="col-sm-6">
            <DxSwitch :value="showmeters" @value-changed="showmeters = $event.value"/>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label">Show keys</label>
          <div class="col-sm-6">
            <DxSwitch :value="showkeys" @value-changed="showkeys = $event.value"/>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label">Show alarms</label>
          <div class="col-sm-6">
            <DxSwitch :value="showalarms" @value-changed="showalarms = $event.value"/>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label"
            >Show appliances and manuals</label
          >
          <div class="col-sm-6">
            <DxSwitch :value="showappliancesandmanuals" @value-changed="showappliancesandmanuals = $event.value"/>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label">Show item lozenges</label>
          <div class="col-sm-6">
            <DxSwitch :value="showitemlozenges" @value-changed="showitemlozenges = $event.value"/>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label"
            >Hide room list on alarms</label
          >
          <div class="col-sm-6">
            <DxSwitch :value="hideroomsonalarms" @value-changed="hideroomsonalarms = $event.value"/>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="col-sm-3">
      <fieldset>
        <legend>Show on main page</legend>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label"
            >Show meters on main page</label
          >
          <div class="col-sm-6">
            <DxSwitch :value="showmetersonhomepage" @value-changed="showmetersonhomepage = $event.value"/>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label">Show keys on main page</label>
          <div class="col-sm-6">
            <DxSwitch :value="showkeysonhomepage" @value-changed="showkeysonhomepage = $event.value"/>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label"
            >Show alarms on main page</label
          >
          <div class="col-sm-6">
            <DxSwitch :value="showalarmsonhomepage" @value-changed="showalarmsonhomepage = $event.value"/>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label"
            >Show appliances and manuals on main page</label
          >
          <div class="col-sm-6">
            <DxSwitch :value="showappliancesandmanualsonhomepage" @value-changed="showappliancesandmanualsonhomepage = $event.value"/>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label"
            >Show general notes on main page</label
          >
          <div class="col-sm-6">
            <DxSwitch :value="showgeneralnotesonhomepage" @value-changed="showgeneralnotesonhomepage = $event.value"/>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label"
            >Show compliance sections on main page</label
          >
          <div class="col-sm-6">
            <DxSwitch :value="showcompliancesectionsonhomepage" @value-changed="showcompliancesectionsonhomepage = $event.value"/>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script setup lang="ts">
import _ from "lodash";

import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Logos from '@/components/logo/Logos.vue';
import { DxSwitch } from 'devextreme-vue/switch';
import { CustomerDictionary, Photo } from "@/models";

const store = useStore();
const router = useRouter();
const validColorCode = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i;

const customerdictionary = computed((): CustomerDictionary => store.getters['customerdictionary/current']);

const setCustomerDictionaryDeep = async (payload: {
    path: string;
    data: any;
  }) => {
  await store.dispatch('customerdictionary/setCustomerDictionaryDeep', payload);
};

const primarycolour = computed({
  get: () => customerdictionary.value.report_configuration.primarycolour,
  set: value => {
    if(validColorCode.test(value)){
      setCustomerDictionaryDeep({
        path: "report_configuration.primarycolour",
        data: value,
      });
    } else {
      setCustomerDictionaryDeep({
        path: "report_configuration.primarycolour",
        data: customerdictionary.value.report_configuration.primarycolour,
      });
    }  
  }
});
const secondarycolour = computed({
  get: () => customerdictionary.value.report_configuration.secondarycolour,
  set: value => {
    if(validColorCode.test(value)){
      setCustomerDictionaryDeep({
        path: "report_configuration.secondarycolour",
        data: value,
      });
    } else {
      setCustomerDictionaryDeep({
        path: "report_configuration.secondarycolour",
        data: customerdictionary.value.report_configuration.secondarycolour,
      });
    }  
  }
});

const backgroundcolour = computed({
  get: () => customerdictionary.value.report_configuration.backgroundcolour,
  set: value => {
    if(validColorCode.test(value)){
      setCustomerDictionaryDeep({
        path: "report_configuration.backgroundcolour",
        data: value,
      });
    } else {
      setCustomerDictionaryDeep({
        path: "report_configuration.backgroundcolour",
        data: customerdictionary.value.report_configuration.backgroundcolour,
      });
    }  
  }
});

const frontpagetextcolour = computed({
  get: () => customerdictionary.value.report_configuration.frontpagetextcolour,
  set: value => {
    if(validColorCode.test(value)){
      setCustomerDictionaryDeep({
        path: "report_configuration.frontpagetextcolour",
        data: value,
      });
    } else {
      setCustomerDictionaryDeep({
        path: "report_configuration.frontpagetextcolour",
        data: customerdictionary.value.report_configuration.frontpagetextcolour,
      });
    }  
  }
});

const showtenants = computed({
  get: () => customerdictionary.value.report_configuration.showtenants,
  set: value => setCustomerDictionaryDeep({
      path: "report_configuration.showtenants",
      data: value,
    })
});

const showmeters = computed({
  get: () => customerdictionary.value.report_configuration.showmeters,
  set: value => setCustomerDictionaryDeep({
      path: "report_configuration.showmeters",
      data: value,
    })
});

const showkeys = computed({
  get: () => customerdictionary.value.report_configuration.showkeys,
  set: value => setCustomerDictionaryDeep({
      path: "report_configuration.showkeys",
      data: value,
    })
});

const showalarms = computed({
  get: () => customerdictionary.value.report_configuration.showalarms,
  set: value => setCustomerDictionaryDeep({
      path: "report_configuration.showalarms",
      data: value,
    })
});

const showappliancesandmanuals = computed({
  get: () => customerdictionary.value.report_configuration.showappliancesandmanuals,
  set: value => setCustomerDictionaryDeep({
      path: "report_configuration.showappliancesandmanuals",
      data: value,
    })
});

const showgeneralnotesonhomepage = computed({
  get: () => customerdictionary.value.report_configuration.showgeneralnotesonhomepage,
  set: value => setCustomerDictionaryDeep({
      path: "report_configuration.showgeneralnotesonhomepage",
      data: value,
    })
});

const showtenantsonhomepage = computed({
  get: () => customerdictionary.value.report_configuration.showtenantsonhomepage,
  set: value => setCustomerDictionaryDeep({
      path: "report_configuration.showtenantsonhomepage",
      data: value,
    })
});

const showmetersonhomepage = computed({
  get: () => customerdictionary.value.report_configuration.showmetersonhomepage,
  set: value => setCustomerDictionaryDeep({
      path: "report_configuration.showmetersonhomepage",
      data: value,
    })
});

const showkeysonhomepage = computed({
  get: () => customerdictionary.value.report_configuration.showkeysonhomepage,
  set: value => setCustomerDictionaryDeep({
      path: "report_configuration.showkeysonhomepage",
      data: value,
    })
});

const showalarmsonhomepage = computed({
  get: () => customerdictionary.value.report_configuration.showalarmsonhomepage,
  set: value => setCustomerDictionaryDeep({
      path: "report_configuration.showalarmsonhomepage",
      data: value,
    })
});

const showappliancesandmanualsonhomepage = computed({
  get: () => customerdictionary.value.report_configuration.showappliancesandmanualsonhomepage,
  set: value => setCustomerDictionaryDeep({
      path: "report_configuration.showappliancesandmanualsonhomepage",
      data: value,
    })
});

const showitemlozenges = computed({
  get: () => customerdictionary.value.report_configuration.showitemlozenges,
  set: value => setCustomerDictionaryDeep({
      path: "report_configuration.showitemlozenges",
      data: value,
    })
});

const showcompliancesectionsonhomepage = computed({
  get: () => customerdictionary.value.report_configuration.showcompliancesectionsonhomepage,
  set: value => setCustomerDictionaryDeep({
      path: "report_configuration.showcompliancesectionsonhomepage",
      data: value,
    })
});

const hideroomsonalarms = computed({
  get: () => customerdictionary.value.report_configuration.hideroomsonalarms,
  set: value => setCustomerDictionaryDeep({
      path: "report_configuration.hideroomsonalarms",
      data: value,
    })
});

const getLogos = () => {
  return _.get(customerdictionary.value, 'report_configuration.logo', []);
};
const getLogoKey = () => {
  const logos = getLogos();
  return logos.map((l: Photo) => l.src).concat('-');
};

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
legend .subtitle {
  font-size: 80%;
}
.file-preview {
  width: 300px !important;
}
</style>
