import _ from 'lodash';

import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { ReportsState } from './types';

import { Report } from '@/models/report.model';
import { Fee, Item, Room, Section, Type } from '@/models';

/**
 * Getters
 *
 * Vuex allows us to define "getters" in the store.
 * - You can think of them as computed properties for stores.
 * - Like computed properties, a getter's result is cached based on its dependencies,
 *   and will only re-evaluate when some of its dependencies have changed.
 */
export const getters: GetterTree<ReportsState, RootState> = {

  /**
   * The currently-queried list of reports, returned from GET {API_URL}/reports
   */
  list: (state: ReportsState): Report[] => state.list,

  /**
   * The postcode search list of reports, returned from GET getaddress api
   */
  postcodesearchlist: (state: ReportsState): Report[] => state.postcodesearchlist,


  /**
   * The Linked Reports of the currently queried Report, returned from GET {API_URL}/reports?linked=XXXX
   */
  linked: (state: ReportsState): Report[] => state.linked,

  /**
   * The current Report, created fresh or returned from GET {API_URL}/report
   */
  current: (state: ReportsState): Report => state.current,

  /**
   * The updates which have been made since creating/loading/last-saving the current report
   */
  updates: (state: ReportsState): string[] => state.updates,

  /**
   * The updates which have been made since creating/loading/last-saving the current report
   */
  hasUnsavedChanges: (state: ReportsState): boolean => state.updates.length > 0,

  /**
   * The list of rooms which the current report has
   * - This sets the available <option>s in "+ Item" modal
   */
  currentReportRooms: (state: ReportsState): Room[] => _.uniq(state.current.rooms),

  /**
   * Currently edited room
   */
  currentroom: (state: ReportsState): Room => state.currentroom,

  /**
   * The list of rooms which can be copied into the current report
   * - Defaults to the Rooms of the current Report
   * - Set to the Rooms of the source Report, if populated
   */
  roomsToCopy: (state: ReportsState): Room[] => {
    if (_.get(state, 'source.ref')) {
      return state.source.rooms;
    } else {
      return state.current.rooms;
    }
  },

  /**
   * The list of sections which the current report has
   * - This sets the available <option>s in "+ Item" modal
   */
  currentReportSections: (state: ReportsState): Section[] => {
    return state.current.rooms.flatMap((room: Room) => room.sections);
  },

  /**
   * The list of types which the current report has
   * - This sets the available <option>s in "+ Item" modal
   */
  currentReportTypes: (state: ReportsState, getters: any): Type[] => {
    return getters.currentReportSections.flatMap((section: Section) => section.types);
  },

  /**
   * The list of items which the current report has
   * - This sets the available <option>s in "+ Item" modal
   */
  currentReportItems: (state: ReportsState, getters: any): Item[] => {
    return getters.currentReportTypes.flatMap((type: Type) => type.items);
  },

  /**
   * The number (order) of the Item in the Report
   */
  itemNumber: (state: ReportsState, getters: any) => (item: any): number => {
    return getters.currentReportItems.findIndex((x: Item) => x._uuid === item._uuid) + 1;
  },

  /**
   * The current office
   */
  currentoffice: (state: ReportsState): string => state.currentoffice,

  /**
   * The current office
   */
  gallerydisplaymode: (state: ReportsState): string => state.gallerydisplaymode,


};
