<template>
  <div class="report-section manuals">

    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th>Name</th>
          <th width="20%">Make</th>
          <th width="25%">Model</th>
          <th width="15%">Serial No.</th>
          <th width="10%" v-if="isCheckOut()">Seen</th>
          <th width="5%"><span class="sr-only">Actions</span></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(manual, $index) in manuals" :key="manual._uuid">
          <td><input v-model.lazy=manual.name class="form-control form-control-sm" size="10" @change="editManuals(manual,$index)" /></td>
          <td><input v-model.lazy=manual.make class="form-control form-control-sm" size="10" @change="editManuals(manual,$index)"/></td>
          <td><input v-model.lazy=manual.model class="form-control form-control-sm" size="10" @change="editManuals(manual,$index)" /></td>
          <td><input v-model.lazy=manual.serial class="form-control form-control-sm" size="5" @change="editManuals(manual,$index)" /></td>
          <td v-if="isCheckOut()" class="text-center">
            <div>
              <input @click="setManualInteractedAndSeen(manual,$index)" class="form-check-input" id="seen" type="radio" :checked="manual.interacted && manual.seen">
              <label for="seen">Seen</label>
            </div>
            <div>
              <input @click="setManualInteractedAndNotSeen(manual,$index)" class="form-check-input" id="not_seen" type="radio" :checked="manual.interacted && !manual.seen">
              <label for="not_seen">Not Seen</label>
            </div>
          </td>
          <td>
            <button class="btn btn-sm btn-outline-danger" @click="removeManual($index)" tabindex="-1">
              <i class="fas fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <button class="btn btn-primary" @click="addNewManual()">
      <i class="fas fa-plus"></i> Manual
    </button>

  </div>
</template>

<script lang="ts" setup>
import { computed, ref, inject, defineProps } from 'vue';
import { useStore } from 'vuex';
import { Report, Manual } from '@/models';

const actProperty: any = inject('actProperty');

// Vuex Store
const store = useStore();

// Computed properties and methods
const report = computed((): Report => store.getters['reports/current']);

const manuals = computed({
  get: (): Manual[] => report.value.manuals,
  set: (data: Manual[]) => setReportDeep({ path: 'manuals', data })
});

const setReportDeep = (payload: {path: string, data: any}): Promise<any> => {
  return store.dispatch('reports/setReportDeep', payload);
};

const isCheckOut = () => report.value.type == 'checkout';

const addNewManual = () => {
  manuals.value = [...manuals.value, new Manual()];
};

const removeManual = (index: number) => {
  actProperty.confirmPrompt()
      .then(() => manuals.value = actProperty.removeItemByIndex(manuals.value, index));
};

const setManualInteractedAndSeen = (manual: Manual,index:Number) => {
  manual.interacted = true;
  manual.seen = true;
  editManuals(manual,index);
};

const setManualInteractedAndNotSeen = (manual: Manual,index:Number) => {
  manual.interacted = true;
  manual.seen = false;
  editManuals(manual,index);
};
const editManuals = (manual:Manual,index:Number) => {
 setReportDeep({
    path: `manuals[${index}]`,
    data:manual
  });
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
label {
  margin: 0;
}
</style>
