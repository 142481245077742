<template>
  <div :id="id" class="modal" data-keyboard="true" tabindex="-1" role="dialog" aria-labelledby="`delete-note-model-label`"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="delete-note-model-label">
            <span v-if="operation === 'edit-scheduler'">Edit recurring event</span>
            <span v-if="operation === 'edit'">Edit recurring event</span>
            <span v-if="operation === 'deletes'">Delete recurring event</span>
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="hide">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div id="delete-note-model-body" :key="radiovalue">
            <div class="wrapper">
              <label class="btn_container">
                <input type="radio" class="thisevent" name="radio" id="thisevent" v-model="radiovalue"
                  :checked="radiovalue === 'thisevent'" @click="emitSelect('thisevent')" />
                <label for="seen" class="seen" @click="emitLabel('thisevent')">This Event</label>
              </label><br />
              <label class="btn_container">
                <input type="radio" class="thisandfollowingevent" name="radio" id="thisandfollowingevent"
                  v-model="radiovalue" :checked="radiovalue === 'thisandfollowingevent'"
                  @click="emitSelect('thisandfollowingevent')" />
                <label for="seen" class="seen" @click="emitLabel('thisandfollowingevent')">This and following
                  events</label>
              </label><br />
              <label class="btn_container">
                <input type="radio" id="allevents" class="allevents" name="radio" v-model="radiovalue"
                  :checked="radiovalue === 'allevents'" @click="emitSelect('allevents')" />
                <label for="seen" class="seen" @click="emitLabel('allevents')">All events</label>
              </label><br />
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" @click.prevent="hide">
            Cancel
          </button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" @click.prevent="saveAll()"
            :disabled="!radiovalue">
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import $ from 'jquery';
import _get from 'lodash/get';
import { ref, computed, PropType, inject, defineEmits, defineExpose, defineProps } from 'vue';
import { useStore } from 'vuex';
// Import your models or other external methods
import { Booking } from "@/models";
const emit = defineEmits(['select','selectedit']);
const store = useStore();
const operation = ref("");
// Props
const props = defineProps({
  booking: { type: Object as PropType<Booking> },
  id: String
});
const saveBooking = (booking: Booking): Promise<any> => {
  return store.dispatch('diary/saveBooking', booking);
};

const realtime: any = inject('realtime');
const actProperty: any = inject('actProperty');

const channel = realtime.channels.get('diary');
const deviceid = actProperty.getDeviceId();

const booking = ref(props.booking);
const copybooking = ref(props.booking);

const radiovalue = ref('');
const titlenoteschanged = ref(false);

// Methods
const init = (bookingData: Booking) => {
  radiovalue.value = '';
  booking.value = bookingData;
};

const show = () => {
  if ($(`#${props.id}` + 'Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.id + 'Backdrop"></div>');
    $('body').append(backdropDiv);
    $(`#${props.id}`).show();
  }
}
const hide = () => {
  if ($(`#${props.id}`).length > 0) {
    $(`#${props.id}` + 'Backdrop').remove();
    if (titlenoteschanged.value) {
      saveBooking(booking.value).then((b: Booking) => {
        channel.publish('appointmentChanged', { deviceid: deviceid, bookingid: b.id, source: 'Scheduler' });
        $(`#${props.id}`).hide();
      });
    }
    $(`#${props.id}`).hide();
  }
};
// const show = () => {
//   if ($('#delete-note-model-Backdrop').length == 0) {
//     const backdropDiv = $('<div class="modal-backdrop fade show" id="delete-note-model-Backdrop"></div>');
//     $('body').append(backdropDiv);
//     let el = $(`#delete-note-model`);
//     el.show();
//   }
// }


// const hide = () => {
//   if ($('#delete-note-model-Backdrop').length > 0) {
//     $('#delete-note-model-Backdrop').remove();
//   }
//   if (titlenoteschanged.value) {
//     saveBooking(booking.value).then((b: Booking) => {
//       channel.publish('appointmentChanged', { deviceid: deviceid, bookingid: b.id, source: 'Scheduler' });
//       $(`#delete-note-model`).hide();
//     });
//   }
//   else {
//     $(`#delete-note-model`).hide();
//   }
// };


const saveAll = () => {
  if(operation.value === 'edit-scheduler') {
    emit('selectedit', radiovalue.value, booking.value,copybooking.value);
  } else {
    emit('select', radiovalue.value, booking.value);
  }
}

const emitLabel = (val: string) => {
  radiovalue.value = val;
};

const emitSelect = (val: string) => {
  radiovalue.value = val;
};
const edit = () => {
  operation.value = "edit";
};

const editScheduler = () => {
  operation.value = "edit-scheduler";
};
const deletes = () => {
  operation.value = "deletes";
}

defineExpose({ init, show, hide, deletes, edit,editScheduler});
</script>


<style scoped lang="scss">
.modal-body {
  max-height: 50vh;
  overflow-y: scroll;
}

.seen {
  margin-left: 10px;
}</style>