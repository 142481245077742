<template>
  <div class="customer">
    <div class="user-header">
      <form>
        <fieldset>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">A/C Client Code</label>
            <div class="col-sm-3">
              <input type="text" class="form-control" v-model.lazy="customer.acClientCode"
                @change="editCurrentCustomer()" />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Nominal Code</label>
            <div class="col-sm-3">
              <input type="text" class="form-control" v-model.lazy="customer.nominalCode"
                @change="editCurrentCustomer()" />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Works/Purchase order number required</label>
            <div class="col-sm-4">
              <DxSwitch :value="customer.workordernumberrequired"
                @value-changed="setCustomerDeep({ path: 'workordernumberrequired', data: $event.value })" />             
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Landlord name required</label>
            <div class="col-sm-4">
              <DxSwitch :value="customer.accountsettings.llnamerequired"
                @value-changed="setCustomerDeep({ path: 'accountsettings.llnamerequired', data: $event.value })" />  
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Landlord email required</label>
            <div class="col-sm-4">
              <DxSwitch :value="customer.accountsettings.llemailrequired"
                @value-changed="setCustomerDeep({ path: 'accountsettings.llemailrequired', data: $event.value })" />  
            </div>
          </div>
          <!-- <div class="form-group row">
            <label class="col-sm-3 col-form-label">Generic email address for invocies</label>
            <div class="col-sm-3">
              <input type="text" class="form-control" v-model.lazy="customer.accountsettings.generalemailforinvoice"
                @change="editCurrentCustomer()" />
            </div>
          </div> -->
          <!-- <div class="form-group row">
            <label class="col-sm-3 col-form-label">Payment in advance</label>
            <div class="col-sm-4">
              <DxSwitch :value="customer.accountsettings.paymentinadvance"
                @value-changed="setCustomerDeep({ path: 'accountsettings.paymentinadvance', data: $event.value })" />  
            </div>
          </div> -->
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Payment in advance for Inv/CI</label>
            <div class="col-sm-4">
              <DxSwitch :value="customer.accountsettings.paymentinadvinvci"
                @value-changed="setCustomerDeep({ path: 'accountsettings.paymentinadvinvci', data: $event.value })" />  
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Payment in advance for CO/SOC</label>
            <div class="col-sm-4">
              <DxSwitch :value="customer.accountsettings.paymentinadvcosoc"
                @value-changed="setCustomerDeep({ path: 'accountsettings.paymentinadvcosoc', data: $event.value })" />  
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Invoice in advance</label>
            <div class="col-sm-4">
              <DxSwitch :value="customer.accountsettings.invoiceinadvance"
                @value-changed="setCustomerDeep({ path: 'accountsettings.invoiceinadvance', data: $event.value })" />  
            </div>
          </div>
          
          <!-- Header ro -->
          <div class="form-group row">
            <div class="col-sm-9 offset-3">
              <div class="row">
                <div class="col-sm-2">Email address</div>
                <div class="col-md-2">Inc Invoice</div>
                <div class="col-md-2">Inc Summary Doc</div>
                <div class="col-md-2">Copy in booked by</div>
                <div class="col-md-2">Issue to LL</div>
                <div class="col-md-2">Issue to TT</div>
              </div>
            </div>
          </div>

          <!-- Inv & Check-In issue rules -->
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Inv & Check-In issue rules</label>
            <div class="col-sm-9">
              <div class="row">
                <div class="col-sm-2">
                  <input type="text" class="form-control" v-model.lazy="invciissueemail"/>
                </div>
                <div class="col-md-2">
                  <DxSwitch :value="invciissueeincludeinvoice" @value-changed="invciissueeincludeinvoice = $event.value"/>
                </div>
                <div class="col-md-2">N/A</div>
                <div class="col-md-2">
                  <DxSwitch  :value="invciissueccbookedby" @value-changed="invciissueccbookedby = $event.value" />
                </div>
                <div class="col-md-2">
                  <DxSwitch :value="invciissueissuetoll" @value-changed="invciissueissuetoll = $event.value" />
                </div>
                <div class="col-md-2">
                  <DxSwitch :value="invciissueissuetott" @value-changed="invciissueissuetott = $event.value" />
                </div>
              </div>
            </div>
          </div>

          <!-- Check-Out and SOC issue rules -->
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"> Check-Out and SOC issue rules</label>
            <div class="col-sm-9">
              <div class="row">
                <div class="col-sm-2">
                  <input type="text" class="form-control" v-model.lazy="cosocissueemail"/>
                </div>
                <div class="col-md-2">
                  <DxSwitch :value="cosocissueincludeinvoice" @value-changed="cosocissueincludeinvoice = $event.value" />
                </div>
                <div class="col-md-2">Defer to report settings</div>
                <div class="col-md-2">
                  <DxSwitch :value="cosocissueccbookedby" @value-changed="cosocissueccbookedby = $event.value" />
                </div>
                <div class="col-md-2">
                  <DxSwitch  :value="cosocissueissuetoll" @value-changed="cosocissueissuetoll = $event.value" />
                </div>
                <div class="col-md-2">
                  <DxSwitch  :value="cosocissueissuetott" @value-changed="cosocissueissuetott = $event.value" />
                </div>
              </div>
            </div>
          </div>

          <!-- Inv & Check-In issue to LL rules-->
          <div class="form-group row">
            <label class="col-sm-3 col-form-label"> Inv & Check-In issue to LL rules</label>
            <div class="col-sm-9">
              <div class="row">
                <div class="col-sm-2">
                  <input type="text" class="form-control" v-model.lazy="invciissuetollemail"/>
                </div>
                <div class="col-md-2">
                  <DxSwitch :value="invciissuetollincludeinvoice" @value-changed="invciissuetollincludeinvoice = $event.value"  />
                </div>
                <div class="col-md-2">N/A</div>
                <div class="col-md-2">
                  <DxSwitch :value="invciissuetollccbookedby" @value-changed="invciissuetollccbookedby = $event.value" />
                </div>
                <div class="col-md-2">
                  <DxSwitch  :value="invciissuetollissuetoll" @value-changed="invciissuetollissuetoll = $event.value" />
                </div>
                <div class="col-md-2">
                  <DxSwitch  :value="invciissuetollissuetott" @value-changed="invciissuetollissuetott = $event.value" />
                </div>
              </div>
            </div>
          </div>

          <!-- Check-Out and SOC issue to LL rules -->
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Check-Out and SOC issue to LL rules</label>
            <div class="col-sm-9">
              <div class="row">
                <div class="col-sm-2">
                  <input type="text" class="form-control" v-model.lazy="cosocissuetollemail"/>
                </div>
                <div class="col-md-2">
                  <DxSwitch :value="cosocissuetollincludeinvoice" @value-changed="cosocissuetollincludeinvoice = $event.value" />
                </div>
                <div class="col-md-2">
                  <DxSwitch :value="cosocissuetollincludesummarydoc" @value-changed="cosocissuetollincludesummarydoc = $event.value" />
                </div>
                <div class="col-md-2">
                  <DxSwitch :value="cosocissuetollccbookedby" @value-changed="cosocissuetollccbookedby = $event.value" />
                </div>
                <div class="col-md-2">
                  <DxSwitch  :value="cosocissuetollissuetoll" @value-changed="cosocissuetollissuetoll = $event.value" />
                </div>
                <div class="col-md-2">
                  <DxSwitch  :value="cosocissuetollissuetott" @value-changed="cosocissuetollissuetott = $event.value" />
                </div>
              </div>
            </div>
          </div>

           <!-- Inv & Check-In issue to TT rules -->
           <div class="form-group row">
            <label class="col-sm-3 col-form-label">Inv & Check-In issue to TT rules</label>
            <div class="col-sm-9">
              <div class="row">
                <div class="col-sm-2">
                  <input type="text" class="form-control" v-model.lazy="invciissuetottemail"/>
                </div>
                <div class="col-md-2">
                  <DxSwitch :value="invciissuetottincludeinvoice" @value-changed="invciissuetottincludeinvoice = $event.value" />
                </div>
                <div class="col-md-2">N/A</div>
                <div class="col-md-2">
                  <DxSwitch  :value="invciissuetottccbookedby" @value-changed="invciissuetottccbookedby = $event.value" />
                </div>
                <div class="col-md-2">
                  <DxSwitch   :value="invciissuetottissuetoll" @value-changed="invciissuetottissuetoll = $event.value" />
                </div>
                <div class="col-md-2">
                  <DxSwitch  :value="invciissuetottissuetott" @value-changed="invciissuetottissuetott = $event.value" />
                </div>
              </div>
            </div>
          </div>

          <!-- Check-Out and SOC issue to TT rules -->
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Check-Out and SOC issue to TT rules:</label>
            <div class="col-sm-9">
              <div class="row">
                <div class="col-sm-2">
                  <input type="text" class="form-control" v-model.lazy="cosocissuetottemail"/>
                </div>
                <div class="col-md-2">
                  <DxSwitch :value="cosocissuetottincludeinvoice" @value-changed="cosocissuetottincludeinvoice = $event.value" />
                </div>
                <div class="col-md-2">
                  <DxSwitch  :value="cosocissuetottincludesummarydoc" @value-changed="cosocissuetottincludesummarydoc = $event.value" />
                </div>
                <div class="col-md-2">
                  <DxSwitch   :value="cosocissuetottccbookedby" @value-changed="cosocissuetottccbookedby = $event.value" />
                </div>
                <div class="col-md-2">
                  <DxSwitch :value="cosocissuetottissuetoll" @value-changed="cosocissuetottissuetoll = $event.value" />
                </div>
                <div class="col-md-2">
                  <DxSwitch  :value="cosocissuetottissuetott" @value-changed="cosocissuetottissuetott = $event.value"/>
                </div>
              </div>
            </div>
          </div>

          <!-- Property Visit Isssue Rules -->
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Property Visit Issue Rules</label>
            <div class="col-sm-9">
              <div class="row">
                <div class="col-sm-2">
                  <input type="text" class="form-control"  v-model.lazy="propvisitissueemail"/>
                </div>
                <div class="col-md-2">
                  <DxSwitch :value="propvisitissueincludeinvoice" @value-changed="propvisitissueincludeinvoice = $event.value"/>
                </div>
                <div class="col-md-2">N/A</div>
                <div class="col-md-2">
                  <DxSwitch :value="propvisitissueccbookedby" @value-changed="propvisitissueccbookedby = $event.value"/>
                </div>
                <div class="col-md-2">
                  <DxSwitch :value="propvisitissuetoll" @value-changed="propvisitissuetoll = $event.value"/>
                </div>
                <div class="col-md-2">
                  <DxSwitch :value="propvisitissuetott" @value-changed="propvisitissuetott = $event.value"/>
                </div>
              </div>
            </div>
          </div>

          <!-- Other Report Isssue Rules -->
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Other Report Issue Rules</label>
            <div class="col-sm-9">
              <div class="row">
                <div class="col-sm-2">
                  <input type="text" class="form-control"  v-model.lazy="otherreportissueemail"/>
                </div>
                <div class="col-md-2">
                  <DxSwitch :value="otherreportissueincludeinvoice" @value-changed="otherreportissueincludeinvoice = $event.value"/>
                </div>
                <div class="col-md-2">N/A</div>
                <div class="col-md-2">
                  <DxSwitch :value="otherreportissueccbookedby" @value-changed="otherreportissueccbookedby = $event.value"/>
                </div>
                <div class="col-md-2">
                  <DxSwitch :value="otherreportissuetoll" @value-changed="otherreportissuetoll = $event.value"/>
                </div>
                <div class="col-md-2">
                  <DxSwitch :value="otherreportissuetott" @value-changed="otherreportissuetott = $event.value"/>
                </div>
              </div>
            </div>
          </div>

           <!-- Inv & Check-In invoice issue rules -->
           <div class="form-group row">
            <label class="col-sm-3 col-form-label">Inv & Check-In invoice issue rules</label>
            <div class="col-sm-9">
              <div class="row">
                <div class="col-sm-2">
                  <input type="text" class="form-control"  v-model.lazy="invciinvoiceissueemail"/>
                </div>
                <div class="col-md-2">N/A</div>
                <div class="col-md-2">N/A</div>
                <div class="col-md-2">
                  <DxSwitch :value="invciinvoiceissueccbookedby" @value-changed="invciinvoiceissueccbookedby = $event.value"/>
                </div>
                <div class="col-md-2">
                  <DxSwitch :value="invciinvoiceissuetoll" @value-changed="invciinvoiceissuetoll = $event.value"/>
                </div>
                <div class="col-md-2">
                  <DxSwitch :value="invciinvoiceissuetott" @value-changed="invciinvoiceissuetott = $event.value"/>
                </div>
              </div>
            </div>
          </div>


           <!-- Check-Out and SOC invoice issue rules -->
           <div class="form-group row">
            <label class="col-sm-3 col-form-label">Check-Out and SOC invoice issue rules</label>
            <div class="col-sm-9">
              <div class="row">
                <div class="col-sm-2">
                  <input type="text" class="form-control"  v-model.lazy="cosocinvoiceissueemail"/>
                </div>
                <div class="col-md-2">N/A</div>
                <div class="col-md-2">N/A</div>
                <div class="col-md-2">
                  <DxSwitch :value="cosocinvoiceissueccbookedby" @value-changed="cosocinvoiceissueccbookedby = $event.value"/>
                </div>
                <div class="col-md-2">
                  <DxSwitch :value="cosocinvoiceissuetoll" @value-changed="cosocinvoiceissuetoll = $event.value"/>
                </div>
                <div class="col-md-2">
                  <DxSwitch :value="cosocinvoiceissuetott" @value-changed="cosocinvoiceissuetott = $event.value"/>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Misc notes</label>
            <div class="col-sm-6">
              <textarea class="form-control" rows="5" v-model.lazy="miscnotes"></textarea>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { Customer } from "@/models";
import { DxSwitch } from 'devextreme-vue/switch';

const store = useStore();
const customer = computed((): Customer => store.getters['customers/current']);
const editCustomer = async (customer: Customer) => await store.dispatch('customers/editCustomer', customer);
const setCustomerDeep = async (payload: {
  path: string;
  data: any;
}) => {
  await store.dispatch('customers/setCustomerDeep', payload);
}
const editCurrentCustomer = () => {
  editCustomer(customer.value);
};

//for invciissue
const invciissueemail = computed({
  get: () => { return customer.value.qcrules.invciissue.email},
  set: (val: string) => { setCustomerDeep({path: 'qcrules.invciissue.email', data: val})}
});
const invciissueeincludeinvoice = computed({
  get: () => { return customer.value.qcrules.invciissue.includeinvoice},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.invciissue.includeinvoice', data: val})}
});
const invciissueccbookedby = computed({
  get: () => { return customer.value.qcrules.invciissue.ccbookedby},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.invciissue.ccbookedby', data: val})}
});
const invciissueissuetoll = computed({
  get: () => { return customer.value.qcrules.invciissue.issuetoll},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.invciissue.issuetoll', data: val})}
});
const invciissueissuetott = computed({
  get: () => { return customer.value.qcrules.invciissue.issuetott},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.invciissue.issuetott', data: val})}
});

//for cosocissue
const cosocissueemail = computed({
  get: () => { return customer.value.qcrules.cosocissue.email},
  set: (val: string) => { setCustomerDeep({path: 'qcrules.cosocissue.email', data: val})}
});
const cosocissueincludeinvoice = computed({
  get: () => { return customer.value.qcrules.cosocissue.includeinvoice},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.cosocissue.includeinvoice', data: val})}
});
const cosocissueccbookedby = computed({
  get: () => { return customer.value.qcrules.cosocissue.ccbookedby},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.cosocissue.ccbookedby', data: val})}
});
const cosocissueissuetoll = computed({
  get: () => { return customer.value.qcrules.cosocissue.issuetoll},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.cosocissue.issuetoll', data: val})}
});
const cosocissueissuetott = computed({
  get: () => { return customer.value.qcrules.cosocissue.issuetott},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.cosocissue.issuetott', data: val})}
});


//for invciissuetoll
const invciissuetollemail = computed({
  get: () => { return customer.value.qcrules.invciissuetoll.email},
  set: (val: string) => { setCustomerDeep({path: 'qcrules.invciissuetoll.email', data: val})}
});
const invciissuetollincludeinvoice = computed({
  get: () => { return customer.value.qcrules.invciissuetoll.includeinvoice},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.invciissuetoll.includeinvoice', data: val})}
});
const invciissuetollccbookedby = computed({
  get: () => { return customer.value.qcrules.invciissuetoll.ccbookedby},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.invciissuetoll.ccbookedby', data: val})}
});
const invciissuetollissuetoll = computed({
  get: () => { return customer.value.qcrules.invciissuetoll.issuetoll},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.invciissuetoll.issuetoll', data: val})}
});
const invciissuetollissuetott = computed({
  get: () => { return customer.value.qcrules.invciissuetoll.issuetott},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.invciissuetoll.issuetott', data: val})}
});

//for cosocissuetoll
const cosocissuetollemail = computed({
  get: () => { return customer.value.qcrules.cosocissuetoll.email},
  set: (val: string) => { setCustomerDeep({path: 'qcrules.cosocissuetoll.email', data: val})}
});
const cosocissuetollincludeinvoice = computed({
  get: () => { return customer.value.qcrules.cosocissuetoll.includeinvoice},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.cosocissuetoll.includeinvoice', data: val})}
});

const cosocissuetollincludesummarydoc = computed({
  get: () => { return customer.value.qcrules.cosocissuetoll.includesummary},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.cosocissuetoll.includesummary', data: val})}
});

const cosocissuetollccbookedby = computed({
  get: () => { return customer.value.qcrules.cosocissuetoll.ccbookedby},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.cosocissuetoll.ccbookedby', data: val})}
});
const cosocissuetollissuetoll = computed({
  get: () => { return customer.value.qcrules.cosocissuetoll.issuetoll},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.cosocissuetoll.issuetoll', data: val})}
});
const cosocissuetollissuetott = computed({
  get: () => { return customer.value.qcrules.cosocissuetoll.issuetott},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.cosocissuetoll.issuetott', data: val})}
});


// for invciissuetott
const invciissuetottemail = computed({
  get: () => { return customer.value.qcrules.invciissuetott.email},
  set: (val: string) => { setCustomerDeep({path: 'qcrules.invciissuetott.email', data: val})}
});
const invciissuetottincludeinvoice = computed({
  get: () => { return customer.value.qcrules.invciissuetott.includeinvoice},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.invciissuetott.includeinvoice', data: val})}
});

const invciissuetottccbookedby = computed({
  get: () => { return customer.value.qcrules.invciissuetott.ccbookedby},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.invciissuetott.ccbookedby', data: val})}
});
const invciissuetottissuetoll = computed({
  get: () => { return customer.value.qcrules.invciissuetott.issuetoll},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.invciissuetott.issuetoll', data: val})}
});
const invciissuetottissuetott = computed({
  get: () => { return customer.value.qcrules.invciissuetott.issuetott},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.invciissuetott.issuetott', data: val})}
});

// for cosocissuetott
const cosocissuetottemail = computed({
  get: () => { return customer.value.qcrules.cosocissuetott.email},
  set: (val: string) => { setCustomerDeep({path: 'qcrules.cosocissuetott.email', data: val})}
});
const cosocissuetottincludeinvoice = computed({
  get: () => { return customer.value.qcrules.cosocissuetott.includeinvoice},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.cosocissuetott.includeinvoice', data: val})}
});

const cosocissuetottincludesummarydoc = computed({
  get: () => { return customer.value.qcrules.cosocissuetott.includesummary},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.cosocissuetott.includesummary', data: val})}
});

const cosocissuetottccbookedby = computed({
  get: () => { return customer.value.qcrules.cosocissuetott.ccbookedby},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.cosocissuetott.ccbookedby', data: val})}
});
const cosocissuetottissuetoll = computed({
  get: () => { return customer.value.qcrules.cosocissuetott.issuetoll},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.cosocissuetott.issuetoll', data: val})}
});
const cosocissuetottissuetott = computed({
  get: () => { return customer.value.qcrules.cosocissuetott.issuetott},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.cosocissuetott.issuetott', data: val})}
});

//for propvisitissue
const propvisitissueemail = computed({
  get: () => { return customer.value.qcrules.propvisitissue.email},
  set: (val: string) => { setCustomerDeep({path: 'qcrules.propvisitissue.email', data: val})}
});
const propvisitissueincludeinvoice = computed({
  get: () => { return customer.value.qcrules.propvisitissue.includeinvoice},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.propvisitissue.includeinvoice', data: val})}
});

const propvisitissueincludesummarydoc = computed({
  get: () => { return customer.value.qcrules.propvisitissue.includesummary},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.propvisitissue.includesummary', data: val})}
});

const propvisitissueccbookedby = computed({
  get: () => { return customer.value.qcrules.propvisitissue.ccbookedby},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.propvisitissue.ccbookedby', data: val})}
});
const propvisitissuetoll = computed({
  get: () => { return customer.value.qcrules.propvisitissue.issuetoll},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.propvisitissue.issuetoll', data: val})}
});
const propvisitissuetott = computed({
  get: () => { return customer.value.qcrules.propvisitissue.issuetott},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.propvisitissue.issuetott', data: val})}
});

//for otherreportissue
const otherreportissueemail = computed({
  get: () => { return customer.value.qcrules.otherreportissue.email},
  set: (val: string) => { setCustomerDeep({path: 'qcrules.otherreportissue.email', data: val})}
});
const otherreportissueincludeinvoice = computed({
  get: () => { return customer.value.qcrules.otherreportissue.includeinvoice},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.otherreportissue.includeinvoice', data: val})}
});

const otherreportissueincludesummarydoc = computed({
  get: () => { return customer.value.qcrules.otherreportissue.includesummary},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.otherreportissue.includesummary', data: val})}
});

const otherreportissueccbookedby = computed({
  get: () => { return customer.value.qcrules.otherreportissue.ccbookedby},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.otherreportissue.ccbookedby', data: val})}
});
const otherreportissuetoll = computed({
  get: () => { return customer.value.qcrules.otherreportissue.issuetoll},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.otherreportissue.issuetoll', data: val})}
});
const otherreportissuetott = computed({
  get: () => { return customer.value.qcrules.otherreportissue.issuetott},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.otherreportissue.issuetott', data: val})}
});

//for invciinvoiceissue
const invciinvoiceissueemail = computed({
  get: () => { return customer.value.qcrules.invciinvoiceissue.email},
  set: (val: string) => { setCustomerDeep({path: 'qcrules.invciinvoiceissue.email', data: val})}
});
const invciinvoiceissueccbookedby = computed({
  get: () => { return customer.value.qcrules.invciinvoiceissue.ccbookedby},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.invciinvoiceissue.ccbookedby', data: val})}
});
const invciinvoiceissuetoll = computed({
  get: () => { return customer.value.qcrules.invciinvoiceissue.issuetoll},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.invciinvoiceissue.issuetoll', data: val})}
});
const invciinvoiceissuetott = computed({
  get: () => { return customer.value.qcrules.invciinvoiceissue.issuetott},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.invciinvoiceissue.issuetott', data: val})}
});

//for cosocinvoiceissue
const cosocinvoiceissueemail = computed({
  get: () => { return customer.value.qcrules.cosocinvoiceissue.email},
  set: (val: string) => { setCustomerDeep({path: 'qcrules.cosocinvoiceissue.email', data: val})}
});
const cosocinvoiceissueccbookedby = computed({
  get: () => { return customer.value.qcrules.cosocinvoiceissue.ccbookedby},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.cosocinvoiceissue.ccbookedby', data: val})}
});
const cosocinvoiceissuetoll = computed({
  get: () => { return customer.value.qcrules.cosocinvoiceissue.issuetoll},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.cosocinvoiceissue.issuetoll', data: val})}
});
const cosocinvoiceissuetott = computed({
  get: () => { return customer.value.qcrules.cosocinvoiceissue.issuetott},
  set: (val: boolean) => { setCustomerDeep({path: 'qcrules.cosocinvoiceissue.issuetott', data: val})}
});

//for Misc notes
const miscnotes = computed({
  get: () => { return customer.value.qcrules.misc},
  set: (val: string) => { setCustomerDeep({path: 'qcrules.misc', data: val})}
});

</script>

<style scoped lang="scss"></style>