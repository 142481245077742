<template>
  <div>
    <div v-for="(item, $index) in type!.items" :key="item._uuid">
      <report-room-item :item="item" :index="$index" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, provide } from 'vue';
import ReportRoomItem from '@/components/report/Item.vue';
import { Type } from '@/models';

const props = defineProps({
  index: Number,
  type: Type,
});

provide('typeIndex', props.index);
provide('typeSlug', props.type!.slug);
provide('typeName', props.type!.name);
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
