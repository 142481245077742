import _ from "lodash";
import { API } from "aws-amplify";

import { ActionTree, ActionContext } from "vuex";
import { RootState } from "../types";
import { CustomersState } from "./types";

import { Contact, Customer } from "@/models";

/**
 * Actions
 *
 * Actions are similar to mutations, the differences being that:
 * - Instead of mutating the state, actions commit mutations.
 * - Actions can contain arbitrary asynchronous operations.
 * - Actions are triggered with the store.dispatch method e.g. `store.dispatch('getSettings')`
 */
export const actions: ActionTree<CustomersState, RootState> = {
  /**
   * Get multiple Customers
   *
   * @param {CustomersState} store
   * @param filters
   * @returns Multiple Customers
   */
  async getCustomers(
    store: ActionContext<CustomersState, any>,
    filters: any
  ): Promise<Customer[]> {
    const options = {
      queryStringParameters: _.pickBy(
        _.pick(filters, [
          "starts_with",
          "company_name",
          "branch_name",
          "managedtype",
        ])
      ),
    };

    store.commit("app/addRequest", "getCustomers", { root: true });

    return API.get("RestAPI", "/customers", options)
      .then((response) =>
        response.map((x: Partial<Customer>) => new Customer(x))
      )
      .then((customers: Customer[]) => {
        store.commit("setCustomers", customers);
        return customers;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getCustomers", { root: true })
      );
  },

  /**
   * Get multiple Customers, bypassing the store (used by diary)
   *  the only difference between this and the previous is, this one
   *  does not update the store.
   *
   * @param {CustomersState} store
   * @param filters
   * @returns Multiple Customers
   */
  async getCustomersBypassStore(
    store: ActionContext<CustomersState, any>,
    filters: any
  ): Promise<Customer[]> {
    const options = {
      queryStringParameters: _.pickBy(
        _.pick(filters, [
          "starts_with",
          "company_name",
          "branch_name",
          "managedtype",
        ])
      ),
    };

    store.commit("app/addRequest", "getCustomers", { root: true });

    return API.get("RestAPI", "/customers", options)
      .then((response) =>
        response.map((x: Partial<Customer>) => new Customer(x))
      )
      .then((customers: Customer[]) => {
        return customers;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getCustomers", { root: true })
      );
  },

  /**
   * Get one Customer
   *
   * @param {CustomersState} store
   * @param id
   * @returns Individual Customer
   */
  async getCustomer(store: ActionContext<CustomersState, any>, id) {
    // New Customer
    if (id === "new") {
      const customer = new Customer();
      return customer;
    }
    // Search by Ref
    store.commit("app/addRequest", "getCustomer", { root: true });
    return API.get("RestAPI", `/customers/${id}`, null)
      .then((response) => new Customer(response))
      .then((customer: Customer) => {
        store.commit("setCustomer", customer);
        return customer;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getCustomer", { root: true })
      );
  },

  /**
   * Check if depositary is active for given customer
   *
   * @param {CustomersState} store
   * @param id
   * @returns Boolean
   */
  async getDepositoryActive(store: ActionContext<CustomersState, any>, id) {
    store.commit("app/addRequest", "getDepositoryActive", { root: true });
    return API.get("RestAPI", `/depository/active/${id}`, null)
      .then((response) => response)
      .finally(() =>
        store.commit("app/removeRequest", "getDepositoryActive", { root: true })
      );
  },

  /**
   * Add a Customer
   *
   * @param {CustomersState} store
   * @param customer Customer
   * @returns Customer
   */
  async addCustomer(
    store: ActionContext<CustomersState, any>,
    customer: Customer
  ) {
    store.commit("app/addRequest", "addCustomer", { root: true });
    return API.post("RestAPI", `/customers`, { body: customer.toJSON() })
      .then((response) => new Customer(response))
      .then((customer: Customer) => {
        store.commit("setCustomers", store.state.list.concat(customer));
        store.commit("setCustomer", customer);
        store.commit("resetUnsavedChanges");
        return customer;
      })
      .finally(() =>
        store.commit("app/removeRequest", "addCustomer", { root: true })
      );
  },

  /**
   * Update a Customer
   *
   * @param {CustomersState} store
   * @param customer Customer
   * @returns Updated Customer
   */
  async updateCustomer(
    store: ActionContext<CustomersState, any>,
    customer: Customer
  ) {
    store.commit("app/addRequest", "updateCustomer", { root: true });
    return API.put("RestAPI", `/customers/${customer.id}`, {
      body: customer.toJSON(),
    })
      .then((response) => new Customer(response))
      .then((customer: Customer) => {
        store.commit("setCustomer", customer);
        store.commit("resetUnsavedChanges");
        return customer;
      })
      .finally(() =>
        store.commit("app/removeRequest", "updateCustomer", { root: true })
      );
  },

  /**
   * Delete a Customer
   *
   * @param {CustomersState} store
   * @param customer Customer
   * @returns Deleted Customer
   */
  async deleteCustomer(
    store: ActionContext<CustomersState, any>,
    customer: Customer
  ): Promise<void> {
    store.commit("app/addRequest", "deleteCustomer", { root: true });
    return API.del("RestAPI", `/customers/${customer.id}`, {})
      .then(() =>
        store.commit(
          "setCustomers",
          _.remove(store.state.list, (c: Customer) => c.id !== customer.id)
        )
      )
      .then(() => store.commit("setCustomer", new Customer()))
      .then(() => store.commit("resetUnsavedChanges"))
      .finally(() =>
        store.commit("app/removeRequest", "deleteCustomer", { root: true })
      );
  },

  /**
   * Get Company Name options
   *
   * - Used for select tag options
   * @param {CustomersState} store
   * @returns Company Name options
   */
  async getCompanyNameOptions(
    store: ActionContext<CustomersState, any>,
    managedtype?: string
  ) {
    const options = {
      queryStringParameters: {
        select: "company_name",
        managedtype: "",
      },
    };
    if (managedtype || managedtype === "") {
      _.set(options.queryStringParameters, "managedtype", managedtype);
    }

    store.commit("app/addRequest", "getCompanyNameOptions", { root: true });
    return API.get("RestAPI", `/customers`, options).finally(() =>
      store.commit("app/removeRequest", "getCompanyNameOptions", { root: true })
    );
  },

  /**
   * Get Branch Name options
   *
   * - Used for select tag options
   * @param {CustomersState} store
   * @param company_name string
   * @returns Branch Name options
   */
  async getBranchNameOptions(
    store: ActionContext<CustomersState, any>,
    company_name?: string
  ): Promise<any> {
    const options = {
      queryStringParameters: {
        select: "branch_name",
      },
    };
    if (company_name !== undefined) {
      _.set(options.queryStringParameters, "company_name", company_name);
    }
    store.commit("app/addRequest", "getBranchNameOptions", { root: true });
    return API.get("RestAPI", `/customers`, options).finally(() =>
      store.commit("app/removeRequest", "getBranchNameOptions", { root: true })
    );
  },

  /**
   * Get Branch Name options - filter by managedtype
   *
   * - Used for select tag options
   * @param {CustomersState} store
   * @param payload: {company_name :string, managedtype: string}
   * @returns Branch Name options
   */
  async getBranchNameOptionsWithManagedtype(
    store: ActionContext<CustomersState, any>,
    payload: { company_name: string; managedtype: string }
  ): Promise<any> {
    const options = {
      queryStringParameters: {
        select: "branch_name",
      },
    };
    if (payload.company_name !== undefined) {
      _.set(
        options.queryStringParameters,
        "company_name",
        payload.company_name
      );
    }
    if (payload.managedtype || payload.managedtype === "") {
      _.set(options.queryStringParameters, "managedtype", payload.managedtype);
    }
    store.commit("app/addRequest", "getBranchNameOptions", { root: true });
    return API.get("RestAPI", `/customers`, options).finally(() =>
      store.commit("app/removeRequest", "getBranchNameOptions", { root: true })
    );
  },

  /**
   * Get Company Name options
   *
   * - Used for select tag options
   * @param {CustomersState} store
   * @returns Company Name options
   */
  async getReportactiveCompanyNameOptions(
    store: ActionContext<CustomersState, any>,
    reportname
  ) {
    const options = {
      queryStringParameters: {
        select: "company_name",
        reportnamelist: reportname,
      },
    };
    store.commit("app/addRequest", "getCompanyNameOptions", { root: true });
    return API.get("RestAPI", `/customers`, options).finally(() =>
      store.commit("app/removeRequest", "getCompanyNameOptions", { root: true })
    );
  },

  /**
   * Get Branch Name options
   *
   * - Used for select tag options
   * @param {CustomersState} store
   * @param company_name string
   * @returns Branch Name options
   */
  async getReportactiveBranchNameOptions(
    store: ActionContext<CustomersState, any>,
    option: { company_name?: string; reportname: string }
  ): Promise<any> {
    const options = {
      queryStringParameters: {
        select: "branch_name",
        reportname: option.reportname,
      },
    };
    if (option.company_name !== undefined) {
      _.set(options.queryStringParameters, "company_name", option.company_name);
    }
    store.commit("app/addRequest", "getBranchNameOptions", { root: true });
    return API.get("RestAPI", `/customers`, options).finally(() =>
      store.commit("app/removeRequest", "getBranchNameOptions", { root: true })
    );
  },

  /**
   * Edit a Customer
   *
   * @param {CustomersState} store
   * @param customer Customer
   */
  editCustomer(store: ActionContext<CustomersState, any>, customer: Customer) {
    store.commit("setCustomer", customer);
    store.commit("addUnsavedChange");
  },

  /**
   * Sets any deep customer property and increments the "unsaved changes" count
   * @param store
   * @param data
   */
  setCustomerDeep(store: ActionContext<CustomersState, any>, data): void {
    store.commit("setCustomerDeep", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Adds a establishment, and increments the "unsaved changes" count
   */
  addEstablishment(store: ActionContext<CustomersState, any>, data): void {
    store.commit("addEstablishment", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Removes a Establishment, and increments the "unsaved changes" count
   */
  removeEstablishment(store: ActionContext<CustomersState, any>, data): void {
    store.commit("removeEstablishment", data);
    store.commit("addUnsavedChange");
  },
};
