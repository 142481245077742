import { snakeCase } from 'lodash';
import { copyInto } from '@/utilities';
import _get from 'lodash/get';


export class CustomerReportSettings {

  public redlinecoactive: boolean;
  public cosummaryactive: boolean;
  public cosmredlineactive: boolean;
  public activereporttypes: string[];

  /**
   * Create an object from a JSON representation
   * @param data
   * @return Report
   */
  public static parse(data?: Partial<CustomerReportSettings>) {
    const obj = new CustomerReportSettings;
    copyInto(obj, data);
    return obj;
  }

  /**
   * Constructor
   */
  public constructor() {
    this.redlinecoactive = true;
    this.cosummaryactive = true;
    this.cosmredlineactive = false;
    this.activereporttypes = [];
  }

}
