import { snakeCase } from 'lodash';
import { copyInto } from '@/utilities';
import { DictionaryItem } from '@/models';

export class DictionaryType {

  public id: string;
  public items: DictionaryItem[];
  public name: string;
  public systemName: string;
  public slug: string;
  public minimumRequired: number;

  public fixflocategory: string;
  public fixflosubcategory: string;

  /**
   * Create an object from a JSON representation
   * @param data
   * @return Report
   */
  public static parse(data: object) {
    const obj = new DictionaryType;

    copyInto(obj, data);

    obj.items = obj.items ? obj.items.map(DictionaryItem.parse) : [];

    if (!obj.slug) {
      obj.slug = snakeCase(obj.systemName) || snakeCase(obj.name);
    }

    return obj;
  }

  /**
   * Constructor
   */
  public constructor() {
    this.items = [];
    this.name = '';
    this.systemName = '';
    this.slug = '';
    this.minimumRequired = 0;
    this.fixflocategory = '';
    this.fixflosubcategory = '';
  }

  /**
   * Constructor
   */
  public getSystemName() {
    return this.systemName || this.name;
  }

}
