import _get from 'lodash/get';

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

export class Client extends Base {
  /**
   * name expects a string defining the client name
   */
  public name: string;

  /**
   * emails expects a string defining the client email address
   */
  public email: string;

  /**
   * Constructor initialises the variables from the class Client
   * @param data - the data to construct a type with all properties of <Client>
   */
  public constructor(data?: Partial<Client>) {
    super(data);
    data = toCamelCase(data);

    this.name = _get(data, 'name', '');
    this.email = _get(data, 'email', '');
  }

}
