import _get from 'lodash/get';

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

export class Attachments extends Base {
  public invoice?: string;
  public proforma?: string;
  public photos?: string;
  public report?: string;
  public reportuploaddate?: string;
  public summary?: string;
  public summaryuploaddate?: string;
  public clientconfirmation?: string;

  /**
   * Constructor initialises the variables from the class Attachments
   * @param data - the data to construct a type with all properties of <Attachments>
   */
  public constructor(data?: Partial<Attachments>) {
    super(data);
    data = toCamelCase(data);

    if (_get(data, 'invoice')) {
      this.invoice = _get(data, 'invoice');
    }

    if (_get(data, 'proforma')) {
      this.proforma = _get(data, 'proforma');
    }

    if (_get(data, 'photos')) {
      this.photos = _get(data, 'photos');
    }

    if (_get(data, 'report')) {
      this.report = _get(data, 'report');
    }

    if (_get(data, 'reportuploaddate')) {
      this.reportuploaddate = _get(data, 'reportuploaddate');
    }

    if (_get(data, 'summary')) {
      this.summary = _get(data, 'summary');
    }

    if (_get(data, 'summaryuploaddate')) {
      this.summaryuploaddate = _get(data, 'summaryuploaddate');
    }

    if (_get(data, 'clientconfirmation')) {
      this.clientconfirmation = _get(data, 'clientconfirmation');
    }
  }
}
