import _get from "lodash/get";
import _castArray from "lodash/castArray";
import { copyInto } from "@/utilities";

import { Base } from "@/models";
import { toCamelCase } from "@/utilities";

export class Fixflo {
  public authToken: string;

  /**
   * Create an object from a JSON representation
   * @param data
   * @return Fixflo
   */
  public static parse(data?: Partial<Fixflo>) {
    const obj = new Fixflo();
    copyInto(obj, data);
    return obj;
  }

  /**
   * Constructor
   */
  public constructor() {
    this.authToken = "";
  }
}
