import _get from "lodash/get";
import _castArray from "lodash/castArray";

import { Base, Complianceitem } from "@/models";
import { toCamelCase } from "@/utilities";

export class Compliancesection extends Base {
  public heading: string;
  public complianceitems: Complianceitem[];

  /**
   * Constructor initialises the variables from the class Compliancesection
   * @param data - the data to construct a type with all properties of <Compliancesection>
   */
  public constructor(data?: Partial<Compliancesection>) {
    super(data);
    data = toCamelCase(data);

    this.heading = _get(data, "heading", "");
    this.complianceitems = _castArray(_get(data, "complianceitems", [])).map(
      (x: Partial<Complianceitem>) => new Complianceitem(x)
    );
  }
}
