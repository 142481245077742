import Vue from "vue";
import { API } from "aws-amplify";
import _ from "lodash";

import { ActionTree, ActionContext } from "vuex";
import { RootState } from "../types";
import { AppState } from "./types";

/**
 * Actions
 *
 * Actions are similar to mutations, the differences being that:
 * - Instead of mutating the state, actions commit mutations.
 * - Actions can contain arbitrary asynchronous operations.
 * - Actions are triggered with the store.dispatch method e.g. `store.dispatch('getSettings')`
 */
export const actions: ActionTree<AppState, RootState> = {
  /**
   * Get Options (for select boxes)
   * @param {AppState} store
   * @returns Select box options
   */
  async getOptions(store: ActionContext<AppState, any>) {
    store.commit("app/addRequest", "getOptions", { root: true });
    return API.get("RestAPI", `/options`, {})
      .then((data) => JSON.parse(data))
      .then((options) => store.commit("setOptions", options))
      .finally(() =>
        store.commit("app/removeRequest", "getOptions", { root: true })
      );
  },

  /**
   * Get system param
   * @param {AppState} store
   * @returns value
   */
  async getSysParam(
    store: ActionContext<AppState, any>,
    key: string
  ): Promise<any> {
    store.commit("app/addRequest", "getSysParam", { root: true });
    return API.get("RestAPI", `/systemparam/${key}`, {})
      .then((value) => value)
      .finally(() =>
        store.commit("app/removeRequest", "getSysParam", { root: true })
      );
  },

  /**
   * Update system param
   * @param {AppState} store
   * @returns value
   */
  async updateSysParam(
    store: ActionContext<AppState, any>,
    payload: { key: string; value: string }
  ) {
    store.commit("app/updateSysParam", "updateSysParam", { root: true });
    API.put("RestAPI", `/systemparam/${payload.key}`, {
      body: payload.value,
    }).finally(() =>
      store.commit("app/removeRequest", "updateSysParam", { root: true })
    );
  },
};
