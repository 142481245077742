import _get from 'lodash/get';

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

export class Document extends Base {

  public src: string;
  public createdAt: string;

  public rotate?: number;
  public hasGalleryLink: boolean;

  /**
   * Constructor initialises the variables from the class Document
   * @param data - the data to construct a type with all properties of <Document>
   */
  public constructor(data?: Partial<Document>) {
    super(data);
    data = toCamelCase(data);

    this.src = _get(data, 'src', '');
    this.createdAt = _get(data, 'createdAt', '');
  }
}
