<template>
  <div class="dictionary section-type-selector">

    <div class="card bg-light mb-3">
      <div class="card-body">
        <div class="row">

          <div class="col-3" v-if="showSections">
            <h2>Section</h2>
            <multiselect
              v-model="activeSection"
              track-by="slug"
              :options="allSections">
            </multiselect>
            <button class="btn btn-link pb-0" :class="{'text-muted': !hasActiveSection}" @click="unsetActiveSection">
              Clear
            </button>
          </div>

          <div class="col-3" v-if="showTypes && hasActiveSection">
            <button class="btn btn-outline-secondary float-right" data-toggle="modal" data-target="#dictionary-types-modal">
              <i class="fas fa-ellipsis-h"></i>
            </button>
            <h2>Type</h2>
            <multiselect
              v-model="activeType"
              track-by="slug"
              :custom-label="getSystemName"
              :options="currentTypes">
            </multiselect>
            <button class="btn btn-link pb-0" :class="{'text-muted': !hasActiveType}" @click="unsetActiveType">
              Clear
            </button>
          </div>

          <div class="col-6" v-if="showItems && hasActiveType">
            <button class="btn btn-outline-secondary float-right" data-toggle="modal" data-target="#dictionary-items-modal">
              <i class="fas fa-ellipsis-h"></i>
            </button>
            <h2>Item</h2>
            <multiselect
              v-model="activeItem"
              track-by="slug"
              :options="currentItems">
            </multiselect>
            <button class="btn btn-link pb-0" :class="{'text-muted': !hasActiveItem}" @click="unsetActiveItem">
              Clear
            </button>
          </div>

        </div>
      </div>
    </div>

    <!-- Modals -->
    <DictionaryTypesModal />
    <DictionaryItemsModal />

  </div>
</template>

<script lang="ts" setup>
import { computed, ref, defineProps } from 'vue';
import { useStore } from 'vuex';
import DictionaryTypesModal from '@/components/modals/DictionaryTypesModal.vue';
import DictionaryItemsModal from '@/components/modals/DictionaryItemsModal.vue';

// Models can remain the same
import { DictionarySection, DictionaryType, DictionaryItem } from '@/models';
import { DictionaryState } from '@/store/dictionary/types';

// Define props with type annotations
const props = defineProps<{
  showSections: boolean;
  showTypes: boolean;
  showItems: boolean;
}>();

const store = useStore();

// Computed properties for getting state values
const dictionaryState = computed(() => store.state.dictionary as DictionaryState);

// Computed properties with getters and setters for activeSection, activeType, and activeItem
const activeSection = computed<DictionarySection>({
  get: () => dictionaryState.value.activeSection,
  set: (val: DictionarySection) => {
    store.commit('dictionary/setActiveSection', val);
    unsetActiveType();
  },
});

const activeType = computed<DictionaryType>({
  get: () => dictionaryState.value.activeType,
  set: (val: DictionaryType) => {
    store.commit('dictionary/setActiveType', val);
    unsetActiveItem();
  },
});

const activeItem = computed<DictionaryItem>({
  get: () => dictionaryState.value.activeItem,
  set: (val: DictionaryItem) => {
    store.commit('dictionary/setActiveItem', val);
  },
});

// Methods are simply converted to functions
function unsetActiveSection() {
  store.commit('dictionary/setActiveSection', new DictionarySection());
  unsetActiveType();
}

function unsetActiveType() {
  store.commit('dictionary/setActiveType', new DictionaryType());
  unsetActiveItem();
}

function unsetActiveItem() {
  store.commit('dictionary/setActiveItem', new DictionaryItem());
}

function getSystemName(type: DictionaryType): string {
  return type.getSystemName();
}

// Computed properties for the getters
const allSections = computed(() => store.getters['dictionary/allSections'] as DictionarySection[]);
const currentTypes = computed(() => store.getters['dictionary/currentTypes'] as DictionaryType[]);
const currentItems = computed(() => store.getters['dictionary/currentItems'] as DictionaryItem[]);

const hasActiveSection = computed(() => store.getters['dictionary/hasActiveSection'] as boolean);
const hasActiveType = computed(() => store.getters['dictionary/hasActiveType'] as boolean);
const hasActiveItem = computed(() => store.getters['dictionary/hasActiveItem'] as boolean);

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.btn-sm {
  font-size: 0.4rem;
}
.list-group-item {
  cursor: pointer;
}
.list-group-item-active {
  .list-group-sections & { background-color: #42A5F5; }
  .list-group-types & { background-color: #9575CD; }
  .list-group-items & { background-color: #FFB74D; }
}
.list-group {
  max-height: 155px;
  overflow: scroll;
}
.highlight {
  border-color: red;
}
.text-muted {
  opacity: 0.5;
}
</style>
