<template>
  <div
  :id="id"
    class="modal"
    data-keyboard="true"
    tabindex="-1"
    role="dialog"
    aria-labelledby="`fixedtime-booking-modal-label`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="fixedtime-booking-modal-label">Fixed Time (will be seen on PI's tablet)</h5>
          <button
            id="close"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click.prevent="hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <label class="col-form-label">Why are you fixing the time?</label>
          <div>
            <textarea
              class="form-control"
              rows="3"
              v-model="notes"
            ></textarea>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary"
            data-dismiss="modal"
            @click.prevent="hide()"
          >
            Back
          </button>
          <button
            type="button"
            class="btn btn-primary btn-danger"
            data-dismiss="modal"
            @click.prevent="save()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import { ref, defineEmits, inject, computed, defineExpose,defineProps } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const booking = computed(() => store.getters['diary/booking']);

const setBookingDeep = (payload: { path: string; data: any }): Promise<any> => {
 return store.dispatch('diary/setBookingDeep', payload);
};

const notes = ref(""); 
const emit = defineEmits(['hide'])
const props = defineProps<{
  id: string;
}>();

const init = () => {
  notes.value = booking.value.fixedtimenotes;
};

const show = () => {
  if ($(`#${props.id}` + 'Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.id + 'Backdrop"></div>');
    $('body').append(backdropDiv);
    $(`#${props.id}`).show();
  }
}
const hide = () => {
  if ($(`#${props.id}`).length > 0) {
    $(`#${props.id}` + 'Backdrop').remove();
    $(`#${props.id}`).hide();
  }
};
const save = async () => {
  hide();
  setBookingDeep({ path: 'fixedtimenotes', data: notes.value });
};

defineExpose({init,show,hide});
</script>
