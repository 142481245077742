import { Module } from 'vuex';
import { RootState } from '../types';
import { AppState } from './types';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

/**
 * Default state
 */
export const state: AppState = {
  options: {
    companyNames: [],
    branchNames: [],
  },
  requests: [],
  timezone: 'Europe/London', // For now, ALWAYS work in Europe/London TZ
};

/**
 * Use namespaced Vuex modules
 */
const namespaced: boolean = true;

/**
 * Module export
 */
const app: Module<AppState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};

export default app;
