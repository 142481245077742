import { MutationTree } from "vuex";
import { FeedbackState } from "./types";
import {
  OrderedItem,
  Comments,
  ShareholderComments,
  LandlordComments,
  TenantComments,
} from "@/models";
import { datetimeToUTC } from "@/utilities";
import _set from "lodash/set";

export const mutations: MutationTree<FeedbackState> = {
  setShareholdertype(state: FeedbackState, shareholdertype: string): void {
    state.shareholdertype = shareholdertype;
  },

  setLandlordcommentsname(
    state: FeedbackState,
    landlordcommentsname: string
  ): void {
    state.landlordcommentsname = landlordcommentsname;
  },

  setLandlordcommentsemail(
    state: FeedbackState,
    landlordcommentsemail: string
  ): void {
    state.landlordcommentsemail = landlordcommentsemail;
  },

  setTenantcommentsname(
    state: FeedbackState,
    tenantcommentsname: string
  ): void {
    state.tenantcommentsname = tenantcommentsname;
  },

  setTenantcommentsemail(
    state: FeedbackState,
    tenantcommentsemail: string
  ): void {
    state.tenantcommentsemail = tenantcommentsemail;
  },

  setComments(state: FeedbackState, comments: Comments): void {
    state.comments = comments;
  },

  setCurrentItem(state: FeedbackState, item: OrderedItem): void {
    state.currentItem = item;
  },

  setStep(state: FeedbackState, step: string): void {
    state.step = step;
  },

  setCommentedItems(state: FeedbackState, itemlist: OrderedItem[]): void {
    state.commentedItems = itemlist;
  },

  addCommentedItem(state: FeedbackState, itemtoadd: OrderedItem): void {
    let alreadysaved = state.commentedItems.filter(
      (item) => item === itemtoadd
    );

    let datetime = datetimeToUTC();
    if (alreadysaved.length) {
      let previouslysaveditem = alreadysaved[0];
      if (previouslysaveditem.tenantcomments != itemtoadd.tenantcomments) {
        previouslysaveditem.tenantcomments.updatedAt == datetime;
        previouslysaveditem.tenantcomments.approved = false;
        previouslysaveditem.tenantcomments.load(itemtoadd.tenantcomments);
      }
    } else {
      itemtoadd.tenantcomments.updatedAt = datetime;
      itemtoadd.tenantcomments.approved = false;
      state.commentedItems.push(itemtoadd);
    }
  },

  removeCommentedItem(
    state: FeedbackState,
    payload: { shareholdertype: string; itemtodelete: OrderedItem }
  ): void {
    let index = -1;
    state.commentedItems.forEach((item, i) => {
      if (item === payload.itemtodelete) index = i;
    });

    if (index) {
      state.commentedItems.splice(index, 1);
      if (payload.shareholdertype === "Landlord") {
        payload.itemtodelete.landlordcomments = new LandlordComments();
      } else if (payload.shareholdertype === "Tenant") {
        payload.itemtodelete.tenantcomments = new TenantComments();
      }
    }
  },
};
