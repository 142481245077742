import _ from "lodash";
import { MutationTree } from "vuex";
import { UsermanagementState } from "./types";
import { User, Customer } from "@/models";

/**
 * Mutations
 *
 * The only way to actually change state in a Vuex store is by committing a mutation.
 * - Vuex mutations are very similar to events: each mutation has a string type and a handler.
 * - The handler function is where we perform actual state modifications - it receives the state as the first argument:
 * - You cannot directly call a mutation handler.
 * - Think of it more like event registration: "When a mutation with type X is triggered, call this handler."
 * - To invoke a mutation handler, you need to call store.commit with its type
 * - e.g. `store.commit('setCustomers', customer)`
 */
export const mutations: MutationTree<UsermanagementState> = {
  /**
   * Set the user currently being viewed/edited
   *
   * @param state UsermanagementState
   * @param user User
   * @returns void
   */
  setCurrentUser(state: UsermanagementState, user: User): void {
    state.current = user;
  },

  /**
   * Set the currently edited/viewed inspector
   *
   * @param state InspectorsState
   * @param payload.path string
   * @param payload.data
   * @returns void
   */
  setUserDeep(
    state: UsermanagementState,
    payload: { path: string; data: any }
  ): void {
    _.set(state.current, payload.path, payload.data);
  },

  /**
   * Set list of users
   *
   * @param state UsermanagementState
   * @param list User[]
   * @returns void
   */
  setUsers(state: UsermanagementState, list: User[]): void {
    state.users = list;
  },

  /**
   * Set list of customers
   *
   * @param state UsermanagementState
   * @param list Customer[]
   * @returns void
   */
  setCustomers(state: UsermanagementState, list: Customer[]): void {
    state.customers = list;
  },

  /**
   * ++ Unsaved changes
   *
   * @param state UsermanagementState
   * @param element string
   * @returns void
   */
  addUnsavedChange(state: UsermanagementState, element: string): void {
    state.updates = [...new Set(state.updates.concat([element]))];
  },

  /**
   * Reset Unsaved changes
   *
   * @param state UsermanagementState
   * @returns void
   */
  resetUnsavedChanges(state: UsermanagementState): void {
    state.updates = [];
  },
};
