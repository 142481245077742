import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { Base, CognotoUserAttribute } from '@/models';
import { datetimeToUTC, parseDate } from '@/utilities';
import { CognitoGroup } from './cognitogroup.model';

export class CognitoUser extends Base {

    public Username: string;
    public Attributes: CognotoUserAttribute[];
    public UserAttributes: CognotoUserAttribute[];
    public UserCreateDate: Date;
    public UserLastModifiedDate: Date;
    public Enabled: boolean;
    public UserStatus: string;

    public CognitoUserGroups: CognitoGroup[];

    /**
     * Constructor initialises the variables from the class User
     * @param data - the data to construct a type with all properties of <User>
     */
    public constructor(data?: Partial<CognitoUser>) {
        super(data);

        this.Username = _get(data, 'Username', '');
        this.Attributes = _castArray(_get(data, 'Attributes', [])).map((x: Partial<CognotoUserAttribute>) => new CognotoUserAttribute(x));
        this.UserAttributes = _castArray(_get(data, 'UserAttributes', [])).map((x: Partial<CognotoUserAttribute>) => new CognotoUserAttribute(x));
        this.UserCreateDate = parseDate(_get(data, 'UserCreateDate', datetimeToUTC()));
        this.UserLastModifiedDate = parseDate(_get(data, 'UserLastModifiedDate', datetimeToUTC()));
        this.Enabled = _get(data, 'Enabled', true);
        this.UserStatus = _get(data, 'UserStatus', '');
    }

    public findAttributeValue(name: string): string {
        let value = '';
        if (this.Attributes) {
            let att = this.Attributes.find((a: CognotoUserAttribute) => a.Name == name);
            if (att)
                value = att.Value;
        }
        if (!value && this.UserAttributes) {
            let att = this.UserAttributes.find((a: CognotoUserAttribute) => a.Name == name);
            if (att)
                value = att.Value;
        }
        return value;
    }

    public setAttributeValue(name: string, value: string) {
        let valueset = false;
        if (this.Attributes) {
            let att = this.Attributes.find((a: CognotoUserAttribute) => a.Name == name);
            if (att) {
                att.Value = value;
                valueset = true;
            }
        }

        if (!valueset && this.UserAttributes) {
            let att = this.UserAttributes.find((a: CognotoUserAttribute) => a.Name == name);
            if (att) {
                att.Value = value;
                valueset = true;
            }
        }
    }

    public load(user: CognitoUser) {
        this.Username = _get(user, 'Username', '');
        this.Attributes = _castArray(_get(user, 'Attributes', [])).map((x: Partial<CognotoUserAttribute>) => new CognotoUserAttribute(x));
        this.UserAttributes = _castArray(_get(user, 'UserAttributes', [])).map((x: Partial<CognotoUserAttribute>) => new CognotoUserAttribute(x));
        this.UserCreateDate = parseDate(_get(user, 'UserCreateDate', datetimeToUTC()));
        this.UserLastModifiedDate = parseDate(_get(user, 'UserLastModifiedDate', datetimeToUTC()));
        this.Enabled = _get(user, 'Enabled', true);
        this.UserStatus = _get(user, 'UserStatus', '');
    }

}
