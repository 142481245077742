<template>
  <div :id="name" class="modal" data-keyboard="true" tabindex="-1" role="dialog"
    aria-labelledby="`worksordernotes-modal-label`" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="worksordernotes-modal-label">
            Worksorder notes
          </h5>
          <button id="close" type="button" class="close" data-dismiss="modal" aria-label="Close"  @click.prevent="hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <form>
            <fieldset>
              <div>
                <tiptap-editor :key="tiptapeditorkey" :content="tiptapworksordernotes" @blur="setWorksordernotes"
                  style="min-height: 50vh;" />
              </div>
            </fieldset>
          </form>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" @click.prevent="hide()">
            Cancel
          </button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" @click.prevent="save()">
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import { ref, PropType, inject, defineEmits, defineExpose, defineProps } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { v4 as uuidv4 } from 'uuid';
import TiptapEditor from "@/components/editor/TiptapEditor.vue";
import { Booking } from "@/models";

const props = defineProps({
  name: {
    type: String,
    required: false,
  },
  booking: {
    type: Object as PropType<Booking>,
    default: () => new Booking(),
  },
});
const tiptapeditorkey = ref('');
const tiptapworksordernotes = ref('');

const store = useStore();
const route = useRoute();
const actProperty: any = inject('actProperty');

const emit = defineEmits(['hide']);

const setBookingDeep = (payload: { path: string; data: any }): Promise<any> => {
  return store.dispatch('diary/setBookingDeep', payload);
};

const saveBooking = (booking: Booking): Promise<Booking> => {
  return store.dispatch('diary/saveBooking', booking);
};

const resetUnsavedChanges = (): void => {
  store.commit('diary/resetUnsavedChanges');
};

const setWorksordernotes = (val: string) => {
  tiptapworksordernotes.value = val;
};

const init = () => {
  tiptapeditorkey.value = uuidv4();
  tiptapworksordernotes.value = props.booking.worksordernotes;
}

const save = async () => {
  setBookingDeep({ path: 'worksordernotes', data: tiptapworksordernotes.value });
  if (!isNewBooking()) {
    await saveBooking(props.booking)
      .then(() => resetUnsavedChanges())
      .catch((err: any) => actProperty.displayError(err));
  }
  hide();
}

const isNewBooking = () => {
  return route.params.id == 'new';
};

const show = () => {
  if ($(`#${props.name}` + 'Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.name + 'Backdrop"></div>');
    $('body').append(backdropDiv);
    $(`#${props.name}`).show();
  }
}
const hide = () => {
  if ($(`#${props.name}`).length > 0) {
    $(`#${props.name}` + 'Backdrop').remove();
    $(`#${props.name}`).hide();
  }
};


defineExpose({ init, show, hide });
</script>

<style scoped lang="scss"></style>