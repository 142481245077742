<template>
  <nav>
    <ul class="pagination">
      <li
        v-for="(link, $index) in links"
        :key="$index"
        class="page-item"
        :class="{ active: $route.params.search == link }"
      >
        <router-link :to="`${link}`" class="page-link">
          {{ link.toUpperCase() }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import { computed, onMounted, watch, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import { Customer } from '@/models';

const store = useStore();
const route = useRoute();
const router = useRouter();
const actProperty: any = inject('actProperty');

const links = [
  "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "0-9",
];

const currentSearch = computed(() => route.params.search as string);

const getCustomersAction = (payload?: {
  starts_with?: string;
  company_name?: string;
  branch_name?: string;
}): Promise<Customer[]> => store.dispatch('customers/getCustomers', payload);

const getCustomers = (starts_with: string) => {
  getCustomersAction({ starts_with })
    .catch((err: any) => actProperty.displayError(err));
}

const init = () => {
  if (currentSearch.value) {
    getCustomers(currentSearch.value);
  }
};

onMounted(() => {
  if (currentSearch.value) {
    init();
  }
});

watch(currentSearch, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    init();
  }
});

// No need to convert getCustomers as it's already been handled above
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
