<template>
  <!-- "New Item" Modal -->
  <div :id="id" class="modal" data-keyboard="true" tabindex="-1" role="dialog"
    aria-labelledby="`midterm-question-modal-label`" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="add-room-item-modal-label" v-if="operation == 'add'">
            New Question
          </h5>
          <h5 class="modal-title" id="add-room-item-modal-label" v-else>
            {{ questionwrapper.prompt }}
          </h5>

          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" @click.prevent="hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <fieldset>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Prompt</label>
              <div class="col-sm-9">
                <input type="text" class="form-control" v-model="questionwrapper.prompt" />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Type</label>
              <div class="col-sm-9">
                <multiselect v-model="questionwrapper.type" track-by="slug" label="name" :options="typeOptions">
                </multiselect>
              </div>
            </div>

            <div class="form-group row" v-if="questionwrapper.type && questionwrapper.type.slug === 'select'
              ">
              <label class="col-sm-3 col-form-label">Options</label>
              <div class="col-sm-3">
                <div v-if="!questionwrapper.options.length">
                  <button @click="insertOption(0, 0)" class="btn btn-sm btn-outline-secondary">
                    <i class="fas fa-plus"></i> Option
                  </button>
                </div>
                <div v-for="(option, $optionIndex) in questionoptions" :key="option.slug">
                  <content-editable ref="options" :content="option.value" :data-uuid="option._uuid"
                    @insert="insertOption($optionIndex, $event)" @update="updateOption($optionIndex, $event)"
                    @delete="deleteOption($optionIndex)">
                  </content-editable>
                </div>
              </div>
            </div>

            <div class="form-group row" v-if="linkOptions.length">
              <label class="col-sm-3 col-form-label">Link</label>
              <div class="col-sm-9">
                <multiselect v-model="questionwrapper.link" track-by="slug" label="name" :allow-empty="true"
                  :options="linkOptions">
                  <template #clear v-if="linkOptions.length">
                    <i @mousedown.prevent.stop="clearLinkedItem()" class="multiselect__clear fa fa-times"
                      aria-label="Clear Linked Item"></i>
                  </template>
                </multiselect>
              </div>
            </div>

            <div class="form-group row" v-if="linkOptions && questionwrapper.link.slug">
              <label class="col-sm-3 col-form-label">Conditional</label>
              <div class="col-sm-9">
                <DxSwitch v-model="questionwrapper.conditional"
                  @value-changed="setCustomerdictionaryDeep({ path: 'questionwrapper.conditional', data: $event.value })" />
              </div>
            </div>

            <div class="form-group row" v-if="questionwrapper.link.slug && questionwrapper.conditional">
              <label class="col-sm-3 col-form-label">Link condition value</label>
              <div class="col-sm-9">
                <input type="text" class="form-control" v-model="questionwrapper.linkconditionvalue" />
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-sm-6 col-form-label">Flag</label>
                  <div class="col-sm-6">
                    <DxSwitch v-model="questionwrapper.flag"
                      @value-changed="setCustomerdictionaryDeep({ path: 'questionwrapper.flag', data: $event.value })" />
                  </div>
                </div>

                <div class="form-group row" v-if="questionwrapper.flag">
                  <label class="col-sm-6 col-form-label">Flag value</label>
                  <div class="col-sm-6">
                    <input type="text" class="form-control" v-model="questionwrapper.flagvalue" />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-6 col-form-label">Required</label>
                  <div class="col-sm-6">
                    <DxSwitch v-model="questionwrapper.required"
                      @value-changed="setCustomerdictionaryDeep({ path: 'questionwrapper.required', data: $event.value })" />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-6 col-form-label">Photo</label>
                  <div class="col-sm-6">
                    <DxSwitch v-model="questionwrapper.photo"
                      @value-changed="setCustomerdictionaryDeep({ path: 'questionwrapper.photo', data: $event.value })" />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-6 col-form-label">Notes</label>
                  <div class="col-sm-6">
                    <DxSwitch v-model="questionwrapper.notes"
                      @value-changed="setCustomerdictionaryDeep({ path: 'questionwrapper.notes', data: $event.value })" />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">FixFlo category</label>
                  <div class="col-sm-9">
                    <singleselect-text
                      v-model="fixfloCategoryForQuestion"
                      :options="fixfloItemCategoryOption">
                      <template v-if="fixfloCategoryForQuestion"
                          #clear>
                        <i
                          @mousedown.prevent.stop="fixfloCategoryForQuestion = ''"
                          class="multiselect__clear fa fa-times"
                          aria-label="Clear Job Type"
                        ></i>
                      </template>
                    </singleselect-text>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">FixFlo sub-category</label>
                  <div class="col-sm-9">
                    <singleselect-text
                      v-model="fixfloSubCategoryForQuestion"
                      :options="fixfloSubCategoryOption">
                      <template v-if="fixfloSubCategoryForQuestion"
                          #clear>
                        <i
                          @mousedown.prevent.stop="fixfloSubCategoryForQuestion = ''"
                          class="multiselect__clear fa fa-times"
                          aria-label="Clear Job Type"
                        ></i>
                      </template>
                    </singleselect-text>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary mr-auto" data-bs-dismiss="modal" @click.prevent="hide()">
            Back
          </button>
          <button type="submit" class="btn btn-primary" @click.prevent="add()" :disabled="!canAdd()"
            v-if="operation == 'add'">
            Add question
          </button>
          <button type="submit" class="btn btn-primary" @click.prevent="save()" :disabled="!canAdd()" v-else>
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import { computed, ref, inject, nextTick, defineProps, defineExpose } from 'vue';
import { snakeCase } from "lodash";
import { useStore } from 'vuex';
import { Compliancequestion, QuestionWrapper, SelectOption, QuestionOption } from "@/models";
import ContentEditable from "@/components/ContentEditable.vue";
import { CustomerDictionaryState } from '@/store/customerdictionary/types';
import { useToast } from "vue-toastification";
import { DxSwitch } from 'devextreme-vue/switch';
import fixflocategories from '@/store/dictionary/json/fixflocategories.json';

// Define props
const props = defineProps<{
  id: string,
  operation: string;
}>();

// Vuex store
const store = useStore();
const compliancelist = computed((): Compliancequestion[] => store.getters['customerdictionary/compliancelist'] as Compliancequestion[]);
const questionwrapper = computed(() => store.getters['customerdictionary/questionwrapper'] as QuestionWrapper);
const selectedquestion = computed(() => store.getters['customerdictionary/selectedquestion'] as Compliancequestion);

const addToComplianceList = async (question: {}) => {
  await store.dispatch('customerdictionary/addToComplianceList', question);
};

const setCustomerdictionaryDeep = async (payload: { path: string; data: any; }) => {
  await store.dispatch('customerdictionary/setCustomerdictionaryDeep', payload);
};
const actProperty: any = inject('actProperty');
const toasted = useToast();
const options = ref(null);

// Computed properties for options
const typeOptions = computed(() => [
  { name: "Select List", slug: "select" },
  { name: "Text", slug: "text" },
  { name: "Text Area", slug: "textarea" },
  { name: "Heading", slug: "heading" },
  { name: "Sub Heading", slug: "subheading" },
]);

const questionoptions = computed(() => questionwrapper.value.options);
const linkOptions = computed(() => {
  let optionList: SelectOption[] = [];
  if (compliancelist.value) {
    optionList = compliancelist.value
      .filter((question) => question != selectedquestion.value)
      .map((question) => ({ slug: question.slug, name: question.prompt }));
  }
  return optionList;
});

const clearLinkedItem = () => {
  questionwrapper.value.link = new SelectOption();
};

const insertOption = (optionIndex: number, $event?: any) => {
  let insertAtIndex = 0;

  if (typeof optionIndex === "number") {
    insertAtIndex = optionIndex + 1;
  }

  const option = new QuestionOption({});

  let cursorPos = -1;
  let selection: Selection | null = window.getSelection();
  if (selection) {
    cursorPos = selection.anchorOffset;
  }

  if (questionwrapper.value.options.length > optionIndex) {
    let currentOption = questionwrapper.value.options[optionIndex];
    let currentOptionValue = $event.currentTarget.innerText;
    if (
      currentOptionValue &&
      cursorPos >= 0 &&
      currentOptionValue.length > cursorPos
    ) {
      let contentsBeforeCursor = currentOptionValue.substring(0, cursorPos);
      let contentsAfterCursor = currentOptionValue.substring(
        cursorPos,
        currentOptionValue.length
      );
      currentOption.value = contentsBeforeCursor;
      option.value = contentsAfterCursor;
      const found = (options.value as any).find(
        (x: any) => x.$attrs["data-uuid"] === currentOption._uuid
      );
      if (found) {
        found.$el.innerHTML = contentsBeforeCursor;
      }
    }
  }

  questionwrapper.value.options.splice(insertAtIndex, 0, option);
  nextTick(() => {
    const found = (options.value as any).find((x: any) => x.$attrs["data-uuid"] === option._uuid);
    if (found) {
      setCursorAtEnd(found.$el);
    }
  });
};

const setCursorAtEnd = (el: any) => {
  if (!el) {
    return;
  }

  const range = document.createRange();
  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
  }

  if (el.firstChild) {
    range.setStart(el.firstChild, el.firstChild.length);
  } else {
    range.setStart(el, 0);
  }
  range.collapse(true);
  if (selection) {
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

const updateOption = (optionIndex: number, $event: any) => {
  questionwrapper.value.options[optionIndex].value = $event;
}

const deleteOption = (optionIndex: number) => {
  questionwrapper.value.options.splice(optionIndex, 1);
  const refs = (options as any);
  if (refs.length) {
    if (optionIndex > 0) {
      setCursorAtEnd(refs[optionIndex - 1].$el);
    } else {
      setCursorAtEnd(refs[0].$el);
    }
  }
}

const fixfloCategoryForQuestion = computed({
  get: () => {
    return questionwrapper.value.fixflocategory;
  },
  set:(val: string) => {
    questionwrapper.value.fixflocategory = val;
    questionwrapper.value.fixflosubcategory = '';
  }
});

const fixfloSubCategoryForQuestion = computed({
  get: () => {
    return questionwrapper.value.fixflosubcategory;
  },
  set:(val: string) => {
    questionwrapper.value.fixflosubcategory = val;
  }
});

const fixfloItemCategoryOption = computed(() => {
  return fixflocategories.map((c: any) => c.name).sort();
});
const fixfloSubCategoryOption = computed(() => {
  const index = fixflocategories.findIndex((c: any) => c.name === fixfloCategoryForQuestion.value);
  var list: string[] = [];
  if(index >= 0) {
    list = fixflocategories[index].subcategories;
  }
  return list.sort();
});

const canAdd = (): boolean => {
  let result = true;

  const qw = questionwrapper.value;

  if (!qw.prompt) result = false;
  if (!qw.type.slug) result = false;
  if (qw.type.slug === "select" && !qw.options) result = false;
  if (qw.conditional && !qw.linkconditionvalue) result = false;
  if (qw.flag && !qw.flagvalue) result = false;

  return result;
};

const add = async () => {
  let newQuestion = new Compliancequestion();
  newQuestion.slug = snakeCase(questionwrapper.value.prompt);
  newQuestion.prompt = questionwrapper.value.prompt;
  newQuestion.questionType = questionwrapper.value.type.slug;
  newQuestion.optionList = questionwrapper.value.options.map(option => option.value);
  newQuestion.link = questionwrapper.value.link.slug;
  newQuestion.conditional = questionwrapper.value.conditional;
  newQuestion.linkconditionvalue = questionwrapper.value.linkconditionvalue;
  newQuestion.flag = questionwrapper.value.flag;
  newQuestion.flagvalue = questionwrapper.value.flagvalue;
  newQuestion.required = questionwrapper.value.required;
  newQuestion.photo = questionwrapper.value.photo;
  newQuestion.notes = questionwrapper.value.notes;
  newQuestion.fixflocategory = questionwrapper.value.fixflocategory;
  newQuestion.fixflosubcategory = questionwrapper.value.fixflosubcategory;
  addToComplianceList(newQuestion)
    .then(() => toasted.success(`Added "${newQuestion.prompt}"`))
    .then(() => reset())
    .catch((err: any) => {
      actProperty.displayError(err);
    });
  hide();
};

const midTermQuestionModalEdit = ref(null);
const save = async () => {
  if (!selectedquestion.value.slug)
    selectedquestion.value.slug = snakeCase(questionwrapper.value.prompt);
  selectedquestion.value.prompt = questionwrapper.value.prompt;
  selectedquestion.value.questionType = questionwrapper.value.type.slug;
  selectedquestion.value.optionList = questionwrapper.value.options.map(
    (option) => option.value
  );
  selectedquestion.value.link = questionwrapper.value.link.slug;
  selectedquestion.value.conditional = questionwrapper.value.conditional;
  selectedquestion.value.linkconditionvalue =
    questionwrapper.value.linkconditionvalue;
  selectedquestion.value.flag = questionwrapper.value.flag;
  selectedquestion.value.flagvalue = questionwrapper.value.flagvalue;
  selectedquestion.value.required = questionwrapper.value.required;
  selectedquestion.value.photo = questionwrapper.value.photo;
  selectedquestion.value.notes = questionwrapper.value.notes;
  selectedquestion.value.fixflocategory = questionwrapper.value.fixflocategory;
  selectedquestion.value.fixflosubcategory = questionwrapper.value.fixflosubcategory;

  let index = compliancelist.value.findIndex(
    (q) => q.slug == selectedquestion.value.slug
  );
  if (index >= 0) {
    setCustomerdictionaryDeep({
      path: `compliance_list[${index}]`,
      data: selectedquestion.value,
    })
      .then(() =>
        toasted.warning(`Locally saved "${selectedquestion.value.prompt}. Please remember to press final Save"`)
      )
      .then(() => reset())
      .catch((err: any) => {
        actProperty.displayError(err);
      });
  }

  hide();
};

const reset = () => {
  questionwrapper.value.slug = "";
  questionwrapper.value.prompt = "";
  questionwrapper.value.type = new SelectOption();
  // ... reset other properties ...
};
const show = () => {
  if ($(`#${props.id}` + 'Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.id + 'Backdrop"></div>');
    $('body').append(backdropDiv);
    $(`#${props.id}`).show();
  }
}
const hide = () => {
  if ($(`#${props.id}`).length > 0) {
    $(`#${props.id}` + 'Backdrop').remove();
    $(`#${props.id}`).hide();
  }
};
defineExpose({ add, show, hide });

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
