/**
 * Getters
 *
 * Vuex allows us to define "getters" in the store.
 * - You can think of them as computed properties for stores.
 * - Like computed properties, a getter's result is cached based on its dependencies,
 *   and will only re-evaluate when some of its dependencies have changed.
 */
import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { RemotecommandState } from './types';
import { Remotecommand } from '@/models/remotesupport.model';
import _ from 'lodash';

export const getters: GetterTree<RemotecommandState, RootState> = {

    /**
	 * Get the current Remotecommand
	 * 
	 * @param { SupportState } state
	 * @returns Remotecommand command
	 */
    currentcommand: (state: RemotecommandState): Remotecommand|undefined => {
		return state.currentcommand;
	},

    /**
	 * Get the list of Remotecommands
	 * 
	 * @param { SupportState } state
	 * @returns List of commands
	 */
	commandlist: (state: RemotecommandState): Remotecommand[]|undefined => {
		return _.orderBy(state.commandlist, ['updatedAt'], ['desc']);
	},

};
