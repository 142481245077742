<template>
  <div
    :id="name"
    class="modal"
    data-keyboard="true"
    tabindex="-1"
    role="dialog"
    aria-labelledby="`smsmodal-label`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="sms-modal-label">
            {{ title }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click.prevent="hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div
          id="smslogs-scoller"
          class="modal-body"
          v-if="booking && booking.smslogs"
        >
          <div
            class="row message-body"
            v-for="(log, $index) in booking.smslogs.logs"
            :key="$index"
          >
            <div
              class="col-sm-12"
              :class="{
                'message-main-sender': log.inout === 'in',
                'message-main-receiver': log.inout === 'out',
              }"
            >
              <div
                :class="{
                  sender: log.inout === 'in',
                  receiver: log.inout === 'out',
                }"
              >
                <div class="message-text">
                  {{ log.message }}
                </div>
                <span
                  v-if="log.mobile"
                  :class="{
                    'message-time-right': log.inout === 'in',
                    'message-time-left': log.inout === 'out',
                  }"
                >
                  <i class="fas fa-mobile-alt"></i> {{ log.mobile }}<br
                /></span>
                <span
                  :class="{
                    'message-time-right': log.inout === 'in',
                    'message-time-left': log.inout === 'out',
                  }"
                >
                  {{ actProperty.formatUtcDatetimeForDisplay(log.logdate) }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary mr-auto"
            @click.prevent="refresh()"
          >
            Refresh
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary"
            data-dismiss="modal"
            @click.prevent="hide()"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import { defineProps, defineExpose, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { SMSLogs } from "@/models";
const props = defineProps<{
  name: string;
  title: string;
}>();

const store = useStore();

const actProperty: any = inject('actProperty');

const booking = computed(() => store.getters['diary/booking']);

const getSMSLogs = (bookingid: string): Promise<SMSLogs> => {
  return store.dispatch('diary/getSMSLogs', bookingid);
};
const refresh = () => {
  getSMSLogs(booking.value.id).then(() => {
    const container = document.querySelector("#smslogs-scoller");
    if (container) container.scrollTop = container.scrollHeight;
  });
};
const show = () => {
  if ($(`#${props.name}` + 'Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.name + 'Backdrop"></div>');
    $('body').append(backdropDiv);
    $(`#${props.name}`).show();
  }
}
const hide = () => {
  if ($(`#${props.name}`).length > 0) {
    $(`#${props.name}` + 'Backdrop').remove();
    $(`#${props.name}`).hide();
  }
};
defineExpose({show, hide });
</script>


<style scoped lang="scss">
.modal-body {
  max-height: 50vh;
  overflow-y: scroll;
}
</style>

<style lang="scss">
/*Conversation*/

.conversation {
  padding: 0 !important;
  margin: 0 !important;
  height: 100%;
  /*width: 100%;*/
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  /*overflow-y: auto;*/
}

.message {
  padding: 0 !important;
  margin: 0 !important;
  //background: url("w.jpg") no-repeat fixed center;
  background-size: cover;
  overflow-y: auto;
  border: 1px solid #f7f7f7;
  height: calc(100% - 120px);
}
.message-previous {
  margin: 0 !important;
  padding: 0 !important;
  height: auto;
  width: 100%;
}
.previous {
  font-size: 15px;
  text-align: center;
  padding: 10px !important;
  cursor: pointer;
}

.previous a {
  text-decoration: none;
  font-weight: 700;
}

.message-body {
  margin: 0 !important;
  padding: 0 0 2px 0 !important;
  width: auto;
  height: auto;
}

.message-main-receiver {
  /*padding: 10px 20px;*/
  //max-width: 60%;
}

.message-main-sender {
  padding: 3px 12px !important;
  //margin-left: 40% !important;
  //max-width: 60%;
}

.message-text {
  margin: 0 !important;
  padding: 5px !important;
  word-wrap: break-word;
  font-weight: 200;
  font-size: 14px;
  padding-bottom: 0 !important;
}

.message-time-right {
  margin: 0 !important;
  margin-left: 50px !important;
  font-size: 12px;
  text-align: right;
  color: #9a9a9a;
}

.message-time-left {
  margin: 0 !important;
  margin-right: 50px !important;
  font-size: 12px;
  text-align: right;
  color: #9a9a9a;
}

.receiver {
  width: auto !important;
  padding: 4px 10px 7px !important;
  border-radius: 10px 10px 10px 0;
  background: #f1f1f1;
  font-size: 12px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  word-wrap: break-word;
  display: inline-block;
}

.sender {
  float: right;
  width: auto !important;
  background: #dcf8c6;
  border-radius: 10px 10px 0 10px;
  padding: 4px 10px 7px !important;
  font-size: 12px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  display: inline-block;
  word-wrap: break-word;
}

/*Reply*/

.reply {
  height: 60px;
  width: 100%;
  background-color: #f5f1ee;
  padding: 10px 5px 10px 5px !important;
  margin: 0 !important;
  z-index: 1000;
}

.reply-emojis {
  padding: 5px !important;
}

.reply-emojis i {
  text-align: center;
  padding: 5px 5px 5px 5px !important;
  color: #93918f;
  cursor: pointer;
}

.reply-recording {
  padding: 5px !important;
}

.reply-recording i {
  text-align: center;
  padding: 5px !important;
  color: #93918f;
  cursor: pointer;
}

.reply-send {
  padding: 5px !important;
}

.reply-send i {
  text-align: center;
  padding: 5px !important;
  color: #93918f;
  cursor: pointer;
}

.reply-main {
  padding: 2px 5px !important;
}

.reply-main textarea {
  width: 100%;
  resize: none;
  overflow: hidden;
  padding: 5px !important;
  outline: none;
  border: none;
  text-indent: 5px;
  box-shadow: none;
  height: 100%;
  font-size: 16px;
}

.reply-main textarea:focus {
  outline: none;
  border: none;
  text-indent: 5px;
  box-shadow: none;
}

@media screen and (max-width: 700px) {
  .app {
    top: 0;
    height: 100%;
  }
  .heading {
    height: 70px;
    background-color: #009688;
  }
  .fa-2x {
    font-size: 2.3em !important;
  }
  .heading-avatar {
    padding: 0 !important;
  }
  .heading-avatar-icon img {
    height: 50px;
    width: 50px;
  }
  .heading-compose {
    padding: 5px !important;
  }
  .heading-compose i {
    color: #fff;
    cursor: pointer;
  }
  .heading-dot {
    padding: 5px !important;
    margin-left: 10px !important;
  }
  .heading-dot i {
    color: #fff;
    cursor: pointer;
  }
  .sideBar {
    height: calc(100% - 130px);
  }
  .sideBar-body {
    height: 80px;
  }
  .sideBar-avatar {
    text-align: left;
    padding: 0 8px !important;
  }
  .avatar-icon img {
    height: 55px;
    width: 55px;
  }
  .sideBar-main {
    padding: 0 !important;
  }
  .sideBar-main .row {
    padding: 0 !important;
    margin: 0 !important;
  }
  .sideBar-name {
    padding: 10px 5px !important;
  }
  .name-meta {
    font-size: 16px;
    padding: 5% !important;
  }
  .sideBar-time {
    padding: 10px !important;
  }
  .time-meta {
    text-align: right;
    font-size: 14px;
    padding: 4% !important;
    color: rgba(0, 0, 0, 0.4);
    vertical-align: baseline;
  }
  /*Conversation*/
  .conversation {
    padding: 0 !important;
    margin: 0 !important;
    height: 100%;
    /*width: 100%;*/
    border-left: 1px solid rgba(0, 0, 0, 0.08);
    /*overflow-y: auto;*/
  }
  .message {
    height: calc(100% - 140px);
  }
  .reply {
    height: 70px;
  }
  .reply-emojis {
    padding: 5px 0 !important;
  }
  .reply-emojis i {
    padding: 5px 2px !important;
    font-size: 1.8em !important;
  }
  .reply-main {
    padding: 2px 8px !important;
  }
  .reply-main textarea {
    padding: 8px !important;
    font-size: 18px;
  }
  .reply-recording {
    padding: 5px 0 !important;
  }
  .reply-recording i {
    padding: 5px 0 !important;
    font-size: 1.8em !important;
  }
  .reply-send {
    padding: 5px 0 !important;
  }
  .reply-send i {
    padding: 5px 2px 5px 0 !important;
    font-size: 1.8em !important;
  }
}
</style>