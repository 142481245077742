<template>
  <div class="dictionary templates">
    <section-type-selector
      :showSections="true"
      :showTypes="false"
      :showItems="false"
    />

    <div
      class="legend d-flex flex-row justify-content-around mb-3"
      v-if="hasActiveSection"
    >
      <div>
        <i class="fas fa-square"></i>
        Unselected
      </div>
      <div>
        <i class="fas fa-square"></i>
        Default
      </div>
      <div>
        <i class="fas fa-square"></i>
        Unfurnished
      </div>
      <div>
        <i class="fas fa-square"></i>
        Part furnished
      </div>
      <div>
        <i class="fas fa-square"></i>
        Fully furnished
      </div>
      <div>
        <i class="fas fa-square"></i>
        Fully furnished with linen
      </div>
    </div>

    <div class="card" v-if="hasActiveSection">
      <div class="card-body p-0">
        <table>
          <tbody>
            <tr>
              <td></td>
              <td
                v-for="category in [1, 2, 3, 4]"
                :key="category"
                class="rotate"
              >
                <div>
                  <span
                    ><strong>CAT {{ category }}</strong></span
                  >
                </div>
              </td>
              <td
                v-for="room in roomsByPriority"
                :key="room.name"
                class="rotate"
              >
                <div>
                  <span>{{ room.name }}</span>
                </div>
              </td>
            </tr>
            <tr v-for="type in currentTypes" :key="type.getSystemName()">
              <td>{{ type.getSystemName() }}</td>
              <td
                v-for="category in [1, 2, 3, 4]"
                :key="category"
                class="templatecell"
                @click="showCategoryModal($event, type, category)"
                data-toggle="modal"
                data-target="#template-select-modal"
              >
                <div class="templateicon no-template"></div>
                <div
                  class="templateicon"
                  :class="{ furnish0: isCategoryTemplate(category, type, 32) }"
                ></div>
              </td>
              <td
                v-for="room in roomsByPriority"
                :key="room.name"
                class="templatecell"
                @click="showRoomModal($event, type, room)"
                data-toggle="modal"
                data-target="#template-select-modal"
              >
                <div class="templateicon no-template"></div>
                <div
                  class="templateicon"
                  :class="{ furnish0: isRoomTemplate(room, type, 1) }"
                ></div>
                <div
                  class="templateicon"
                  :class="{ furnish1: isRoomTemplate(room, type, 2) }"
                ></div>
                <div
                  class="templateicon"
                  :class="{ furnish2: isRoomTemplate(room, type, 4) }"
                ></div>
                <div
                  class="templateicon"
                  :class="{ furnish3: isRoomTemplate(room, type, 8) }"
                ></div>
                <div
                  class="templateicon"
                  :class="{ furnish4: isRoomTemplate(room, type, 16) }"
                ></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modals -->
    <CustomerDictionaryTemplateSelectModal />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import _ from "lodash";
import { DictionaryType, DictionaryRoom, DictionaryCategory } from "@/models";
import { CustomerDictionaryState } from '@/store/customerdictionary/types';
import SectionTypeSelector from './partials/SectionTypeSelector.vue';
import CustomerDictionaryTemplateSelectModal from "@/components/modals/CustomerDictionaryTemplateSelectModal.vue";
const store = useStore();

const dictionaryState = computed(() => store.state.customerdictionary as CustomerDictionaryState);
const hasActiveSection = computed(() => store.getters['customerdictionary/hasActiveSection']);
const currentTypes = computed(() => store.getters['customerdictionary/currentTypes'] as DictionaryType[]);
const roomsByPriority = computed(() => store.getters['customerdictionary/roomsByPriority'] as DictionaryRoom[]);

const isRoomTemplate = (room: DictionaryRoom, type: DictionaryType, furnishStatusMask: number) => {
  const currentRoom = _.find(dictionaryState.value.current.rooms, (curr: DictionaryRoom) => room.name === curr.name);
  if (!currentRoom) {
    return false;
  }
  const sectionSlug = _.get(dictionaryState.value.activeSection, "slug");
  if (!sectionSlug) {
    return false;
  }
  const slug = `${sectionSlug}/${type.slug}`;
  const hasSlug = _.has(currentRoom.types, slug);
  const flagOn = currentRoom.types[slug] & furnishStatusMask;
  return Boolean(hasSlug && flagOn);
};

const isCategoryTemplate = (order: number, type: DictionaryType, furnishStatusMask: number) => {
  const category = _.find(dictionaryState.value.current.categories, (cat: DictionaryCategory) => order === cat.order);
  if (!category) {
    return false;
  }
  const sectionSlug = _.get(dictionaryState.value.activeSection, "slug");
  if (!sectionSlug) {
    return false;
  }
  const slug = `${sectionSlug}/${type.slug}`;
  return _.has(category.types, slug) && (category.types[slug] & furnishStatusMask);
};

const showRoomModal = ($event: Event, type: DictionaryType, room: DictionaryRoom) => {
  store.commit('customerdictionary/setActiveType', type);
  store.commit('customerdictionary/setActiveRoom', room);
  store.commit('customerdictionary/setActiveRoomCategory', 0);
};

const showCategoryModal = ($event: Event, type: DictionaryType, category: number) => {
  store.commit('customerdictionary/setActiveType', type);
  store.commit('customerdictionary/setActiveRoom', new DictionaryRoom());
  store.commit('customerdictionary/setActiveRoomCategory', category);
};
</script>


<!-- Imported CSS from http://www.merlinlive.co.uk:8082/merlin/resources/template/css/dictionarytable.css -->
<style scoped lang="scss">
table {
  border-collapse: collapse;
  width: 100%;
}

table.dictionarytable {
  margin: 0 0 0px;
  table-layout: fixed;
}

th {
  font-size: 14px;
  font-weight: bold;
}
th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}
td.category:hover {
  background-color: #f8bbd0;
}
td.selectedcategory {
  background-color: #f48fb1;
}

td.section:hover {
  background-color: #90caf9;
}
td.selectedsection {
  background-color: #42a5f5;
}

td.type:hover {
  background-color: #b39ddb;
}
td.selectedtype {
  background-color: #9575cd;
}

td.item:hover {
  background-color: #ffe0b2;
}
td.selecteditem {
  background-color: #ffb74d;
}

td.cicondition:hover {
  background-color: #c8e6c9;
}
td.selectedcicondition {
  background-color: #81c784;
}

td.cocondition:hover {
  background-color: #e6ee9c;
}
td.selectedcocondition {
  background-color: #c0ca33;
}

td.colour:hover {
  background-color: #b2dfdb;
}
td.selectedcolour {
  background-color: #4db6ac;
}

td.condition:hover {
  background-color: #b2ebf2;
}
td.selectedcondition {
  background-color: #4dd0e1;
}

.scrollable-600 {
  max-height: 600px !important;
  overflow: auto;
}

.scrollable-500 {
  max-height: 500px !important;
  overflow: auto;
}
.scrollable-400 {
  max-height: 400px !important;
  overflow: auto;
}

.scrollable-250 {
  max-height: 250px !important;
  overflow: auto;
}
.scrollable-200 {
  max-height: 200px !important;
  overflow: auto;
}

.scrollable-100 {
  max-height: 100px !important;
  overflow: auto;
}

th.content,
td.content {
  position: relative;
}
div.dictionaryoperations {
  position: absolute;
  top: 8px;
  right: 10px;
  text-align: right;
  display: inline;
}

.dictionaryoperationicon {
  padding-left: 3px;
  padding-right: 3px;
}

input.itemedittext,
input.itemedittext:focus {
  background-color: transparent;
  padding: 0px;
  min-height: 20px;
  border: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-rendering: auto;
  font-size: 12px;
  color: initial;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  margin: 0em 0em 0em 0em;
}

.navBardemoBasicUsage md-content .ext-content {
  padding: 50px;
  margin: 20px;
  background-color: #fff2e0;
}

.dictionarysavebutton {
  margin-top: 5px;
  height: 40px;
  float: right;
}

th.headercolumn {
  min-width: 285px;
}
td.spacer {
  min-height: 50px;
}

td.rotate {
  /* Something you can count on */
  height: 140px;
  white-space: nowrap;
}

td.rotate > div {
  transform: 
    /* Magic Numbers */ translate(25px, 51px)
    /* 45 is really 360 - 45 */ rotate(315deg);
  width: 30px;
}
td.rotate > div > span {
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
}

td.templatecell {
  width: 30px;
  height: 34px;
  border: 1px solid #ddd;
  padding: 3px 7px;
  vertical-align: top;
  // horizontal-align: center;
  position: relative;
}

.selectedtemplatesection {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  vertical-align: top;
}

.templateicon {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px, 30px;
  margin: auto;
  position: absolute;
}
.no-template {
  background-image: url("~@/assets/images/grey-donut.png");
}
.furnish0 {
  background-image: url("~@/assets/images/darkgrey-donut.png");
}
.furnish1 {
  background-image: url("~@/assets/images/donut-1.png");
}
.furnish2 {
  background-image: url("~@/assets/images/donut-2.png");
}
.furnish3 {
  background-image: url("~@/assets/images/donut-3.png");
}
.furnish4 {
  background-image: url("~@/assets/images/donut-4.png");
}

.checkboxSelectAll .legend {
  color: #3f51b5;
}

.checkboxSelectAll .fieldset {
  border-style: solid;
  border-width: 1px;
  height: 100%;
}

.checkboxSelectAll .select-all-checkboxes {
  padding-left: 30px;
}

div.md-toolbar-tools h2 {
  color: inherit;
}

.legendrow {
  margin-top: 5px;
  margin-bottom: 5px;
}

.spacer {
  height: 39px;
}

.borderonright {
  border-right: 1px solid #ddd;
}
.borderonbottom {
  border-bottom: 1px solid #ddd;
}
.zerobottommargin {
  margin-bottom: 0px;
}

.level1 {
  color: #2196f3;
}
.level2 {
  color: #f06292;
}
.level3 {
  color: #880e4f;
}

.templatetable {
  max-width: 950px;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
table {
  position: relative;
  width: auto;
  tr {
    position: relative;
    transform: scale(1);
  }
  td:first-child,
  th:first-child {
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    border-left: 1px solid white;
  }
}
.card-body {
  overflow: scroll;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
  &:before {
    content: "";
    display: block;
    top: 2px;
    bottom: 2px;
    width: 1px;
    background-color: white;
    z-index: 3;
    position: absolute;
  }
}

.legend {
  div:nth-child(1) i {
    color: #c0c0c0;
  }
  div:nth-child(2) i {
    color: #b39ed9;
  }
  div:nth-child(3) i {
    color: #fabf40;
  }
  div:nth-child(4) i {
    color: #7eb249;
  }
  div:nth-child(5) i {
    color: #7e5bbf;
  }
  div:nth-child(6) i {
    color: #1b277c;
  }
}
</style>
