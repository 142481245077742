/**
 * Getters
 *
 * Vuex allows us to define "getters" in the store.
 * - You can think of them as computed properties for stores.
 * - Like computed properties, a getter's result is cached based on its dependencies,
 *   and will only re-evaluate when some of its dependencies have changed.
 */
import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { OfficesState } from './types';

import { Office } from '@/models';

export const getters: GetterTree<OfficesState, RootState> = {

  /**
   * Are the Offices Empty?
   * 
   * @param {OfficesState} state 
   * @returns Boolean if office is empty
   */
  isEmpty: (state: OfficesState): boolean => {
    return state.offices.length === 0;
  },

  /**
   * Get the list of offices
   * 
   * @param state OfficesState
   * @returns offices list
   */
  getOffices: (state: OfficesState): Office[] => {
    return state.offices;
  },

  /**
   * Get the current Office
   * 
   * @param {OfficesState} state 
   * @returns Current offices
   */
  currentOffice: (state: OfficesState): Office | undefined => {
    return state.offices.find(x => x.id === state.currentOfficeId);
  },

};
