import _get from "lodash/get";
import _castArray from "lodash/castArray";
import { Base, Qcmember } from "@/models";
import { toCamelCase } from "@/utilities";
export class Qcmemberdailytarget extends Base {
  public id: string;
  public date: string;
  public working: boolean;
  public dailytarget: number;
  public qcmember: Qcmember;


  /**
   * Constructor initialises the variables from the class Qcmember
   * @param data - the data to construct a type with all properties of <Qcmember>
   */
  public constructor(data?: Partial<Qcmemberdailytarget>) {
    super(data);
    data = toCamelCase(data);
    this.id = _get(data, "id", "");
    this.date = _get(data, "date", '');
    this.working = _get(data, "working", false);
    this.dailytarget = _get(data, "dailytarget", 0);
    this.qcmember = new Qcmember(_get(data, "qcmember"));
  }

}
