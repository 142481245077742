import _get from "lodash/get";
import _castArray from "lodash/castArray";

import { Base } from "@/models";
import { toCamelCase } from "@/utilities";

export class InspectorTravelTime extends Base {
  public fromhome: number = -1;
  public fromhomestarttime: string = "";

  public bookingtime: string[] = [];
  public tobooking: number[] = [];
  public tobookingstarttime: string[] = [];
  public frombooking: number[] = [];
  public frombookingstarttime: string[] = [];

  public constructor(data?: Partial<InspectorTravelTime>) {
    super(data);
    data = toCamelCase(data);
  }

  get ttpairs() {
    let pairarray = [];
    for (let i = 0; i < this.tobooking.length; i++) {
      pairarray.push({
        bookingtime: this.bookingtime[i],
        to: this.tobooking[i],
        tostart: this.tobookingstarttime[i],
        from: this.frombooking[i],
        fromstart: this.frombookingstarttime[i],
      });
    }
    return pairarray;
  }
}
