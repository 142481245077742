<template>
  <div class="template" :class="{ selected: selected }">
    <div class="row">
      <div class="col-md-12">
        {{ employeetitle }}
      </div>
      <div class="col-md-12" v-if="!props.pidiary && employee.data.itype">
        {{ employee.data.itype }}
      </div>
    </div>
    <div class="row" @click="emitClick(employee.data)">
      <div class="col-md-12 container">
        <div class="first-div">
          <img
            v-if="employee.data.picture && employee.data.picture.length > 0"
            :src="actProperty.s3Origin(employee.data.picture[0].src)"
            class="avatar"
          />
          <div v-else class="chip">
            <div class="chip-head">
              <i class="fas fa-cog fa-spin" v-if="employee.data.calculatingtraveltime"></i>
              <avatar
                v-else
                :name="employee.data.name"
                :size="25"
                :inline="true"
                :background="employeecolour"
                color="#fff"
              ></avatar>
            </div>
            <div class="chip-content">
              {{ employee.data.address.postcode }}
            </div>
          </div>
        </div>
        <div class="second-div">
          <div v-if="employee.data.fromDurationSecs >= 0"
            style="position:absolute; bottom:0; left: 20px;"
            :title="employee.data.fromDurationText"
            >
            <span class="fa-stack duration-badge">
              <i class="fa fa-circle duration-badge-circle" 
                :class="{'top-badge-circle': employee.data.topTo }"></i>
              <strong class="fa-stack-1x duration-badge-number"
                :class="{'top-badge-text': employee.data.topTo }"
                v-if="employee.data.fromDurationSecs < 5941">{{ employee.data.fromDurationMins ? employee.data.fromDurationMins : '0' }}</strong>
              <i v-else class="fa-stack-1x duration-badge-number fas fa-times"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, inject, defineProps, defineEmits } from 'vue';
import { getRegionColor } from "@/utilities";

const emit = defineEmits(['click']);

// Define props
const props = defineProps({
  employee: Object as () => any,
  highlightemployee: String,
  pidiary: Boolean,
});

// Computed properties
const selected = computed(() => {
  return (
    props.employee &&
    props.employee.data &&
    props.employee.data.id === props.highlightemployee
  );
});

const employeecolour = computed(() => {
  return getRegionColor(props.employee.data.region);
});

const employeetitle = computed(() => {
  let lines: string[] = [];
  if (props.employee.data.region) {
    lines.push(props.employee.data.region.replace("Region ", ""));
  }
  lines.push(props.employee.data.name);
  return lines.join(" - ");
});

const emitClick = (insp: any) => {
  emit('click', insp);
}

// Inject actProperty
const actProperty: any = inject('actProperty');
</script>


<style scoped lang="scss">
.container {
  position: relative;
  overflow: hidden !important;
  width: 100%;
  height: 44px;
}

.first-div {
  position: absolute;
  background: transparent;
  overflow: hidden !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 3px;
}

.second-div {
  position: absolute;
  background: transparent;
  overflow: hidden !important;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0px;
}
.duration-badge {
  height: 1.5em;
  line-height: 1.5em;
  width: 1.5em;
}
.duration-badge-circle {
  font-size: 20px;
  color: #cccccc;
}
.duration-badge-number {
  font-size: 12px;
  color: #253746;
}

.top-badge-circle {
  color: yellow;
}
.top-badge-text {
  color: #253746;
}

.chip {
  display: inline-flex;
  flex-direction: row;
  background-color: #e5e5e5;
  color: rgb(127, 127, 127);
  border: none;
  cursor: default;
  height: 20px;
  outline: none;
  padding: 0;
  font-size: 10px;
  font-color: #333333;
  font-family: "Open Sans", sans-serif;
  white-space: nowrap;
  align-items: center;
  border-radius: 10px;
  vertical-align: middle;
  text-decoration: none;
  justify-content: center;
}
.chip-head {
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: #969696;
  font-size: 1.25rem;
  flex-shrink: 0;
  align-items: center;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
  width: 20px;
  color: #fff;
  height: 20px;
  font-size: 10px;
  margin-right: -4px;
}
.chip-content {
  cursor: inherit;
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
}

</style>

<style>
.avatar {
  vertical-align: middle;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-family: inherit !important;
}
</style>
