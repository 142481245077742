import _castArray from 'lodash/castArray';
import _get from 'lodash/get';

import { toCamelCase, omitDeep } from '@/utilities';

import {
  Base,
  Inspector,
  PmLandlord,
  PmProperty,
  PmPropertyManager,
  PmTenant
} from '@/models';
export class PmTenancy extends Base {
  public id: string;
  public startDate: string;
  public endDate: string;
  public pvDueDate: string;
  public property: PmProperty;
  public propertymanager: PmPropertyManager;
  public tenants: PmTenant[];
  public landlords: PmLandlord[];
  public inspector: Inspector;
  public constructor(data?: Partial<PmTenancy>) {
    super(data);
    data = toCamelCase(data);
    this.id = _get(data, 'id', '');
    this.startDate = _get(data, 'startDate', '');
    this.endDate = _get(data, 'endDate', '');
    this.pvDueDate = _get(data, 'pvDueDate', '');
    this.property = new PmProperty(_get(data, "property"));
    this.propertymanager = new PmPropertyManager(_get(data, "propertymanager"));
    this.tenants = _castArray(_get(data, "tenants", [])).map(
      (x: Partial<PmTenant>) => new PmTenant(x)
    );
    this.landlords = _castArray(_get(data, "landlords", [])).map(
      (x: Partial<PmLandlord>) => new PmLandlord(x)
    );
    this.inspector = new Inspector(_get(data, "inspector"));
  }

  public toObject() {
    return omitDeep({ ...this }, ['_uuid']);
  }
}