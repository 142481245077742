import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { Base, Photo } from '@/models';
import { toCamelCase } from '@/utilities';

export class Complianceitem extends Base {
    public slug: string;
    public prompt: string;
    public notes: string;
    public value: string;
    public photos: Photo[];

    public subheadingitem?: boolean;

    /**
     * Constructor initialises the variables from the class Complianceitem
     * @param data - the data to construct a type with all properties of <Complianceitem>
     */
    public constructor(data?: Partial<Complianceitem>) {
        super(data);
        data = toCamelCase(data);

        this.slug = _get(data, 'slug', '');
        this.prompt = _get(data, 'prompt', '');
        this.notes = _get(data, 'notes', '');
        this.value = _get(data, 'value', '');
        this.slug = _get(data, 'slug', '');
        this.photos = _castArray(_get(data, 'photos', [])).map((x: Partial<Photo>) => new Photo(x));
    }

}
