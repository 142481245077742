<template>
  <div class="report-section property-details">
    <fieldset>
      <legend>Visit Checks</legend>
    </fieldset>

    <div
      v-for="(compliancesection, $index) in report.compliancesections"
      :key="compliancesection._uuid"
    >
      <compliance-section
        :compliancesection="compliancesection"
        :index="$index"
        :opened="
          sectionsShowBody[$index] === undefined
            ? true
            : sectionsShowBody[$index]
        "
        :toggle="true"
        :class="{ activeCard: focused === $index }"
        ref="cards"
        @showBody="syncSectionsShowBody"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted, inject, computed } from 'vue';
import _get from "lodash/get";
import _ from "lodash";
import {
  CustomerDictionary,
  Compliancequestion,
  Report,
  Compliancesection,
} from "@/models";
import ComplianceSection from "@/components/visitchecks/ComplianceSection.vue";
import { useStore } from 'vuex';
import { useEvent } from '@/eventBus';

const store = useStore();

const report = computed<Report>(() => store.getters['reports/current']);
const customerdictionary = computed<CustomerDictionary>(() => store.getters['customerdictionary/current']);
const compliancelist = computed<Compliancequestion[]>(() => store.getters['customerdictionary/compliancelist']);

const actProperty: any = inject('actProperty');

const cards = ref(null);

const setReportDeepWithoutCounting = (payload: {
  path: string;
  data: any;
}): Promise<any> => {
  return store.dispatch('reports/setReportDeepWithoutCounting', payload);
};

const getCustomersDictionaryAction = (payload?: {
  companyname?: string;
  branchname?: string;
  reporttype?: string;
}): Promise<CustomerDictionary> => {
  return store.dispatch('customerdictionary/getCustomerDictionary', payload);
};

const sectionsShowBody = ref<boolean[]>([]);
const focused = ref<number | null>(null);

onMounted(() => {
  if (report.value && report.value.companyName) {
    let cname = report.value.companyName;
    if (report.value.branchName && report.value.branchName != "default")
      cname = report.value.branchName;
    getCustomerDictionary(cname, "default", report.value.type);

    if (report.value.compliancesections && compliancelist.value) {
      let unsaved = false;
      if (!report.value.compliancesections) {
        report.value.compliancesections = [];
        unsaved = false;
      }

      let headinglist: string[] = [];
      let sectionMap = new Map<string, Compliancesection>();
      compliancelist.value.forEach((question) => {
        if (question.questionType === "heading") {
          let section = report.value.compliancesections.find(
            (s) => s.heading === question.prompt
          );
          if (!section) {
            section = new Compliancesection();
            section.heading = question.prompt;
            section.complianceitems = [];
            report.value.compliancesections.push(section);
            unsaved = true;
          }
          headinglist.push(question.prompt);
          sectionMap.set(section.heading, section);
        }
      });

      if (unsaved) {
        report.value.compliancesections.length = 0;
        headinglist.forEach((heading) => {
          let section = sectionMap.get(heading);
          if (section) report.value.compliancesections.push(section);
        });
        setReportDeepWithoutCounting({
          path: `compliancesections`,
          data: report.value.compliancesections,
        });
      }
    }
  }
});

const getCustomerDictionary = (
  companyname: any,
  branchname: any,
  reporttype: any
) => {
  if (companyname && companyname === "ACT") companyname = "default";
  getCustomersDictionaryAction({
    companyname,
    branchname,
    reporttype,
  }).catch((err: any) => {
    actProperty.displayError(err);
  });
}

/**
 * Expand all section
 */
const expandAll = (): void => {
  const sectionsOpened: boolean[] = [];
  if (cards.value) {
    (cards as any[]).forEach((card, index) => {
      card.showBody = true;
      sectionsOpened[index] = true;
    });
  }

  sectionsShowBody.value = sectionsOpened;
}

/**
 * Collapse all section
 */
const collapseAll = (): void => {
  const esctionClosed: boolean[] = [];
  if (cards.value) {
    (cards as any[]).forEach((card, index) => {
      card.showBody = false;
      esctionClosed[index] = false;
    });
  }
  sectionsShowBody.value = esctionClosed;
}

useEvent("expandAll", () => expandAll());
useEvent("collapseAll", () => collapseAll());

const setActiveCard = (index: any) => {
  focused.value = index;
}

/**
 * Show/Hide Section Body
 *
 * @param {Number} index
 * @param {Boolean} showBody
 * @returns boolean value if room body is shown/hidden
 */
const syncSectionsShowBody = (index: number, showBody: boolean) => {
  const cardsrefArray = cards as any[];
  if (cardsrefArray.length !== sectionsShowBody.value.length) {
    // To cope with adding/removing section
    // or sometimes mounted won't set up this.sectionsShowBody correctly
    const section: boolean[] = [];
    for (let i = 0; i < cardsrefArray.length; i++) {
      if (sectionsShowBody.value[i] === undefined) {
        section[i] = true;
      } else if (i === index) {
        section[i] = showBody;
      } else {
        section[i] = sectionsShowBody.value[i];
      }
    }
    sectionsShowBody.value = section;
  } else {
    sectionsShowBody.value = sectionsShowBody.value.map((value, i) => {
      return i === index ? showBody : value;
    });
  }
}

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
