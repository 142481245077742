<template>
  <div class="report-section invoice">
    <fieldset>
      <legend>Client/Landlord</legend>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Landlord/Client name</label>
        <div class="col-sm-3">
          <input type="text" class="form-control" v-model.lazy="clientName" />
          <p v-if="llnamerequired && !clientName" class="error-message">
            Landord name is required
          </p>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">E-mail</label>
        <div class="col-sm-6">
          <input type="text" class="form-control" v-model="clientEmail" />
          <p v-if="llemailrequired && !clientEmail" class="error-message">
            Landord e-mail is required
          </p>
        </div>
      </div>
    </fieldset>

    <fieldset>
      <legend>Customer</legend>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Company</label>
        <div class="col-sm-3">
          <multiselect-text
            v-model="customerCompanyName"
            :value="customerCompanyName"
            :options="companyNameOptions"
            :allow-empty="true"
            :taggable="true"
            @tag="onTagCompanyName"
            @select="onSelectCompanyName"
          >
            <template 
                #clear
                v-if="customerCompanyName && customerCompanyName.length">
              <i
                @mousedown.prevent.stop="onTagCompanyName('')"
                class="multiselect__clear fa fa-times"
                aria-label="Clear Company Name"
              ></i>
            </template>
          </multiselect-text>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Branch</label>
        <div class="col-sm-3">
          <multiselect-text
            v-model="customerBranchName"
            :value="customerBranchName"
            :options="branchNameOptions"
            :allow-empty="true"
            :disabled="!customerCompanyName || customerCompanyName.length < 1"
            :taggable="true"
            @tag="onTagBranchName"
            @select="onSelectBranchName"
          >
            <template #clear v-if="customerBranchName && customerBranchName.length">
              <i
                @mousedown.prevent.stop="onTagBranchName('')"
                class="multiselect__clear fa fa-times"
                aria-label="Clear Branch Name"
              ></i>
            </template>
          </multiselect-text>
        </div>
      </div>

      <input
        type="hidden"
        class="form-control"
        disabled
        v-model.lazy="customerBranchId"
      />

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">C/o</label>
        <div class="col-sm-6">
          <input
            type="text"
            class="form-control"
            :disabled="isExistingCustomer() && !statereport.customer.editable"
            v-model.lazy="customerCo"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label"
          >Address <span class="sr-only">Line 1</span></label
        >
        <div class="col-sm-6">
          <input
            type="text"
            class="form-control"
            :disabled="isExistingCustomer() && !statereport.customer.editable"
            v-model.lazy="customerAddressLine1"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label"
          ><span class="sr-only">Address Line 2</span></label
        >
        <div class="col-sm-6">
          <input
            type="text"
            class="form-control"
            :disabled="isExistingCustomer() && !statereport.customer.editable"
            v-model.lazy="customerAddressLine2"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">City</label>
        <div class="col-sm-3">
          <input
            type="text"
            class="form-control"
            :disabled="isExistingCustomer() && !statereport.customer.editable"
            v-model.lazy="customerAddressTown"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">County</label>
        <div class="col-sm-3">
          <input
            type="text"
            class="form-control"
            :disabled="isExistingCustomer() && !statereport.customer.editable"
            v-model.lazy="customerAddressCounty"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Postcode</label>
        <div class="col-sm-2">
          <input
            type="text"
            class="form-control"
            :disabled="isExistingCustomer() && !statereport.customer.editable"
            v-model.lazy="customerAddressPostcode"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">A/C Client Code</label>
        <div class="col-sm-4">
          <input
            type="text"
            class="form-control"
            :disabled="isExistingCustomer() && !statereport.customer.editable"
            v-model.lazy="customerAcClientCode"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Nominal Code</label>
        <div class="col-sm-4">
          <input
            type="text"
            class="form-control"
            :disabled="isExistingCustomer() && !statereport.customer.editable"
            v-model.lazy="customerNominalCode"
          />
        </div>
      </div>

      <div v-if="!isExistingCustomer()">
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Feedback link active</label>
          <div class="col-sm-4">
            <DxSwitch v-model="feedbacklinkactive"/>
          </div>
        </div>
        <div v-if="feedbacklinkactive" class="form-group row">
          <label class="col-sm-2 col-form-label"
            >Feedback link active for (Days)</label
          >
          <div class="col-sm-4">
            <singleselect-text
              v-model="feedbacklinkactivedays"
              :value="feedbacklinkactivedays"
              :options="listofdays"
            >
            </singleselect-text>
          </div>
        </div>
      </div>
    </fieldset>

    <fieldset>
      <legend>Job Details</legend>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label"
          >Works/Purchase order number</label
        >
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-6">
              <input
                type="text"
                class="form-control"
                v-model="workorderNumber"
              />
            </div>
            <div class="col-sm-12">
              <p
                v-if="workordernumberrequired && !workorderNumber"
                class="error-message"
              >
                Works/Purchase order number is required
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Fees</label>
        <div class="col-sm-6">
          <div v-for="(fee, $index) in fees" :key="fee._uuid" class="row mb-3">
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                v-model.lazy="fee.name"
                @change="editFee(fee,$index)"
                :placeholder="
                  $index === 0
                    ? actProperty.formatReportType(statereport.type, dictionary)
                    : ''
                "
              />
            </div>
            <div class="col-4">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">&pound;</span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  size="6"
                  @change="editFee(fee,$index)"
                  v-model.lazy="fee.amount"
                  :disabled="!customerBranchName || !customerBranchName.length"
                />
              </div>
            </div>
            <div class="col-1">
              <button
                v-if="$index !== 0"
                class="btn btn-outline-danger"
                @click="removeFee($index)"
                tabindex="-1"
              >
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
          <button
            class="btn btn-primary"
            ref="defaultFee"
            @click="addNewFee()"
            :disabled="!customerBranchName || !customerBranchName.length"
          >
            <i class="fas fa-plus"></i> Fees
          </button>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, inject, onMounted, watch, defineProps } from 'vue';
import { useStore } from 'vuex';
import { Fee, Customer, Dictionary, Report, Depository } from '@/models';
import { DxSwitch } from 'devextreme-vue/switch';
import { useEvent } from '@/eventBus';

const store = useStore();
const actProperty: any = inject('actProperty');

const listofdays = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30,
];

const isAdministrator = computed(() => store.getters['auth/isAdministrator']);
const isCustomer = computed(() => store.getters['auth/isCustomer']);
const dictionary = computed(() => store.getters['dictionary/current']);
const statereport = computed(() => store.getters['reports/current']);

// Converting @Action to arrow functions
const setReportDeep = (payload: { path: string; data: any }): Promise<any> => {
  return store.dispatch('reports/setReportDeep', payload);
};

const getCustomers = (payload?: {
  starts_with?: string;
  company_name?: string;
  branch_name?: string;
}): Promise<Customer[]> => {
  return store.dispatch('customers/getCustomers', payload);
};

const getCompanyNameOptions = (): Promise<any> => {
  return store.dispatch('customers/getCompanyNameOptions');
};

const getBranchNameOptions = (companyName?: string): Promise<any> => {
  return store.dispatch('customers/getBranchNameOptions', companyName);
};

// Converting @Mutation to arrow functions
const setCurrentReportFlagged = (flagged: boolean): void => {
  store.commit('reports/setCurrentReportFlagged', flagged);
};

const setCurrentCOSMReportFlagged = (flaggedCOSM: boolean): void => {
  store.commit('reports/setCurrentCOSMReportFlagged', flaggedCOSM);
};

const setReportDeepWithoutCounting = (payload: {
  path: string;
  data: any;
}): Promise<any> => {
  return store.dispatch('reports/setReportDeepWithoutCounting', payload);
};

const companyNameOptions = ref<string[]>([]);
const branchNameOptions = ref<string[]>([]);

onMounted(() => {
  useEvent("reportLoadedOnEditReportPage", (report: Report) => {
    Promise.all([
      getCompanyNameOptions(),
      getBranchNameOptions(customerCompanyName.value),
    ])
      .then((values) => {
        companyNameOptions.value = values[0];
        branchNameOptions.value = values[1];
      })
      .catch((err) => {
        actProperty.displayError(err);
      });
  });
  
  store.subscribe((mutation, state) => {
    if (!isCustomer.value && mutation.type === "reports/setReport") {
      initFeedback();
    }
  });

  setTimeout(() => {
    if (!isCustomer.value && fees.value.length === 0) {
      setReportDeepWithoutCounting({ path: "fees", data: [new Fee()] });
    }
  }, 2000);
});

const clientName = computed({
  get: () => statereport.value.client.name,
  set: (data) => setReportDeep({ path: 'client.name', data })
});

// clientEmail
const clientEmail = computed({
  get: () => statereport.value.client.email,
  set: (data) => setReportDeep({ path: 'client.email', data })
});

// customerCompanyName
const customerCompanyName = computed({
  get: () => statereport.value.customer.companyName,
  set: (data) => setReportDeep({ path: 'customer.companyName', data })
});

// customerBranchId
const customerBranchId = computed({
  get: () => {
    return statereport.value.customerId
  },
  set: (data) => setReportDeep({ path: 'customerId', data })
});

// customerBranchName
const customerBranchName = computed({
  get: () => statereport.value.customer.branchName,
  set: (data) => setReportDeep({ path: 'customer.branchName', data })
});

// customerId
const customerId = computed({
  get: () => statereport.value.customer.id,
  set: (data) => setReportDeep({ path: 'customer.id', data })
});

// customerCo
const customerCo = computed({
  get: () => statereport.value.customer.co,
  set: (data) => setReportDeep({ path: 'customer.co', data })
});

// customerAddressLine1
const customerAddressLine1 = computed({
  get: () => statereport.value.customer.address.line1,
  set: (data) => setReportDeep({ path: 'customer.address.line1', data })
});

// customerAddressLine2
const customerAddressLine2 = computed({
  get: () => statereport.value.customer.address.line2,
  set: (data) => setReportDeep({ path: "customer.address.line2", data })
});

// customerAddressTown
const customerAddressTown = computed({
  get: () => statereport.value.customer.address.town,
  set: (data) => setReportDeep({ path: "customer.address.town", data })
});

// customerAddressCounty
const customerAddressCounty = computed({
  get: () => statereport.value.customer.address.county,
  set: (data) => setReportDeep({ path: "customer.address.county", data })
});

// customerAddressPostcode
const customerAddressPostcode = computed({
  get: () => statereport.value.customer.address.postcode,
  set: (data) => setReportDeep({ path: "customer.address.postcode", data })
});

// customerAcClientCode
const customerAcClientCode = computed({
  get: () => statereport.value.customer.acClientCode,
  set: (data) => setReportDeep({ path: "customer.acClientCode", data })
});

// customerNominalCode
const customerNominalCode = computed({
  get: () => statereport.value.customer.nominalCode,
  set: (data) => setReportDeep({ path: "customer.nominalCode", data })
});

// customerOffice
const customerOffice = computed({
  get: () => statereport.value.customer.office,
  set: (data) => setReportDeep({ path: "customer.office", data })
});

// feedbacklinkactive
const feedbacklinkactive = computed({
  get: () => statereport.value.customer.feedbacklinkactive,
  set: (data) => {
    if (!isCustomer.value && feedbacklinkactive.value !== data) {
      setReportDeep({ path: "customer.feedbacklinkactive", data });
    }
  }
});

// feedbacklinkactivedays
const feedbacklinkactivedays = computed({
  get: () => statereport.value.customer.feedbacklinkactivedays,
  set: (data) => {
    if (!isCustomer.value && feedbacklinkactivedays.value !== data) {
      setReportDeep({ path: "customer.feedbacklinkactivedays", data });
    }
  }
});

// workordernumberrequired
const workordernumberrequired = computed({
  get: () => statereport.value.customer.workordernumberrequired,
  set: (data) => setReportDeep({ path: "customer.workordernumberrequired", data })
});

// llnamerequired
const llnamerequired = computed({
  get: () => statereport.value.customer.accountsettings.llnamerequired,
  set: (data) => setReportDeep({ path: "customer.accountsettings.llnamerequired", data })
});

// llemailrequired
const llemailrequired = computed({
  get: () => statereport.value.customer.accountsettings.llemailrequired,
  set: (data) => setReportDeep({ path: "customer.accountsettings.llemailrequired", data })
});

// workorderNumber
const workorderNumber = computed({
  get: () => statereport.value.workorderNumber,
  set: (data) => setReportDeep({ path: "workorderNumber", data })
});

// redlinecoactive
const redlinecoactive = computed({
  get: () => statereport.value.customer.reportsettings.redlinecoactive,
  set: (data) => setReportDeep({ path: "customer.reportsettings.redlinecoactive", data })
});

// cosummaryactive
const cosummaryactive = computed({
  get: () => statereport.value.customer.reportsettings.cosummaryactive,
  set: (data) => setReportDeep({ path: "customer.reportsettings.cosummaryactive", data })
});

// cosmredlineactive
const cosmredlineactive = computed({
  get: () => statereport.value.customer.reportsettings.cosmredlineactive,
  set: (data) => setReportDeep({ path: "customer.reportsettings.cosmredlineactive", data })
});

// depository
const depository = computed({
  get: () => statereport.value.customer.depository,
  set: (depository) => setReportDeep({ path: "customer.depository", data: depository })
});

// editable
const editable = computed({
  get: () => statereport.value.customer.editable,
  set: (data) => setReportDeep({ path: "customer.editable", data })
});

// fees
const fees = computed({
  get: () => statereport.value.fees,
  set: (data) => setReportDeep({ path: "fees", data })
});

// hidefrontpageaddress
const hidefrontpageaddress = computed({
  get: () => statereport.value.customer.hidefrontpageaddress,
  set: (data) => setReportDeep({ path: "customer.hidefrontpageaddress", data })
});

// Add new Fee to Invoice
const addNewFee = () => {
  fees.value = fees.value.concat([new Fee()]);
};

// Remove Fee from Invoice
const removeFee = (index) => {
  actProperty.confirmPrompt()
    .then(() => {
      fees.value = actProperty.removeItemByIndex(fees.value, index);
    });
};

// Get Branch details when Company Name has been selected
const onSelectCompanyName = async (companyName) => {
  if (companyName) {
    branchNameOptions.value = await getBranchNameOptions(companyName);
  } else {
    customerCompanyName.value = "";
  }
  customerBranchId.value = undefined;
  customerBranchName.value = "";
  resetBranchDetails();
};

// Add Company Name as tag on Div
const onTagCompanyName = (val) => {
  customerCompanyName.value = val;
  onTagBranchName("");
};

// Get Branch details when Company Name has been selected
const onSelectBranchName = async (branchName) => {
  customerBranchName.value = branchName;

  if (!customerBranchName.value || !customerCompanyName.value) {
    return actProperty.displayError("Provide both a Company and a Branch");
  }

  const customers = await getCustomers({
    company_name: customerCompanyName.value,
    branch_name: customerBranchName.value,
  });

  if (customers.length) {
    setBranchDetails(customers[0]);
  } else {
    resetBranchDetails();
  }
};

// Add Branch Name as tag on Div
const onTagBranchName = (val) => {
  customerBranchName.value = val;
  customerBranchId.value = undefined;
  resetBranchDetails();
};

// Assuming the reactive properties and methods used are available in the scope

// Set Branch Details
const setBranchDetails = (customer) => {
  customerBranchId.value = customer.id;
  customerId.value = customer.id;
  customerOffice.value = customer.office;
  customerCo.value = customer.co;
  customerAddressLine1.value = customer.address.line1;
  customerAddressLine2.value = customer.address.line2;
  customerAddressTown.value = customer.address.town;
  customerAddressCounty.value = customer.address.county;
  customerAddressPostcode.value = customer.address.postcode;
  customerAcClientCode.value = customer.acClientCode;
  customerNominalCode.value = customer.nominalCode;
  feedbacklinkactive.value = customer.feedbacklinkactive;
  feedbacklinkactivedays.value = customer.feedbacklinkactivedays;
  workordernumberrequired.value = customer.workordernumberrequired;
  llnamerequired.value = customer.accountsettings.llnamerequired;
  llemailrequired.value = customer.accountsettings.llemailrequired;
  hidefrontpageaddress.value = customer.hidefrontpageaddress;

  cosummaryactive.value = customer.reportsettings.cosummaryactive;
  redlinecoactive.value = customer.reportsettings.redlinecoactive;
  setCurrentReportFlagged(customer.reportsettings.redlinecoactive);
  cosmredlineactive.value = customer.reportsettings.cosmredlineactive;
  setCurrentCOSMReportFlagged(customer.reportsettings.cosmredlineactive);
  depository.value = customer.depository;
  editable.value = customer.editable;
};

// Reset Branch Details
const resetBranchDetails = () => {
  customerCo.value = "";
  customerOffice.value = "";
  customerAddressLine1.value = "";
  customerAddressLine2.value = "";
  customerAddressTown.value = "";
  customerAddressCounty.value = "";
  customerAddressPostcode.value = "";
  customerAcClientCode.value = "";
  customerNominalCode.value = "";
  feedbacklinkactive.value = true;
  feedbacklinkactivedays.value = 22;
  workordernumberrequired.value = false;
  llemailrequired.value = false;
  hidefrontpageaddress.value = false;

  cosummaryactive.value = true;
  redlinecoactive.value = true;
  setCurrentReportFlagged(true);
  cosmredlineactive.value = false;
  setCurrentCOSMReportFlagged(false);
  editable.value = false;
};

// Initialize Feedback
const initFeedback = () => {
  if (!statereport.value.customer || (!customerCo.value && !customerAddressLine1.value)) {
    feedbacklinkactive.value = true;
    feedbacklinkactivedays.value = 22;
  }
};

// Check if the customer/branch is new
const isNewCustomer = () => {
  return customerCompanyName.value === "" || customerBranchId.value === undefined;
};

// Check if the customer/branch already exists
const isExistingCustomer = () => {
  return !isNewCustomer();
};

const editFee = (fee:Fee,index:Number) => {
 setReportDeep({
    path: `fees[${index}]`,
    data:fee
  });
}

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.no-padding {
  padding: 0%;
}

.error-message {
  color: #dc3545;
}
</style>
