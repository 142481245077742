<template>
  <div id="download-photos">

    <h1 class="mt-1 text-center">
      Download Photographs
    </h1>

    <div class="row">
      <div class="col-8 offset-2">

        <div v-if="success === true" class="alert alert-success text-center">
          <h2 class="mt-1 mb-2">Photographs downloaded</h2>
          <p>Check your downloads folder.</p>
        </div>

        <div v-if="success === false" class="alert alert-danger text-center">
          <h2 class="mt-1 mb-2">Download Failed</h2>
          <p v-if="errorMessage" class="font-weight-bold">{{ errorMessage }}</p>
          <button class="btn btn-danger" @click.stop="generatePhotos">
            Try again
          </button>
        </div>

      </div>
    </div>

  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, watch, inject } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

// Injected properties
const actProperty: any = inject('actProperty');

// VueX Store
const store = useStore();
const router = useRouter();
const route = useRoute();

// Props definition
const errorMessage = ref('');
const success = ref<boolean | null>(null);
const refId = ref('');

const addRequest = (id: string) => {
  store.commit('app/addRequest', id);
};

const removeRequest = (id: string) => {
  store.commit('app/removeRequest', id);
};

const generatePhotosAction = (ref: string): Promise<any> =>  store.dispatch('reports/generatePhotos', ref);

// Equivalent of "mounted" lifecycle hook
onMounted(async () => {
  refId.value = (route.params.ref as string);
  await generatePhotos();
});

// Methods
const generatePhotos = async () => {
  await addRequest('generatePhotos');

  generatePhotosAction(refId.value)
    .then((key: string) => actProperty.downloadAttachment(key))
    .then(() => {
      success.value = true;
    })
    .catch((err: any) => {
      actProperty.displayError(err);
      success.value = false;
    })
    .finally(() => {
      removeRequest('generatePhotos');
    });
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
