<template>
  <div class="file-preview" v-bind:class="{ logo: isLogo }">
    <a @click.stop="openPhotoInGallery()" class="photo-link">
      <img
        :src="actProperty.s3Origin(photo.src)"
        :alt="actProperty.formatDatetimeForDisplay(photo.createdAt)"
        :title="actProperty.formatDatetimeForDisplay(photo.createdAt)"
        :class="{ avatar: target === 'inspector' }"
      />
      <div class="button-group">
        <button class="btn btn-sm btn-danger" @click.stop="onDelete()">
          <i class="fas fa-times"></i>
        </button>
        <button
          v-if="!isLogo"
          class="btn btn-sm btn-info rotate-btn-clockwise"
          @click.stop="onRotate()"
        >
          <i class="fas fa-redo"></i>
        </button>
      </div>
    </a>
  </div>
</template>

<script lang="ts" setup>
import { inject, defineProps, defineEmits } from 'vue';
import { useStore } from 'vuex';
import { Photo } from '@/models';

const props = defineProps<{
  photo: Photo;
  galleryType: string;
  isLogo: boolean;
  target: string;
}>();

const isLogo = props.isLogo || false;

const actProperty: any = inject('actProperty');
const store = useStore();

const emit = defineEmits(['rotate', 'delete'])

const onRotate = (event: Event) => {
  emit("rotate", props.photo);
};

const onDelete = (event: Event) => {
  emit("delete", props.photo);
};

/**
 * Open a Photo in the Gallery.
 *
 * The Gallery page makes an API call to "get all the Photos in a Report", so
 * any Photos added in the CMS will not be available in the Gallery until the
 * changes to the Report (the new Photos) have been saved to the database.
 */
const openPhotoInGallery = () => {
  if (isLogo) return;
  const reportsState = store.state.reports;
  
  const url = actProperty.photoGalleryUrl(
    props.photo,
    reportsState.current,
    props.galleryType
  );
  window.open(url, "_blank");
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/bootstrap/_variables.scss";

.file-preview {
  display: inline-block;
  vertical-align: top;
  margin: 0 0.5rem 0.5rem 0;
  width: 100px;
  position: relative;
  z-index: 0;
}

img {
  height: auto;
  image-orientation: from-image;
  width: 100%;
}

.button-group {
  left: 0.3rem;
  position: absolute;
  top: 0.25rem;
  .btn + .btn {
    margin-left: 0.25rem;
  }
}

.photo-link {
  cursor: pointer;
}

.photo-link.not-saved-to-report {
  cursor: not-allowed;
}

.logo {
  width: 300px !important;
}

.avatar {
  vertical-align: middle;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
</style>