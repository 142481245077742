<template>
  <div class="report-section keys">
    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th width="25%">Type</th>
          <th width="20%">Location</th>
          <th width="15%">Floor</th>
          <th width="25%">Condition</th>
          <th width="10%">Expiry date</th>
          <th width="5%"><span class="sr-only">Actions</span></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(alarm, $index) in alarms" :key="`row${alarm._uuid}`">
          <tr>
            <td>
              <input
                v-model.lazy="alarm.name"
                class="form-control form-control-sm"
                size="5"
                @change="editAlarms(alarm,$index)"
              />
            </td>
            <td>
              <input
                v-model.lazy="alarm.location"
                class="form-control form-control-sm"
                size="5"
                @change="editAlarms(alarm,$index)"
              />
            </td>
            <td>
              <input
                v-model.lazy="alarm.floor"
                class="form-control form-control-sm"
                size="2"
                @change="editAlarms(alarm,$index)"
              />
            </td>
            <td>
              <input
                v-model.lazy="alarm.condition"
                class="form-control form-control-sm"
                size="10"
                @change="editAlarms(alarm,$index)"
              />
            </td>
            <td>
              <DxDateBox
                v-model="alarm.edate"
                display-format="MMM yyyy"
                type="date"
                picker-type="calendar"
                apply-value-mode="instantly"
                @value-changed="expiryDateSelected(alarm, $index)"
              />
            </td>
            <td>
              <button
                class="btn btn-sm btn-outline-danger"
                @click="removeAlarm($index)"
                tabindex="-1"
              >
                <i class="fas fa-trash"></i>
              </button>
            </td>
          </tr>
          <tr>
            <td class="no-border">
              <Photos :key="`row${alarm._uuid}${alarm.photos.length}`"
                :photos="alarm.photos"
                :path="`alarms[${$index}].photos`"
              />
            </td>
            <td colspan="4" class="text-right no-border">
              <div class="btn-group">
                <button
                  class="btn conditionbutton"
                  v-for="condition in conditions"
                  :key="condition"
                  :class="{
                    'btn-primary': alarm.expirydatecondition === condition,
                    'btn-light': alarm.expirydatecondition != condition,
                  }"
                  @click="toggleCondition(condition, $index)"
                >
                  {{ condition }}
                </button>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <button class="btn btn-primary" @click="addNewAlarm()">
      <i class="fas fa-plus"></i> Alarm
    </button>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, ref } from 'vue';
import { useStore } from 'vuex';
import Photos from "@/components/photo/Photos.vue";
import { DxDateBox } from "devextreme-vue/date-box";
import { Alarm, Photo, Report } from "@/models";

const store = useStore();
const actProperty: any = inject('actProperty');

const statereport = computed<Report>(() => store.getters['reports/current']);

const setReportDeep = (payload: { path: string; data: any }) => {
  store.dispatch('reports/setReportDeep', payload);
};

const conditions = ref<string[]>([
  "Expiry date not seen",
  "Expiry date seen but not legible",
]);

const alarms = computed<Alarm[]>({
  get: () => statereport.value.alarms,
  set: (data) => {
    setReportDeep({ path: "alarms", data });
  }
});

const addNewAlarm = () => {
  alarms.value = [...alarms.value, new Alarm()];
};

const removeAlarm = async (index: number) => {
  actProperty
      .confirmPrompt()
      .then(() => alarms.value = actProperty.removeItemByIndex(alarms.value, index));
};

const toggleCondition = (condition: string, index: number) => {
  const alarm: Alarm = alarms.value[index];
  if (alarm) {
    const path = `alarms[${index}].expirydatecondition`;
    const data = alarm.expirydatecondition === condition ? '' : condition;
    setReportDeep({ path, data });
  }
};

const expiryDateSelected = (alarm: Alarm, index: number) => {
  if (alarm.edate) {
    setReportDeep({
      path: `alarms[${index}].expirydatecondition`,
      data: "",
    });
  }
};
const editAlarms = (alarm:Alarm,index:Number) => {
 setReportDeep({
    path: `alarms[${index}]`,
    data:alarm
  });
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.conditionbutton {
  margin-left: 2px;
  margin-right: 2px;
}
.no-border {
  border: 0px;
}
</style>
