<template>
  <!-- "Merge Reports" Modal -->
  <div class="modal fade" id="report-merge-modal" data-keyboard="true" tabindex="-1" role="dialog" aria-labelledby="report-merge-modal-title" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">

        <div class="modal-header">
          <h5 class="modal-title" id="report-merge-modal-title">
            Merge Reports
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="reset">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div v-if="step === 1">
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Source Ref</label>
              <div class="col-sm-9">
                <input class="form-control" v-model.lazy="mergeSourceRef" readonly />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Target Ref</label>
              <div class="col-sm-9">
                <input class="form-control" v-model.lazy="mergeTargetRef" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary mr-auto" data-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click.stop="confirmMerge">Merge</button>
          </div>
        </div>

        <div v-if="step === 2">
          <div class="modal-body">
            <h2>Warning</h2>
            <p class="lead">
              Merge <strong>{{mergeSourceRef}}</strong> with <strong>{{mergeTargetRef}}</strong>?
            </p>
            <p>
              You can only merge if:
            </p>
            <ul>
              <li>The <strong>Invoice Report</strong> has no Rooms in it.</li>
              <li>The <strong>Rooms Report</strong> has no Invoice information in it.</li>
            </ul>
            <p>
              During the merge:
            </p>
            <ul>
              <li>The <strong>Rooms from the Rooms Report</strong> will be copied <strong>into the Invoice Report</strong>.</li>
              <li>No reports will be deleted.</li>
            </ul>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary mr-auto" @click="reset" data-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" @click="merge">OK, Merge</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, inject, onMounted, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import { Report } from '@/models';

const instance = getCurrentInstance();
const actProperty: any = inject('actProperty');

const store = useStore();
const toasted = useToast();


const reportsState = computed(() => store.state.reports);
const mergeSource = computed({
  get: () => reportsState.value.mergeSource,
  set: (value: Report | null) => setMergeSource(value)
});

const mergeSourceRef = computed(() => mergeSource.value?.ref);

const mergeTargetRef = computed({
  get: () => reportsState.value.mergeTargetRef,
  set: (value: string) => setMergeTargetRef(value)
});

const setMergeSource = async (report: Report | null) => store.commit('reports/setMergeSource', report);
const setMergeTargetRef = async (ref: string) => store.commit('reports/setMergeTargetRef', ref);
const getReports = async () => store.dispatch('reports/getReports');
const mergeReports = async () => store.dispatch('reports/mergeReports');

const step = ref(1);

const reset = () => {
  step.value = 1;
  setMergeTargetRef('');
  instance?.proxy?.$forceUpdate();
};

const confirmMerge = () => {
  var refRegExp = new RegExp('^[0-9]+$');
  if (!mergeSource.value) {
    toasted.error(`Source Report has not been set`);
  } else if (!refRegExp.test(mergeSource.value.ref.toString())) {
    toasted.error(`Source Ref must only contain numbers`);
  } else if (!refRegExp.test(mergeTargetRef.value)) {
    toasted.error(`Target Ref must only contain numbers`);
  } else {
    step.value = 2;
    instance?.proxy?.$forceUpdate();
  }
};

const merge = async () => {
  mergeReports()
    .then(() => getReports())
    .then(() => {
      toasted.success(`Merged ${mergeSource.value?.ref} with ${mergeTargetRef.value}`);
    })
    .catch((err: any) => actProperty.displayError(err));
};

onMounted(() => reset());
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
