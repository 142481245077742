import { Auth } from 'aws-amplify';

const awsmobile = {
  Auth: {
    identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
    region: process.env.VUE_APP_AWS_REGION,
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_USER_POOL_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'RestAPI',
        region: process.env.VUE_APP_AWS_REGION,
        endpoint: process.env.VUE_APP_REST_API_URL,
        custom_header: async () => {
          return {
            Pragma: 'no-cache',
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          };
        },
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: process.env.VUE_APP_S3_BUCKET,
      region: process.env.VUE_APP_AWS_REGION,
    },
  },
};

export default awsmobile;
