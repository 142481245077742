<template>
  <div
    :id="id"
    class="modal"
    data-keyboard="true"
    tabindex="-1"
    role="dialog"
    aria-labelledby="`add-reporttype-modal-label`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="add-reporttype-modal-label">
            Report Type
          </h5>

          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click.prevent="hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <form>
            <fieldset>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Slug</label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    class="form-control"
                    v-model="slug"
                    :disabled="operation != 'add'"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Name</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" v-model="name" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Display Name</label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    class="form-control"
                    v-model="displayname"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label"
                  >New report can be created on app</label
                >
                <div class="col-sm-9">
                  <DxSwitch v-model="createallowedflag" @value-changed="setReportTypeDeep({path: 'createallowed', data: $event.value})"/>
                </div>
              </div>
              <div class="form-group row" v-if="!isCorereport()">
                <label class="col-sm-3 col-form-label">Dictionary</label>
                <div class="col-sm-9">
                  <DxSwitch v-model="dictionaryflag"  @value-changed="setReportTypeDeep({path: 'dictionaryflag', data: $event.value})"/>
                </div>
              </div>

              <div class="form-group row" v-if="!isCorereport() || isPV()">
                <label class="col-sm-3 col-form-label">Is visible to ACT PIs</label>
                <div class="col-sm-9">
                  <DxSwitch v-model="accessiblebyactpi"  @value-changed="setReportTypeDeep({path: 'accessiblebyactpi', data: $event.value})"/>
                </div>
              </div>
              <div class="form-group row" v-if="(!isCorereport() || isPV()) && accessiblebyactpi">
                <label class="col-sm-3 col-form-label">List visible customer to ACT PIs</label>
                <div class="col-sm-9">
                  <multiselect-text
                    v-model="accessiblebyactpicompanynamelist"
                    :options="companyNameOptions"
                    multiple
                    :allow-empty="true"
                    :taggable="false"
                  >
                  </multiselect-text>
                </div>
              </div>

            </fieldset>
          </form>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary mr-auto"
            data-dismiss="modal"
            @click.prevent="hide()"
          >
            Back
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            @click.prevent="add()"
            :disabled="!canSave()"
            v-if="operation == 'add'"
          >
            Add
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            @click.prevent="save()"
            :disabled="!canSave()"
            v-else
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import { ref, computed, inject, defineProps, defineExpose } from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import { DxSwitch } from 'devextreme-vue/switch';

import { Dictionary, DictionaryReportType } from "@/models";

// Define props
const props = defineProps<{
  id: string,
  operation: string;
}>();

// Using Vuex store
const store = useStore();
const actProperty: any = inject('actProperty');
const toasted = useToast();

const reportTypeModalAdd = ref(null);

// Computed properties and methods
const dictionary = computed((): Dictionary => store.getters['dictionary/current']);
const activeReportType = computed(() => store.getters['dictionary/activeReportType']);
const companyNameOptions = ref<string[]>([]);

// Actions
const addReportType = async (reporttype: DictionaryReportType): Promise<any> => {
  return store.dispatch('dictionary/addReportType', reporttype);
};

const setDictionaryDeep = async (payload: { path: string; data: any }): Promise<any> => {
  return store.dispatch('dictionary/setDictionaryDeep', payload);
};

const getReportactiveCompanyNameOptions = async (reportname: string): Promise<any> => {
  return store.dispatch('customers/getReportactiveCompanyNameOptions', reportname);
};

const setActiveReportType = (reporttype: DictionaryReportType) => {
  store.commit('dictionary/setActiveReportType', reporttype);
};

const setReportTypeDeep = (payload: { path: string; data: any }) => {
  store.commit('dictionary/setReportTypeDeep', payload);
};

// Computed properties
const slug = computed({
  get: () => activeReportType.value.slug,
  set: (val: string) => setReportTypeDeep({ path: "slug", data: val })
});

const name = computed({
  get: () => activeReportType.value.name,
  set: (val: string) => setReportTypeDeep({ path: "name", data: val })
});

const displayname = computed({
  get: () => activeReportType.value.displayname,
  set: (val: string) => setReportTypeDeep({ path: "displayname", data: val })
});

const createallowedflag = computed({
  get: () => activeReportType.value.createallowed,
  set: (val: boolean) => setReportTypeDeep({ path: "createallowed", data: val })
});

const dictionaryflag = computed({
  get: () => activeReportType.value.dictionaryflag,
  set: (val: boolean) => setReportTypeDeep({ path: "dictionaryflag", data: val })
});

const accessiblebyactpi = computed({
  get: () => activeReportType.value.accessiblebyactpi,
  set: (val: boolean) => setReportTypeDeep({ path: "accessiblebyactpi", data: val })
});

const accessiblebyactpicompanynamelist = computed({
  get: () => activeReportType.value.accessiblebyactpicompanynamelist,
  set: (val: string[]) => setReportTypeDeep({ path: "accessiblebyactpicompanynamelist", data: val })
});


// Helper methods converted to arrow functions
const isCorereport = () => {
  const corereports = ["inventory", "soc", "checkin", "checkout", "property visit"];
  return corereports.includes(activeReportType.value.slug);
};

const isPV = () => slug.value === 'property visit';

const init = () => {
  getReportactiveCompanyNameOptions(activeReportType.value.slug)
  .then((values) => {
    companyNameOptions.value = values;
  })
  .catch((err) => {
    actProperty.displayError(err);
  });
}

const add = async () => {
  addReportType(activeReportType.value)
    .then(() =>
      toasted.success(`Added "${activeReportType.value.name}"`)
    )
    .then(() => reset())
    .catch((err: any) => {
      actProperty.displayError(err);
    });
    hide();
};

const save = async () => {
  if (dictionary.value.reporttypes) {
    let index = dictionary.value.reporttypes?.findIndex(
      (f) => f.slug == activeReportType.value.slug
    );
    if (index >= 0) {
      setDictionaryDeep({
        path: `reporttypes[${index}]`,
        data: activeReportType.value,
      })
        .then(() =>
          toasted.warning(`Locally saved "${activeReportType.value.name}. Please remember to press final Save"`)
        )
        .then(() => reset())
        .catch((err: any) => {
          actProperty.displayError(err);
        });
    }
  }
  hide();

};

const reset = () => {
  setActiveReportType(new DictionaryReportType());
};

const canSave = (): boolean => {
  return !!name.value && !!displayname.value;
}

const show = () => {
  if ($(`#${props.id}` + 'Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.id + 'Backdrop"></div>');
    $('body').append(backdropDiv);
    $(`#${props.id}`).show();
  }
}
const hide = () => {
  if ($(`#${props.id}`).length > 0) {
    $(`#${props.id}` + 'Backdrop').remove();
    $(`#${props.id}`).hide();
  }
};

defineExpose({init, add, show, hide});
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.error-message {
  color: #dc3545;
}
</style>