import _ from 'lodash';
import Vue from 'vue';

import _snakeCase from 'lodash/snakeCase';

import { MutationTree } from 'vuex';
import { EmailTemplatesState } from './types';
import { EmailTemplate, Email } from '@/models';

/**
 * Mutations
 *
 * The only way to actually change state in a Vuex store is by committing a mutation.
 * - Vuex mutations are very similar to events: each mutation has a string type and a handler.
 * - The handler function is where we perform actual state modifications - it receives the state as the first argument:
 * - You cannot directly call a mutation handler.
 * - Think of it more like event registration: "When a mutation with type X is triggered, call this handler."
 * - To invoke a mutation handler, you need to call store.commit with its type
 * - e.g. `store.commit('setEmailTemplates', emailtemplate)`
 */
export const mutations: MutationTree<EmailTemplatesState> = {

  /**
   * Set the report currently being viewed/edited
   *
   * @param state EmailTemplatesState
   * @param emailtemplate EmailTemplate
   * @returns void
   */
  setCurrentEmailTemplate(state: EmailTemplatesState, emailtemplate: EmailTemplate): void {
    state.current = emailtemplate;
  },

  /**
   * Set the reports list
   *
   * @param state EmailTemplatesState
   * @param emailtemplate EmailTemplate
   * @returns void
   */
  setEmailTemplates(state: EmailTemplatesState, emailtemplates: EmailTemplate[]): void {
    state.list = emailtemplates;
  },

  /**
   * Set the currently edited/viewed emailtemplate
   * 
   * @param state EmailTemplatesState
   * @param emailtemplate EmailTemplate
   * @returns void
   */
  setEmailTemplate(state: EmailTemplatesState, emailtemplate: EmailTemplate): void {
    state.current = emailtemplate;
  },


  /**
   * Set the currently edited/viewed client email
   * 
   * @param state EmailTemplatesState
   * @param payload.path string
   * @param payload.data 
   * @returns void
   */
  setClientEmailDeep(state: EmailTemplatesState, payload: { path: string, data: any }): void {
    _.set(state.clientemail, payload.path, payload.data);
  },

  /**
   * Set the currently edited/viewed client email
   * 
   * @param state EmailTemplatesState
   * @param email Email
   * @returns void
   */
  setClientEmail(state: EmailTemplatesState, email: Email): void {
    state.clientemail = email;
  },

  /**
   * Set the currently edited/viewed feedback email
   * 
   * @param state EmailTemplatesState
   * @param payload.path string
   * @param payload.data 
   * @returns void
   */
  setFeedbackEmailDeep(state: EmailTemplatesState, payload: { path: string, data: any }): void {
    _.set(state.feedbackemail, payload.path, payload.data);
  },

  /**
   * Set the currently edited/viewed feedback email
   * 
   * @param state EmailTemplatesState
   * @param email Email
   * @returns void
   */
  setFeedbackEmail(state: EmailTemplatesState, email: Email): void {
    state.feedbackemail = email;
  },

  /**
   * Set the currently edited/viewed landlord email
   * 
   * @param state EmailTemplatesState
   * @param payload.path string
   * @param payload.data 
   * @returns void
   */
  setLandlordEmailDeep(state: EmailTemplatesState, payload: { path: string, data: any }): void {
    _.set(state.landlordemail, payload.path, payload.data);
  },

  /**
   * Set the currently edited/viewed landlord email
   * 
   * @param state EmailTemplatesState
   * @param email Email
   * @returns void
   */
  setLandlordEmail(state: EmailTemplatesState, email: Email): void {
    state.landlordemail = email;
  },

  /**
   * Set the currently edited/viewed tenant email
   * 
   * @param state EmailTemplatesState
   * @param payload.path string
   * @param payload.data 
   * @returns void
   */
  setTenantEmailDeep(state: EmailTemplatesState, payload: { path: string, data: any }): void {
    _.set(state.tenantemail, payload.path, payload.data);
  },

  /**
   * Set the currently edited/viewed tenant email
   * 
   * @param state EmailTemplatesState
   * @param email Email
   * @returns void
   */
  setTenantEmail(state: EmailTemplatesState, email: Email): void {
    state.tenantemail = email;
  },

  /**
   * Set the currently edited/viewed invoice email
   * 
   * @param state EmailTemplatesState
   * @param payload.path string
   * @param payload.data 
   * @returns void
   */
  setInvoiceEmailDeep(state: EmailTemplatesState, payload: { path: string, data: any }): void {
    _.set(state.invoiceemail, payload.path, payload.data);
  },

  /**
   * Set the currently edited/viewed invoice email
   * 
   * @param state EmailTemplatesState
   * @param email Email
   * @returns void
   */
  setInvoiceEmail(state: EmailTemplatesState, email: Email): void {
    state.invoiceemail = email;
  },


  /**
   * Set the currently edited/viewed dataentry email
   * 
   * @param state EmailTemplatesState
   * @param payload.path string
   * @param payload.data 
   * @returns void
   */
  setDataentryEmailDeep(state: EmailTemplatesState, payload: { path: string, data: any }): void {
    _.set(state.dataentryemail, payload.path, payload.data);
  },

  /**
   * Set the currently edited/viewed data entry email
   * 
   * @param state EmailTemplatesState
   * @param email Email
   * @returns void
   */
  setDataentryEmail(state: EmailTemplatesState, email: Email): void {
    state.dataentryemail = email;
  },

  /**
   * Set the currently edited/viewed delete booking email
   * 
   * @param state EmailTemplatesState
   * @param payload.path string
   * @param payload.data 
   * @returns void
   */
  setDeletebookingEmailDeep(state: EmailTemplatesState, payload: { path: string, data: any }): void {
    _.set(state.deletebookingemail, payload.path, payload.data);
  },

  /**
   * Set the currently edited/viewed delete booking  email
   * 
   * @param state EmailTemplatesState
   * @param email Email
   * @returns void
   */
  setDeletebookingEmail(state: EmailTemplatesState, email: Email): void {
    state.deletebookingemail = email;
  },



  /**
   * Set the currently edited/viewed emailtemplate
   * 
   * @param state EmailTemplatesState
   * @param payload.path string
   * @param payload.data 
   * @returns void
   */
  setEmailTemplateDeep(state: EmailTemplatesState, payload: { path: string, data: any }): void {
    _.set(state.current, payload.path, payload.data);
  },


  /**
   * Adds a client
   */
  addClient(state: EmailTemplatesState, clientid: string): void {
    let clients: string[] = state.current.clients;
    if (!clients)
      clients = [];
    _.set(state.current, `clients`, clients.concat(clientid));
  },

  /**
   * Remove client from the given list
   * 
   * @param state 
   * @param flag 
   */
  removeClient(state: EmailTemplatesState, clientid: string): void {
    let clients: string[] = state.current.clients;
    if (clients) {
      let index = clients.findIndex((id) => id == clientid);
      if (index >= 0) {
        clients.splice(index, 1);
        _.set(state.current, `clients`, clients);
      }
    }
  },

  /**
   * Sets a reporttype list
   */
  setReporttypes(state: EmailTemplatesState, types: string[]): void {
    let reporttypes: string[] = state.current.reporttypes;
    if (!reporttypes)
      reporttypes = [];
    if (Array.isArray(types)) {
      _.set(state.current, `reporttypes`, types);
    }
  },

  /**
   * Adds a reporttype
   */
  addReporttype(state: EmailTemplatesState, reporttype: string): void {
    let reporttypes: string[] = state.current.reporttypes;
    if (!reporttypes)
      reporttypes = [];
    let index = reporttypes.indexOf(reporttype);
    if (index < 0)
      _.set(state.current, `reporttypes`, reporttypes.concat(reporttype));
  },

  /**
   * Remove reporttype from the given list
   * 
   * @param state 
   * @param flag 
   */
  removeReporttype(state: EmailTemplatesState, reporttype: string): void {
    let reporttypes: string[] = state.current.reporttypes;
    if (reporttypes) {
      let index = reporttypes.findIndex((type) => type == reporttype);
      if (index >= 0) {
        reporttypes.splice(index, 1);
        _.set(state.current, `reporttypes`, reporttypes);
      }
    }
  },


  /**
   * ++ Unsaved changes
   * 
   * @param state EmailTemplatesState
   * @param element string
   * @returns void
   */
  addUnsavedChange(state: EmailTemplatesState, element: string): void {
    state.updates = [...new Set(state.updates.concat([element]))];
  },


  /**
   * Reset Unsaved changes
   * 
   * @param state EmailTemplatesState
   * @returns void
   */
  resetUnsavedChanges(state: EmailTemplatesState): void {
    state.updates = [];
  },

};
