import _get from 'lodash/get';

import { Base } from '@/models';

export class CognotoUserAttribute extends Base {

    public Name: string;
    public Value: string;

    /**
     * Constructor initialises the variables from the class User
     * @param data - the data to construct a type with all properties of <User>
     */
    public constructor(data?: Partial<CognotoUserAttribute>) {
        super(data);

        this.Name = _get(data, 'Name', '');
        this.Value = _get(data, 'Value', '');
    }

}
