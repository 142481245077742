<template>
  <div class="customer">
    <div class="user-header">
      <form>
        <fieldset>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-8 col-form-label">Feedback link active</label>
                <div class="col-sm-1">
                  <DxSwitch :value="customer.feedbacklinkactive"
                    @value-changed="setCustomerDeep({ path: 'feedbacklinkactive', data: $event.value })" />
                </div>
              </div>
              <div v-if="customer.feedbacklinkactive" class="form-group row">
                <label class="col-sm-8 col-form-label">Feedback link active for (Days)</label>
                <div class="col-sm-3">
                  <singleselect-text v-model="customer.feedbacklinkactivedays" :value="customer.feedbacklinkactivedays"
                    :options="listofdays" @select="editCurrentCustomer()">
                  </singleselect-text>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-8 col-form-label">Hide ACT address from report</label>
                <div class="col-sm-1">

                  <DxSwitch :value="customer.hidefrontpageaddress"
                    @value-changed="setCustomerDeep({ path: 'hidefrontpageaddress', data: $event.value })" />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-8 col-form-label">Redline CheckOut</label>
                <div class="col-sm-4">

                  <DxSwitch :value="customer.reportsettings.redlinecoactive"
                    @value-changed="setCustomerDeep({ path: 'reportsettings.redlinecoactive', data: $event.value })" />

                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-8 col-form-label">CheckOut Summary</label>
                <div class="col-sm-1">

                  <DxSwitch :value="customer.reportsettings.cosummaryactive"
                    @value-changed="setCustomerDeep({ path: 'reportsettings.cosummaryactive', data: $event.value })" />

                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-8 col-form-label">Redline CheckOut Summary</label>
                <div class="col-sm-1">
                  <DxSwitch :value="customer.reportsettings.cosmredlineactive"
                    @value-changed="setCustomerDeep({ path: 'reportsettings.cosmredlineactive', data: $event.value })" />

                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-8 col-form-label">Mid Term report active</label>
                <div class="col-sm-1">
                  <DxSwitch :value="customer.midtermactive"
                    @value-changed="setCustomerDeep({ path: 'midtermactive', data: $event.value })" />
                </div>
              </div>

              <div class="form-group row" v-for="reporttype in getReporttypesWithDictionary" :key="reporttype.slug">
                <label class="col-sm-8 col-form-label">{{ reporttype.displayname }} active</label>
                <div class="col-sm-1">
                  <DxSwitch :value="isReporttypeactive(reporttype)" @value-changed="toggleReporttypeactive(reporttype)" />

                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { Customer, Dictionary, DictionaryReportType } from "@/models";
import { DxSwitch } from 'devextreme-vue/switch';
const listofdays = ref([
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30,
]);
const dictionary = computed(() => store.getters['dictionary/current']);

const store = useStore();
const customer = computed(() => store.getters['customers/current']);
const editCustomer = async (customer: Customer) => await store.dispatch('customers/editCustomer', customer);
const setCustomerDeep = async (payload: {
  path: string;
  data: any;
}) => {
  await store.dispatch('customers/setCustomerDeep', payload);
}
const editCurrentCustomer = () => {
  editCustomer(customer.value);
};
const getReporttypesWithDictionary = computed(() => {
  return dictionary.value?.reporttypes?.filter((t) => t.dictionaryflag === true);
});


const isReporttypeactive = (reporttype: DictionaryReportType) => {
  const type = customer.value.reportsettings.activereporttypes.find(
    (r) => r === reporttype.slug
  );
  return type ? true : false;
};
const toggleReporttypeactive = (reporttype: DictionaryReportType) => {
  const index = customer.value.reportsettings.activereporttypes.indexOf(
    reporttype.slug
  );

  if (index >= 0) {
    customer.value.reportsettings.activereporttypes.splice(index, 1);
  } else {
    customer.value.reportsettings.activereporttypes.push(reporttype.slug);
  }

  // Emit an event to notify the parent component about the change
  editCurrentCustomer();
}
</script>

<style scoped lang="scss"></style>