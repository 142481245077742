<template>
  <div class="customers list">
    <div class="row mb-3">
      <div class="col-12">
        <div class="row">
          <div class="col-3">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Duedate</label>
              <div class="col-7">
                <DxDateRangeBox
                  v-model="computedDateRange"
                  :min="minDate"
                  :max="maxDate"
                  displayFormat="dd/MM/yyyy"
                  label="Select Date Range"
                />
              </div>
              <div class="col-3" style="padding-top: 10px">
                <button class="btn btn-outline-secondary" @click="showAll()">
                  Show All
                </button>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">PostCode</label>
              <div class="col-10">
                <multiselect-text
                  v-model="postCodeData"
                  :options="uniquePostcodes"
                  multiple
                  :close-on-select="false"
                  :allowEmpty="true"
                  taggable
                >
                  <!-- Template for rendering options with checkboxes -->
                  <template #option="{ option, selected, disabled }">
                    <div class="custom-checkbox">
                      <input
                        type="checkbox"
                        :checked="postCodeData.includes(option)"
                        :disabled="disabled"
                      />
                      <span style="padding-left: 10px"> {{ option }}</span>
                    </div>
                  </template>
                  <template #selection="{ values, search, isOpen }">
                    <span
                      class="multiselect__single"
                      v-if="values.length"
                      v-show="!isOpen"
                      >{{ values.length }} options selected</span
                    >
                  </template>
                </multiselect-text>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">Status</label>
              <div class="col-10">
                <multiselect-text
                  v-model="statusData"
                  :options="statusList"
                  multiple
                  :close-on-select="false"
                  :allowEmpty="true"
                  taggable
                >
                  <!-- Template for rendering options with checkboxes -->
                  <template #option="{ option, selected, disabled }">
                    <div class="custom-checkbox">
                      <input
                        type="checkbox"
                        :checked="statusData.includes(option)"
                        :disabled="disabled"
                      />
                      <span style="padding-left: 10px"> {{ option }}</span>
                    </div>
                  </template>
                  <template #selection="{ values, search, isOpen }">
                    <span
                      class="multiselect__single"
                      v-if="statusData.length"
                      v-show="!isOpen"
                      >{{ statusData.length }} options selected</span
                    >
                  </template>
                </multiselect-text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!propertyvisitmanagement.length && !hasBlockingRequests"
      class="alert alert-warning text-center"
    >
      <h2 class="mt-1 mb-2">No Property Visit Management found</h2>
    </div>

    <table v-else class="table table-hover">
      <thead>
        <tr>
          <th @click="sortTable('jobtype')" class="jobtype">
            Jobtype
            <span v-if="sortKey === 'jobtype'">{{
              sortOrder === "asc" ? "▲" : "▼"
            }}</span>
          </th>
          <th @click="sortTable('duedate')" class="duedate">
            Due Date
            <span v-if="sortKey === 'duedate'">{{
              sortOrder === "asc" ? "▲" : "▼"
            }}</span>
          </th>
          <th class="status">Status</th>
          <th @click="sortTable('postcode')" class="postcode">
            PostCode
            <span v-if="sortKey === 'postcode'">{{
              sortOrder === "asc" ? "▲" : "▼"
            }}</span>
          </th>
          <th class="address">Address</th>
          <th class="pi">PI</th>
          <!-- <th class="pi">Drag PI</th> -->
          <th class="customer">Customer</th>
          <th class="branch">Branch</th>
          <th class="date">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(pvm, rowIndex) in sortedData" :key="pvm.id">
          <td></td>
          <td>{{ actProperty.formatDateForDisplay(pvm.pvDueDate) }}</td>
          <td></td>
          <td>
            {{ pvm.property.address.postcode }}
          </td>
          <td
            v-html="actProperty.formatAddress(pvm.property.address, ', ')"
          ></td>
          <td
            contenteditable="false"
            @input="updateCellValue($event, rowIndex, pvm.id)"
            @mousedown="startDrag(rowIndex, pvm.id)"
            @mouseup="endDrag(pvm.id)"
            @mousemove="handleDrag(rowIndex)"
            class="no-select"
          >
            <InspectorSelect
              :pvm="pvm"
              :changestatuspvmid="changestatuspvmid"
              @statuspvmselected="changestatuspvmid = $event"
            />
          </td>
          <!-- <td
            contenteditable="false"
            @input="updateCellValue($event, rowIndex, pvm.id)"
            @mousedown="startDrag(rowIndex, pvm.id)"
            @mouseup="endDrag(pvm.id)"
            @mousemove="handleDrag(rowIndex)"
            class="no-select"
          >
            {{ pvm.inspector.name }}
          </td> -->
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { Inspector, PmTenancy } from "@/models";
import { computed, onMounted, inject, ref } from "vue";
import { useStore } from "vuex";
import { DxDateRangeBox } from "devextreme-vue/date-range-box";
import InspectorSelect from "@/components/propertyvisitmanagement/InspectorSelect.vue";
import moment from "moment";
const store = useStore();
const postCodeOptions = ref([]);
const statusOptions = ref([]);
const propertyvisitmanagementData = ref<PmTenancy[]>([]);
const hasBlockingRequests = computed(
  () => store.getters["app/hasBlockingRequests"]
);
const inspectorlist = computed(() => store.getters["diary/inspectorlist"]);
const setTenanciesData = (data: PmTenancy[]) =>
  store.commit("propertyvisitmanagement/setTenancies", data);
const actProperty: any = inject("actProperty");
const getTenanciesAction = (payload: {
  startdate: string;
  enddate: string;
}) => {
  return store.dispatch("propertyvisitmanagement/getTenancies", payload);
};
const dateRange = ref<[Date | null, Date | null]>([null, null]);
const changestatuspvmid = ref("");
const computedDateRange = computed<[Date | null, Date | null]>({
  get() {
    return dateRange.value;
  },
  set(value: [Date | null, Date | null]) {
    dateRange.value = value;
    if (dateRange.value[0] && dateRange.value[1]) {
      getTenancies();
    }
  },
});
const sortKey = ref(""); // Holds the key of the column being sorted
const sortOrder = ref<"asc" | "desc">("asc"); // Sorting order
const statusList: string[] = [
  "Select All",
  "Booked",
  "Cancelled",
  "Completed and report sent",
  "Tenant contacted",
  "Work in progress",
];

// Sorting function
const sortTable = (key: string) => {
  if (sortKey.value === key) {
    // If the same column is clicked, toggle the sort order
    sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc";
  } else {
    // If a new column is clicked, set to ascending order
    sortKey.value = key;
    sortOrder.value = "asc";
  }
};

// Computed property for sorted data
const sortedData = computed(() => {
  const sorted = [...propertyvisitmanagementData.value];

  // Define sorting logic based on the selected key
  if (sortKey.value) {
    sorted.sort((a, b) => {
      let compareA = a[sortKey.value] ?? "";
      let compareB = b[sortKey.value] ?? "";

      // Handle nested fields like postcode, customer, and address
      if (sortKey.value === "postcode") {
        compareA = a.property.address.postcode;
        compareB = b.property.address.postcode;
      }

      // Handle date sorting
      if (sortKey.value === "duedate") {
        compareA = new Date(a.pvDueDate);
        compareB = new Date(b.pvDueDate);
      }

      // For string comparison
      if (typeof compareA === "string") {
        compareA = compareA.toLowerCase();
        compareB = compareB.toLowerCase();
      }

      if (compareA < compareB) return sortOrder.value === "asc" ? -1 : 1;
      if (compareA > compareB) return sortOrder.value === "asc" ? 1 : -1;
      return 0;
    });
  }
  let filtered = [...sorted];

  // Filter propertyvisitmanagementData based on postcodes
  // Filter by postcode if postCodeData is not empty
  if (postCodeData.value.length) {
    filtered = filtered.filter((item: PmTenancy) =>
      postCodeData.value.includes(item.property.address.postcode)
    );
  }

  // Filter by status if statusData is not empty
  if (statusData.value.length) {
    // filtered = filtered.filter((item: PmTenancy) =>
    //   statusData.value.includes(item.id) // Assuming `item.status` is the status field in `PmTenancy`
    // );
  }

  return filtered;
});
const postCodeData = computed({
  get() {
    return postCodeOptions.value;
  },
  set(val: string[]) {
    if (
      val.includes("Select All") &&
      !postCodeOptions.value.includes("Select All")
    ) {
      postCodeOptions.value = uniquePostcodes.value;
    } else if (
      !val.includes("Select All") &&
      postCodeOptions.value.includes("Select All")
    ) {
      postCodeOptions.value = [];
    } else {
      postCodeOptions.value = val.filter((f: any) => f !== "Select All");
    }
  },
});
const statusData = computed({
  get() {
    return statusOptions.value;
  },
  set(val: string[]) {
    if (
      val.includes("Select All") &&
      !statusOptions.value.includes("Select All")
    ) {
      statusOptions.value = statusList;
    } else if (
      !val.includes("Select All") &&
      statusOptions.value.includes("Select All")
    ) {
      statusOptions.value = [];
    } else {
      statusOptions.value = val.filter((f: any) => f !== "Select All");
    }
  },
});
const formatDate = (date: Date) => {
  return moment(date).format("DD-MM-YYYY");
};
const propertyvisitmanagement = computed(
  () => store.getters["propertyvisitmanagement/getTenancies"]
);
const getTenancies = async () => {
  if (dateRange.value[0] && dateRange.value[1]) {
    // Format the start and end dates and make the API call
    await getTenanciesAction({
      startdate: formatDate(dateRange.value[0]), // Format the start date
      enddate: formatDate(dateRange.value[1]), // Format the end date
    });
  } else {
    await getTenanciesAction({
      startdate: "", // Format the start date
      enddate: "", // Format the end date
    });
  }
  propertyvisitmanagementData.value = propertyvisitmanagement.value;
};
const uniquePostcodes = computed(() => {
  let postcodes = propertyvisitmanagement.value.map(
    (f: PmTenancy) => f.property.address.postcode
  );

  // Remove duplicates using Set and convert back to an array
  const uniquePostcodesArray = Array.from(new Set(postcodes));

  // Add 'Select All' at the 0th index
  return ["Select All", ...uniquePostcodesArray];
});

// Converted methods
const init = async () => {
  await getTenancies();
};
const showAll = async () => {
  dateRange.value = [null, null]; // Reset date range
  await getTenancies();
};
const minDate = new Date("2020-01-01"); // Very old date (acts as "infinity" in the past)
const maxDate = new Date("2050-12-31"); // Very distant future date (acts as "infinity" in the future)
// Lifecycle hook
onMounted(() => {
  init();
});

// Reactive state for drag-to-fill functionality
const dragging = ref(false);
const startRow = ref<number | null>(null);
const startValue = ref<string | null>(null);
const endRow = ref<number | null>(null);
// Function to get the inspector ID based on the name and set it in the appropriate cell
const getInspectorId = (rowIndex: number, name: string, id: string) => {
  const inspector = inspectorlist.value.find((f: Inspector) => f.name === name);
  if (inspector?.id) {
    sortedData.value[rowIndex].inspector.id = inspector.id;
    sortedData.value[rowIndex].inspector.name = name;
    const pmIndex = propertyvisitmanagement.value.findIndex(
      (r: PmTenancy) => r.id === id
    );
    propertyvisitmanagement.value[pmIndex].inspector = inspector;
    setTenanciesData(propertyvisitmanagement.value);
  }
};

// Function to update the cell value when a user enters data
const updateCellValue = (event: InputEvent, rowIndex: number, id: string) => {
  const value = (event.target as HTMLElement).innerText;
  getInspectorId(rowIndex, value, id);
};

// Function to initiate drag-to-fill (stores initial row and value)
const startDrag = (rowIndex: number, id: string) => {
  dragging.value = true;
  startRow.value = rowIndex;
  startValue.value = sortedData.value[rowIndex].inspector.name; // Capture the initial value
  getInspectorId(rowIndex, startValue.value, id);
  endRow.value = rowIndex; // Initialize endRow as startRow to reset drag boundaries
};

// Function to finalize the drag-to-fill action, filling cells downward only
const endDrag = (id: string) => {
  if (
    dragging.value &&
    startRow.value !== null &&
    startValue.value !== null &&
    endRow.value !== null &&
    endRow.value > startRow.value // Ensure we’re only dragging downward
  ) {
    dragging.value = false;

    // Fill cells only downward from startRow to endRow
    const start = startRow.value;
    const end = endRow.value;

    for (let i = start; i <= end; i++) {
      getInspectorId(i, startValue.value, id);
    }
  }
  // Reset start and end rows after drag
  startRow.value = null;
  endRow.value = null;
};

// Function to track the drag row index (determines the end row)
// Only allow downward dragging (endRow >= startRow)
const handleDrag = (rowIndex: number) => {
  if (dragging.value && rowIndex > startRow.value) {
    endRow.value = rowIndex;
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
th {
  &.jobtype {
    width: 12rem;
  }
  &.duedate {
    width: 8rem;
  }
  &.schedate {
    width: 8rem;
  }
  &.status {
    width: 12rem;
  }
  &.postcode {
    width: 12rem;
  }
  &.address {
    width: 20rem;
  }
  &.pi {
    width: 12rem;
  }
  &.customer {
    width: 12rem;
  }
  &.branch {
    width: 12rem;
  }
}
.no-select {
  user-select: none;
}
</style>
