<template>
  <div class="list">
    <div
      v-if="!groups.length && !hasBlockingRequests"
      class="alert alert-warning text-center"
    >
      <h2 class="mt-1 mb-2">No groups found</h2>
    </div>

    <table v-else class="table table-hover">
      <thead>
        <th width="30%">Name</th>
        <th width="10%">Precedance</th>
        <th width="60%">Discription</th>
      </thead>
      <tbody>
        <tr v-for="group in groups" :key="group.groupname">
          <td>{{ group.GroupName }}</td>
          <td>{{ group.Precedence }}</td>
          <td>{{ group.Description }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const hasBlockingRequests = computed(() => store.getters['app/hasBlockingRequests']);
const groups = computed(() => store.getters['sysadmin/groups']);
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
