<template>
  <nav>
      <ul class="pagination">
        <li v-for="(link, $index) in links" :key="$index" class="page-item" :class="{active: $route.params.search == link}">
          <router-link :to="{name: 'customerlist', params: {search: `${link}` }}" class="page-link">
            {{link.toUpperCase()}}
          </router-link>
        </li>
      </ul>
  </nav>
</template>

<script lang="ts" setup>
import { inject, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

const store = useStore();
const route = useRoute();
const actProperty: any = inject('actProperty');

const links = [
  'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm',
  'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
  '0-9',
];

onMounted(() => {
  if (route.params.search) {
    init();
  }
});

const getCustomersAction = async (payload?: { starts_with?: string, company_name?: string, branch_name?: string }) => {
  try {
    await store.dispatch('customers/getCustomers', payload);
  } catch (err: any) {
    actProperty.displayError(err)
  }
};

const init = async () => {
  await getCustomersAction({ starts_with: (route.params.search as string) });
};

watch(() => route.params.search, (newValue) => {
  if (newValue) {
    init();
  }
});
</script>

<style scoped lang="scss">
</style>
