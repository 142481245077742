import _ from "lodash";
import _set from "lodash/set";
import moment from "moment-timezone";

import Vue from "vue";

import _snakeCase from "lodash/snakeCase";

import { MutationTree } from "vuex";
import { DiaryState } from "./types";
import {
  Address,
  PropertySpec,
  Tenant,
  Customer,
  Inspector,
  Report,
  Booking,
  GetaddressResponse,
  DictionaryReportType,
  SMSLogs,
  EmailLogs,
  EmailLog,
  Landlord,
  Bookedby,
} from "@/models";

import { bookingdateformat } from "@/utilities";

/**
 * Mutations
 *
 * The only way to actually change state in a Vuex store is by committing a mutation.
 * - Vuex mutations are very similar to events: each mutation has a string type and a handler.
 * - The handler function is where we perform actual state modifications - it receives the state as the first argument:
 * - You cannot directly call a mutation handler.
 * - Think of it more like event registration: "When a mutation with type X is triggered, call this handler."
 * - To invoke a mutation handler, you need to call store.commit with its type
 * - e.g. `store.commit('setInspectors', inspector)`
 */
export const mutations: MutationTree<DiaryState> = {
  setBooking(state: DiaryState, booking: Booking): void {
    const bookingstartdateMoment = moment(booking.startdateAsDate);
    const currentdateMoment = moment(state.currentdate);
    if (
      booking.subtype != Booking.PERSONAL ||
      bookingstartdateMoment.isSame(currentdateMoment, "day")
    ) {
      state.booking = booking;
      state.currentdate = moment(booking.startdate)
        .utc()
        .startOf("day")
        .add(9, "hours")
        .toDate();
    }
  },
  setBookings(state: DiaryState, list: Booking[]): void {
    // Set z-inspectorid in all inspectors
    const zinspector = state.inspectorlist.find(
      (i) => i.name === "Z data HISTORIC bookings"
    );
    if (zinspector?.id) list.forEach((b) => (b.zinspectorid = zinspector.id));
    state.list = list;
  },
  setCurrentdate(state: DiaryState, date: Date): void {
    state.currentdate = moment(date)
      .utc()
      .startOf("day")
      .add(9, "hours")
      .toDate();
  },
  setGetaddressresponse(state: DiaryState, response: GetaddressResponse): void {
    state.getaddressresponse = response;
  },
  setPreviousbooking(state: DiaryState, booking: Booking): void {
    state.booking.previousbooking = booking;
    state.updates = [...new Set(state.updates.concat(["setPreviousbooking"]))];
  },

  // What & When
  setJobtype(state: DiaryState, type: DictionaryReportType): void {
    state.booking.jobtype = type.slug;
    state.updates = [...new Set(state.updates.concat(["setJobtype"]))];
  },
  setInternaljobtype(state: DiaryState, internaljobtype: string): void {
    state.booking.internaljobtype = internaljobtype;
    state.updates = [...new Set(state.updates.concat(["setInternaljobtype"]))];
  },
  setStartdate(state: DiaryState, startdate: string): void {
    state.booking.startdate = startdate;
    state.updates = [...new Set(state.updates.concat(["setStartdate"]))];
  },
  setEnddate(state: DiaryState, enddate: string): void {
    state.booking.enddate = enddate;
    state.updates = [...new Set(state.updates.concat(["setEnddate"]))];
  },
  setAddressDeep(
    state: DiaryState,
    payload: { path: string; data: any }
  ): void {
    state.booking.address = Object.assign(
      new Address(),
      _set(state.booking.address, payload.path, payload.data)
    );
    state.updates = [...new Set(state.updates.concat(["setAddressDeep"]))];
  },
  setPropertyspecDeep(
    state: DiaryState,
    payload: { path: string; data: any }
  ): void {
    state.booking.propertyspec = Object.assign(
      new PropertySpec(),
      _set(state.booking.propertyspec, payload.path, payload.data)
    );
    state.updates = [...new Set(state.updates.concat(["setPropertyspecDeep"]))];
  },
  setPreviousreport(state: DiaryState, previousreport: Report): void {
    state.booking.previousreport = previousreport;
    state.updates = [...new Set(state.updates.concat(["setPreviousreport"]))];
  },
  setDataentryreport(state: DiaryState, dataentryreport: Report): void {
    state.booking.dataentryreport = dataentryreport;
    state.updates = [...new Set(state.updates.concat(["setDataentryreport"]))];
  },
  setBookingDeep(
    state: DiaryState,
    payload: { path: string; data: any }
  ): void {
    state.booking = Object.assign(
      new Booking(),
      _set(state.booking, payload.path, payload.data)
    );
    if (payload.path != "locked" && payload.path != "lockedby")
      state.updates = [...new Set(state.updates.concat(["setBookingDeep"]))];
  },

  // Scheduling
  setInspector(state: DiaryState, inspector: Inspector): void {
    state.booking.inspector = inspector;
    state.updates = [...new Set(state.updates.concat(["setInspector"]))];
  },
  setRecommendedtime(state: DiaryState, recommendedtime: number): void {
    state.booking.recommendedtime = recommendedtime;
    state.updates = [...new Set(state.updates.concat(["setRecommendedtime"]))];
  },
  setAppointmenttime(state: DiaryState, appointmenttime: string): void {
    state.booking.appointmenttime = appointmenttime;
    state.updates = [...new Set(state.updates.concat(["setAppointmenttime"]))];
  },
  setAppointmentchanged(state: DiaryState, changed: boolean): void {
    state.booking.appointmentchanged = changed;
  },

  // Who For & How Much
  setCustomer(state: DiaryState, customer: Customer): void {
    state.booking.customer = customer;
    state.updates = [...new Set(state.updates.concat(["setCustomer"]))];
  },
  setInvoiceCustomer(state: DiaryState, customer: Customer): void {
    state.booking.invoicecustomer = customer;
    state.updates = [...new Set(state.updates.concat(["setInvoiceCustomer"]))];
  },
  setManagedtype(state: DiaryState, managedtype: string): void {
    state.booking.managedtype = managedtype;
    state.updates = [...new Set(state.updates.concat(["setManagedtype"]))];
  },
  setWorksorder(state: DiaryState, worksorder: string): void {
    state.booking.worksorder = worksorder;
    state.updates = [...new Set(state.updates.concat(["setLlphone"]))];
  },
  setPaymentinadv(state: DiaryState, paymentinadv: boolean): void {
    state.booking.paymentinadv = paymentinadv;
    state.updates = [...new Set(state.updates.concat(["setPaymentinadv"]))];
  },
  setInvoices(state: DiaryState, invoices: Report[]): void {
    state.booking.invoices = [...invoices];
  },
  addInvoice(state: DiaryState, invoice: Report): void {
    state.booking.invoices.push(invoice);
  },
  removeInvoice(state: DiaryState, index: number): void {
    if (index >= 0) state.booking.invoices.splice(index, 1);
  },
  setPaid(state: DiaryState, paid: boolean): void {
    state.booking.paid = paid;
    state.updates = [...new Set(state.updates.concat(["setPaid"]))];
  },
  setPaymentdate(state: DiaryState, paymentdate: string): void {
    state.booking.paymentdate = paymentdate;
    state.updates = [...new Set(state.updates.concat(["setPaymentdate"]))];
  },
  setDevelopment(state: DiaryState, development: Customer): void {
    state.booking.development = development;
    state.updates = [...new Set(state.updates.concat(["setDevelopment"]))];
  },

  // Access
  setKeypickup(state: DiaryState, keypickup: string): void {
    state.booking.keypickup = keypickup;
    state.updates = [...new Set(state.updates.concat(["setKeypickup"]))];
  },
  setKeypickupcompanyname(
    state: DiaryState,
    keypickupcompanyname: string
  ): void {
    state.booking.keypickupcompanyname = keypickupcompanyname;
    state.updates = [
      ...new Set(state.updates.concat(["setKeypickupcompanyname"])),
    ];
  },
  setKeypickupbranchname(state: DiaryState, keypickupbranchname: string): void {
    state.booking.keypickupbranchname = keypickupbranchname;
    state.updates = [
      ...new Set(state.updates.concat(["setKeypickupbranchname"])),
    ];
  },
  setKeypickupnotes(state: DiaryState, keypickupnotes: string): void {
    state.booking.keypickupnotes = keypickupnotes;
    state.updates = [...new Set(state.updates.concat(["setKeypickupnotes"]))];
  },
  setConfirmaccess(state: DiaryState, confirmaccess: boolean): void {
    state.booking.confirmaccess = confirmaccess;
    state.updates = [...new Set(state.updates.concat(["setConfirmaccess"]))];
  },
  setAccessissues(state: DiaryState, accessissues: string): void {
    state.booking.accessissues = accessissues;
    state.updates = [...new Set(state.updates.concat(["setAccessissues"]))];
  },
  setReleasekeysto(state: DiaryState, releasekeysto: string): void {
    state.booking.releasekeysto = releasekeysto;
    state.updates = [...new Set(state.updates.concat(["setReleasekeysto"]))];
  },
  setReleasekeystocompanyname(
    state: DiaryState,
    releasekeystocompanyname: string
  ): void {
    state.booking.releasekeystocompanyname = releasekeystocompanyname;
    state.updates = [
      ...new Set(state.updates.concat(["setReleasekeystobranchname"])),
    ];
  },
  setReleasekeystobranchname(
    state: DiaryState,
    releasekeystobranchname: string
  ): void {
    state.booking.releasekeystobranchname = releasekeystobranchname;
    state.updates = [
      ...new Set(state.updates.concat(["setReleasekeystobranchname"])),
    ];
  },
  setReleasekeystonotes(state: DiaryState, releasekeystonotes: string): void {
    state.booking.releasekeystonotes = releasekeystonotes;
    state.updates = [
      ...new Set(state.updates.concat(["setReleasekeystonotes"])),
    ];
  },
  setKeystobereleased(state: DiaryState, keystobereleased: string): void {
    state.booking.keystobereleased = keystobereleased;
    state.updates = [...new Set(state.updates.concat(["setKeystobereleased"]))];
  },
  addTenant(state: DiaryState, tenant: Tenant): void {
    state.booking.tenants.push(tenant);
    state.updates = [...new Set(state.updates.concat(["addTenant"]))];
  },
  updateTenant(state: DiaryState, tenant: Tenant): void {
    const index = _.findIndex(
      state.booking.tenants,
      (val: Tenant) => val.ttname === tenant.ttname
    );
    if (index >= 0) {
      state.booking.tenants.splice(index, 1, tenant);
      state.updates = [...new Set(state.updates.concat(["updateTenant"]))];
    }
  },
  removeTenant(state: DiaryState, tenant: Tenant): void {
    const index = _.findIndex(
      state.booking.tenants,
      (val: Tenant) => val.ttname === tenant.ttname
    );
    if (index >= 0) {
      state.booking.tenants.splice(index, 1);
      state.updates = [...new Set(state.updates.concat(["removeTenant"]))];
    }
  },
  addLandlord(state: DiaryState, landlord: Landlord): void {
    state.booking.landlords.push(landlord);
    state.updates = [...new Set(state.updates.concat(["addLandlord"]))];
  },
  updateLandlord(state: DiaryState, landlord: Landlord): void {
    const index = _.findIndex(
      state.booking.landlords,
      (val: Landlord) => val.llname === landlord.llname
    );
    if (index >= 0) {
      state.booking.landlords.splice(index, 1, landlord);
      state.updates = [...new Set(state.updates.concat(["updateLandlord"]))];
    }
  },
  removeLandlord(state: DiaryState, landlord: Landlord): void {
    const index = _.findIndex(
      state.booking.landlords,
      (val: Landlord) => val.llname === landlord.llname
    );
    if (index >= 0) {
      state.booking.landlords.splice(index, 1);
      state.updates = [...new Set(state.updates.concat(["removeLandlord"]))];
    }
  },
  addBookedby(state: DiaryState, bookedby: Bookedby): void {
    state.booking.bookedby.push(bookedby);
    state.updates = [...new Set(state.updates.concat(["addBookedby"]))];
  },
  updateBookedby(state: DiaryState, bookedby: Bookedby): void {
    const index = _.findIndex(
      state.booking.bookedby,
      (val: Bookedby) => val.bbname === bookedby.bbname
    );
    if (index >= 0) {
      state.booking.bookedby.splice(index, 1, bookedby);
      state.updates = [...new Set(state.updates.concat(["updateBookedby"]))];
    }
  },
  removeBookedby(state: DiaryState, bookedby: Bookedby): void {
    const index = _.findIndex(
      state.booking.bookedby,
      (val: Bookedby) => val.bbname === bookedby.bbname
    );
    if (index >= 0) {
      state.booking.bookedby.splice(index, 1);
      state.updates = [...new Set(state.updates.concat(["removeBookedby"]))];
    }
  },
  setAgencyaddress(state: DiaryState, address: Address): void {
    state.booking.agencyaddress = address;
    state.updates = [...new Set(state.updates.concat(["setAgencyaddress"]))];
  },
  setAgencyaddressDeep(
    state: DiaryState,
    payload: { path: string; data: any }
  ): void {
    state.booking.agencyaddress = Object.assign(
      new Address(),
      _set(state.booking.agencyaddress, payload.path, payload.data)
    );
    state.updates = [
      ...new Set(state.updates.concat(["setAgencyaddressDeep"])),
    ];
  },
  setKeyreleasetoaddress(state: DiaryState, address: Address): void {
    state.booking.keyreleasetoaddress = address;
    state.updates = [
      ...new Set(state.updates.concat(["setKeyreleasetoaddress"])),
    ];
  },
  setKeyreleasetoaddressDeep(
    state: DiaryState,
    payload: { path: string; data: any }
  ): void {
    state.booking.keyreleasetoaddress = Object.assign(
      new Address(),
      _set(state.booking.keyreleasetoaddress, payload.path, payload.data)
    );
    state.updates = [
      ...new Set(state.updates.concat(["setKeyreleasetoaddressDeep"])),
    ];
  },
  setKeypickupfromaddress(state: DiaryState, address: Address): void {
    state.booking.keypickupfromaddress = address;
    state.updates = [
      ...new Set(state.updates.concat(["setKeypickupfromaddress"])),
    ];
  },
  setKeypickupfromaddressDeep(
    state: DiaryState,
    payload: { path: string; data: any }
  ): void {
    state.booking.keypickupfromaddress = Object.assign(
      new Address(),
      _set(state.booking.keypickupfromaddress, payload.path, payload.data)
    );
    state.updates = [
      ...new Set(state.updates.concat(["setKeypickupfromaddressDeep"])),
    ];
  },
  setTenantattending(state: DiaryState, tenantattending: string): void {
    state.booking.tenantattending = tenantattending;
    state.updates = [...new Set(state.updates.concat(["setTenantattending"]))];
  },
  setDonotcontacttt(state: DiaryState, donotcontacttt: boolean): void {
    state.booking.donotcontacttt = donotcontacttt;
    state.updates = [...new Set(state.updates.concat(["setDonotcontacttt"]))];
  },

  // Other
  setInternalnotes(state: DiaryState, internalnotes: string): void {
    state.booking.internalnotes = internalnotes;
    state.updates = [...new Set(state.updates.concat(["setInternalnotes"]))];
  },
  setClientnotes(state: DiaryState, clientnotes: string): void {
    state.booking.clientnotes = clientnotes;
    state.updates = [...new Set(state.updates.concat(["setClientnotes"]))];
  },
  setLongtermnotes(state: DiaryState, longtermnotes: string): void {
    state.booking.longtermnotes = longtermnotes;
    state.updates = [...new Set(state.updates.concat(["setLongtermnotes"]))];
  },

  // Email
  setEmailLogs(state: DiaryState, emaillogs: EmailLogs): void {
    state.booking.emaillogs = emaillogs;
  },
  setCurrentEmailLog(state: DiaryState, emaillog: EmailLog): void {
    state.currentlog = emaillog;
  },
  updateTenantEmailConfirmationSent(state: DiaryState, booking: Booking): void {
    state.booking.emaillogs.tenantconfirmationsentdate =
      booking.emaillogs.tenantconfirmationsentdate;
  },
  updateTenantSMSConfirmationSent(state: DiaryState, booking: Booking): void {
    state.booking.smslogs.tenantconfirmationsentdate =
      booking.smslogs.tenantconfirmationsentdate;
  },
  updateClientEmailConfirmationSent(state: DiaryState, booking: Booking): void {
    state.booking.emaillogs.clientconfirmationsentdate =
      booking.emaillogs.clientconfirmationsentdate;
  },
  updateLandlordEmailConfirmationSent(
    state: DiaryState,
    booking: Booking
  ): void {
    state.booking.emaillogs.landlordconfirmationsentdate =
      booking.emaillogs.landlordconfirmationsentdate;
  },
  updateDataentryEmailConfirmationSent(
    state: DiaryState,
    booking: Booking
  ): void {
    state.booking.emaillogs.dataentryconfirmationsentdate =
      booking.emaillogs.dataentryconfirmationsentdate;
  },
  updateDataentryAcknowledge(state: DiaryState, booking: Booking): void {
    state.booking.emaillogs.dataentryacknowledgementreceiveddate =
      booking.emaillogs.dataentryacknowledgementreceiveddate;
  },

  //SMS
  setSMSLogs(state: DiaryState, smslogs: SMSLogs): void {
    state.booking.smslogs = smslogs;
  },

  insertBooking(state: DiaryState, booking: Booking): void {
    if (booking.id) {
      var index = state.list.findIndex((b: Booking) => b.id === booking.id);
      if (index < 0) {
        state.list.push(booking);
      }
    }
  },

  insertBookings(state: DiaryState, bookings: Booking[]): void {
    if (bookings.length > 0) {
      bookings.forEach((b) => {
        if (b.id) {
          var index = state.list.findIndex((b: Booking) => b.id === b.id);
          if (index < 0) {
            state.list.push(b);
          }
        }
      });
    }
  },

  updateBooking(state: DiaryState, booking: Booking): void {
    if (booking.id) {
      var index = state.list.findIndex((b: Booking) => b.id === booking.id);
      if (index >= 0) {
        state.list.splice(index, 1);
        state.list.push(booking);
      }
    }
  },

  removeBooking(state: DiaryState, id: string): void {
    if (id) {
      var index = state.list.findIndex((b: Booking) => b.id === id);
      if (index >= 0) state.list.splice(index, 1);
    }
  },
  addUnsavedChange(state: DiaryState, element: string): void {
    state.updates = [...new Set(state.updates.concat([element]))];
  },
  resetUnsavedChanges(state: DiaryState): void {
    state.updates = [];
  },

  setInspectors(state: DiaryState, inspectors: Inspector[]): void {
    state.inspectorlist = inspectors;
  },

  setInspectorBookings(
    state: DiaryState,
    payload: { inspectorid: string; bookings: Booking[] }
  ): void {
    state.inspectorlist.forEach((i) => {
      if (i.id === payload.inspectorid) {
        i.bookings = payload.bookings;
      }
    });
  },

  setPostcodeBookings(state: DiaryState, bookings: Booking[]): void {
    state.postcodesearchlist = bookings;
  },

  setCancelled(state: DiaryState, cancelled: boolean): void {
    state.booking.cancelled = cancelled;
  },

  setCustomers(state: DiaryState, customers: Customer[]): void {
    state.customerlist = customers;
  },
  setSelectedcustomername(state: DiaryState, name: string): void {
    state.selectedCustomername = name;
  },

  setShowbookingswithproblems(state: DiaryState, flag: boolean): void {
    state.showbookingswithproblems = flag;
  },

  // Parking/unparking
  parkBooking(state) {
    if (state.booking?.address?.postcode) {
      const index = state.parkedbookings.findIndex((b) => {
        if (b.id && b.id === state.booking?.id) return true;
        else if (b.address?.postcode === state.booking?.address?.postcode)
          return true;
        return false;
      });
      if (index < 0) {
        state.parkedbookings.push(state.booking);
        state.booking = new Booking();
      } else {
        state.parkedbookings.splice(index, 1, state.booking);
        state.booking = new Booking();
      }
    }
  },
  loadParkedBooking(state, index) {
    state.booking = state.parkedbookings.splice(index, 1)[0];
  },
  /**
   * Set the parkbookings list
   *
   * @param state DiaryState
   * @param customer Booking
   * @returns void
   */
  setParkBookings(state: DiaryState, parkBooking: Booking[]): void {
    state.parkedbookings = parkBooking;
  },
};
