<template>
  <div
    :id="id"
    class="modal"
    data-backdrop="false"
    data-keyboard="true"
    tabindex="-1"
    role="dialog"
    aria-labelledby="`subjob-modal-label`"
    aria-hidden="true"
  >
    
    <div class="modal-dialog modal-dialog-centered" :class="{'modal-xl': operation === 'edit'}" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="subjob-modal-label">
            <span v-if="operation === 'add'">Add {{ subjobtype }} job</span>
            <span v-if="operation === 'edit'">Edit {{ subjobtype }} job</span>
          </h5>
          <button
            id="close"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click.prevent="hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="row">
            <div :class="{'col-6': operation === 'edit', 'col-12': operation === 'add'}">
              <form>
                <fieldset>
                  <div class="form-group row">
                    <label class="col-md-2 col-form-label offset-1">Date</label>
                    <div class="col-md-6">
                      <DxDateBox
                        v-model="subjobdate"
                        display-format="dd/MM/yyyy"
                        type="date"
                        picker-type="calendar"
                        apply-value-mode="instantly"
                        :calendarOptions="{firstDayOfWeek:1}"
                        @value-changed="setCurrentdate"
                        :disabled="subjobtype === 'Shared'"
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-md-2 col-form-label offset-1"
                      >Inspector</label
                    >
                    <div class="col-sm-8">
                      <singleselect-text
                        v-model="inspector"
                        track-by="slug"
                        :options="inspectorlist"
                        :allow-empty="true"
                        :taggable="false"
                        :disabled="subjobtype === 'Key'"
                        class="no-padding"
                      >
                        <template 
                            #clear
                            v-if="selectedinspector && selectedinspector.id">
                          <i 
                            @mousedown.prevent.stop="resetInspector"
                            class="multiselect__clear fa fa-times"
                            aria-label="Clear Who"
                          ></i>
                        </template>
                        <template v-slot:singleLabel="{ option }">
                          <div class="row">
                            <div :class="getPiNameColClass(option)">
                              {{ option.name }}
                            </div>
                            <div class="col-md-1" v-if="previousPI(option)">
                              <i class="fas fa-redo"></i>
                            </div>
                            <div class="col-md-4" v-if="getRating(option)">
                              <i
                                v-for="index in getRating(option)"
                                :key="index"
                                class="fas fa-star"
                              ></i>
                            </div>
                          </div>
                        </template>
                        <template v-slot:option="{ option }"
                          ><div
                            class="row"
                            :class="{
                              green: getRating(option) >= 2,
                              amber: getRating(option) == 1,
                              red: getRating(option) == 0,
                            }"
                          >
                            <div class="col-md-7">{{ option.name }}</div>
                            <div class="col-md-1">
                              <i
                                v-if="previousPI(option)"
                                class="fas fa-redo"
                              ></i>
                            </div>
                            <div class="col-md-3">
                              <i
                                v-for="index in getRating(option)"
                                :key="index"
                                class="fas fa-star"
                              ></i>
                            </div></div
                        ></template>
                      </singleselect-text>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-md-2 col-form-label offset-1">Timing</label>
                    <div class="col-md-4 pr-0">
                      <singleselect-text
                        v-model="_startdate"
                        :options="starttimeoptions"
                        :customLabel="customLabel"
                        :allow-empty="true"
                        :taggable="false"
                        :ref="el => { dynamicRefs['startDateDropDown'] = el }"
                        @open="dropDownOpened('startDateDropDown')"
                      >
                        <template #clear
                          v-if="_startdate">
                          <i @mousedown.prevent.stop="_startdate = ''"
                            class="multiselect__clear fa fa-times"
                            aria-label="Clear Start Time"
                          ></i>
                        </template>
                      </singleselect-text>
                    </div>
                    <div class="col-md-4 pl-0">
                      <singleselect-text
                        v-model="_enddate"
                        :options="endtimeoptions"
                        :customLabel="customLabel"
                        :allow-empty="true"
                        :taggable="false"
                        :ref="el => { dynamicRefs['endDateDropDown'] = el }"
                        @open="dropDownOpened('endDateDropDown')"
                      >
                        <template #clear
                            v-if="_enddate">
                          <i @mousedown.prevent.stop="_enddate = ''"
                            class="multiselect__clear fa fa-times"
                            aria-label="Clear End Time"
                          ></i>
                        </template>
                      </singleselect-text>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-2 col-form-label offset-1">PI Notes</label>
                    <div class="col-md-8 pr-0">
                      <textarea
                        class="form-control"
                        rows="3"
                        v-model.lazy="pinotes"
                      ></textarea>
                    </div>
                  </div>
                  <!--div class="row" v-if="subjobbooking.preferredduration > 0">
                    <div
                      class="col-md-10"
                      v-if="recommendedtime > subjobbooking.preferredduration"
                    >
                      Recommended time overridden. {{ _preferredduration }} short.
                    </div>
                    <div
                      class="col-md-10"
                      v-if="recommendedtime < subjobbooking.preferredduration"
                    >
                      Recommended time overridden. Additional
                      {{ _preferredduration }} given.
                    </div>
                  </div-->
                </fieldset>
              </form>
            </div>
            <div v-if="operation === 'edit'" class="col-6 leadbooking-detail-container">
              <BookingDetail
                :booking="leadbooking" :leadbooking="leadbooking" :prepandkeybookings="booking"/>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            v-if="operation === 'edit' && booking.cancelled"
            type="button"
            class="btn btn-outline-secondary mr-auto"
            data-dismiss="modal"
            @click.prevent="restore()"
          >
            Restore
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary"
            data-dismiss="modal"
            @click.prevent="hide()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            @click.prevent="save()"
            :disabled="isSaving || !savingAllowed"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import { ref, computed, watch, onMounted, inject, defineEmits, nextTick, defineProps, defineExpose, PropType } from 'vue';
import moment from 'moment-timezone';
import { useStore } from 'vuex';
import { DxDateBox } from 'devextreme-vue/date-box';
import { Booking, Inspector, Location, ReportTiming, SelectOption} from '@/models';
import { useToast } from "vue-toastification";
import { useRouter, useRoute } from 'vue-router';
import { emitEvent } from '@/eventBus';
import { toRawObject } from '@/utilities';
import BookingDetail from "@/components/diary/BookingDetail.vue";

const emit = defineEmits(['hide', 'updatedsubbooking'])

const store = useStore();
const toasted = useToast();
const route = useRoute();
const actProperty: any = inject('actProperty');
const realtime: any = inject('realtime');

const channel = realtime.channels.get('diary');
const deviceid = actProperty.getDeviceId();

const operation = ref('');
const booking = ref<Booking>(new Booking());
const subjobtype = ref('');
const subjobbooking = ref(new Booking());
const selectedinspector = ref(new Inspector());
const subjobdate = ref(new Date());
const starttimeoptions = ref<string[]>([]);
const endtimeoptions = ref<string[]>([]);
const appointmenttimeoptions = ref<string[]>([]);
const isSaving = ref(false);

const dynamicRefs = ref({});

const props = defineProps<{
  id: string
}>();

// Getters
const _currentdate = computed((): Date => store.getters['diary/currentdate']);
const recommendedtime = computed((): number => store.getters['diary/recommendedtime']);
const bookings = computed((): Booking[] => store.getters['diary/list']);
const inspectors = computed((): Inspector[] => store.getters['diary/inspectorlist']);

// Actions and Mutations converted to functions
const addBooking = async (booking: Booking) => store.dispatch('diary/addBooking', booking);
const saveBooking = async (booking: Booking) => store.dispatch('diary/saveBooking', booking);
const restoreBookingInStore = async (booking: Booking) => store.dispatch('diary/restoreBooking', booking);
const updateBooking = async (booking: Booking) => store.commit('diary/updateBooking', booking);

onMounted(async () => {
  Promise.all([setStartTimeOptions(), setEndTimeOptions()])
    .then((values) => {})
    .catch((err) => {
      actProperty.displayError(err);
    });
});

const init = (book: Booking, sbt: string) => {
  booking.value = book;
  subjobtype.value = sbt;
  subjobbooking.value = Booking.forSubbooking(book);
  selectedinspector.value = new Inspector();
}

const add = () => {
  operation.value = "add";
  subjobbooking.value.auditlogs = [];
  subjobbooking.value.subtype = subjobtype.value;
  subjobdate.value = subjobbooking.value.startdateAsDate;
  if (subjobtype.value === Booking.PREP) {
    let sdateMoment = moment(booking.value.startdate).utc();
    let daystodeduct = -1;
    // If it is Monday, go back to Saturday
    if (sdateMoment.day() === 1) daystodeduct = -2;
    subjobdate.value = sdateMoment.add(daystodeduct, "days").toDate();
  }
  if (subjobtype.value != Booking.SHARED) {
    selectedinspector.value = booking.value.inspector;
  }

  if(route.name === 'newbooking'  || route.name === 'existingbooking') {
    // Change the date on the Diary to show 
    publishCurrentdateChange(moment(subjobdate.value)
      .utc()
      .format(actProperty.bookingdateformat));
  }
}

const edit = () => {
  operation.value = "edit";
  subjobdate.value = subjobbooking.value.startdateAsDate;
  selectedinspector.value = booking.value.inspector;

  if(route.name === 'newbooking'  || route.name === 'existingbooking') {
    // Change the date on the Diary to show 
    publishCurrentdateChange(moment(subjobdate.value)
      .utc()
      .format(actProperty.bookingdateformat));
  }
}


const setStartTimeOptions = () => {
  starttimeoptions.value = [];
  let startmoment = moment().set("hour", 6).set("minute", 0);
  for (let i = 0; i < 62; i++) {
    const hourMinutes = startmoment.format("hh:mm A");
    starttimeoptions.value.push(hourMinutes);
    appointmenttimeoptions.value.push(hourMinutes);
    startmoment.add(15, "minutes");
  }
}

const _startdate = computed({
  get: () => {
    let value = subjobbooking.value.startdate
      ? moment(subjobbooking.value.startdate).utc().format("hh:mm A")
      : "";
    const index = starttimeoptions.value.findIndex(
      (option: string) => option === value
    );
    if (index < 0) value = "";
    return value;
  },
  set: (val: string) => {
    subjobbooking.value.startdate = subjobendDate(
      val,
      subjobbooking.value.startdateAsDate
    );
    determinEnddate();
    determineAppointmenttime();
  }
});


watch(() => _startdate.value, (newValue) => {
  setEndTimeOptions();
});

const setEndTimeOptions = () => {
  endtimeoptions.value = [];
  let startmoment = moment().set("hour", 6).set("minute", 0);
  if (_startdate.value) {
    startmoment = moment(_startdate.value, "hh:mm A");
    startmoment.add(15, "minutes");
  }
  for (let i = 0; i < 62; i++) {
    const hourMinutes = startmoment.format("hh:mm A");
    endtimeoptions.value.push(hourMinutes);
    startmoment.add(15, "minutes");
    if (hourMinutes === "09:00 PM ") break;
  }
}

const _enddate = computed({
  get: () => {
    let value = subjobbooking.value.enddate
      ? moment(subjobbooking.value.enddate).utc().format("hh:mm A")
      : "";
    const index = endtimeoptions.value.findIndex(
      (option: string) => option === value
    );
    if (index < 0) value = "";
    return value;
  },
  set: (val: string) => {
    subjobbooking.value.enddate = subjobendDate(
      val,
      subjobbooking.value.startdateAsDate
    );
    let duration = subjobbooking.value.duration;
    if (duration === subjobbooking.value.recommendedtime) duration = 0;
    subjobbooking.value.preferredduration = duration;
    determineAppointmenttime();
  }
});

const determinEnddate = () => {
  if (subjobbooking.value.startdate && subjobbooking.value.recommendedtime) {
    let time = booking.value.preferredduration > 0
      ? booking.value.preferredduration
      : subjobbooking.value.recommendedtime;
    subjobbooking.value.enddate = subjobendDate(
      moment(subjobbooking.value.startdate).utc().add(time, 'minutes').format('hh:mm A'),
      subjobbooking.value.startdateAsDate
    );
  }
};

const determineAppointmenttime = () => {
  let appointmenttime = '';
  let timeinminutes = 0;
  if (booking.value.preferredappointmenttime > 0)
    timeinminutes = booking.value.preferredappointmenttime;
  else if (subjobbooking.value.recommendedappointmenttime > 0)
    timeinminutes = subjobbooking.value.recommendedappointmenttime;

  // Round it to the nearest 15 minutes interval
  timeinminutes = Math.round(timeinminutes / 15) * 15;
  if (subjobbooking.value.enddate && timeinminutes > 0) {
    appointmenttime = moment(subjobbooking.value.enddate).utc().subtract(timeinminutes, 'minutes').format('hh:mm A');
  } else if (subjobbooking.value.startdate) {
    appointmenttime = moment(subjobbooking.value.startdate).utc().format('hh:mm A');
  }
  subjobbooking.value.appointmenttime = subjobendDate(
    appointmenttime,
    subjobbooking.value.startdateAsDate
  );
};

const _preferredduration = computed(() => {
  return convertToHoursMinutes(subjobbooking.value.preferredduration);
});

const pinotes = computed({
  get: () => subjobbooking.value.pinotes,
  set: (val: string) => {
    subjobbooking.value.pinotes = val;
  }
});

const convertToHoursMinutes = (val: number) => {
  let hourMinutes = moment.utc().startOf('day').add(val, 'minutes').format('HH [Hours] mm [Minutes]');
  hourMinutes = hourMinutes.replaceAll('00 Hours', '');
  hourMinutes = hourMinutes.replaceAll('00 Minutes', '');
  return hourMinutes;
};

const restore = () => {
  restoreBookingInStore(booking.value)
    .then(() => {
      toasted.success('Booking restored');
      channel.publish('appointmentRestored', { deviceid, bookingid: booking.value.id });
    })
    .catch((err: any) => actProperty.displayError(err));
};

const save = async () => {
  hide();
  if (selectedinspector.value.id) {
    isSaving.value = true;
    if (operation.value === "add") {
      subjobbooking.value.id = "";
      if (!subjobbooking.value.leadbooking)
        subjobbooking.value.leadbooking = new Booking();
      subjobbooking.value.leadbooking.id = booking.value.id;
      if (subjobtype.value === Booking.PREP &&
          booking.value.jobtype === "checkin") {
        // https://trello.com/c/M96MSsLb/513-change-of-terminology-for-ci-prep
        //subjobbooking.value.jobtype = "inventory";
      }
      subjobbooking.value.inspector = selectedinspector.value;
      subjobbooking.value.startdate = subjobendDate(
        subjobbooking.value.starttime,
        subjobdate.value
      );
      subjobbooking.value.enddate = subjobendDate(
        subjobbooking.value.endtime,
        subjobdate.value
      );
      addBooking(subjobbooking.value)
        .then(async (b: Booking) => {
          channel.publish('newAppointmentAdded', {deviceid: deviceid, bookingid: b.id});
          booking.value.addSubbooking(b);
          if (
            subjobtype.value === Booking.PREP &&
            (booking.value.jobtype === "inventory" ||
            booking.value.jobtype === "checkin")
          ) {
            // https://trello.com/c/4wJJyycV/512-adding-a-prep-to-an-inventory-check-in
            //booking.value.jobtype = "checkin";
            //booking.value.internaljobtype = "Check-In - off ACT report";
            
            // Change the timing to reflect schedule
            // Check-In following Check-Out less than 24 hours ago or following an Inventory Prep - off ACT report or upload and same PI
            let timing: ReportTiming | undefined =
              actProperty.determineRecommendedtime(
                booking.value,
                "Check-In following Check-Out less than 24 hours ago or following an Inventory Prep - off ACT report or upload and same PI"
              );
            if (timing) {
              // eslint-disable-next-line vue/no-mutating-props
              booking.value.startdate = actProperty.prependDate(
                moment(booking.value.enddate)
                  .utc()
                  .subtract(timing.timing, "minutes")
                  .format("hh:mm A"),
                booking.value.startdate,
                booking.value.enddateAsDate
              );
            }
          }
          await saveBooking(booking.value).then((b: Booking) => {
            isSaving.value = false;
            toasted.success(`Added ${subjobtype.value}`);
            updateBooking(booking.value);
            // Update children
            let children: Booking[] = bookings.value.filter((b: Booking) => b.leadbooking?.id === booking.value.id);
            if(children?.length) {
              children.forEach((b: Booking) => {
                b.leadbooking = new Booking(booking.value);
                updateBooking(b);
              });
            }
            channel.publish('appointmentChanged', {deviceid: deviceid, bookingid: booking.value.id, source: 'SubJobModal'} );
          });
        })
        .catch((err: any) => {
          isSaving.value = false;
          actProperty.displayError(err);
        });
    } else {
      subjobbooking.value.inspector = selectedinspector.value;
      subjobbooking.value.startdate = subjobendDate(
        subjobbooking.value.starttime,
        subjobdate.value
      );
      subjobbooking.value.enddate = subjobendDate(
        subjobbooking.value.endtime,
        subjobdate.value
      );
      await saveBooking(subjobbooking.value)
        .then(() => {
          isSaving.value = false;
          channel.publish('appointmentChanged', {deviceid: deviceid, bookingid: subjobbooking.value.id, source: 'SubJobModal'} );
          emitEvent("appointmentChangedInBookingTemplate", subjobbooking.value);
          emit('updatedsubbooking', subjobbooking.value);
        })
        .catch((err: any) => {
          isSaving.value = false;
          actProperty.displayError(err);
        });
    }
  }
};

const subjobendDate = (time: string, date: Date) => {
  let value = "";
  if (time) {
    let dt = date ? date : _currentdate.value;
    let justdate = moment(dt).utc().format("YYYY-MM-DD");
    let justtime = moment(time, "hh:mm A").format("HH:mm");
    value = `${justdate}T${justtime}:00.000Z`;
  }
  return value;
};

const setCurrentdate = (e: any) => {
  if (route.name === 'newbooking' || route.name === 'existingbooking') {
    publishCurrentdateChange(moment(e.value).utc().format(actProperty.bookingdateformat));
  }
};

const publishCurrentdateChange = (val: string) => {
  channel.publish('currentdateChanged', { deviceid, bookingdate: val });
};

const inspectorlist = computed((): SelectOption[] => {
  const activeinspectors = inspectors.value.filter((i) => !i.inactive);
  let sortedlist = actProperty.sortInspectorList(activeinspectors, {
    previousreport: booking.value?.basereport,
    postcode: booking.value?.address?.postcode,
  });

  if (subjobtype.value === Booking.SHARED) {
    sortedlist = sortedlist.filter(
      (i: Inspector) => i.id != booking.value?.inspector?.id
    );
  }
  return sortedlist.map(
    (insp: Inspector) => new SelectOption({ name: insp.name, slug: insp.id })
  );
});

const inspector = computed({
  get: () => {
    let insp = inspectors.value.find((i) => i.id == selectedinspector.value.id);
    return new SelectOption({ name: insp?.name, slug: insp?.id });
  },
  set: (val: SelectOption) => {
    if (val) {
      let insp = inspectors.value.find((i) => i.id == val.slug);
      if (insp) {
        selectedinspector.value = insp;
      }
    }
  }
});
const resetInspector = () => {
  selectedinspector.value = new Inspector();
}

const leadbooking = computed(() => {
  if(booking.value?.leadbooking?.id) return booking.value.leadbooking;
  else return booking.value;
})

const getRating = (option: any): number => {
  let inspector = option && option.slug 
    ? inspectors.value.find((i: Inspector) => i.id === option.slug)
    : undefined;

  let rating = 0;
  if (inspector?.locations?.length && booking.value?.address?.postcode) {
    let postcode = booking.value.address.postcode.replace(/ /g, "");
    let areacode = postcode.substring(0, postcode.length - 3);
    if (areacode) {
      let location = inspector.locations.find((l: Location) => l.code.trim() === areacode.trim());
      if (location) rating = location.rating;
    }
  }
  return rating;
};

const previousPI = (option: any): boolean => {
  let inspector = inspectors.value.find((i: Inspector) => i.id === option.slug);
  return inspector && booking.value?.basereport?.user
    ? inspector.email === booking.value.basereport.user
    : false;
};

const getPiNameColClass = (option: any) => {
  let rating = getRating(option);
  let previous = previousPI(option);
  let columns = 11;
  if (rating) columns -= 4;
  if (previous) columns -= 1;
  return `col-md-${columns}`;
};

const dropDownOpened = (ref: string) =>{
  nextTick(() => {
    // Get the reference to the dropdown list container
    const multiselect = dynamicRefs.value[ref] as any;
    const dropdownList = multiselect.$el.querySelector('.multiselect__content-wrapper');

    // Get the reference to the selected option element
    const selectedOption = multiselect.$el.querySelector('.multiselect__option--selected');

    // Scroll to the position of the selected element
    if(selectedOption?.offsetTop)
      dropdownList.scrollTop = selectedOption.offsetTop;
  });
}

const savingAllowed = computed(() => {
  let result = false;
  if (selectedinspector.value && selectedinspector.value.id && subjobdate.value)
    result = true;
  return result;
});

const show = () => {
  if ($(`#${props.id}` + 'Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.id + 'Backdrop"></div>');
    $('body').append(backdropDiv);
    $(`#${props.id}`).show();
  }
}
const hide = () => {
  if ($(`#${props.id}`).length > 0) {
    $(`#${props.id}` + 'Backdrop').remove();
    $(`#${props.id}`).hide();
    emit('hide')
  }
};

defineExpose({init, add, edit, show, hide});

const customLabel = (option: string) => {
  return option.replace(/^0/, '');
}
</script>


<style scoped lang="scss">
.text-yellow {
  color: #e9db4b;
}

.no-padding ::v-deep .multiselect__option {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  min-height: 0px !important;
  line-height: 0px !important;
}

.leadbooking-detail-container {
  max-height: 70vh;
  overflow-y: scroll;
}
</style>