<template>
  <div>
    <header>
      <div class="row">
        <div class="col-sm-6">
          <h1>System Settings</h1>
        </div>
        <div class="col-sm-6 text-right" v-if="defaultpropertylist.length">
          <button class="btn btn-lg btn-primary" @click="addnewproperty">
            <i class="fas fa-plus-square"></i>
            New
          </button>
        </div>
      </div>
    </header>

    <div
      v-if="
        (!systemproperties || !systemproperties.length) && !hasBlockingRequests
      "
      class="alert alert-warning text-center"
    >
      <h2 class="mt-1 mb-2">No settings found!</h2>
    </div>

    <table v-else class="table table-fixed">
      <tbody>
        <tr v-for="(systemproperty, $index) in systemproperties" :key="$index">
          <td class="col-md-3">
            <div v-if="systemproperty === currentsystemproperty">
              <singleselect-text
                v-model="currentsystemproperty"
                :options="defaultpropertylist"
                :custom-label="customLabelForSystemProperty"
                @select="onSelectPropertyType"
              ></singleselect-text>
            </div>
            <div v-else>
              <div>
                {{ systemproperty.prompt }}
              </div>
            </div>
          </td>
          <td class="col-md-5">
            <input
              v-if="
                systemproperty.propertytype === '' ||
                systemproperty.propertytype === 'text'
              "
              type="text"
              class="form-control"
              :value="systemproperty.value"
              @change="saveSystemPropertyValue($index, $event)"
            />

            <input
              v-if="systemproperty.propertytype === 'number'"
              type="number"
              class="form-control"
              :value="systemproperty.value"
              @change="saveSystemPropertyValue($index, $event)"
            />

            <tiptap-editor
              v-if="systemproperty.propertytype === 'richtext'"
              :key="systemproperty.propertykey"
              :content="systemproperty.value"
              @blur="setSystemPropertyValue({ index: $index, data: $event })"
            />
            <div class="text-secondary">{{ getPropertyDescription(systemproperty) }}</div>
          </td>
          <td class="col-md-1 text-right">
            <button
              class="btn btn-outline-danger"
              @click="remove($index)"
              v-on:click.stop
            >
              <i class="fas fa-trash-alt"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, inject, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { SystemProperty, Dictionary } from '@/models';
import TiptapEditor from '@/components/editor/TiptapEditor.vue';

const store = useStore();

const defaultproperties = [
  {
    propertykey: "current.app.version",
    prompt: "Curent app version",
    description: "Version number to be incremeneted everyime there is a new app release. This shows notification on all tablets and encourages the users to upgrade",
    propertytype: "number",
  },
  {
    propertykey: "app.release.notes",
    prompt: "Release notes",
    description: "Announcements and change logs for new feature as well as bug fixes. These notes gives the user an impression of what is changing it the app.",
    propertytype: "richtext",
  },
  {
    propertykey: "booking.email.fromaddress",
    prompt: "Booking confirmations from address",
    description: "Source email address to be used while sending various confirmations and notification email from booking form",
    propertytype: "text",
  },
  {
    propertykey: "booking.email.ccaddress",
    prompt: "Booking confirmations CC address",
    description: "CC email address to be used while sending various confirmations and notification email from booking form",
    propertytype: "text",
  },
  {
    propertykey: "booking.email.bccaddress",
    prompt: "Booking confirmations BCC address",
    description: "BCC email address to be used while sending various confirmations and notification email from booking form",
    propertytype: "text",
  },
  {
    propertykey: "dataentry.email",
    prompt: "Data entry email address",
    description: "Default email address for sending data entry related emails",
    propertytype: "text",
  },
];

const defaultpropertylist = computed(() => {
  return defaultproperties
    .filter((dp) => {
      const index = systemproperties.value?.findIndex(
        (sp: SystemProperty) => dp.propertykey === sp.propertykey
      );
      return index !== undefined && index < 0;
    })
    .map(SystemProperty.parse);
});

const systemproperties = computed(() => store.getters['dictionary/systemproperties']);
const currentsystemproperty = computed(() => store.getters['dictionary/currentsystemproperty']);
const hasBlockingRequests = computed(() => store.getters['app/hasBlockingRequests']);

const setSystemPropertyValue = async (payload: {
    index: number;
    data: string;
  }) => {
    await store.dispatch('dictionary/setSystemPropertyValue', payload);
};

const customLabelForSystemProperty = (property: SystemProperty) => {
  return property.prompt;
};

const getPropertyDescription = (sp: SystemProperty) => {
  let description = '';
  const defaultproperty = defaultproperties.find((dp) => dp.propertykey === sp.propertykey);
  if (defaultproperty) {
    description = defaultproperty.description;
  }
  return description;
};

const addnewproperty = async () => {
  await store.dispatch('dictionary/addSystemProperty', new SystemProperty());
};

const remove = async (index: number) => {
  await store.dispatch('dictionary/removeSystemProperty', index);
};

const onSelectPropertyType = async (property: any) => {
  await store.dispatch('dictionary/setSystemPropertyKey', property);
};

const saveSystemPropertyValue = async (index: number, $event: any) => {
  await setSystemPropertyValue({
      index,
      data: $event.target.value,
    });
};

</script>

<style lang="scss" scoped>
</style>