<template>
  <div class="reports">
    <header>
      <div class="row">
        <div class="col-sm-6">
          <h1>Reports</h1>
        </div>
        <div v-if="isDataentry" class="col-sm-6 text-right">
          <router-link class="btn btn-lg btn-primary" to="/dereports/new">
            <i class="fas fa-plus-square"></i>
            New Report
          </router-link>
        </div>
      </div>
    </header>

    <div class="row mb-3">
      <ReportsSearchByMonth class="col-sm-2" />
      <button
        type="submit"
        class="btn btn-outline-primary col-sm-1"
        @click="resetSearch()"
      >
        <i class="fas fa-calendar-day"></i>
        This month
      </button>
      <ReportsSearchByStatus
        class="col-sm-3"
      />
      <ReportsSearchByString
        class="col-sm-6"
        placeholder="Search all reports"
      />
    </div>

    <ReportsList />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import moment from 'moment-timezone';
import _ from 'lodash';

import ReportsList from '@/components/dataentry/DEList.vue';
import ReportsSearchByMonth from '@/components/dataentry/SearchByMonth.vue';
import ReportsSearchByString from '@/components/dataentry/SearchByString.vue';
import ReportsSearchByStatus from '@/components/dataentry/SearchByStatus.vue';

const store = useStore();
const router = useRouter();
const route = useRoute();

// State
const reportsState = computed(() => store.state.reports);

// Getters
const isDataentry = computed(() => store.getters['auth/isDataentry']);

// Actions
const setMonth = (month: string): Promise<any> => store.dispatch('reports/setMonth', month);
const setDataentrystatus = (status: string): Promise<any> => store.dispatch('reports/setDataentrystatus', status);
const setSearch = (search: string): Promise<any> => store.dispatch('reports/setSearch', search);
const getReports = (payload: { withbooking: boolean }): Promise<any> => store.dispatch('reports/getReports', payload);
const resetCurrentPage = (): Promise<any> => store.dispatch('reports/resetCurrentPage');
const setCurrentPage = (page: number): Promise<any> => store.dispatch('reports/setCurrentPage', page);

// Methods
const prepareQuery = () => {
  let query = { month: '', search: '', dataentrystatus: '' };
  if (!reportsState.value.month && !reportsState.value.search && !reportsState.value.dataentrystatus){
    const page = reportsState.value.page ? Number(reportsState.value.page) : 1;
    setCurrentPage(page);
    setMonth(moment().format("YYYY-MM"));
    query["month"] = moment().format("YYYY-MM");
  } else {
    if (reportsState.value.month)
      query["month"] = reportsState.value.month;
    if (reportsState.value.search)
      query["search"] = reportsState.value.search;
    if (reportsState.value.dataentrystatus)
      query["dataentrystatus"] = reportsState.value.dataentrystatus;
  }  
  return query;
};

const resetSearch = () => {
  if (route.query.month != moment().format("YYYY-MM")) {
    router.push({
      path: "dereports",
      query: { month: moment().format("YYYY-MM") },
    });
    resetCurrentPage();
  }
};

// Watchers and Lifecycle Hooks
onMounted(async () => {
  const query = route.query;
  if (typeof query.month === "string" && query.month.length) {
    const page = query.page ? Number(query.page) : 1;
   setCurrentPage(page);
   setMonth(query.month)
   .then(() => getReports({ withbooking: true }));
  } else if (typeof query.search === "string" && query.search.length) {
   setSearch(query.search)
   .then(() => getReports({ withbooking: true }));
  } else if (typeof query.dataentrystatus === "string" && query.dataentrystatus.length) {
   setDataentrystatus(query.dataentrystatus)
   .then(() => getReports({ withbooking: true }));
  } else {
    const queryToPush = prepareQuery();
    router.push({ path: "dereports", query: queryToPush });
  }
});

watch(() => route.query.month, async (val, oldVal) => {
  if (val) {
   setMonth(val as string)
   .then(() => getReports({ withbooking: true }));
  }
});

watch(() => route.query.search, async (val, oldVal) => {
  if (val) {
   setSearch(val as string)
   .then(() => getReports({ withbooking: true }));
  }
});

watch(() => route.query.dataentrystatus, async (val, oldVal) => {
  if (val) {
   setDataentrystatus(val as string);
   getReports({ withbooking: true });
  } else {
   setDataentrystatus('')
   .then(() => getReports({ withbooking: true }));
  }
});

watch(() => route.query.page, async (val, oldVal) => {
  if (val) {
   setCurrentPage(Number(val))
   .then(() => getReports({ withbooking: true }));
  }
});
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.act-top-menu {
  display: none !important;
}
</style>
