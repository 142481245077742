import _get from 'lodash/get';
import snakeCaseKeys from 'snakecase-keys';

import { copyInto } from '@/utilities';
import { DictionaryRoom, DictionarySection, DictionaryReportType, SystemProperty } from '@/models';

export class Dictionary {

  public rooms: DictionaryRoom[];
  public sections: DictionarySection[];
  public reporttypes?: DictionaryReportType[];
  public systemproperties?: SystemProperty[];

  /**
   * Create an object from a JSON representation
   * @param data
   * @return Dictionary
   */
  public static parse(data: object) {
    const obj = new Dictionary;

    copyInto(obj, data, false); // false param to bypass camelcase-keys

    obj.rooms = obj.rooms ? obj.rooms.map(DictionaryRoom.parse) : [];
    obj.sections = obj.sections ? obj.sections.map(DictionarySection.parse) : [];
    obj.reporttypes = obj.reporttypes ? obj.reporttypes.map(DictionaryReportType.parse) : [];
    obj.systemproperties = obj.systemproperties ? obj.systemproperties.map(SystemProperty.parse) : [];

    return obj;
  }

  /**
   * Empty Item Colours And Conditions, this was required when migrating the dictionary
   * data from the old app XML file
   *
   * @param {Dictionary} dictionary
   * @return {Dictionary}
   */
  public static emptyItemColoursAndConditions(dictionary: Dictionary) {
    dictionary.sections.map((section) => {
      return section.types.map((type) => {
        return type.items.map((item) => {
          // Keep the "Misc" Item values
          if (section.name === 'Misc' && type.name === 'Misc' && item.name === 'Misc') {
            return item;
          }
          // Reset all other Items
          item.conditions.additional = [];
          item.colours = [];
          return item;
        });
      });
    });
    return dictionary;
  }

  /**
   * Constructor
   */
  public constructor() {
    this.rooms = [];
    this.sections = [];
    this.reporttypes = [];
    this.systemproperties = [];
  }

  /**
   * Create a JSON representation of this object
   * @param data
   * @return Report
   */
  public toJSON() {
    return {
      rooms: this.rooms,
      sections: snakeCaseKeys(this.sections),
      reporttypes: this.reporttypes,
      systemproperties: this.systemproperties,
    };
  }

}
