<template>
  <div :id="id" class="modal" data-keyboard="true" tabindex="-1" role="dialog"
    aria-labelledby="`booking-detail-modal-label`" aria-hidden="true" style="">
    <div class="modal-dialog modal-dialog-centered" role="document" :class="{ 'modal-lg': booking.googleid }">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="sms-modal-label">
            <span v-if="booking.googleid">Google event <span v-if="booking.inspector">: {{ booking.inspector.name
                }}</span></span>
            <span v-else>{{ title }}</span>
          </h5>
          <content-editable v-if="booking.jobtype && editable" :key="titlenotes" :content="titlenotes"
            class="ml-2 mr-2 d-inline-block col-md-9" @click.prevent @update="titlenotes = $event"
            @delete="titlenotes = ''"></content-editable>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="hide">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body" :key="bookingid">
          <div id="booking-detail-modal-body">
            <div v-if="booking.googleid">
              <div>
                <h3 class="mb-0">Time</h3>
              </div>
              <div v-if="booking.startdate || booking.enddate">
                {{ booking.starttime }}
                to
                {{ booking.endtime }}
              </div>
              <div class="mt-4" v-if="booking.summary">
                <h3 class="mb-0">Summary</h3>
              </div>
              <div v-if="booking.summary">{{ booking.summary }}</div>
              <div class="mt-4" v-if="booking.location">
                <h3 class="mb-0">Location</h3>
              </div>
              <div v-if="booking.location">{{ booking.location }}</div>
              <div class="mt-4" v-if="booking.description">
                <h3 class="mb-0">Description</h3>
              </div>
              <div v-if="booking.description" v-html="booking.description"> </div>
            </div>
            <div v-else-if="booking.jobtype === 'Personal'">
              <div>
                <h3 class="mb-0">Time</h3>
              </div>
              <div v-if="booking.startdate || booking.enddate">
                {{ booking.starttime }}
                to
                {{ booking.endtime }}
              </div>
              <div class="mt-4" v-if="booking.summary">
                <h3 class="mb-0">Summary</h3>
              </div>
              <div v-if="booking.summary">{{ booking.summary }}</div>
              <div class="mt-4" v-if="booking.description">
                <h3 class="mb-0">Description</h3>
              </div>
              <div v-if="booking.description" v-html="booking.description"> </div>
            </div>
            <div v-else-if="booking.subtype === 'Key'">
              <div v-if="booking.provisional">
                <h3 class="mb-0">Provisional</h3>
              </div>
              <div v-if="booking.provisional" class="mb-4">
                Why: {{ booking.provisionalnotes }}
              </div>

              <div class="mt-4">
                <h3 class="mb-0">Inspector</h3>
              </div>
              <div v-if="booking.inspector">
                {{ booking.inspector.name }}
              </div>
              <div v-if="booking.leadbooking?.inspector">
                {{ booking.leadbooking.inspector.name }} -  ({{ masterReportType }})
              </div>
              <div class="mt-4">
                <h3 class="mb-0">Time</h3>
              </div>
              <div v-if="booking?.startdate || booking?.enddate">
                {{ booking.starttime }}
                to
                {{ booking.endtime }}
              </div>
              <div v-if="booking?.leadbooking?.startdate || booking.leadbooking?.enddate">
                {{ booking?.leadbooking?.bookingdate }} -
                {{ booking?.leadbooking?.starttime }}
                to
                {{ booking?.leadbooking?.endtime }} - ({{ masterReportType }})
              </div>

              <div class="mt-4" @click="onAddressclick">
                <h3 class="mb-0">Address</h3>
              </div>
              <div @click="onAddressclick">
                {{ actProperty.formatAddress(booking.address, ", ") }}
              </div>
              <div class="mt-4" v-if="booking.leadbooking && booking.leadbooking.keypickup">
                <h3 class="mb-0">Key collection</h3>
              </div>
              <div v-if="booking.leadbooking && booking.leadbooking.keypickup">
                {{ booking.leadbooking.keypickup }}: {{ booking.leadbooking.customer.companyName }} - {{
    booking.leadbooking.keypickupbranchname }}
              </div>
              <div
                v-if="booking.leadbooking && booking.leadbooking.keypickup && booking.leadbooking.keypickup === 'From Agency'">
                {{ actProperty.formatAddress(booking.leadbooking.keypickupfromaddress, ", ") }}
              </div>

              <div v-if="booking.pinotes" class="mt-4">
                <h3 class="mb-0">Notes</h3>
              </div>
              <div v-if="booking.pinotes">
                {{ booking.pinotes }}
              </div>

            </div>
            <div v-else-if="booking">
              <BookingDetail
                v-if="booking.subtype === 'Prep' || booking.subtype === 'Shared' || booking.subtype === 'Revisit'"
                :booking="booking" :leadbooking="booking.leadbooking" />
              <BookingDetail v-else :booking="booking" :leadbooking="booking"
                :prepandkeybookings="prepandkeybookings" />
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" @click.prevent="hide">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import $ from 'jquery';
import _get from 'lodash/get';
import { ref, computed, onMounted, inject, defineProps, defineExpose } from 'vue';
import { useStore } from 'vuex';
import ContentEditable from "@/components/ContentEditable.vue";
import BookingDetail from "@/components/diary/BookingDetail.vue";

// Import your models or other external methods
import { Bookedby, Booking, CognitoUser, Dictionary, Landlord, Tenant } from "@/models";

const props = defineProps({
  editable: {
    type: Boolean,
    default: true,
  },
  id: String
});

const store = useStore();

const cognitoUser = computed((): CognitoUser => store.getters['auth/cognitoUser']);
const dictionary = computed((): Dictionary => store.getters['dictionary/current']);

const saveBooking = (booking: Booking) => {
  return store.dispatch("diary/saveBookingFromScheduler", booking);
};

const realtime: any = inject('realtime');
const actProperty: any = inject('actProperty');

const channel = realtime.channels.get('diary');
const deviceid = actProperty.getDeviceId();

const booking = ref(new Booking());
const prepandkeybookings = ref<Booking[]>([]);


const title = ref("");
const titlenoteschanged = ref(false);
const bookingid = computed(() => booking.value.id);
const titlenotes = computed({
  get: () => booking.value.titlenotes,
  set: (val) => {
    booking.value.titlenotes = val;
    titlenoteschanged.value = true;
  }
});

// Methods
const init = (bookingData: Booking) => {
  booking.value = bookingData;
  title.value = actProperty.getBookingTitle(booking.value, dictionary.value);
  if (!bookingData.subtype) {
    prepandkeybookings.value = bookingData.subbookings.filter((f: Booking) => f.subtype === "Prep" || f.subtype === "Key");
  }
};

const masterReportType = computed(() => {
  return actProperty.formatReportType(booking.value.leadbooking?.jobtype, dictionary)
});

const pischeduler = computed(() => {
  const assignedGroups = _get(
    cognitoUser.value,
    'signInUserSession.accessToken.payload["cognito:groups"]',
    []
  );
  const isDiaryUser = assignedGroups.some(
    (assignedGroup: string) => ["DiaryUser"].includes(assignedGroup)
  );
  return (screen.width <= 760 && isDiaryUser);
});

const onAddressclick = () => {
  if (pischeduler.value) {
    let address = actProperty.formatAddress(booking.value.address, ", ");
    window.open(`http://maps.google.com/?q=${encodeURI(address)}`, '_blank', 'noreferrer');
  }
}


const show = () => {
  if ($(`#${props.id}` + 'Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.id + 'Backdrop"></div>');
    $('body').append(backdropDiv);
    let el = $(`#${props.id}`);
    el.show();
  }
}

const hide = () => {
  if ($(`#${props.id}`).length > 0) {
    $(`#${props.id}` + 'Backdrop').remove();
  }
  if (titlenoteschanged.value) {
    saveBooking(booking.value).then((b: Booking) => {
      channel.publish('appointmentChanged', { deviceid: deviceid, bookingid: b.id, source: 'Scheduler' });
      $(`#${props.id}`).hide();
    });
  }
  else {
    $(`#${props.id}`).hide();
  }
};

defineExpose({ init, show, hide });
</script>


<style scoped lang="scss">
.modal-body {
  max-height: 50vh;
  overflow-y: scroll;
}
</style>