import _get from 'lodash/get';
import _castArray from 'lodash/castArray';
import { copyInto } from '@/utilities';
import { QCRule } from './qcrule.model';
import { Base } from '../_base.class';

export class QCRules extends Base {

  public misc: string;
  public invciissue: QCRule;
  public cosocissue: QCRule;
  public invciissuetoll: QCRule;
  public cosocissuetoll: QCRule;
  public invciissuetott: QCRule;
  public cosocissuetott: QCRule;
  public propvisitissue: QCRule;
  public otherreportissue: QCRule;
  public invciinvoiceissue: QCRule;
  public cosocinvoiceissue:QCRule

  /**
   * Create an object from a JSON representation
   * @param data
   * @return QCRules
   */
  public static parse(data?: Partial<QCRules>) {
    const obj = new QCRules;
    copyInto(obj, data);
    obj.misc = _get(data, 'misc', '');
    obj.invciissue = QCRule.parse(_get(data, 'invciissue', {}));
    obj.cosocissue = QCRule.parse(_get(data, 'cosocissue', {}));
    obj.invciissuetoll = QCRule.parse(_get(data, 'invciissuetoll', new QCRule({includeinvoice: false})));
    obj.cosocissuetoll = QCRule.parse(_get(data, 'cosocissuetoll', new QCRule({includeinvoice: false})));
    obj.invciissuetott = QCRule.parse(_get(data, 'invciissuetott', new QCRule({includeinvoice: false})));
    obj.cosocissuetott = QCRule.parse(_get(data, 'cosocissuetott', new QCRule({includeinvoice: false})));
    obj.propvisitissue = QCRule.parse(_get(data, 'propvisitissue', new QCRule({includeinvoice: false})));
    obj.otherreportissue = QCRule.parse(_get(data, 'otherreportissue', new QCRule({includeinvoice: false, ccbookedby: false})));
    obj.invciinvoiceissue = QCRule.parse(_get(data, 'invciinvoiceissue', new QCRule({includeinvoice: false, ccbookedby: false})));
    obj.cosocinvoiceissue = QCRule.parse(_get(data, 'cosocinvoiceissue', new QCRule({includeinvoice: false, ccbookedby: false})));
    
    return obj;
  }

  /**
   * Constructor
   */
  public constructor(data?: Partial<QCRule>) {
    super(data);
    this.misc = _get(data, 'misc', '');
    this.invciissue = QCRule.parse(_get(data, 'invciissue', {}));
    this.cosocissue = QCRule.parse(_get(data, 'cosocissue', {}));
    this.invciissuetoll = QCRule.parse(_get(data, 'invciissuetoll', new QCRule({includeinvoice: false})));
    this.cosocissuetoll = QCRule.parse(_get(data, 'cosocissuetoll', new QCRule({includeinvoice: false})));
    this.invciissuetott = QCRule.parse(_get(data, 'invciissuetott', new QCRule({includeinvoice: false})));
    this.cosocissuetott = QCRule.parse(_get(data, 'cosocissuetott', new QCRule({includeinvoice: false})));
    this.propvisitissue = QCRule.parse(_get(data, 'propvisitissue', new QCRule({includeinvoice: false})));
    this.otherreportissue = QCRule.parse(_get(data, 'otherreportissue', new QCRule({includeinvoice: false, ccbookedby: false})));
    this.invciinvoiceissue = QCRule.parse(_get(data, 'invciinvoiceissue', new QCRule({includeinvoice: false, ccbookedby: false})));
    this.cosocinvoiceissue = QCRule.parse(_get(data, 'cosocinvoiceissue', new QCRule({includeinvoice: false, ccbookedby: false})));
  }

  public getQcrule(jobtype: string, target: string): QCRule {
    var qcrule = undefined;
    if (target === 'client') {
      if (jobtype === "checkout" || jobtype === "soc") {
        qcrule = this.invciissue;
      } else if (jobtype === "inventory" || jobtype === "checkin") {
        qcrule = this.invciissue;
      } else if (jobtype === "property visit") {
        qcrule = this.propvisitissue;
      } else {
        qcrule = this.otherreportissue;
      }
    }
    if (target === 'landlord') {
      if (jobtype === "checkout" || jobtype === "soc") {
        qcrule = this.cosocissuetoll;
      } else if (jobtype === "inventory" || jobtype === "checkin") {
        qcrule = this.invciissuetoll;
      }
    }
    if (target === 'tenant') {
      if (jobtype === "checkout" || jobtype === "soc") {
        qcrule = this.cosocissuetott;
      } else if (jobtype === "inventory" || jobtype === "checkin") {
        qcrule = this.invciissuetott;
      }
    }
    if (target === 'invoice') {
      if (jobtype === "checkout" || jobtype === "soc") {
        qcrule = this.cosocinvoiceissue;
      } else if (jobtype === "inventory" || jobtype === "checkin") {
        qcrule = this.invciinvoiceissue;
      }
    }
    return qcrule;
  }

}
