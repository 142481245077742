<template>
  <div class="card reports--room">
    <div class="card-header" :class="{ 'toggle-body': _toggle }">
      <h2 class="mb-0" @click.self="toggleShowBody()">
        <div class="btn-group-vertical">
          <button
            class="btn btn-sm btn-outline-secondary order-arrows"
            @click.stop="moveRoomUp(_room._uuid)"
            :disabled="_index == 0"
          >
            <i class="fas fa-arrow-up"></i>
          </button>
          <button
            class="btn btn-sm btn-outline-secondary order-arrows"
            @click.stop="moveRoomDown(_room._uuid)"
            :disabled="_index == report.rooms.length - 1"
          >
            <i class="fas fa-arrow-down"></i>
          </button>
        </div>
        <content-editable
          :content="_room.name"
          class="h3 ml-2 mr-2 d-inline-block"
          @click.prevent
          @update="
            setReportDeep({
              path: `rooms[${_index}].name`,
              data: $event,
            })
          "
        ></content-editable>
        <div class="h3 pt-1 ml-2 mr-2 d-inline-block float-right">
          <button
            @click="remove()"
            class="btn btn-sm btn-outline-danger mt-1 float-right"
          >
            <i class="fas fa-trash"></i>
          </button>
          <small class="h5 float-right text-muted mt-2 mr-2">
            {{ _room.type }}
          </small>
        </div>
      </h2>
    </div>
    <div class="card-body" v-if="_opened">
      <div v-for="(section, $index) in _room.sections" :key="section._uuid">
        <report-room-section
          v-if="section.types.length"
          :section="section"
          :index="$index"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, inject, provide, defineProps, defineEmits, getCurrentInstance } from 'vue';
import store from '@/store';
import { useToast } from "vue-toastification";
import { Report, Room } from "@/models";
import { ReportsState } from '@/store/reports/types';
import { emitEvent } from '@/eventBus';
import ContentEditable from '../ContentEditable.vue';
import ReportRoomSection from './Section.vue';

// Props with type annotation
const props = defineProps<{
  index: number;
  room: Room;
  toggle: boolean;
  opened: boolean;
}>();

provide('roomIndex', props.index);

const instance = getCurrentInstance();

// Using `inject` to replace `actProperty`
const actProperty: any = inject('actProperty');

// State and actions from Vuex store
const reportsState = computed(() => store.state.reports as ReportsState);

const emit = defineEmits(['showBody'])

// Converted methods to use composition API
const removeFromRooms = async (payload: { roomIndex: number }) => {
  await store.dispatch('reports/removeFromRooms', payload);
};

const setReportDeep = async (payload: { path: string; data: any }) => {
  await store.dispatch('reports/setReportDeep', payload);
};

const moveRoomDownAction = async (uuid: string) => {
  await store.dispatch('reports/moveRoomDown', uuid);
};

const moveRoomUpAction = async (uuid: string) => {
  await store.dispatch('reports/moveRoomUp', uuid);
};

// Mutation from Vuex store
const setCurrentRoom = (room: Room) => {
  store.commit('reports/setCurrentRoom', room);
};

// Reactive references for local state
const showBody = ref<boolean>(true);

// Computed getters based on props
const _index = computed(() => props.index);
const _room = computed(() => props.room);
const _toggle = computed(() => props.toggle);
const _opened = computed(() => props.opened);
const report = computed(() => reportsState.value.current);

// Methods converted to composition API
const toggleShowBody = () => {
  setCurrentRoom(_room.value);
  if (_toggle.value) {
    showBody.value = !_opened.value;
    emit("showBody", _index.value, showBody.value);
  }
};

const remove = async () => {
  actProperty
    .confirmPrompt()
    .then(() =>
      removeFromRooms({
        roomIndex: _index.value,
      })
    )
    .then(() => instance?.proxy?.$forceUpdate());
};

const moveRoomDown = async (uuid: string) => {
  moveRoomDownAction(uuid).then(() => emitEvent("collapseAll"));
};

const moveRoomUp = async (uuid: string) => {
  moveRoomUpAction(uuid).then(() => emitEvent("collapseAll"));
};

// Watchers or other composition functions (if needed) can be defined here
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/bootstrap/_variables.scss";

.card {
  margin-bottom: 1rem;
  // &:last-child {
  //   margin-bottom: 0;
  // }
}

.card-header {
  a {
    display: inline-block;
    margin-left: 1rem;
  }
  small {
    font-size: 56%;
  }
}

.order-arrows {
  padding: 0.1rem 0.35rem !important;
}

.btn {
  padding-bottom: 0.2rem;
  &.disabled {
    opacity: 0.35;
  }
}
</style>
