/**
 * Getters
 *
 * Vuex allows us to define "getters" in the store.
 * - You can think of them as computed properties for stores.
 * - Like computed properties, a getter's result is cached based on its dependencies,
 *   and will only re-evaluate when some of its dependencies have changed.
 */
import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { AppState } from './types';

export const getters: GetterTree<AppState, RootState> = {

  /**
   * Has Blocking Requests?
   * 
   * @param {AppState} boolean
   * @returns blocking request 
   */
  hasBlockingRequests: (state: AppState): boolean => {
    return state.requests.filter(x => x.blocking).length > 0;
  },
};
