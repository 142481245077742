<template>
  <div
    :id="refname"
    class="modal"
    data-backdrop="false"
    data-keyboard="true"
    tabindex="-1"
    role="dialog"
    aria-labelledby="`postcode-search-modal-label`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content postcodesearch-modal">
        <div class="modal-header postcode-modal-header">
          <div class="modal-title" id="postcode-report-saarch-modal-label" style="width: 100%">
            <div class="row">
              <div class="col-md-8 mt-auto mb-auto">
                <h1 class="mt-auto mb-auto">Creating a new booking</h1>
              </div>
              <div class="col-md-4">
                <input
                  type="text"
                  ref="addressfilterref"
                  class="form-control height-43"
                  v-model="addressfilter"
                  autocomplete="addressfilter"
                />
              </div>
            </div>
            
          </div>

          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="hide"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="row" :class="{'greyed': highlight != 'first'}">
            <div class="col-md-12">
              <div class="card bg-light postcode-search-booking-section">
                <h3 class="card-header postcode-search-section-header">
                  First Choice - There is an existing booking for this property within the new diary system
                </h3>
                <div class="card-body postcode-property-container">
                  <table class="table table-hover">
                    <thead>
                      <th width="10%">Date</th>
                      <th width="15%">Time</th>
                      <th width="10%">Job Type</th>
                      <th width="20%">Address</th>
                      <th width="12%">Customer</th>
                      <th width="12%">Branch</th>
                      <th width="12%">Inspector</th>
                      <th width="9%"></th>
                    </thead>
                    <tbody>
                      <tr
                        v-for="b in filteredbookings"
                        :key="b.id"
                        :class="{'cancelled-booking': b.cancelled}"
                        @click="googleeventpopup(b)"
                      >
                        <td>
                          {{ b.bookingdate }}
                        </td>
                        <td>
                          {{ b.starttime }} to {{ b.endtime }}
                        </td>
                        <td>
                          <div v-if="!b.googleid">
                            <span v-if="b.subtype">{{ b.subtype }}</span>
                              {{ customLabelForInternaljobtype(b.internaljobtype) }}
                          </div>
                          <div v-else>
                            <i class="fab fa-google"></i>
                          </div>
                        </td>
                        <td>
                          <span v-if="!b.googleid">
                            {{ b.addressPreview }}
                          </span>
                          <span v-else>
                            <div>{{ b.location }}</div>
                            <div>{{ b.summary }}</div>
                          </span>
                        </td>
                        <td>{{ b.customer.companyName }}</td>
                        <td>{{ b.customer.branchName }}</td>
                        <td>{{ b.inspector.name }}</td>
                        <td>
                          <button
                            v-if="!b.cancelled && b.isMasterBooking()"
                            class="btn btn-light ml-1"
                            title="Select"
                            @click.stop="selectbooking(b)"
                          >
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="row" :class="{'greyed': highlight != 'second'}">
            <div class="col-md-12">
              <div class="card bg-light postcode-search-section mt-2">
                <h3 class="card-header postcode-search-section-header">
                  Second Choice - No booking has been compiled within the new diary system but historic report available on CMS
                </h3>
                <div class="card-body postcode-property-container">
                  <table class="table table-hover">
                    <thead>
                      <th width="20%">Date</th>
                      <th width="10%">Ref</th>
                      <th width="20%">Type</th>
                      <th width="40%">Address</th>
                      <th width="10%">Select</th>
                    </thead>
                    <tbody>
                      <tr v-for="report in filteredreports" :key="report.id">
                        <td>
                          {{ actProperty.formatDateForDisplay(report.date) }}
                        </td>
                        <td>{{ report.ref }}</td>
                        <td>
                          {{
                            actProperty.formatReportType(
                              report.type,
                              dictionary
                            )
                          }}
                        </td>
                        <td
                          v-html="
                            actProperty.formatAddress(report.address, ', ')
                          "
                        ></td>
                        <td>
                          <button
                            class="btn btn-light ml-1"
                            title="Select"
                            @click="selectreport(report)"
                          >
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="row" :class="{'greyed': highlight != 'third'}">
            <div class="col-md-12">
              <div class="card bg-light postcode-search-section mt-2">
                <h3 class="card-header postcode-search-section-header">
                  New to ACT - No booking or report on our new system - General Postcode Search
                </h3>
                <div class="card-body postcode-property-container">
                  <table class="table table-hover">
                    <thead>
                      <th width="80%">Address</th>
                      <th width="20%">Select</th>
                    </thead>
                    <tbody>
                      <tr
                        v-for="report in filteredpostcodesearchreports"
                        :key="report.id"
                        @click="filterreport = report"
                        :class="{
                          selected:
                            report.address.line1 === filterreport.address.line1,
                        }"
                      >
                        <td
                          v-html="
                            actProperty.formatAddress(report.address, ', ')
                          "
                        ></td>
                        <td>
                          <button
                            class="btn btn-light ml-1"
                            title="Select"
                            @click="selectreport(report)"
                          >
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary mr-auto"
            data-dismiss="modal"
            @click="hide"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import moment from "moment-timezone";
import { computed, inject, ref, nextTick, defineEmits, defineProps, PropType, defineExpose } from 'vue';
import { useStore } from 'vuex';
import { Report, Dictionary, PropertySpec, Booking } from "@/models";

const props = defineProps({
  title: {type: String as PropType<string>, default: '', required: false},
  refname: {type: String as PropType<string>, default: '', required: false},
});

const store = useStore();
const actProperty: any = inject('actProperty');
const realtime: any = inject('realtime');

const filterreport = ref(new Report);
const addressfilter = ref('');
const addressfilterref = ref(null);
const reports = computed(() => store.getters['reports/list']);
const postcodesearchreports = computed(() => store.getters['reports/postcodesearchlist']);
const postcodesearchbookings = computed(() => store.getters['diary/postcodesearchlist']);
const dictionary = computed(() => store.getters['dictionary/current']);
const propertyspec = computed(() => store.getters['diary/propertyspec']);
const emit = defineEmits(['previousbookingselected', 'showmatchingproperywarning','googlebookingselected']);
const booking = computed(() => store.getters['diary/booking']);

const setReports = (reports: Report[]): void => {
  store.commit('reports/setReports', reports);
};

const setPostcodeReports = (reports: Report[]): void => {
  store.commit('reports/setPostcodeReports', reports);
};

const setPostcodeBookings = (bookings: Booking[]): void => {
  store.commit('diary/setPostcodeBookings', bookings);
};

const setPreviousreport = (previousreport: Report): void => {
  store.commit('diary/setPreviousreport', previousreport);
};

const setDataentryreport = (dataentryreport: Report): void => {
  store.commit('diary/setDataentryreport', dataentryreport);
};

const setPreviousbooking = (booking: Booking): void => {
  store.commit('diary/setPreviousbooking', booking);
};

const setBookingDeep = (payload: { path: string; data: any }): void => {
  store.commit('diary/setBookingDeep', payload);
};

const setAddressDeep = (payload: { path: string; data: any }): Promise<any> => {
  return store.dispatch('diary/setAddressDeep', payload);
};
const internaljobtypedisplayname = computed((): Map<string, string> => store.getters['diary/internaljobtypedisplayname']);
const setPropertyspecDeep = (payload: {
  path: string;
  data: any;
}): Promise<any> => {
  return store.dispatch('diary/setPropertyspecDeep', payload);
};

const getReport = (id: string): Promise<Report> => {
  return store.dispatch('reports/getReport', id);
};

const getBooking = (payload: { id: string; cancelled: string }): Promise<Booking> => {
  return store.dispatch('diary/getBookingWithoutStoringInState', payload);
};

const init = () => {
  filterreport.value = new Report;
  addressfilter.value = '';
  setReports([]);
  setPostcodeReports([]);
  setPostcodeBookings([]);

  nextTick(() => {
    (addressfilterref.value as any).focus();
  })
}

const selectreport = async (report: Report) => {
  if (report.id) {
    const fetchedReport = await getReport(report.id);
    if (fetchedReport) {
      setDataentryreport(new Report());
      setBookingDeep({ path: "dataentrydocuments", data: [] });
      setPreviousreport(fetchedReport);
      setBookingFieldsFromReport(fetchedReport);
      hide();
    }
  } else {
    setPreviousreport(new Report());
    setBookingFieldsFromReport(report);
    hide();
    const foundmatchingproperties = checkPropertyOnSystem(report);
    if(foundmatchingproperties) {
      emit('showmatchingproperywarning')
    }
  }
};

const checkPropertyOnSystem = (report: Report) => {
  const foundreports = filteredreports.value.filter((dbreport: Report) => dbreport.address.isMatching(report.address));
  const foundbookings = filteredbookings.value.filter((dbbooking: Booking) => dbbooking.address.isMatching(report.address));
  return foundreports.length > 0 || foundbookings.length > 0;
}

const selectbooking = async (booking: Booking) => {
  if (booking.id) {
    const fetchedBooking = await getBooking({ id: booking.id, cancelled: '' });
    if (fetchedBooking) {
      setPreviousbooking(fetchedBooking);
      emit('previousbookingselected', fetchedBooking);
      hide();
    }
  } else {
    setPreviousbooking(new Booking());
    hide();
  }
};

const setBookingFieldsFromReport = (report: Report) => {
  if (report) {
    setAddressDeep({ path: "line1", data: report.address.line1 });
    setAddressDeep({ path: "line2", data: report.address.line2 });
    setAddressDeep({ path: "town", data: report.address.town });
    setAddressDeep({ path: "county", data: report.address.county });

    setPropertyspecDeep({
      path: "bedrooms",
      data: findRoomtypeCount(report, ["bedroom"]),
    });
    setPropertyspecDeep({
      path: "bathrooms",
      data: findRoomtypeCount(report, ["bathroom", "ensuite"]),
    });
    setPropertyspecDeep({
      path: "receptions",
      data: findRoomtypeCount(report, ["reception"]),
    });
    setPropertyspecDeep({
      path: "furnished",
      data: report.propertyDetails.furnished,
    });

    let totalrooms = 0;
    if (propertyspec.value) {
      if (propertyspec.value.bedrooms)
        totalrooms += propertyspec.value.bedrooms;
      if (propertyspec.value.bathrooms)
        totalrooms += propertyspec.value.bathrooms;
      if (propertyspec.value.receptions)
        totalrooms += propertyspec.value.receptions;
    }
  }
};

const findRoomtypeCount = (report: Report, roomtypes: string[]): number => {
  let count = 0;
  if (report && report.rooms && report.rooms.length) {
    count = report.rooms.filter((r) => {
      let index = roomtypes.findIndex((type: string) => type === r.type);
      return index >= 0;
    }).length;
  }
  return count;
};

const filteredpostcodesearchreports = computed(() => {
  if (addressfilter.value) {
    const lowercaseaddressfilter = addressfilter.value.toLowerCase();
    return postcodesearchreports.value.filter((r: Report) => {
      if (r.address.line1.toLowerCase().includes(lowercaseaddressfilter)) return true;
      if (r.address.line2.toLowerCase().includes(lowercaseaddressfilter)) return true;
      if (r.address.town.toLowerCase().includes(lowercaseaddressfilter)) return true;
      if (r.address.county.toLowerCase().includes(lowercaseaddressfilter)) return true;
      return false;
    });
  } else {
    return postcodesearchreports.value;
  }
});

const filteredreports = computed(() => {
  let frlist: Report[] = sortedreportlist.value.filter((r: Report) => {
      if (booking.value?.id && booking.value?.targetreport?.id && booking.value?.targetreport?.ref && booking.value.targetreport.ref === r.ref) return false;
      return true;
    });
  let filteredlist: Report[] = [];
  if (addressfilter.value) {
    const lowercaseaddressfilter = addressfilter.value.toLowerCase();
    filteredlist = frlist.filter((r: Report) => {
      if (r.address.line1.toLowerCase().includes(lowercaseaddressfilter)) return true;
      if (r.address.line2.toLowerCase().includes(lowercaseaddressfilter)) return true;
      if (r.address.town.toLowerCase().includes(lowercaseaddressfilter)) return true;
      if (r.address.county.toLowerCase().includes(lowercaseaddressfilter)) return true;
      return false;
    });
  } else {
    filteredlist = frlist;
  }
  return filteredlist.filter((r: Report) => !r.address?.line1?.includes('PROFORMA'));
});

const filteredbookings = computed(() => {
  const fblist = sortedpostcodesearchbookings.value.filter((b: Booking) => {
      if (booking.value?.id && b.id === booking.value?.id) return false;
      return true;
    });
  if (addressfilter.value) {
    const lowercaseaddressfilter = addressfilter.value.toLowerCase();
    return fblist.filter((b: Booking) => {
      if (b.address.line1.toLowerCase().includes(lowercaseaddressfilter)) return true;
      if (b.address.line2.toLowerCase().includes(lowercaseaddressfilter)) return true;
      if (b.address.town.toLowerCase().includes(lowercaseaddressfilter)) return true;
      if (b.address.county.toLowerCase().includes(lowercaseaddressfilter)) return true;
      return false;
    });
  } else {
    return fblist;
  }
});

const sortedreportlist = computed(() => {
  let sortedlist = [...reports.value];
  if (
    filterreport.value &&
    filterreport.value.address &&
    filterreport.value.address.line1
  ) {
    sortedlist = sortedlist.sort((a: Report, b: Report) => {
      let aline1 = a.address?.line1 ?? '';
      aline1 = aline1.replaceAll("PROFORMA INVOICE ", "")
                     .replaceAll("INVOICE ONLY ", "")
                     .replaceAll("UPLOAD ", "");

      let bline1 = b.address?.line1 ?? '';
      bline1 = bline1.replaceAll("PROFORMA INVOICE ", "")
                     .replaceAll("INVOICE ONLY ", "")
                     .replaceAll("UPLOAD ", "");
      
      if (moment.utc(a.date).isAfter(moment.utc(b.date))) {
        return 1;
      } else if (
        moment.utc(a.date).isBefore(moment.utc(b.date))
      ) {
        return -1;
      } else {
        if (aline1 && aline1.startsWith(filterreport.value.address.line1)) return -1;
        if (bline1 && bline1.startsWith(filterreport.value.address.line1)) return 1;
        return 0;
      }
    });
  }
  return sortedlist;
});

const sortedpostcodesearchbookings = computed(() => {
  let sortedlist = [...postcodesearchbookings.value];
  if (
    filterreport.value &&
    filterreport.value.address &&
    filterreport.value.address.line1
  ) {
    sortedlist = sortedlist.sort((a: Booking, b: Booking) => {
      let aline1 = a.address?.line1 ?? '';
      aline1 = aline1.replaceAll("PROFORMA INVOICE ", "")
                     .replaceAll("INVOICE ONLY ", "")
                     .replaceAll("UPLOAD ", "");

      let bline1 = b.address?.line1 ?? '';
      bline1 = bline1.replaceAll("PROFORMA INVOICE ", "")
                     .replaceAll("INVOICE ONLY ", "")
                     .replaceAll("UPLOAD ", "");

      if (moment.utc(a.startDate).isAfter(moment.utc(b.startDate))) {
        return 1;
      } else if (
        moment.utc(a.startDate).isBefore(moment.utc(b.startDate))
      ) {
        return -1;
      } else {
        if (aline1 && aline1.startsWith(filterreport.value.address.line1)) return -1;
        if (bline1 && bline1.startsWith(filterreport.value.address.line1)) return 1;
        return 0;
      }
    });
  }
  return sortedlist;
});

const highlight = computed(() => {
  let val = '';
  const firsttablecount = filteredbookings.value.filter((b: Booking) => !b.googleid).length;
  const secondtablecount = filteredreports.value.length;
  if(firsttablecount > 0) {
    val = 'first';
  }
  else if(secondtablecount > 0) {
    val = 'second';
  }
  else {
    val = 'third';
  }
  return val;
})

const show = () => {
  if ($(`#${props.refname}` + 'Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.refname + 'Backdrop"></div>');
    $('body').append(backdropDiv);
    let modalEl = $(`#${props.refname}`);
    modalEl.show();
  }
}
const hide = () => {
  if ($(`#${props.refname}`).length > 0) {
    $(`#${props.refname}` + 'Backdrop').remove();
    $(`#${props.refname}`).hide();
  }
};
const customLabelForInternaljobtype = (internaljobtype: string): string => {
  return actProperty.customLabelForInternaljobtype(internaljobtypedisplayname.value,internaljobtype )
}
defineExpose({init, show, hide});

const googleeventpopup = (b:Booking) =>{
    if (b.googleid) {
      emit('googlebookingselected', b);
    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.postcodesearch-modal {
  border-color: #eadb40;
  border-width: 2px;
  border-radius: 10px;
}
.scrollable {
  max-height: 60vh;
  overflow-y: auto;
}
.rightborder {
  border-right: 2px solid #dee2e6;
}

.selected {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.postcode-search-section {
  border-color: #566b7d;
  max-height: 20vh;
  min-height: 20vh;
}
.postcode-search-booking-section {
  border-color: #566b7d;
  max-height: 25vh;
  min-height: 25vh;
}
.postcode-search-section-header {
  color: #eadb40;
  background-color: #566b7d;
  font-size: 1rem;
}

.postcode-bookings-container {
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: rgb(240, 240, 240);
  margin: 10px;
}

.postcode-property-container {
  overflow-y: scroll;
  overflow-x: hidden;
}

.postcode-modal-header {
  color: #eadb40;
  background-color: #566b7d !important;
}

thead {
  position: sticky; /* make the header stick to the top of the container */
  top: 0;
  background-color: white; /* set a background color to cover the container's scrollbar */
}

th {
  background-color: white; /* set a background color to cover the container's scrollbar */
  position: sticky; /* make the header cell sticky */
  top: 0;
  z-index: 1; /* set a higher z-index than the container to avoid overlapping */
}

.cancelled-booking {
  background: repeating-linear-gradient(
    135deg,
    rgb(136, 8, 8, 0.2),
    rgba(136, 8, 8, 0.2) 5px,
    rgb(255, 255, 255) 5px,
    rgb(255, 255, 255) 10px
  );
  color: #212529;
}
.cancelled-bookingx:hover {
  background: repeating-linear-gradient(
    135deg,
    rgb(217, 218, 219),
    rgb(217, 218, 219) 5px,
    rgb(255, 255, 255) 5px,
    rgb(255, 255, 255) 10px
  );
  color: #212529;
}

.greyed {
  .card {
    border-color: lightgrey;
  }
  .card-header {
    background-color: lightgrey;
    color: #ffffff;
  }

  .table {
    thead {
      color: lightgray;
    }
    tbody {
      tr {
        color: rgb(73, 73, 73);
      }
      .btn {
        color: rgb(140, 140, 140);
      }
    }
  }
}
</style>