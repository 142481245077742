<template>
  <div class="modal" data-keyboard="true" tabindex="-1" role="dialog" :id="name"
    aria-labelledby="`cancel-booking-modal-label`" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="cancel-booking-modal-label">Delete {{ title }}</h5>
          <button id="close" type="button" class="close" data-dismiss="modal" aria-label="Close"
            @click.prevent="hide(false)">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <label class="col-form-label">Why are you deleting this booking?</label>
          <div>
            <textarea class="form-control" rows="3" v-model="cancelnotes"></textarea>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" @click.prevent="hide(false)">
            Back
          </button>
          <button type="button" class="btn btn-primary btn-danger" data-dismiss="modal" @click.prevent="cancel()"
            :disabled="isCancelling || !cancelAllowed">
            Delete Job
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import { ref, computed, inject, defineEmits, defineExpose, defineProps } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment-timezone';
import { Booking, Dictionary } from '@/models';
import { useToast } from "vue-toastification";
const props = defineProps({
  name: {
    type: String,
    default: ''
  }
});
const store = useStore();
const dictionary = computed((): Dictionary => store.getters['dictionary/current']);
const realtime: any = inject('realtime');
const actProperty: any = inject('actProperty');
const channel = realtime.channels.get('diary');
// Reactive state for the component
const booking = ref(new Booking());
const title = ref("");
const cancelnotes = ref('');
const emit = defineEmits(['hide']);
const toasted = useToast();

// Initialize your booking
const init = (bookingData: Booking) => {
  booking.value = bookingData;
  cancelnotes.value = moment().format("DD/MM/YYYY");
  title.value = actProperty.getBookingTitle(booking.value, dictionary.value);
};

const getBooking = (payload: { id: string, cancelled: string }): Promise<any> =>
  store.dispatch('diary/getBookingWithoutStoringInState', payload);
const cancelBookingInStore = (booking: Booking): Promise<any> =>
  store.dispatch('diary/cancelBooking', booking);
const setBookingDeep = async (payload: {
  path: string;
  data: any;
}) => {
  await store.dispatch('diary/setBookingDeep', payload);
}

const cancelAllowed = computed(() => {
  return cancelnotes.value.length > 0;
});

const isCancelling = ref(false);
const bookingstocancel = ref(new Map<string, Booking>());
const cancel = async () => {
  isCancelling.value = true;
  booking.value.cancelnotes = cancelnotes.value;
  cancelBookingInStore(booking.value)
    .then(() => {
      channel.publish('appointmentCancelled', booking.value.id);
      isCancelling.value = false;
      hide(true);
      toasted.success("Booking deleted");
    })
    .then(() => {
      // Clear connectedbookingflag
      setBookingDeep({ path: 'connectedbooking', data: '' });
    })
    .then(async () => {
      // Cancel all subbookings
      if (booking.value.subbookings.length) {
        bookingstocancel.value = new Map();
        await getsubbookings(booking.value);
        for (let [id, b] of bookingstocancel.value) {
          cancelBookingInStore(b);
        }
      }
    })
    .catch((err: any) => {
      isCancelling.value = false;
      hide(false);
      actProperty.displayError(err);
    });
}

const getsubbookings = async (booking: Booking) => {
  bookingstocancel.value.set(booking.id, booking);
  if (booking.subbookings.length) {
    for (let i = 0; i < booking.subbookings.length; i++) {
      let sb: Booking = booking.subbookings[i];
      if (sb.id) {
        await getsubbookings(sb);
      }
      else {
        let b: Booking = await getBooking({ id: booking.id, cancelled: 'false' });
        await getsubbookings(b);
      }
    }
  }
}
const show = () => {
  if ($(`#${props.name}` + 'Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.name + 'Backdrop"></div>');
    $('body').append(backdropDiv);
    $(`#${props.name}`).show();
  }
}
const hide = (val: boolean) => {
  if ($(`#${props.name}`).length > 0) {
    $(`#${props.name}` + 'Backdrop').remove();
    $(`#${props.name}`).hide();
    emit('hide', val, booking.value);
  }
};

defineExpose({ init, show, hide });
</script>
