import _castArray from 'lodash/castArray';
import _get from 'lodash/get';

import { toCamelCase, omitDeep } from '@/utilities';

import {
  Base,
} from '@/models';

export class Auditlogvaluechange extends Base {
  public fieldname: string;
  public oldvalue: string;
  public newvalue: string;

  public constructor(data?: Partial<Auditlogvaluechange>) {
    super(data);
    data = toCamelCase(data);

    this.fieldname = _get(data, 'fieldname', '');
    this.oldvalue = _get(data, 'oldvalue', '');
    this.newvalue = _get(data, 'newvalue', '');
  }

  public toObject() {
    return omitDeep({ ...this }, ['_uuid']);
  }
}