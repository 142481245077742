import _castArray from 'lodash/castArray';
import _get from 'lodash/get';
import { toCamelCase, copyInto } from '@/utilities';
import { Base, Customer } from '@/models';

export class SMSTemplate extends Base {

  public id: string;
  public title: string;
  public reporttypes: string[];
  public to: string[];
  public body: string;
  public target: string[];
  public chronorder: string[];
  public daysbefore: number;
  public clients: string[];
  public managedtype: string;
  public defaulttemplate: boolean;

  /**
   * Constructor
   */
  public constructor(data?: Partial<SMSTemplate>) {
    super(data);
    data = toCamelCase(data);

    this.id = _get(data, 'id', '');
    this.title = _get(data, 'title', '');
    this.reporttypes = _get(data, 'reporttypes', []);
    this.body = _get(data, 'body', '');
    this.target = _get(data, 'target', ['Tenant']);
    this.chronorder = _get(data, 'chronorder', []);
    this.daysbefore = _get(data, 'daysbefore', 0);
    this.clients = _get(data, 'clients', []);
    this.managedtype = _get(data, 'managedtype', '');
    this.defaulttemplate = _get(data, 'defaulttemplate', true);
  }

  /**
   * Create an object from a JSON representation
   * @param data
   * @return EmailTemplate
   */
  public static parse(data?: Partial<SMSTemplate>) {
    const obj = new SMSTemplate;
    copyInto(obj, data);

    data = toCamelCase(data);
    obj.id = _get(data, 'id', '');
    obj.title = _get(data, 'title', '');
    obj.reporttypes = _get(data, 'reporttypes', []);
    obj.body = _get(data, 'body', '');
    obj.target = _get(data, 'target', []);
    obj.chronorder = _get(data, 'chronorder', []);
    obj.daysbefore = _get(data, 'daysbefore', 0);
    obj.clients = _get(data, 'clients', []);
    obj.managedtype = _get(data, 'managedtype', '');
    obj.defaulttemplate = _get(data, 'defaulttemplate', true);

    return obj;
  }

}
