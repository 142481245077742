import _get from 'lodash/get';
import _castArray from 'lodash/castArray';
import { copyInto } from '@/utilities';

import { Base, SMSLog } from '@/models';
import { toCamelCase, datetimeToUTC } from '@/utilities';

export class SMSLogs extends Base {
  public tenantconfirmationsentdate: string;
  public tenantconfirmationreceiveddate: string;
  public tenantconfirmationresponse: string;
  public logs: SMSLog[];

  public static parse(data: object) {
    const obj = new SMSLogs;
    copyInto(obj, data);

    obj.tenantconfirmationsentdate = _get(data, 'tenantconfirmationsentdate', '');
    obj.tenantconfirmationreceiveddate = _get(data, 'tenantconfirmationreceiveddate', '');
    obj.tenantconfirmationresponse = _get(data, 'tenantconfirmationresponse', '');
    obj.logs = _castArray(_get(data, 'logs', [])).map((x: Partial<SMSLog>) => new SMSLog(x));
    return obj;
  }

  /**
   * Constructor
   */
  public constructor(data?: Partial<SMSLogs>) {
    super(data);
    data = toCamelCase(data);

    this.tenantconfirmationsentdate = _get(data, 'tenantconfirmationsentdate', '');
    this.tenantconfirmationreceiveddate = _get(data, 'tenantconfirmationreceiveddate', '');
    this.tenantconfirmationresponse = _get(data, 'tenantconfirmationresponse', '');
    this.logs = _castArray(_get(data, 'logs', [])).map((x: Partial<SMSLog>) => new SMSLog(x));
  }
}