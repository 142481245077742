import _get from 'lodash/get';

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

export class Meter extends Base {

  public name: string;
  public accessible: boolean;
  public reason: string;
  public supplier: string;
  public reference: string;
  public location: string;
  public readings: string[];
  public photos: string[];

  /**
   * Constructor initialises the variables from the class Meter
   * @param data - the data to construct a type with all properties of <Meter>
   */
  public constructor(data?: Partial<Meter>) {
    super(data);
    data = toCamelCase(data);

    this.name = _get(data, 'name', '');
    this.accessible = _get(data, 'accessible', true);
    this.reason = _get(data, 'reason', '');
    this.supplier = _get(data, 'supplier', '');
    this.reference = _get(data, 'reference', '');
    this.location = _get(data, 'location', '');
    this.readings = _get(data, 'readings', ['']);
    this.photos = _get(data, 'photos', []);
  }

}
