<template>
  <div class="modal alert-dialog" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" :id="name"
    aria-labelledby="`alert-modal-label`" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="alert-modal-label" :class="titlestyle">
            {{ title }}
          </h3>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-md-12" :class="messagestyle">
              <h5>{{ message }}</h5>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button v-if="button2text" type="button" class="btn btn-outline-primary mr-auto" data-dismiss="modal"
            :class="buttonstyle" @click="button2Clicked">
            {{ button2text }}
          </button>
          <button type="button" class="btn btn-primary ml-auto" data-dismiss="modal" :class="buttonstyle"
            @click="button1Clicked">
            {{ button1text }}
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import { head } from 'lodash';
import { ref, watch, inject, defineProps, defineEmits, defineExpose } from 'vue';

// Define state with ref() and specify initial values
const title = ref('title');
const titlestyle = ref('');
const message = ref('message');
const messagestyle = ref('');
const button1text = ref('OK');
const button2text = ref('Cancel');
const buttonstyle = ref('');
const id = ref();
const props = defineProps<{
  name: string
}>();

// Define emits
const emit = defineEmits(['alertDialogClosed']);

// Initialize component with props
const init = (t: string, m: string, bt1: string, bt2: string, idValue: string) => {
  title.value = t;
  message.value = m;
  button1text.value = bt1;
  button2text.value = bt2;
  id.value = idValue;
};
// Methods
const button1Clicked = () => {
  emit("alertDialogClosed", id.value, button1text.value);
  hide();
};
const button2Clicked = () => {
  emit("alertDialogClosed", id.value, button2text.value);
  hide();
};

const show = () => {
  if ($(`#${props.name}` + 'Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.name + 'Backdrop"></div>');
    $('body').append(backdropDiv);
    $(`#${props.name}`).show();
  }
}
const hide = () => {
  emit("alertDialogClosed", '', '');
  if ($(`#${props.name}`).length > 0) {
    $(`#${props.name}` + 'Backdrop').remove();
    $(`#${props.name}`).hide();
  }
};
defineExpose({ init, show, hide });
</script>


<style scoped lang="scss">
.alert-dialog {
  z-index: 1051;
}
</style>