<template>
  <!-- "Dictionary Items Modal -->
  <div class="modal fade" :id="`dictionary-items-modal`" data-keyboard="true" tabindex="-1" role="dialog" :aria-labelledby="`dictionary-items-modal-title`" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="`dictionary-items-modal-title`">
            Dictionary Items
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <table class="table w-100">
          <thead class="thead-dark">
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th width="60"></th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-primary">
              <td><input class="form-control" v-model="newItem.slug" placeholder="Auto-generated" readonly autocomplete="off" /></td>
              <td><input class="form-control" @keyup.enter="add" v-model="newItem.name" placeholder="Required" autocomplete="off" /></td>
              <td><button class="btn btn-primary" @click="add" :disabled="!newItem.name.length"><i class="fas fa-plus"></i></button></td>
            </tr>
          </tbody>
          <tbody class="fixed-height">
            <tr v-for="item in currentItemsSorted" :key="`${item.slug}`">
              <td><input class="form-control" :value="item.slug" readonly autocomplete="off" /></td>
              <td><input class="form-control" :value="item.name" @blur="updateName($event, item)" autocomplete="off" /></td>
              <td><button class="btn btn-outline-danger" @click="remove(item)"><i class="fas fa-trash"></i></button></td>
            </tr>
          </tbody>
        </table>
        <!--
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" data-dismiss="modal">
            OK
          </button>
        </div>
        -->
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { DictionaryItem } from '@/models';
import _ from 'lodash';
import { useToast } from "vue-toastification";
import { inject } from 'vue';

const store = useStore();
const actProperty: any = inject('actProperty');
const toasted = useToast();

// Reactive state
const newItem = ref(new DictionaryItem());

// Computed properties and getters from Vuex
const currentItemsSorted = computed(() => store.getters['dictionary/currentItemsSorted']);

const setActiveItem = (item: DictionaryItem): void => {
  store.commit('dictionary/setActiveItem', item);
};

const addItem = (item: DictionaryItem): Promise<any> => {
  return store.dispatch('dictionary/addItem', item);
};

const updateItem = (item: DictionaryItem): Promise<any> => {
  return store.dispatch('dictionary/updateItem', item);
};

const removeItem = (item: DictionaryItem): Promise<any> => {
  return store.dispatch('dictionary/removeItem', item);
};

// Methods converted to functions
const reset = () => {
  newItem.value = new DictionaryItem();
};

const add = () => {
  newItem.value.slug = _.snakeCase(newItem.value.name);
  addItem(newItem.value)
    .then(() => setActiveItem(newItem.value))
    .then(() => toasted.success(`Added "${newItem.value.name}"`))
    .then(() => reset())
    .catch((err: any) => actProperty.displayError(err));
};

const updateName = ($event: any, item: DictionaryItem) => {
  item.name = $event.target.value;
  updateItem(item)
    .catch((err: any) => actProperty.displayError(err));
};

const remove = (item: DictionaryItem) => {
  actProperty.confirmPrompt()
    .then(() => removeItem(item))
    .then(() => toasted.success(`Removed "${item.name}"`))
    .then(() => setActiveItem(new DictionaryItem()))
    .then(() => reset())
    .catch((err: any) => actProperty.displayError(err));
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
::placeholder {
  color: #ddd;
}
</style>
