import _get from 'lodash/get';
import _castArray from 'lodash/castArray';
import moment from "moment-timezone";

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

export class DepositoryTenancy extends Base {

  public uid: string;
  public moveOutDate: string;
  public addressLine1: string;
  public addressLine2: string;
  public city: string;
  public county: string;
  public postcode: string;
  public country: string;
  public propertyReference: string;
  public leadTenantFullName: string;

  public constructor(data?: Partial<DepositoryTenancy>) {
    super(data);
    data = toCamelCase(data);

    this.uid = _get(data, 'uid', '');
    this.moveOutDate = _get(data, 'moveOutDate', '');
    this.addressLine1 = _get(data, 'addressLine1', '');
    this.addressLine2 = _get(data, 'addressLine2', '');
    this.city = _get(data, 'city', '');
    this.county = _get(data, 'county', '');
    this.postcode = _get(data, 'postcode', '');
    this.country = _get(data, 'country', '');
    this.propertyReference = _get(data, 'propertyReference', '');
    this.leadTenantFullName = _get(data, 'leadTenantFullName', '');
  }

  get codate() {
    let result = "";
    if (this.moveOutDate)
      result = moment(this.moveOutDate).utc().format("DD/MM/YYYY");
    return result;
  }

  get displayaddress() {
    return `${this.addressLine1}${this.addressLine2.trim() ? ", " + this.addressLine2.trim() : ""}${this.city.trim() ? ", " + this.city.trim() : ""}${this.postcode.trim() ? ", " + this.postcode.trim() : ""}`;
  }

}
