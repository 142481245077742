import { snakeCase } from 'lodash';
import { copyInto } from '@/utilities';

export class CustomerAccountSettings {

  public llnamerequired: boolean;
  public llemailrequired: boolean;
  public generalemailforinvoice: string;
  public paymentinadvance: boolean; // Kept for backward compatibility
  public paymentinadvinvci: boolean;
  public paymentinadvcosoc: boolean;
  public invoiceinadvance: boolean;
  public pobinvoice: boolean;


  /**
   * Create an object from a JSON representation
   * @param data
   * @return CustomerAccountSettings
   */
  public static parse(data?: Partial<CustomerAccountSettings>) {
    const obj = new CustomerAccountSettings;
    copyInto(obj, data);
    return obj;
  }

  /**
   * Constructor
   */
  public constructor() {
    this.llnamerequired = false;
    this.llemailrequired = false;
    this.generalemailforinvoice = '';
    this.paymentinadvance = false;
    this.paymentinadvinvci = false;
    this.paymentinadvcosoc = false;
    this.invoiceinadvance = true;
    this.pobinvoice = false;
  }

}
