import _get from 'lodash/get';

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

export class Photo extends Base {

  public src: string;
  public createdAt: string;

  public rotate?: number;
  public hasGalleryLink: boolean;

  /**
   * Constructor initialises the variables from the class Photo
   * @param data - the data to construct a type with all properties of <Photo>
   */
  public constructor(data?: Partial<Photo>) {
    super(data);
    data = toCamelCase(data);

    this.src = _get(data, 'src', '');
    this.createdAt = _get(data, 'createdAt', '');

    if (_get(data, 'rotate')) {
      this.rotate = _get(data, 'rotate', 0);
    }

    this.hasGalleryLink = _get(data, 'hasGalleryLink', true);
  }
}
