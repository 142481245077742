import { ActionTree, ActionContext } from "vuex";
import { RootState } from "../types";
import { PropertyState } from "./types";
import { API } from "aws-amplify";

import { Property, Booking } from "@/models";
import { error } from "jquery";

/**
 * Actions
 *
 * Actions are similar to mutations, the differences being that:
 * - Instead of mutating the state, actions commit mutations.
 * - Actions can contain arbitrary asynchronous operations.
 * - Actions are triggered with the store.dispatch method e.g. `store.dispatch('getSettings')`
 */
export const actions: ActionTree<PropertyState, RootState> = {
  /**
   * Get one Property By Id
   *
   * @param {PropertyState} store
   * @param id
   * @returns Individual Property
   */
  async getPropertyById(store: ActionContext<PropertyState, any>, id) {
    // New Property
    if (id === "new") {
      const property = new Property();
      return property;
    }
    // Search by id
    store.commit("app/addRequest", "getPropertyById", { root: true });
    return API.get("RestAPI", `/properties/${id}`, null)
      .then((response) => new Property(response))
      .finally(() =>
        store.commit("app/removeRequest", "getPropertyById", { root: true })
      );
  },

  /**
   * Get all bookings for given property address
   *
   * @param {PropertyState} store
   * @param id
   * @returns Booking[]
   */
  async getPropertyBookings(
    store: ActionContext<PropertyState, any>,
    bookingid: string
  ) {
    store.commit("app/addRequest", "getPropertyBookings", { root: true });
    return API.get("RestAPI", `/propertybookings/${bookingid}`, null)
      .then((response) => response.map((x: Partial<Booking>) => new Booking(x)))
      .then((bookings: Booking[]) => {
        return bookings;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getPropertyBookings", { root: true })
      );
  },

  /**
   * Get one Property By Postcode
   *
   * @param {PropertyState} store
   * @param any valid text
   * @returns list of Property
   */
  async searchProperty(store: ActionContext<PropertyState, any>, postcode) {
    // Search by postcode
    store.commit("app/addRequest", "searchProperty", { root: true });
    const options = {
      queryStringParameters: {
        anytext: postcode,
      },
    };
    store.commit("app/addRequest", "searchGetaddressByAddress", { root: true });
    return await API.get("RestAPI", `/properties/search/`, options)
      .then((response) =>
        response.data.map((x: Partial<Property>) => new Property(x))
      )
      .finally(() =>
        store.commit("app/removeRequest", "searchProperty", { root: true })
      );
  },

  /**
   * Add a Property
   *
   * @param {PropertyState} store
   * @param property Property
   * @returns Property
   */
  async addProperty(
    store: ActionContext<PropertyState, any>,
    property: Property
  ) {
    store.commit("app/addRequest", "addProperty", { root: true });
    return API.post("RestAPI", `/properties`, { body: property.toJSON() })
      .then((response) => new Property(response))
      .finally(() =>
        store.commit("app/removeRequest", "addProperty", { root: true })
      );
  },

  /**
   * Update a Property
   *
   * @param {PropertyState} store
   * @param property Property
   * @returns Updated Property
   */
  async updateCustomer(
    store: ActionContext<PropertyState, any>,
    property: Property
  ) {
    store.commit("app/addRequest", "updateCustomer", { root: true });
    return API.put("RestAPI", `/properties/${property.id}`, {
      body: property.toJSON(),
    })
      .then((response) => new Property(response))
      .finally(() =>
        store.commit("app/removeRequest", "updateCustomer", { root: true })
      );
  },

  /**
   * Get previous booking
   *
   * @param {PropertyState} store
   * @param id
   * @returns previous Booking in chron order
   */
  async getPreviousBooking(
    store: ActionContext<PropertyState, any>,
    id
  ): Promise<Booking> {
    store.commit("app/addRequest", "getPreviousBooking", { root: true });
    return API.get("RestAPI", `/properties/previousbooking/${id}`, null)
      .then((response) => new Booking(response))
      .catch((error) => undefined)
      .finally(() =>
        store.commit("app/removeRequest", "getPreviousBooking", { root: true })
      );
  },

  /**
   * Get next booking
   *
   * @param {PropertyState} store
   * @param id
   * @returns next Booking in chron order
   */
  async getNextBooking(
    store: ActionContext<PropertyState, any>,
    id
  ): Promise<Booking> {
    store.commit("app/addRequest", "getNextBooking", { root: true });
    return API.get("RestAPI", `/properties/nextbooking/${id}`, null)
      .then((response) => new Booking(response))
      .catch((error) => undefined)
      .finally(() =>
        store.commit("app/removeRequest", "getNextBooking", { root: true })
      );
  },
};
