import { Module } from 'vuex';
import { RootState } from '../types';
import { CustomerDictionaryState } from './types';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

import {
  Compliancequestion,
  CustomerDictionary,
  DictionaryConditionMatrix,
  DictionaryItem,
  DictionaryRoom,
  DictionarySection,
  DictionaryType,
  QuestionWrapper,
  Maintenanceflag,
  DictionaryReportType,
  SystemProperty,
} from '@/models';

/**
 * Default state
 */
export const state: CustomerDictionaryState = {
  activeSection: new DictionarySection,
  activeType: new DictionaryType,
  activeItem: new DictionaryItem,
  activeRoom: new DictionaryRoom,
  activeRoomCategory: 0,
  activeReportType: new DictionaryReportType,
  current: new CustomerDictionary,
  currentsystemproperty: new SystemProperty,
  defaultdictionary: new CustomerDictionary,
  customerdictionary: new CustomerDictionary,
  clipboardColours: [],
  clipboardConditions: [],
  clipboardConditionMatrix: [
    new DictionaryConditionMatrix,
    new DictionaryConditionMatrix,
    new DictionaryConditionMatrix,
  ],
  clipboardConstituents: [],
  clipboardMakes: [],
  updates: [],
  compliance_questions: [],
  compliance_list: [],
  selected_question: new Compliancequestion,
  questionwrapper: new QuestionWrapper,
  maintenance_flags: [],
  selected_maintenanceflag: new Maintenanceflag,
  selected_room: new DictionaryRoom,
};

/**
 * Use namespaced Vuex modules
 */
const namespaced: boolean = true;

/**
 * Module export
 */
const customerdictionary: Module<CustomerDictionaryState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};

export default customerdictionary;
