<template>
  <div :id="id" class="modal" data-keyboard="true" tabindex="-1" role="dialog"
    aria-labelledby="`dictionary-room-modal-label`" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="dictionary-room-modal-label" v-if="operation == 'add'">
            New Room
          </h5>
          <h5 class="modal-title" id="add-room-item-modal-label" v-else>
            {{ selectedroom.name }}
          </h5>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <fieldset>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Name</label>
              <div class="col-sm-9">
                <input type="text" class="form-control" v-model="selectedroom.name" />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Category</label>
              <div class="col-sm-9">
                <singleselect-text v-model="category" :options="categoryOptions" @select="onSelectCategory">
                </singleselect-text>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Priority</label>
              <div class="col-sm-9">
                <singleselect-text v-model="priority" :options="priorityOptions" @select="onSelectPriority">
                </singleselect-text>
              </div>
            </div>
          </fieldset>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary mr-auto" data-dismiss="modal" @click.prevent="reset()">
            Back
          </button>
          <button type="submit" class="btn btn-primary" @click.prevent="add()" :disabled="!canAdd()"
            v-if="operation == 'add'">
            Add room
          </button>
          <button type="submit" class="btn btn-primary" @click.prevent="save()" :disabled="!canAdd()" v-else>
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import { snakeCase } from "lodash";
import { computed, inject, ref, defineProps, defineExpose } from 'vue';
import { useStore } from 'vuex';
import { DictionaryRoom } from "@/models";
import { useToast } from "vue-toastification";
const store = useStore();
const toasted = useToast();
const actProperty: any = inject('actProperty');
const rooms = computed(() => store.getters['customerdictionary/rooms'] as DictionaryRoom[]);
const selectedroom = computed(() => store.getters['customerdictionary/selectedroom'] as DictionaryRoom);
const categoryOptions = ref([1, 2, 3, 4]);
const priorityOptions = ref([1, 2, 3]);
// Define props
const props = defineProps<{
  id: string,
  operation: string;
}>();

const addToRooms = async (flag: unknown) => {
  await store.dispatch('customerdictionary/addToRooms', flag);
};

const removeFromRooms = async (flag: unknown) => {
  await store.dispatch('customerdictionary/removeFromRooms', flag);
};

const setCustomerdictionaryDeep = async (payload: { path: string; data: any; }) => {
  await store.dispatch('customerdictionary/setCustomerdictionaryDeep', payload);
};

const setSelectedMaintenanceflag = (room: DictionaryRoom) => {
  store.commit('customerdictionary/setSelectedRoom', room);
};

const category = computed({
  get: () => selectedroom.value.category,
  set: (value: number) => {
    if (value) selectedroom.value.category = value;
  }
});

const priority = computed({
  get: () => selectedroom.value.priority,
  set: (value: number) => {
    if (value) selectedroom.value.priority = value;
  }
});

const onSelectCategory = (value: number) => {
  selectedroom.value.category = value;
};

const onSelectPriority = (value: number) => {
  selectedroom.value.priority = value;
};

const canAdd = () => {
  return selectedroom.value.name ? true : false;
};

const add = () => {
  selectedroom.value.slug = snakeCase(selectedroom.value.name);
  addToRooms(selectedroom.value)
    .then(() => toasted.success(`Added "${selectedroom.value.name}"`))
    .then(() => reset())
    .catch((err: any) => {
      actProperty.displayError(err);
    });
  hide();
};

const save = () => {
  let index = rooms.value.findIndex((f) => f.slug == selectedroom.value.slug);
  if (index >= 0) {
    selectedroom.value.slug = snakeCase(selectedroom.value.name);
    setCustomerdictionaryDeep({
      path: `rooms[${index}]`,
      data: selectedroom.value,
    })
      .then(() => toasted.warning(`Locally saved "${selectedroom.value.name}. Please remember to press final Save"`))
      .then(() => reset())
      .catch((err: any) => {
        actProperty.displayError(err);
      });
  }

  hide();
};

const reset = () => {
  setSelectedMaintenanceflag(new DictionaryRoom());
  hide();
};

const show = () => {
  if ($(`#${props.id}` + 'Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.id + 'Backdrop"></div>');
    $('body').append(backdropDiv);
    $(`#${props.id}`).show();
  }
}
const hide = () => {
  if ($(`#${props.id}`).length > 0) {
    $(`#${props.id}` + 'Backdrop').remove();
    $(`#${props.id}`).hide();
  }
};
defineExpose({ add, show, hide });
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>

<style lang="scss">
.acteditor {
  min-height: 50px !important;
}
</style>
