import _ from 'lodash';
import { MutationTree } from 'vuex';
import { SysAdminState } from './types';
import { CognitoUser, CognitoGroup } from '@/models';
import { use } from 'chai';

/**
 * Mutations
 *
 * The only way to actually change state in a Vuex store is by committing a mutation.
 * - Vuex mutations are very similar to events: each mutation has a string type and a handler.
 * - The handler function is where we perform actual state modifications - it receives the state as the first argument:
 * - You cannot directly call a mutation handler.
 * - Think of it more like event registration: "When a mutation with type X is triggered, call this handler."
 * - To invoke a mutation handler, you need to call store.commit with its type
 * - e.g. `store.commit('setCustomers', customer)`
 */
export const mutations: MutationTree<SysAdminState> = {

    /**
     * Set the user currently being viewed/edited
     *
     * @param state SysAdminState
     * @param user CognitoUser
     * @returns void
     */
    setCurrentUser(state: SysAdminState, user: CognitoUser): void {
        state.current = user;
    },

    /**
     * Set the users list
     *
     * @param state SysAdminState
     * @param users CognitoUser
     * @returns void
     */
    setUsers(state: SysAdminState, users: CognitoUser[]): void {
        state.list = users;
    },

    /**
     * Set the groups
     *
     * @param state SysAdminState
     * @param groups CognitoGroup
     * @returns void
     */
    setGroups(state: SysAdminState, groups: CognitoGroup[]): void {
        state.groups = groups;
    },

    /**
     * Add user
     *
     * @param state SysAdminState
     * @param user CognitoUser
     * @returns void
     */
    addUser(state: SysAdminState, user: CognitoUser): void {
        state.list.push(user);
    },

    /**
     * Set usergroups for given user
     * @param state 
     * @param sub
     * @param payload {username, groups}
     */
    setUserGroups(state: SysAdminState, payload: { username: string, usergroups: CognitoGroup[] }): void {
        let user: CognitoUser | undefined = state.list.find((u) => u.Username === payload.username);
        if (user) {
            user.CognitoUserGroups = payload.usergroups
        }
    },

    /**
     * Add usergroup for given user
     * @param state 
     * @param sub
     * @param payload {username, groupname}
     */
    addUserGroup(state: SysAdminState, payload: { username: string, groupname: string }): void {
        let user: CognitoUser | undefined = state.list.find((u) => u.Username === payload.username);
        let group: CognitoGroup | undefined = state.groups.find((g) => g.GroupName === payload.groupname);
        if (user && group) {
            let index = user.CognitoUserGroups.findIndex(ug => ug.GroupName === payload.groupname);
            // Only add if not already present
            if (index < 0) {
                user.CognitoUserGroups.push(group);
            }
        }
    },

    /**
     * Remove usergroup for given user
     * @param state 
     * @param sub
     * @param payload {username, groupname}
     */
    removeUserGroup(state: SysAdminState, payload: { username: string, groupname: string }): void {
        let user: CognitoUser | undefined = state.list.find((u) => u.Username === payload.username);
        let group: CognitoGroup | undefined = state.groups.find((g) => g.GroupName === payload.groupname);
        if (user && group) {
            let index = user.CognitoUserGroups.findIndex(ug => ug.GroupName === payload.groupname);
            if (index >= 0) {
                user.CognitoUserGroups.splice(index, 1);
            }
        }
    },

    /**
     * Set custom attribute for given user
     * @param state 
     * @param payload {username, attributename, attributevalue}
     * @returns void
     */
    setUserSttribute(state: SysAdminState, payload: { username: string, attributename: string, attributevalue: string }): void {
        let user: CognitoUser | undefined = state.list.find((u) => u.Username === payload.username);
        if (user) {
            user.setAttributeValue(payload.attributename, payload.attributevalue);
        }
    },

    /**
     * Set UserStatus for given user
     * @param state 
     * @param payload {username, status}
     * @returns void
     */
    setUserStatus(state: SysAdminState, payload: { username: string, status: string }): void {
        let user: CognitoUser | undefined = state.list.find((u) => u.Username === payload.username);
        if (user) {
            user.UserStatus = payload.status;
        }
    },

    /**
     * Disable/enable fiven user
     * @param state 
     * @param payload {username, disabled}
     * @returns void
     */
    disableUser(state: SysAdminState, payload: { username: string, disable: boolean }): void {
        let user: CognitoUser | undefined = state.list.find((u) => u.Username === payload.username);
        if (user) {
            user.Enabled = !payload.disable;
        }
    },

    /**
     * ++ Unsaved changes
     * 
     * @param state SysAdminState
     * @param element string
     * @returns void
     */
    addUnsavedChange(state: SysAdminState, element: string): void {
        state.updates = [...new Set(state.updates.concat([element]))];
    },

    /**
     * Reset Unsaved changes
     * 
     * @param state SysAdminState
     * @returns void
     */
    resetUnsavedChanges(state: SysAdminState): void {
        state.updates = [];
    },

};
