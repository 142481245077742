<template>
  <button
    type="button"
    class="menu-item"
    :class="{ 'is-active': isActive ? isActive() : null }"
    @click="clickAction"
    :title="title"
  >
    <svg v-svg :symbol="`ri-${icon}`" size="24"></svg>
  </button>
</template>

<script lang="ts" setup>
import remixiconUrl from '@/components/editor/RemixIcon.vue';
import { PropType, defineProps } from 'vue';

// Define the props with TypeScript type annotations
const props = defineProps({
  icon: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  action: {
    type: Function,
    required: true
  },
  isActive: {
    type: Function,
    default: null
  }
});

const clickAction = (event: Event) => {
  props.action!(event);
}
</script>

<style lang="scss">
.menu-item {
  width: 1.75rem;
  height: 1.75rem;
  color: #0d0d0d;
  border: none;
  background-color: transparent;
  border-radius: 0.4rem;
  padding: 0.25rem;
  margin-right: 0.25rem;

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }

  &.is-active,
  &:hover {
    color: #fff;
    background-color: #0d0d0d;
  }
}
</style>