import _get from 'lodash/get';
import _castArray from 'lodash/castArray'

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

export class ReportDeliverySetting extends Base {

  public bookedby: boolean;
  public landlord: boolean;
  public email: string[];

  /**
   * Constructor initialises the variables 
   * @param data - the data to construct a type with all properties of <ReportDeliverySetting>
   */
  public constructor(data?: Partial<ReportDeliverySetting>) {
    super(data);
    data = toCamelCase(data);

    this.bookedby = _get(data, 'bookedby', true);
    this.landlord = _get(data, 'landlord', false);
    this.email = _castArray(_get(data, 'toaddresses', []));
  }

}
