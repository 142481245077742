<template>
  <div
    class="file-upload mt-2"
    :class="{ drag: isDragging }"
    @dragover.stop.prevent="onDragOver"
    @dragleave.stop.prevent="onDragLeave"
    @drop.stop.prevent="onDrop"
  >
    <div>
      <PhotoComponent
        v-for="(photo, $index) in photos"
        :key="actProperty.hashObj(photo)"
        :photo="photo"
        :galleryType="galleryType"
        :showrotatebutton="showrotatebutton"
        :showdeletebutton="showdeletebutton"
        @rotate="rotatePhoto($index)"
        @delete="deletePhoto($index)"
      />
    </div>

    <span v-if="uploadingCount"><i class="fas fa-spinner fa-spin"></i></span>

    <label v-else-if="shownewbutton">
      <span class="btn btn-sm btn-outline-secondary photo-upload-button">
        <div v-if="buttontext">{{ buttontext }}</div>
        <div v-else>
          <i class="fas fa-plus"></i>
          Photo
        </div>
      </span>
      <input
        type="file"
        accept="image/jpeg"
        multiple
        ref="file"
        v-on:change="onFileInputChange"
      />
    </label>
  </div>
</template>

<script setup lang="ts">
import _ from 'lodash';
import { ref, computed, PropType, inject, defineProps, defineEmits } from 'vue';
import PhotoComponent from '@/components/photo/Photo.vue';
import { useStore } from 'vuex';
import { Photo } from '@/models';
import { useToast } from "vue-toastification";
import sanitize from "sanitize-filename";
import slugify from "slugify";
const jo = require("jpeg-autorotate");
const piexif = require("piexifjs");
import moment from "moment-timezone";
import { Storage } from 'aws-amplify';

const props = defineProps({
  path: { type: String, default: ''},
  photos: { type: Array as PropType<Photo[]>, default: []},
  buttontext: String,
  shownewbutton: { type: Boolean, default: true },
  showdeletebutton: { type: Boolean, default: true },
  showrotatebutton: { type: Boolean, default: true },
});

const { path, photos, buttontext, shownewbutton, showdeletebutton, showrotatebutton } = props;

const store = useStore();

const report = computed(() => store.getters['reports/current']);
const deviceKey = computed(() => store.getters['auth/deviceKey']);

const setReportDeep = (payload: { path: string; data: any }): Promise<any> =>
store.dispatch('reports/setReportDeep', payload);

const actProperty: any = inject('actProperty');
const toasted = useToast();
const file = ref(null);

const emit = defineEmits(['documentadded', 'documentdeleted'])

const isDragging = ref(false);
const uploadingCount = ref(0);

const galleryType = computed((): string => {
  if (path.endsWith("photos.in")) {
    return path.endsWith("photos.in") ? "in" : "out";
  }
  return path.endsWith("condition.in.photos") ? "in" : "out";
});

// Methods converted to the Composition API style
const onDragOver = (event: DragEvent) => {
  isDragging.value = true;
};

const onDragLeave = (event: MouseEvent) => {
  if (event.currentTarget === event.target) {
    isDragging.value = false;
  }
};

const onDrop = (event: DragEvent) => {
  isDragging.value = false;
  const files: FileList | null = _.get(event, "dataTransfer.files", null);
  uploadFiles(files);
};

const onFileInputChange = (event: Event) => {
  const files = _.get(file.value, "files", null);
  uploadFiles(files);
};

const uploadFiles = async (files: FileList | null) => {
  try {
      if (null === files || 0 === files.length) {
        return;
      }

      uploadingCount.value = 0;

      // S3 options
      const options: any = {
        contentType: "image/jpeg",
        customPrefix: {
          public: "",
          protected: "",
          private: "",
        },
        level: "public",
      };

      for (let i = 0; i < files.length; i++) {
        let file: File | null = files.item(i);

        if (file === null) {
          continue;
        }

        const { name, type, lastModified } = file;

        if (type !== "image/jpeg") {
          actProperty.displayError(`${sanitize(name)} is not a JPEG.`);
          continue;
        }

        // Increase uploadingCount (displays loading spinner)
        ++uploadingCount.value;

        // Use ArrayBuffer for jpeg-autorotate
        const arrayBuffer = await file.arrayBuffer();

        let imageBuffer = new Buffer(arrayBuffer);

        // Prevent "Given thumbnail is too large. max 64kB" error
        imageBuffer = deleteThumbnailFromExif(imageBuffer);

        // Correct the orientation using jpeg-autorotate
        await jo
          .rotate(imageBuffer, {})
          .then(
            (result: {
              buffer: any;
              orientation: number;
              dimensions: {
                width: number;
                height: number;
              };
              quality: number;
            }) => {
              // console.log(`Orientation was ${result.orientation}`)
              // console.log(`Dimensions after rotation: ${result.dimensions.width}x${result.dimensions.height}`);
              // console.log(`Quality: ${result.quality}`);

              /**
               * The 8 EXIF orientation values are numbered 1 to 8:
               *
               * 1 = 0 degrees: the correct orientation, no adjustment is required.
               * 2 = 0 degrees, mirrored: image has been flipped back-to-front.
               * 3 = 180 degrees: image is upside down.
               * 4 = 180 degrees, mirrored: image has been flipped back-to-front and is upside down.
               * 5 = 90 degrees: image has been flipped back-to-front and is on its side.
               * 6 = 90 degrees, mirrored: image is on its side.
               * 7 = 270 degrees: image has been flipped back-to-front and is on its far side.
               * 8 = 270 degrees, mirrored: image is on its far side.
               */
              if (result.orientation > 1) {
                // Recreate File from jpeg-autorotate Buffer data
                const newFileBuffer = Buffer.from(result.buffer);

                // Update the File we will send to S3
                file = new File([newFileBuffer], name, {
                  type: type,
                  lastModified: lastModified,
                });
              }
            }
          )
          .catch((error: any) => {
            switch (error.code) {
              // Some errors should not halt processing
              case jo.errors.read_exif: // EXIF data could not be read
              case jo.errors.no_orientation: // No orientation tag was found
              case jo.errors.correct_orientation: // The image orientation is already correct
                console.info(name, error.message, error);
                break;
              // Rethrow all other errors
              case jo.errors.read_file: // File could not be opened
              case jo.errors.unknown_orientation: // The orientation tag is unknown
              case jo.errors.rotate_file: // An error occurred when rotating the image
              default:
                throw error;
            }
          });

        // Add timestamp to make each device upload unique, and sanitise input filename
        let deviceKeyPrefix = `${deviceKey.value}`;
        if (!deviceKey.value) deviceKeyPrefix = `tenantcomments`;
        else deviceKeyPrefix = `${deviceKey.value}`;
        const s3Filepath = `${deviceKeyPrefix}/${moment().format(
          "x"
        )}/${slugify(sanitize(name))}`;

        // Save the File to S3
        await Storage.put(s3Filepath, file, options)
          .then(({ key }: any) => {
            if (!key || !key.length) {
              --uploadingCount.value;
              throw Error("Could not determine uploaded URL");
            }

            // Create the Photo from the S3 response
            const photo = new Photo({
              src: actProperty.s3Origin(key),
              createdAt: actProperty.datetimeToUTC(lastModified),
              // We only display the link to the Gallery when the parent Report has been saved
              hasGalleryLink: false,
            });

            // Add Photo to photos array
            const photos = _.get(report.value, path).concat([photo]);

            // Update Vuex
            setReportDeep({ path: path, data: photos });

            --uploadingCount.value;
          })
          .catch((error: Error) => {
            // Adjust uploadingCount and rethrow error
            --uploadingCount.value;
            throw error;
          });
      }
    } catch (error) {
      actProperty.displayError(error);
    }
}

/**
 * Remove the thumbnail from an image.
 *
 * This prevents the "Given thumbnail is too large. max 64kB" error.
 *
 * @link https://github.com/johansatge/jpeg-autorotate#thumbnail-too-large
 *
 * @param {Buffer} imageBuffer Image Buffer
 *
 * @returns {Buffer}
 */
const deleteThumbnailFromExif = (imageBuffer: Buffer) => {
  const imageString = imageBuffer.toString("binary");
  const exifObj = piexif.load(imageString);
  delete exifObj.thumbnail;
  delete exifObj["1st"];
  const exifBytes = piexif.dump(exifObj);
  const newImageString = piexif.insert(exifBytes, imageString);
  return Buffer.from(newImageString, "binary");
}

/**
 * Rotate a Photo.
 *
 * A single rotation is always 90° clockwise.
 *
 * @param {number} index index of Photo to rotate
 */
const rotatePhoto = (index: number) => {
  let photo = photos[index];
  let { rotate } = photo;

  // init rotate property if it doesn't exist
  if (rotate === undefined) {
    rotate = 0;
  }

  // Rotate by 90°, convert 360° to 0°
  rotate = (rotate + 90) % 360;

  // remove rotate property if we are back to 0°
  if (rotate) {
    photo.rotate = rotate;
  } else {
    delete photo.rotate;
  }

  // Update photos array
  photos.splice(index, 1, photo);

  // Update vuex
  setReportDeep({ path: path, data: photos });
}

/**
 * Delete a Photo.
 *
 * Note this only removes the Photo reference from the data in Vuex.
 * It DOES NOT delete the actual file from file storage (e.g. from FTP/S3).
 *
 * @param {number} index Index of Photo to delete
 */
const deletePhoto = (index: number) => {
  actProperty.confirmPrompt().then(() => {
    // Update photos array
    // const index = this._photos.findIndex(x => x._uuid == photo._uuid);
    photos.splice(index, 1);

    // Update Vuex
    setReportDeep({ path: path, data: photos })
      .then(() => toasted.success("Photo removed"))
      .catch((err: any) => actProperty.displayError(err));
  }).catch(e => {});
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/bootstrap/_variables.scss";

.drag * {
  pointer-events: none;
}

.file-upload {
  border: 2px dashed rgba(0, 0, 0, 0);
  border-radius: 0.2rem;
  box-sizing: border-box;
  width: 100%;
  &.drag {
    background-color: $info-semi-opaque;
    border-color: $info;
  }
}

label {
  margin-bottom: 0;
  input[type="file"] {
    display: none;
  }
}
</style>