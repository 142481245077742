import _castArray from 'lodash/castArray';
import _get from 'lodash/get';

import { Base, Item } from '@/models';
import { toCamelCase } from '@/utilities';

export class Type extends Base {

  public slug: string;
  public name: string;
  public items: Item[];

  /**
   * Constructor initialises the variables from the class Type
   * @param data - the data to construct a type with all properties of <Type>
   */
  public constructor(data?: Partial<Type>) {
    super(data);
    data = toCamelCase(data);

    this.slug = _get(data, 'slug', '');
    this.name = _get(data, 'name', '');
    this.items = _castArray(_get(data, 'items', [])).map((x: Partial<Item>) => new Item(x));
  }

}
