import _castArray from 'lodash/castArray';
import _get from 'lodash/get';

import { Base, Address, Booking, Report } from '@/models';
import { toCamelCase } from '@/utilities';

export class Property extends Base {

  public id: string;
  public address: Address;
  public bookings: Booking[];
  public reports: Report[];

  public createdAt: string;
  public updatedAt: string;


  public constructor(data?: Partial<Property>) {
    super(data);
    data = toCamelCase(data);

    this.id = _get(data, 'id', '');

    this.bookings = _castArray(_get(data, 'bookings', [])).map((x: Partial<Booking>) => new Booking(x));
    this.reports = _castArray(_get(data, 'reports', [])).map((x: Partial<Report>) => new Report(x));

    this.createdAt = _get(data, 'createdAt', '');
    this.updatedAt = _get(data, 'updatedAt', '');
  }
}
