<template>
  <div class="report-section keys">

    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th width="20%">Location</th>
          <th width="15%">Type</th>
          <th width="15%">Check In</th>
          <th width="15%">Check Out</th>
          <th width="10%">Code</th>
          <th width="20%">Notes</th>
          <th width="5%"><span class="sr-only">Actions</span></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(key, $index) in keys" :key="key._uuid">
          <td><input v-model.lazy=key.name class="form-control form-control-sm" size="10"  @change="editKey(key,$index)" /></td>
          <td><input v-model.lazy=key.type class="form-control form-control-sm" size="10"  @change="editKey(key,$index)"/></td>
          <td>
            <input type="number" v-model.number.lazy=key.qty.in class="form-control form-control-sm" min="0" size="1" @change="editKey(key,$index)"/>
            <Photos
              :key="`key${key._uuid}in${key.photos.in.length}`"
              :photos="key.photos.in"
              :path="`keys[${$index}].photos.in`"
            />
          </td>
          <td v-if="isCheckOut()">
            <input type="number" v-model.number.lazy=key.qty.out class="form-control form-control-sm" min="0" size="1" @change="editKey(key,$index)"/>
            <Photos
              :key="`key${key._uuid}out${key.photos.out.length}`"
              :photos="key.photos.out"
              :path="`keys[${$index}].photos.out`"
            />
          </td>
          <td v-else />
          <td><input v-model.lazy=key.code class="form-control form-control-sm" size="4" @change="editKey(key,$index)"/></td>
          <td><input v-model.lazy=key.notes class="form-control form-control-sm" @change="editKey(key,$index)"/></td>
          <td>
            <button class="btn btn-sm btn-outline-danger" @click="removeKey($index)" tabindex="-1">
              <i class="fas fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <button class="btn btn-primary" @click="addNewKey()">
      <i class="fas fa-plus"></i> Key
    </button>

  </div>
</template>

<script lang="ts" setup>
import { computed, inject, ref } from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';
import { Report, Key } from '@/models';
import Photos from '@/components/photo/Photos.vue';

const store = useStore();
const report = computed((): Report => store.getters['reports/current']);
const actProperty: any = inject('actProperty');
// Converting @Action to arrow functions
const setReportDeep = (payload: { path: string; data: any }): Promise<any> => {
  return store.dispatch('reports/setReportDeep', payload);
};
const keys = computed({
  get: () => report.value.keys,
  set: (data: Key[]) => {
    store.dispatch('reports/setReportDeep', { path: 'keys', data });
  },
});

const isCheckOut = () => {
  return report.value.type == 'checkout';
};

const addNewKey = () => {
  keys.value = keys.value.concat([new Key()]);
};

const removeKey = (index: number) => {
  actProperty.confirmPrompt()
      .then(() => keys.value = actProperty.removeItemByIndex(keys.value, index));
};
const editKey = (key:Key,index:Number) => {
 setReportDeep({
    path: `keys[${index}]`,
    data:key
  });
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
