import _ from "lodash";

import { API } from "aws-amplify";

import { ActionTree, ActionContext } from "vuex";
import { RootState } from "../types";
import { InspectorsState } from "./types";

import { Inspector, Location } from "@/models";

import defaultschedules from "./json/schedules.json";

/**
 * Actions
 *
 * Actions are similar to mutations, the differences being that:
 * - Instead of mutating the state, actions commit mutations.
 * - Actions can contain arbitrary asynchronous operations.
 * - Actions are triggered with the store.dispatch method e.g. `store.dispatch('getSettings')`
 */
export const actions: ActionTree<InspectorsState, RootState> = {
  /**
   * Get multiple Inspectors
   *
   * @param {InspectorsState} store
   * @param filters
   * @returns Multiple Inspectors
   */
  async getInspectors(
    store: ActionContext<InspectorsState, any>,
    filters: any
  ): Promise<Inspector[]> {
    const options = {
      queryStringParameters: _.pickBy(_.pick(filters, ["starts_with"])),
    };

    store.commit("app/addRequest", "getInspectors", { root: true });

    return API.get("RestAPI", "/inspectors", options)
      .then((response) =>
        response.map((x: Partial<Inspector>) => new Inspector(x))
      )
      .then((inspectors: Inspector[]) => {
        store.commit("setInspectors", inspectors);
        return inspectors;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getInspectors", { root: true })
      );
  },

  /**
   * Get one Inspector
   *
   * @param {InspectorsState} store
   * @param id
   * @returns Individual Inspector
   */
  async getInspector(store: ActionContext<InspectorsState, any>, id) {
    // New Inspector
    if (id === "new") {
      const inspector = new Inspector();
      return inspector;
    }
    store.commit("app/addRequest", "getInspector", { root: true });
    return API.get("RestAPI", `/inspectors/${id}`, null)
      .then((response) => new Inspector(response))
      .then((inspector: Inspector) => {
        store.commit("setInspector", inspector);
        return inspector;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getInspector", { root: true })
      );
  },

  /**
   * Get default Inspector
   *
   * @param {InspectorsState} store
   * @param id
   * @returns Individual Inspector
   */
  async getDefaultInspector(store: ActionContext<InspectorsState, any>) {
    store.commit("app/addRequest", "getDefaultInspector", { root: true });
    const options = {
      queryStringParameters: { starts_with: "d" },
    };
    return API.get("RestAPI", `/inspectors/`, options)
      .then((response) =>
        response.map((x: Partial<Inspector>) => new Inspector(x))
      )
      .then((inspectors: Inspector[]) => {
        let defaultinspector: Inspector | undefined = inspectors.find(
          (i: Inspector) => i.name.toLowerCase() === "default"
        );
        store.commit("setDefaultInspector", defaultinspector);
        return defaultinspector;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getDefaultInspector", { root: true })
      );
  },

  /**
   * Add a Inspector
   *
   * @param {InspectorsState} store
   * @param inspector Inspector
   * @returns Inspector
   */
  async addInspector(
    store: ActionContext<InspectorsState, any>,
    inspector: Inspector
  ) {
    store.commit("app/addRequest", "addInspector", { root: true });
    let jsonBody = inspector.toJSON();
    //jsonBody.schedules = defaultschedules.schedules;
    return API.post("RestAPI", `/inspectors`, { body: jsonBody })
      .then((response) => new Inspector(response))
      .then((inspector: Inspector) => {
        const newlist: Inspector[] = store.state.list.concat(inspector);
        store.commit("setInspectors", newlist);
        store.dispatch("diary/addInspector", inspector, { root: true });
        store.commit("setInspector", inspector);
        store.commit("resetUnsavedChanges");
        return inspector;
      })
      .finally(() =>
        store.commit("app/removeRequest", "addInspector", { root: true })
      );
  },

  /**
   * Update a Inspector
   *
   * @param {InspectorsState} store
   * @param inspector Inspector
   * @returns Updated Inspector
   */
  async updateInspector(
    store: ActionContext<InspectorsState, any>,
    inspector: Inspector
  ) {
    store.commit("app/addRequest", "updateInspector", { root: true });
    return API.put("RestAPI", `/inspectors/${inspector.id}`, {
      body: inspector.toJSON(),
    })
      .then((response) => new Inspector(response))
      .then((inspector: Inspector) => {
        store.commit("setInspector", inspector);
        _.remove(store.state.list, (c: Inspector) => c.id === inspector.id);
        const newlist: Inspector[] = store.state.list.concat(inspector);
        store.commit("setInspectors", newlist);
        store.dispatch("diary/updateInspector", inspector, { root: true });
        store.commit("resetUnsavedChanges");
        return inspector;
      })
      .finally(() =>
        store.commit("app/removeRequest", "updateInspector", { root: true })
      );
  },

  /**
   * Update a Inspector are coverage ratings
   *
   * @param {InspectorsState} store
   * @param locations Location array
   * @returns Updated Inspector
   */
  async updateCoverageRating(
    store: ActionContext<InspectorsState, any>,
    inspector: Inspector
  ) {
    store.commit("app/addRequest", "updateCoverageRating", { root: true });
    return API.put("RestAPI", `/inspectors/${inspector.id}/coveragerating`, {
      body: inspector.toJSON(),
    })
      .then((response) => new Inspector(response))
      .then((inspector: Inspector) => {
        store.commit("setInspector", inspector);
        _.remove(store.state.list, (c: Inspector) => c.id === inspector.id);
        const newlist: Inspector[] = store.state.list.concat(inspector);
        store.commit("setInspectors", newlist);
        store.dispatch("diary/updateInspector", inspector, { root: true });
        store.commit("resetUnsavedChanges");
        return inspector;
      })
      .finally(() =>
        store.commit("app/removeRequest", "updateCoverageRating", {
          root: true,
        })
      );
  },

  /**
   * Delete a Inspector
   *
   * @param {InspectorsState} store
   * @param inspector Inspector
   * @returns Deleted Inspector
   */
  async deleteInspector(
    store: ActionContext<InspectorsState, any>,
    inspector: Inspector
  ): Promise<void> {
    store.commit("app/addRequest", "deleteInspector", { root: true });
    return API.del("RestAPI", `/inspectors/${inspector.id}`, {})
      .then(() => {
        const newlist: Inspector[] = _.remove(
          store.state.list,
          (c: Inspector) => c.id !== inspector.id
        );
        store.commit("setInspectors", newlist);
        store.dispatch("diary/deleteInspector", inspector, { root: true });
      })
      .then(() => store.commit("setInspector", new Inspector()))
      .then(() => store.commit("resetUnsavedChanges"))
      .finally(() =>
        store.commit("app/removeRequest", "deleteInspector", { root: true })
      );
  },

  /**
   * Edit a Inspector
   *
   * @param {InspectorsState} store
   * @param inspector Inspector
   */
  editInspector(
    store: ActionContext<InspectorsState, any>,
    inspector: Inspector
  ) {
    store.commit("setInspector", inspector);
    store.commit("addUnsavedChange");
  },

  /**
   * Sets any deep inspector property and increments the "unsaved changes" count
   * @param store
   * @param data
   */
  setInspectorDeep(store: ActionContext<InspectorsState, any>, data): void {
    store.commit("setInspectorDeep", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Adds a RateCard, and increments the "unsaved changes" count
   */
  addRateCard(store: ActionContext<InspectorsState, any>, data): void {
    store.commit("addRateCard", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Removes a RateCard, and increments the "unsaved changes" count
   */
  removeRateCard(store: ActionContext<InspectorsState, any>, data): void {
    store.commit("removeRateCard", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Adds a Location, and increments the "unsaved changes" count
   */
  addLocation(store: ActionContext<InspectorsState, any>, data): void {
    store.commit("addLocation", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Removes a Location, and increments the "unsaved changes" count
   */
  removeLocation(store: ActionContext<InspectorsState, any>, data): void {
    store.commit("removeLocation", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Adds an excluded client, and increments the "unsaved changes" count
   */
  addExcludedClient(store: ActionContext<InspectorsState, any>, data): void {
    store.commit("addExcludedClient", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Removes a excluded client, and increments the "unsaved changes" count
   */
  removeExcludedClient(store: ActionContext<InspectorsState, any>, data): void {
    store.commit("removeExcludedClient", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Adds an preferred client, and increments the "unsaved changes" count
   */
  addPreferredClient(store: ActionContext<InspectorsState, any>, data): void {
    store.commit("addPreferredClient", data);
    store.commit("addUnsavedChange");
  },

  /**
   * Removes a preferred client, and increments the "unsaved changes" count
   */
  removePreferredClient(
    store: ActionContext<InspectorsState, any>,
    data
  ): void {
    store.commit("removePreferredClient", data);
    store.commit("addUnsavedChange");
  },
};
