<template>
  <div class="card reports--room">
    <div class="card-header" :class="{ 'toggle-body': _toggle }">
      <h2 class="mb-0" @click="toggleShowBody()">
        <div class="">{{ _compliancesection.heading }}</div>
      </h2>
    </div>
    <div class="card-body" v-if="_opened">
      <div
        v-for="(complianceitem, $index) in _compliancesection.complianceitems"
        :key="complianceitem._uuid"
      >
        <div v-if="complianceitem.subheadingitem">
          <h5>{{ complianceitem.prompt }}</h5>
        </div>
        <compliance-item
          v-else
          :complianceitem="complianceitem"
          :index="$index"
          :toggle="false"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineEmits, ref, onMounted, provide, defineProps } from 'vue';
import { useStore } from 'vuex';
import {
  Compliancesection,
  Compliancequestion,
  Complianceitem,
} from "@/models";
import ComplianceItem from "@/components/visitchecks/ComplianceItem.vue";

const props = defineProps<{
  compliancesection: Compliancesection;
  index: number;
  toggle: boolean;
  opened: boolean;
}>();

const store = useStore();

const _compliancesection = computed(() => props.compliancesection);
const _index = computed(() => props.index);
const _toggle = computed(() => props.toggle);
const _opened = computed(() => props.opened);

provide("sectionIndex", _index.value);

const showBody = ref(true);

const setReportDeepWithoutCounting = (payload: { path: string; data: any }) => {
  store.dispatch('reports/setReportDeepWithoutCounting', payload);
};

const compliancelist = computed((): Compliancequestion[] => store.getters['customerdictionary/compliancelist']);

const emit = defineEmits(['showBody'])

// Methods
const toggleShowBody = () => {
  if (_toggle.value) {
    showBody.value = !_opened.value;
    emit('showBody', _index.value, showBody.value);
  }
};

onMounted(async () => {
  if (_compliancesection.value && compliancelist.value) {
    if (!_compliancesection.value.complianceitems) {
      _compliancesection.value.complianceitems = [];
      await setReportDeepWithoutCounting({
        path: `compliancesections[${_index.value}].complianceitems`,
        data: _compliancesection.value.complianceitems,
      });
    }

    let sectionitem = false;
    let newcompliancelist: Complianceitem[] = [];
    compliancelist.value.forEach((question) => {
      let headingitem = false;
      if (question.questionType === "heading") {
        headingitem = true;
        sectionitem = question.prompt === _compliancesection.value.heading;
      }
      if (!headingitem && sectionitem) {
        let existingitem = _compliancesection.value.complianceitems.find(
          (i) => i.slug === question.slug
        );
        let item: Complianceitem = new Complianceitem();
        if (!existingitem) {
          item.slug = question.slug;
          item.prompt = question.prompt;
          item.photos = [];
        } else {
          item.slug = existingitem.slug;
          item.prompt = existingitem.prompt;
          item.notes = existingitem.notes;
          item.value = existingitem.value;
          item.photos = existingitem.photos;
        }
        newcompliancelist.push(item);
        item.subheadingitem = question.questionType === "subheading";
      }
    });
    _compliancesection.value.complianceitems = newcompliancelist;
    setReportDeepWithoutCounting({
      path: `compliancesections[${_index.value}].complianceitems`,
      data: _compliancesection.value.complianceitems,
    });
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card {
  margin-bottom: 1rem;
}

.card-header {
  a {
    display: inline-block;
    margin-left: 1rem;
  }
  small {
    font-size: 56%;
  }
}
</style>
