import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

export class Landlord extends Base {

  public llname: string;
  public llemail: string;
  public llmobile: string;

  public constructor(data?: Partial<Landlord>) {
    super(data);
    data = toCamelCase(data);

    this.llname = _get(data, 'llname', '');
    this.llemail = _get(data, 'llemail', '');
    this.llmobile = _get(data, 'llmobile', '');
    this.llmobile = this.llmobile.replaceAll(' ', '');
  }
}