<template>
  <div class="auth confirm-email">

    <div class="alert danger" v-if="error.length">
      {{error}}
    </div>

    <div class="form-group row">
      <label class="col-sm-3 col-form-label">Verification Code</label>
      <div class="col-sm-6">
        <input type="email" class="form-control" v-model="code" />
        <small id="verification-code-help" class="form-text text-muted">
          The verification code has been sent to the email you supplied.
        </small>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-6 offset-sm-3">
        <button class="btn btn-primary" @click="confirmSignIn()" :disabled="!code.length">
          Confirm
        </button>
      </div>
    </div>

  </div>
</template>

<script lang="ts" setup>
import { ref, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Auth from '@aws-amplify/auth';

const actProperty: any = inject('actProperty');

const store = useStore();
const router = useRouter();

// Reactive state for code and error
const code = ref('');
const error = ref('');

// Computed property for cognitoUser from Vuex store
const cognitoUser = computed(() => store.getters['auth/cognitoUser']);

// Method to confirm sign in
const confirmSignIn = async () => {
  error.value = ''; // Reset error message before the attempt

  try {
    await Auth.confirmSignIn(cognitoUser.value, code.value, 'SOFTWARE_TOKEN_MFA');
    router.push({ name: 'reports' });
  } catch (err: any) {
    actProperty.displayError(err);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
