<template>
  <form class="form" @submit.prevent="onSubmit">
    <div class="input-group">
      <label for="report-filter" class="sr-only">Search</label>
      <input id="report-filter" type="search"  class="form-control" v-model="filter" :placeholder=placeholder autocomplete="off" />
      <span class="input-group-btn ml-2">
        <button type="submit" class="btn btn-outline-primary">
          <i class="fas fa-filter"></i>
        </button>
      </span>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { computed, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

// Define the placeholder prop.
const props = defineProps<{
  placeholder: string;
}>();

const store = useStore();
const router = useRouter();
const setFilter = (filter: string): Promise<any> => {
  return store.dispatch('reports/setFilter', filter);
};

// Computed setter and getter for the filter value that uses the store
const filter = computed({
  get: () => store.state.reports.filter,
  set: (val: string | undefined) => store.commit('reports/setFilter', val),
});

// Actions using the store.dispatch
const resetCurrentPage = async () => {
  await store.dispatch('reports/resetCurrentPage');
};

// Methods converted to arrow functions
const onSubmit = async () => {
  await router.push({ path: 'reports', query: { search: store.state.reports.search, filter: filter.value }});
  await resetCurrentPage();
};

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
