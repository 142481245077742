<template>
  <div>
    <header>
      <div class="row">
        <div class="col-sm-6">
          <h1>Heat Chart</h1>
        </div>
      </div>
    </header>

    <LocationsPagination @filter="filterLocations" />

    <div class="row m-3" v-if="inspectors">
      <table class="table table-bordered table-sm">
        <thead>
          <tr>
            <th class="text-center" style="width: 100px">Area</th>
            <th class="text-center" style="width: 40px">1</th>
            <th class="text-center" style="width: 40px">2</th>
            <th class="text-center" style="width: 40px">3</th>
            <th
              style="width: 40px"
              class="rotate"
              v-for="inspector in sortedinspectors"
              :key="inspector.id"
            >
              <div>
                <span>{{ inspector.name }}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-for="(location, $index) in filteredLocations" :key="$index">
          <tr>
            <td class="align-middle">
              {{ location.code }}
            </td>
            <td class="coverage-cell text-center">
              {{ getTally(location, 1) }}
            </td>
            <td class="coverage-cell text-center">
              {{ getTally(location, 2) }}
            </td>
            <td class="coverage-cell text-center">
              {{ getTally(location, 3) }}
            </td>
            <td
              v-for="inspector in sortedinspectors"
              :key="inspector.id"
              :class="getCoverageClass(location, inspector)"
              class="text-center"
              @click="changeCoverage(inspector, location)"
            >
              <div>
                <span>
                  {{ getCoverage(location, inspector) }}
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import _ from "lodash";
import locationsData from "@/assets/json/locations.json";
import LocationsPagination from "@/components/inspectors/LocationsPagination.vue";
import { Location, Inspector } from "@/models";

const store = useStore();
const filteredLocations = ref([] as any[]);

// Getting inspectors from the store
const inspectors = computed(() => store.getters['diary/inspectorlist']);

const setCurrentInspector = (inspector: Inspector) => {
  store.commit('inspectors/setCurrentInspector', inspector);
};
const setInspectorDeep = async (payload: { path: string; data: any }) => {
  await store.dispatch('inspectors/setInspectorDeep', payload);
};
const updateCoverageRating = async (inspector: Inspector) => {
  await store.dispatch('inspectors/updateCoverageRating', inspector);
}

// Computed property for sorted inspectors
const sortedinspectors = computed(() => {
  return inspectors.value.filter((i: Inspector) => i.id).sort((i1: Inspector, i2: Inspector) =>
    i1.name.localeCompare(i2.name)
  );
});

// Computed property for all locations
const allLocations = computed(() => {
  return locationsData.sort((l1: any, l2: any) => l1.code.localeCompare(l2.code));
});

// Method for getting coverage class
const getCoverageClass = (location: Location, inspector: Inspector) => {
  let loc: Location | undefined = inspector.locations.find(
    (l: Location) => l.code.trim() === location.code.trim()
  );
  let cls: string = "rating-0";
  if (loc) {
    if (loc.rating == 1) cls = "rating-1";
    else if (loc.rating == 2) cls = "rating-2";
    else if (loc.rating == 3) cls = "rating-3";
  }
  return cls;
};

// Method for getting coverage
const getCoverage = (location: Location, inspector: Inspector) => {
  let loc: Location | undefined = inspector.locations.find(
    (l: Location) => l.code.trim() === location.code.trim()
  );
  return loc ? loc.rating : "-";
};

// Method for getting tally
const getTally = (location: Location, rating: number) => {
  let list = inspectors.value.filter((i: Inspector) =>
    i.locations.find(
      (l: Location) => l.code.trim() === location.code.trim() && l.rating === rating
    )
  );
  return list && list.length ? list.length : "-";
};

// Method for filtering locations
const filterLocations = (startsWith: string) => {
  filteredLocations.value = locationsData
    .filter((l: any) => {
      return String(l.code).toLowerCase().startsWith(startsWith.toLowerCase());
    })
    .sort((l1: any, l2: any) => l1.code.localeCompare(l2.code));
};

const changeCoverage = (inspector: Inspector, location: Location) => {
  setCurrentInspector(inspector);
  const locIndex = inspector.locations.findIndex((l: Location) =>  l.code.trim() === location.code.trim());
  if(locIndex >= 0) {
    // The location is already in the list
    const l: Location = inspector.locations[locIndex];
    if(l.rating === 3) {
      // Make the rating zero by remocing location from inspector location list
      inspector.locations.splice(locIndex, 1);
      setInspectorDeep({path: 'locations', data: [...inspector.locations]});
    }
    else {
      setInspectorDeep({path: `locations[${locIndex}].rating`, data: l.rating + 1});
    }
  }
  else {
    inspector.locations.push(new Location(location));
    setInspectorDeep({path: 'locations', data: [...inspector.locations]});
  }
  updateCoverageRating(inspector);
}

</script>


<style scoped lang="scss">
table {
  position: relative;
  width: auto;
  tr {
    position: relative;
    transform: scale(1);
  }
  td:first-child,
  th:first-child {
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    //border-left: 1px solid white;
  }
}
.card-body {
  overflow: scroll;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
  &:before {
    content: "";
    display: block;
    top: 2px;
    bottom: 2px;
    width: 1px;
    background-color: white;
    z-index: 3;
    position: absolute;
  }
}

th.rotate {
  /* Something you can count on */
  height: 160px;
  white-space: nowrap;
}

th.rotate > div {
  transform: 
    /* Magic Numbers */ translate(0px, 0px)
    /* 45 is really 360 - 45 */ rotate(270deg);
  width: 30px;
}
th.rotate > div > span {
  border-bottom: 0px solid #ccc;
  padding: 5px 10px;
}

.coverage-cell {
  width: 20px;
  height: 20px;
}

.rating-0 {
  color: rgb(200, 200, 200);
}
.rating-1 {
  background-color: rgb(224, 255, 246);
  font-weight: bold !important;
}
.rating-2 {
  background-color: rgb(141, 251, 216);
  font-weight: bold !important;
}
.rating-3 {
  background-color: rgb(2, 199, 140);
  font-weight: bold !important;
}
</style>

<!-- Imported CSS from http://www.merlinlive.co.uk:8082/merlin/resources/template/css/dictionarytable.css -->
<style scoped lang="scss">
table {
  border-collapse: collapse;
  width: 100%;
}

table.dictionarytable {
  margin: 0 0 0px;
  table-layout: fixed;
}

th {
  font-size: 14px;
  font-weight: bold;
}
th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}
td.category:hover {
  background-color: #f8bbd0;
}
td.selectedcategory {
  background-color: #f48fb1;
}

td.section:hover {
  background-color: #90caf9;
}
td.selectedsection {
  background-color: #42a5f5;
}

td.type:hover {
  background-color: #b39ddb;
}
td.selectedtype {
  background-color: #9575cd;
}

td.item:hover {
  background-color: #ffe0b2;
}
td.selecteditem {
  background-color: #ffb74d;
}

td.cicondition:hover {
  background-color: #c8e6c9;
}
td.selectedcicondition {
  background-color: #81c784;
}

td.cocondition:hover {
  background-color: #e6ee9c;
}
td.selectedcocondition {
  background-color: #c0ca33;
}

td.colour:hover {
  background-color: #b2dfdb;
}
td.selectedcolour {
  background-color: #4db6ac;
}

td.condition:hover {
  background-color: #b2ebf2;
}
td.selectedcondition {
  background-color: #4dd0e1;
}

.scrollable-600 {
  max-height: 600px !important;
  overflow: auto;
}

.scrollable-500 {
  max-height: 500px !important;
  overflow: auto;
}
.scrollable-400 {
  max-height: 400px !important;
  overflow: auto;
}

.scrollable-250 {
  max-height: 250px !important;
  overflow: auto;
}
.scrollable-200 {
  max-height: 200px !important;
  overflow: auto;
}

.scrollable-100 {
  max-height: 100px !important;
  overflow: auto;
}

th.content,
td.content {
  position: relative;
}
div.dictionaryoperations {
  position: absolute;
  top: 8px;
  right: 10px;
  text-align: right;
  display: inline;
}

.dictionaryoperationicon {
  padding-left: 3px;
  padding-right: 3px;
}

input.itemedittext,
input.itemedittext:focus {
  background-color: transparent;
  padding: 0px;
  min-height: 20px;
  border: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-rendering: auto;
  font-size: 12px;
  color: initial;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  margin: 0em 0em 0em 0em;
}

.navBardemoBasicUsage md-content .ext-content {
  padding: 50px;
  margin: 20px;
  background-color: #fff2e0;
}

.dictionarysavebutton {
  margin-top: 5px;
  height: 40px;
  float: right;
}

th.headercolumn {
  min-width: 285px;
}
td.spacer {
  min-height: 50px;
}

td.rotate {
  /* Something you can count on */
  height: 140px;
  white-space: nowrap;
}

td.rotate > div {
  transform: 
    /* Magic Numbers */ translate(25px, 51px)
    /* 45 is really 360 - 45 */ rotate(315deg);
  width: 30px;
}
td.rotate > div > span {
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
}

td.templatecell {
  width: 30px;
  height: 34px;
  min-width: 30px;
  max-width: 30px;
  min-height: 34px;
  max-height: 34px;
  border: 1px solid #ddd;
  padding: 3px 7px;
  vertical-align: top;
  // horizontal-align: center;
  position: relative;
}

.selectedtemplatesection {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  vertical-align: top;
}

.templateicon {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px, 30px;
  margin: auto;
  position: absolute;
}
.no-template {
  background-image: url("~@/assets/images/grey-donut.png");
}
.furnish0 {
  background-image: url("~@/assets/images/darkgrey-donut.png");
}
.furnish1 {
  background-image: url("~@/assets/images/donut-1.png");
}
.furnish2 {
  background-image: url("~@/assets/images/donut-2.png");
}
.furnish3 {
  background-image: url("~@/assets/images/donut-3.png");
}
.furnish4 {
  background-image: url("~@/assets/images/donut-4.png");
}

.checkboxSelectAll .legend {
  color: #3f51b5;
}

.checkboxSelectAll .fieldset {
  border-style: solid;
  border-width: 1px;
  height: 100%;
}

.checkboxSelectAll .select-all-checkboxes {
  padding-left: 30px;
}

div.md-toolbar-tools h2 {
  color: inherit;
}

.legendrow {
  margin-top: 5px;
  margin-bottom: 5px;
}

.spacer {
  height: 39px;
}

.borderonright {
  border-right: 1px solid #ddd;
}
.borderonbottom {
  border-bottom: 1px solid #ddd;
}
.zerobottommargin {
  margin-bottom: 0px;
}

.level1 {
  color: #2196f3;
}
.level2 {
  color: #f06292;
}
.level3 {
  color: #880e4f;
}

.templatetable {
  max-width: 950px;
}
</style>