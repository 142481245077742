<template>
  <div>
    <div class="row">
      <div class="col-md-10">
        <label class="col-form-label" :class="{ 'text-muted': disabled }">{{
          getFieldDisplayName()
        }}
        <!-- <i
          class="fas fa-plus ml-2"
          v-if="!notes && !showinput && !disabled"
          @click="showinput = !showinput"
        ></i> -->
      </label>
        
        <div class="row">
          <div :class="{'col-md-9':documentsfield, 'col-md-12':!documentsfield}">
            <label class="col-form-label text-muted notes-caption" >{{
              getFieldCaption()
            }}</label>
          </div>
          <div v-if="documentsfield" class="col-md-3 pl-0 pr-0" >
            <Documents
              style="margin-top: 0px !important;"
              :buttontext="'Document'"
              :entity="'booking'"
              :key="documents.length"
              :documents="documents"
              :path="documentsfield"
              @clicked="showDocument"
            />
          </div>
        </div>
        <!-- <i
          v-if="(notes || showinput) && auditlogs.length"
          class="fas fa-clipboard-list text-right ml-2"
          @click="showAuditLogsModal"
        ></i> -->
      </div>
    </div>
    <textarea
      class="form-control"
      rows="3"
      ref="accessissuesref"
      v-model.lazy="notes"
      :maxlength="fixedsize ? 30 : null"
      v-if="notes || showinput"
    ></textarea>
    <i class="fas fa-arrow-down text-right ml-2" @click="showAccessIssuesnotesModal" :style="{ background: accessissuesrowCount ? 'yellow !important' : '' }"></i>
    <AuditlogsModal ref="auditLogsModal" :field="field" />
  </div>
  <OtherNotesDialog :name="`${field}-modal`" :field="field"
      ref="othernotesModal" />
</template>

<script lang="ts" setup>
import { ref, computed, defineEmits, defineProps, PropType} from 'vue';
import { useStore } from 'vuex';
import { Booking, Document } from '@/models';
import AuditlogsModal from '@/components/modals/AuditlogsModal.vue';
import Documents from '@/components/document/Documents.vue';
import OtherNotesDialog from "@/components/modals/OtherNotesDialog.vue";
const emit = defineEmits(['showdocument'])

const props = defineProps({
  field: {type: String as PropType<string>, default: ''},
  disabled: { type: Boolean as PropType<boolean>, required: false, default: false },
  documents: {type: Object as PropType<Document[]>, default: []},
  documentsfield: {type: String as PropType<string>, default: ''},
  fixedsize: { type: Boolean as PropType<boolean>, required: false, default: false },
});

const store = useStore();
const showinput = ref(true);
const auditLogsModal = ref(null);
const accessissuesref = ref(null);
const othernotesModal = ref(null);
const booking = computed(() => store.getters['diary/booking']);
const notes = computed({
  get: () =>  {
    accessissuesref.value = booking.value[props.field as keyof Booking];
    return booking.value[props.field as keyof Booking];
  },
  set: (val) => {
    accessissuesref.value = val;
    store.commit('diary/setBookingDeep', { path: props.field, data: val });
  }
});

const getFieldDisplayName = () => Booking.getFieldDisplayName(props.field);

const getFieldCaption = () => Booking.getFieldCaption(props.field);
const showDocument = (index:number) => {
  emit('showdocument',index);
};
const accessissuesrowCount = computed(() => {
  if (!accessissuesref.value) return;
  return accessissuesref.value.scrollHeight > accessissuesref.value.clientHeight;
});
const showAccessIssuesnotesModal = () => {
  const modal = othernotesModal.value as any;
  modal.init(props);
  modal.show();
}
</script>


<style scoped lang="scss">
.notes-caption{
  font-size: 0.8rem;
  margin-left: 5px;
}
</style>