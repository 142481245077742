<template>
  <div>
    <header>
      <div class="row">
        <div class="col-sm-6">
          <h1>Scheduling</h1>
        </div>
      </div>
    </header>

    <div v-if="!inspector.schedules.length && !hasBlockingRequests" class="alert alert-warning text-center">
      <h2 class="mt-1 mb-2">No schedules found</h2>
      <p class="mb-1">for {{ inspector.name }}, please add</p>
    </div>
    <div v-else v-for="(schedule, $scheduleindex) in inspector.schedules" :key="$scheduleindex">
      <header>
        <span class="schedule-header">{{ schedule.reporttype }}</span>
      </header>
      <div
        v-for="(timinggroup, $timinggroupindex) in schedule.timinggroups"
        :key="$timinggroupindex"
      >
        <table class="table table-bordered table-sm">
          <tbody
            v-for="(
              reporttiming, $reporttimingindex
            ) in timinggroup.propertytypegroup"
            :key="$reporttimingindex"
          >
            <tr>
              <td width="17.7%" class="align-middle">
                {{ reporttiming.propertytype }}
              </td>
              <td width="3.3%">
                <input :value="reporttiming.minutesperitem" min="0" type="number" class="minutesperitem" @select="setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].minutesperitem`,
                    data: $event.target.value,
                  })">
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 15,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    15
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 15">
                  0:15
                </div>
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 30,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    30
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 30">
                  0:30
                </div>
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 45,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    45
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 45">
                  0:45
                </div>
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 60,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    60
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 60">
                  1:00
                </div>
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 75,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    75
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 75">
                  1:15
                </div>
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 90,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    90
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 90">
                  1:30
                </div>
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 105,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    105
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 105">
                  1:45
                </div>
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 120,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    120
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 120">
                  2:00
                </div>
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 135,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    135
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 135">
                  2:15
                </div>
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 150,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    150
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 150">
                  2:30
                </div>
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 165,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    165
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 165">
                  2:45
                </div>
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 180,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    180
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 180">
                  3:00
                </div>
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 195,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    195
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 195">
                  3:15
                </div>
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 210,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    210
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 210">
                  3:30
                </div>
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 225,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    225
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 225">
                  3:45
                </div>
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 240,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    240
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 240">
                  4:00
                </div>
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 255,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    255
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 255">
                  4:15
                </div>
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 270,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    270
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 270">
                  4:30
                </div>
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 285,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    285
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 285">
                  4:45
                </div>
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 300,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    300
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 300">
                  5:00
                </div>
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 315,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    315
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 315">
                  5:15
                </div>
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 330,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    330
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 330">
                  5:30
                </div>
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 345,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    345
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 345">
                  5:45
                </div>
              </td>
              <td
                @click="
                  setInspectorDeep({
                    path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].timing`,
                    data: 360,
                  })
                "
                width="3.3%"
                :style="{
                  'background-color': getBackgroundColor(
                    schedule,
                    timinggroup,
                    reporttiming,
                    360
                  ),
                }"
              >
                <div class="timing-cell" v-if="reporttiming.timing === 360">
                  6:00
                </div>
              </td>
              <td width="3.3%"></td>
            </tr>
            <tr
              class="sliderrow"
              v-if="!schedule.reporttype.startsWith('Check-Out') && !schedule.reporttype.startsWith('Schedule of Condition')"
            >
              <td class="slidercol"></td>
              <td></td>
              <td
                :colspan="getSliderWidth(reporttiming.timing)"
                class="slidercol"
              >

                <vue-slider
                  v-model="reporttiming.tenanttoarriveafter"
                  :min="0"
                  :max="reporttiming.timing"
                  :interval="15"
                  :tooltip-formatter="(val) => minutesToTime(val)"
                  direction="rtl"
                  :processStyle="{
                    'background-color': getBackgroundColor(
                      schedule,
                      timinggroup,
                      reporttiming,
                      0
                    ),
                  }"
                  @change="
                    (value) =>
                      setInspectorDeep({
                        path: `schedules[${$scheduleindex}].timinggroups[${$timinggroupindex}].propertytypegroup[${$reporttimingindex}].tenanttoarriveafter`,
                        data: value,
                      })
                  "
                ></vue-slider>
              </td>
              <td
  :colspan="24 - getSliderWidth(reporttiming.timing)"
  style="white-space: nowrap"
>
                {{ minutesToTime(reporttiming.tenanttoarriveafter) }} minutes
                prior to end of inspection
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { Schedule, ReportTiming, TimingGroup, Inspector } from '@/models';
import defaultschedules from "@/store/inspectors/json/schedules.json";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import moment from 'moment';
import { useEvent } from '@/eventBus';

const hasBlockingRequests = computed(() => store.getters['app/hasBlockingRequests']);
const inspector = computed(() => store.getters['inspectors/current']);

const store = useStore();
const setInspectorDeep = (payload: { path: string; data: any }): Promise<any> => {
  return store.dispatch('inspectors/setInspectorDeep', payload);
};
const getSliderWidth = (minutes: number) => {
  let width = 0;
  if (minutes >= 15) {
    width = minutes / 15;
  }
  return width;
};

const primaryColors = ref<string[]>([
    "#E51A51",
    "#1AE5AE",
    "#7A0FF0",
    "#85F00F",
    "#6251AE",
    "#9DAE51",
    "#1858E7",
    "#E7A718",
    "#4192BE",
    "#BE6D41",
    "#05E1FA",
    "#FA1E05",
    "#E0BF1F",
    "#1F40E0",
    "#B2F302",
    "#214FC6",
    "#FF8866",
    "#CEC8EF",
    "#FF5050",
]);


onMounted(() => {
  useEvent("inspectorLoadedOnSchedulingPage", (insp: Inspector) => {
    if (!inspector.value.schedules || inspector.value.schedules.length === 0) {
      inspector.value.schedules = defaultschedules.schedules.map(
        (s: any) => new Schedule(s)
      );
    }
  });
});

const getBackgroundColor = (
  schedule: Schedule,
  timingGroup: TimingGroup,
  reportTiming: ReportTiming,
  minutes: number
) => {
  let color = '#ffffff';
  const scheduleIndex = inspector.value.schedules.findIndex(
    (s) => s.reporttype === schedule.reporttype
  );
  if (scheduleIndex >= 0) {
    const timingIndex = timingGroup.propertytypegroup.findIndex(
      (t) => t.propertytype === reportTiming.propertytype
    );
    if (timingIndex >= 0 && minutes <= reportTiming.timing) {
      color =
        timingIndex % 2 === 0
          ? primaryColors.value[scheduleIndex]
          : primaryColors.value[scheduleIndex] + '55';
    }
  }
  return color;
};

const minutesToTime = (minutes: number) => {
  const format = new Date(minutes * 60 * 1000).toISOString().substr(11, 5);
  return format;
};

</script>


<style scoped lang="scss">
.schedule-header {
  font-size: 1.2rem;
}
.timing-cell {
  text-align: center;
  color: white;
}
.sliderrow {
}
.slidercol {
  padding: 0px;
  padding-bottom: 5px;
  border: 0px;
}

.minutesperitem {
  width: 100%;
  border: 0px;
}
</style>

