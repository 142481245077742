<template>
  <div
    :id="name"
    class="modal"
    data-keyboard="true"
    tabindex="-1"
    role="dialog"
    aria-labelledby="`smsmodal-label`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="sms-modal-label">
            {{ title }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click.prevent="hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div
          id="emaillogs-scroller"
          class="modal-body"
          v-if="booking && booking.emaillogs"
        >
          <div class="row">
            <div class="col-md-4 right-border vertical-scroll" style="overflow-x: hidden;">
              <div
                class="row"
                :class="{'text-secondary': log.bookingid && log.bookingid != booking.id}"
                v-for="(log, $logindex) in emaillogs"
                :key="$logindex"
                @click="setSelected(log)"
              >
                <div
                  class="col-md-12"
                  :class="{
                    'selected-log': log.logdate == current.logdate,
                  }"
                  style="padding: 0 0 0 25px"
                >
                  <div class="row" style="padding: 5px 0 0 0px">
                    <div class="col-md-12" v-if="log.subject">{{ shortSubject(log.subject) }}</div>
                    <div class="col-md-12" v-if="log.tenantresponse">Tenant responded</div>
                  </div>
                  <div class="row" style="padding: 0 0 5px 0px">
                    <div class="col-md-12">{{ actProperty.formatUtcDatetimeForDisplay(log.logdate) }}</div>
                  </div>
                  <!--div
                    class="row"
                    style="padding: 0 0 5px 0px"
                    v-if="
                      target === 'Tenant' &&
                      $logindex === emaillogs.length - 1 &&
                      booking.emaillogs.tenantconfirmationreceiveddate
                    "
                  >
                    <div class="col-md-12">
                      <i
                        class="fa fa-check-circle text-success"
                        aria-hidden="true"
                      >
                      </i>
                      Tenant responded on
                      {{
                        actProperty.formatDatetimeForDisplay(
                          booking.emaillogs.tenantconfirmationreceiveddate
                        )
                      }}
                    </div>
                  </div-->
                  <div class="row bottom-border"></div>
                </div>
              </div>
            </div>
            <div
              class="col-md-8 vertical-scroll mt-auto mb-auto tenantresponsecontainer text-center"
              v-if="current.tenantresponse"
              style="border-left: 1px solid #9b9b9b;"
            >
              <div class="tenantresponse" style="margin-top: 100px;">Tenant has selected <b>{{ current.responsedescription ?  current.responsedescription : getButtonLabel(current.tenantresponse) }}</b> option from email</div> 
              <div v-if="current.responseemailaddress" class="tenantresponse">The response came from <b>{{ current.responseemailaddress }}</b></div> 
            </div>
            <div v-else
              class="col-md-8 vertical-scroll"
              style="border-left: 1px solid #9b9b9b"
            >
              <div
                class="row log-element"
                v-if="current.toaddresses && current.toaddresses.length"
              >
                <label class="col-md-2 text-secondary">To</label>
                <div class="col-md-10">
                  <div class="row multiselecttags" style="padding: 0 0 0 15px">
                    <span
                      class="multiselecttag"
                      v-for="(
                        selectedemail, $selectedtoindex
                      ) in current.toaddresses"
                      :key="$selectedtoindex"
                      >{{ selectedemail }}</span
                    >
                  </div>
                </div>
              </div>
              <div
                class="row log-element"
                v-if="current.ccaddresses && current.ccaddresses.length"
              >
                <label class="col-md-2 text-secondary">CC</label>
                <div class="col-md-10">
                  <div class="row multiselecttags" style="padding: 0 0 0 15px">
                    <span
                      class="multiselecttag"
                      v-for="(
                        selectedcc, $selectedccindex
                      ) in current.ccaddresses"
                      :key="$selectedccindex"
                      >{{ selectedcc }}</span
                    >
                  </div>
                </div>
              </div>
              <div
                class="row log-element"
                v-if="current.bccaddresses && current.bccaddresses.length"
              >
                <label class="col-md-2 text-secondary">BCC</label>
                <div class="col-md-10">
                  <div class="row multiselecttags" style="padding: 0 0 0 15px">
                    <span
                      class="multiselecttag"
                      v-for="(
                        selectedbcc, $selectedbccindex
                      ) in current.bccaddresses"
                      :key="$selectedbccindex"
                      >{{ selectedbcc }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="row log-element" v-if="current.subject">
                <label class="col-md-2 text-secondary">Subject</label>
                <div class="col-md-10">
                  {{ current.subject }}
                </div>
              </div>
              <div class="row mt-3" v-if="emailAttachmentsFlag && current?.attachments?.length > 0">
                <label class="col-md-2 col-form-label text-secondary">Attachments</label>
                <div class="col-md-10">
                  <ul class="list-unstyled">
                    <li v-for="(attachment, index) in current?.attachments" :key="index">
                      <a :href="attachment.path" target="_blank" class="attachment-link">
                        <i class="fas fa-paperclip mr-2"></i>
                        <span>{{ attachment.filename }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="row" v-if="current.message">
                <label class="col-md-2 text-secondary">Message</label>
                <div class="col-md-10" v-html="current.message"></div>
              </div>
            </div>
            
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary mr-auto"
            @click.prevent="refresh()"
          >
            Refresh
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary"
            data-dismiss="modal"
            @click.prevent="hide()"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import _get from "lodash/get";
import moment from "moment-timezone";
import { computed, ref, inject, onMounted, defineProps,defineExpose } from 'vue';
import { useStore } from 'vuex';
import { Booking, EmailLogs, EmailLog } from "@/models";

const props = defineProps<{
  name: string;
  title: string;
  target: string;
  chronorder?: string;
}>();

const store = useStore();
const actProperty: any = inject('actProperty');
const previousbooking = ref<Booking | undefined>(undefined);

const booking = computed(() => store.getters['diary/booking']);
const current = computed(() => store.getters['diary/currentlog']);
const emailAttachmentsFlag = computed(() => {
  if (current.value && Array.isArray(current.value.attachments)) {
        return current.value.attachments.some(attachment => 'path' in attachment);
    }
    return false;
});


const getEmailLogs = async (bookingid: string): Promise<EmailLogs> => {
  return store.dispatch('diary/getEmailLogs', bookingid);
};

const getPreviousBooking = async (bookingid: string): Promise<Booking> => {
  return store.dispatch('property/getPreviousBooking', bookingid);
};
const setCurrentEmailLog = (log: EmailLog): void => {
  store.commit('diary/setCurrentEmailLog', log);
};

const init = async () => {
  if (booking.value.id) {
    previousbooking.value = await getPreviousBooking(booking.value.id);
  }
  if(emaillogs.value.length > 0){
    setSelected(emaillogs.value[0]);
  }
};

const setSelected = (value: EmailLog) => {
  setCurrentEmailLog(value);
};


const refresh = () => {
  getEmailLogs(booking.value.id).then(() => {
    var container = ($("#emaillogs-scroller") as any);
    if (container) container.scrollTop = container.scrollHeight;
  });
};

const emaillogs = computed(() => {
  let logs: EmailLog[] = [];
  let previouslogs: EmailLog[] = [];
  if (props.target === "Tenant") {
    logs = booking.value.emaillogs.tenantemaillogs;
    if(props.chronorder === "Issue Report") {
      logs = booking.value.emaillogs.issuereporttenantlogs;
    }
  } else if (props.target === "Landlord") {
    logs = booking.value.emaillogs.landlordemaillogs;
    if(props.chronorder === "Issue Report") {
      logs = booking.value.emaillogs.issuereportlandlordlogs;
    }
  } else if ((props.target === 'Managed Client' || props.target === 'Non-Managed Client') && props.chronorder === 'Issue Report') {
    logs = [...booking.value.emaillogs.issuereportclientlogs];
  } else if ((props.target === 'Managed Client' || props.target === 'Non-Managed Client') && props.chronorder === 'Correspondance/Feedback to Client') {
    logs = [...booking.value.emaillogs.feedbackemaillogs];
    if(previousbooking.value?.id && previousbooking.value?.emaillogs?.feedbackemaillogs?.length > 0) {
      previouslogs = [...previousbooking.value.emaillogs.feedbackemaillogs];
      previouslogs.forEach((l: EmailLog) => l.bookingid = previousbooking.value!.id);
    }
  } else if (props.target === "Client") {
    logs = booking.value.emaillogs.clientemaillogs;
    if(props.chronorder === "Issue Report") {
      logs = booking.value.emaillogs.issuereportclientlogs;
    }
  } else if (props.target === "Data Entry") {
    logs = booking.value.emaillogs.dataentryemaillogs;
  } else if (props.target === "Invoice") {
    logs = booking.value.emaillogs.issuereportinvoicelogs;
  }
  logs = sortLogs(logs);
  if(previouslogs.length) {
    previouslogs = sortLogs(previouslogs);
    logs = [...previouslogs, ...logs];
  }
  return logs;
});

const sortLogs = (logs: EmailLog[]): EmailLog[] => {
  return logs.sort((l1: EmailLog, l2: EmailLog) => {
    if (moment.utc(l1.logdate).isAfter(moment.utc(l2.logdate))) {
      return 1;
    } else if (moment.utc(l1.logdate).isBefore(moment.utc(l2.logdate))) {
      return -1;
    } else {
      return 0;
    }
  });
}

const shortSubject = (value: string) => {
  if (value && value.length > 50) return value.substring(0, 50) + "..";
  else return value;
}

const getButtonLabel = (val: string) => {
  let label = val;
  if(val === 'yes') label = 'Yes';
  else if(val === 'no') label = 'No';
  else if(val === 'callback') label = 'Call Me';
  else if(val === 'option1') label = 'Option 1';
  else if(val === 'option2') label = 'Option 2';
  else if(val === 'option3') label = 'Option 3';
  else if(val === 'option4') label = 'Option 4';
  return label;
}

const show = () => {
  if ($(`#${props.name}` + 'Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.name + 'Backdrop"></div>');
    $('body').append(backdropDiv);
    $(`#${props.name}`).show();
  }
}
const hide = () => {
  if ($(`#${props.name}`).length > 0) {
    $(`#${props.name}` + 'Backdrop').remove();
    $(`#${props.name}`).hide();
  }
};
defineExpose({init, show, hide });
</script>


<style scoped lang="scss">
.log-element {
  margin-bottom: 5px;
}
.email-content {
  background-color: transparent;
  border: 0px;
  border-color: transparent;
}

.vertical-scroll {
  max-height: 50vh;
  overflow-y: auto;
}

.bottom-border {
  border-bottom: 1px dotted #cacaca;
}

.selected-log {
  color: #007bff !important;
  background-color: #e9e9e9;
}

.multiselecttag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 15px;
  margin-right: 10px;
  color: rgb(255, 255, 255);
  line-height: 1;
  background: #cacaca;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.multiselecttags {
  display: block;
  font-size: 14px;
}

.tenantresponsecontainer {
  min-height: 466.5px;
}
.tenantresponse {
  font-size: 150%;
}
.attachment-link {
  display: flex;
  align-items: center;
  padding: 5px 0;
  color: #007bff; /* Bootstrap primary color, or choose your own */
  text-decoration: none;
}

.attachment-link:hover {
  color: #0056b3; /* Darker shade for hover state */
}

.attachment-link i.fas {
  margin-right: 10px;
  font-size: 1.2em; /* Slightly larger icon */
}
</style>

<style lang="scss">
</style>