import _ from 'lodash';

import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { DictionaryState } from './types';
import {
  Dictionary,
  DictionaryConditionMatrix,
  DictionaryItem,
  DictionaryType,
  DictionarySection,
  DictionaryRoom,
  DictionaryReportType,
  SystemProperty
} from '@/models';

/**
 * Getters
 *
 * Vuex allows us to define "getters" in the store.
 * - You can think of them as computed properties for stores.
 * - Like computed properties, a getter's result is cached based on its dependencies,
 *   and will only re-evaluate when some of its dependencies have changed.
 */
export const getters: GetterTree<DictionaryState, RootState> = {

  /**
   * The updates which have been made since creating/loading/last-saving the current report
   * 
   * @param {DictionaryState} state string
   * @returns void
   */
  updates: (state: DictionaryState): string[] => state.updates,

  /**
   * The current Dictionary, populated by GET {API_URL}/dictionary
   * 
   * @param {DictionaryState} Dictionary
   * @returns void
   */
  current: (state: DictionaryState): Dictionary => state.current,

  /**
   * Is the Dictionary Empty?
   * 
   * @param {DictionaryState} state boolean
   * @returns populated dictionary state 
   */
  isEmpty: (state: DictionaryState): boolean => {
    return state.current.rooms.length === 0 && state.current.sections.length === 0;
  },

  /**
   * The updates which have been made since creating/loading/last-saving the current report
   * 
   * @param {DictionaryState} state boolean
   * @returns void
   */
  hasUnsavedChanges: (state: DictionaryState): boolean => state.updates.length > 0,

  /**
   * Has Active Section?
   * 
   * @param {DictionaryState} state boolean
   * @returns void
   */
  hasActiveSection: (state: DictionaryState): boolean => state.activeSection.slug.length > 0,

  /**
   * Has Active Type?
   * 
   * @param {DictionaryState} state boolean
   * @returns void
   */
  hasActiveType: (state: DictionaryState): boolean => state.activeType.slug.length > 0,

  /**
   * Has Active Items?
   * 
   * @param {DictionaryState} state boolean
   * @returns void
   */
  hasActiveItem: (state: DictionaryState): boolean => state.activeItem.slug.length > 0,

  /**
   * Has Active Room
   * 
   * @param {DictionaryState} state boolean
   * @returns void
   */
  hasActiveRoom: (state: DictionaryState): boolean => state.activeRoom.slug.length > 0,

  /**
   * Has Active Room Category?
   * 
   * @param {DictionaryState} state boolean
   * @returns void
   */
  hasActiveRoomCategory: (state: DictionaryState): boolean => state.activeRoomCategory > 0,

  /**
   * Get all Dictionary Sections
   * 
   * @param {DictionaryState} state
   * @returns Dictionary sections
   */
  allSections: (state: DictionaryState): DictionarySection[] => {
    return state.current.sections;
  },

  /**
   * Get all Dictionary Types
   * 
   * @param {DictionaryState} state 
   * @param getters 
   * @returns Dictionary section types
   */
  allTypes: (state: DictionaryState, getters: any): DictionaryType[] => {
    return getters.allSections.flatMap((section: DictionarySection) => {
      if (_.isUndefined(section.types)) {
        return null;
      }
      return section.types;
    }).filter(Boolean);
  },

  /**
   * Get current Dictionary Types
   * 
   * @param {DictionaryState} state 
   * @param getters 
   * @returns Dictionary section types
   */
  currentTypes: (state: DictionaryState, getters: any): DictionaryType[] => {
    return getters.allSections.flatMap((section: DictionarySection) => {
      if (_.isUndefined(section.types)) {
        return null;
      }
      if (state.activeSection.slug !== section.slug) {
        return null;
      }
      return section.types;
    }).filter(Boolean);
  },

  /**
   * Get all Dictionary Types, sorted alphabetically
   * 
   * @param {DictionaryState} state 
   * @param getters 
   * @returns Sorted Dictionary types
   */
  allTypesSorted: (state: DictionaryState, getters: any): DictionaryType[] => {
    return _.orderBy(getters.allTypes, ['name'], ['asc']);
  },

  /**
   * Get current Dictionary Types, sorted alphabetically
   * 
   * @param {DictionaryState} state 
   * @param getters 
   * @returns Sorted Dictionary types
   */
  currentTypesSorted: (state: DictionaryState, getters: any): DictionaryType[] => {
    return _.orderBy(getters.currentTypes, ['name'], ['asc']);
  },

  /**
   * Get all Dictionary Items
   * 
   * @param {DictionaryState} state 
   * @param getters 
   * @returns Dictionary items
   */
  allItems: (state: DictionaryState, getters: any): DictionaryItem[] => {
    return getters.allTypes.flatMap((type: DictionaryType) => {
      if (_.isUndefined(type.items)) {
        return null;
      }
      return type.items;
    }).filter(Boolean);
  },

  /**
   * Get current Dictionary Items
   * 
   * @param {DictionaryState} state 
   * @param getters 
   * @returns Dictionary items
   */
  currentItems: (state: DictionaryState, getters: any): DictionaryItem[] => {
    return getters.currentTypes.flatMap((type: DictionaryType) => {
      if (_.isUndefined(type.items)) {
        return null;
      }
      if (state.activeType.slug !== type.slug) {
        return null;
      }
      return type.items;
    }).filter(Boolean);
  },

  /**
   * Get all Dictionary Items, sorted alphabetically
   * 
   * @param {DictionaryState} state 
   * @param getters 
   * @returns Sort all Dictionary types
   */
  allItemsSorted: (state: DictionaryState, getters: any): DictionaryItem[] => {
    return _.orderBy(getters.allItems, ['name'], ['asc']);
  },

  /**
   * Get current Dictionary Items, sorted alphabetically
   * 
   * @param {DictionaryState} state 
   * @param getters 
   * @returns Sort current Dictionary types
   */
  currentItemsSorted: (state: DictionaryState, getters: any): DictionaryItem[] => {
    return _.orderBy(getters.currentItems, ['name'], ['asc']);
  },

  /**
   * Get Dictionary "Misc" Section
   * 
   * @param {DictionaryState} state 
   * @param getters 
   * @returns Dictionary "Misc" Section
   */
  miscSection: (state: DictionaryState, getters: any): DictionarySection => {
    const section = _.find(getters.allSections, (section: DictionarySection) => section.name === 'Misc');
    if (_.isUndefined(section)) {
      return new DictionarySection;
    }
    return section;
  },

  /**
   * Get Dictionary "Misc" Type
   * 
   * @param {DictionaryState} state 
   * @param getters 
   * @returns Dictionary "Misc" Type
   */
  miscType: (state: DictionaryState, getters: any): DictionaryType => {
    const type = _.find(getters.miscSection.types, (type: DictionaryType) => type.name === 'Misc');
    if (_.isUndefined(type)) {
      return new DictionaryType;
    }
    return type;
  },

  /**
   * Get Dictionary "Misc" Item
   * 
   * @param {DictionaryState} state 
   * @param getters 
   * @returns Dictionary "Misc" Item
   */
  miscItem: (state: DictionaryState, getters: any): DictionaryItem => {
    const item = _.find(getters.miscType.items, (item: DictionaryItem) => item.name === 'Misc');
    if (_.isUndefined(item)) {
      return new DictionaryItem;
    }
    return item;
  },

  /**
   * Room Options
   * - for Multiselect, and the Templates <table>
   * 
   * @param {DictionaryState} state 
   * @returns Rooms sorted by priority
   */
  roomsByPriority: (state: DictionaryState): DictionaryRoom[] => {
    if (!state.current.rooms) return [];

    return _.sortBy(state.current.rooms, ['priority', 'name']);
  },

  /**
   * All Colours
   * - for Multiselect
   * 
   * @param {DictionaryState} state 
   * @param getters 
   * @returns Get all colours
   */
  allColours: (state: DictionaryState, getters: any): string[] => {
    const colours: string[] = getters.allItems.flatMap((item: DictionaryItem) => {
      return _.get(item, 'colours', []);
    });

    // Order alphabetically
    return _.orderBy(Array.from(new Set(colours)), (text) => text.toLowerCase(), ['asc']);
  },

  /**
   * Get "Misc" Item Colours
   * - for Multiselect
   * 
   * @param {DictionaryState} state 
   * @param getters 
   * @returns Ordered "Misc" colours
   */
  miscItemColours: (state: DictionaryState, getters: any): string[] => {
    const colours: string[] = getters.miscItem.colours;

    // Order alphabetically
    return _.orderBy(Array.from(new Set(colours)), (text) => text.toLowerCase(), ['asc']);
  },

  /**
   * All Conditions (Additional)
   * - for Multiselect
   * - "Conditions (Additional)" are also used to populate the Condition Matrix dropdowns
   * 
   * @param {DictionaryState} state 
   * @param getters 
   * @returns Get all conditions
   */
  allConditions: (state: DictionaryState, getters: any): string[] => {
    const conditions: string[] = getters.allItems.flatMap((item: DictionaryItem) => {
      return _.get(item.conditions, 'additional', []);
    });

    // Order alphabetically
    return _.orderBy(Array.from(new Set(conditions)), (text) => text.toLowerCase(), ['asc']);
  },

  /**
   * "Misc" Item Conditions (Additional)
   * - for Multiselect
   * - "Conditions (Additional)" are also used to populate the Condition Matrix dropdowns
   * 
   * @param {DictionaryState} state 
   * @param getters 
   * @returns additional misc condition notes
   */
  miscItemConditions: (state: DictionaryState, getters: any): string[] => {
    // (these have been ordered alphabetically by the mutation)
    return getters.miscItem.conditions.additional;
  },

  /**
   * All Constituents
   * - for Multiselect
   * 
   * @param {DictionaryState} state 
   * @param getters 
   * @returns All Constituents
   */
  allConstituents: (state: DictionaryState, getters: any): string[] => {
    const constituents: string[] = getters.allItems.flatMap((item: DictionaryItem) => {
      return _.get(item, 'constituents', []);
    });

    // Order alphabetically
    return _.orderBy(Array.from(new Set(constituents)), (text) => text.toLowerCase(), ['asc']);
  },

  /**
   * Get "Misc" Item Constituents
   * - for Multiselect
   * 
   * @param {DictionaryState} state 
   * @param getters 
   * @returns additional misc constituents
   */
  miscItemConstituents: (state: DictionaryState, getters: any): string[] => {
    const constituents: string[] = getters.miscItem.constituents;

    // Order alphabetically
    return _.orderBy(Array.from(new Set(constituents)), (text) => text.toLowerCase(), ['asc']);
  },

  /**
   * All Makes
   * - for Multiselect
   * 
   * @param {DictionaryState} state 
   * @param getters 
   * @returns All Makes
   */
  allMakes: (state: DictionaryState, getters: any): string[] => {
    const makes: string[] = getters.allItems.flatMap((item: DictionaryItem) => {
      return _.get(item, 'makes', []);
    });

    // Order alphabetically
    return _.orderBy(Array.from(new Set(makes)), (text) => text.toLowerCase(), ['asc']);
  },

  /**
   * Get "Misc" Item Makes
   * - for Multiselect
   * 
   * @param {DictionaryState} state 
   * @param getters 
   * @returns additional misc Item Makes
   */
  miscItemMakes: (state: DictionaryState, getters: any): string[] => {
    const makes: string[] = getters.miscItem.makes;

    // Order alphabetically
    return _.orderBy(Array.from(new Set(makes)), (text) => text.toLowerCase(), ['asc']);
  },

  /**
   * Get Acitve Condition Matrix
   * 
   * @param {DictionaryState} state 
   * @returns void
   */
  activeConditionMatrix: (state: DictionaryState): DictionaryConditionMatrix[] => state.activeItem.conditions.matrix,

  /**
   * Get Acitve Report Type
   * 
   * @param {DictionaryState} state 
   * @returns ReportType
   */
  activeReportType: (state: DictionaryState): DictionaryReportType => state.activeReportType,

  /**
   * Get All Report Type
   * 
   * @param {DictionaryState} state 
   * @returns ReportType
   */
  reportTypes: (state: DictionaryState): DictionaryReportType[] | undefined => state.current.reporttypes,

  /**
   * Get All system properties
   * 
   * @param {DictionaryState} state 
   * @returns SystemProperty[]
   */
  systemproperties: (state: DictionaryState): SystemProperty[] | undefined => state.current.systemproperties,

  /**
   * Get current system property
   * 
   * @param {DictionaryState} state 
   * @returns SystemProperty
   */
  currentsystemproperty: (state: DictionaryState): SystemProperty | undefined => state.currentsystemproperty,
};
