import { Module } from 'vuex';
import { RootState } from '../types';
import { FeedbackState } from './types';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

import { OrderedItem, Comments } from '@/models';

// Default state
export const state: FeedbackState = {
  shareholdertype: "",
  landlordcommentsname: "",
  landlordcommentsemail: "",
  tenantcommentsname: "",
  tenantcommentsemail: "",
  comments: new Comments(),
  currentItem: new OrderedItem(),
  commentedItems: [],
  step: "",
};

// Use namespaced Vuex modules
const namespaced: boolean = true;

// Module export
const feedback: Module<FeedbackState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};

export default feedback;
