<template>
  <div v-if="showSACI()" class="custom-control custom-switch">
    <input type="checkbox" class="custom-control-input" :id="elementId" v-model="sameAsCheckinActive">
    <label class="custom-control-label text-dark" :class="labelCssClass()" :for="elementId">
      Same as Check-In
    </label>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, ref } from 'vue';
import { useStore } from 'vuex';
import _get from 'lodash/get';
import { Condition } from '@/models';
import { ReportsState } from '@/store/reports/types';

// Injects
const roomIndex = inject<number>('roomIndex')!;
const sectionIndex = inject<number>('sectionIndex')!;
const typeIndex = inject<number>('typeIndex')!;
const itemIndex = inject<number>('itemIndex')!;

const store = useStore();
const reportsState = computed(() => store.state.reports as ReportsState);

// Computed properties for elementId and path
const elementId = computed(() => `saci-${roomIndex}-${sectionIndex}-${typeIndex}-${itemIndex}`);
const path = computed(() => `rooms[${roomIndex}].sections[${sectionIndex}].types[${typeIndex}].items[${itemIndex}].condition.out.sameAsCheckin.active`);

// Computed property with getter and setter for sameAsCheckinActive
const sameAsCheckinActive = computed({
  get: () => _get(reportsState.value.current, path.value, false),
  set: (value: boolean) => setReportDeep({ path: path.value, data: value }),
});

// Methods converted to regular functions
const setReportDeep = async (payload: { path: string; data: any }) => {
  await store.dispatch('reports/setReportDeep', payload);
};

const showSACI = () => {
  const slug = _get(reportsState.value, `current.rooms[${roomIndex}].sections[${sectionIndex}].slug`);
  return slug !== 'overview';
};

const labelCssClass = () => {
  return sameAsCheckinActive.value ? '' : 'text-muted';
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.custom-switch {
  margin: 0.4em 0;
}
</style>
