<template>
  <div class="inspector">
    <div class="row">
      <h1 class="col-md-6">
        <img v-if="inspector != undefined && inspector.picture &&
          inspector.picture.length &&
          inspector.picture[0].src
          " :src="actProperty.s3Origin(inspector.picture[0].src)" class="avatar" />
        <img v-else src="@/assets/images/avatar.jpg" class="avatar" />
        <span v-if="isNewInspector()">Add Inspector</span>
        <span v-else>{{ inspector.name }}</span>
      </h1>
      <div class="col-sm-6 text-right">
        <span v-if="uploading"><i class="fas fa-spinner fa-spin"></i> Uploading google
          calendar</span>
        <label v-else>
          <span class="btn btn-sm btn-outline-secondary document-upload-button">
            <div><i class="fas fa-file-upload"></i> Upload google calendar</div>
          </span>
          <input type="file" accept="application/ics" ref="file" v-on:change="onFileInputChange" />
        </label>
      </div>
    </div>

    <!-- Tabs -->
    <div>
      <ul class="nav nav-tabs mb-3" role="tablist">
        <li class="nav-item">
          <router-link to="address" class="nav-link" active-class="active">
            Address
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="ratecard" class="nav-link" active-class="active">
            Rate card
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="preferredlocations" class="nav-link" active-class="active">
            Preferred locations
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="preferredclients" class="nav-link" active-class="active">
            Preferred clients
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="excludedclients" class="nav-link" active-class="active">
            Excluded clients
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="scheduling" class="nav-link" active-class="active">
            Scheduling
          </router-link>
        </li>
      </ul>
    </div>

    <!-- Tab content (child routes) -->
    <router-view :key="$route.name" style="padding-bottom: 60px" />

    <!-- Status Bar (Bottom navbar) -->
    <nav class="navbar fixed-bottom navbar-dark bg-primary">
      <div class="container-fluid">
        <!-- Status Bar Actions -->
        <div id="status-bar-actions" style="margin-left: auto">
          <div class="text-light">
            <span v-if="hasUnsavedChanges">You have unsaved changes</span>
            <span v-else class="text-muted">No unsaved changes</span>
            <div class="btn-group" role="group" aria-label="Secondary Actions">
              <button class="btn btn-outline-light" :class="{ disabled: !hasUnsavedChanges }" @click.prevent="cancel()" :disabled="!hasUnsavedChanges">
                <i class="fas fa-ban"></i> Cancel
              </button>
            </div>
            <div class="btn-group" role="group" aria-label="Primary Actions">
              <button class="btn btn-light" :class="{ disabled: !hasUnsavedChanges }" @click.prevent="saveAll()"
                :disabled="isSaving || !hasUnsavedChanges">
                <span v-if="isSaving" class="spinner-border spinner-border-sm">
                  <span class="sr-only">Loading...</span>
                </span>
                <i v-else class="fas fa-save"></i>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, inject } from 'vue';
import { useStore } from 'vuex';
import { Inspector } from '@/models'; // Adjust the path accordingly
import { useRoute, useRouter } from 'vue-router';
import _ from 'lodash';
import { useToast } from "vue-toastification";
import sanitize from 'sanitize-filename';
import moment from 'moment-timezone';
const ical = require('node-ical');
const actProperty: any = inject('actProperty');
import { emitEvent } from '@/eventBus';

const router = useRouter();
const route = useRoute();
const store = useStore();
const toasted = useToast();
const file = ref(null);

const unsavedChanges = ref(false);
const uploading = ref(false);
const isSaving = ref(false);
const toast = useToast();
const hasUnsavedChanges = computed(() => store.getters['inspectors/hasUnsavedChanges']);
const inspector = computed(() => store.getters['inspectors/current']);
const getDefaultInspector = async () => {
  return store.dispatch('inspectors/getDefaultInspector');
};

const getInspector = async (id: string) => {
  return store.dispatch('inspectors/getInspector', id);
};

const addInspector = async (inspector: Inspector) => {
  return store.dispatch('inspectors/addInspector', inspector);
};

const updateInspector = async (inspector: Inspector) => {
  return store.dispatch('inspectors/updateInspector', inspector);
};

const setInspector = async (inspector: Inspector) => {
  return store.commit('inspectors/setInspector', inspector);
};

const importGoogleBookings = async (bookings: any[]) => {
  return store.dispatch('diary/importGoogleBookings', bookings);
};

const resetUnsavedChanges = (): void => {
  store.commit('inspectors/resetUnsavedChanges');
};

const isNewInspector = () => {
  return route.params.id == "new";
};

const onFileInputChange = (event: Event) => {
  const files = _.get(file.value, "files", null);
  uploadFiles(files);
};
const setUnsavedChanges = (value: boolean) => {
  unsavedChanges.value = value;
};

const uploadFiles = async (files: FileList | null) => {
  try {
    if (null === files || 0 === files.length) {
      return;
    }

    uploading.value = true;
    for (let i = 0; i < files.length; i++) {
      let file: File | null = files.item(i);

      if (file === null) {
        continue;
      }

      const { name, type } = file;

      if (type !== 'text/calendar') {
        toast.error(`${sanitize(name)} is not an ICS file.`);
        continue;
      }

      const timezone = process.env.VUE_APP_TIMEZONE || 'Europe/London';

      const events = await ical.sync.parseICS(await file.text());
      let bookings: any[] = [];

      // const marchStart = moment('2024-03-01T00:00:00.000Z');
      // const marchEnd = moment('2024-03-31T23:59:59.000Z');
      for (var key of Object.keys(events)) {
        let e = events[key];
        if (e.type === 'VEVENT' && e.uid && !e.rrule) {
          const booking: any = {
            invoices: [],
            inspector: { id: inspector.value.id },
            startdate: moment.tz(e.start, timezone).format('YYYY-MM-DD[T]HH:mm') + ':00.000Z',
            enddate: moment.tz(e.end, timezone).format('YYYY-MM-DD[T]HH:mm') + ':00.000Z',
            googleid: e.uid,
            description: e.description,
            summary: e.summary,
            location: e.location,
            recurrenceRule: e.rrule?.toString(),
          };
          // A temperary fix for last mopup
          // Only push if the startdate is between 1st and 31st March
          // const bm = moment(booking.startdate);
          // if(bm.isAfter(marchStart) && bm.isBefore(marchEnd)) {
          //   bookings.push(booking);
          // }
          bookings.push(booking);
          if (bookings.length >= 100) {
            await importGoogleBookings(bookings);
            bookings = [];
          }
        }
      }

      if (bookings.length) {
        await importGoogleBookings(bookings);
        bookings = [];
      }
    }
    uploading.value = false;
  } catch (error) {
    uploading.value = false;
    toast.error(error);
  }  
};

onMounted(async () => {
  if (isNewInspector()) {
    setInspector(new Inspector());
    return;
  }
  try {

    const values = await Promise.all([getDefaultInspector(), getInspector(route.params.id as string)]);
    const inspector: Inspector = values[1];

    if (inspector === undefined) {
      toast.error(`Could not find inspector with ID ${route.params.id as string}`);
      router.push({ name: 'inspectorlist' });
    }

    const defaultinspector: Inspector = values[0];

    if (isNewInspector()) {
      const defaultinsp: Inspector = new Inspector(defaultinspector);
      defaultinsp.id = '';
      defaultinsp.name = '';
      setInspector(defaultinsp);
      return;
    }
    emitEvent('inspectorLoadedOnSchedulingPage', inspector);

  } catch (err) {
    toast.error(err);
  }
});

const saveAll = async () => {
  if (isNewInspector()) {
    isSaving.value = true;
    try {
      const newInspector = await addInspector(inspector.value);
      isSaving.value = false;
      toasted.success('Saved');
      router.push(`/inspectors/${newInspector.id}`);
      setUnsavedChanges(false);
    } catch (err) {
      isSaving.value = false;
      toasted.error(err);
    }
  } else {
    isSaving.value = true;
    try {
      await updateInspector(inspector.value);
      isSaving.value = false;
      toasted.success('Updated');
      setUnsavedChanges(false);
    } catch (err) {
      isSaving.value = false;
      toasted.error(err);
    }
  }
};
const cancel = () => {
  resetUnsavedChanges();
  router.push({name:'inspectorlist'});
};
</script>


<style scoped lang="scss">
@import "@/assets/sass/bootstrap/_variables.scss";

.nav-item {
  a {
    cursor: pointer;
  }
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.drag * {
  pointer-events: none;
}

.file-upload {
  border: 2px dashed rgba(0, 0, 0, 0);
  border-radius: 0.2rem;
  box-sizing: border-box;
  width: 100%;

  &.drag {
    background-color: $info-semi-opaque;
    border-color: $info;
  }
}

label {
  margin-bottom: 0;

  input[type="file"] {
    display: none;
  }
}
</style>
