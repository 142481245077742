<template>
  <div>
    <header>
      <div class="row">
        <div class="col-sm-6">
          <h1>Preferred clients</h1>
        </div>
        <div class="col-sm-6 text-right">
          <button class="btn btn-outline-primary" data-toggle="modal" data-target="#add-preferredclient-modal">
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
    </header>

    <div v-if="!inspector.preferredclients.length && !hasBlockingRequests" class="alert alert-warning text-center">
      <h2 class="mt-1 mb-2">No preferred clients found</h2>
      <p class="mb-1">for {{ inspector.name }}, please add</p>
    </div>

    <table v-else class="table table-fixed">
      <thead>
        <tr>
          <th class="align-middle">Company</th>
          <th class="align-middle">Branch</th>
          <th class="align-middle">Region</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(customer, $customerindex) in inspector.preferredclients" :key="$customerindex">
          <td>{{ customer.companyName }}</td>
          <td>{{ customer.branchName }}</td>
          <td>{{ getRegion(customer.office) }}</td>
          <td class="text-right">
            <ul class="list-unstyled mb-0">
              <li class="list-item" @click.stop>
                <button class="btn btn-outline-danger" @click="remove(customer)">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>

    <AddPreferredClientModal />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import AddPreferredClientModal from '@/components/modals/AddPreferredClientModal.vue';
import { Inspector, Office, Customer } from "@/models";

const hasBlockingRequests = computed(() => store.getters['app/hasBlockingRequests']);
const inspector = computed(() => store.getters['inspectors/current']);
const offices = computed(() => store.getters['offices/getOffices']);

const removePreferredClient = (client: Customer) => store.dispatch("inspectors/removePreferredClient", client);
const remove = (customer: Customer) => {
  removePreferredClient(customer);
}


const store = useStore();


// Create a function to get the region by ID
const getRegion = (id: string) => {
  const office = offices.value.find((o: Office) => o.id === id);
  return office ? office.name : '';
};
</script>
