import { Module } from 'vuex';
import { RootState } from '../types';
import { DictionaryState } from './types';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

import {
  Dictionary,
  DictionaryConditionMatrix,
  DictionaryItem,
  DictionaryReportType,
  DictionaryRoom,
  DictionarySection,
  DictionaryType,
  SystemProperty,
} from '@/models';

/**
 * Default state
 */
export const state: DictionaryState = {
  activeSection: new DictionarySection,
  activeType: new DictionaryType,
  activeItem: new DictionaryItem,
  activeRoom: new DictionaryRoom,
  activeRoomCategory: 0,
  activeReportType: new DictionaryReportType,
  current: new Dictionary,
  clipboardColours: [],
  clipboardConditions: [],
  clipboardConditionMatrix: [
    new DictionaryConditionMatrix,
    new DictionaryConditionMatrix,
    new DictionaryConditionMatrix,
  ],
  clipboardConstituents: [],
  clipboardMakes: [],
  currentsystemproperty: new SystemProperty,
  updates: [],
};

/**
 * Use namespaced Vuex modules
 */
const namespaced: boolean = true;

/**
 * Module export
 */
const dictionary: Module<DictionaryState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};

export default dictionary;
