import _get from 'lodash/get';

import { Base } from '@/models';
import { toCamelCase, datetimeToUTC } from '@/utilities';

export class ShareholderComments extends Base {
    /**
     * comments stores the text added by the shareholder
     */
    public comments: string;

    /**
     * lastupdated stores the text added by the shareholder
     */
    public updatedAt: string;

    /**
     * approved stores a boolean flag denoting ACT back office approval for the given comment
     */
    public approved: boolean;

    /**
     * flagged stores a boolean value denoting ACT red flagged comment
     */
    public flagged: boolean;

    /**
     * photos stores the uploaded image src and upload timestamp added by the shareholder
     */
    public photos: string[];

    /**
     * Constructor initialises the variables from the class Comments
     * @param data - the data to construct a type with all properties of <Comments>
     */
    public constructor(data?: Partial<ShareholderComments>) {
        super(data);
        data = toCamelCase(data);

        this.comments = _get(data, 'comments', '');
        this.updatedAt = _get(data, 'updatedAt', datetimeToUTC());
        this.approved = _get(data, 'approved', false);
        this.flagged = _get(data, 'flagged', false);
        this.photos = _get(data, 'photos', []);
    }

    public load(data?: Partial<ShareholderComments>) {
        data = toCamelCase(data);

        this.comments = _get(data, 'comments', '');
        this.updatedAt = _get(data, 'updatedAt', datetimeToUTC());
        this.approved = _get(data, 'approved', false);
        this.flagged = _get(data, 'flagged', false);
        this.photos = _get(data, 'photos', []);
    }

}
