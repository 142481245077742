import _get from 'lodash/get';
import _castArray from 'lodash/castArray';
import { copyInto } from '@/utilities';

import { Base, Email, EmailLog } from '@/models';
import { toCamelCase, datetimeToUTC } from '@/utilities';

export class EmailLogs extends Base {
  public clientconfirmationsentdate: string;
  public tenantconfirmationsentdate: string;
  public landlordconfirmationsentdate: string
  public tenantconfirmationreceiveddate: string;
  public tenantconfirmationresponse: string;
  public dataentryconfirmationsentdate: string;
  public dataentryacknowledgementreceiveddate: string;
  public clientemaillogs: EmailLog[];
  public feedbackemaillogs: EmailLog[];
  public tenantemaillogs: EmailLog[];
  public landlordemaillogs: EmailLog[];
  public dataentryemaillogs: EmailLog[];

  public issuereportemailsendtoclientdate: string;
  public issuereportclientlogs: EmailLog[];
  public issuereportemailsendtolandlorddate: string;
  public issuereportlandlordlogs: EmailLog[];
  public issuereportemailsendtotenantdate: string;
  public issuereporttenantlogs: EmailLog[];
  public issuereportinvoicelogs: EmailLog[];

  public invoicesentdate: string;
  public invoicereceiveddate: string;
  public invoiceresponse: string;

  public feedbacksentddate: string;


  public static parse(data: object) {
    const obj = new EmailLogs;
    copyInto(obj, data);

    obj.clientconfirmationsentdate = _get(data, 'clientconfirmationsentdate', '');
    obj.tenantconfirmationsentdate = _get(data, 'tenantconfirmationsentdate', '');
    obj.landlordconfirmationsentdate = _get(data, 'landlordconfirmationsentdate', '');
    obj.tenantconfirmationreceiveddate = _get(data, 'tenantconfirmationreceiveddate', '');
    obj.tenantconfirmationresponse = _get(data, 'tenantconfirmationresponse', '');
    obj.dataentryconfirmationsentdate = _get(data, 'dataentryconfirmationsentdate', '');
    obj.dataentryacknowledgementreceiveddate = _get(data, 'dataentryacknowledgementreceiveddate', '');
    obj.clientemaillogs = _castArray(_get(data, 'clientemaillogs', [])).map((x: Partial<EmailLog>) => new EmailLog(x));
    obj.feedbackemaillogs = _castArray(_get(data, 'feedbackemaillogs', [])).map((x: Partial<EmailLog>) => new EmailLog(x));
    obj.tenantemaillogs = _castArray(_get(data, 'tenantemaillogs', [])).map((x: Partial<EmailLog>) => new EmailLog(x));
    obj.landlordemaillogs = _castArray(_get(data, 'landlordemaillogs', [])).map((x: Partial<EmailLog>) => new EmailLog(x));
    obj.dataentryemaillogs = _castArray(_get(data, 'dataentryemaillogs', [])).map((x: Partial<EmailLog>) => new EmailLog(x));

    obj.issuereportemailsendtoclientdate = _get(data, 'issuereportemailsendtoclientdate', '');
    obj.issuereportclientlogs = _castArray(_get(data, 'issuereportclientlogs', [])).map((x: Partial<EmailLog>) => new EmailLog(x));
    obj.issuereportemailsendtolandlorddate = _get(data, 'issuereportemailsendtolandlorddate', '');
    obj.issuereportlandlordlogs = _castArray(_get(data, 'issuereportlandlordlogs', [])).map((x: Partial<EmailLog>) => new EmailLog(x));
    obj.issuereportemailsendtotenantdate = _get(data, 'issuereportemailsendtotenantdate', '');
    obj.issuereporttenantlogs = _castArray(_get(data, 'issuereporttenantlogs', [])).map((x: Partial<EmailLog>) => new EmailLog(x));
    obj.issuereportinvoicelogs = _castArray(_get(data, 'issuereportinvoicelogs', [])).map((x: Partial<EmailLog>) => new EmailLog(x));

    obj.invoicesentdate = _get(data, 'invoicesentdate', '');
    obj.invoicereceiveddate = _get(data, 'invoicereceiveddate', '');
    obj.invoiceresponse = _get(data, 'invoiceresponse', '');

    obj.feedbacksentddate = _get(data, 'feedbacksentddate', '');

    return obj;
  }

  /**
   * Constructor
   */
  public constructor(data?: Partial<EmailLogs>) {
    super(data);
    data = toCamelCase(data);

    this.clientconfirmationsentdate = _get(data, 'clientconfirmationsentdate', '');
    this.tenantconfirmationsentdate = _get(data, 'tenantconfirmationsentdate', '');
    this.landlordconfirmationsentdate = _get(data, 'landlordconfirmationsentdate', '');
    this.tenantconfirmationreceiveddate = _get(data, 'tenantconfirmationreceiveddate', '');
    this.tenantconfirmationresponse = _get(data, 'tenantconfirmationresponse', '');
    this.dataentryconfirmationsentdate = _get(data, 'dataentryconfirmationsentdate', '');
    this.dataentryacknowledgementreceiveddate = _get(data, 'dataentryacknowledgementreceiveddate', '');
    this.clientemaillogs = _castArray(_get(data, 'clientemaillogs', [])).map((x: Partial<EmailLog>) => new EmailLog(x));
    this.feedbackemaillogs = _castArray(_get(data, 'feedbackemaillogs', [])).map((x: Partial<EmailLog>) => new EmailLog(x));
    this.tenantemaillogs = _castArray(_get(data, 'tenantemaillogs', [])).map((x: Partial<EmailLog>) => new EmailLog(x));
    this.landlordemaillogs = _castArray(_get(data, 'landlordemaillogs', [])).map((x: Partial<EmailLog>) => new EmailLog(x));
    this.dataentryemaillogs = _castArray(_get(data, 'dataentryemaillogs', [])).map((x: Partial<EmailLog>) => new EmailLog(x));

    this.issuereportemailsendtoclientdate = _get(data, 'issuereportemailsendtoclientdate', '');
    this.issuereportclientlogs = _castArray(_get(data, 'issuereportclientlogs', [])).map((x: Partial<EmailLog>) => new EmailLog(x));
    this.issuereportemailsendtolandlorddate = _get(data, 'issuereportemailsendtolandlorddate', '');
    this.issuereportlandlordlogs = _castArray(_get(data, 'issuereportlandlordlogs', [])).map((x: Partial<EmailLog>) => new EmailLog(x));
    this.issuereportemailsendtotenantdate = _get(data, 'issuereportemailsendtotenantdate', '');
    this.issuereporttenantlogs = _castArray(_get(data, 'issuereporttenantlogs', [])).map((x: Partial<EmailLog>) => new EmailLog(x));
    this.issuereportinvoicelogs = _castArray(_get(data, 'issuereportinvoicelogs', [])).map((x: Partial<EmailLog>) => new EmailLog(x));

    this.invoicesentdate = _get(data, 'invoicesentdate', '');
    this.invoicereceiveddate = _get(data, 'invoicereceiveddate', '');
    this.invoiceresponse = _get(data, 'invoiceresponse', '');

    this.feedbacksentddate = _get(data, 'feedbacksentddate', '');

  }
}