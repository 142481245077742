<template>
  <div class="report-section rooms">
    <div
      class="card"
      v-for="(room, $index) in report.rooms"
      :key="room._uuid"
      @click.stop="setActiveCard($index)"
    >
      <report-room
        :room="room"
        :index="$index"
        :opened="
          roomsShowBody[$index] === undefined ? true : roomsShowBody[$index]
        "
        :toggle="true"
        :class="{ activeCard: focused === $index }"
        ref="dynamicRefs"
        @showBody="syncRoomsShowBody"
      />
    </div>

    <!-- Modals -->
    <ReportItemModal />
    <ReportRoomModal />
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, inject, computed } from 'vue';
import { useStore } from 'vuex';
import { Report, Room, CustomerDictionary } from "@/models";
import ReportItemModal from "@/components/modals/ReportItemModal.vue";
import ReportRoomModal from "@/components/modals/ReportRoomModal.vue";
import ReportRoom from "@/components/report/Room.vue";
import { ReportsState } from '@/store/reports/types';
import { useEvent } from '@/eventBus';

// Inject actProperty
const actProperty: any = inject('actProperty');

// Using Composition API
const store = useStore();
const reportsState = computed((): ReportsState => store.state.reports);
const focused = ref(-1);
const roomsShowBody = ref<boolean[]>([]);
const dynamicRefs = ref(null);

const report = computed((): Report => reportsState.value.current);

const getCustomersDictionaryAction = async (payload?: {
  companyname?: string;
  branchname?: string;
  reporttype?: string;
}): Promise<CustomerDictionary> => {
  return store.dispatch('customerdictionary/getCustomerDictionary', payload);
};

onMounted(() => {
  report.value.rooms.forEach((v, index) => {
    roomsShowBody.value[index] = true;
  });
  if (
    report.value &&
    actProperty.isFactoryreport(report.value.type) &&
    report.value.companyName
  ) {
    let cname = report.value.companyName;
    if (report.value.branchName && report.value.branchName != "default")
      cname = report.value.branchName;
    getCustomerDictionary(cname, "default", report.value.type);
  }
});

const getCustomerDictionary = async (
  companyname: any,
  branchname: any,
  reporttype: any
) => {
  if (companyname && companyname === "ACT") companyname = "default";
    getCustomersDictionaryAction({
      companyname,
      branchname,
      reporttype,
    }).catch((err: any) => {
      actProperty.displayError(err);
    });
};

const setActiveCard = (index: any) => {
  focused.value = index;
};

const syncRoomsShowBody = (index: number, showBody: boolean) => {
  const cardsrefArray = (dynamicRefs.value as any[]);
  if (cardsrefArray.length !== roomsShowBody.value.length) {
    // To cope with adding/removing rooms
    // or sometimes mounted won't set up this.roomsShowBody correctly
    const rooms: boolean[] = [];
    for (let i = 0; i < cardsrefArray.length; i++) {
      if (roomsShowBody.value[i] === undefined) {
        rooms[i] = true;
      } else if (i === index) {
        rooms[i] = showBody;
      } else {
        rooms[i] = roomsShowBody.value[i];
      }
    }
    roomsShowBody.value = rooms;
  } else {
    roomsShowBody.value = roomsShowBody.value.map((value, i) => {
      return i === index ? showBody : value;
    });
  }
};

/**
 * Expand all rooms
 */
 const expandAll = (): void => {
  const roomsOpened: boolean[] = [];
  if (dynamicRefs.value) {
    (dynamicRefs.value as any[]).forEach((card, index) => {
      card.showBody = true;
      roomsOpened[index] = true;
    });
  }

  roomsShowBody.value = roomsOpened;
}

/**
 * Collapse all rooms
 */
const collapseAll = (): void => {
  const roomsClosed: boolean[] = [];
  if (dynamicRefs.value) {
    (dynamicRefs.value as any[]).forEach((card, index) => {
      card.showBody = false;
      roomsClosed[index] = false;
    });
  }
  roomsShowBody.value = roomsClosed;
}

useEvent("expandAll", () => {
  expandAll()
});
useEvent("collapseAll", () => {
  collapseAll()
});

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/bootstrap/_variables.scss";

.card {
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.activeCard {
  border-color: #ff0000;
}

.card-header {
  &.room {
    a {
      display: inline-block;
      margin-left: 1rem;
    }
    small {
      font-size: 56%;
    }
  }
}

.btn {
  padding-bottom: 0.2rem;
  &.disabled {
    opacity: 0.35;
  }
}
</style>
