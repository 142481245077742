import { Module } from 'vuex';
import { RootState } from '../types';
import { AuditState } from './types';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

/**
 * Default state
 */
export const state: AuditState = {
  auditentitylist: new Map([
    ['Booking', ['startdate', 'enddate', 'appointmenttime']],
  ]),
};

/**
 * Use namespaced Vuex modules
 */
const namespaced: boolean = true;

/**
 * Module export
 */
const app: Module<AuditState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};

export default app;
