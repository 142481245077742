<template>
  <div class="card bg-light booking-section">
    <h3 class="card-header booking-section-header">Issue Report</h3>
    <div class="card-body">
      <div class="row" style="margin-left: -12px; margin-right: -12px">
        <div class="col-md-3">
          <span class="multiselecttags" v-if="booking.report && booking.report.ref">
            <span class="multiselecttag" :class="{'withoutuserprofile': booking.report.firstexportedby?.toLocaleLowerCase() != booking.inspector?.email.toLocaleLowerCase()}">{{ booking.report.ref }} <i tabindex="1" class="multiselect__tag-icon"
                @click="clearRef"></i></span>
          </span>
          <div v-else style="display: inline-flex;">
            <input type="number" min="0" class="form-control" v-model.lazy="reportref" placeholder="Report ref" :class="{
              outlineerror:
                errorreportref,
            }" />
            <button type="submit" class="btn btn-outline-primary" @click="onSubmit">
              <i class="fas fa-plus"></i>
            </button>
          </div>
        </div>
        <div class="col-md-3">
          <div style="display: inline-flex;">
            <input type="number" min="0" class="form-control" v-model.lazy="subreportref" placeholder="Sub Report ref"
              :class="{
                outlineerror:
                  errorsubreportref,
              }" />
            <button type="submit" class="btn btn-outline-primary" @click="onSubReport">
              <i class="fas fa-plus"></i>
            </button>
          </div>
          <span class="multiselecttags" v-if="booking.subreports">
            <span v-for="(sub, $index) in booking.subreports" :key="$index" class="multiselecttag">{{ sub.ref }} <i
                tabindex="1" class="multiselect__tag-icon" @click="clearsubRef($index)"></i></span>
          </span>
        </div>
        <div class="col-md-2 mt-auto mb-auto">
          <button class="btn btn-sm btn-outline-secondary" @click="showQCRulesDialog">QC Rules</button>
        </div>
        <div class="col-md-2 mt-auto mb-auto">
          <button class="btn btn-sm btn-outline-secondary" style="min-height: 33.2px;">
            <img class="trafficlight" src="~@/assets/images/traffic-red.png" v-if="feedbackstatus === 'red'" />
            <img class="trafficlight" src="~@/assets/images/traffic-green.png" v-else-if="feedbackstatus === 'green'" />
            <img class="trafficlight" src="~@/assets/images/traffic-amber.png" v-else-if="feedbackstatus === 'amber'" />
            Feedback
          </button>
        </div>
        <div class="col-md-2 mt-auto mb-auto">
          <button class="btn btn-sm btn-outline-secondary" style="min-height: 33.2px;">
            <img class="trafficlight" src="~@/assets/images/traffic-red.png" v-if="issuestatus === 'red'" />
            <img class="trafficlight" src="~@/assets/images/traffic-amber.png" v-else-if="issuestatus === 'amber'" />
            <img class="trafficlight" src="~@/assets/images/traffic-green.png" v-else />
            Status
          </button>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-3 mt-auto mb-auto">
          <div class="btn-group" role="group" aria-label="Issue report to client"
            v-if="booking.clientqcrule && booking.clientqcrule.email">
            <button class="btn btn-sm btn-outline-secondary" @click="showEmailClientDialog">
              <img class="trafficlight" src="~@/assets/images/traffic-green.png"
                v-if="emaillogs.issuereportemailsendtoclientdate" />
              <img class="trafficlight" src="~@/assets/images/traffic-red.png" v-else />
              Email to Client
            </button>
            <button class="btn btn-sm btn-outline-secondary" @click="showClientEmailLogsDialog"
              v-if="emaillogs.issuereportclientlogs.length">
              <i class="fa fa-history"></i>
            </button>
          </div>
        </div>
        <div class="col-md-3 mt-auto mb-auto">
          <div class="btn-group" role="group" aria-label="Issue report to landlord"
            v-if="booking.landlordqcrule && booking.landlordqcrule.email">
            <button class="btn btn-sm btn-outline-secondary" @click="showEmailLandlordDialog">
              <img class="trafficlight" src="~@/assets/images/traffic-green.png"
                v-if="emaillogs.issuereportemailsendtolandlorddate" />
              <img class="trafficlight" src="~@/assets/images/traffic-red.png" v-else />
              Email to Landlord
            </button>
            <button class="btn btn-sm btn-outline-secondary" @click="showLandlordEmailLogsDialog"
              v-if="emaillogs.issuereportlandlordlogs.length">
              <i class="fa fa-history"></i>
            </button>
          </div>
        </div>
        <div class="col-md-2 mt-auto mb-auto">
          <div class="btn-group" role="group" aria-label="Issue report to tenant" v-if="tenanttoaddresses.length">
            <button class="btn btn-sm btn-outline-secondary" @click="showEmailTenantDialog">
              <img class="trafficlight" src="~@/assets/images/traffic-green.png"
                v-if="emaillogs.issuereportemailsendtotenantdate" />
              <img class="trafficlight" src="~@/assets/images/traffic-red.png" v-else />
              Email to Tenant
            </button>
            <button class="btn btn-sm btn-outline-secondary" @click="showTenantEmailLogsDialog"
              v-if="emaillogs.issuereporttenantlogs.length">
              <i class="fa fa-history"></i>
            </button>
          </div>
        </div>
        <div class="col-md-2 mt-auto mb-auto">
          <div class="btn-group" role="group" aria-label="Issue report to tenant"
            v-if="booking.invoiceqcrule && booking.invoiceqcrule.email">
            <button class="btn btn-sm btn-outline-secondary" @click="showEmailInvoiceDialog">
              <img class="trafficlight" src="~@/assets/images/traffic-green.png" v-if="emaillogs.invoicesentdate" />
              <img class="trafficlight" src="~@/assets/images/traffic-red.png" v-else />
              Email Invoice
            </button>
            <button class="btn btn-sm btn-outline-secondary" @click="showInvoiceEmailLogsDialog"
              v-if="emaillogs.issuereportinvoicelogs.length">
              <i class="fa fa-history"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <MiscNotesDialog ref="qcRulesDialog" modalid="qcrules" :title="`QC Rules`" :notes="booking.customer.qcrules.misc" />

    <IssueReportEmailModal id="issuetoclient-email-modal" name="issuetoclient-email-modal" ref="clientEmailModal"
      title="Issue report to client" :reporttype="reporttype" :target="clienttarget" chronorder="Issue Report"
      :customer="booking.customer.id" :fromaddress="fromaddress" :qcrules="booking.clientqcrule" />

    <IssueReportEmailModal id="issuetolandlord-email-modal" name="issuetolandlord-email-modal" ref="landlordEmailModal"
      title="Issue report to landlord" :reporttype="reporttype" target="Landlord" chronorder="Issue Report"
      :customer="booking.customer.id" :fromaddress="fromaddress" :qcrules="booking.landlordqcrule" />

    <IssueReportEmailModal id="issuetotenant-email-modal" name="issuetotenant-email-modal" ref="tenantEmailModal"
      title="Issue report to tenant" :reporttype="reporttype" target="Tenant" chronorder="Issue Report"
      :customer="booking.customer.id" :fromaddress="fromaddress" :qcrules="booking.tenantqcrule" />
    <IssueReportEmailModal id="issuetoinvoice-email-modal" name="issuetoinvoice-email-modal" ref="invoiceEmailModal"
      title="Issue report to invoice" :reporttype="reporttype" target="Invoice" chronorder="Issue Report"
      :customer="booking.customer.id" :fromaddress="fromaddress" :qcrules="booking.invoiceqcrule" />
  </div>

  <EmailLogsModal name="issuereporttoclient-emaillogs-modal" ref="issuereporttoclientEmailLogsModal" target="Client"
    title="Issue report to client - email logs" chronorder="Issue Report" />

  <EmailLogsModal name="issuereporttolandlord-emaillogs-modal" ref="issuereporttolandlordEmailLogsModal" target="Landlord"
    title="Issue report to landlord - email logs" chronorder="Issue Report" />

  <EmailLogsModal name="issuereporttotenant-emaillogs-modal" ref="issuereporttotenantEmailLogsModal" target="Tenant"
    title="Issue report to tenant - email logs" chronorder="Issue Report" />
  <EmailLogsModal name="issueinvoice-emaillogs-modal" ref="issueinvoiceEmailLogsModal" target="Invoice"
    title="Issue invoice - email logs" chronorder="Issue Report" />
</template>

<script lang="ts" setup>
import { computed, ref, defineEmits, watch } from 'vue';
import { useStore } from 'vuex';
import { EmailLogs, Booking, Report } from "@/models";
import { useToast } from "vue-toastification";
import EmailLogsModal from "@/components/modals/EmailLogsModal.vue";
import MiscNotesDialog from '@/components/modals/MiscNotesDialog.vue';
import IssueReportEmailModal from "@/components/modals/IssueReportEmailModal.vue";
import { useRoute } from 'vue-router';

const emit = defineEmits(['']);
const toast = useToast();
const route = useRoute();

const reportref = ref('');
const subreportref = ref('');
const errorreportref = ref(false);
const errorsubreportref = ref(false);
const qcRulesDialog = ref(null);
const clientEmailModal = ref(null);
const issuereporttoclientEmailLogsModal = ref(null);
const landlordEmailModal = ref(null);
const issuereporttolandlordEmailLogsModal = ref(null);
const tenantEmailModal = ref(null);
const invoiceEmailModal = ref(null);
const issuereporttotenantEmailLogsModal = ref(null);
const issueinvoiceEmailLogsModal = ref(null);

const getReportCheckPostCode = async (payload: { ref: string; postcode: string, type: string }): Promise<any> => {
  return await store.dispatch('reports/getReportCheckPostCode', payload);
};
const setBookingDeep = (payload: { path: string; data: any }) => store.dispatch('diary/setBookingDeep', payload);

// Getters
const store = useStore();
const booking = computed((): Booking => store.getters['diary/booking']);
const systemproperties = computed(() => store.getters['dictionary/systemproperties']);
const emaillogs = computed((): EmailLogs => store.getters['diary/emaillogs']);

const reporttype = computed(() => booking.value.jobtype);
const clienttarget = computed(() =>
  booking.value.managedtype === 'Managed' ? "Managed Client" : "Non-Managed Client"
);
const fromaddress = computed(() => {
  let prop = systemproperties.value?.find(sp => sp.propertykey === 'booking.email.fromaddress');
  return prop?.value;
});

const tenanttoaddresses = computed(() => {
  let toaddresses = [];
  if (booking.value && booking.value.tenants && booking.value.tenants.length) {
    toaddresses = booking.value.tenants
      .filter(t => t.ttemail)
      .map(t => t.ttemail);
  }
  return [...toaddresses];
});


watch(() => booking.value.id, async () => {
  if (booking.value.id) {
    reportref.value = '';
    subreportref.value = '';
    errorreportref.value = false;
  }
});

const onSubmit = async () => {
  if (reportref.value && !isNewBooking()) {
    errorreportref.value = false;
    let response = await getReportCheckPostCode({ ref: reportref.value, postcode: booking.value.address.postcode, type: booking.value.jobtype });
    if (response) {
      errorreportref.value = false;
      booking.value.report.id = response;
      booking.value.report.firstexportedby = booking.value?.inspector?.email;
      booking.value.report.ref = reportref.value;
      setBookingDeep({ path: 'report', data: booking.value.report });
    } else {
      errorreportref.value = true;
      toast.error('Selected report does does not match with this booking');
    }
  }
};

const onSubReport = async () => {
  if (subreportref.value && !isNewBooking()) {
    errorsubreportref.value = false;
    let response = await getReportCheckPostCode({ ref: subreportref.value, postcode: booking.value.address.postcode, type: booking.value.jobtype });
    if (response) {
      errorsubreportref.value = false;
      let report = new Report();
      report.id = response;
      report.ref = subreportref.value;
      booking.value.subreports.push(report);
      setBookingDeep({ path: 'subreports', data: booking.value.subreports });
    } else {
      errorsubreportref.value = true;
      toast.error('Selected sub report does does not match with this booking');
    }
  }
}

const clearRef = () => {
  errorreportref.value = false;
  booking.value.report.id = '';
  booking.value.report.ref = '';
  reportref.value = '';
  setBookingDeep({ path: 'report', data: booking.value.report });
};


const clearsubRef = (index: number) => {
  errorsubreportref.value = false;
  booking.value.subreports.splice(index, 1);
  setBookingDeep({ path: 'subreports', data: booking.value.subreports });
};
const showQCRulesDialog = () => {
  const modal = qcRulesDialog.value as any;
  modal.show();
}

const showEmailClientDialog = () => {
  const modal = clientEmailModal.value as any;
  modal.init();
  modal.show();
}
const showClientEmailLogsDialog = () => {
  const modal = issuereporttoclientEmailLogsModal.value as any;
  modal.init();
  modal.show();
}

const showEmailTenantDialog = () => {
  const modal = tenantEmailModal.value as any;
  modal.init();
  modal.show();
}
const showLandlordEmailLogsDialog = () => {
  const modal = issuereporttolandlordEmailLogsModal.value as any;
  modal.init();
  modal.show();
}

const showEmailLandlordDialog = () => {
  const modal = landlordEmailModal.value as any;
  modal.init();
  modal.show();
}
const showTenantEmailLogsDialog = () => {
  const modal = issuereporttotenantEmailLogsModal.value as any;
  modal.init();
  modal.show();
}
const showEmailInvoiceDialog = () => {
  const modal = invoiceEmailModal.value as any;
  modal.init();
  modal.show();
}
const showInvoiceEmailLogsDialog = () => {
  const modal = issueinvoiceEmailLogsModal.value as any;
  modal.init();
  modal.show();
}
const isNewBooking = () => {
  return route.params.id == "new";
}

const feedbackstatus = computed(() => {
  var val = '';
  if (booking.value.report.feedbackstatus === 'Opened') {
    val = 'amber';
  }
  else if (booking.value.report.feedbackstatus === 'Received') {
    val = 'red';
  }
  else if (!booking.value.report.feedbackstatus || booking.value.report.feedbackstatus === 'Acknowledged') {
    val = 'green';
  }
  return val;
});

const emailtoclientstatus = computed(() => {
  var val = 'green';
  if (booking.value.clientqcrule && booking.value.clientqcrule.email) {
    if (emaillogs.value.issuereportemailsendtoclientdate) {
      val = 'green';
    } else {
      val = 'red';
    }
  }
  return val;
});

const emailtolandlordstatus = computed(() => {
  var val = 'green';
  if (booking.value.landlordqcrule && booking.value.landlordqcrule.email) {
    if (emaillogs.value.issuereportemailsendtolandlorddate) {
      val = 'green';
    } else {
      val = 'red';
    }
  }
  return val;
});

const emailtotenantstatus = computed(() => {
  var val = 'green';
  if (tenanttoaddresses.value.length) {
    if (emaillogs.value.issuereportemailsendtotenantdate) {
      val = 'green';
    } else {
      val = 'red';
    }
  }
  return val;
});

const emailinvalidstatus = computed(() => {
  var val = 'green';
  if (booking.value.invoiceqcrule && booking.value.invoiceqcrule.email) {
    if (emaillogs.value.invoicesentdate) {
      val = 'green';
    } else {
      val = 'red';
    }
  }
  return val;
});

const issuestatus = computed(() => {
  const statusarray = [feedbackstatus.value, emailtoclientstatus.value, emailtolandlordstatus.value, emailtotenantstatus.value, emailinvalidstatus.value];
  const redindex = statusarray.findIndex((v) => v === 'red');
  const amberindex = statusarray.findIndex((v) => v === 'amber');
  if (redindex >= 0) return 'red';
  else if (amberindex >= 0) return 'amber';
  else return 'green';
});



</script>


<style scoped lang="scss">
.topborder {
  border-top: 2px solid #dee2e6;
}

.yellow-outline {
  border: 4px solid yellow !important;
}

.multiselecttag {
  position: relative;
  display: inline-block;
  padding: 4px 20px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: rgb(255, 255, 255);
  line-height: 1;
  background: #41b883;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.withoutuserprofile {
  background: red;
}

.multiselecttags {
  display: block;
  font-size: 14px;
}

.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: initial;
  width: 22px;
  text-align: center;
  line-height: 22px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-radius: 5px;
}

.outlineerror {
  border: 2px solid red !important;
}</style>