<template>
  <div class="booking" :id="'appointment-box-' + booking.id" :style="appointmentstyle" :class="{
    'selectedbookingcard': booking.id === selectedbooking.id
  }">
    <div class="card custom-box-shadow text-bg-light mb-6"
      :class="{ 'text-bg-secondary': booking.id === selectedbooking.id, 'inprogress': booking.inprogress }">
      <div class="card-header">
        <div class="row">
          <div class="col-md-10">
            <div class="jobtype">
              <span class="font-weight-bold" :key="booking.updatedAt">
                {{ title }}
              </span>
              <span class="font-weight-bold" v-if="booking.addressPreviewLine1 || booking.addressPreviewLine2">
                - {{ booking.address.displayaddress }}
              </span>
              <br/>
              <span v-if="booking.inprogress && booking.customer && (booking.customer.companyName || booking.keypickupbranchname || booking.customer.branchName)" class="font-weight-bold customer">
                 {{ booking.customer.companyName }} - {{ booking.keypickupbranchname ? booking.keypickupbranchname : booking.customer.branchName }}
              </span>
            </div>
          </div>

          <div class="col-md-1 p-0">
            <span style="float: right;">
              <button v-if="booking.inprogress" type="button" class="close" data-dismiss="modal" aria-label="Close"
                @click.stop="bookingNotinprogress(booking)"
                :style="{ color: booking.id === selectedbooking.id ? 'white !important' : 'black !important' }">
                <i class="far fa-window-maximize"></i>
              </button>
              <button v-else type="button" class="close" data-dismiss="modal" aria-label="Close"
                @click.stop="bookingInprogress(booking)"
                :style="{ color: booking.id === selectedbooking.id ? 'white !important' : 'black !important' }">
                <i class="fas fa-window-minimize"></i>
              </button>
            </span>
          </div>
          <div class="col-md-1 p-0 pl-2">
            <span style="float: left;">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                @click.stop="removeBookingFromList(booking)"
                :style="{ color: booking.id === selectedbooking.id ? 'white !important' : 'black !important' }">
                <i class="fas fa-times"></i>
              </button>
            </span>
          </div>
        </div>
      </div>
      <div class="card-body" v-if="!booking.inprogress">
        <div class="second-div">
          <div>
            <div v-if="booking.titlenotes" class="mb-1" style="white-space: normal">
              <span><b>Title note </b> </span>
              <span>
                <span class="ml-1">
                  {{ booking.titlenotes }}
                </span>
              </span>
            </div>
            <div class="mb-1" style="white-space: normal">
              <span><b>Date </b> </span>
              <span>
                <span class="ml-1">
                  {{ booking.bookingdate }}
                </span>
                <span class="ml-1">{{ booking.starttime }}
                </span>
                <span class="ml-1">{{ booking.endtime }}
                </span>
              </span>
              <div v-if="subbookingdatetimeflag" class="mb-1" style="white-space: normal">
                <div v-for="(prepdata, $prepdata) in prepandKeyJobData" :key="$prepdata">
                  <div v-if="prepdata?.startdate || prepdata?.enddate">
                    <span class="ml-4"></span>
                    <span class="ml-1">{{ prepdata.bookingdate }}
                    </span>
                    <span class="ml-1">{{ prepdata.starttime }}
                    </span>
                    <span class="ml-1">{{ prepdata.endtime }}
                    </span>
                    <span class="ml-1">
                      ({{ prepdata.subtype }})
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="booking?.leadbooking">
              <div v-if="booking?.leadbooking?.startdate || booking.leadbooking?.enddate" class="mb-1"
                style="white-space: normal">
                <span class="ml-4"></span>
                <span class="ml-1">{{ booking?.leadbooking.bookingdate }}
                </span>
                <span class="ml-1">{{ booking?.leadbooking.starttime }}
                </span>
                <span class="ml-1">{{ booking?.leadbooking.endtime }}
                </span>
                <span class="ml-1">({{ masterReportType }})
                </span>
              </div>
            </div>
            <div v-if="_appointmenttime != 'Invalid date'" class="mb-1" style="white-space: normal">
              <span><b>Appointment time </b> </span>
              <span class="ml-1"> {{ _appointmenttime }}
              </span>
            </div>
            <!-- PI name -->
            <div class="piname" v-if="booking.inspector && booking.inspector.name">
              <span><b>PI </b></span> {{ booking.inspector.name }}
            </div>
            <div v-if="subbookingdatetimeflag" class="mb-1" style="white-space: normal">
              <div v-for="(prepdata, $prepdata) in prepandKeyJobData" :key="$prepdata">
                <div class="piname" v-if="prepdata.inspector.name">
                  <span class="ml-3"></span>
                  <span>{{ prepdata.inspector.name }}
                  </span>
                  <span class="ml-1">
                    ({{ prepdata.subtype }})
                  </span>
                </div>
              </div>
            </div>
            <div v-if="booking?.leadbooking">
              <div class="piname" v-if="booking?.leadbooking?.inspector || booking.leadbooking?.inspector.name">
                <span class="ml-3"></span>
                <span>{{ booking.leadbooking?.inspector.name }}
                </span>
                <span class="ml-1">
                  ({{ masterReportType }})
                </span>
              </div>
            </div>
            <!-- Customer -->
            <div class="customer" v-if="booking.customer &&
    (booking.customer.companyName || booking.keypickupbranchname || booking.customer.branchName)
    ">
              {{ booking.customer.companyName }} -
              {{ booking.keypickupbranchname ? booking.keypickupbranchname : booking.customer.branchName }}
            </div>

            <div v-if="booking.bookedbyname || booking.bookedbymobile || booking.bookedbyemail">
              <div v-for="(bookedby, $bbindex) in booking.bookedby" :key="$bbindex">
                <span><b>Booked By</b></span>
                <span class="ml-1"> {{ bookedby.bbname }}</span>
                <span class="ml-1"> {{ bookedby.bbemail }}</span>
                <span class="ml-1"> {{ bookedby.bbmobile }} </span>
              </div>
            </div>

            <div v-if="booking.tenants.length > 0">
              <div v-for="(tenant, $bbindex) in booking.tenants" :key="$bbindex">
                <span v-if="tenant.ttname"><b>TT </b>{{ tenant.ttname }}</span>
                <span v-if="tenant.ttemail" class="ml-1"><b>TT </b> {{ tenant.ttemail }}</span>
                <span v-if="tenant.ttmobile" class="ml-1"><b>TT </b>{{ tenant.ttmobile }} </span>
              </div>
            </div>
            <div class="mb-1" style="white-space: normal" v-if="booking.internaljobtype != 'Check-In - back to back'">
              <span v-if="booking.keypickup"><b>Key pickup/Access </b>{{ booking.keypickup }}
              </span>
              <span class="ml-1" v-if="booking.keypickup === 'From Agency' && booking.keypickupbranchname"> <b>Branch </b> {{ booking.keypickupbranchname }}</span>
              <span class="ml-1"
                v-if="((booking.keypickup === 'From Agency' || booking.keypickup === 'Via Concierge' || booking.keypickup === 'Via Landlord') && booking.keypickupfromaddress.phone)">
                <b>Phone </b> {{ booking.keypickupfromaddress.phone }} </span>
            </div>
            <div class="mb-1" style="white-space: normal" v-if="booking.connectedbooking && booking.connectedbooking.internaljobtype != 'Check-In - back to back'">
              <span v-if="booking.releasekeysto"><b>Return/Key Release </b>{{ booking.releasekeysto }}
              </span>
              <span class="ml-1" v-if="booking.releasekeystobranchname"><b>Branch </b>{{ booking.releasekeystobranchname
                }}
              </span>
            </div>

            <div v-if="primaryErrors.length" class="mb-1">
              <span style="white-space: normal;color: red;"><b>Primary Issues </b></span>
              <div v-for="(errors, $primaryerror) in primaryErrors" :key="$primaryerror">
                <span>{{ errors.message }}</span>
              </div>
            </div>

            <div v-if="secondaryErrors.length" class="mb-1">
              <span style="white-space: normal;color: #bd8319;"><b>Secondary Issues </b></span>
              <div v-for="(errors, $secondaryerror) in secondaryErrors" :key="$secondaryerror">
                <span>{{ getPresetErrorMessage(errors) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="first-div">
          <i class="fas fa-2x fa-skull-crossbones icon-botton-right" style="color: yellow"
            v-if="booking.checkoutWithoutDataentry" :title="skulltitle"></i>
          <i class="fa fa-3x fa-exclamation-circle icon-botton-right" style="color: yellow" v-if="booking.provisional"
            :title="`Provisional booking: ${booking.provisionalnotes}`"></i>
          <i class="fa fa-check-circle icon-botton-right" style="color: yellowgreen"
            v-if="!booking.issued && !booking.provisional && booking.tenantattending != 'unsure' && booking.confirmaccess"
            title="Job fully confirmed"></i>
          <!-- <i class="fa fa-star  icon-botton-right" style="color: yellow" v-if="booking.issued"
            title="Job issued to client"></i> -->
          <i class="fas fa-lock icon-botton-right" style="color: yellow" v-if="booking.locked"
            :title="booking.lockedby"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, defineProps, inject, onMounted, ref, defineEmits } from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import { Dictionary, Inspector, SelectOption, Booking, PresetError } from "@/models";
import { getAppointmentcolour } from "@/utilities";
import Ably from "ably";
import moment from 'moment-timezone';
const props = defineProps<{
  highlight?: string;
  picolour: boolean;
  rightclick: boolean;
  showdate: boolean;
  templateModel: any;
}>();

const realtime: Ably.Realtime = inject('realtime');
const channel = realtime.channels.get('diary');
const actProperty: any = inject('actProperty');
const store = useStore();

const dictionary = computed((): Dictionary => store.getters['dictionary/current']);
const inspectorlist = computed((): Inspector[] => store.getters['diary/inspectorlist']);
const selectedbooking = computed(() => store.getters['diary/booking'] as Booking);
const emit = defineEmits(['removebookingfromlocal']);

const prepdate = ref("");
const cidate = ref("");


const bookingInprogress = async (booking: Booking) => {
  if (booking && booking.id) {
    await store.dispatch('diary/bookingInprogress', booking)
      .then((b: Booking) => {
        booking.inprogress = b.inprogress;
        channel.publish('bookinginprogress', { bookingid: b.id });
      });
  }
};
const bookingNotinprogress = async (booking: Booking) => {
  if (booking && booking.id) {
    await store.dispatch('diary/bookingNotinprogress', booking)
      .then((b: Booking) => {
        booking.inprogress = b.inprogress;
        channel.publish('bookingnotinprogress', { bookingid: b.id });
      })
  }
};

onMounted(() => {
  if (props.templateModel.appointmentData.subtype === Booking.PREP) {
    if (props.templateModel.appointmentData.leadbooking?.id) {
      cidate.value = props.templateModel.appointmentData.leadbooking.bookingdate;
    }
  } else if (props.templateModel.appointmentData.subbookings) {
    props.templateModel.appointmentData.subbookings.forEach((b: Booking) => {
      if (b.subtype === Booking.PREP) {
        prepdate.value = b.bookingdate;
      }
    });
  }
});


const _appointmenttime = computed(() => {
  let apptime = moment(booking.value.appointmenttime).utc().format("h:mm a");
  if (apptime === "12:00 am") apptime = "No Tenant but Fixed Time";
  if (apptime === "12:15 am") apptime = "Flexi all day";
  if (apptime === "12:20 am") apptime = "Flexi AM";
  if (apptime === "12:25 am") apptime = "Flexi PM";
  return apptime;
});

const primaryErrors = computed(() => {
  return booking.value.preseterrors.filter(((f: PresetError) => f.category === 'primary'));
});
const secondaryErrors = computed(() => {
  return booking.value.preseterrors.filter(((f: PresetError) => f.category === 'secondary'));
});

const booking = computed(() => {
  return props.templateModel?.appointmentData;
});

const subbookingdatetimeflag = computed(() => {
  let prepJob = booking.value.subbookings.filter(((f: any) => f.subtype === 'Prep' || f.subtype === 'Key'));
  if (prepJob.length > 0) {
    return true;
  } else {
    return false;
  }
});

const prepandKeyJobData = computed(() => {
  let prepandKeyJob = booking.value.subbookings.filter(((f: any) => f.subtype === 'Prep' || f.subtype === 'Key'));
  return prepandKeyJob;
});

const title = computed(() => {
  return actProperty.getBookingTitle(booking.value, dictionary.value);
});

const appointmentstyle = computed(() => {
  if (!props.picolour) return "";
  let style = "";
  if (booking.value.id === props.highlight) {
    style = `background-image: conic-gradient(from var(--border-angle), ${appointmentcolour.value}, ${appointmentcolour.value} 50%, ${appointmentcolour.value}), conic-gradient(from var(--border-angle), transparent 20%, #253746, #eadb40);`;
  } else {
    style = `background: ${appointmentcolour.value};`;
  }
  return style;
});

const skulltitle = computed(() => {
  let title = '';
  if (booking.value.checkoutWithoutPreviousReport) {
    title = 'Previous report not present';
  } else if (booking.value.checkoutWithoutDataentry) {
    if (!booking.value.dataentryreport)
      title = 'Dataentry report is missing';
    else if (booking.value.dataentryreport.dataentrystatus != 'Upload Complete')
      title = 'Dataentry report is not completed by dataentry team';
  }
  return title;
});

const appointmentcolour = computed(() => {
  return getAppointmentcolour(booking.value, inspectorlist.value);
});

const getPresetErrorMessage = (error: PresetError) => {
  const errors = [{
    message: "No PDF Upload Doc for CO off upload",
    presetnumber: 1,
    flag: false
  }, {
    message: "Missing upload ref for CO off upload",
    presetnumber: 2,
    flag: false
  }, {
    message: "Appointment time falls outside of booking time",
    presetnumber: 3,
    flag: false
  }, {
    message:
      "Appointment time is not the same as booking start time when access is via tenant",
    presetnumber: 4,
    flag: false
  }, {
    message: "Booking start time falls outside of agency opening hours",
    presetnumber: 7,
    flag: false
  }, {
    message: "Access via tenant for CO/SOC unconfirmed",
    presetnumber: 5,
    flag: false
  }, {
    message: "Access unconfirmed (excluding clients that have opted out)",
    presetnumber: 6,
    flag: false
  }, {
    message: "Provisional booking (all job types)",
    presetnumber: 8,
    flag: false
  }];
  const index = errors.findIndex((e) => e.presetnumber === error.presetnumber);
  if (index >= 0)
    return errors[index].message;
  else
    return error.message;
};

const removeBookingFromList = (booking: Booking) => {
  emit("removebookingfromlocal", booking);
}
const masterReportType = computed(() => {
  return actProperty.formatReportType(booking.value.leadbooking?.jobtype, dictionary)
});
</script>

<style scoped lang="scss">
.booking {
  white-space: normal !important;
  overflow: hidden !important;
}

.container {
  position: relative;
  overflow: hidden !important;
  width: 100%;
}

.first-div {
  position: absolute;
  background: transparent;
  overflow: hidden !important;
  top: 0;
  left: 0;
  width: 100%;
  padding: 3px;
}

.second-div {
  position: absolute;
  background: transparent;
  overflow: hidden !important;
  top: 0;
  left: 0;
  width: 100%;
  padding: 3px;
}

.allday {
  padding: 0 0 0 3px;
}

.white-border {
  border: 3px dashed rgba(250, 250, 250, 0.5);
}

.jobtype {
  white-space: normal !important;
}

.address {
  white-space: normal !important;
}

.customer {
  white-space: normal !important;
}

.piname {
  white-space: normal !important;
}


.description {
  white-space: normal !important;
}

.summary {
  white-space: normal !important;
}

.location {
  white-space: normal !important;
}

.timing {
  white-space: normal !important;
}

.dateofmaster {
  white-space: normal !important;
}

.dx-scheduler-appointment {
  /*background: transparent !important;*/
}

.first-div {
  display: contents;
  position: absolute;
  background: transparent;
  overflow: hidden !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 3px;
}

.second-div {
  display: contents;
  position: absolute;
  background: transparent;
  overflow: hidden !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 3px;
}

.selectedbookingcard {
  color: white !important;
  height: inherit;
  --border-size: 7px;
  --border-angle: 0turn;
  background-size: calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;

  animation: bg-spin 3s linear infinite;

  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }

  &:hover {
    animation-play-state: paused;
  }
}

.inprogress {
  background-color: #EADB40;
}
</style>

<style lang="scss">
$light-grey: #ecf0f1;
$grey: darken($light-grey, 15%);
$blue: #007aff;
$white: #fff;
$black: #333;

.dx-submenu {
  background-color: $light-grey;
  border-bottom-width: 0px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba($black, 0.2);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  z-index: 1000000;

  li.dx-menu-item-wrapper {
    border-bottom: 2px solid $grey;
  }

  li {
    &:first-of-type {
      margin-top: 4px;
    }

    &:last-of-type {
      margin-bottom: 4px;
      border-bottom: 0px;
    }
  }
}

.dx-scheduler-appointment {
  overflow-x: hidden;
  overflow-y: hidden;
}

.text-bg-light {
  background-color: rgb(221, 221, 221);
}

.text-bg-secondary {
  background-color: rgb(37, 55, 70);
}

.custom-box-shadow {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.1), -5px 5px 10px rgba(0, 0, 0, 0.1);
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

.ml-4,
.mx-4 {
  margin-left: 2.0rem !important;
}
</style>
