<template>
  <nav>
    <ul class="pagination">
      <li
        v-for="(link, $index) in links"
        :key="$index"
        class="page-item"
        :class="{active: selected === link}"
        @click="onClick(link)"
      >
        <div class="page-link" >
          {{ link.toUpperCase() }}
        </div>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import { ref, defineEmits, inject, onMounted } from 'vue';
import { useStore } from 'vuex';

const emit = defineEmits(['click']);
const selected = ref('a');

const store = useStore();
const actProperty: any = inject('actProperty');

const links = [
  "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", 
  "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", 
  "0-9",
];

onMounted(() => {
  init();
});

const init = async () => {
  await getCustomersAction(selected.value);
};

const onClick = (event: any) => {
  selected.value = event;
  getCustomersAction(selected.value);
  emit("click", event);
};

const getCustomersAction = async (starts_with: string) => {
  try {
    await store.dispatch('usermanagement/getCustomers', starts_with);
  } catch (err: any) {
    actProperty.displayError(err)
  }
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
