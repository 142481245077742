<template>
  <form class="auth login" @submit.prevent="signIn()">
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">Email</label>
      <div class="col-sm-6">
        <input
          type="email"
          class="form-control"
          v-model.trim="username"
          aria-describedby="usernameHelp"
        />
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-3 col-form-label">Password</label>
      <div class="col-sm-6">
        <input
          type="password"
          class="form-control"
          v-model="password"
          aria-describedby="passwordHelp"
        />
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-6 offset-sm-3">
        <button class="btn btn-primary" type="submit">Sign in</button>

        <span class="text-muted ml-4">or</span>
        <button class="btn btn-link ml-3" @click.prevent="resetPassword()">
          Reset your password
        </button>
      </div>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { ref, inject } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Auth from "@aws-amplify/auth";
import _ from "lodash";
import moment from "moment-timezone";

const store = useStore();
const router = useRouter();
const actProperty: any = inject('actProperty');

const setCognitoUser = (user: any) => {
  store.commit("auth/setCognitoUser", user);
}

const addRequest = (id: string) => {
  store.commit("app/addRequest", id);
}

const removeRequest = (id: string) => {
  store.commit("app/removeRequest", id);
}

const username = ref('');
const password = ref('');

// Methods converted to composition API style
const signIn = async () => {
  try {
    addRequest("signIn");
    const user = await Auth.signIn(username.value, password.value);
    setCognitoUser(user);

    if (
      user.challengeName === "SMS_MFA" ||
      user.challengeName === "SOFTWARE_TOKEN_MFA"
    ) {
      // Confirm your email (or mobile, if SMS_MFA is implemented)
      router.push({ name: "confirm-email" });
    } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      // Choose a new password
      router.push({ name: "complete-new-password" });
    } else {
      // Check if we are allowed to access the CMS (if Cognito Group includes "Administrators" or "Customers")
      const assignedGroups = _.get(
        user,
        'signInUserSession.accessToken.payload["cognito:groups"]',
        []
      );
      const isAllowedAccess = assignedGroups.some((assignedGroup: string) =>
        ["Administrators", "Customers", "Dataentry", "Booking"].includes(
          assignedGroup
        )
      );
      if (isAllowedAccess) {
        // You are signed in
        // TODO redirect to path saved in URL
        const isDataEntry = assignedGroups.some((assignedGroup: string) =>
          ["Dataentry"].includes(assignedGroup)
        );
        const isBooking = assignedGroups.some((assignedGroup: string) =>
          ["Booking"].includes(assignedGroup)
        );
        const isDiaryUser = assignedGroups.some((assignedGroup: string) =>
          ["DiaryUser"].includes(assignedGroup)
        );
        const isBookingManagement = assignedGroups.some((assignedGroup: string) =>
          ["BookingManagement"].includes(assignedGroup)
        );
        if(screen.width <= 760 && isDiaryUser) {
          router.push({ name: "pischeduler", params: { date: moment(new Date()).format("DD-MM-YYYY")} });
        } else if (isDataEntry) {
          router.push({ name: "dereports" });
        } else if (isBooking) {
          router.push({ name: "newbooking" });
        } else if (isBookingManagement) {
          router.push({ name: "management-filters" });
        }  else {
          router.push({ name: "reports" });
        }
      } else {
        await Auth.signOut();
        setCognitoUser(null);
        throw Error(
          "You do not have the required permissions to access this system. Please contact support to arrange access."
        );
      }
    }
  } catch (err: any) {
    if (err.code === "UserNotConfirmedException") {
      // The error happens if the user didn't finish the confirmation step when signing up
      // In this case you need to resend the code and confirm the user
      // About how to resend the code and confirm the user, please check the signUp part
      actProperty.displayError(err);
    } else if (err.code === "PasswordResetRequiredException") {
      // The error happens when the password is reset in the Cognito console
      // In this case you need to call forgotPassword to reset the password
      // Please check the Forgot Password part.
      actProperty.displayError(err);
      resetPassword();
    } else if (
      err.code === "NotAuthorizedException" ||
      err.code === "UserNotFoundException"
    ) {
      // The error happens when the incorrect password is provided
      // or when the supplied username/email does not exist in the Cognito user pool
      actProperty.displayError("Invalid login details");
    } else if (typeof err.message === "string" && err.message.length) {
      actProperty.displayError(err);
    } else {
      actProperty.displayError(
        "An unexpected error occurred, please try again later."
      );
    }
  } finally {
    removeRequest("signIn");
  }
};

const resetPassword = async () => {
  router.push({ name: 'reset-password' });
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
