// // Uncomment if we ever need to import the dictionary from last-saved JSON
// import jsonData from './json/dictionary.json';

import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import Vue from 'vue';
import { API } from 'aws-amplify';

import { ActionTree, ActionContext } from 'vuex';
import { DictionaryState } from './types';
import { RootState, } from '../types';
import { Dictionary, DictionaryType, DictionaryItem, DictionaryConditionMatrix, DictionaryReportType, SystemProperty } from '@/models';

/**
 * Actions
 *
 * Actions are similar to mutations, the differences being that:
 * - Instead of mutating the state, actions commit mutations.
 * - Actions can contain arbitrary asynchronous operations.
 * - Actions are triggered with the store.dispatch method e.g. `store.dispatch('getSettings')`
 */
export const actions: ActionTree<DictionaryState, RootState> = {

  /**
   * Gets the Dictionary
   * 
   * - The API returns the most recent revision of the Dictionary
   * @param {DictionaryState} store 
   * @returns Dictionary
   */
  async getDictionary(store: ActionContext<DictionaryState, any>): Promise<Dictionary> {
    // Because we are making multiple calls to this endpoint each one needs a unique ID
    const requestId = `getDictionary-${uuidv4()}`;

    store.commit('app/addRequest', requestId, { root: true });

    return API.get('RestAPI', '/dictionary', null)
      .then((data) => Dictionary.parse(data))
      // // Uncomment, load & manually "Save" to reset all Item Colours and Conditions
      // .then((dictionary: Dictionary) => Dictionary.emptyItemColoursAndConditions(dictionary))
      .then((dictionary: Dictionary) => {
        // console.log('getDictionary', dictionary);
        store.commit('setDictionary', dictionary);
        return dictionary;
      })
      .finally(() => store.commit('app/removeRequest', requestId, { root: true }));
  },

  /**
   * Add a Dictionary
   * 
   * - Adds an entire record, when we GET we return the most recent and retain the rest for posterity
   * @param {DictionaryState} store 
   * @param queryStringParameters.reorder boolean
   * @returns Dictionary
   */
  async addDictionary(store: ActionContext<DictionaryState, any>, queryStringParameters?: { reorder: boolean }): Promise<Dictionary> {
    const dictionary = store.state.current;
    const init = {
      body: dictionary.toJSON(),
      queryStringParameters: _.pickBy(_.pick(queryStringParameters, ['reorder'])),
    };
    store.commit('app/addRequest', 'getDictionary', { root: true });
    return API.post('RestAPI', `/dictionary`, init)
      .then((data) => Dictionary.parse(data))
      .then((dictionary: Dictionary) => {
        store.commit('resetUnsavedChanges');
        // console.log('addDictionary', dictionary);

        // Disabled for now, it isn't really necessary as we don't need e.g. new ID from database
        // If enabled, there are reactivity issues when resetting dictionary e.g. Cannot resave Condition Matrix Set 1, after saving once.
        // // store.commit('setDictionary', dictionary);

        return dictionary;
      })
      .finally(() => store.commit('app/removeRequest', 'getDictionary', { root: true }));
  },

  /**
   * Sets any deep dictionary property and increment the "unsaved changes" count
   * 
   * @param {DictionaryState} store 
   * @param data
   * @returns void
   */
  setDictionaryDeep(store: ActionContext<DictionaryState, any>, data: any) {
    store.commit('setDictionaryDeep', data);
    store.commit('addUnsavedChange');
  },

  /**
   * Add Type and increment the "unsaved changes" count
   * 
   * @param {DictionaryState} store 
   * @param {DictionaryType} type
   * @returns void
   */
  addType(store: ActionContext<DictionaryState, any>, type: DictionaryType) {
    store.commit('addType', type);
    store.commit('addUnsavedChange');
  },

  /**
   * Update Type and increment the "unsaved changes" count
   * 
   * @param {DictionaryState} store 
   * @param {DictionaryType} type
   * @returns void
   */
  updateType(store: ActionContext<DictionaryState, any>, type: DictionaryType) {
    store.commit('updateType', type);
    store.commit('addUnsavedChange');
  },

  /**
   * Remove Type and increment the "unsaved changes" count
   * 
   * @param {DictionaryState} store
   * @param {DictionaryType} type
   * @returns void
   */
  removeType(store: ActionContext<DictionaryState, any>, type: DictionaryType) {
    store.commit('removeType', type);
    store.commit('addUnsavedChange');
  },

  /**
   * Add Item and increment the "unsaved changes" count
   * 
   * @param {DictionaryState} store
   * @param {DictionaryItem} item
   * @returns void
   */
  addItem(store: ActionContext<DictionaryState, any>, item: DictionaryItem) {
    store.commit('addItem', item);
    store.commit('addUnsavedChange');
  },

  /**
   * Update Item and increment the "unsaved changes" count
   * 
   * @param {DictionaryState} store
   * @param {DictionaryItem} item
   * @returns void
   */
  updateItem(store: ActionContext<DictionaryState, any>, item: DictionaryItem) {
    store.commit('updateItem', item);
    store.commit('addUnsavedChange');
  },

  /**
   * Remove Item and increment the "unsaved changes" count
   * 
   * @param {DictionaryState} store
   * @param {DictionaryItem} item
   * @returns void
   */
  removeItem(store: ActionContext<DictionaryState, any>, item: DictionaryItem) {
    store.commit('removeItem', item);
    store.commit('addUnsavedChange');
  },

  /**
   * Set the Colours of the currently selected Item
   * 
   * @param {DictionaryState} store
   * @param {string[]} value
   * @returns void
   */
  setColours(store: ActionContext<DictionaryState, any>, value: string[]) {
    // Set the Colours for the currently selected Item
    store.commit('setItemProperty', {
      property: 'colours',
      value,
    });
    // Set the Colours for the "Misc" Item, which populates select boxes
    store.commit('setMiscItemProperty', {
      property: 'colours',
      value: _.uniq(store.getters.allColours.concat(value)),
    });
    store.commit('addUnsavedChange');
  },

  /**
   * Set the Conditions (Additional) of the currently selected Item
   * 
   * @param {DictionaryState} store
   * @param {string[]} conditions
   * @returns void
   */
  setConditions(store: ActionContext<DictionaryState, any>, conditions: string[]) {
    // Set the Conditions for the currently selected Item
    store.commit('setItemConditions', {
      property: 'additional',
      value: conditions,
    });
    store.commit('setMiscItemConditions', _.uniq(store.getters.allConditions.concat(conditions)));
    store.commit('addUnsavedChange');
  },

  /**
   * Set the complete Condition Matrix of the currently selected Item
   * 
   * @param {DictionaryState} store
   * @param {DictionaryConditionMatrix} matrix
   * @returns void
   */
  setConditionMatrix(store: ActionContext<DictionaryState, any>, matrix: DictionaryConditionMatrix[]) {
    store.commit('setItemConditions', {
      property: 'matrix',
      value: matrix,
    });
    store.commit('addUnsavedChange');
  },

  /**
   * Set a value of the Condition Matrix of the currently selected Item
   * 
   * @param {DictionaryState} store
   * @param {String} path
   * @param {String} value
   * @returns void
   */
  setConditionMatrixValue(store: ActionContext<DictionaryState, any>, payload: { path: string, value: string }) {
    let matrix = store.getters.activeConditionMatrix;
    store.commit('setItemConditions', {
      property: 'matrix',
      value: _.set(matrix, payload.path, payload.value),
    });
    store.commit('setMiscItemConditions', Object.assign([], store.getters.allConditions.concat([payload.value])));
    store.commit('addUnsavedChange');
  },

  /**
   * Set the Constituents of the currently selected Item
   * 
   * @param {DictionaryState} store
   * @param {string[]} value
   * @returns void
   */
  setConstituents(store: ActionContext<DictionaryState, any>, value: string[]) {
    store.commit('setItemProperty', {
      property: 'constituents',
      value,
    });
    // Set the Constituents for the "Misc" Item, which populates select boxes
    store.commit('setMiscItemProperty', {
      property: 'constituents',
      value: _.uniq(store.getters.allConstituents.concat(value)),
    });
    store.commit('addUnsavedChange');
  },

  /**
   * Set the Makes of the currently selected Item
   * 
   * @param {DictionaryState} store
   * @param {string[]} value
   * @returns void
   */
  setMakes(store: ActionContext<DictionaryState, any>, value: string[]) {
    store.commit('setItemProperty', {
      property: 'makes',
      value,
    });
    // Set the Makes for the "Misc" Item, which populates select boxes
    store.commit('setMiscItemProperty', {
      property: 'makes',
      value: _.uniq(store.getters.allMakes.concat(value)),
    });
    store.commit('addUnsavedChange');
  },

  /**
   * Add Report Type
   * 
   * @param {DictionaryState} store
   * @param {DictionaryReportType} reporttype
   * @returns void
   */
  addReportType(store: ActionContext<DictionaryState, any>, reporttype: DictionaryReportType) {
    store.commit('setReportTypes', store.state.current.reporttypes?.concat(reporttype));
    store.commit('addUnsavedChange');
  },

  /**
   * Update SystemPropety value increment the "unsaved changes" count
   * 
   * @param {DictionaryState} store
   * @param {DictionaryItem} item
   * @returns void
   */
  setSystemPropertyValue(store: ActionContext<DictionaryState, any>, payload: { index: number, data: any }) {
    store.commit('setSystemPropertyValue', payload);
    store.commit('addUnsavedChange');
  },

  /**
   * Update SystemProperty propertykey
   * 
   * @param {DictionaryState} store
   * @param {DictionaryItem} item
   * @returns void
   */
  setSystemPropertyKey(store: ActionContext<DictionaryState, any>, systemproperty: SystemProperty) {
    store.commit('setSystemPropertyKey', systemproperty);
    store.commit('addUnsavedChange');
  },

  /**
  * Adds new systemproperty
  */
  addSystemProperty(store: ActionContext<DictionaryState, any>, systemproperty: SystemProperty): void {
    store.commit('addSystemProperty', systemproperty);
    store.commit('addUnsavedChange');
  },

  /**
  * Removes existing systemrpoperty
  */
  removeSystemProperty(store: ActionContext<DictionaryState, any>, index: number): void {
    store.commit('removeSystemProperty', index);
    store.commit('addUnsavedChange');
  },

  /**
  * Adds a reporttype, and increments the "unsaved changes" count
  */
  addSystemPropertyReporttype(store: ActionContext<DictionaryState, any>, data): void {
    store.commit('addSystemPropertyReporttype', data);
    store.commit('addUnsavedChange');
  },

  /**
  * Removes a reporttype, and increments the "unsaved changes" count
  */
  removeSystemPropertyReporttype(store: ActionContext<DictionaryState, any>, payload: { index: number, data: any }): void {
    store.commit('removeSystemPropertyReporttype', payload);
    store.commit('addUnsavedChange');
  },

  /**
   * Update Item and increment the "unsaved changes" count
   * 
   * @param {DictionaryState} store
   * @param {DictionaryItem} item
   * @returns void
   */
  setSystemPropertyReportTypes(store: ActionContext<DictionaryState, any>, payload: { index: number, data: any }) {
    store.commit('setSystemPropertyReportTypes', payload);
    store.commit('addUnsavedChange');
  },

};
