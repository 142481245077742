<template>
  <div class="report-section depository">
    <div class="row">
      <div class="col-md-4"><legend>Depositary</legend></div>
      <!--div class="col-md-8 text-right">
        <div
          v-if="
            report && report.depositorytenancy && report.depositorytenancy.uid
          "
        >
          <button
            v-if="!hasUnsavedChanges"
            class="btn btn-outline-primary mr-2"
            @click="submitDeductions"
            :disabled="isSubmitting"
          >
            <i class="fas fa-search-dollar"></i> Submit deductions
          </button>
          <button
            v-if="report.attachments.report"
            class="btn btn-outline-primary mr-2"
            @click="uploadReport"
            :class="{ uploaded: report.attachments.reportuploaddate }"
            :disabled="isSubmitting"
          >
            <i class="far fa-file-pdf"></i> Submit PDF/Meter reading
          </button>
          <button class="btn btn-outline-primary" @click="unlinkTenancy">
            <i class="fas fa-unlink"></i> Unlink tenancy
          </button>
        </div>
        <button
          v-else
          class="btn btn-outline-primary"
          @click="showDepositoryPropertyListModal()"
        >
          <i class="fas fa-link"></i> Link tenancy
        </button>
      </div-->
    </div>

    <div
      v-if="statereport && statereport.depositorytenancy && statereport.depositorytenancy.uid"
    >
      <div
        class="card"
        v-for="(room, $index) in roomswithdeductions"
        :key="room._uuid"
        @click.stop="setActiveCard($index)"
      >
        <report-room
          :room="room"
          :index="getRoomIndex(room)"
          :opened="
            roomsShowBody[$index] === undefined ? true : roomsShowBody[$index]
          "
          :toggle="true"
          :class="{ activeCard: focused === $index }"
          ref="cards"
          @showBody="syncRoomsShowBody"
        />
      </div>
    </div>

    <DepositoryPropertyListModal
      ref="depositoryPropertyListModal"
      @select="linkDepositoryTenancy"
      @submit="submitToDepository"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import _ from "lodash";
import { checkitem } from "@/utilities";
import { useEvent } from '@/eventBus';
import { Report, Room, Section, Type, Item, Note, DepositoryTenancy, Dictionary, DictionaryRoom } from "@/models";
import DepositoryPropertyListModal from "@/components/modals/DepositoryPropertylistModal.vue";
import ReportRoom from "@/components/depository/Room.vue";

const store = useStore();
const toast = useToast();

// Convert @Getter to computed properties
const hasUnsavedChanges = computed(() => store.getters['reports/hasUnsavedChanges']);
const dictionary = computed(() => store.getters['dictionary/current']);
const statereport = computed(() => store.getters['reports/current']);

// Convert @Action to methods using store.dispatch
const setReportDeep = (payload: { path: string; data: any }) => store.dispatch('reports/setReportDeep', payload);
const submitDeductionsToState = (id: string) => store.dispatch('reports/submitDeductions', id);
const uploadReportToState = (id: string) => store.dispatch('reports/uploadReport', id);

const focused = ref(-1);
const roomsShowBody = ref<boolean[]>([]);
const isSubmitting = ref(false);
const depositoryPropertyListModal = ref(null);
const cards = ref(null);

onMounted(() => {
  roomswithdeductions.value.forEach((room: Room, index) => {
    roomsShowBody.value[index] = true;
  });
});

const roomswithdeductions = computed(() => {
  let roomlist: Room[] = [];
  roomlist = statereport.value?.rooms?.filter((room: Room) => checkroom(room));
  return roomlist;
});

const getRoomIndex = (room: Room) => statereport.value?.rooms.findIndex((r: Room) => r._uuid === room._uuid);

const checkroom = (room: Room) => {
  let result = room?.sections.filter((section: Section) => checksection(room, section));
  return result?.length > 0;
};

const checksection = (room: Room, section: Section) => {
  let result = section?.types.filter((type: Type) => checktype(room, section, type));
  return result?.length > 0;
};

const checktype = (room: Room, section: Section, type: Type) => {
  let result = type?.items.filter((item: Item) => checkitem(room, section, type, item));
  return result?.length > 0;
};

const isCategoryFourRoom = (room: Room) => {
  let currentroomtype = room?.type;
  let found: number = dictionary.value.rooms.findIndex((dr: DictionaryRoom) => dr.category === 4 && dr.slug === currentroomtype);
  return found >= 0;
};

const showDepositoryPropertyListModal = () => {
  const modal = depositoryPropertyListModal.value as any; // Make sure to define refs correctly
  modal.init(statereport.value);
  
  (modal.$el as any).modal("show");
};

const linkDepositoryTenancy = (tenancy: DepositoryTenancy) => {
  setReportDeep({ path: "depositorytenancy", data: tenancy });
  const modal = depositoryPropertyListModal.value as any; // Define refs correctly
  (modal.$el as any).modal("hide");
};

const unlinkTenancy = () => {
  setReportDeep({
    path: "depositorytenancy",
    data: new DepositoryTenancy(),
  });
};

const submitDeductions = () => {
  if (statereport.value.id) {
    isSubmitting.value = true;
    submitDeductionsToState(statereport.value.id).then(() => {
      toast.success(`Posted all deductions to Depositary`);
      isSubmitting.value = false;
    });
  }
};

const submitToDepository = (tenancy: DepositoryTenancy) => {
  setReportDeep({ path: "depositorytenancy", data: tenancy });
  const modal = depositoryPropertyListModal.value as any;
  (modal.$el as any).modal("hide");
};

const uploadReport = () => {
  if (statereport.value.id) {
    isSubmitting.value = true;
    uploadReportToState(statereport.value.id).then(() => {
      toast.success(`Successfully uploaded report PDF and meter readings to Depositary`);
      isSubmitting.value = false;
    });
  }
};

/**
 * Expand all rooms
 */
const expandAll = (): void => {
  const roomsOpened: boolean[] = [];
  if (cards.value) {
    (cards as any[]).forEach((card, index) => {
      card.showBody = true;
      roomsOpened[index] = true;
    });
  }

  roomsShowBody.value = roomsOpened;
}

/**
 * Collapse all rooms
 */
const collapseAll = (): void => {
  const roomsClosed: boolean[] = [];
  if (cards.value) {
    (cards as any[]).forEach((card, index) => {
      card.showBody = false;
      roomsClosed[index] = false;
    });
  }
  roomsShowBody.value = roomsClosed;
}

useEvent("expandAll", () => expandAll());
useEvent("collapseAll", () => collapseAll());

/**
 * Highlight Current active Room
 */
const setActiveCard = (index: any) => {
  focused.value = index;
}

/**
 * Show/Hide Room Body
 *
 * @param {Number} index
 * @param {Boolean} showBody
 * @returns boolean value if room body is shown/hidden
 */
const syncRoomsShowBody = (index: number, showBody: boolean) => {
  const cardsrefArray = cards as any[];
  if (cardsrefArray.length !== roomsShowBody.value.length) {
    // To cope with adding/removing rooms
    // or sometimes mounted won't set up roomsShowBody.value correctly
    const rooms: boolean[] = [];
    for (let i = 0; i < cardsrefArray.length; i++) {
      if (roomsShowBody.value[i] === undefined) {
        rooms[i] = true;
      } else if (i === index) {
        rooms[i] = showBody;
      } else {
        rooms[i] = roomsShowBody.value[i];
      }
    }
    roomsShowBody.value = rooms;
  } else {
    roomsShowBody.value = roomsShowBody.value.map((value, i) => {
      return i === index ? showBody : value;
    });
  }
}
</script>


<style scoped lang="scss">
@import "@/assets/sass/bootstrap/_variables.scss";

.card {
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.activeCard {
  border-color: #ff0000;
}

.card-header {
  &.room {
    a {
      display: inline-block;
      margin-left: 1rem;
    }
    small {
      font-size: 56%;
    }
  }
}

.btn {
  padding-bottom: 0.2rem;
  &.disabled {
    opacity: 0.35;
  }
}

.uploaded {
  border-color: green;
  border-width: 2px;
}
</style>
