<template>
  <nav>
    <ul class="pagination">
      <li
        v-for="(link, $index) in links"
        :key="$index"
        class="page-item"
        :class="{ active: $route.params.search == link }"
      >
        <router-link :to="{ name: 'email-template-clients' , params: {search: link}}" class="page-link">
          {{ link.toUpperCase() }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import { ref, onMounted, watch, inject } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { Customer } from "@/models/customer.model";
import { useToast } from "vue-toastification";

const store = useStore();
const route = useRoute();
const toast = useToast();
const actProperty: any = inject('actProperty');

const links = [
  "a", "b", "c", "d", "e", "f", "g", "h", "i", "j",
  "k", "l", "m", "n", "o", "p", "q", "r", "s", "t",
  "u", "v", "w", "x", "y", "z", "0-9"
];

const getCustomersAction = (payload?: {
  starts_with?: string;
  company_name?: string;
  branch_name?: string;
}): Promise<Customer[]> => {
  return store.dispatch('customers/getCustomers', payload);
};

const getCustomers = (starts_with: string) => {
    getCustomersAction({ starts_with })
      .catch((err: any) => actProperty.displayError(err));
  }

const init = () => {
  if (route.params.search) {
    getCustomers((route.params.search as string));
  }
};

onMounted(init);

watch(() => route.params.search, (currentSearch, prevSearch) => {
  if (currentSearch !== prevSearch) {
    init();
  }
});
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
