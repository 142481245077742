import _castArray from 'lodash/castArray';
import _get from 'lodash/get';

import { toCamelCase, omitDeep } from '@/utilities';

import {
  Base,
} from '@/models';

export class PmPropertyManager extends Base {
  public id: string;
  public name: string;
  public email: string;
  public telephone: string;
  public constructor(data?: Partial<PmPropertyManager>) {
    super(data);
    data = toCamelCase(data);

    this.id = _get(data, 'id', '');
    this.name = _get(data, 'name', '');
    this.email = _get(data, 'email', '');
    this.telephone = _get(data, 'telephone', '');
  }

  public toObject() {
    return omitDeep({ ...this }, ['_uuid']);
  }
}