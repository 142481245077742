import _get from 'lodash/get';

import { Item } from '@/models';
import { TenantComments } from './tenantcomments.model';
import { LandlordComments } from './landlordcomments.model';

export class OrderedItem extends Item {
  public order: number;
  public roomname: String;
  public roomindex: number;
  public section: String;
  public sectionindex: number;
  public type: String;
  public typeindex: number;

  public item: Item;  // This is the actual item in report, kept here just for easy access
  public itemindex: number;

  public constructor(data?: Partial<Item>) {
    super(data);
  }

  public saveChangesToReportItem() {
    if (!this.item.tenantcomments)
      this.item.tenantcomments = new TenantComments(this.tenantcomments);
    else
      this.item.tenantcomments.load(this.tenantcomments)

    if (!this.item.landlordcomments)
      this.item.landlordcomments = new LandlordComments(this.landlordcomments);
    else
      this.item.landlordcomments.load(this.landlordcomments)
  }
}