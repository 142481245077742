<template>
  <div class="modal" data-keyboard="true" tabindex="-1" role="dialog" :id="`${name}-modal`"
    :aria-labelledby="`${name}-modal-label`" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="`${name}-modal-label`">{{ getFieldDisplayName() }}</h5>
          <button id="close" type="button" class="close" data-dismiss="modal" aria-label="Close"
            @click.prevent="hide(false)">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div>
            <textarea class="form-control" rows="20" v-model.lazy="othernotes"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed,ref } from 'vue';
import { useStore } from 'vuex';
import { Booking, Document } from '@/models';
const store = useStore();
import $ from 'jquery';
import { defineProps, defineExpose } from 'vue';
const booking = computed(() => store.getters['diary/booking']);
const props = defineProps<{
  name: string;
  title?: string;
  notes?: string;
}>();
const initdata = ref({field:'',title:''});

const othernotes = computed({
  get: () =>  {
    return booking.value[initdata.value.field as keyof Booking];
  },
  set: (val) => {
    store.commit('diary/setBookingDeep', { path: initdata.value.field, data: val });
  }
});

const init = (data:any) => {
  initdata.value = {field:'',title:''};
  initdata.value = data;
}
const show = () => {
  if ($(`#${props.name}-modal` + 'Backdrop').length == 0) {
    const backdropDiv = $(`<div class="modal-backdrop fade show" id="${props.name}-modalBackdrop"></div>`);
    $('body').append(backdropDiv);
    $(`#${props.name}-modal`).show();
  }
}
const hide = async (val: boolean) => {
  if ($(`#${props.name}-modal`).length > 0) {
    $(`#${props.name}-modal` + 'Backdrop').remove();
    $(`#${props.name}-modal`).hide();
  }
};
const getFieldDisplayName = () => Booking.getFieldDisplayName(initdata.value.field);

defineExpose({ init,show, hide });
</script>

<style scoped>
.miscnotes-textarea {
  resize: none;
}
</style>