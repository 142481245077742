<template>
  <div class="report">
    <div class="jumbotron">
      <div v-if="errormessage">
        <h1 class="display-4">Error!</h1>
        <p class="lead">
          {{ errormessage }}
        </p>
      </div>
      <div v-else>
        <h1 class="display-4">Thank you!</h1>
        <p class="lead">
          Thank you for activating ACT on The Depositary. We are now ready to
          send/receive data between the two systems.
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue';
const em = ref('');
const errormessage = computed({
  get: () => em.value,
  set: (val: string) => em.value = val,
});
</script>
