import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

export class ReportTiming extends Base {

  public id: string;
  public propertytype: string;
  public timing: number;
  public minutesperitem: number;
  public tenanttoarriveafter: number;


  public constructor(data?: Partial<ReportTiming>) {
    super(data);
    data = toCamelCase(data);

    this.id = _get(data, 'id', '');
    this.propertytype = _get(data, 'propertytype', '');
    // This is to correct an unfortunate spelling mistake made in the default schedulers
    this.propertytype = this.propertytype.replaceAll("Frunished", "Furnished");
    this.timing = _get(data, 'timing', 0.0);
    this.minutesperitem = _get(data, 'minutesperitem', 0);
    this.tenanttoarriveafter = _get(data, 'tenanttoarriveafter', 0);
  }
}