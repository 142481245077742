/**
 * Getters
 *
 * Vuex allows us to define "getters" in the store.
 * - You can think of them as computed properties for stores.
 * - Like computed properties, a getter's result is cached based on its dependencies,
 *   and will only re-evaluate when some of its dependencies have changed.
 */
import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { SysAdminState } from './types';
import { CognitoUser, CognitoGroup } from '@/models';

export const getters: GetterTree<SysAdminState, RootState> = {

    /**
     * The currently-queried list of users, returned from GET {API_URL}/users
     * 
     * @param {SysAdminState} state 
     * @returns void
     */
    list: (state: SysAdminState): CognitoUser[] => state.list,

    /**
     * The currently-queried list of groups, returned from GET {API_URL}/groups
     * 
     * @param {SysAdminState} state 
     * @returns void
     */
    groups: (state: SysAdminState): CognitoGroup[] => state.groups,

    /**
     * The current CognitoUser
     * 
     * @param {SysAdminState} state 
     * @returns user CognitoUser
     */
    current: (state: SysAdminState): CognitoUser => state.current,

    /**
    * The updates which have been made since creating/loading/last-saving the current user
    * 
    * @param {SysAdminState} state 
    * @returns void
    */
    updates: (state: SysAdminState): string[] => state.updates,

    /**
     * The updates which have been made since creating/loading/last-saving the current user
     * 
     * @param {SysAdminState} state 
     * @returns void
     */
    hasUnsavedChanges: (state: SysAdminState): boolean => state.updates.length > 0,
};
