<template>
  <div class="report">
    <div class="jumbotron">
      <h1 class="display-4">Oops!</h1>
      <p class="lead">Report not found</p>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
</style>