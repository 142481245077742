import _get from 'lodash/get';
import _castArray from 'lodash/castArray';
import moment from "moment-timezone";

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

export class DepositoryDeduction extends Base {

  public externalId: string;
  public name: string;
  public description: string;
  public amountSought: string;
  public type: string;
  public externalCreationTime: string;
  public inventoryRef: string;
  public checkoutReportRef: string;
  public evidenceUrls: string[];

  public constructor(data?: Partial<DepositoryDeduction>) {
    super(data);
    data = toCamelCase(data);

    this.externalId = _get(data, 'externalId', '');
    this.name = _get(data, 'name', '');
    this.description = _get(data, 'description', '');
    this.amountSought = _get(data, 'amountSought', '');
    this.type = _get(data, 'type', '');
    this.externalCreationTime = _get(data, 'externalCreationTime', '');
    this.inventoryRef = _get(data, 'inventoryRef', '');
    this.checkoutReportRef = _get(data, 'checkoutReportRef', '');
    this.evidenceUrls = _get(data, 'evidenceUrls', []);
  }

  public equals(other: DepositoryDeduction) {
    if (this.externalId != other.externalId) return false;
    if (this.name != other.name) return false;
    if (this.description != other.description) return false;
    if (this.type != other.type) return false;
    if (this.inventoryRef != other.inventoryRef) return false;
    if (this.checkoutReportRef != other.checkoutReportRef) return false;
    if (this.evidenceUrls.length != other.evidenceUrls.length) return false;
    if (this.evidenceUrls.length && other.evidenceUrls.length) {
      let index1: number = this.evidenceUrls.findIndex((url1: string) => {
        let index2 = other.evidenceUrls.findIndex((url2: string) => url1 === url2);
        if (index2 >= 0) return true;
      });
      if (index1 < 0) return false;
    }
    return true;
  }

}
