/**
 * Getters
 *
 * Vuex allows us to define "getters" in the store.
 * - You can think of them as computed properties for stores.
 * - Like computed properties, a getter's result is cached based on its dependencies,
 *   and will only re-evaluate when some of its dependencies have changed.
 */
import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { SMSTemplatesState } from './types';
import { SMSTemplate, SMS } from '@/models';

export const getters: GetterTree<SMSTemplatesState, RootState> = {

  /**
     * is the template list empty?
     * 
     * @param {SMSTemplatesState} state 
     * @returns Boolean if office is empty
     */
  isEmpty: (state: SMSTemplatesState): boolean => {
    return state.list.length === 0;
  },

  /**
   * The currently-queried list of smstemplates, returned from GET {API_URL}/smstemplates
   * 
   * @param {SMSTemplatesState} state 
   * @returns void
   */
  list: (state: SMSTemplatesState): SMSTemplate[] => state.list,

  /**
   * The current SMSTemplate, populated by GET {API_URL}/smstemplate/{id}
   * 
   * @param {SMSTemplatesState} state 
   * @returns void
   */
  current: (state: SMSTemplatesState): SMSTemplate => state.current,

  /**
   * The tenant sms, populated by GET {API_URL}/smstemplateswithoptions
   * 
   * @param {SMSTemplatesState} state 
   * @returns void
   */
  tenantsms: (state: SMSTemplatesState): SMS => state.tenantsms,

  /**
   * The updates which have been made since creating/loading/last-saving the current report
   * 
   * @param {SMSTemplatesState} state 
   * @returns void
   */
  updates: (state: SMSTemplatesState): string[] => state.updates,

  /**
   * The updates which have been made since creating/loading/last-saving the current report
   * 
   * @param {SMSTemplatesState} state 
   * @returns void
   */
  hasUnsavedChanges: (state: SMSTemplatesState): boolean => state.updates.length > 0,
};
