import { Module } from 'vuex';
import { RemotecommandState } from './types';
import { Remotecommand } from '@/models/remotesupport.model';

import { getters } from './getters';
import { RootState } from '../types';
import { actions } from './actions';
import { mutations } from './mutations';

/**
 * Default state
 */
export const state: RemotecommandState = {
    currentcommand: new Remotecommand,
	commandlist: [],
};

/**
 * Use namespaced Vuex modules
 */
const namespaced: boolean = true;

/**
 * Module export
 */
const customers: Module<RemotecommandState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};

export default customers;
