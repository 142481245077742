<template>
  <div class="list">
    <div
      v-if="!customerdictionary.rooms.length"
      class="alert alert-warning text-center"
      style="margin-top: 10px"
    >
      <h2 class="mt-1 mb-2">Rooms</h2>
      <h5 class="mt-1 mb-2">No items found</h5>
      <p class="mb-1"></p>
    </div>

    <table v-else class="table table-hover">
      <thead>
        <tr>
          <th class="name">Name</th>
          <th class="category text-center">Category</th>
          <th class="priority text-center">Priority</th>
          <th class="actions"></th>
        </tr>
      </thead>
      <Container
        @drop="onDrop"
        tag="tbody"
        lock-axis="y"
        drag-class="opacity-ghost"
        :animation-duration="500"
      >
        <Draggable
          v-for="room in customerdictionary.rooms"
          :key="room.slug"
          tag="tr"
        >
          <td>{{ room.name }}</td>
          <td class="text-center">{{ room.category }}</td>
          <td class="text-center">{{ room.priority }}</td>

          <td class="text-center">
            <button
              class="btn btn-outline-danger"
              @click="remove(room)"
              v-on:click.stop
            >
              <i class="fas fa-trash-alt"></i>
            </button>
            <button
              class="btn btn-outline-primary"
              @click="edit(room)"
              v-on:click.stop
            >
              <i class="fas fa-pencil-alt"></i>
            </button>
          </td>
        </Draggable>
      </Container>
    </table>

    <!-- Modals -->
    <DictionaryRoomModal
      :id="`room-modal-edit`"
      ref="dictionaryRoomModalEdit"
      :operation="'edit'"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import { DictionaryRoom } from '@/models';
import DictionaryRoomModal from '@/components/modals/DictionaryRoomModal.vue';

import { Container, Draggable } from "vue-dndrop";

const dictionaryRoomModalEdit = ref(null);

const store = useStore();
const toasted = useToast();
const actProperty: any = inject('actProperty');

const customerdictionary = computed(() => store.getters['customerdictionary/current']);
const rooms = computed(() => store.getters['customerdictionary/rooms']);

const removeFromRooms = (room: DictionaryRoom) => {
  store.commit('customerdictionary/removeFromRooms', room);
};

const setSelectedRoom = (room: DictionaryRoom) => {
  store.commit('customerdictionary/setSelectedRoom', room);
};

const setRoomList = async (rooms: DictionaryRoom[]) => {
  await store.dispatch('customerdictionary/setRoomList', rooms);
};

const edit = (room: DictionaryRoom) => {
  setSelectedRoom(room);
  const modal = dictionaryRoomModalEdit.value;
  modal.show();
}

const remove = async (room: DictionaryRoom) => {
  actProperty
    .confirmPrompt()
    .then(() => removeFromRooms(room))
    .then(() => toasted.success("Room deleted"))
    .catch((err: any) => actProperty.displayError(err));
};

const onDrop = (dragResult: any) => {
  setRoomList(applyDrag(rooms.value, dragResult));
};

const applyDrag = (rooms: DictionaryRoom[], dragResult: any) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return rooms;

  const result = [...rooms];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
table {
  display: table;
}

tr {
  cursor: pointer;
  display: table-row !important;
}
tr[style*="transform"] {
  border-collapse: separate !important;
}

th {
  &.name {
    width: 10rem;
  }
  &.systemname {
    width: 10rem;
  }
  &.category {
    width: 8rem;
  }
  &.priority {
    width: 8rem;
  }
  &.actions {
    width: 8rem;
  }
}
</style>
