<template>
  <!-- "New Item" Modal -->
  <div
    class="modal fade"
    data-keyboard="true"
    tabindex="-1"
    role="dialog"
    id="add-newuser-modal"
    aria-labelledby="`add-newuser-modal-label`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="add-newuser-modal-label">New User</h5>

          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <fieldset>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Email</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  v-model="email"
                  @blur="setEmailTouched()"
                />
                <p v-if="!emailIsValid" class="error-message">
                  {{ invalidEmailMessage }}
                </p>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Temporary password</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  v-model="temporaryPassword"
                  @blur="setPasswordTouched()"
                />
                <p v-if="!passwordIsValid" class="error-message">
                  {{ invalidPasswordMessage }}
                </p>
                <div
                  v-for="guide in passwordPolicyGuide"
                  :key="guide"
                  style="margin-top=2px;"
                >
                  {{ guide }}
                </div>
              </div>
            </div>
          </fieldset>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary mr-auto"
            data-dismiss="modal"
            @click.prevent="reset()"
          >
            Back
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            @click.prevent="add()"
            :disabled="!canAdd"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { CognitoUser } from "@/models";
import { useToast } from "vue-toastification";

const email = ref('');
const temporaryPassword = ref('');
const emailTouched = ref(false);
const passwordTouched = ref(false);
const invalidEmailMessage = ref('');
const invalidPasswordMessage = ref('');
const store = useStore();
const actProperty: any = inject('actProperty');
const toasted = useToast();

const addUser = (payload: { username: string; temporary_password: string }): Promise<void> =>  {
  return store.dispatch('sysadmin/addUser', payload);
};

const passwordPolicyGuide = [
  "Contains at least 1 number",
  "Contains at least 1 special character (^ $ * . [ ] { } ( ) ? - \" ! @ # % & /  , > < ' : ; | _ ~ ` + =)",
  "Contains at least 1 uppercase letter",
  "Contains at least 1 lowercase letter",
];

const reset = () => {
  email.value = '';
  temporaryPassword.value = '';
  emailTouched.value = false;
  invalidEmailMessage.value = '';
  passwordTouched.value = false;
  invalidPasswordMessage.value = '';
};

const validateEmail = (email: string) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

const setEmailTouched = () => {
  emailTouched.value = true;
};

const setPasswordTouched = () => {
  passwordTouched.value = true;
};

const emailIsValid = computed(() => {
  if (emailTouched.value) {
    const valid = validateEmail(email.value);
    invalidEmailMessage.value = valid ? '' : 'Invalid email address';
    return valid;
  }
  return true;
});

const passwordIsValid = computed(() => {
  if (passwordTouched.value) {
    const numberRe = /[0-9]/;
    const uppercaseLetterRe = /[A-Z]/;
    const lowercaseLetterRe = /[a-z]/;
    const specialCharRe = /[\^\$\*\.\[\]\{\}\(\)\?\-\"\!\@\#\%\&\/\,\>\<\'\:\;\\\|\_\~`\+=]/;
    if (
      !numberRe.test(temporaryPassword.value) ||
      !uppercaseLetterRe.test(temporaryPassword.value) ||
      !lowercaseLetterRe.test(temporaryPassword.value) ||
      !specialCharRe.test(temporaryPassword.value)
    ) {
      invalidPasswordMessage.value = 'Invalid password';
      return false;
    }
    return true;
  }
  return false;
});

const canAdd = computed(() => {
  return email.value && temporaryPassword.value && emailIsValid.value && passwordIsValid.value;
});

const add = async () => {
  addUser({
    username: email.value,
    temporary_password: temporaryPassword.value,
  }).then(() => reset())
  .catch((err: any) => actProperty.displayError(err));
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.error-message {
  color: #dc3545;
}
</style>