<template>
  <VueFinalModal class="confirm-modal" content-class="confirm-modal-content" overlay-transition="vfm-fade"
    content-transition="vfm-fade" display-directive="show" background="interactive" :hide-overlay="true"
    @update:model-value="(val) => emit('update:modelValue', val)" :modal-id="modalId">
    <VueDragResize :is-active="false" :isResizable="false" :w="dynaWidth" :h="dynaHeight" :x="position.x" :y="position.y"
      class="bg-primary-100 dark:bg-gray-800" @dragging="dragResize">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="pdf-viewer-modal-label">{{ title }}</h5>

          <div>
            <button type="button" class="close" aria-label="Close" @click.prevent="hide()">
              <span aria-hidden="true">&times;</span>
            </button>
            <button type="button" class="close" aria-label="Open in new window" @click.prevent="openseparate">
              <i class="far fa-window-maximize"></i>
            </button>
          </div>
        </div>

        <div class="modal-body" v-if="document && document.src">

          <iframe v-if="getDocumenttype != 'image'" class="document-iframe" :key="modalkey" :src="documentsrc"
            style="height: 60vh; width: 100%" frameborder="0"></iframe>

          <div v-else style="height: 60vh; width: 100%"><img :src="documentsrc" style="height: 100%; width: 100%" /></div>

        </div>
      </div>
    </VueDragResize>
  </VueFinalModal>
</template>

<script lang="ts" setup>
import { computed, ref, defineProps, PropType, defineEmits, reactive, onMounted,defineExpose } from 'vue';
import { Document } from "@/models";
import { VueFinalModal, useVfm } from 'vue-final-modal';
import VueDragResize from 'vue3-drag-resize';
import 'vue-final-modal/style.css';
const vfm = useVfm();

const emit = defineEmits<{
    (e: 'update:modelValue', modelValue: boolean): void
  }>()

const props = defineProps({
  modalId: { type: String as PropType<string>, default: '' },
  name: { type: String as PropType<string>, default: '' },
  document: { type: Document as PropType<Document> },
  title: { type: String as PropType<string>, default: '' },
  modalkey: { type: String as PropType<string>, default: '' },
});
const position = reactive({
  x: 0, // initial x position
  y: 0, // initial y position
});

const width = ref(0)
const height = ref(0)
const top = ref(0)
const left = ref(0)
function dragResize(newRect: any) {
  width.value = newRect.width
  height.value = newRect.height
  top.value = newRect.top
  left.value = newRect.left
}
const openseparate = () => {
  hide();
  window.open(props.document.src);
};

const dynaWidth = ref(window.innerWidth*.8);
const dynaHeight = ref(window.innerHeight*.6);

// Computed properties
const documentsrc = computed(() => {
  let src = props.document.src;
  let filextension = props.document?.src?.toLocaleLowerCase();
  if (filextension?.endsWith("doc") || filextension?.endsWith("docx")) {
    src = `https://docs.google.com/gview?url=${props.document.src}&embedded=true`;
  }
  return src;
});

const getDocumenttype = computed(() => {
  let filename = props.document?.src?.toLocaleLowerCase();
  return (filename.endsWith('jpg') || filename.endsWith('png')) ? 'image' : 'document';
});
// Center the component on mount
onMounted(() => {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  position.x = (screenWidth - dynaWidth.value) / 2;
  position.y = (screenHeight - dynaHeight.value) / 2;
});
const hide = () => {
  vfm.close(props.modalId);
};
defineExpose({hide });

</script>


<style lang="scss">
.confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-radius: 0.5rem;
}

.confirm-modal-content>*+* {
  margin: 0.5rem 0;
}

.confirm-modal-content h1 {
  font-size: 1.375rem;
}

.confirm-modal-content button {
  margin: 0.25rem 0 0 auto;
  padding: 0 8px;
  // border: 1px solid;
  border-radius: 0.5rem;
}

.dark .confirm-modal-content {
  background: #000;
}

.vdr.active:before {
  outline: none !important
}
</style>

