import { Module } from 'vuex';
import { RootState } from '../types';
import { OfficesState } from './types';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

// Default state
export const state: OfficesState = {
  currentOfficeId: '',
  offices: [],
};

// Use namespaced Vuex modules
const namespaced: boolean = true;

// Module export
const offices: Module<OfficesState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};

export default offices;
