<template>
  <div class="report">
    <div class="jumbotron">
      <h1 class="display-4">Sorry!</h1>
      <p class="lead">
        This report is now outside the cooling down period. No further changes
        are permitted.
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
</style>
