<template>
  <div>
    <div v-for="item in itemswithdeductions" :key="item._uuid">
      <report-room-item :item="item" :index="getItemIndex(item)" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, provide, ref, toRefs, defineProps } from 'vue';
import { checkitem } from "@/utilities";
import { Room, Section, Type, Item, Note } from "@/models";
import ReportRoomItem from "@/components/depository/Item.vue";

// Define props with type annotations
const props = defineProps<{
  index: number;
  room: Room;
  section: Section;
  type: Type;
}>();

// Destructuring props as refs
const { room, section, type } = toRefs(props);

// Provide values
const typeIndex = ref(props.index);
const typeSlug = computed(() => type.value?.slug);
const typeName = computed(() => type.value?.name);

// Provide functions
provide("typeIndex", typeIndex);
provide("typeSlug", typeSlug);
provide("typeName", typeName);

// Computed property for filtered items
const itemswithdeductions = computed(() => {
  return type.value?.items.filter((item: Item) =>
    checkitem(room.value, section.value, type.value, item)
  ) || [];
});

// Method to get item index
const getItemIndex = (item: Item): number => {
  return type.value?.items.findIndex((i: Item) => i._uuid === item._uuid) ?? -1;
};

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
