<template>
  <div class="report">
    <h1>
      {{ actProperty.formatReportType(getReporttype(), dictionary) }} Designer
    </h1>

    <div class="report-section invoice">
      <legend>Customer</legend>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Company</label>
        <div class="col-sm-3">
          <multiselect-text
            v-model="customerCompanyName"
            :value="customerCompanyName"
            :options="companyNameOptions"
            :allow-empty="true"
            :taggable="true"
            @tag="onTagCompanyName"
            @select="onSelectCompanyName"
          >
            <template #clear v-if="customerCompanyName.length">
              <i
                @mousedown.prevent.stop="onTagCompanyName('')"
                class="multiselect__clear fa fa-times"
                aria-label="Clear Company Name"
              ></i>
            </template>
          </multiselect-text>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Branch</label>
        <div class="col-sm-3">
          <multiselect-text
            v-model="customerBranchName"
            :value="customerBranchName"
            :options="branchNameOptions"
            :allow-empty="true"
            :disabled="customerCompanyName.length < 1"
            :taggable="true"
            @tag="onTagBranchName"
            @select="onSelectBranchName"
          >
            <template #clear v-if="customerBranchName.length">
              <i
                @mousedown.prevent.stop="onTagBranchName('')"
                class="multiselect__clear fa fa-times"
                aria-label="Clear Branch Name"
              ></i>
            </template>
          </multiselect-text>
        </div>
      </div>
    </div>

    <!-- Tabs -->
    <ul class="nav nav-tabs mb-3" role="tablist">
      <li class="nav-item">
        <router-link
          to="customerdictionaryitems"
          class="nav-link"
          active-class="active"
        >
          Items
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          to="customerdictionaryconditions"
          class="nav-link"
          active-class="active"
        >
          Condition Matrix
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          to="customerdictionarytemplates"
          class="nav-link"
          active-class="active"
        >
          Templates
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          to="customerdictionaryrooms"
          class="nav-link"
          active-class="active"
        >
          Rooms
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          to="customerdictionarymaintenanceflags"
          class="nav-link"
          active-class="active"
        >
          Maintenance flags
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          to="customerdictionarycompliance"
          class="nav-link"
          active-class="active"
        >
          Compliance
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          to="customerdictionarytnc"
          class="nav-link"
          active-class="active"
        >
          Terms &amp; Conditions
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          to="customerdictionaryaddress"
          class="nav-link"
          active-class="active"
        >
          Address
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          to="customerdictionarylogo"
          class="nav-link"
          active-class="active"
        >
          Report Configurations
        </router-link>
      </li>
    </ul>

    <!-- Tab content (child routes) -->
    <router-view :key="$route.name" style="padding-bottom: 60px" />

    <!-- Bottom navbar -->
    <nav class="navbar fixed-bottom navbar-dark bg-primary">
      <div class="container-fluid">
        <div id="status-bar-buttons">
          <div
            v-if="
              router.currentRoute.value.name ===
                'customer-dictionary-compliance' ||
              $router.currentRoute.value.name ===
                'customer-dictionary-maintenanceflags' ||
              $router.currentRoute.value.name === 'customer-dictionary-rooms'
            "
            class="btn-group"
            role="group"
            aria-label="Add Room/Item"
          >
            <button
              class="btn btn-light"
              @click.prevent="showAddDialog()"
              title="Add Question"
            >
              <i class="fas fa-plus"></i> Add
            </button>
          </div>
        </div>

        <div class="ml-auto text-light">
          <button
            class="btn btn-outline-light"
            @click.prevent="saveCustomerDictionary(true)"
          >
            <i class="fas fa-save"></i> Save and Reorder
          </button>
          &nbsp;
          <button
            class="btn btn-light"
            @click.prevent="saveCustomerDictionary(false)"
          >
            <i class="fas fa-save"></i> Save
          </button>
        </div>
      </div>
    </nav>

    <MidTermQuestionModal
      :id="`midterm-question-modal-add`"
      ref="midTermQuestionModalAdd"
      :operation="'add'"
    />

    <MaintenanceflagModal
      :id="`maintenance-flag-modal-add`"
      ref="maintenanceFlagModalAdd"
      :operation="'add'"
    />

    <!-- Modals -->
    <DictionaryRoomModal
      :id="`room-modal-edit`"
      ref="dictionaryRoomModalAdd"
      :operation="'add'"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, inject } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useToast } from "vue-toastification"

import MidTermQuestionModal from '@/components/modals/MidTermQuestionModal.vue';
import MaintenanceflagModal from '@/components/modals/MaintenanceflagModal.vue';
import DictionaryRoomModal from '@/components/modals/DictionaryRoomModal.vue';
import { Compliancequestion, Customer, DictionaryRoom, Maintenanceflag, QuestionWrapper } from '@/models';

// Replace `@State`, `@Getter`, `@Action`, and `@Mutation` with store composition function
const store = useStore();
const router = useRouter();
const route = useRoute();
const toast = useToast();

const actProperty: any = inject('actProperty');

const dictionary = computed(() => store.getters['dictionary/current']);

const midTermQuestionModalAdd = ref(null);
const maintenanceFlagModalAdd = ref(null);
const dictionaryRoomModalAdd = ref(null);

const getCustomersDictionaryAction = (payload?: {
    companyname?: string;
    branchname?: string;
    reporttype?: string;
  }) => {
  return store.dispatch('customerdictionary/getCustomerDictionary', payload);
};

const addCustomerDictionary = (queryStringParameters: {
    reorder: boolean;
  }) => {
  return store.dispatch('customerdictionary/addCustomerDictionary', queryStringParameters);
};

const getCustomers = (payload?: {
    starts_with?: string;
    company_name?: string;
    branch_name?: string;
  }) => {
  return store.dispatch('customers/getCustomers', payload);
};

const getReportactiveCompanyNameOptions = (reportname: string): Promise<any> => {
  return store.dispatch('customers/getReportactiveCompanyNameOptions', reportname);
};

const getReportactiveBranchNameOptions = (option: {
    company_name?: string;
    reportname: string;
  }): Promise<any> => {
  return store.dispatch('customers/getReportactiveBranchNameOptions', option);
};

const setCompanyname = (companyname: string) => {
  return store.dispatch('customerdictionary/setCompanyname', companyname);
};

const setBranchname = (branchname: string) => {
  return store.dispatch('customerdictionary/setBranchname', branchname);
};

const resetAll = () => {
  return store.dispatch('customerdictionary/resetAll');
};

const setSelectedQuestion = (question: Compliancequestion) => {
  store.commit('customerdictionary/setSelectedQuestion', question);
};

const setSelectedMaintenanceflag = (flag: Maintenanceflag) => {
  store.commit('customerdictionary/setSelectedMaintenanceflag', flag);
};

const setSelectedRoom = (room: DictionaryRoom) => {
  store.commit('customerdictionary/setSelectedRoom', room);
};

const setQuestionWrapper = (questionwrapper: QuestionWrapper) => {
  store.commit('customerdictionary/setQuestionWrapper', questionwrapper);
};

const companyNameOptions = ref([]);
const branchNameOptions = ref([]);
const selectedCompanyName = ref('');
const selectedBranchName = ref('');

const tab = ref(route.params.tab);

async function getCustomerDictionary(companyname: any, branchname: any, reporttype: any) {
  getCustomersDictionaryAction({ companyname, branchname, reporttype })
    .catch((err: any) => {
      if (err.response && err.response.status && err.response.status == 404) {
        // Initialise a new dictionary for selected customer
        if (companyname) setCompanyname(companyname);
        else setCompanyname("default");

        if (branchname) setBranchname(branchname);
        else setBranchname("default");

        let reorder = false;
        addCustomerDictionary({ reorder }).then(() => {
          getCustomersDictionaryAction({
            companyname,
            branchname,
          }).catch((err: any) => {
            // If we are here there is a problem in initialising
            actProperty.displayError(err);
          });
        });
      } else {
        actProperty.displayError(err);
      }
    });
}

async function onSelectCompanyName(companyName: string) {
  if (companyName) {
    selectedCompanyName.value = companyName;
    branchNameOptions.value = await getReportactiveBranchNameOptions({
      company_name: companyName,
      reportname: getReporttype(),
    });
    resetAll();
    await getCustomerDictionary(
      selectedCompanyName.value,
      "default",
      getReporttype()
    );
  }
}

async function onTagCompanyName(val: string) {
  selectedCompanyName.value = val;
  if (!selectedCompanyName.value) {
    resetAll();
    selectedBranchName.value = "";
    await getCustomerDictionary(
      "default",
      "default",
      getReporttype()
    );
  }
}

async function onSelectBranchName(branchName: string) {
  if (branchName) {
    const customers: Customer[] = await getCustomers({
      company_name: customerCompanyName.value,
      branch_name: branchName,
    });

    if (customers.length) {
      selectedBranchName.value = branchName;
      resetAll();
      await getCustomerDictionary(
        selectedCompanyName.value,
        branchName,
        getReporttype()
      );
    }
  }
}

const customerCompanyName = computed({
  get: () => selectedCompanyName.value,
  set: (newValue) => {
    selectedCompanyName.value = newValue; 
  }
});

const customerBranchName = computed({
  get: () => selectedBranchName.value,
  set: (newValue) => {
    selectedBranchName.value = newValue;
  }
});

async function saveCustomerDictionary(reorder: boolean) {
  let confirm;

  if (reorder) {
    confirm = actProperty.confirmPrompt(
      "Are you sure you want to reorder?"
    );
  } else {
    confirm = new Promise((resolve, reject) => resolve(true));
  }

  confirm
    .then(() => addCustomerDictionary({ reorder }))
    .then(() => toast.success(`Saved`))
    .catch((err: any) => actProperty.displayError(err));
}

const onTagBranchName = async (val: string) => {
  selectedBranchName.value = val;
  if (!selectedBranchName.value) {
    resetAll();
    await getCustomerDictionary(
      selectedCompanyName.value,
      "default",
      getReporttype()
    );
  }
}

const showAddDialog = () => {
  if (router.currentRoute.value.name === "customer-dictionary-compliance") {
    setSelectedQuestion(new Compliancequestion());
    setQuestionWrapper(new QuestionWrapper());
    const modal = midTermQuestionModalAdd.value;
    modal.show();
  } else if (
    router.currentRoute.value.name === "customer-dictionary-maintenanceflags"
  ) {
    setSelectedMaintenanceflag(new Maintenanceflag());
    const modal = maintenanceFlagModalAdd.value;
    modal.show();
  } else if (router.currentRoute.value.name === "customer-dictionary-rooms") {
    setSelectedRoom(new DictionaryRoom());
    const modal = dictionaryRoomModalAdd.value;
    modal.show();
  }
}

const getReporttype = (): string => {
  return (router.currentRoute.value.params.reporttype as string);
}

watch(() => route.params.reporttype, async (newVal, oldVal) => {
  resetAll();
  getReportactiveCompanyNameOptions(getReporttype()).then(
    (values) => {
      companyNameOptions.value = values;
    }
  );
  let company_name = selectedCompanyName.value;
  if (!company_name) company_name = "default";
  let branch_name = selectedBranchName.value;
  if (!branch_name) branch_name = "default";
  // Load default mid-term dictionary
  getCustomerDictionary(company_name, branch_name, getReporttype());
});

onMounted(async () => {
  Promise.all([
      getReportactiveCompanyNameOptions(getReporttype()),
      getCustomerDictionary("default", "default", getReporttype()),
    ])
      .then((values) => {
        companyNameOptions.value = values[0];
      })
      .catch((err) => {
        actProperty.displayError(err);
      });
});

</script>

<style scoped lang="scss">
</style>