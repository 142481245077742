<template>
  <div class="acknowledge">
    <div class="jumbotron">
      <h1 class="display-4">Acknowledge</h1>
      <p class="lead">
        {{ message }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, inject } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { Booking } from '@/models';

// No props defined for this component

const store = useStore();
const route = useRoute();
const message = ref('');

// Convert Actions
const updateDataentryAcknowledge = async (bookingid: string): Promise<any> => {
  await store.dispatch('diary/updateDataentryAcknowledge', bookingid);
};

const updateReportDataentryStatus = async (payload: { id: string | undefined; status: string }): Promise<any> => {
  await store.dispatch('reports/updateReportDataentryStatus', payload);
};

const getBooking = async (payload: { id: string; cancelled: string }): Promise<Booking> => {
  return await store.dispatch('diary/getBookingWithoutStoringInState', payload);
};

// Converted mounted lifecycle hook
onMounted(async () => {
  const routeId = (route.params.id as string);
  if (routeId) {
    await updateDataentryAcknowledge(routeId);
    getBooking({ id: routeId, cancelled: 'false' })
      .then((booking: Booking) => {
        if (booking?.dataentryreport?.id) {
          updateReportDataentryStatus({ id: booking.dataentryreport.id, status: 'Acknowledged' });
        }
      });
    message.value = 'Thank you for confirming';
  } else {
    message.value = 'No booking id provided!';
  }
});
</script>


