<template>
  <div class="reports">
    <header>
      <div class="row">
        <div class="col-sm-6">
          <h1>Reports</h1>
        </div>
        <div v-if="isAdministrator" class="col-sm-6 text-right">
          <button
            class="btn btn-lg btn-primary mr-3"
            data-toggle="modal"
            data-target="#report-migrate-modal"
          >
            <i class="fas fa-file-import"></i>
            Migrate Report
          </button>
          <router-link class="btn btn-lg btn-primary" to="/reports/new">
            <i class="fas fa-plus-square"></i>
            New Report
          </router-link>
        </div>
      </div>
    </header>

    <div class="row mb-3">
      <ReportsSearchByMonth class="col-sm-2" />
      <button
        type="submit"
        class="btn btn-outline-primary col-sm-1"
        @click="resetSearch()"
      >
        <i class="fas fa-calendar-day"></i>
        This month
      </button>
      <ReportsSearchByString
        :class="route.query.search &&  reports.length > 0 ? 'col-sm-3' : 'col-sm-6'"
        placeholder="Search all reports"
      />
      <FilterByString
        v-if="route.query.search && reports.length > 0"
        class="col-sm-3"
        placeholder="Filter all reports"
      />
      <div class="col-sm-3" v-if="isAdministrator">
        <singleselect-text
          v-model="currentoffice"
          :options="officeNameOptions"
          @select="onSelectOffice"
          :allow-empty="true"
          ><template v-slot:placeholder>
            <span>Region</span>
          </template>
          <template 
              #clear
              v-if="currentoffice">
            <i
              @mousedown.prevent.stop="clearCurrentOffice()"
              class="multiselect__clear fa fa-times"
              aria-label="Clear Region"
            ></i
          ></template>
        </singleselect-text>
      </div>
    </div>

    <ReportsList />

    <!-- Modals -->
    <ReportMigrateModal />
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import moment from 'moment-timezone';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";

// Components
import ReportsList from "@/components/report/List.vue";
import ReportsSearchByMonth from "@/components/report/SearchByMonth.vue";
import ReportsSearchByString from "@/components/report/SearchByString.vue";
import ReportMigrateModal from "@/components/modals/ReportMigrateModal.vue";
import FilterByString from "@/components/report/FilterByString.vue";

// Types
import { Report, Office } from "@/models";

// Setup
const store = useStore();
const router = useRouter();
const route = useRoute();
const reportsState = ref(store.state.reports);
const isAdministrator = computed((): boolean => store.getters['auth/isAdministrator']);
const offices = computed((): Office[] => store.getters['offices/getOffices']);
const reports = computed((): Report[] => store.getters['reports/list']);

const currentoffice = ref();
// Methods
const getReports = (): Promise<any> => {
  return store.dispatch('reports/getReports');
};

const setMonth = (month: string): Promise<any> => {
  return store.dispatch('reports/setMonth', month);
};

const setSearch = (search: string): Promise<any> => {
  return store.dispatch('reports/setSearch', search);
};

const setFilter = (search: string): Promise<any> => {
  return store.dispatch('reports/setFilter', search);
};


const setCurrentPage = (page: number): Promise<any> => {
  return store.dispatch('reports/setCurrentPage', page);
};

const resetCurrentPage = (): Promise<any> => {
  return store.dispatch('reports/resetCurrentPage');
};

const setCurrentOffice = (office: string) => {
  return store.commit('reports/setCurrentOffice', office);
};

const officeNameOptions = computed(() => offices.value.map(o => o.name));

const onSelectOffice = async (officename: string) => {
  const office: Office = offices.value.find(o => o.name === officename);
  if (office?.id) {
    await setCurrentOffice(office.id);
    await getReports();
  }
};

const clearCurrentOffice = async () => {
  currentoffice.value = '';
  await setCurrentOffice('');
  await getReports();
};

const resetSearch = () => {
  if (route.query.month !== moment().format("YYYY-MM")) {
    router.push({
      path: "reports",
      query: { month: moment().format("YYYY-MM") },
    });
    resetCurrentPage();
  }
};

// Watchers
watch(() => route.query.month, async (newVal: any, oldVal: any) => {
  if (newVal) {
    await setMonth(newVal);
    await getReports();
  }
});

watch(() => route.query.search, async (newVal: any, oldVal: any) => {
  if (newVal) {
    await setSearch(newVal);
    await getReports();
  }
});

watch(() => route.query.filter, async (newVal: any, oldVal: any) => {
  if (newVal) {
    await setFilter(newVal);
    await getReports();
  }
});

watch(() => route.query.page, async (newVal: any, oldVal: any) => {
  if (newVal) {
    await setCurrentPage(newVal);
    await getReports();
  }
});

// Handle component creation logic
onMounted(() => {
  document.title = 'Reports';
  const queryParams = route.query;
  if (typeof queryParams.month === 'string' && queryParams.month.length) {
    const page = queryParams.page ? Number(queryParams.page) : 1;
    setCurrentPage(page);
    setMonth(queryParams.month).then(() => getReports());
  } else if ((typeof queryParams.search === 'string' && queryParams.search.length) || (typeof queryParams.filter === 'string' && queryParams.filter.length)) {
    if ((typeof queryParams.search === 'string' && queryParams.search.length) && (typeof queryParams.filter === 'string' && queryParams.filter.length)) {
      setSearch(queryParams.search);
      setFilter(queryParams.filter).then(() => getReports());
    } else if(typeof queryParams.search === 'string' && queryParams.search.length){
      setSearch(queryParams.search).then(() => getReports());
    } else if(typeof queryParams.filter === 'string' && queryParams.filter.length){
      setFilter(queryParams.filter).then(() => getReports());
    }
  } else if (reportsState.value.month) {
    router.push({
      path: "reports",
      query: { month: reportsState.value.month },
    });
  } else if (reportsState.value.search) {
    router.push({
      path: "reports",
      query: { text: reportsState.value.search },
    });
  } else {
   
    router.push({
      path: "reports",
      query: { month: moment().format("YYYY-MM") },
    });
  }
});
</script>

