import _ from "lodash";
import Vue from "vue";

import _snakeCase from "lodash/snakeCase";

import { MutationTree } from "vuex";
import { InspectorsState } from "./types";
import { Inspector, RateCard, Location, Customer } from "@/models";

/**
 * Mutations
 *
 * The only way to actually change state in a Vuex store is by committing a mutation.
 * - Vuex mutations are very similar to events: each mutation has a string type and a handler.
 * - The handler function is where we perform actual state modifications - it receives the state as the first argument:
 * - You cannot directly call a mutation handler.
 * - Think of it more like event registration: "When a mutation with type X is triggered, call this handler."
 * - To invoke a mutation handler, you need to call store.commit with its type
 * - e.g. `store.commit('setInspectors', inspector)`
 */
export const mutations: MutationTree<InspectorsState> = {
  /**
   * Set the inspector currently being viewed/edited
   *
   * @param state InspectorsState
   * @param inspector Inspector
   * @returns void
   */
  setCurrentInspector(state: InspectorsState, inspector: Inspector): void {
    state.current = inspector;
  },

  /**
   * Set the inspectors list
   *
   * @param state InspectorsState
   * @param inspector Inspector
   * @returns void
   */
  setInspectors(state: InspectorsState, inspectors: Inspector[]): void {
    state.list = inspectors;
  },

  /**
   * Set the currently edited/viewed inspector
   *
   * @param state InspectorsState
   * @param inspector Inspector
   * @returns void
   */
  setInspector(state: InspectorsState, inspector: Inspector): void {
    state.current = inspector;
  },

  /**
   * Set the default inspector
   *
   * @param state InspectorsState
   * @param inspector Inspector
   * @returns void
   */
  setDefaultInspector(state: InspectorsState, inspector: Inspector): void {
    state.default = inspector;
  },

  /**
   * Set the currently edited/viewed inspector
   *
   * @param state InspectorsState
   * @param payload.path string
   * @param payload.data
   * @returns void
   */
  setInspectorDeep(
    state: InspectorsState,
    payload: { path: string; data: any }
  ): void {
    _.set(state.current, payload.path, payload.data);
  },

  /**
   * Adds a ratecard
   */
  addRateCard(state: InspectorsState, ratecard: RateCard): void {
    _.set(
      state.current,
      `ratecards`,
      state.current.ratecards.concat(new RateCard(ratecard))
    );
  },

  /**
   * Remove ratecard from the given list
   *
   * @param state
   * @param {InspectorsState, ratecard }
   */
  removeRateCard(state: InspectorsState, ratecard: RateCard): void {
    let index = state.current.ratecards.findIndex((r) => r.id == ratecard.id);
    if (index >= 0) {
      state.current.ratecards.splice(index, 1);
      _.set(state.current, `ratecards`, state.current.ratecards);
    }
  },

  /**
   * Adds a location
   */
  addLocation(state: InspectorsState, location: Location): void {
    let index = state.current.locations.findIndex(
      (r) => r.code == location.code
    );
    if (index < 0) {
      _.set(
        state.current,
        `locations`,
        state.current.locations.concat(new Location(location))
      );
    }
  },

  /**
   * Remove location from the given list
   *
   * @param state
   * @param {InspectorsState, location }
   */
  removeLocation(state: InspectorsState, location: Location): void {
    let index = state.current.locations.findIndex(
      (r) => r.code == location.code
    );
    if (index >= 0) {
      state.current.locations.splice(index, 1);
      _.set(state.current, `locations`, state.current.locations);
    }
  },

  /**
   * Adds a excluded client
   */
  addExcludedClient(state: InspectorsState, client: Customer): void {
    let excludedclients: Customer[] = state.current.excludedclients;
    if (!excludedclients) excludedclients = [];
    _.set(
      state.current,
      `excludedclients`,
      excludedclients.concat(new Customer(client))
    );
  },

  /**
   * Remove excluded client from the given list
   *
   * @param state
   * @param flag
   */
  removeExcludedClient(state: InspectorsState, client: Customer): void {
    let excludedclients: Customer[] = state.current.excludedclients;
    if (excludedclients) {
      let index = excludedclients.findIndex((e) => e.id == client.id);
      if (index >= 0) {
        excludedclients.splice(index, 1);
        _.set(state.current, `excludedclients`, excludedclients);
      }
    }
  },

  /**
   * Adds a preferred client
   */
  addPreferredClient(state: InspectorsState, client: Customer): void {
    let preferredclients: Customer[] = state.current.preferredclients;
    if (!preferredclients) preferredclients = [];
    _.set(
      state.current,
      `preferredclients`,
      preferredclients.concat(new Customer(client))
    );
  },

  /**
   * Remove preferred client from the given list
   *
   * @param state
   * @param flag
   */
  removePreferredClient(state: InspectorsState, client: Customer): void {
    let preferredclients: Customer[] = state.current.preferredclients;
    if (preferredclients) {
      let index = preferredclients.findIndex((e) => e.id == client.id);
      if (index >= 0) {
        preferredclients.splice(index, 1);
        _.set(state.current, `preferredclients`, preferredclients);
      }
    }
  },

  /**
   * ++ Unsaved changes
   *
   * @param state InspectorsState
   * @param element string
   * @returns void
   */
  addUnsavedChange(state: InspectorsState, element: string): void {
    state.updates = [...new Set(state.updates.concat([element]))];
  },

  /**
   * Reset Unsaved changes
   *
   * @param state InspectorsState
   * @returns void
   */
  resetUnsavedChanges(state: InspectorsState): void {
    state.updates = [];
  },
};
