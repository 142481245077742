<template>
  <button @click="onClick" @dblclick="onDblclick" :disabled="disabled">
    <i v-if="inProgress" class="fas fa-circle-notch fa-spin"></i>
    <slot v-else></slot>
  </button>
</template>

<script lang="ts" setup>
import { computed, ref, onMounted, inject, defineProps, defineEmits } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  }
});

const store = useStore();
const id = ref(uuidv4());
const timer = ref<number>(0);
const delay = 200;
const prevent = ref(false);
const actProperty: any = inject('actProperty');

const emit = defineEmits(['click', 'dblclick'])

// Access state using composition API
const appState = computed(() => store.state.app);

// Convert mutations to store.commit calls
const addRequest = (payload: { id: string; blocking: boolean }) => {
  store.commit('app/addRequest', payload);
};

onMounted(() => {
  id.value = uuidv4();
});

const inProgress = computed(() => {
  return appState.value.requests.find((r: any) => r.id === id.value);
});

const onClick = () => {
  if (inProgress.value) {
    return;
  }

  (timer.value as any) = setTimeout(() => {
    if (!prevent.value) {
      emit('click', id.value);
    }
    prevent.value = false;
  }, delay);
};

const onDblclick = () => {
  if (inProgress.value) {
    return;
  }

  clearTimeout(timer.value);
  prevent.value = true;
  emit('dblclick', id.value);

  addRequest({
    id: id.value,
    blocking: false,
  });
};
</script>

<style scoped lang="scss">
button:disabled {
  cursor: default;
}
</style>
