import _get from "lodash/get";
import _castArray from "lodash/castArray";
import { Base } from "@/models";
import { toCamelCase } from "@/utilities";

export class Qcmember extends Base {
  public id: string;
  public name: string;
  public email: string;
 


  /**
   * Constructor initialises the variables from the class Qcmember
   * @param data - the data to construct a type with all properties of <Qcmember>
   */
  public constructor(data?: Partial<Qcmember>) {
    super(data);
    data = toCamelCase(data);

    this.id = _get(data, "id", "");
    this.name = _get(data, "name", "");
    this.email = _get(data, "email", "");    
  }

}
