import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

export class Location extends Base {

  public id: string;
  public rating: number;
  public name: string;
  public code: string;

  public constructor(data?: Partial<Location>) {
    super(data);
    data = toCamelCase(data);

    this.id = _get(data, 'id', '');
    this.rating = _get(data, 'rating', 1);
    this.name = _get(data, 'name', '');
    this.code = _get(data, 'code', '');


  }
}