<template>
  <div class="row">
    <div v-if="results && results.length" class="col-md-12 result-pane" ref="resultpane">
      <!-- Filter results -->
      <div class="col-md-12 pt-2 pb-2" :class="{
      'selected-booking':
        (booking.id && selectedbooking.id && booking.id === selectedbooking.id) ||
        (booking.googleid && selectedbooking.googleid && booking.googleid === selectedbooking.googleid),
      'cancelled-booking': booking.cancelled,
      'focused-booking': isfocused(booking)
    }" v-for="booking in results" :key="booking.id">
        <ManagementResultTemplate @click="handleClick(booking)" @dblclick.prevent="handleDoubleClick"
          :templateModel="{ appointmentData: booking }" :picolour="false" :rightclick="false" :showdate="true"
          style="overflow-y: hidden" @removebookingfromlocal="removebookingfromlocal" />
      </div>
    </div>
    <div v-else class="col-md-12 result-pane">
      <p class="lead text-center align-middle">No bookings found</p>
    </div>
    <AlertDialog ref="alertDialog" name="scalertdialog" />
    <SubJobModal ref="subJobModal" id="management-results-subjob-modal" />

    <PersonalJobModal id="manage-personal-job-modal" ref="personalJobModal" :booking="selectedsubjob" />
    <BookingDetailModal id="schedular-booking-detail-model" ref="bookingDetailModal" />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, inject, onMounted, defineProps, defineEmits, onBeforeUnmount, onUnmounted, } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useEvent } from '@/eventBus';
import { Booking } from "@/models";
import SubJobModal from "@/components/modals/SubJobModal.vue";
import PersonalJobModal from "@/components/modals/PersonalJobModal.vue";
import ManagementResultTemplate from "./ManagementResultTemplate.vue";
import Ably from "ably";
import AlertDialog from "@/components/modals/AlertDialog.vue";
import { debounce } from "lodash";
import BookingDetailModal from "@/components/modals/BookingDetailModal.vue";
const realtime: Ably.Realtime = inject('realtime');
const actProperty: any = inject('actProperty');
const channel = realtime.channels.get('diary');
const deviceid = actProperty.getDeviceId();

// Props
const props = defineProps<{
  results: Booking[];
  futurebookingid: string;
}>();

const emit = defineEmits(['selectgooglebooking', 'selectbooking', 'removebookingfromlocal']);

// Store access
const store = useStore();
const router = useRouter();
const route = useRoute();


// Data
const selectedbookingid = ref('');
const selectedsubjob = ref(new Booking());
const unsavedchangesonform = ref(false);
const subJobModal = ref(null);
const personalJobModal = ref(null);
const alertDialog = ref(null);
const bookingDetailModal = ref(null);

// Computed properties and getters
const selectedbooking = ref(new Booking());
const clickTimer = ref<ReturnType<typeof setTimeout> | null>(null);
const email = computed(() => store.getters['auth/email']);

// Methods
const getBooking = (id: string): Promise<Booking> => {
  return store.dispatch('diary/getBooking', id);
};

const isfocused = (b: Booking) => {
  if (b?.id) {
    if (selectedbookingid.value) {
      return b.id === selectedbookingid.value;
    }
    // else if (props.futurebookingid) {
    //   return b.id === props.futurebookingid;
    // }
  }
  return false;
};
// Events handling
onMounted(() => {
  useEvent("appointmentRestoredInBookingTemplate", (booking: any) => {
    let index = props.results.findIndex((b: Booking) => b.id === booking.id);
    if (index >= 0) {
      // eslint-disable-next-line vue/no-mutating-props
      props.results.splice(index, 1);
    }
  });
  useEvent("appointmentChangedInBookingTemplate", (booking: any) => {
    let index = props.results.findIndex((b: Booking) => b.id === booking.id);
    if (index >= 0) {
      // eslint-disable-next-line vue/no-mutating-props
      props.results.splice(index, 1);
      // eslint-disable-next-line vue/no-mutating-props
      props.results.push(booking);
    }
  });
  channel.subscribe('unsavedChangesOnFormForExistingBooking', (message: any) => {
    if (message?.data) {
      if (message.data.deviceid != deviceid) return;
      selectedbookingid.value = message.data.bookingid;
      showUnsavedChangesAlert('There are unsaved changes on the open booking form. Please first save all the changes to load the selected booking');
    }
  });

  channel.subscribe('unsavedChangesOnFormForNewBooking', (message: any) => {
    if (message?.data) {
      if (message.data.deviceid != deviceid) return;
      selectedbookingid.value = message.data.bookingid;
      showUnsavedChangesAlert('There are unsaved changes on the open booking form. Please first save all the changes before starting a new booking');
    }
  });
  channel.subscribe('appointmentLoading', (message: any) => {
  if (message?.data) {
    if (message.data.deviceid != deviceid) return;
    if (!message.data.cancelled) {
      // If it is not a cancelled booking, highlight
      selectedbookingid.value = message.data.bookingid;
    }
    else {
      // If it is a cancelled booking, don't highlight, just set the selectedbookingid
      // so that the 2 second timeout does not open another tab
      selectedbookingid.value = message.data.bookingid;
    }
  }
});
});

onBeforeUnmount(() => {
  channel.unsubscribe('unsavedChangesOnFormForExistingBooking');
  channel.unsubscribe('unsavedChangesOnFormForNewBooking');
  channel.unsubscribe('appointmentLoading');
});

const showUnsavedChangesAlert = (message: string) => {
  const modal = alertDialog.value as any;
  if (modal) {
    modal.init(
      'Warning',
      message,
      'Ok'
    );
    modal.show();
  }
}

const selectbooking = async (booking: Booking) => {
  if (booking.id && !booking.locked || (booking.locked && booking.lockedby === email.value)) {
    if (booking.googleid) {
      // Assuming $emit is available in the setup context
      selectedbookingid.value = booking.id;
      emit("selectgooglebooking", booking);
    } else if (!booking.subtype) {
      channel.publish('appointmentSelected',
        {
          deviceid: deviceid,
          bookingid: booking.id,
          inspectorid: booking.inspector?.id,
          googleid: booking.googleid,
          subtype: booking.subtype,
          jobtype: booking.jobtype,
          bookingdate: booking.bookingdate,
        });
      channel.publish('locateBooking', { deviceid: deviceid, bookingid: booking.id, date: booking.startdate });
      channel.publish('appointmentSelectedOnManagementFilters', { deviceid: deviceid, bookingid: booking.id });
      unsavedchangesonform.value = false;
      emit('selectbooking', booking);
      try {
        const fetchedBooking = await getBooking(booking.id);
        if (fetchedBooking === undefined) {
          actProperty.displayError(`Could not find booking with ID ${route.params.id}`);
          router.push({ name: "newbooking" });
        }
      } catch (err) {
        actProperty.displayError(err);
      }
      selectedbooking.value = booking;
      // Wait for 2 seconds, to check if there is form open on another tab and the appointment loaded
      setTimeout(() => {
        if (selectedbooking.value.id != selectedbookingid.value) {
          window.open(`/diary/${selectedbooking.value.id}`, '_blank');
          selectedbookingid.value = booking.id;
        }
      }, 2000);
      if (unsavedchangesonform.value) {
        selectedbookingid.value = '';
        unsavedchangesonform.value = false;
      }
    }
  }
};

const removebookingfromlocal = (booking: Booking) => {
  emit("removebookingfromlocal", booking);
}

const handleClick = (booking: any) => {
  if (booking.subtype === 'Prep' || booking.subtype === 'Key') {
    singleClickOrSubbooking(booking);
  }
  else if (clickTimer.value) {
    clearTimeout(clickTimer.value);
    clickTimer.value = null;
    selectbooking(booking);
  } else {
    singleClickOrSubbooking(booking);
  }
}

const singleClickOrSubbooking = (booking: Booking) => {
  clickTimer.value = setTimeout(async () => {
    const fetchedBooking = await getBooking(booking.id);
    if (fetchedBooking === undefined) {
      actProperty.displayError(`Could not find booking with ID ${route.params.id}`);
    }
    singleAppointmentClick(fetchedBooking);
    clearTimeout(clickTimer.value as ReturnType<typeof setTimeout>);
    clickTimer.value = null;
  }, 300); // 300 ms delay to distinguish between single and double clicks
}

const singleAppointmentClick = (booking: Booking) => {
  if (booking && booking?.subtype != Booking.PERSONAL) {
    const modal = bookingDetailModal.value as any;
    modal.init(booking);
    modal.show();
  }
}
const handleDoubleClick = (event: MouseEvent) => {
  // Prevent the default double click behavior
  event.preventDefault();
}

</script>

<style scoped lang="scss">
.result-pane {
  flex-grow: 1;
  height: 77vh;
  overflow-x: auto !important;
  overflow-y: auto !important;
  padding: 0 0 150px 0;
}

.result-pane::-webkit-scrollbar {
  width: 20px;
}

.booking-wrapper,
.booking-wrapper:hover {
  border-top: 1px solid #d9dadb;
  border-bottom: 1px solid #d9dadb;
}

.cancelled-booking {
  background: repeating-linear-gradient(135deg,
      rgb(136, 8, 8, 0.2),
      rgb(136, 8, 8, 0.2) 5px,
      rgb(255, 255, 255) 5px,
      rgb(255, 255, 255) 10px);
  color: #212529;
}

.cancelled-booking:hover {
  background: repeating-linear-gradient(135deg,
      rgb(217, 218, 219),
      rgb(217, 218, 219) 5px,
      rgb(255, 255, 255) 5px,
      rgb(255, 255, 255) 10px);
  color: #212529;
}

.focused-booking.cancelled-booking,
.focused-booking.cancelled-booking:hover {
  color: #ffffff !important;
  background: repeating-linear-gradient(135deg,
      #5c96c5,
      #5c96c5 5px,
      #052c4c 5px,
      #052c4c 10px);
  background-color: transparent !important;
  font-weight: bolder;
}

::-webkit-scrollbar {
  width: 10px;
}
</style>