<template>
    <div class="report">
      <div class="jumbotron">
        <div>
          <h1 class="display-4">Thank you!</h1>
          <p class="lead">
                Thank you for confirming. We have made a note of your response and we will process the appointment accordingly
          </p>
        </div>
      </div>
    </div>
  </template>
<script lang="ts" setup>
import { onMounted, inject } from 'vue';
import { useRoute } from 'vue-router';
import Ably from "ably";
const route = useRoute();
const realtime: Ably.Realtime = inject('realtime');
const actProperty: any = inject('actProperty');
const channel = realtime.channels.get('diary');
const deviceid = actProperty.getDeviceId();

// onMounted(() => {
//     if (route.params.bookingid) {
//         channel.publish('bookingConfirmResponse', {deviceid: deviceid, bookingid: route.params.bookingid});
//     }
// });
</script>