<template>
  <div
    class="card bg-white"
    v-if="linkCheck()"
    :id="`item-${complianceitem._uuid}`"
  >
    <div class="card-header">
      <h4 class="h5 mb-1 mt-1">{{ complianceitem.prompt }}</h4>
    </div>
    <div class="card-body">
      <table class="table table-sm table-borderless" v-if="compliancequestion">
        <tbody>
          <tr>
            <td width="20%">
              <div v-if="compliancequestion.questionType == 'select'">
                <div class="btn-group" role="group" aria-label="Extra info">
                  <button
                    v-for="option in optionlist"
                    :key="option"
                    class="btn btn-light"
                    @click="setValue(option)"
                    :class="{
                      buttonselected: complianceitem.value == option,
                    }"
                  >
                    {{ option }}
                  </button>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <content-editable
                      :key="getValueEditableKey()"
                      :content="complianceitem.value"
                      @update="setValue($event)"
                    ></content-editable>
                  </div>
                </div>
              </div>

              <div v-if="compliancequestion.questionType == 'text'">
                <div class="row">
                  <div class="col-sm-12">
                    <content-editable
                      :key="getValueEditableKey()"
                      :content="complianceitem.value"
                      @update="setValue($event)"
                    ></content-editable>
                  </div>
                </div>
              </div>

              <div v-if="compliancequestion.questionType == 'textarea'">
                <div class="row">
                  <div class="col-sm-12">
                    <textarea
                      class="form-control textarea"
                      rows="3"
                      :value="complianceitem.value"
                      @update="setValue($event)"
                    ></textarea>
                  </div>
                </div>
              </div>
            </td>
            <td width="10%"></td>
            <td width="30%">
              <div class="row" v-if="compliancequestion.notes">
                <div class="col-sm-2 promptcontainer">Notes:</div>
                <div class="col-sm-10" style="padding-left: 0px">
                  <content-editable
                    :content="complianceitem.notes"
                    @update="
                      setReportDeep({
                        path: getItemPath('notes'),
                        data: $event,
                      })
                    "
                  ></content-editable>
                </div>
              </div>
            </td>
            <td width="10%"></td>
            <td width="30%">
              <div class="" v-if="compliancequestion.photo == true">
                <Photos 
                  :key="`${photoscount}`"
                  :photos="getPhotos()" 
                  :path="getItemPath('photos')" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, ref, watch, defineProps } from 'vue';
import { useStore } from 'vuex';
import _get from 'lodash/get';
import { Compliancequestion, Complianceitem, Report } from '@/models';
import ContentEditable from '@/components/ContentEditable.vue';
import Photos from '@/components/photo/Photos.vue';
import { useToast } from "vue-toastification";
import { photoSrc } from '@/utilities';

const props = defineProps<{
  complianceitem: Complianceitem;
  index: number;
}>();

const store = useStore();
const toasted = useToast();
const sectionIndex = inject<number>('sectionIndex');
const actProperty: any = inject('actProperty');

const report = computed(() => store.getters['reports/current'] as Report);
const compliancelist = computed(() => store.getters['customerdictionary/compliancelist'] as Compliancequestion[]);

const setReportDeep = (payload: { path: string; data: any }): Promise<void> => store.dispatch('reports/setReportDeep', payload);

//const complianceitem = ref(props.complianceitem);
//const index = ref(props.index);

const getValueEditableKey = () => {
  return props.complianceitem.slug + props.complianceitem.value;
};

const compliancequestion = computed(() => {
  let question = null;
  if (compliancelist.value && props.complianceitem) {
    question = compliancelist.value.find(
      (q) => q.slug === props.complianceitem.slug
    );
  }
  return question;
});

const linkcompliancequestion = computed(() => {
  let question = null;
  if (compliancequestion.value?.link) {
    question = compliancelist.value.find(
      (q) => q.slug === compliancequestion.value?.link
    );
  }
  return question;
});

const optionlist = computed(() => {
  let list: string[] = [];
  if (compliancequestion.value?.optionList) {
    list = compliancequestion.value.optionList.filter((o) => o);
  }
  return list;
});

const setValue = (value: string) => {
  setReportDeep({ path: getItemPath("value"), data: value });
};


const getPhotos = () => {
  return _get(props.complianceitem, "photos", []);
};

const photoscount = computed(() => {
  const photos = _get(props.complianceitem, "photos", []);
  return photos.length;
});

const linkCheck = () => {
  let display = true;
  if (linkcompliancequestion.value) {
    if (report.value && report.value.compliancesections && sectionIndex !== undefined) {
      let section = report.value.compliancesections[sectionIndex];
      if (section && section.complianceitems) {
        let linkeditem = section.complianceitems.find(
          (ci) => ci.slug === linkcompliancequestion.value?.slug
        );
        if (
          linkeditem &&
          linkeditem.value &&
          linkeditem.value === compliancequestion.value?.linkconditionvalue
        ) {
          display = true;
        } else {
          display = false;
        }
      }
    }
  }
  return display;
};

const getItemPath = (append: string) => {
  return `compliancesections[${sectionIndex}].complianceitems[${props.index}]${append ? `.${append}` : ''}`;
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.buttonselected {
  background-color: rgb(179, 179, 179);
  font-weight: bold;
}

.promptcontainer {
  padding-left: 0px;
  padding-right: 0px;
  text-align: right;
  height: 28px;
  line-height: 28px;
  font-weight: bold;
  color: #6c757d;
}

.textarea {
  -moz-appearance: textfield-multiline;
  -webkit-appearance: textarea;
  border: 0px;
  border-bottom: dashed 1px lightGrey;
  font: medium -moz-fixed;
  font: -webkit-small-control;
  overflow: auto;
  padding: 2px;
  resize: both;
}
</style>
