import Vue from "vue";
import _ from "lodash";

import { v4 as uuidv4 } from "uuid";

import { MutationTree } from "vuex";
import { CustomerDictionaryState } from "./types";
import {
  Compliancequestion,
  CustomerDictionary,
  DictionaryConditionMatrix,
  DictionaryItem,
  DictionaryRoom,
  DictionarySection,
  DictionaryType,
  QuestionWrapper,
  Photo,
  Maintenanceflag,
  ReportTypeDictionary,
} from "@/models";

/**
 * Mutations
 *
 * The only way to actually change state in a Vuex store is by committing a mutation.
 * - Vuex mutations are very similar to events: each mutation has a string type and a handler.
 * - The handler function is where we perform actual state modifications - it receives the state as the first argument:
 * - You cannot directly call a mutation handler.
 * - Think of it more like event registration: "When a mutation with type X is triggered, call this handler."
 * - To invoke a mutation handler, you need to call store.commit with its type e.g. `store.commit('setUser', user)`
 */
export const mutations: MutationTree<CustomerDictionaryState> = {
  /**
   * Set the customerdictionary
   *
   * @param state CustomerDictionaryState
   * @param data CustomerDictionary
   * @returns void
   */
  setDefaultDictionary(
    state: CustomerDictionaryState,
    data: CustomerDictionary
  ): void {
    state.defaultdictionary = data;
  },

  /**
   * Set the customerdictionary
   *
   * @param state CustomerDictionaryState
   * @param data CustomerDictionary
   * @returns void
   */
  setCustomerDictionary(
    state: CustomerDictionaryState,
    data: CustomerDictionary
  ): void {
    state.customerdictionary = data;
  },

  /**
   * Set the current dictionary
   *
   * @param state CustomerDictionaryState
   * @param data CustomerDictionary
   * @returns void
   */
  setCurrentDictionary(
    state: CustomerDictionaryState,
    data: CustomerDictionary
  ): void {
    state.current = data;
  },

  /**
   * Set current selected room, for processing further
   * @param state
   * @param flag
   */
  setSelectedRoom(state: CustomerDictionaryState, room: DictionaryRoom): void {
    state.selected_room = room;
  },

  /**
   * Add room to room list
   *
   * @param state
   * @param flsg
   */
  addToRooms(state: CustomerDictionaryState, room: DictionaryRoom): void {
    state.current.rooms.push(room);
  },

  /**
   * Remove room from room list
   *
   * @param state
   * @param flag
   */
  removeFromRooms(state: CustomerDictionaryState, room: DictionaryRoom): void {
    state.current.rooms.splice(
      state.current.rooms.findIndex((f) => f.slug == room.slug),
      1
    );
  },

  /**
   * Set room list
   *
   * @param state
   * @param room
   */
  setRoomList(state: CustomerDictionaryState, rooms: DictionaryRoom[]): void {
    state.current.rooms = rooms;
  },

  /**
   * Add reporttypedictionary
   *
   * @param state
   * @param dictionary ReportTypeDictionary
   */
  addReportTypeDictionary(
    state: CustomerDictionaryState,
    dictionary: ReportTypeDictionary
  ): void {
    state.customerdictionary.reporttypedictionaries.push(dictionary);
  },

  /**
   * Set compliance question list
   *
   * @param state
   * @param question
   */
  setComplianceList(
    state: CustomerDictionaryState,
    list: Compliancequestion[]
  ): void {
    state.current.compliance_list = list;
  },

  /**
   * Set TNC
   *
   * @param state
   * @param value
   */
  setTNC(state: CustomerDictionaryState, tnc: string): void {
    state.current.report_configuration.tnc = tnc;
  },

  /**
   * Set logo
   * @param state
   * @param logo Photo[]
   */
  setLogo(state: CustomerDictionaryState, logo: Photo[]): void {
    state.current.report_configuration.logo = logo;
  },

  /**
   * Set primarycolour
   * @param state
   * @param primarycolour string
   */
  setPrimarycolour(
    state: CustomerDictionaryState,
    primarycolour: string
  ): void {
    state.current.report_configuration.primarycolour = primarycolour;
  },

  /**
   * Set secondarycolour
   * @param state
   * @param secondarycolour string
   */
  setSecondarycolour(
    state: CustomerDictionaryState,
    secondarycolour: string
  ): void {
    state.current.report_configuration.secondarycolour = secondarycolour;
  },

  /**
   * Add question to compliance question list
   *
   * @param state
   * @param question
   */
  addToComplianceList(
    state: CustomerDictionaryState,
    question: Compliancequestion
  ): void {
    state.current.compliance_list.push(question);
  },

  /**
   * Remove question from compliance question list
   *
   * @param state
   * @param payload
   */
  removeFromComplianceList(
    state: CustomerDictionaryState,
    question: Compliancequestion
  ): void {
    state.current.compliance_list.splice(
      state.current.compliance_list.findIndex((q) => q.slug == question.slug),
      1
    );
  },

  /**
   * Set current selected user, for processing further
   * @param state
   * @param question
   */
  setSelectedQuestion(
    state: CustomerDictionaryState,
    question: Compliancequestion
  ): void {
    state.selected_question = question;
  },

  /**
   * Set maintenanceflag list
   *
   * @param state
   * @param flag
   */
  setMaintenanceflags(
    state: CustomerDictionaryState,
    list: Maintenanceflag[]
  ): void {
    state.current.maintenance_flags = list;
  },

  /**
   * Add question to maintenanceflag list
   *
   * @param state
   * @param flsg
   */
  addToMaintenanceflags(
    state: CustomerDictionaryState,
    flag: Maintenanceflag
  ): void {
    state.current.maintenance_flags.push(flag);
  },

  /**
   * Remove question from maintenance list
   *
   * @param state
   * @param flag
   */
  removeFromMaintenanceflags(
    state: CustomerDictionaryState,
    flag: Maintenanceflag
  ): void {
    state.current.maintenance_flags.splice(
      state.current.maintenance_flags.findIndex((f) => f.slug == flag.slug),
      1
    );
  },

  /**
   * Set current selected user, for processing further
   * @param state
   * @param flag
   */
  setSelectedMaintenanceflag(
    state: CustomerDictionaryState,
    flag: Maintenanceflag
  ): void {
    state.selected_maintenanceflag = flag;
  },

  /**
   * Set the deep values in currently editedcustomerdictionary
   *
   * @param state CustomerDictionaryState
   * @param payload.path string
   * @param payload.data
   * @returns void
   */
  setCustomerdictionaryDeep(
    state: CustomerDictionaryState,
    payload: { path: string; data: any }
  ): void {
    _.set(state.current, payload.path, payload.data);
  },

  /**
   * Set questionwrapper
   * @param state
   * @param payload
   */
  setQuestionWrapper(
    state: CustomerDictionaryState,
    questionwrapper: QuestionWrapper
  ): void {
    state.questionwrapper = questionwrapper;
  },

  /**
   * Set the customerdictionary (deep properties)
   */
  setCustomerDictionaryDeep(
    state: CustomerDictionaryState,
    payload: { path: string; data: any }
  ): void {
    state.current = Object.assign(
      new CustomerDictionary(),
      _.set(state.current, payload.path, payload.data)
    );
  },

  /**
   * Set Template Types For Room Category
   *
   * @param payload.flag 1|2|4|8|16
   * @param payload.selected boolean
   */
  setTemplateTypesForCategory(
    state: CustomerDictionaryState,
    payload: { flag: number; selected: boolean }
  ): void {
    state.current.rooms.map((room: DictionaryRoom, index: number) => {
      if (room.category !== state.activeRoomCategory) {
        return;
      }
      let msg;
      const slug = `${state.activeSection.slug}/${state.activeType.slug}`;
      let bitmask = _.get(state.current.rooms[index].types, slug, 0);
      if (payload.selected) {
        msg = `➕ ${slug} ${room.slug} ${bitmask} -> `;
        bitmask = bitmask | payload.flag;
      } else {
        msg = `➖ ${slug} ${room.slug} ${bitmask} -> `;
        bitmask = bitmask & ~payload.flag;
      }
      msg += bitmask;
      if (bitmask > 0) {
        state.current.rooms[index].types[slug] = bitmask;
        msg += " (SETTING KEY)";
      } else {
        delete state.current.rooms[index].types[slug];
        msg += " (REMOVING KEY)";
      }
      if (process.env.NODE_ENV === "development") {
        console.log(msg);
      }
    });
  },

  /**
   * Set Template Types For Rooms
   *
   * @param payload.flag 1|2|4|8|16
   * @param payload.selected boolean
   */
  setTemplateTypesForRoom(
    state: CustomerDictionaryState,
    payload: { flag: number; selected: boolean }
  ): void {
    const roomIndex = _.findIndex(
      state.current.rooms,
      (room) => room === state.activeRoom
    );
    if (roomIndex < 0) {
      return;
    }
    let msg;
    const slug = `${state.activeSection.slug}/${state.activeType.slug}`;
    let bitmask = _.get(state.current.rooms[roomIndex].types, slug, 0);
    if (payload.selected) {
      msg = `➕ ${slug} ${bitmask} -> `;
      bitmask = bitmask | payload.flag;
    } else {
      msg = `➖ ${slug} ${bitmask} -> `;
      bitmask = bitmask & ~payload.flag;
    }
    msg += bitmask;
    if (bitmask > 0) {
      state.current.rooms[roomIndex].types[slug] = bitmask;
      msg += " (SETTING KEY)";
    } else {
      delete state.current.rooms[roomIndex].types[slug];
      msg += " (REMOVING KEY)";
    }
    if (process.env.NODE_ENV === "development") {
      console.log(msg);
    }
  },

  /**
   * Set Template Types For Rooms
   *
   * @param payload.flag 1|2|4|8|16
   * @param payload.selected boolean
   */
  setTemplateTypesForCatchall(
    state: CustomerDictionaryState,
    payload: { flag: number; selected: boolean }
  ): void {
    const categoryIndex = _.findIndex(
      state.current.categories,
      (category) => category.order === state.activeRoomCategory
    );
    if (categoryIndex < 0) {
      return;
    }

    let msg;
    const slug = `${state.activeSection.slug}/${state.activeType.slug}`;
    let bitmask = _.get(state.current.categories[categoryIndex].types, slug, 0);
    if (payload.selected) {
      msg = `➕ ${slug} ${bitmask} -> `;
      bitmask = bitmask | payload.flag;
    } else {
      msg = `➖ ${slug} ${bitmask} -> `;
      bitmask = bitmask & ~payload.flag;
    }
    msg += bitmask;
    if (bitmask > 0) {
      state.current.categories[categoryIndex].types[slug] = bitmask;
      msg += " (SETTING KEY)";
    } else {
      delete state.current.categories[categoryIndex].types[slug];
      msg += " (REMOVING KEY)";
    }
    if (process.env.NODE_ENV === "development") {
      console.log(msg);
    }
  },

  /**
   * Set a direct Property of the currently selected Item
   */
  setItemProperty(
    state: CustomerDictionaryState,
    payload: { property: string; value: any }
  ): void {
    const sectionIndex = _.findIndex(
      state.current.sections,
      (val) => val.slug === state.activeSection.slug
    );
    const typeIndex = _.findIndex(
      state.current.sections[sectionIndex].types,
      (val) => val.slug === state.activeType.slug
    );
    const itemIndex = _.findIndex(
      state.current.sections[sectionIndex].types[typeIndex].items,
      (val) => val.slug === state.activeItem.slug
    );
    // console.log(`Setting ${payload.property} of s${sectionIndex} t${typeIndex} i${itemIndex}`);
    state.current.sections[sectionIndex].types[typeIndex].items[itemIndex][
      payload.property
    ] = Object.assign([], payload.value);
  },

  /**
   * Set a direct Property of the "Misc" Item
   */
  setMiscItemProperty(
    state: CustomerDictionaryState,
    payload: { property: string; value: any }
  ): void {
    const sectionIndex = _.findIndex(
      state.current.sections,
      (val) => val.name === "Misc"
    );
    const typeIndex = _.findIndex(
      state.current.sections[sectionIndex].types,
      (val) => val.name === "Misc"
    );
    const itemIndex = _.findIndex(
      state.current.sections[sectionIndex].types[typeIndex].items,
      (val) => val.name === "Misc"
    );
    state.current.sections[sectionIndex].types[typeIndex].items[itemIndex][
      payload.property
    ] = Object.assign([], payload.value);
  },

  /**
   * Set an Item Conditions roperty of the currently selected Item
   */
  setItemConditions(
    state: CustomerDictionaryState,
    payload: { property: string; value: any }
  ): void {
    // console.log('setItemConditions()', 'payload', payload);
    const sectionIndex = _.findIndex(
      state.current.sections,
      (val) => val.slug === state.activeSection.slug
    );
    const typeIndex = _.findIndex(
      state.current.sections[sectionIndex].types,
      (val) => val.slug === state.activeType.slug
    );
    const itemIndex = _.findIndex(
      state.current.sections[sectionIndex].types[typeIndex].items,
      (val) => val.slug === state.activeItem.slug
    );
    state.current.sections[sectionIndex].types[typeIndex].items[
      itemIndex
    ].conditions[payload.property] = Object.assign([], payload.value);
  },

  /**
   * Set an Item Conditions property of the "Misc" Item
   */
  setMiscItemConditions(
    state: CustomerDictionaryState,
    conditions: string[]
  ): void {
    const sectionIndex = _.findIndex(
      state.current.sections,
      (val) => val.name === "Misc"
    );
    const typeIndex = _.findIndex(
      state.current.sections[sectionIndex].types,
      (val) => val.name === "Misc"
    );
    const itemIndex = _.findIndex(
      state.current.sections[sectionIndex].types[typeIndex].items,
      (val) => val.name === "Misc"
    );
    // Order before saving
    conditions = _.orderBy(
      Array.from(new Set(conditions)),
      (text) => text.toLowerCase(),
      ["asc"]
    );
    state.current.sections[sectionIndex].types[typeIndex].items[
      itemIndex
    ].conditions["additional"] = Object.assign([], _.uniq(conditions));
  },

  /**
   * Set Active Section
   */
  setActiveSection(
    state: CustomerDictionaryState,
    section: DictionarySection
  ): void {
    state.activeSection = section;
  },

  /**
   * Set Active Type
   */
  setActiveType(state: CustomerDictionaryState, type: DictionaryType): void {
    state.activeType = type;
  },

  /**
   * Set Active Item
   */
  setActiveItem(state: CustomerDictionaryState, item: DictionaryItem): void {
    state.activeItem = item;
  },

  /**
   * Set Active Room
   */
  setActiveRoom(state: CustomerDictionaryState, room: DictionaryRoom): void {
    state.activeRoom = room;
  },

  /**
   * Set Active Room Category
   */
  setActiveRoomCategory(
    state: CustomerDictionaryState,
    category: number
  ): void {
    state.activeRoomCategory = category;
  },

  /**
   * Set Clipboard Colours
   */
  setClipboardColours(state: CustomerDictionaryState, data: string[]): void {
    state.clipboardColours = data;
  },

  /**
   * Set Clipboard Conditions
   */
  setClipboardConditions(state: CustomerDictionaryState, data: string[]): void {
    state.clipboardConditions = data;
  },

  /**
   * Set Clipboard Constituents
   */
  setClipboardConstituents(
    state: CustomerDictionaryState,
    data: string[]
  ): void {
    state.clipboardConstituents = data;
  },

  /**
   * Set Clipboard Makes
   */
  setClipboardMakes(state: CustomerDictionaryState, data: string[]): void {
    state.clipboardMakes = data;
  },

  /**
   * Set Clipboard Condition Matrix
   */
  setClipboardConditionMatrix(
    state: CustomerDictionaryState,
    matrix: DictionaryConditionMatrix[]
  ): void {
    state.clipboardConditionMatrix = matrix;
  },

  /**
   * Add Dictionary Type
   */
  addType(state: CustomerDictionaryState, type: DictionaryType): void {
    const sectionIndex = _.findIndex(
      state.current.sections,
      (val: DictionarySection) => val.slug === state.activeSection.slug
    );
    // Prevent duplicate names/system names
    const duplicateTypeByName = _.find(
      state.current.sections[sectionIndex].types,
      (val) => val.getSystemName() === type.getSystemName()
    );
    if (duplicateTypeByName) {
      throw Error(
        `Unable to add Type – duplicate name "${duplicateTypeByName.getSystemName()}"`
      );
    }
    // Prevent duplicate slugs
    const duplicateTypeBySlug = _.find(
      state.current.sections[sectionIndex].types,
      (val) => val.slug === type.slug
    );
    if (duplicateTypeBySlug) {
      throw Error(
        `Unable to add Type – duplicate slug "${duplicateTypeBySlug.slug}"`
      );
    }
    state.current.sections[sectionIndex].types.push(type);
  },

  /**
   * Update Dictionary Type
   */
  updateType(state: CustomerDictionaryState, type: DictionaryType): void {
    const sectionIndex = _.findIndex(
      state.current.sections,
      (val: DictionarySection) => val.slug === state.activeSection.slug
    );
    const typeIndex = _.findIndex(
      state.current.sections[sectionIndex].types,
      (val: DictionaryType) => val.slug === type.slug
    );
    state.current.sections[sectionIndex].types.splice(typeIndex, 1, type);
  },

  /**
   * Remove Dictionary Type
   */
  removeType(state: CustomerDictionaryState, type: DictionaryType): void {
    const sectionIndex = _.findIndex(
      state.current.sections,
      (val: DictionarySection) => val.slug === state.activeSection.slug
    );
    state.current.sections[sectionIndex].types.splice(
      state.current.sections[sectionIndex].types.indexOf(type),
      1
    );
  },

  /**
   * Add Dictionary Item
   */
  addItem(state: CustomerDictionaryState, item: DictionaryItem): void {
    const sectionIndex = _.findIndex(
      state.current.sections,
      (val: DictionarySection) => val.slug === state.activeSection.slug
    );
    const typeIndex = _.findIndex(
      state.current.sections[sectionIndex].types,
      (val: DictionaryType) => val.slug === state.activeType.slug
    );
    // Prevent duplicate names
    const duplicateItemByName = _.find(
      state.current.sections[sectionIndex].types[typeIndex].items,
      (val) => val.name === item.name
    );
    if (duplicateItemByName) {
      throw Error(
        `Unable to add Item – duplicate name "${duplicateItemByName.name}"`
      );
    }
    // Prevent duplicate slugs
    const duplicateItemBySlug = _.find(
      state.current.sections[sectionIndex].types[typeIndex].items,
      (val) => val.slug === item.slug
    );
    if (duplicateItemBySlug) {
      throw Error(
        `Unable to add Item – duplicate slug "${duplicateItemBySlug.slug}"`
      );
    }
    state.current.sections[sectionIndex].types[typeIndex].items.push(item);
  },

  /**
   * Update Dictionary Item
   */
  updateItem(state: CustomerDictionaryState, item: DictionaryItem): void {
    const sectionIndex = _.findIndex(
      state.current.sections,
      (val: DictionarySection) => val.slug === state.activeSection.slug
    );
    const typeIndex = _.findIndex(
      state.current.sections[sectionIndex].types,
      (val: DictionaryType) => val.slug === state.activeType.slug
    );
    const itemIndex = _.findIndex(
      state.current.sections[sectionIndex].types[typeIndex].items,
      (val: DictionaryItem) => val.slug === item.slug
    );
    state.current.sections[sectionIndex].types[typeIndex].items.splice(
      itemIndex,
      1,
      item
    );
  },

  /**
   * Remove Dictionary Item
   */
  removeItem(state: CustomerDictionaryState, item: DictionaryItem): void {
    const sectionIndex = _.findIndex(
      state.current.sections,
      (val: DictionarySection) => val.slug === state.activeSection.slug
    );
    const typeIndex = _.findIndex(
      state.current.sections[sectionIndex].types,
      (val: DictionaryType) => val.slug === state.activeType.slug
    );
    state.current.sections[sectionIndex].types[typeIndex].items.splice(
      state.current.sections[sectionIndex].types[typeIndex].items.indexOf(item),
      1
    );
  },

  /**
   * Update company name
   */
  updateCompanyname(state: CustomerDictionaryState, companyname: string): void {
    state.customerdictionary.company_name = companyname;
  },

  /**
   * Update customerid
   */
  updateBranchname(state: CustomerDictionaryState, branch_name: string): void {
    state.customerdictionary.branch_name = branch_name;
  },

  /**
   * ++ Unsaved changes
   */
  addUnsavedChange(state: CustomerDictionaryState, id: string): void {
    if (_.isEmpty(id)) {
      id = uuidv4();
    }
    state.updates = [...new Set(state.updates.concat([id]))];
  },

  /**
   * Reset Unsaved changes
   */
  resetUnsavedChanges(state: CustomerDictionaryState): void {
    state.updates = [];
  },

  /**
   *  Reset everything back to initial state
   */
  resetAll(state: CustomerDictionaryState): void {
    state.activeSection = new DictionarySection();
    state.activeType = new DictionaryType();
    state.activeItem = new DictionaryItem();
    state.activeRoom = new DictionaryRoom();
    state.activeRoomCategory = 0;
    state.clipboardColours = [];
    state.clipboardConditions = [];
    state.clipboardConditionMatrix = [
      new DictionaryConditionMatrix(),
      new DictionaryConditionMatrix(),
      new DictionaryConditionMatrix(),
    ];
    state.clipboardConstituents = [];
    state.clipboardMakes = [];
    state.updates = [];
  },
};
