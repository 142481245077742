<template>
  <div class="card reports--room">
    <div class="card-header" :class="{ 'toggle-body': _toggle }">
      <h2 class="mb-0" @click.self="toggleShowBody()">{{ _room.name }}</h2>
    </div>
    <div class="card-body" v-if="_opened">
      <div v-for="section in sectionswithdeductions" :key="section._uuid">
        <report-room-section
          v-if="section.types.length"
          :room="room"
          :section="section"
          :index="getSectionIndex(section)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps, ref, defineEmits, provide } from 'vue';
import { useStore } from 'vuex';
import { checkitem } from '@/utilities';
import { Section, Type, Item, Room } from "@/models";
import ReportRoomSection from './Section.vue';

const emit = defineEmits(['showBody'])

const store = useStore();
const props = defineProps<{
  index: number;
  room: Room;
  toggle: boolean;
  opened: boolean;
}>();

provide('roomIndex', props.index);

const showBody = ref(true);

const _toggle = computed(() => props.toggle);
const _opened = computed(() => props.opened);
const _room = computed(() => props.room);
const _index = computed(() => props.index);

const sectionswithdeductions = computed(() => {
  return _room.value?.sections?.filter((section: Section) =>
    checksection(_room.value, section)
  ) ?? [];
});

function getSectionIndex(section: Section): number {
  return _room.value.sections.findIndex((s: Section) => s._uuid === section._uuid);
}

function checksection(room: Room, section: Section): boolean {
  let result = section.types.filter((type: Type) =>
    checktype(room, section, type)
  );
  return result.length > 0;
}

function checktype(room: Room, section: Section, type: Type): boolean {
  let result = type.items.filter((item: Item) =>
    checkitem(room, section, type, item)
  );
  return result.length > 0;
}

function toggleShowBody(): void {
  store.commit('reports/setCurrentRoom', _room.value);
  if (_toggle.value) {
    showBody.value = !_opened.value;
    emit("showBody", _index.value, showBody.value);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/bootstrap/_variables.scss";

.card {
  margin-bottom: 1rem;
  // &:last-child {
  //   margin-bottom: 0;
  // }
}

.card-header {
  a {
    display: inline-block;
    margin-left: 1rem;
  }
  small {
    font-size: 56%;
  }
}

.order-arrows {
  padding: 0.1rem 0.35rem !important;
}

.btn {
  padding-bottom: 0.2rem;
  &.disabled {
    opacity: 0.35;
  }
}
</style>
