<template>
  <div class="report">
    <h1>Dictionary</h1>

    <!-- Tabs -->
    <ul class="nav nav-tabs mb-3" role="tablist">
      <li class="nav-item">
        <router-link to="items" class="nav-link" active-class="active">
          Items
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="conditions" class="nav-link" active-class="active">
          Condition Matrix
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="templates" class="nav-link" active-class="active">
          Templates
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="reporttypes" class="nav-link" active-class="active">
          Report Types
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="systemsetting" class="nav-link" active-class="active">
          System Setting
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="fixflo" class="nav-link" active-class="active">
          Fixflo
        </router-link>
      </li>
    </ul>

    <!-- Tab content (child routes) -->
    <router-view :key="$route.name" style="padding-bottom: 60px" />

    <!-- Bottom navbar -->
    <nav class="navbar fixed-bottom navbar-dark bg-primary">
      <div class="container-fluid">
        <div class="mr-auto"></div>
        <div class="ml-auto text-light">
          <button
            class="btn btn-outline-light"
            @click.prevent="saveDictionary(true)"
          >
            <i class="fas fa-save"></i> Save and Reorder
          </button>
          &nbsp;
          <button class="btn btn-light" @click.prevent="saveDictionary(false)">
            <i class="fas fa-save"></i> Save
          </button>
        </div>
      </div>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { inject, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';


const store = useStore();
const route = useRoute();
const toasted = useToast();

const tab = ref(route.params.tab);
const actProperty: any = inject('actProperty');

const addDictionary = (queryStringParameters: {
    reorder: boolean;
  }) => {
  return store.dispatch('dictionary/addDictionary', queryStringParameters);
};

onMounted(async () => {
  tab.value = route.params.tab;
});

const saveDictionary = async (reorder: boolean) => {
  let confirm;

  if (reorder) {
    confirm = actProperty.confirmPrompt(
      "Are you sure you want to reorder?"
    );
  } else {
    confirm = new Promise((resolve, reject) => resolve(true));
  }

  confirm
    .then(() => addDictionary({ reorder }))
    .then(() => toasted.success(`Saved`))
    .catch((err: any) => actProperty.displayError(err));
};

</script>

<style scoped lang="scss">
</style>