import { Module } from "vuex";
import { RootState } from "../types";
import { DiaryState } from "./types";

import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { Booking, EmailLog, GetaddressResponse, Inspector } from "@/models";

/**
 * Default state
 */
export const state: DiaryState = {
  booking: new Booking(),
  list: [],
  parkedbookings: [],
  jobtypelist: [
    "inventory",
    "checkin",
    "checkout",
    "soc",
    "property visit",
    "legra",
    "vm",
  ],
  internaljobtypelist: [
    "Inv CI - new to ACT",
    "Inv CI - off upload",
    "Inv CI - old report on file, charge CI rate",
    "Check-Out - off ACT report",
    "Check-Out - off upload",
    "Schedule of Condition",
    "Check-In - off ACT report",
    "Check-In - off upload",
    "Check-In - back to back",
    "Property Visit",
    "Legionella Risk Assessment",
    "Vacant Management",
  ],
  internaljobtypedisplayname: new Map([
    ["Inv CI - off upload", "InvCI - last CO, from upload-charge CI rate"],
    ["Check-In - off upload", "Check In - last CO, from upload"],
  ]),
  offactreporttypes: [
    "Inv CI - old report on file, charge CI rate",
    "Check-Out - off ACT report",
    "Check-In - off ACT report",
    "Check-In - back to back",
  ],
  jobtypemap: new Map([
    [
      "inventory",
      [
        "Inv CI - new to ACT",
        "Inv CI - off upload",
        "Inv CI - old report on file, charge CI rate",
      ],
    ],
    [
      "checkin",
      [
        "Check-In - off ACT report",
        "Check-In - off upload",
        "Check-In - back to back",
      ],
    ],
    ["checkout", ["Check-Out - off ACT report", "Check-Out - off upload"]],
    ["soc", ["Schedule of Condition"]],
    ["property visit", ["Property Visit"]],
    ["legra", ["Legionella Risk Assessment"]],
    ["vm", ["Vacant Management"]],
  ]),
  propertytypelist: ["Studio", "Flat", "House", "Cottage"],
  getaddressresponse: new GetaddressResponse(),
  postcodesearchlist: [],
  updates: [],
  currentdate: new Date(),
  currentlog: new EmailLog(),
  inspectorlist: [],
  customerlist: [],
  selectedCustomername: "",
  showbookingswithproblems: false,
};

/**
 * Use namespaced Vuex modules
 */
const namespaced: boolean = true;

/**
 * Module export
 */
const diary: Module<DiaryState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};

export default diary;
