<template>
  <div class="report-section signatures">

    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th width="15%"><span class="sr-only">Who</span></th>
          <th width="30%">Name</th>
          <th width="30%">Email</th>
          <th width="19%">Signed</th>
          <th width="6%"><span class="sr-only">Actions</span></th>
        </tr>
      </thead>
      <tbody>
        <SignatureRow v-for="signature in signatures" :key="signature._uuid" :signature="signature" />
      </tbody>
    </table>

    <button class="btn btn-primary" @click="addSignature()">
      <i class="fas fa-plus"></i>&nbsp;Signature
    </button>

    <p class="mt-4" v-if="report.type === 'checkout'">
      <em>A <strong>Forwarding Address</strong> can be added on the <strong>Details</strong> tab.</em>
    </p>

  </div>
</template>

<script lang="ts" setup>
import { computed, inject, ref } from 'vue';
import { useStore } from 'vuex';
import SignatureRow from '@/components/report/SignatureRow.vue';
import { Report, Signature } from '@/models';
import { ReportsState } from '@/store/reports/types';

// Props and state
const store = useStore();
const reportsState = computed(() => store.state.reports as ReportsState);

// Computed properties
const report = computed(() => reportsState.value.current);
const signatures = computed(() => reportsState.value.current.signatures);

// Methods
const addSignature = async (): Promise<void> => {
  await store.dispatch('reports/addSignature');
};

// Injects (if applicable)
const actProperty: any = inject('actProperty');
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
