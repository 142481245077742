<template>
  <div class="report-section keys">
    <fieldset>
      <legend>Key Return</legend>

      <!-- Section Buttons -->
      <div class="btn-group mt-3">
        <button type="button" class="btn btn-light" :class="{ buttonselected: returntype === 'Tenant' }" @click="returntype = 'Tenant'">Tenant</button>
        <button type="button" class="btn btn-light" :class="{ buttonselected: returntype === 'Agent' }" @click="returntype = 'Agent'">Agent</button>
        <button type="button" class="btn btn-light" :class="{ buttonselected: returntype === 'Concierge' }" @click="returntype = 'Concierge'">Concierge</button>
        <button type="button" class="btn btn-light" :class="{ buttonselected: returntype === 'Letterbox' }" @click="returntype = 'Letterbox'">Letterbox</button>
        <button type="button" class="btn btn-light" :class="{ buttonselected: returntype === 'Misc' }" @click="returntype = 'Misc'">Misc</button>
      </div>

      <div class="form-group" v-if="returntype === 'Agent' || returntype === 'Concierge'">
        <label class="form-label">Name</label>
        <input type="text" class="form-control" v-model="name" />
      </div>
      <div class="form-group" v-if="returntype === 'Agent' || returntype === 'Concierge'">
        <label class="form-label">Description</label>
        <input type="text" class="form-control" v-model="description" />
      </div>
      <div class="form-group" v-if="returntype === 'Misc'">
        <label class="form-label">Description of events</label>
        <input type="text" class="form-control" v-model="description" />
      </div>
      <div class="form-group" v-if="returntype === 'Agent' || returntype === 'Concierge' || returntype === 'Letterbox' || returntype === 'Misc'">
        <Photos :photos="photos" :path="'keysreturn.photos'" :key="`${photos.length}`"/>
      </div>
    </fieldset>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Photos from "@/components/photo/Photos.vue";

const store = useStore();

const report = computed(() => store.getters['reports/current']);
const setReportDeep = (payload: { path: string; data: any }): Promise<any> => {
  return store.dispatch('reports/setReportDeep', payload);
};

const returntype = computed({
  get: () => report.value.keysreturn.returntype,
  set: (val: string) => store.dispatch('reports/setReportDeep', { path: 'keysreturn.returntype', data: val }),
});

const name = computed({
  get: () => report.value.keysreturn.name,
  set: (val: string) => store.dispatch('reports/setReportDeep', { path: 'keysreturn.name', data: val }),
});

const description = computed({
  get: () => report.value.keysreturn.description,
  set: (val: string) => store.dispatch('reports/setReportDeep', { path: 'keysreturn.description', data: val }),
});

const photos = computed({
  get: () => report.value.keysreturn.photos,
  set: (data) => setReportDeep({ path: 'keysreturn.photos', data })
});

</script>

<style scoped lang="scss">
.btn-group {
  display: flex;
  gap: 0; 
  margin-top: 1rem;
}

.btn {
  background-color: white;
  border: 1px solid #ccc;
  color: black;
  padding: 0.4rem 0.8rem; 
  font-size: 0.9rem; 
  border-radius: 0.25rem;
  transition: background-color 0.2s, color 0.2s;
  flex: 1; 
  max-width: 100px; 
}

.btn.active {
  background-color: #333;
  color: white;
}

.buttonselected {
  background-color: rgb(179, 179, 179);
  font-weight: bold;
}

.card-header {
  font-weight: bold;
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
}

.card {
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  margin-top: 1rem;
}

.card-body {
  padding: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}
</style>
