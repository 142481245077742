<template>
  <div class="customer">
    <h3>Developments</h3>
    <div v-if="establishments" class="col-sm-8">
      <table class="table table-hover">
        <thead>
          <th>Name</th>
          <th>Branch</th>
          <th></th>
        </thead>
        <tbody>
          <tr v-for="(est, $index) in establishments" :key="$index">
            <td>
              {{ est.companyName }}
            </td>
            <td>{{ est.branchName }}</td>
            <td class="text-right">
              <div class="btn-group" role="group" aria-label="Actions">
                <button class="btn btn-outline-danger" @click="removeEstablishment(est)">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-sm-1">
      <button class="btn btn-outline-primary" data-toggle="modal" data-target="#add-establishment-modal">
        <i class="fas fa-plus"></i>
      </button>
    </div>

    <AddEstablishmentModal />
  </div>
</template>

<script lang="ts" setup>
import _ from "lodash";
import { computed } from 'vue';
import { useStore } from 'vuex';
import { Customer } from "@/models";
import AddEstablishmentModal from "@/components/modals/AddEstablishmentModal.vue";

const store = useStore();

const customer = computed(() => store.getters['customers/current']);
const removeEstablishment = async (establishment: Customer) => await store.dispatch('customers/removeEstablishment', establishment);
const establishments = computed(() => {
  return customer.value.establishments;
});
</script>

<style scoped lang="scss"></style>