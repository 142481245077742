import _ from 'lodash';
import Vue from 'vue';

import _snakeCase from 'lodash/snakeCase';

import { MutationTree } from 'vuex';
import { RemotecommandState } from './types';
import { Remotecommand } from '@/models/remotesupport.model';
import _set from 'lodash/set';

/**
 * Mutations
 *
 * The only way to actually change state in a Vuex store is by committing a mutation.
 * - Vuex mutations are very similar to events: each mutation has a string type and a handler.
 * - The handler function is where we perform actual state modifications - it receives the state as the first argument:
 * - You cannot directly call a mutation handler.
 * - Think of it more like event registration: "When a mutation with type X is triggered, call this handler."
 * - To invoke a mutation handler, you need to call store.commit with its type
 * - e.g. `store.commit('setCustomers', customer)`
 */
export const mutations: MutationTree<RemotecommandState> = {

    /**
	 * Add remotecommand to store
	 * 
	 * @param {SupportState} state
	 * @param {command} Remotecommand
	 * @returns void
	 */
	addRemotecommand(state: RemotecommandState, command: Remotecommand): void {
		state.commandlist.push(command);
	},

    /**
	 * Update remotecommand to store
	 * 
	 * @param {SupportState} state
	 * @param {command} Remotecommand
	 * @returns void
	 */
	updateRemotecommand(state: RemotecommandState, command: Remotecommand): void {
		const index = state.commandlist.findIndex((c) => c.idcopy === command.idcopy);
		if(index >= 0) {
			state.commandlist.splice(index,1);
			state.commandlist.push(command);
		}
	},

    /**
	 * Set the commandss list
	 * 
	 * @param {SupportState} state
	 * @param {commands} Remotecommand[]
	 * @returns void
	 */
	setRemotecommands(state: RemotecommandState, commands: Remotecommand[]): void {
		state.commandlist = commands;
	},

	/**
	 * Set the current command
	 * 
	 * @param {SupportState} state
	 * @param {command} Remotecommand
	 * @returns void
	 */
	setCurrentcommand(state: RemotecommandState, command: Remotecommand): void {
		state.currentcommand = command;
	},

	/**
	 * Set the currently edited/viewed command
	 */
	setCommandDeep(state: RemotecommandState, payload: {path: string, data: any}): void {
    state.currentcommand = Object.assign(new Remotecommand, _set(state.currentcommand, payload.path, payload.data));
  },

	removeRemotecommand(state, id: string) {
		state.commandlist = state.commandlist.filter(command => command.id !== id);
	},

    resetState(state) {
        state.commandlist = [];
        state.currentcommand = new Remotecommand();
    },

    rehydrateState(state) {
        state.commandlist = state.commandlist.map(command => new Remotecommand(command));
        state.currentcommand = new Remotecommand(state.currentcommand);
      }
  
};
