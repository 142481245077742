<template>
  <div class="report">
    <div v-if="success === true">
      <!-- Report Header -->
      <div class="row">
        <div class="col-sm-6">
          <h1>
            {{ addressPreviewLine1() }}<br />
            {{ addressPreviewLine2() }}
          </h1>
          <p class="lead">
            This is your opportunity to leave feedback. Please follow the steps
            below. <br />For feedback to be considered it must be specific and
            linked to the items within the body of the report. It will be useful
            for you to have a copy of the report open when you are completing
            step 2. <br />If you have any questions or queries please call our
            team, we will be happy to help you. We can be reached on 0208 896
            2232 and are available Monday to Friday 9.00am-5.00pm.
          </p>
          <p class="lead">
            To download PDF copy of the report. Please click
            <a
              href="#"
              class="bluelink"
              @click="generateReportPdf(report, $event)"
              >here</a
            >.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <form>
            <legend>Step 1 (Mandatory)</legend>
            <legend>
              <span class="subtitle">Please enter your details.</span>
            </legend>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label"
                >Please select<span class="red-text"
                  ><strong>* </strong></span
                ></label
              >
              <div class="col-sm-6">
                <singleselect-text
                  v-model="shareholdertype"
                  :value="shareholdertype"
                  :options="shareholderOptions"
                  @click="setShareholdertypetouched()"
                ></singleselect-text>
                <p v-if="!shareholdertypeisvalid" class="error-message">
                  Type is required
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label"
                >Your name<span class="red-text"
                  ><strong>* </strong></span
                ></label
              >
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  v-model.lazy="shareholdername"
                  @blur="setNametouched()"
                />
                <p v-if="!nameisvalid" class="error-message">
                  Name is required
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label"
                >Your email<span class="red-text"
                  ><strong>* </strong></span
                ></label
              >
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  v-model.lazy="shareholderemail"
                  @blur="setEmailtouched()"
                />
                <p v-if="!emailisvalid" class="error-message">
                  {{ invalidemailmessage }}
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="row" style="padding-top: 50px">
        <div class="col-sm-12 report-section">
          <div class="row">
            <div class="col-sm-6">
              <div class="feedback-half-section">
                <div class="row">
                  <legend>Step 2</legend>
                  <legend>
                    <span class="subtitle"
                      >Please select the item number from the list that you wish
                      to make a comment against</span
                    >
                  </legend>

                  <div class="col-sm-12">
                    <div class="form-group row">
                      <singleselect-number
                        v-model="currentItem"
                        :options="unselectedItems"
                        :custom-label="itemLabel"
                        label="order"
                      >
                        <template #singleLabel="props">
                          <span>{{ itemLabel(props.option) }}</span>
                        </template>
                      </singleselect-number>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="currentItem && currentItem.order"
                class="row feedback-half-section"
              >
                <div class="col-sm-12">
                  <div class="row">
                    <legend>
                      <span class="subtitle"
                        >Please add your comments here, and if you wish to
                        submit a photo please upload it by clicking the photo
                        button below. The photo must be a JPEG format</span
                      >
                    </legend>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 selected-item-table">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col" style="width: 10%">Ref</th>
                            <th scope="col" style="width: 15%">Item</th>
                            <th scope="col" style="width: 25%">Description</th>
                            <th scope="col" style="width: 10%">Qty</th>
                            <th scope="col" style="width: 15%">Colour</th>
                            <th scope="col" style="width: 15%">Condition</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">
                              {{ currentItem.order }}
                            </th>
                            <td>
                              {{ currentItem.roomname }}
                            </td>
                            <td>{{ currentItem.name }}</td>
                            <td>
                              <span v-if="currentItem.qty">{{
                                currentItem.qty
                              }}</span>
                            </td>
                            <td>{{ currentItem.colour }}</td>
                            <td>
                              <div
                                v-for="note in currentItem.condition.in.notes"
                                :key="note.id"
                              >
                                {{ note.note }}<br />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <fieldset>
                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label"
                            >Comments</label
                          >
                          <div class="col-sm-9">
                            <textarea
                              class="form-control"
                              rows="10"
                              v-model.lazy="itemcomments"
                            ></textarea>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-3 col-form-label"></label>
                          <div class="col-sm-9">
                            <table class="table table-sm table-borderless">
                              <!-- Photos In -->
                              <Photos
                                :key="getCommentsPhotosLength(currentItem.item)"
                                :photos="getCommentsPhotos(currentItem.item)"
                                :path="getCommentsPhotoPath(currentItem)"
                                :buttontext="
                                  getCommentsPhotoButtonLabel(currentItem.item)
                                "
                              />
                            </table>
                          </div>
                        </div>

                        <div class="form-group row">
                          <div class="col-sm-9 offset-sm-3">
                            <button
                              class="btn btn-primary"
                              @click.prevent="
                                saveCommentedItemAndResetForm(currentItem)
                              "
                            >
                              <span>Save</span>
                            </button>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div
                v-if="commentedItems.length"
                class="feedback-half-section jumbotron selected-item-jumbotron"
              >
                <legend>
                  <span class="subtitle">Commented items</span>
                </legend>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" style="width: 10%">Ref</th>
                      <th scope="col" style="width: 14%">Item</th>
                      <th scope="col" style="width: 26%">Description</th>
                      <th scope="col" style="width: 30%">Comments</th>
                      <th style="width: 20%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in commentedItems" :key="item._uuid">
                      <th scope="row">{{ item.order }}</th>
                      <td>
                        {{ item.roomname }}
                      </td>
                      <td>{{ item.name }}</td>
                      <td>{{ getItemcomments(item) }}</td>
                      <td>
                        <div class="btn-group" role="group">
                          <button
                            class="btn btn-sm btn-primary"
                            @click.prevent="setCurrentItem(item)"
                          >
                            Edit
                          </button>
                          <button
                            class="btn btn-sm btn-primary"
                            @click.prevent="removeCommentedItem(shareholdertype, item)"
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" style="padding-top: 50px">
        <div class="col-sm-6">
          <fieldset>
            <legend>Step 3 (If appropriate)</legend>
            <legend>
              <span class="subtitle"
                >General comments - This should only be used for comments you
                are unable to assign to a specific item number above. Please
                leave textbox blank if not needed.</span
              >
            </legend>
            <div class="form-group row">
              <div class="col-sm-12">
                <textarea
                  class="form-control"
                  rows="10"
                  v-model="generalcomments"
                  :maxlength="maxtextlength"
                ></textarea>
                <div style="float: right">
                  Remaining characters :
                  {{ maxtextlength - generalcomments.length }}
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-12">
                <table class="table table-sm table-borderless">
                  <!-- Photos In -->
                  <Photos
                    :key="getGeneralCommentsPhotosKey(report)"
                    :photos="getGeneralCommentsPhotos(report)"
                    :path="getGeneralCommentsPhotoPath(report)"
                    :buttontext="getGeneralCommentsPhotoButtonLabel(report)"
                  />
                </table>
              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-sm-12 report-section">
          <div class="row">
            <div class="col-sm-6">
              <div class="feedback-half-section">
                <div class="row">
                  <legend>Step 4.</legend>
                  <legend>
                    <span class="subtitle"
                      >Press submit. All your comments will be sent to ACT and
                      we will contact you shortly.</span
                    >
                  </legend>

                  <div class="col-sm-12">
                    <div class="form-group row">
                      <button
                        class="btn btn-danger btn-lg btn-block"
                        @click.prevent="saveAll()"
                        :disabled="isSaving"
                      >
                        <span
                          v-if="isSaving"
                          class="spinner-border spinner-border-sm"
                        >
                          <span class="sr-only">Loading...</span>
                        </span>
                        <i v-else class="fas fa-save"></i>
                        Submit all comments to ACT, finalising the feedback
                        process
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Status Bar (Bottom navbar) -->
      <nav class="navbar fixed-bottom navbar-dark bg-primary">
        <div class="container-fluid">
          <!-- Status Bar Buttons -->
          <div id="status-bar-buttons"></div>

          <!-- Status Bar Actions -->
          <div id="status-bar-actions">
            <div class="text-light">
              <div
                class="btn-group"
                role="group"
                aria-label="Primary Actions"
              ></div>
            </div>
          </div>
        </div>
      </nav>
    </div>

    <div v-if="success === false" class="row">
      <div class="col-8 offset-2">
        <div v-if="success === false" class="alert alert-danger text-center">
          <h2 class="mt-1 mb-2">Error loading report detail</h2>
          <p v-if="errorMessage" class="font-weight-bold">{{ errorMessage }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import _ from "lodash";
import _get from "lodash/get";
import moment from "moment";

import { computed, ref, onMounted, inject } from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import { useRoute, useRouter } from 'vue-router';
import { Report, OrderedItem, Item, Comments, LandlordComments, TenantComments } from '@/models';
import Photos from '@/components/photo/Photos.vue';
import Swal from 'sweetalert2'

import emailjs from "emailjs-com";
import { init } from "emailjs-com";

const store = useStore();
const route = useRoute();
const router = useRouter();
const toasted = useToast();

// Injected properties
const actProperty: any = inject('actProperty');

// Component options
const shareholderOptions: string[] = ['Tenant', 'Landlord'];
const report = computed(() => store.getters['reports/current']);

const getReport = async (id: string): Promise<Report> => {
  return await store.dispatch('reports/getReportUnauthenticated', id);
};

const updateReport = async (): Promise<Report> => {
  return await store.dispatch('reports/updateReportUnauthenticated');
};

const updateReportFeedbackStatus = async (payload: { id: string | undefined; status: string }): Promise<any> => {
  await store.dispatch('reports/updateReportFeedbackStatus', payload);
};

const setReportDeep = async (payload: { path: string; data: any }): Promise<any> => {
  return await store.dispatch('reports/setReportDeep', payload);
};

const generateReportPdfAction = async (report: Report): Promise<any> => {
  return await store.dispatch('reports/generateReportPdf', report);
};

const removeRequest = (id: string): void => {
  store.commit('app/removeRequest', id);
};

const addCommentedItem = (item: OrderedItem): void => {
  store.commit('feedback/addCommentedItem', item);
};

const removeCommentedItem = (shareholdertype: string, itemToDelete: OrderedItem): void => {
  store.commit('feedback/removeCommentedItem', { shareholdertype, itemToDelete });
};

const setCommentedItems = (items: OrderedItem[]): void => {
  store.commit('feedback/setCommentedItems', items);
};

const setCurrentItem = (item: Item): void => {
  store.commit('feedback/setCurrentItem', item);
};

const errorMessage = ref("");
const refReport = ref<string>("");
const success = ref<boolean | null>(null);
const isSaving = ref(false);

const shareholdertypetouched = ref(false);
const nametouched = ref(false);
const emailtouched = ref(false);
const invalidemailmessage = ref("");

const conflictModal = ref(null);

const maxtextlength = ref(250);

const shareholdertype = computed({
  get: () => store.state.feedback.shareholdertype,
  set: (value: string) => { store.commit('feedback/setShareholdertype', value); }
});

const comments = computed({
  get: () => store.state.feedback.comments,
  set: (value: Comments) => { store.commit('feedback/setComments', value); }
});

const currentItem = computed({
  get: () => store.state.feedback.currentItem,
  set: (value: OrderedItem) => {
    // Make sure shareholdertype, name and email has been entered
    shareholdertypetouched.value = true;
    nametouched.value = true;
    emailtouched.value = true;

    if (!shareholderdetailvalid.value) return;

    // isItemPreviouslyCommentedOn and gotoStep3 should be defined elsewhere in your setup function
    if (isItemPreviouslyCommentedOn(value)) {
      // Display toast message
    } else {
      // Find the item and set it
      setCurrentItem(value);
      gotoStep3();
    }
  }
});

const saveCommentedItemAndResetForm = async (item: OrderedItem) => {
  addCommentedItem(item);
  setCurrentItem(new OrderedItem());

  Swal.fire({
    title: "Thanks for your feedback",
    html: `<div class="container"><div class="row"><div class="col-sm-12 text-left">You now have 3 choices.<br /><b>Go to
      Step 2</b> - Add another specific comment<br /><b>Go to Step 3</b> - If
      appropriate, give a general comment<br /><b>Go to Step 4</b> - Submit your
      feedback ending this process</div></div></div>`,
    confirmButtonColor: "#253746",
    width: 599,
  });
};

const removeCommentedItemAndReset = (item: OrderedItem) => {
  // Clear comments and photos if any
  if (shareholdertype.value === "Landlord") {
    item.landlordcomments.comments = "";
    item.landlordcomments.photos = [];
  } else if (shareholdertype.value === "Tenant") {
    item.tenantcomments.comments = "";
    item.tenantcomments.photos = [];
  }

  store.commit('feedback/removeCommentedItem', { shareholdertype: shareholdertype.value, itemtodelete: item });
};

const shareholderdetailvalid = computed((): boolean => {
  if (!shareholdertypeisvalid.value || !nameisvalid.value || !emailisvalid.value)
    return false;
  else return true;
});

const commentedItems = computed({
  get: (): OrderedItem[] => {
    return store.state.feedback.commentedItems;
  },
  set: (commentedItems: OrderedItem[]) => {
    store.state.feedback.commentedItems = commentedItems;
  }
});

const unselectedItems = computed((): OrderedItem[] => {
  let items: OrderedItem[] = [];
  let commentedItems: OrderedItem[] = [];
  if (report.value && report.value.rooms) {
    report.value.rooms.forEach((room, roomindex) => {
      if (room.sections) {
        room.sections.forEach((section, sectionindex) => {
          if (section.types) {
            section.types.forEach((type, typeindex) => {
              if (type.items) {
                type.items.forEach((item, itemindex) => {
                  let orderedItem = new OrderedItem(item);
                  orderedItem.roomname = room.name;
                  orderedItem.roomindex = roomindex;
                  orderedItem.section = section.name;
                  orderedItem.sectionindex = sectionindex;
                  orderedItem.type = type.name;
                  orderedItem.typeindex = typeindex;
                  orderedItem.item = item;
                  orderedItem.itemindex = itemindex;
                  items.push(orderedItem);
                });
              }
            });
          }
        });
      }
    });
  }

  const orderedlist = items.map((item, order) => {
    item.order = order + 1;
    return item;
  });
  if (store.state.feedback.step == "step3") {
    orderedlist.forEach((ordereditem) => {
      // If this item is in the commented list
      // Update that
      if (
        store.state.feedback.commentedItems &&
        store.state.feedback.commentedItems.length
      ) {
        const commenteditems = store.state.feedback.commentedItems.filter(
          (citem) => citem.order == ordereditem.order
        );
        if (commenteditems && commenteditems.length) {
          const commenteditem = commenteditems[0];
          commenteditem._uuid = ordereditem._uuid;
          if (shareholdertype.value == "Landlord") {
            commenteditem.landlordcomments.photos =
              ordereditem.landlordcomments.photos;
          } else if (shareholdertype.value == "Tenant") {
            commenteditem.tenantcomments.photos =
              ordereditem.tenantcomments.photos;
          }
        }
      }
    });
  }
  return orderedlist;
});

const shareholdertypeisvalid = computed((): boolean => {
  let valid = true;
  if (emailtouched.value) {
    valid = !!shareholdertype.value;
  }
  return valid;
});

const shareholdername = computed({
  get: (): string => {
    let name = "";
    if (shareholdertype.value == "Landlord") {
      name = store.state.feedback.landlordcommentsname;
    } else if (shareholdertype.value == "Tenant") {
      name = store.state.feedback.tenantcommentsname;
    }
    return name;
  },
  set: (data: string) => {
    if (shareholdertype.value == "Landlord") {
      store.state.feedback.landlordcommentsname = data;
      setReportDeep({ path: "landlordcommentsname", data });
    } else if (shareholdertype.value == "Tenant") {
      store.state.feedback.tenantcommentsname = data;
      setReportDeep({ path: "tenantcommentsname", data });
    }
  }
});

const nameisvalid = computed((): boolean => {
  let valid = true;
  if (nametouched.value) {
    if (shareholdertype.value == "Landlord") {
      valid = !!store.state.feedback.landlordcommentsname;
    } else if (shareholdertype.value == "Tenant") {
      valid = !!store.state.feedback.tenantcommentsname;
    } else {
      valid = false;
    }
  }
  return valid;
});

const shareholderemail = computed({
  get: (): string => {
    let email = "";
    if (shareholdertype.value == "Landlord") {
      email = store.state.feedback.landlordcommentsemail;
    } else if (shareholdertype.value == "Tenant") {
      email = store.state.feedback.tenantcommentsemail;
    }
    return email;
  },
  set: (data: string) => {
    if (shareholdertype.value == "Landlord") {
      store.state.feedback.landlordcommentsemail = data;
      setReportDeep({ path: "landlordcommentsemail", data });
    } else if (shareholdertype.value == "Tenant") {
      store.state.feedback.tenantcommentsemail = data;
      setReportDeep({ path: "tenantcommentsemail", data });
    }
  }
});

const emailisvalid = computed((): boolean => {
  let result = true;
  if (emailtouched.value) {
    let present = true;
    if (shareholdertype.value == "Landlord") {
      present = !!store.state.feedback.landlordcommentsemail;
    } else if (shareholdertype.value == "Tenant") {
      present = !!store.state.feedback.tenantcommentsemail;
    }

    if (!present) {
      invalidemailmessage.value = "Email is required";
      result = false;
    } else {
      let valid = validateEmail(shareholderemail.value);
      if (!valid) {
        result = false;
        invalidemailmessage.value = "Invalid email address";
      } else {
        result = true;
        invalidemailmessage.value = "";
      }
    }
  }
  return result;
});

const validateEmail = (email: string) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
}

const setShareholdertypetouched = () => {
  shareholdertypetouched.value = true;
}
const setNametouched = () => {
  nametouched.value = true;
}
const setEmailtouched = () => {
  emailtouched.value = true;
}

// Lifecycle hooks
onMounted(async () => {
  refReport.value = route.params.ref as string;
  await initialise();
});

const initialise = async () =>  {
  // initialise email service
  init("user_bxsrHY1JcetciJlEtXpDb");
  store.state.feedback.step = "step1";
  await getReport(refReport.value)
    .then((report: Report) => {
      if (report === undefined) {
        success.value = false;
        router.push({ name: "reportnotfound" });
      } else {
        // Check if report is within the days limit
        // Check if the feedback link is active
        // Update feedbackstatus to Opened
        if(report.feedbackstatus === ''){
          updateReportFeedbackStatus({ id: report.id, status: 'Opened' });
        }
        let exp_date_string = report.exportedAt;
        if (!exp_date_string) {
          exp_date_string = report.date;
        }
        let feedback_date = undefined;
        if (exp_date_string) {
          feedback_date = moment(
            exp_date_string,
            "YYYY-MM-DD[T]HH:mm"
          ).toDate();
        }

        const date1: any = moment(feedback_date, "YYYY-MM-DD").startOf("day");
        const date2: any = moment(new Date(), "YYYY-MM-DD").startOf("day");
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const feedbackActive =
          "checkout" != report.type &&
          report.customer &&
          report.customer.feedbacklinkactive &&
          report.customer.feedbacklinkactivedays &&
          diffDays <= report.customer.feedbacklinkactivedays;

        if (feedbackActive) {
          success.value = true;
        } else {
          success.value = false;
          router.push({ name: "feedbacklock" });
        }
      }
    })
    .catch((err: any) => {
      success.value = false;
      router.push({ name: "reportnotfound" });
    });
}

/**
 * Generate INV/CI/CO Report PDF
 */
const generateReportPdf = (report: Report, requestId: any): void => {
  generateReportPdfAction(report)
    .then((key: string) => {
      actProperty.downloadAttachment(key);
      toasted.success(`Report ${report.ref} PDF downloaded`);
    })
    .finally(() => removeRequest(requestId))
    .catch((err: any) => actProperty.displayError(err));
}

const gotoStep2 = () => {
  shareholdertypetouched.value = true;
  nametouched.value = true;
  emailtouched.value = true;

  if (shareholderdetailvalid.value) {
    store.commit('feedback/setStep', 'step2'); // Assuming you have a mutation for setting step

    // Clear previously entered general comments and photographs
    const currentReport = store.state.reports.current; // Accessing the current report
    if (currentReport && currentReport.comments) {
      if (shareholdertype.value === "Landlord") {
        currentReport.comments.client = "";
        currentReport.comments.clientPhotos = [];
      } else if (shareholdertype.value === "Tenant") {
        currentReport.comments.tenant = "";
        currentReport.comments.tenantPhotos = [];
      }
    }
  }
};

const gotoStep3 = () => {
  store.commit('feedback/setStep', 'step3'); // Assuming you have a mutation for setting step

  // Clear previously entered comments and photographs from selected item
  const currentItem = store.state.feedback.currentItem;
  if (!isItemAlreadyCommentedOn(currentItem)) {
    if (shareholdertype.value === "Landlord") {
      currentItem.landlordcomments = new LandlordComments();
      currentItem.item.landlordcomments.photos = [];
    } else if (shareholdertype.value === "Tenant") {
      currentItem.tenantcomments = new TenantComments();
      currentItem.item.tenantcomments.photos = [];
    }
  }
};

const addressPreviewLine1 = () => {
  const line1 = store.state.reports.current.address.line1;
  return `${line1}${line1 ? "," : ""}`;
};

const addressPreviewLine2 = () => {
  return store.state.reports.current.address.postcode;
};

const itemLabel = (item: OrderedItem) => {
  return item && item.order
    ? `${item.order} - ${item.roomname} - ${item.section} - ${item.type} - ${item.name}`
    : "";
};

const itemcomments = computed({
  get: () => {
    const currentItem = store.state.feedback.currentItem;

    if (shareholdertype.value === "Landlord") {
      return currentItem.landlordcomments.comments;
    } else if (shareholdertype.value === "Tenant") {
      return currentItem.tenantcomments.comments;
    }

    return null;
  },
  set: (data) => {
    const currentItem = store.state.feedback.currentItem;

    if (shareholdertype.value === "Landlord") {
      currentItem.landlordcomments.comments = data;
    } else if (shareholdertype.value === "Tenant") {
      currentItem.tenantcomments.comments = data;
    }
  }
});

const getItemcomments = (item: OrderedItem): string | null => {
  if (shareholdertype.value === "Landlord") {
    return item.landlordcomments.comments;
  } else if (shareholdertype.value === "Tenant") {
    return item.tenantcomments.comments;
  }

  return null;
};

const getCommentsPhotoPath = (item: OrderedItem): string => {  
  if (shareholdertype.value === "Landlord") {
    return `rooms[${item.roomindex}].sections[${item.sectionindex}].types[${item.typeindex}].items[${item.itemindex}].landlordcomments.photos`;
  } else if (shareholdertype.value === "Tenant") {
    return `rooms[${item.roomindex}].sections[${item.sectionindex}].types[${item.typeindex}].items[${item.itemindex}].tenantcomments.photos`;
  }

  return "";
};

const getCommentsPhotos = (item: Item): any[] => {
  if (shareholdertype.value === "Landlord") {
    return _get(item, "landlordcomments.photos", []);
  } else if (shareholdertype.value === "Tenant") {
    return _get(item, "tenantcomments.photos", []);
  }

  return [];
};

const getCommentsPhotosLength = (item: Item): number => {
  let arr = getCommentsPhotos(item);
  return arr.length;
}

const getCommentsPhotoButtonLabel = (item: Item): string => {
  const photos = getCommentsPhotos(item);
  return photos && photos.length ? "Add another photo" : "Add photo";
};

const photosPresent = (item: Item): boolean => {
  const photos = getCommentsPhotos(item);
  return photos && photos.length > 0;
};

const generalcomments = computed({
  get: () => {
    const feedbackComments = store.state.feedback.comments;

    if (shareholdertype.value === "Landlord") {
      return feedbackComments.client;
    } else if (shareholdertype.value === "Tenant") {
      return feedbackComments.tenant;
    }

    return "";
  },
  set: (data) => {
    if (shareholdertype.value === "Landlord") {
      store.state.feedback.comments.client = data;
    } else if (shareholdertype.value === "Tenant") {
      store.state.feedback.comments.tenant = data;
    }
  }
});

const getGeneralCommentsPhotoPath = (report: Report): string => {
  if (shareholdertype.value === "Landlord") {
    return `localcomments.clientPhotos`;
  } else if (shareholdertype.value === "Tenant") {
    return `localcomments.tenantPhotos`;
  }

  return "";
}

const getGeneralCommentsPhotos = (report: Report): any[] => {
  if (shareholdertype.value === "Landlord") {
    return _get(report, "localcomments.clientPhotos", []);
  } else if (shareholdertype.value === "Tenant") {
    return _get(report, "localcomments.tenantPhotos", []);
  }

  return [];
};

const getGeneralCommentsPhotosKey = (report: Report): any => {
  let arr = getGeneralCommentsPhotos(report);
  return `${arr.length}-${shareholdertype.value}`;
}

const getGeneralCommentsPhotoButtonLabel = (report: Report): string => {
  const photos = getGeneralCommentsPhotos(report);
  return photos && photos.length ? "Add another photo" : "Add photo";
};

const defaultReportTitle = (): string => {
  const reportType = store.state.reports.current.type;
  const dictionary = store.state.dictionary; // Assuming dictionary is in the store
  // Replace with appropriate method to format report type
  return actProperty.formatReportType(reportType, dictionary);
};

const isItemAlreadyCommentedOn = (itemToCheck: OrderedItem): boolean => {
  const commentedItems = store.state.feedback.commentedItems;
  return commentedItems.some((item) => item.order === itemToCheck.order);
};

const isItemPreviouslyCommentedOn = (itemToCheck: OrderedItem): boolean => {
  const currentlyCommented = isItemAlreadyCommentedOn(itemToCheck);
  if (!currentlyCommented) {
    const currentComments = getItemcomments(itemToCheck); // Assuming getItemcomments is defined
    const photos = getCommentsPhotos(itemToCheck); // Assuming getCommentsPhotos is defined
    return !!currentComments || (photos && photos.length > 0);
  }
  return false;
};

const saveAll = () => {
  // Make sure shareholdertype, name and email has been entered
  shareholdertypetouched.value = true;
  nametouched.value = true;
  emailtouched.value = true;
  if (!shareholderdetailvalid.value) return;

  // Move across all the unsaved changes from feedback to the current report
  if (
    shareholdertype.value == "Landlord" &&
    store.state.feedback.comments.client
  ) {
    let data = `${report.value.comments.client}\n${store.state.feedback.comments.client}`;
    setReportDeep({ path: "comments.client", data });
  } else if (
    shareholdertype.value == "Tenant" &&
    store.state.feedback.comments.tenant
  ) {
    let data = `${report.value.comments.tenant}\n${store.state.feedback.comments.tenant}`;
    setReportDeep({ path: "comments.tenant", data });
  }

  if (
    shareholdertype.value == "Landlord" &&
    report.value.localcomments.clientPhotos &&
    report.value.localcomments.clientPhotos.length
  ) {
    report.value.comments.clientPhotos =
      report.value.localcomments.clientPhotos;
  } else if (
    shareholdertype.value == "Tenant" &&
    report.value.localcomments.tenantPhotos &&
    report.value.localcomments.tenantPhotos.length
  ) {
    report.value.comments.tenantPhotos =
      report.value.localcomments.tenantPhotos;
  }

  if (commentedItems.value.length) {
    commentedItems.value.forEach((item) => {
      item.saveChangesToReportItem();
    });
  }

  toasted.warning(`Saving`);
  isSaving.value = true;
  updateReport()
    .then((report: Report) => {
      updateReportFeedbackStatus({ id: report.id, status: 'Received' });  
      isSaving.value = false;
      toasted.success(`Saved`);
      notifybackoffice();
      notifylltt();
      router.push({ name: "feedbacksuccess" });
    })
    .catch((err: any) => {
      // Check for 409 Conflict
      if (_.get(err, "response.data.statusCode") === 409) {
        const modal = conflictModal.value as any;
        modal.show();
      } else {
        actProperty.displayError(err);
      }
      isSaving.value = false;
    });
}

const notifybackoffice = async () => {
  try {
    const reportRef = store.state.reports.current.ref;
    await emailjs.send("service_4lk4hif", "template_1tyedx7", {
      ref: refReport.value,
      lltt: shareholdertype.value,
      reportref: reportRef,
      address: `${addressPreviewLine1()} ${addressPreviewLine2()}`,
      llttname: shareholdername.value,
      reporturl: `https://${window.location.hostname}/reports/${refReport.value}/comments`,
    });
  } catch (error) {
    console.log({ error });
  }
};

const notifylltt = async () => {
  try {
    const namesplits = shareholdername.value.split(" ");
    const llttname = namesplits.length > 0 ? namesplits[0] : shareholdername.value;

    await emailjs.send("service_4lk4hif", "template_guas2ag", {
      lltt: shareholdertype.value,
      ref: refReport.value,
      address: `${addressPreviewLine1()} ${addressPreviewLine2()}`,
      llttname: llttname,
      llttemail: shareholderemail.value,
    });
  } catch (error) {
    console.log({ error });
  }
};



// ... other component options and methods
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.report-header {
  margin-bottom: 1em;
}
.form-control-plaintext {
  font-weight: bold;
  outline: none;
}
.nav-item {
  a {
    cursor: pointer;
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #4caf50;
  border-color: #4caf50;
}

#status-bar-buttons,
#status-bar-switches,
#status-bar-actions {
  .btn-group,
  .custom-switch {
    display: inline-block;
    margin: 0 0.5rem;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.status-bar-separator {
  color: #f8f9fa;
}

#status-bar-switches {
  margin-left: 2rem;
}

#status-bar-actions {
  width: 22rem;
  text-align: right;
  span {
    margin-right: 0.25rem;
  }
}

.feedback-container {
  margin: 2em;
}

legend .subtitle {
  font-size: 80%;
}

.selected-item-table {
  padding: 0px;
}
.feedback-half-section {
  padding-right: 20px;
  padding-left: 20px;
}
.selected-item-jumbotron {
  margin-top: 50px;
  padding: 0rem 2rem;
}

.personal-detail {
  font-size: 19.2px;
}
.error-message {
  color: #dc3545;
}
.red-text {
  color: #dc3545;
}

.photospresent {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.swal-wide {
  width: 850px !important;
}

.bluelink {
  color: #018cff;
  text-decoration: underline;
}
</style>
