import _castArray from "lodash/castArray";
import _get from "lodash/get";
import moment from "moment-timezone";

import { datetimeToUTC, toCamelCase, omitDeep } from "@/utilities";

import {
  Base,
  Address,
  Alarm,
  Attachments,
  Client,
  Comments,
  Customer,
  Fee,
  Key,
  Keysreturn,
  Manual,
  Meter,
  Office,
  Room,
  PropertyDetails,
  Signature,
  Compliancesection,
  DepositoryTenancy,
  Booking,
} from "@/models";

const today_minus_sixmonths = moment().utc().subtract(6, "months");
export class Report extends Base {
  /**
   * The total aggregated fees for this report
   */
  public totalFees() {
    return this.fees.reduce((acc: number, fee: Fee) => {
      return (acc += parseFloat(fee.amount));
    }, 0);
  }

  public address: Address;
  public appendImages: boolean;
  public alarms: Alarm[];
  public attachments: Attachments;
  public client: Client;
  public comments: Comments;
  public localcomments: Comments;
  public landlordcommentsname: string;
  public landlordcommentsemail: string;
  public tenantcommentsname: string;
  public tenantcommentsemail: string;
  public customer: Customer;
  public date: string;
  public fees: Fee[];
  public flagged: boolean;
  public flaggedCOSM: boolean;
  public keys: Key[];
  public keysreturn: Keysreturn;
  public linkedReports: string[];
  public locked: boolean;
  public manuals: Manual[];
  public meters: Meter[];
  public office: Office;
  public preparedBy: string;
  public propertyDetails: PropertyDetails;
  public ref: string;
  public rooms: Room[];
  public signatures: Signature[];
  public type: string;
  public workorderNumber: string;
  public companyName: string;
  public branchName: string;
  public compliancesections: Compliancesection[];
  public user: string;
  public firstexportedby: string;

  public id?: string;
  public customerId?: number | string;
  public exportedAt?: string;
  public title?: string;
  public depositorytenancy: DepositoryTenancy;
  public depositorydeductionssubmitdate: string;

  public booking: Booking | undefined;
  public dataentrystatus: string;
  public feedbackstatus: string; // Possible values ['Opened', 'Received', 'Acknowledged']
  public invoiceentry: boolean;

  /**
   * Constructor initialises the variables from the class Report
   * @param data - the data to construct a type with all properties of <Report>
   */
  public constructor(data?: Partial<Report>) {
    super(data);
    data = toCamelCase(data);

    this.address = new Address(_get(data, "address"));
    this.alarms = _castArray(_get(data, "alarms", [])).map(
      (x: Partial<Alarm>) => new Alarm(x)
    );
    this.appendImages = _get(data, "appendImages", false);
    this.attachments = new Attachments(_get(data, "attachments"));
    this.client = new Client(_get(data, "client"));
    this.comments = new Comments(_get(data, "comments"));
    this.localcomments = new Comments();
    this.landlordcommentsname = _get(data, "landlordcommentsname", "");
    this.landlordcommentsemail = _get(data, "landlordcommentsemail", "");
    this.tenantcommentsname = _get(data, "tenantcommentsname", "");
    this.tenantcommentsemail = _get(data, "tenantcommentsemail", "");
    this.customer = new Customer(_get(data, "customer"));
    this.date = _get(data, "date", datetimeToUTC());
    this.flagged = _get(data, "flagged", true);
    this.flaggedCOSM = _get(data, "flaggedCosm", false);
    this.keys = _castArray(_get(data, "keys", [])).map(
      (x: Partial<Key>) => new Key(x)
    );
    this.keysreturn = new Keysreturn(_get(data, "keysreturn"));
    this.fees = _castArray(_get(data, "fees", [])).map(
      (x: Partial<Fee>) => new Fee(x)
    );
    this.locked = _get(data, "locked", false);
    this.linkedReports = _get(data, "linkedReports", []);
    this.manuals = _castArray(_get(data, "manuals", [])).map(
      (x: Partial<Manual>) => new Manual(x)
    );
    this.meters = _castArray(_get(data, "meters", [])).map(
      (x: Partial<Meter>) => new Meter(x)
    );
    this.office = new Office(_get(data, "office"));
    this.preparedBy = _get(data, "preparedBy", "ACT Property");
    this.propertyDetails = new PropertyDetails(_get(data, "propertyDetails"));
    this.ref = _get(data, "ref", "");
    this.rooms = _castArray(_get(data, "rooms", [])).map(
      (x: Partial<Room>) => new Room(x)
    );
    this.signatures = _castArray(_get(data, "signatures", [])).map(
      (x: Partial<Signature>) => new Signature(x)
    );
    this.type = _get(data, "type", "");
    this.workorderNumber = _get(data, "workorderNumber", "");
    this.companyName = _get(data, "companyName", "");
    this.branchName = _get(data, "branchName", "");
    this.compliancesections = _castArray(
      _get(data, "compliancesections", [])
    ).map((x: Partial<Compliancesection>) => new Compliancesection(x));

    if (_get(data, "id")) {
      this.id = _get(data, "id");
    }

    if (_get(data, "customerId")) {
      this.customerId = _get(data, "customerId");
    }

    if (_get(data, "exportedAt")) {
      this.exportedAt = _get(data, "exportedAt");
    }

    if (_get(data, "title")) {
      this.title = _get(data, "title");
    }

    if (_get(data, "user")) {
      this.user = _get(data, "user", "");
    }

    if (_get(data, "firstexportedby")) {
      this.firstexportedby = _get(data, "firstexportedby", "");
    }

    this.depositorytenancy = new DepositoryTenancy(
      _get(data, "depositorytenancy")
    );
    this.depositorydeductionssubmitdate = _get(
      data,
      "depositorydeductionssubmitdate",
      ""
    );
    if (data?.booking && data?.booking?.id) {
      this.booking = new Booking(_get(data, "booking"));
    }
    this.dataentrystatus = _get(data, "dataentrystatus", "");
    this.feedbackstatus = _get(data, "feedbackstatus", "");
    this.invoiceentry = _get(data, "invoiceentry", false);
  }

  get firstexportedbywithcutoff() {
    const date_moment = moment(this.date).utc().endOf("day");
    return date_moment.isBefore(today_minus_sixmonths)
      ? "info@actproperty.co.uk"
      : this.firstexportedby;
  }

  /**
   * Create a JSON representation of this object and it's descedents.
   *
   * @return {Object}
   */
  public toJSON() {
    const json = super.toJSON();

    // Convert the populated Office to the Office ID
    return {
      ...json,
      office: this.office.id,
      booking: this.booking?.id,
    };
  }

  /**
   * Convert class instance back into a plain old JSON object
   * and recursively remove all uuids we've add for Vue.
   *
   * @return {Object}
   */
  public toObject() {
    return omitDeep({ ...this }, ["_uuid", "localcomments"]);
  }
}
