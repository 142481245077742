import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { Base, Document } from '@/models';
import { toCamelCase } from '@/utilities';

interface PropertySpecInterface {
  bedrooms?: number;
  bathrooms?: number;
  additional?: number;
  sqft?: number;
  floorplan: Document[];
  furnished: string;
}

export class PropertySpec extends Base implements PropertySpecInterface {

  public propertytype: string | undefined;
  public bedrooms: number | undefined;
  public bathrooms: number | undefined;
  public receptions: number | undefined;
  public other: string | undefined;
  public sqft: number | undefined;
  public floorplan: Document[];
  public furnished: string;

  public constructor(data?: Partial<PropertySpec>) {
    super(data);
    data = toCamelCase(data);

    this.propertytype = _get(data, 'propertytype', undefined);
    this.bedrooms = _get(data, 'bedrooms', undefined);
    this.bathrooms = _get(data, 'bathrooms', undefined);
    this.receptions = _get(data, 'receptions', undefined);
    this.other = _get(data, 'other', undefined);
    this.sqft = _get(data, 'sqft', undefined);
    this.floorplan = _castArray(_get(data, 'floorplan', [])).map((x: Partial<Document>) => new Document(x));
    this.furnished = _get(data, 'furnished', '');
  }

}
