import _get from 'lodash/get';

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

export class Manual extends Base {

  public name: string;
  public make: string;
  public model: string;
  public serial: string;
  public seen: boolean;
  public interacted: boolean;

  /**
   * Constructor initialises the variables from the class Manual
   * @param data - the data to construct a type with all properties of <Manual>
   */
  public constructor(data?: Partial<Manual>) {
    super(data);
    data = toCamelCase(data);

    this.name = _get(data, 'name', '');
    this.make = _get(data, 'make', '');
    this.model = _get(data, 'model', '');
    this.serial = _get(data, 'serial', '');
    this.seen = _get(data, 'seen', true);
    this.interacted = _get(data, 'interacted', true);
  }

}
