import _get from "lodash/get";
import _castArray from "lodash/castArray";
import { Base, Qcmember } from "@/models";
import { toCamelCase } from "@/utilities";
export class Qc extends Base {
  public status: string;
  public allocationdate: string;
  public qcmember: Qcmember;

  /**
   * Constructor initialises the variables from the class Qcmember
   * @param data - the data to construct a type with all properties of <Qcmember>
   */
  public constructor(data?: Partial<Qc>) {
    super(data);
    data = toCamelCase(data);

    this.status = _get(data, "status", "");
    this.allocationdate = _get(data, "allocationdate", "");
    this.qcmember = new Qcmember(_get(data, "qcmember"));
  }

}
