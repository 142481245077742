import _castArray from 'lodash/castArray';
import _get from 'lodash/get';

import { toCamelCase, omitDeep } from '@/utilities';

import {
  Base,
} from '@/models';
export class PmLandlord extends Base {
  public id: string;
  public name: string;
  public email: string;
  public mobile: string;
  public constructor(data?: Partial<PmLandlord>) {
    super(data);
    data = toCamelCase(data);
    this.id = _get(data, 'id', '');
    this.name = _get(data, 'name', '');
    this.email = _get(data, 'email', '');
    this.mobile = _get(data, 'mobile', '');
  }

  public toObject() {
    return omitDeep({ ...this }, ['_uuid']);
  }
}