import _get from "lodash/get";
import snakeCaseKeys from "snakecase-keys";

import { copyInto } from "@/utilities";
import {
  DictionaryRoom,
  DictionarySection,
  Compliancequestion,
  Maintenanceflag,
  Reportconfiguration,
  Customer,
  DictionaryCategory,
  DictionaryReportType,
  SystemProperty,
} from "@/models";
import { ReportTypeDictionary } from "./customerdictionary/reporttypedictionary.model";
import { CustomerDictionaryInterface } from "./customerdictionaryinterface.model";

export class CustomerDictionary implements CustomerDictionaryInterface {
  public rooms: DictionaryRoom[];
  public sections: DictionarySection[];
  public categories: DictionaryCategory[];
  public reporttypes?: DictionaryReportType[];
  public systemproperties?: SystemProperty[];
  public reporttypedictionaries: ReportTypeDictionary[];

  public compliance_list: Compliancequestion[];
  public maintenance_flags: Maintenanceflag[];
  public report_configuration: Reportconfiguration;
  public company_name?: string;
  public branch_name?: string;
  public customer: Customer;

  /**
   * Create an object from a JSON representation
   * @param data
   * @return Dictionary
   */
  public static parse(data: object) {
    const obj = new CustomerDictionary();

    copyInto(obj, data, false); // false param to bypass camelcase-keys

    obj.rooms = obj.rooms ? obj.rooms.map(DictionaryRoom.parse) : [];
    obj.sections = obj.sections
      ? obj.sections.map(DictionarySection.parse)
      : [];
    obj.categories = obj.categories
      ? obj.categories.map(DictionaryCategory.parse)
      : [];
    obj.reporttypes = obj.reporttypes
      ? obj.reporttypes.map(DictionaryReportType.parse)
      : [];
    obj.systemproperties = obj.systemproperties
      ? obj.systemproperties.map(SystemProperty.parse)
      : [];
    obj.compliance_list = obj.compliance_list
      ? obj.compliance_list.map(Compliancequestion.parse)
      : [];
    obj.maintenance_flags = obj.maintenance_flags
      ? obj.maintenance_flags.map(Maintenanceflag.parse)
      : [];
    obj.report_configuration = _get(
      data,
      "report_configuration",
      {}
    ) as Reportconfiguration;
    obj.customer = new Customer(_get(data, "customer"));
    obj.reporttypedictionaries = obj.reporttypedictionaries
      ? obj.reporttypedictionaries.map(ReportTypeDictionary.parse)
      : [];

    return obj;
  }

  /**
   * Empty Item Colours And Conditions, this was required when migrating the customerdictionary
   * data from the old app XML file
   *
   * @param {CustomerDictionary} customerdictionary
   * @return {CustomerDictionary}
   */
  public static emptyItemColoursAndConditions(
    customerdictionary: CustomerDictionary
  ) {
    customerdictionary.sections.map((section) => {
      return section.types.map((type) => {
        return type.items.map((item) => {
          // Keep the "Misc" Item values
          if (
            section.name === "Misc" &&
            type.name === "Misc" &&
            item.name === "Misc"
          ) {
            return item;
          }
          // Reset all other Items
          item.conditions.additional = [];
          item.colours = [];
          return item;
        });
      });
    });
    return customerdictionary;
  }

  /**
   * Constructor
   */
  public constructor() {
    this.rooms = [];
    this.sections = [];
    this.categories = [
      { order: 1, types: {} },
      { order: 2, types: {} },
      { order: 3, types: {} },
      { order: 4, types: {} },
    ];
    this.compliance_list = [];
    this.maintenance_flags = [];
    this.report_configuration = new Reportconfiguration();
    this.company_name = "default";
    this.branch_name = "default";
    this.customer = new Customer();
    this.reporttypedictionaries = [];
  }

  /**
   * Create a JSON representation of this object
   * @param data
   * @return CustomerDictionary
   */
  public toJSON() {
    return {
      rooms: this.rooms,
      sections: snakeCaseKeys(this.sections),
      categories: this.categories,
      compliance_list: snakeCaseKeys(this.compliance_list),
      reporttypes: this.reporttypes,
      systemproperties: this.systemproperties,
      maintenance_flags: snakeCaseKeys(this.maintenance_flags),
      report_configuration: this.report_configuration,
      company_name: this.company_name,
      branch_name: this.branch_name,
      customer: this.customer,
      reporttypedictionaries: this.reporttypedictionaries,
    };
  }
}
