import _castArray from "lodash/castArray";
import _get from "lodash/get";

import {
  Base,
  Condition,
  Constituent,
  TenantComments,
  LandlordComments,
} from "@/models";
import { toCamelCase } from "@/utilities";

export class Item extends Base {
  public colour: string;
  public constituents: Constituent[];
  public make: string;
  public model: string;
  public name: string;
  public qty: number;
  public serial: string;
  public slug: string;

  public condition: {
    responsibility: string[];
    previousresponsibility: string[];
    in: Condition;
    out: Condition;
  };

  public tenantcomments: TenantComments;
  public landlordcomments: LandlordComments;
  public locallyadded: boolean;

  /**
   * Constructor initialises the variables from the class Item
   * @param data - the data to construct a type with all properties of <Item>
   */
  public constructor(data?: Partial<Item>) {
    super(data);
    data = toCamelCase(data);

    this.colour = _get(data, "colour", "");
    this.constituents = _castArray(_get(data, "constituents", [])).map(
      (x: Partial<Constituent>) => new Constituent(x)
    );
    this.make = _get(data, "make", "");
    this.model = _get(data, "model", "");
    this.name = _get(data, "name", "");
    this.qty = _get(data, "qty", 0);
    this.serial = _get(data, "serial", "");
    this.slug = _get(data, "slug", "");
    this.tenantcomments = new TenantComments(_get(data, "tenantcomments"));
    this.landlordcomments = new LandlordComments(
      _get(data, "landlordcomments")
    );
    this.condition = {
      responsibility: _get(data, "condition.responsibility", []),
      previousresponsibility: _get(
        data,
        "condition.previousresponsibility",
        []
      ),
      in: new Condition(_get(data, "condition.in", {})),
      out: new Condition(_get(data, "condition.out", {})),
    };
    this.locallyadded = _get(data, "locallyadded", false);
  }
}
