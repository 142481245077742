<template>
  <div class="card bg-light booking-section">
    <h3 class="card-header booking-section-header" @click="expanded = !expanded">Access</h3>
    <!--div class="card-body" v-if="(isQCuser && !expanded)">
      <div class="row mb-3" v-if="!isNewBooking()">
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-3">
              {{ keypickup }}
            </div>
            <div class="col-md-3">
              {{ keypickupbranchname }}
            </div>
            <div class="col-md-3">
              {{ keypickupfromaddressLine1 }}
            </div>
            <div class="col-md-3">
              {{ keypickupfromaddressLine2 }}
            </div>
          </div>
        </div>
        <div class="col-md-1">
          {{ keypickupfromaddressTown }}
        </div>
        <div class="col-md-1">
          {{ keypickupfromaddressPostcode }}
        </div>
        <div class="col-md-1">
          {{ keypickupfromaddressPhone }}
        </div>
        <div class="col-md-1">
          {{ confirmaccess ? 'Yes' : 'No' }}
        </div>
      </div>
      <div class="row mb-3" v-if="!isNewBooking()">
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-3">
              {{ releasekeysto }}
            </div>
            <div class="col-md-3">
              {{ releasekeystobranchname }}
            </div>
            <div class="col-md-3">
              {{ keyreleasetoaddressLine1 }}
            </div>
            <div class="col-md-3">
              {{ keyreleasetoaddressLine2 }}
            </div>
          </div>
        </div>
        <div class="col-md-1">
          {{ keyreleasetoaddressTown }}
        </div>
        <div class="col-md-1">
          {{ keyreleasetoaddressPostcode }}
        </div>
        <div class="col-md-1">
          {{ keyreleasetoaddressPhone }}
        </div>
      </div>

      <div class="row" v-if="!isNewBooking()">
        <div class="col-md-6">
          <div class="row" v-for="(tenant, $index) in tenantsarray" :key="$index" :class="{ 'mt-1': $index > 0 }">
            <div class="col-md-1">
              TT
            </div>
            <div class="col-md-4">
              {{ tenant.ttname }}
            </div>
            <div class="col-md-4">
              {{ tenant.ttemail }}
            </div>
            <div class="col-md-3">
              {{ tenant.ttmobile }}
            </div>

          </div>
        </div>
        <div class="col-md-2">
          {{ accessissues }}
        </div>
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-6">
              {{ tenantattending }}
            </div>
            <div class="col-md-6">
              {{ keystobereleased }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label class="col-form-label">Do not contact</label>
              <div>{{ donotcontacttt ? 'Yes' : 'No' }}</div>
            </div>
            <div class="col-md-6">
              <span>
                <label class="col-form-label">Access confirmation (PDF/Word/JPEG Only):</label>
                <div class="row">
                  <div class="col-md-6">
                    <Documents :buttontext="'Upload'" :entity="'booking'" :key="booking.accessattachments.length"
                      :documents="booking.accessattachments" :path="'accessattachments'"
                      @clicked="showAccessattachementDocument" />
                  </div>
                  <div v-html="booking.accessnotes" class="col-md-6">
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </!--div-->
    <div class="card-body">
      <div class="row" style="margin-left: -12px; margin-right: -12px">
        <div class="col">
          <div class="row">
            <div class="col-md-3">
              <label class="col-form-label">Key Pickup/Access</label>
              <div>
                <singleselect-text v-model="keypickup" :options="keypickupOptions" :allow-empty="true" :taggable="false"
                  @select="onKeypickupSelected">
                  <template #clear v-if="keypickup">
                                  <i @mousedown.prevent.stop="
                    setKeypickup('');
                  onTagKeypickupBranchName('');
                  " class="multiselect__clear fa fa-times" aria-label="Clear Key Pickup"></i>
                  </template>

                  <template #option="props">
                    <div :class="{
      'text-cross': checkMeetTenant(props.option),
    }">
                      {{ props.option }}
                    </div>
                  </template>
                </singleselect-text>
              </div>
            </div>
            <div class="col-md-3" v-if="keypickup !== 'Misc'">
              <div class="form-group" v-if="keypickup === 'From Agency'">
                <label class="col-form-label">Branch</label>
                <div>
                  <singleselect-text v-model="keypickupbranchname" :options="filteredkeypickupbranchNameOptions"
                    :allow-empty="true" :disabled="keypickupbranchNameOptions.length < 1" :taggable="false"
                    @select="onKeypickupbranchSelected" :ref="el => { dynamicRefs['keyPickupBranchDropDown'] = el }"
                    @open="dropDownOpened('keyPickupBranchDropDown')"
                    @search-change="keypickupbranchnamesearchfilter = $event">

                    <template #clear>
                      <i v-if="keypickupbranchname" @mousedown.prevent.stop="onTagKeypickupBranchName('')"
                        class="multiselect__clear fa fa-times" aria-label="Clear Branch Name"></i>
                    </template>
                  </singleselect-text>
                </div>
              </div>
            </div>

            <div v-if="keypickup === 'Misc'" class="col-md-3">
              <label class="col-form-label">Title</label>
              <input type="text" min="0" class="form-control height-43" v-model.lazy="keypickupfromaddressName"
                autocomplete="keypickupfromaddressName" />
            </div>

            <div class="col-md-3">
              <label class="col-form-label">Key Pickup Address - Line 1</label>
              <input type="text" min="0" class="form-control height-43" v-model.lazy="keypickupfromaddressLine1"
                autocomplete="keypickupfromaddressLine1" />
            </div>
            <div class="col-md-3">
              <label class="col-form-label">Line 2</label>
              <input type="text" min="0" class="form-control height-43" v-model.lazy="keypickupfromaddressLine2"
                autocomplete="keypickupfromaddressLine2" />
            </div>
          </div>
        </div>

        <div class="col-md-1">
          <label class="col-form-label">Town</label>
          <input type="text" min="0" class="form-control height-43" v-model.lazy="keypickupfromaddressTown"
            autocomplete="keypickupfromaddressTown" />
        </div>

        <div class="col-md-1" style="min-width: 150px;">
          <label class="col-form-label">Postcode</label>
          <input type="text" min="0" class="form-control height-43" v-model.lazy="keypickupfromaddressPostcode"
            autocomplete="keypickupfromaddressPostcode" />
        </div>
        <div class="col-md-1" style="min-width: 150px;">
          <label class="col-form-label">Phone</label>
          <input type="text" min="0" class="form-control height-43" v-model.lazy="keypickupfromaddressPhone"
            autocomplete="keypickupfromaddressPhone" />
        </div>
        <div class="col-md-1">
          <label class="col-form-label">Access confirmed</label>
          <div class="height-43">
            <DxSwitch :value="confirmaccess" @value-changed="confirmaccess = $event.value" />
          </div>
        </div>
      </div>
      <div class="row" style="margin-left: -12px; margin-right: -12px; margin-top: 12px">
        <div class="col">
          <div class="row">
            <div class="col-md-3">
              <label class="col-form-label">Return/Release Keys to</label>
              <div>
                <singleselect-text v-model="releasekeysto" :options="releasekeystoOptions" :allow-empty="true"
                  :taggable="false">

                  <template #clear v-if="releasekeysto">
                    <i @mousedown.prevent.stop="
      setReleasekeysto('');
    onTagKeyreleaseBranchName('');
    " class="multiselect__clear fa fa-times" aria-label="Clear Key Pickup"></i>
                  </template>
                  <template #singleLabel="props">
                    <div :class="{
      'text-cross': checkMeetTenant(props.option),
    }">
                      {{ props.option }}
                    </div>
                  </template>
                  <template #option="props">
                    <div :class="{
      'text-cross': checkMeetTenant(props.option),
    }">
                      {{ props.option }}
                    </div>
                  </template>
                </singleselect-text>
              </div>
            </div>
            <!--div class="col-md-2">
              <div class="form-group" v-if="releasekeysto != 'Meet Tenant'">
                <label class="col-form-label">Agency Name</label>
                <input
                  type="text"
                  min="0"
                  class="form-control height-43"
                  v-model.lazy="releasekeystocompanyname"
                  autocomplete="releasekeystocompanyname"
                  :disabled="releasekeysto === 'To Agency' || releasekeysto === 'Via Concierge' || releasekeysto === 'Via Landlord'"
                />
              </div>
            </div-->
            <div class="col-md-3" v-if="releasekeysto !== 'Misc'">
              <div class="form-group" v-if="releasekeysto === 'To Agency'">
                <label class="col-form-label">Branch</label>
                <div>
                  <singleselect-text v-model="releasekeystobranchname" :options="filteredreleasekeystoOptions"
                    :allow-empty="true" :disabled="keyreleasebranchNameOptions.length < 1" :taggable="false"
                    @select="onKeyreleasebranchSelected" :ref="el => { dynamicRefs['keyReleaseBranchDropDown'] = el }"
                    @open="dropDownOpened('keyReleaseBranchDropDown')"
                    @search-change="keyreleasebranchnamesearchfilter = $event">

                    <template #clear>
                      <i v-if="releasekeystobranchname" @mousedown.prevent.stop="onTagKeyreleaseBranchName('')"
                        class="multiselect__clear fa fa-times" aria-label="Clear Branch Name"></i>
                    </template>
                  </singleselect-text>
                </div>
              </div>
            </div>
            <div v-if="releasekeysto === 'Misc'" class="col-md-3">
              <label class="col-form-label">Title</label>
              <input type="text" min="0" class="form-control height-43" v-model.lazy="keyreleasetoaddressName"
                autocomplete="keyreleasetoaddressName" />
            </div>
            <div class="col-md-3">
              <label class="col-form-label">Key Release To - Line 1</label>
              <input type="text" min="0" class="form-control height-43" v-model.lazy="keyreleasetoaddressLine1"
                autocomplete="keyreleasetoaddressLine1" />
            </div>
            <div class="col-md-3">
              <label class="col-form-label">Line 2</label>
              <input type="text" min="0" class="form-control height-43" v-model.lazy="keyreleasetoaddressLine2"
                autocomplete="keyreleasetoaddressLine2" />
            </div>
          </div>
        </div>

        <div class="col-md-1">
          <label class="col-form-label">Town</label>
          <input type="text" min="0" class="form-control height-43" v-model.lazy="keyreleasetoaddressTown"
            autocomplete="keyreleasetoaddressTown" />
        </div>

        <div class="col-md-1" style="min-width: 150px;">
          <label class="col-form-label">Postcode</label>
          <input type="text" min="0" class="form-control height-43" v-model.lazy="keyreleasetoaddressPostcode"
            autocomplete="keyreleasetoaddressPostcode" />
        </div>
        <div class="col-md-1" style="min-width: 150px;">
          <label class="col-form-label">Phone</label>
          <input type="text" min="0" class="form-control height-43" v-model.lazy="keyreleasetoaddressPhone"
            autocomplete="keyreleasetoaddressPhone" />
        </div>
        <div class="col-md-1"></div>
      </div>

      <div class="row" style="margin-left: -12px; margin-right: -12px; margin-top: 12px">
        <div class="col-md-6">
          <div class="row" v-for="(tenant, $index) in tenantsarray" :key="$index" :class="{ 'mt-1': $index > 0 }">
            <div :class="{ 'col-md-4':isNewBooking(), 'col-md-3':  !isNewBooking()}">
              <label v-if="$index === 0" class="col-form-label">Tenant Name
              </label>
              <Popper class="light-popper float-right pt-2" placement="top" hover="true" arrow="true"
                v-if="booking?.previousbooking?.jobtype === 'checkout' && booking?.jobtype === 'checkin' && booking.previousbooking.tenantNameList.length > 0">
                <template #content>
                  <div v-for="(tenant, $index) in booking.previousbooking.tenants" :key="$index">{{ tenant.ttname }}
                  </div>
                </template>
                <i class="fas fa-undo-alt"></i>
              </Popper>
              <input type="text" min="0" class="form-control height-43"
                :class="{ 'text-danger': tenant.attending === 'no', 'text-success': tenant.attending === 'yes' }"
                v-model.lazy="tenant.ttname" @change="addUnsavedChange('ttname')" />
            </div>
            <div :class="{ 'col-md-4':isNewBooking(), 'col-md-3':  !isNewBooking()}">
              <label v-if="$index === 0" class="col-form-label">Tenant Email</label>
              <input type="text" min="0" class="form-control height-43"
                :class="{ 'text-danger': tenant.attending === 'no', 'text-success': tenant.attending === 'yes' }"
                v-model.lazy="tenant.ttemail" @change="addUnsavedChange('ttemail')" autocomplete="ttemail" />
            </div>
            <div class="col-md-3">
              <label v-if="$index === 0" class="col-form-label">Tenant Mobile</label>
              <div style="display:flex">
              <input type="text" min="0" class="form-control height-43"
                :class="{ 'text-danger': tenant.attending === 'no', 'text-success': tenant.attending === 'yes' }"
                v-model.lazy="tenant.ttmobile" @change="addUnsavedChange('ttmobile')" autocomplete="ttmobile" />
              <i v-if="tenant.ttmobile && isInternationalNumber(tenant.ttmobile)" class="fas fa-info-circle text-right ml-2 mt-3 text-danger" title="International number, will not send SMS"></i>
            </div>
            </div>
            <div class="col-md-1 mt-2">
              <button class="btn btn-sm btn-outline-secondary" @click.prevent="removeTenant(tenant)" v-if="$index > 0">
                <i class="fas fa-minus"></i>
              </button>
            </div>
            <div v-if="!isNewBookingOrTenantBlank(tenant)" class="col-md-2" :class="{'mt-5' : $index === 0,'mt-2' : $index !== 0}">
              <i :class="{ 'far fa-circle fa-1x red': tenant.attending !== 'no', 'far fa-dot-circle fa-1x red': tenant.attending === 'no' }" @click="tenansFlag('red',tenant,$index)"></i>
              <i :class="{ 'far fa-circle fa-1x amber': tenant.attending !== 'unsure' || tenant.attending !== '', 'far fa-dot-circle fa-1x amber': tenant.attending === 'unsure' || tenant.attending === '' }" @click="tenansFlag('amber',tenant,$index)"></i>
              <i :class="{ 'far fa-circle fa-1x green': tenant.attending !== 'yes', 'far fa-dot-circle fa-1x green': tenant.attending === 'yes' }" @click="tenansFlag('green',tenant,$index)"></i>
            </div>
          </div>
          <div class="row">
            <div class="col-md-11 text-right">
              <button class="btn btn-sm btn-outline-secondary mt-2" @click.prevent="addTenant">
                <i class="fas fa-plus"></i> Add
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <label class="col-form-label">Access notes seen by PI
          </label>
          <textarea class="form-control"  ref="accessissuesref" rows="8" v-model.lazy="accessissues"></textarea>
          <i class="fas fa-arrow-down text-right ml-2"  @click="showAccessIssuesnotesModal" :style="{ background: accessissuesrowCount ? 'yellow !important' : '' }"></i>
        </div>
        <div class="col-md-3">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label">Is Tenant Attending?</label>
                <div>
                  <tri-state-toggle :value="tenantattending" @select="setTenantattending" />
                </div>
              </div>
            </div>
            <div class="col-md-6" v-if="jobtype === 'inventory' || jobtype === 'checkin'">
              <label class="col-form-label">Releasing keys</label>
              <singleselect-text v-model="keystobereleased" :options="releasekeysOptions">

                <template #clear v-if="keystobereleased">
                  <i @mousedown.prevent.stop="keystobereleased = ''" class="multiselect__clear fa fa-times"
                    aria-label="Clear"></i>
                </template>
              </singleselect-text>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="col-form-label">Do not contact</label>
                <div>
                  <DxSwitch :value="donotcontacttt" @value-changed="donotcontacttt = $event.value" />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <span>
                <label class="col-form-label">Access confirmation (PDF/Word/JPEG Only):</label>
                <div class="row">
                  <div class="col-md-6">
                    <Documents :buttontext="'Upload'" :entity="'booking'" :key="booking.accessattachments.length"
                      :documents="booking.accessattachments" :path="'accessattachments'"
                      @clicked="showAccessattachementDocument" />
                  </div>
                  <div class="col-md-6">
                    <button v-if="!accessnotePresent()" class="btn btn-sm btn-outline-secondary mt-2"
                      :class="{ 'yellow-outline': accessnotePresent() }" @click.prevent="showAccessnotesModal">
                      Text content
                    </button>
                    <i v-else class="far fa-4x fa-file-alt mt-2" @click.prevent="showAccessnotesModal"></i>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AccessnotesDialog :name="`accessnotes-modal`" ref="accessnotesModal" :booking="booking" />
    <AccessIssueNotesDialog title='Access notes seen by PI' :name="`accessissuesnotes-modal`"
      ref="accessissuesnotesModal" />

  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch, nextTick, defineEmits,inject } from 'vue';
import { useStore } from 'vuex';
import { Customer, Address, Tenant, Policies, Booking } from "@/models";
import AccessnotesDialog from "@/components/modals/AccessnotesDialog.vue";
import AccessIssueNotesDialog from "@/components/modals/AccessIssueNotesDialog.vue";
import { DxSwitch } from 'devextreme-vue/switch';
import { useRoute } from 'vue-router';

import Documents from "@/components/document/Documents.vue";
import TriStateToggle from "@/components/TriStateToggle.vue";

const emit = defineEmits(['showaccessattachementdocument']);

// Getters
const store = useStore();
const booking = computed((): Booking => store.getters['diary/booking']);
const jobtype = computed(() => store.getters['diary/jobtype']);
const _keypickup = computed(() => store.getters['diary/keypickup']);
const customer = computed(() => store.getters['diary/customer']);
const managedtype = computed(() => store.getters['diary/managedtype']);
const _keypickupbranchname = computed(() => store.getters['diary/keypickupbranchname']);
const _confirmaccess = computed(() => store.getters['diary/confirmaccess']);
const _accessissues = computed(() => store.getters['diary/accessissues']);
const _releasekeystobranchname = computed(() => store.getters['diary/releasekeystobranchname']);
const _releasekeysto = computed(() => store.getters['diary/releasekeysto']);
const tenantsarray = computed(() => store.getters['diary/tenantsarray']);
const keypickupfromaddress = computed(() => store.getters['diary/keypickupfromaddress']);
const keyreleasetoaddress = computed(() => store.getters['diary/keyreleasetoaddress']);
const _keystobereleased = computed(() => store.getters['diary/keystobereleased']);
const _tenantattending = computed(() => store.getters['diary/tenantattending']);
const _donotcontacttt = computed(() => store.getters['diary/donotcontacttt']);
const selectedCustomername = computed(() => store.getters['diary/selectedCustomername']);
const isQCuser = computed(() => store.getters['auth/isQCUser']);
// Mutations
const setKeypickup = (keypickup: string) => store.commit('diary/setKeypickup', keypickup);
const setKeypickupfromaddress = (address: Address) => store.commit('diary/setKeypickupfromaddress', address);
const setKeyreleasetoaddress = (address: Address) => store.commit('diary/setKeyreleasetoaddress', address);
const setReleasekeysto = (releasekeysto: string) => store.commit('diary/setReleasekeysto', releasekeysto);
const setKeystobereleased = (releasekeysto: string) => store.commit('diary/setKeystobereleased', releasekeysto);
const addStateTenant = (tenant: Tenant) => store.commit('diary/addTenant', tenant);
const removeTenant = (tenant: Tenant) => store.commit('diary/removeTenant', tenant);
const setKeypickupfromaddressDeep = (payload: { path: string; data: string; }) => store.commit('diary/setKeypickupfromaddressDeep', payload);
const setKeyreleasetoaddressDeep = (payload: { path: string; data: string; }) => store.commit('diary/setKeyreleasetoaddressDeep', payload);
const setStateTenantattending = (value: string) => store.commit('diary/setTenantattending', value);
const setDonotcontacttt = (value: string) => store.commit('diary/setDonotcontacttt', value);
const addUnsavedChange = (element: string) => store.commit('diary/addUnsavedChange', element);
const setBookingDeep = (payload: { path: string; data: any }) => store.dispatch('diary/setBookingDeep', payload);
const route = useRoute();
const actProperty: any = inject('actProperty');

// Actions
const getCustomers = async (payload?: {
  starts_with?: string;
  company_name?: string;
  branch_name?: string;
  managedtype?: string;
}): Promise<Customer[]> => {
  return await store.dispatch('diary/getCustomers', payload);
};
const getBranchNameOptionsWithManagedtype = async (payload?: {
  company_name?: string;
  managedtype?: string;
}): Promise<any> => {
  return await store.dispatch('diary/getBranchNameOptionsWithManagedtype', payload);
};

let keypickupOptions: string[] = [
  "From Agency",
  "Meet Tenant",
  "Via Concierge",
  "Via Landlord",
  "TBC",
  "Misc",
];
const keypickupbranchNameOptions = ref([]);
const keyreleasebranchNameOptions = ref([]);
const releasekeysOptions: string[] = ["Yes", "No", "TBC"];
const keypickupbranchnamesearchfilter = ref('');
const keyreleasebranchnamesearchfilter = ref('');
const accessissuesref = ref(null);

const accessnotesModal = ref(null);
const accessissuesnotesModal = ref(null);
const dynamicRefs = ref({});

const expanded = ref(false);

const keypickup = computed({
  get: () => _keypickup.value,
  set: (val: string) => {
    setKeypickup(val);
    if (keypickup.value != "From Agency")
      setKeypickupfromaddress(new Address());
  }
});

const changeKeypickupbranchnameoptions = async () => {
  if (keypickup.value === "From Agency") {
    if (customer.value && customer.value.companyName) {
      keypickupbranchNameOptions.value = await getBranchNameOptionsWithManagedtype({
        company_name: customer.value.companyName,
        managedtype: managedtype.value,
      });
    } else if (selectedCustomername.value) {
      keypickupbranchNameOptions.value = await getBranchNameOptionsWithManagedtype({
        company_name: selectedCustomername.value,
        managedtype: managedtype.value,
      });
    } else {
      keypickupbranchNameOptions.value = [];
      keypickupbranchname.value = "";
    }
  }
};

const changeKeyreleasebranchnameoptions = async () => {
  if (releasekeysto.value === "To Agency") {
    if (customer.value && customer.value.companyName) {
      keyreleasebranchNameOptions.value = await getBranchNameOptionsWithManagedtype({
        company_name: customer.value.companyName,
        managedtype: managedtype.value,
      });
    } else if (selectedCustomername.value) {
      keyreleasebranchNameOptions.value = await getBranchNameOptionsWithManagedtype({
        company_name: selectedCustomername.value,
        managedtype: managedtype.value,
      });
    } else {
      keyreleasebranchNameOptions.value = [];
      releasekeystobranchname.value = "";
    }
  }
};

const keypickupbranchname = computed({
  get: () => _keypickupbranchname.value,
  set: (name) => {
    store.commit('diary/setKeypickupbranchname', name);
    if(!name){
      setKeypickupfromaddress(new Address());
      keypickupfromaddressPhone.value = '';
    }
    if (releasekeysto.value === "To Agency" && !releasekeystobranchname.value) {
      store.commit('diary/setReleasekeystobranchname', name);
      releasekeystobranchname.value = name;
    }
  }
});

const onTagKeypickupBranchName = (val) => {
  keypickupbranchname.value = val;
  setKeypickupfromaddress(new Address());
  keypickupfromaddressPhone.value = '';
};

const onKeypickupSelected = async (val) => {
  if (val === "From Agency" && !releasekeysto.value) {
    keypickupbranchname.value = customer.value.branchName;
    let { address, policies } = await getBranchAddress(customer.value.branchName);
    setKeypickupfromaddress(new Address(address));
    keypickupfromaddressPhone.value = policies.phone;
    if (!customer.value.policies.accessconfirm) {
      confirmaccess.value = true;
    }
  } 
  if (val !== "From Agency"){
    keypickupbranchname.value = '';
    setKeypickupfromaddress(new Address());
    keypickupfromaddressPhone.value = '';
  }
};


const onKeypickupbranchSelected = async (branchName) => {
  let { address, policies } = await getBranchAddress(branchName);
  setKeypickupfromaddress(new Address(address));
  keypickupfromaddressPhone.value = policies.phone;
  if (releasekeysto.value === "To Agency" && keyreleasetoaddress.value.isempty) {
    setKeyreleasetoaddress(new Address(address));
  }
};

const onKeyreleasebranchSelected = async (branchName) => {
  if (branchName) {
    let { address, policies } = await getBranchAddress(branchName);
    setKeyreleasetoaddress(new Address(address));
    keyreleasetoaddressPhone.value = policies.phone;
  }
};

const onTagKeyreleaseBranchName = (val) => {
  releasekeystobranchname.value = val;
  setKeyreleasetoaddress(new Address());
};

const getBranchAddress = async (branchName) => {
  let address = new Address();
  let policies = new Policies();
  if (branchName) {
    const customers = await getCustomers({
      company_name: customer.value.companyName,
      branch_name: branchName,
      managedtype: booking.value.managedtype, // Assuming booking is a reactive reference
    });
    if (customers && customers.length) {
      policies = customers[0].policies;
      address = customers[0].address;
    }
  }
  return { address, policies };
};


const confirmaccess = computed({
  get: () => _confirmaccess.value,
  set: (val) => store.commit('diary/setConfirmaccess', val)
});

const accessissues = computed({
  get: () =>  {
    accessissuesref.value = _accessissues.value
    return _accessissues.value;
  },
  set: (val) => {
    accessissuesref.value = val;
    store.commit('diary/setAccessissues', val)
  }
});


const releasekeystobranchname = computed({
  get: () => _releasekeystobranchname.value,
  set: (name) => store.commit('diary/setReleasekeystobranchname', name)
});

const releasekeystoOptions = ref([
  "To Agency",
  "Meet Tenant",
  "Via Concierge",
  "Via Landlord",
  "TBC",
  "Misc",
]);

const releasekeysto = computed({
  get: () => _releasekeysto.value,
  set: (val) => {
    setReleasekeysto(val);
    if(!val || val !== "To Agency"){
      setKeyreleasetoaddress(new Address());
      keyreleasetoaddressPhone.value = ''; 
    }
  }
});

const keypickupfromaddressLine1 = computed({
  get: () => keypickupfromaddress.value.line1,
  set: (data) => setKeypickupfromaddressDeep({ path: "line1", data })
});
const keypickupfromaddressName = computed({
  get: () => keypickupfromaddress.value.name,
  set: (data) => setKeypickupfromaddressDeep({ path: "name", data })
});
const keypickupfromaddressLine2 = computed({
  get: () => keypickupfromaddress.value.line2,
  set: (data) => setKeypickupfromaddressDeep({ path: "line2", data })
});

const keypickupfromaddressTown = computed({
  get: () => keypickupfromaddress.value.town,
  set: (data) => setKeypickupfromaddressDeep({ path: "town", data })
});


const keypickupfromaddressPostcode = computed({
  get: () => keypickupfromaddress.value.postcode,
  set: (data) => setKeypickupfromaddressDeep({ path: "postcode", data })
});

const keypickupfromaddressPhone = computed({
  get: () => keypickupfromaddress.value.phone,
  set: (data) => setKeypickupfromaddressDeep({ path: "phone", data })
});

// Computed properties for keyreleasetoaddress
const keyreleasetoaddressName = computed({
  get: () => keyreleasetoaddress.value.name,
  set: (data) => setKeyreleasetoaddressDeep({ path: "name", data })
});
const keyreleasetoaddressLine1 = computed({
  get: () => keyreleasetoaddress.value.line1,
  set: (data) => setKeyreleasetoaddressDeep({ path: "line1", data })
});
const keyreleasetoaddressLine2 = computed({
  get: () => keyreleasetoaddress.value.line2,
  set: (data) => setKeyreleasetoaddressDeep({ path: "line2", data })
});

const keyreleasetoaddressTown = computed({
  get: () => keyreleasetoaddress.value.town,
  set: (data) => setKeyreleasetoaddressDeep({ path: "town", data })
});


const keyreleasetoaddressPostcode = computed({
  get: () => keyreleasetoaddress.value.postcode,
  set: (data) => setKeyreleasetoaddressDeep({ path: "postcode", data })
});

const keyreleasetoaddressPhone = computed({
  get: () => keyreleasetoaddress.value.phone,
  set: (data) => setKeyreleasetoaddressDeep({ path: "phone", data })
});

const tenantattending = computed({
  get: () => _tenantattending.value === "unsure" ? "neutral" : _tenantattending.value,
  set: (val) => {
    val = val === "neutral" ? "unsure" : val;
    setStateTenantattending(val); // Assuming setStateTenantattending is defined
  }
});

const setTenantattending = (val: string) => {
  if (val === "neutral") val = "unsure";
  setStateTenantattending(val);
  booking.value.setAccessAfterTenantattendingChange();
}

const donotcontacttt = computed({
  get: () => _donotcontacttt.value,
  set: (val: string) => setDonotcontacttt(val)
});



const addTenant = () => {
  addStateTenant(new Tenant());
}

// https://trello.com/c/VbNBbsIp/760-1-tenant-responses-manual-override-for-tt-replies
const tenansFlag = (flag:string,tenant:Tenant,index: number) => {
  tenant.attending = flag === 'red' ? 'no' : flag === 'green' ? 'yes' : 'unsure';
  setBookingDeep({
    path: `tenants[${index}]`,
    data:tenant
  });

  const othertenantresponse = actProperty.checkOtherTenantsResponse(booking.value,tenant.ttemail, tenant.attending);
  // Case 1
  if(tenant.attending === 'yes') {
    setTenantattending(tenant.attending);
  }
  // case 2
  else if(tenant.attending === 'no' && othertenantresponse) {
    let filteredTenants = booking.value.tenants.filter((tenant) => {
        return !(tenant.ttname.trim() === "" && tenant.ttmobile.trim() === "" && tenant.ttemail.trim() === "");
    });
    setBookingDeep({ path: "tenants", data: filteredTenants });
    setTenantattending(tenant.attending);
  }
  // Case 3
  else if(tenant.attending === 'unsure') {
    const othersareno = actProperty.checkOtherTenantsResponse(booking.value,tenant.ttemail, 'no');
    if(othersareno) {
      setTenantattending('unsure');
    }
  }

  // Case 4 - is covered by default

}

const showAccessattachementDocument = (index: number) => {
  emit("showaccessattachementdocument", index);
}

const keystobereleased = computed({
  get: () => _keystobereleased.value,
  set: (val: string) => setKeystobereleased(val)
});

const showAccessnotesModal = () => {
  const modal = accessnotesModal.value as any;
  modal.init();
  modal.show();
}

const showAccessIssuesnotesModal = () => {
  const modal = accessissuesnotesModal.value as any;
  modal.show();
}

const accessnotePresent = () => {
  let plaintext = '';
  if (booking.value.accessnotes) {
    var tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = booking.value.accessnotes;
    plaintext = tempDivElement.textContent || tempDivElement.innerText || "";
  }
  return (plaintext.trim().length > 0);
}

const dropDownOpened = (ref: string) => {
  nextTick(() => {
    // Get the reference to the dropdown list container
    const multiselect = dynamicRefs.value[ref] as any;
    const dropdownList = multiselect.$el.querySelector('.multiselect__content-wrapper');

    // Get the reference to the selected option element
    const selectedOption = multiselect.$el.querySelector('.multiselect__option--selected');

    // Scroll to the position of the selected element
    if (selectedOption?.offsetTop)
      dropdownList.scrollTop = selectedOption.offsetTop;
  });
}

const accessissuesrowCount = computed(() => {
  if (!accessissuesref.value) return;
  return accessissuesref.value.scrollHeight > accessissuesref.value.clientHeight;
});

const filteredkeypickupbranchNameOptions = computed(() => {
  return keypickupbranchNameOptions.value.filter((b: string) => {
    if (keypickupbranchnamesearchfilter.value)
      return b.toLowerCase().startsWith(keypickupbranchnamesearchfilter.value.toLowerCase());
    else
      return true;
  })
});


const filteredreleasekeystoOptions = computed(() => {
  return keyreleasebranchNameOptions.value.filter((b: string) => {
    if (keyreleasebranchnamesearchfilter.value)
      return b.toLowerCase().startsWith(keyreleasebranchnamesearchfilter.value.toLowerCase());
    else
      return true;
  })
});


watch(keypickup, async (newValue, oldValue) => {
  await changeKeypickupbranchnameoptions(); // Assuming this is a defined function
  if (newValue === "From Agency") {
    if (!keypickupbranchname.value) { // Assuming keypickupbranchname is a reactive ref
      keypickupbranchname.value = customer.value.branchName;
      let { address, policies } = await getBranchAddress(keypickupbranchname.value); // Assuming getBranchAddress is a defined async function
      setKeypickupfromaddress(new Address(address));
      keypickupfromaddressPhone.value = policies.phone; // Assuming setKeypickupfromaddress is a defined function
    }
  } else {
    // setKeypickupfromaddress(new Address()); // Assuming this is the desired functionality
  }
  // Confirm Access is a false
  if (keypickup.value === "From Agency" && !customer.value.policies.accessconfirm) {
    confirmaccess.value = true;
  }
  if (keypickup.value  === "Via Landlord") {
    if(booking.value.landlordmobilearray.length > 0){
      keypickupfromaddressPhone.value = booking.value.landlordmobilearray[0];
    }
  }
});

watch(releasekeysto, async (newValue, oldValue) => {
  await changeKeyreleasebranchnameoptions(); // Assuming this is a defined function
  if (newValue === "To Agency") {
    if (!releasekeystobranchname.value) { // Assuming releasekeystobranchname is a reactive ref
      releasekeystobranchname.value = customer.value.branchName;
      let { address, policies } = await getBranchAddress(releasekeystobranchname.value);
      setKeyreleasetoaddress(new Address(address));
      keyreleasetoaddressPhone.value = policies.phone;
    }
  }
  //  else {
  //   setKeyreleasetoaddress(new Address()); 
  //   releasekeystobranchname.value = "";
  //   keyreleasetoaddressPhone.value = '';
  // }
  if (newValue === "Via Landlord") {
    if(booking.value.landlordmobilearray.length > 0){
      keyreleasetoaddressPhone.value = booking.value.landlordmobilearray[0];
    }
  }  
});

watch(customer, (newValue, oldValue) => {
  changeKeypickupbranchnameoptions(); // Assuming this is a defined function
  changeKeyreleasebranchnameoptions(); // Assuming this is a defined function
  // Key release required is true
  if (customer.value.policies.keyreleasereq && !keystobereleased.value) {
    keystobereleased.value = 'TBC';
  }
});
const isNewBooking = () => {
  return route.params.id == "new";
}

const isNewBookingOrTenantBlank = (tenant:Tenant) => {
  let flag = false;
  flag =  route.params.id === "new";
  if(flag) return flag;
  if(tenant.ttname.trim() === "" && tenant.ttmobile.trim() === "" && tenant.ttemail.trim() === ""){
    return true;
  }
}

const checkMeetTenant = (option: string) => {
  // The setting is only applicable if Inspection in absence is on for selected customer
  if (!customer.value.policies.inspectioninabs) return false;

  let result = false;
  if (option === 'Meet Tenant') {
    result = true;
  }
  return result;
}

const isInternationalNumber = (mobile) => {
  const regex1 = /^\s*0044\d*\s*$/; // Uk numbers
  if(regex1.test(mobile)) return false;
  const regex2 = /^\s*\+44\d*\s*$/; // Uk numbers
  if(regex2.test(mobile)) return false;

  const regex3 = /^\s*00\d*\s*$/; // International numbers with 00 prefix, followed by one or more digits
  const regex4 = /^\s*\+\d*\s*$/; // International numbers with 00 prefix, followed by one or more digits
  return (regex3.test(mobile) || regex4.test(mobile));
};


</script>


<style scoped lang="scss">
.yellow-outline {
  border: 4px solid yellow !important;
}

.text-cross {
  text-decoration: line-through
}

.red {
  color: rgb(213, 32, 32);
  padding-right: 5px;
}
.amber {
  color: rgb(235, 158, 70);
  padding-right: 5px;
}
.green {
  color: green;
  padding-right: 5px;
}
</style>