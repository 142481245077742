import _castArray from 'lodash/castArray';
import _get from 'lodash/get';

import { toCamelCase, omitDeep } from '@/utilities';

import {
  Address,
  Base,
} from '@/models';

export class PmProperty extends Base {
  public id: string;
  public address: Address;
  public noOfBedrooms: number;
  public furnished: string;
  public branchName: string;
  public propertyType: string;

  public constructor(data?: Partial<PmProperty>) {
    super(data);
    data = toCamelCase(data);
    this.id = _get(data, 'id', '');
    this.address = new Address(_get(data, "address"));
    this.noOfBedrooms = _get(data, 'noOfBedrooms', 0);
    this.furnished = _get(data, 'furnished', '');
    this.branchName = _get(data, 'branchName', '');
    this.propertyType = _get(data, 'propertyType', '');
  }

  public toObject() {
    return omitDeep({ ...this }, ['_uuid']);
  }
}