import _get from "lodash/get";
import _castArray from "lodash/castArray";

import { Base } from "@/models";
import { toCamelCase } from "@/utilities";

export class Tenant extends Base {
  public ttname: string;
  public ttemail: string;
  public ttmobile: string;
  public attending: string;

  public constructor(data?: Partial<Tenant>) {
    super(data);
    data = toCamelCase(data);

    this.ttname = _get(data, "ttname", "");
    this.ttemail = _get(data, "ttemail", "");
    this.ttmobile = _get(data, "ttmobile", "");
    this.ttmobile = this.ttmobile.replaceAll(" ", "");
    this.attending = _get(data, "attending", "unsure");
  }
}
