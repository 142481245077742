import _castArray from 'lodash/castArray';
import _get from 'lodash/get';

import { Base, Type } from '@/models';
import { toCamelCase } from '@/utilities';

export class Section extends Base {

  public name: string;
  public slug: string;
  public types: Type[];

  /**
   * Constructor initialises the variables from the class Section
   * @param data - the data to construct a type with all properties of <Section>
   */
  public constructor(data?: Partial<Section>) {
    super(data);
    data = toCamelCase(data);

    this.name = _get(data, 'name', '');
    this.slug = _get(data, 'slug', '');
    this.types = _castArray(_get(data, 'types', [])).map((x: Partial<Type>) => new Type(x));
  }

}
