<template>
  <!-- "New Room" Modal -->
  <div
    class="modal fade"
    id="report-room-modal"
    data-keyboard="true"
    tabindex="-1"
    role="dialog"
    aria-labelledby="report-room-modal-label"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="report-room-modal-label">Add Rooms</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <ul class="nav nav-tabs" id="new-room-tabs" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                id="new-room-tab-add"
                data-toggle="tab"
                href="#new-room-add"
                role="tab"
                aria-controls="new-room-add"
                aria-selected="true"
                @click="setTab('new')"
              >
                New Room
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="new-room-tab-copy"
                data-toggle="tab"
                href="#new-room-copy"
                role="tab"
                aria-controls="new-room-copy"
                aria-selected="false"
                @click="setTab('copy')"
              >
                Copy Rooms
              </a>
            </li>
          </ul>
          <div class="tab-content mt-4" id="new-room-tabs-content">
            <div
              class="tab-pane fade show active"
              id="new-room-add"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <fieldset>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Room Type</label>
                  <div class="col-sm-9">
                    <multiselect
                      v-model="room"
                      track-by="name"
                      :options="roomsByPriority"
                      @select="onSelectNewRoom"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Room Name</label>
                  <div class="col-sm-9">
                    <input
                      class="form-control"
                      v-model="name"
                      :disabled="room == null"
                    />
                  </div>
                </div>
              </fieldset>
            </div>
            <div
              class="tab-pane fade"
              id="new-room-copy"
              role="tabpanel"
              aria-labelledby="new-room-tab-copy"
            >
              <fieldset class="mb-0">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label"> Report ID </label>
                  <div class="col-sm-9">
                    <form @submit.prevent="getReportToCopy(reportRef)">
                      <div class="input-group">
                        <input
                          class="form-control"
                          v-model="reportRef"
                          placeholder="This Report"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn btn-outline-secondary"
                            type="submit"
                          >
                            <i class="fas fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <hr />

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Rooms</label>
                  <div v-if="roomsToCopy.length > 0" class="col-sm-9">
                    <div>
                      <label class="col-form-label d-block">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="set-all-rooms"
                          @change="setAllRooms"
                        />
                        Select All
                      </label>
                    </div>
                    <hr class="mt-2 mb-0" />
                    <label
                      v-for="(room, $index) in roomsToCopy"
                      :key="$index"
                      class="col-form-label d-block"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :id="`room-${$index}`"
                        :value="$index"
                        v-model="selectedRoomIndexes"
                        @change="forceUpdate"
                      />
                      {{ room.name }}
                    </label>
                  </div>
                  <div v-else class="col-sm-9">
                    <label class="col-form-label d-block text-muted">
                      Select a Report with Rooms to copy.
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            v-if="tab === 'new'"
            class="btn btn-primary"
            data-dismiss="modal"
            @click="add"
            :disabled="!room || !name"
          >
            Add Room
          </button>
          <button
            type="button"
            v-if="tab === 'copy'"
            class="btn btn-primary"
            data-dismiss="modal"
            @click="copy"
            :disabled="selectedRoomIndexes.length === 0"
          >
            Copy {{ selectedRoomIndexes.length }} Room{{
              selectedRoomIndexes.length === 1 ? "" : "s"
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, ref, onMounted, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import { Report, Room } from "@/models";

const instance = getCurrentInstance();

const store = useStore();
const toasted = useToast();
const actProperty: any = inject('actProperty');

const report = computed(() => store.getters['reports/current']);
const roomsToCopy = computed((): Room[] => store.getters['reports/roomsToCopy']);
const roomsByPriorityFromMainDictionary = computed(() => store.getters['dictionary/roomsByPriority']);
const roomsByPriorityFromCustomerDictionary = computed(() => store.getters['customerdictionary/roomsByPriority']);

const tab = ref("new");
const name = ref("");
const room = ref(new Room());
const reportRef = ref("");
const selectedRoomIndexes = ref<number[]>([]);

const getSourceReport = async (ref: string): Promise<any> => {
  return store.dispatch('reports/getSourceReport', ref);
};

const addRoom = async (payload: { room: Room; name: string }): Promise<any> => {
  return store.dispatch('reports/addRoom', payload);
};

const copyRooms = async (payload: { rooms: Room[]; targetIndex: number }): Promise<any> => {
  return store.dispatch('reports/copyRooms', payload);
};

const roomsByPriority = computed(() => {
  return actProperty.isFactoryreport(report.value.type) 
    ? roomsByPriorityFromCustomerDictionary.value 
    : roomsByPriorityFromMainDictionary.value;
});

onMounted(async () => {
  reset();
});

const reset = () => {
  name.value = "";
  room.value = new Room();
  selectedRoomIndexes.value = [];
};

const setTab = (val: string) => {
  tab.value = val;
};

const forceUpdate = () => {
  instance?.proxy?.$forceUpdate();
};

const onSelectNewRoom = (val: Room) => {
  //room.value = val;
  name.value = val.name;
};

const add = () => {
  addRoom({ room: room.value, name: name.value })
      .then(() => toasted.success(`Added`))
      .then(() => reset())
      .catch((err: any) => actProperty.displayError(err));
};

const setAllRooms = (event: any) => {
  if (event.target.checked) {
    selectedRoomIndexes.value = roomsToCopy.value.map((_, index) => index);
  } else {
    selectedRoomIndexes.value = [];
  }
  forceUpdate();
};

const getReportToCopy = (ref: string) => {
  getSourceReport(ref);
};

const copy = () => {
  const rooms = roomsToCopy.value.filter((val, index) =>
    selectedRoomIndexes.value.includes(index)
  );
  // TODO copy Rooms at a specific index?
  const targetIndex = 0;
  copyRooms({ rooms, targetIndex })
    .then(() => toasted.success(`Copied`))
    .then(() => reset())
    .catch((err: any) => actProperty.displayError(err));
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.col-form-label + .col-form-label {
  padding-top: 0;
}
.col-form-label:last-child {
  padding-bottom: 0;
}
.modal-body {
  height: 377px;
  overflow: scroll;
}
</style>
