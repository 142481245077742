import _ from "lodash";
import { API } from "aws-amplify";

import { ActionTree, ActionContext } from "vuex";
import { RootState } from "../types";
import { RemotecommandState } from "./types";

import { Remotecommand } from "@/models";

  /**
 * Actions
 *
 * Actions are similar to mutations, the differences being that:
 * - Instead of mutating the state, actions commit mutations.
 * - Actions can contain arbitrary asynchronous operations.
 * - Actions are triggered with the store.dispatch method e.g. `store.dispatch('getSettings')`
 */
export const actions: ActionTree<RemotecommandState, RootState> = {
  
  /**
   * Get one Customer
   *
   * @param {RemotecommandState} store
   * @param id
   * @returns Individual Customer
   */
  async getRemotecommand(store: ActionContext<RemotecommandState, any>, id) {
    
    store.commit('app/addRequest', 'getRemotecommand', {root: true});
    return API.get("RestAPI", `/remotecommands/${id}`, null)
      .then((response) => new Remotecommand(response))
      .then((command: Remotecommand) => {
        store.commit("setCurrentcommand", command);
        return command;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getRemotecommand", { root: true })
      );
  },

  /**
   * Add Remotecommand
   * 
   * @param {RemotecommandState} store 
   * @param command Remotecommand
   */
	async addRemotecommand(store: ActionContext<RemotecommandState, any>, command: Remotecommand): Promise<Remotecommand> {
		store.commit('app/addRequest', 'addRemotecommand', {root: true});
		return API.post('RestAPI', '/remotecommands', {body: command.toJSON()})
			.then((data) => new Remotecommand(data))
			.then((commandfromserver: Remotecommand) => {
				store.commit('setCurrentcommand', commandfromserver);
				return commandfromserver;
			})
			.finally(() => store.commit('app/removeRequest', 'addRemotecommand', {root: true}));
	},

    /**
   * Change command status
   * 
   * @param {SupportState} store 
   * @param status string
   */
	async changeCommandStatus(store: ActionContext<RemotecommandState, any>): Promise<void> {
		if(store.state.currentcommand) {
			store.commit('app/addRequest', 'changeCommandStatus', {root: true});
			return API.put('RestAPI', `/remotecommands/status/${store.state.currentcommand.id}/${store.state.currentcommand.status}`, null)
				.then((data) => new Remotecommand(data))
				.then((command: Remotecommand) => {
					store.commit('setCurrentcommand', command);
				})
				.finally(() => store.commit('app/removeRequest', 'changeCommandStatus', {root: true}));
		}
	},

    /**
   * Change command status
   * 
   * @param {SupportState} store 
   * @param status string
   */
	async deleteCommand(store: ActionContext<RemotecommandState, any>, id: string): Promise<void> {
		if(store.state.currentcommand) {
			store.commit('app/addRequest', 'changeCommandStatus', {root: true});
			return API.del('RestAPI', `/remotecommands/${store.state.currentcommand.id}`, null)
				.then((data) => new Remotecommand(data))
				.then((command: Remotecommand) => {
					store.commit('removeRemotecommand', command.id);
				})
				.finally(() => store.commit('app/removeRequest', 'changeCommandStatus', {root: true}));
		}
	},


}