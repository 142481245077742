import _get from 'lodash/get';

import { Base, Address } from '@/models';
import { toCamelCase } from '@/utilities';

export class Contact extends Base {

  public name: string;
  public address: Address;
  public phone: string;
  public mobile: string;
  public email: string;
  public confirmationcc: boolean;
  public feedbackcc: boolean;

  /**
   * Constructor initialises the variables from the class Contact
   * @param data - the data to construct a type with all properties of <Contact>
   */
  public constructor(data?: Partial<Contact>) {
    super(data);
    data = toCamelCase(data);

    this.name = _get(data, 'name', '');
    this.address = new Address(_get(data, 'address'));
    this.phone = _get(data, 'phone', '');
    this.mobile = _get(data, 'mobile', '');
    this.email = _get(data, 'email', '');
    this.confirmationcc = _get(data, 'confirmationcc', false);
    this.feedbackcc = _get(data, 'feedbackcc', false);
  }

}
