<template>
  <div class="editor" v-if="editor">
    <menu-bar class="editor__header" :editor="editor" />
    <editor-content class="editor__content" :editor="editor" />
    <div class="editor__footer"></div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, ref, watch, defineProps, defineEmits, computed } from 'vue';
import { EditorContent, Editor } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Highlight from '@tiptap/extension-highlight';
import Paragraph from '@tiptap/extension-paragraph';
import MenuBar from './MenuBar.vue';

// Props definition with type annotation
const props = defineProps<{
  content: string;
}>();

const emit = defineEmits(['update', 'blur',])

const editor = ref<Editor>(new Editor({
    content: props.content,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      // emit is now a global method within setup
      emit('update', html);
    },
    onBlur: ({ editor }) => {
      const html = editor.getHTML();
      emit('blur', html);
    },
    editorProps: {
      attributes: {
        class: 'acteditor',
      },
    },
    extensions: [
      Highlight,
      StarterKit,
      Paragraph.configure({
        HTMLAttributes: {
          class: 'text-justify',
        },
      }),
    ],
  }));

// Before unmount lifecycle hook
onBeforeUnmount(() => {
  editor.value.destroy();
});

// In Vue 3 setup, the component instance (`this`) is not available, so direct property access is used.
</script>


<style scoped lang="scss">
.editor {
  display: flex;
  flex-direction: column;
  max-height: 26rem;
  color: #0d0d0d;
  background-color: #fff;
  border: 3px solid #0d0d0d;
  border-radius: 0.75rem;
}

.editor__content {
  padding: 1.25rem 1rem;
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.editor__header {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  flex-wrap: wrap;
  padding: 0.25rem;
  border-bottom: 3px solid #0d0d0d;
}
.editor__footer {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 12px;
  font-weight: 600;
  color: #0d0d0d;
  white-space: nowrap;
  padding: 0.25rem 0.75rem;
}
</style>
<style lang="scss">
.acteditor {
  min-height: 300px !important;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: break-spaces;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0;
  position: relative;
  outline: 0px solid transparent;
}
blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(#0d0d0d, 0.1);
}
</style>
