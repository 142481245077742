<template>
  <div class="wrapper">
    <label class="btn_container">
      <input
        type="radio"
        class="no"
        name="radio"
        :value="value"
        :checked="value === 'no'"
        @click="emitSelect('no')"
      />
      <div class="checkmark"><i class="far fa-2x fa-times-circle"></i></div>
    </label>
    <label class="btn_container">
      <input
        type="radio"
        class='neutral'
        name="radio"
        :value="value"
        :checked="value === 'neutral' || value === 'unsure'"
        @click="emitSelect('neutral')"
      />
      <div class="checkmark"><i class="fas fa-2x fa-dot-circle"></i></div>
    </label>
    <label class="btn_container">
      <input
        type="radio"
        class="yes"
        name="radio"
        :value="value"
        :checked="value === 'yes'"
        @click="emitSelect('yes')"
      />
      <div class="checkmark"><i class="fas fa-2x fa-check-circle"></i></div>
    </label>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits } from 'vue';

// Define props with their specific types
const props = defineProps<{
  value: string;
}>();

const emit = defineEmits(['select']);

const emitSelect = (val: string) => {
  emit('select', val);
};
</script>


<style scoped lang="scss">
.wrapper {
  width: 87px;
  background: #bfcbd9;
  margin: 5px 5px 5px 0;
  border-radius: 29px;
}
.btn_container {
  display: inline-block;
  margin: 0px;
}

input[type="radio"] {
  display: none;
}

.btn_container .checkmark {
  width: 29px;
  height: 29px;
}

.checkmark {
  padding: 1px;
  margin: 0px;
}

i {
  color: white;
}

.no:checked + .checkmark {
  background-color: red;
  border-radius: 29px;
  border: 1px solid transparent;

  background: rgb(213, 32, 32);
  background: -moz-linear-gradient(
    top,
    rgb(213, 32, 32) 0%,
    rgb(195, 1, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgb(213, 32, 32)),
    color-stop(100%, rgb(195, 1, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgb(213, 32, 32) 0%,
    rgb(195, 1, 1) 100%
  );
  background: -o-linear-gradient(top, rgb(213, 32, 32) 0%, rgb(195, 1, 1) 100%);
  background: -ms-linear-gradient(
    top,
    rgb(213, 32, 32) 0%,
    rgb(195, 1, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgb(213, 32, 32) 0%,
    rgb(195, 1, 1) 100%
  );
  -moz-animation-duration: 0.4s;
  -moz-animation-name: slidein;
  -webkit-animation-duration: 0.4s;
  -webkit-animation-name: slidein;
  animation-duration: 0.4s;
  animation-name: slidein;
  -webkit-transform: translateZ(0);
}

.neutral:checked + .checkmark {
  background-color: rgb(235, 158, 70);
  border: 1px solid rgb(235, 158, 70);
  border-radius: 29px;
}
.yes:checked + .checkmark {
  background-color: green;
  border-radius: 29px;
  border: 1px solid transparent;

  -moz-animation-duration: 0.4s;
  -moz-animation-name: slideno;
  -webkit-animation-duration: 0.4s;
  -webkit-animation-name: slideno;
  animation-duration: 0.4s;
  animation-name: slideno;
}

.ion-checkmark-round,
.ion-close-round {
  margin-left: 10px;
  margin-top: 10px;
  color: #fff;
  line-height: 29px;
}

.ion-record {
  margin-left: 8px;

  color: #fff;
  line-height: 29px;
}

@keyframes slidein {
  from {
    transform: translate(50px, 0);
  }
  to {
    transform: translate(0px, 0px);
  }
}

@keyframes slideno {
  from {
    transform: translate(-50px, 0);
  }
  to {
    transform: translate(0px, 0px);
  }
}

@keyframes returnLeft {
  from {
    transform: translate(-50px, 0);
  }
  to {
    transform: translate(0px, 0);
  }
}

@keyframes returnRight {
  from {
    transform: translate(50px, 0);
  }
  to {
    transform: translate(0px, 0);
  }
}
</style>

