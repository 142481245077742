<template>
  <div>
    <multiselect v-model="monthOption" :options="options" track-by="value" label="label" @select="onSelect"
      placeholder="Month">
    </multiselect>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import * as Moment from "moment-timezone";
import { extendMoment } from "moment-range";
import { useRouter } from 'vue-router';
import { ReportsState } from '@/store/reports/types';
const momentRange = extendMoment(Moment);

const store = useStore();
const router = useRouter();

const moment = extendMoment(Moment);
const options = ref<{ value: string; label: string }[]>([]);

const reportsState = computed(() => store.state.reports as ReportsState);

/**
 * Set the options for the month selector, using moment-range.
 *
 * @link https://github.com/rotaready/moment-range
 */
const setOptions = () => {
  const startDate = new Date(2010, 0, 1); // January 1, 2010
  const endDate = moment().add(5, 'months').toDate(); // Adds 5 months to the current date
  const range = momentRange.range(startDate, endDate);
  options.value = Array.from(range.by("month"))
    .reverse()
    .map(date => ({
      value: date.format("YYYY-MM"),
      label: date.format("MMMM YYYY"),
    }));
};

const monthOption = computed(() => {
  return options.value.find(x => x.value === reportsState.value.month);
});

const resetCurrentPage = async () => {
  await store.dispatch('reports/resetCurrentPage');
};

const onSelect = async (selected: { value: string; label: string }) => {
  if (selected !== null) {
    router.push({
      path: "dereports",
      query: { month: selected.value },
    });
    await resetCurrentPage();
  }
};

onMounted(() => {
  setOptions();
});

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
