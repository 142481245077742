import { copyInto } from '@/utilities';
import _get from 'lodash/get';

export class SystemProperty {

  public propertykey: string;
  public propertytype: string;
  public prompt: string;
  public description: string;
  public reporttypes: string[];
  public value: string;

  /**
   * Create an object from a JSON representation
   * @param data
   * @return SystemProperty
   */
  public static parse(data: object) {
    const obj = new SystemProperty;
    copyInto(obj, data);
    obj.reporttypes = _get(data, 'reporttypes', []);
    return obj;
  }

  /**
   * Constructor
   */
  public constructor() {
    this.propertykey = '';
    this.propertytype = '';
    this.prompt = '';
    this.description = '';
    this.reporttypes = [];
    this.value = '';
  }

}
