import { copyInto } from '@/utilities';

export class DictionaryConditionMatrix {

  public lead: string;
  public set_1: string[];
  public set_2: string[];

  /**
   * Create an object from a JSON representation
   * @param data
   * @return Report
   */
  public static parse(data: object) {
    const obj = new DictionaryConditionMatrix;

    copyInto(obj, data);

    return obj;
  }

  /**
   * Constructor
   */
  public constructor() {
    this.lead = '';
    this.set_1 = ['','',''];
    this.set_2 = ['','',''];
  }

}
