/**
 * Getters
 *
 * Vuex allows us to define "getters" in the store.
 * - You can think of them as computed properties for stores.
 * - Like computed properties, a getter's result is cached based on its dependencies,
 *   and will only re-evaluate when some of its dependencies have changed.
 */
import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { EmailTemplatesState } from './types';
import { EmailTemplate, Email } from '@/models';

export const getters: GetterTree<EmailTemplatesState, RootState> = {

  /**
     * is the template list empty?
     * 
     * @param {EmailTemplatesState} state 
     * @returns Boolean if office is empty
     */
  isEmpty: (state: EmailTemplatesState): boolean => {
    return state.list.length === 0;
  },

  /**
   * The currently-queried list of emailtemplates, returned from GET {API_URL}/emailtemplates
   * 
   * @param {EmailTemplatesState} state 
   * @returns void
   */
  list: (state: EmailTemplatesState): EmailTemplate[] => state.list,

  /**
   * The current EmailTemplate, populated by GET {API_URL}/emailtemplate/{id}
   * 
   * @param {EmailTemplatesState} state 
   * @returns void
   */
  current: (state: EmailTemplatesState): EmailTemplate => state.current,

  /**
   * The client email
   * 
   * @param {EmailTemplatesState} state 
   * @returns void
   */
  clientemail: (state: EmailTemplatesState): Email => state.clientemail,

  /**
   * The feedback email
   * 
   * @param {EmailTemplatesState} state 
   * @returns void
   */
  feedbackemail: (state: EmailTemplatesState): Email => state.feedbackemail,

  /**
   * The landlord email
   * 
   * @param {EmailTemplatesState} state 
   * @returns void
   */
  landlordemail: (state: EmailTemplatesState): Email => state.landlordemail,

  /**
   * The tenant email
   * 
   * @param {EmailTemplatesState} state 
   * @returns void
   */
  tenantemail: (state: EmailTemplatesState): Email => state.tenantemail,

   /**
   * The invoice email
   * 
   * @param {EmailTemplatesState} state 
   * @returns void
   */
   invoiceemail: (state: EmailTemplatesState): Email => state.invoiceemail,

  /**
   * The dataentry email
   * 
   * @param {EmailTemplatesState} state 
   * @returns void
   */
  dataentryemail: (state: EmailTemplatesState): Email => state.dataentryemail,

  /**
   * The delete booking email
   * 
   * @param {EmailTemplatesState} state 
   * @returns void
   */
  deletebookingemail: (state: EmailTemplatesState): Email => state.deletebookingemail,

  /**
   * The updates which have been made since creating/loading/last-saving the current report
   * 
   * @param {EmailTemplatesState} state 
   * @returns void
   */
  updates: (state: EmailTemplatesState): string[] => state.updates,

  /**
   * The updates which have been made since creating/loading/last-saving the current report
   * 
   * @param {EmailTemplatesState} state 
   * @returns void
   */
  hasUnsavedChanges: (state: EmailTemplatesState): boolean => state.updates.length > 0,
};
