<template>
  <div
    ref="contentEditableRef"
    contenteditable="true"
    :placeholder="placeholder"
    :multiline="multiline"
    :integer="integer"
    @blur="onBlur"
    @keypress.enter="onEnter"
    @keyup.delete="onDelete"
    @keydown="onKeyPress"
  ></div>
</template>

<script lang="ts" setup>
import { ref, defineProps, watch, onMounted, defineEmits, PropType } from 'vue';

// Defining props using defineProps with type annotations
const props = defineProps({
  content: {type: [String, Number] as PropType<string | number>, default: ''},
  placeholder: {type: String as PropType<string>, default: ''},
  multiline: {type: Boolean as PropType<boolean>, default: false},
  integer: {type: Boolean as PropType<boolean>, default: false},
  decimal: {type: Boolean as PropType<boolean>, default: false},
});

const emit = defineEmits(['update', 'insert', 'delete']);

const contentEditableRef = ref(null);

onMounted(async () => {
  if (contentEditableRef.value) {
    contentEditableRef.value.innerText = props.content;
  }
});

watch(() => props.content, (newValue) => {
  if (contentEditableRef.value) {
    contentEditableRef.value.innerText = newValue;
  }
});

const onBlur = (event: Event) => {
  const target = event.target as HTMLElement;
  if (target.innerText.length > 0 && target.innerText != '\n') {
    emit("update", target.innerText);
  }
};

const onEnter = (event: KeyboardEvent) => {
  if (!props.multiline) {
    event.preventDefault();
    emit("insert", event);
  }
};

const onDelete = (event: KeyboardEvent) => {
  if (!props.multiline && contentEditableRef.value && (contentEditableRef.value.innerText.length === 0 || contentEditableRef.value.innerText === '\n')) {
    event.preventDefault();
    emit("delete", event);
  }
};

const onKeyPress = (event: KeyboardEvent) => {
  if (props.decimal) {
    if (isNaN(Number(event.key)) && event.key !== "." && event.key !== "Backspace") {
      event.preventDefault();
    }
  } else if (props.integer) {
    if (isNaN(Number(event.key)) && event.key !== "Backspace") {
      event.preventDefault();
    }
  }
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
div {
  border-bottom: dashed 1px lightGrey;
  min-height: 1.8rem;
  line-height: 1.5;
  padding: 0.25rem;
}
[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  color: lightGrey;
  font-style: italic;
  display: block; /* For Firefox */
}
</style>
