<template>
  <div>
    <span v-if="uploading"
      ><i class="fas fa-spinner fa-spin"></i> Uploading bookings</span
    >
    <label v-else>
      <span class="btn btn-light">
        <i class="fas fa-file-upload"></i>
      </span>
      <input
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        ref="file"
        v-on:change="onFileInputChange"
      />
    </label>
  </div>
</template>


<script setup lang="ts">
import _ from "lodash";
import _get from "lodash/get";
import _castArray from "lodash/castArray";
import sanitize from "sanitize-filename";
import moment from "moment-timezone";

import * as XLSX from "xlsx-js-style";
import { WorkSheet } from "xlsx-js-style";

import { ref, onMounted, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";

import {
  Dictionary,
  Address,
  Booking,
  Customer,
  Inspector,
  Schedule,
  Tenant,
  Landlord,
  Bookedby,
} from "@/models";
import defaultschedules from "@/store/inspectors/json/schedules.json";

const store = useStore();
const actProperty: any = inject('actProperty');

const file = ref<FileList | File | null>(null);
const uploading = ref(false);
const toasted = useToast();

const currentdate = computed((): string => store.state.diary.currentdate);
const dictionary = computed((): Dictionary => store.state.dictionary.current);
const jobtypemap = computed((): Map<string, string[]> => store.state.diary.jobtypemap);
const inspectorlist = computed((): Inspector[] => store.state.diary.inspectorlist);
const customerlist = computed(() => store.getters['diary/customerlist'] as Customer[]);

const getBookingsForInspectorWithDate = async (payload?: {
    date?: string;
    inspectorid?: string;
  }): Promise<any> => {
  await store.dispatch('diary/getBookingsForInspectorWithDate', payload);
};

const getBookings = (payload?: { date?: string }): Promise<Booking[]> => {
  return store.dispatch('diary/getBookings', payload);
};

const addBookings = (bookings: Booking[]): Promise<Booking[]> => {
  return store.dispatch('diary/addBookings', bookings);
};

/**
 * When file is uploaded using "+ Document" button.
 */
const onFileInputChange = () => {
  const files = _.get(file.value, "files", null);
  uploadFiles(files);
  file.value = null;
}

async function uploadFiles(files: FileList | null) {
  try {
    if (null === files || 0 === files.length) {
      return;
    }

    uploading.value = true;
    for (let i = 0; i < files.length; i++) {
      let file: File | null = files.item(i);

      if (file === null) {
        continue;
      }

      const { name, type } = file;
      if (
        type !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
        type !== "application/vnd.ms-excel"
      ) {
        actProperty.displayError(
          `${sanitize(name)} is not an Excel file.`
        );
        continue;
      }

      var reader = new FileReader();
      var totaladd = 0;
      reader.onload = (e: any) => {
        var data = e.target.result;
        var workbook = XLSX.read(data, {
          type: "binary",
        });

        let resultrows: any = [];

        workbook.SheetNames.forEach(async (sheetName: any) => {
          // Here is your object
          var rows = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
            raw: false,
            header: [
              "Job Type",
              "Date",
              "Property Inspector Email",
              "Address Line 1",
              "Address Line 2",
              "Address Line 3",
              "Postcode",
              "Tenant Name",
              "Tenant Mobile",
              "Tenant Email",
              "Agency",
              "Branch",
              "LL name",
              "LL email",
              "LL mobile",
              "Property Manager Name",
              "Property Manager Email",
            ],
          });

          let bookings: Booking[] = [];
          let inspectorbookings: Map<
            string,
            Map<string, Booking[]>
          > = new Map<string, Map<string, Booking[]>>();

          let headerrow: any = null;
          for (let i = 0; i < rows.length; i++) {
            let row: any = rows[i];
            const booking: Booking = new Booking();

            let friendlyname = row["Job Type"];
            if (friendlyname === "Job Type") {
              headerrow = row;
            }

            if (!headerrow) {
              continue;
            }

            let resultrow = Object.assign({}, row);
            resultrows.push(resultrow);

            if (row === headerrow) {
              resultrow["Result"] = "Result";
              resultrow["Error"] = "Error";
              continue;
            }

            let internalejobtype: string | undefined = "";
            if (!friendlyname) {
              addFailResult(resultrow, "Jobtype is required");
              continue;
            }

            let jobtype = actProperty.findReportType(
              friendlyname,
              dictionary.value
            );
            if (!jobtype) {
              addFailResult(resultrow, `Invalid job type ${jobtype}`);
              continue;
            }

            if (!jobtypemap.value.get(jobtype)) {
              addFailResult(
                resultrow,
                `Cannot find internal job type for ${jobtype}`
              );
              continue;
            } else {
              let internaljobtypearray: string[] | undefined =
                jobtypemap.value.get(jobtype);
              if (internaljobtypearray)
                internalejobtype = internaljobtypearray[0];
            }
            booking.jobtype = jobtype;
            booking.internaljobtype = internalejobtype;

            let datestring = row["Date"];
            let bookingdate: string = "";
            try {
              bookingdate = moment.utc(datestring).format("DD/MM/YYYY");
            } catch (error) {
              addFailResult(resultrow, `Invalid date ${datestring}`);
              continue;
            }
            if (!bookingdate) continue;

            let piemail = row["Property Inspector Email"];
            if (!piemail) {
              addFailResult(
                resultrow,
                `Property Inspector Email is required`
              );
              continue;
            }
            let piindex = inspectorlist.value.findIndex(
              (insp: Inspector) => insp.email === piemail
            );
            if (piindex < 0) {
              addFailResult(
                resultrow,
                `PI with email ${piemail} not found on the system`
              );
              continue;
            }

            let lightinspector = new Inspector();
            lightinspector.id = inspectorlist.value[piindex].id;
            booking.inspector = lightinspector;

            let address1 = row["Address Line 1"];
            if (!address1) {
              addFailResult(resultrow, `Address 1 is required`);
              continue;
            }
            let address2 = row["Address Line 2"];
            if (!address2) {
              addFailResult(resultrow, `Address 2 is required`);
              continue;
            }
            let town = row["Address Line 3"];
            if (!town) {
              addFailResult(resultrow, `Address 3 is required`);
              continue;
            }
            let postcode = row["Postcode"];
            if (!postcode) {
              addFailResult(resultrow, `Postcode is required`);
              continue;
            }

            const address: Address = new Address();
            address.line1 = address1;
            address.line2 = address2;
            address.town = town;
            address.postcode = postcode;
            booking.address = address;

            setRecommendedtime(booking, inspectorlist.value[piindex]);
            if (!booking.recommendedtime) {
              addFailResult(
                resultrow,
                `Unable to determine recommended time`
              );
              continue;
            }

            let tenant: Tenant = new Tenant();
            booking.tenants.push(tenant);
            let ttname = row["Tenant Name"];
            tenant.ttname = ttname;
            let ttmobile = row["Tenant Mobile"];
            let ttemail = row["Tenant Email"];
            if (!ttmobile && !ttemail) {
              addFailResult(
                resultrow,
                `Tenant mobile or email is required`
              );
              continue;
            }
            tenant.ttmobile = ttmobile;
            tenant.ttemail = ttemail;

            let agency = row["Agency"];
            let branch = row["Branch"];
            if (!agency) {
              addFailResult(resultrow, `Agency is required`);
              continue;
            }
            if (!branch) {
              addFailResult(resultrow, `Branch is required`);
              continue;
            }
            let filteredcustomerlist: Customer[] = customerlist.value.filter(
              (cust: Customer) =>
                cust.companyName === agency && cust.branchName === branch
            );
            if (!filteredcustomerlist || filteredcustomerlist.length === 0) {
              addFailResult(
                resultrow,
                `${agency}-${branch} not found on the system`
              );
              continue;
            }

            let lightcustomer = new Customer();
            lightcustomer.id = filteredcustomerlist[0].id;
            booking.customer = lightcustomer;

            let landlord: Landlord = new Landlord();
            let llname = row["LL Name"];
            landlord.llname = llname;
            let llmobile = row["LL Mobile"];
            landlord.llmobile = llmobile;
            let llemail = row["LL Email"];
            landlord.llemail = llemail;
            booking.landlords.push(landlord);

            let bookedby: Bookedby = new Bookedby();
            let pmname = row["Property Manager Name"];
            bookedby.bbname = pmname;
            let pmemail = row["Property Manager Email"];
            bookedby.bbemail = pmemail;
            booking.bookedby.push(bookedby);

            // Determine start and endtime
            if (!inspectorbookings.has(lightinspector.id)) {
              inspectorbookings.set(
                lightinspector.id,
                new Map<string, Booking[]>()
              );
            }

            let bookingmap = inspectorbookings.get(lightinspector.id);
            let querydate = moment(bookingdate, "DD/MM/YYYY").format(
              "DD-MM-YYYY"
            );
            if (!bookingmap?.has(querydate)) {
              bookingmap?.set(querydate, []);
            }

            let bookingarray = bookingmap?.get(querydate);
            if (bookingarray?.length === 0) {
              await getBookingsForInspectorWithDate({
                date: querydate,
                inspectorid: lightinspector.id,
              }).then((bookings: Booking[]) => {
                if (bookings && bookings.length) {
                  bookings.forEach((b: Booking) => bookingarray?.push(b));
                }
              });
            }
            if (bookingarray) {
              bookingmap?.set(querydate, bookingarray);
              determinebookingtime(booking, bookingarray, bookingdate);
            }

            if (!booking.startdate || !booking.enddate) {
              addFailResult(
                resultrow,
                `Unable to determine start and end time for the booking`
              );
              continue;
            }

            if (booking.startdate && booking.enddate) {
              booking.provisional = true;
              bookingarray?.push(booking);
              bookings.push(booking);
              totaladd++;
              addSuccessResult(resultrow);
            }

            if (bookings.length >= 500) {
              await addBookings(bookings);
              bookings = [];
            }
          }

          if (bookings.length) {
            await addBookings(bookings);
            bookings = [];
          }

          // Refresh today
          getBookings({ date: formatDate(currentdate.value) }).then(
            () => toasted.success(`Added ${totaladd} bookings`)
          );

          var wb = XLSX.utils.book_new();
          var ws: WorkSheet = XLSX.utils.json_to_sheet(resultrows, {
            skipHeader: true,
          });
          XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
          addResultStyle(ws, rows.length);
          XLSX.writeFile(wb, "results.xlsx");
        });
      };

      reader.onerror = function (ex) {
        console.log(ex);
      };

      reader.readAsBinaryString(file);
    }
    uploading.value = false;
  } catch (error) {
    uploading.value = false;
    actProperty.displayError(error);
  }
}

function addFailResult(row: any, error: string) {
  row["Result"] = "Fail";
  row["Error"] = error;
}
function addSuccessResult(row: any) {
  row["Result"] = "Success";
}
function addResultStyle(ws: WorkSheet, totalrows: number) {
  const successstyle = {
    font: {
      color: { rgb: "00EE00" },
    },
  };
  const failstyle = {
    font: {
      color: { rgb: "EE0000" },
    },
  };

  for (let row = 1; row <= totalrows; row++) {
    if (ws[`R${row}`]) {
      const result = ws[`R${row}`].v;
      if (result === "Success") {
        ws[`R${row}`].s = {
          ...ws[`R${row}`].s,
          ...successstyle,
        };
      } else if (result === "Fail") {
        ws[`R${row}`].s = {
          ...ws[`R${row}`].s,
          ...failstyle,
        };
      }
    }
  }
}

function formatDate(date: string) {
  return moment(date).format("DD-MM-YYYY");
}

function determinebookingtime(
  booking: Booking,
  bookingarray: Booking[],
  bookingdate: string
) {
  if (booking && booking.recommendedtime) {
    bookingarray?.sort((b1: Booking, b2: Booking) => {
      if (moment(b1.startdate).isAfter(moment(b2.startdate))) {
        return 1;
      } else if (moment(b1.startdate).isBefore(moment(b2.startdate))) {
        return -1;
      } else {
        return 0;
      }
    });

    let timecursor = `${bookingdate} 08:00 AM`; // Starting from 8am
    let endtime = `${bookingdate} 06:00 PM`; // Ending at 6pm

    if (bookingarray.length === 0) {
      let timecursorMoment = moment(timecursor, "DD/MM/YYYY hh:mm A");
      booking.startdate =
        timecursorMoment.format("YYYY-MM-DD[T]HH:mm") + ":00.000Z";
      booking.enddate = determineEndtime(booking);
    } else {
      bookingarray.forEach(
        (b: Booking, index: number, bookingarray: Booking[]) => {
          if (!booking.startdate || !booking.enddate) {
            let timecursorMoment = moment.utc(
              timecursor,
              "DD/MM/YYYY hh:mm A"
            );
            let bookingstarttimeMoment = moment(
              b.startdate,
              actProperty.bookingdateformat
            ).utc();
            let bookingendtimeMoment = moment(
              b.enddate,
              actProperty.bookingdateformat
            ).utc();

            if (timecursorMoment.isBefore(bookingstarttimeMoment)) {
              let duration = moment.duration(
                bookingstarttimeMoment.diff(timecursorMoment)
              );
              let durationinminutes = duration.asMinutes();
              if (
                booking.recommendedtime &&
                durationinminutes >= booking.recommendedtime
              ) {
                booking.startdate =
                  timecursorMoment.format("YYYY-MM-DD[T]HH:mm") + ":00.000Z";
                booking.enddate = determineEndtime(booking);
              } else {
                timecursor = bookingendtimeMoment
                  .add(5, "minutes")
                  .format("DD/MM/YYYY hh:mm A");
              }
            } else {
              timecursor = bookingendtimeMoment
                .add(5, "minutes")
                .format("DD/MM/YYYY hh:mm A");
            }
          }
        }
      );

      // If start and end times are still not set after traversing through all the existing bookings
      // Then check if there is some time left between last booking and end of the day (6pm) and try
      if (!booking.startdate || !booking.enddate) {
        let timecursorMoment = moment(timecursor, "DD/MM/YYYY hh:mm A");
        let endtimeMoment = moment(endtime, "DD/MM/YYYY hh:mm A").utc();
        if (timecursorMoment.isBefore(endtimeMoment)) {
          let duration = moment.duration(
            endtimeMoment.diff(timecursorMoment)
          );
          let durationinminutes = duration.asMinutes();
          if (
            booking.recommendedtime &&
            durationinminutes >= booking.recommendedtime
          ) {
            booking.startdate =
              timecursorMoment.format("YYYY-MM-DD[T]HH:mm") + ":00.000Z";
            booking.enddate = determineEndtime(booking);
          }
        }
      }
    }
  }
}

function determineEndtime(booking: Booking): string {
  let time = "";
  if (booking.startdate && booking.recommendedtime) {
    let starttimeAsMinutes = moment
      .duration(
        moment(booking.startdate, actProperty.bookingdateformat)
          .utc()
          .format("HH:mm")
      )
      .asMinutes();
    let endtimeAsMunutes = starttimeAsMinutes + booking.recommendedtime;
    time =
      moment(booking.startdate, actProperty.bookingdateformat)
        .utc()
        .startOf("day")
        .add(endtimeAsMunutes, "minutes")
        .format("YYYY-MM-DD[T]HH:mm") + ":00.000Z";
  }
  return time;
}

function setRecommendedtime(booking: Booking, inspector: Inspector) {
  if (inspector) {
    let inspectorschedules: Schedule[] = [];
    if (!inspector.schedules || inspector.schedules.length == 0) {
      inspectorschedules = _castArray(
        _get(defaultschedules, "schedules", [])
      ).map((r: any) => new Schedule(r));
    } else {
      inspectorschedules = inspector.schedules;
    }
    let filtereddefaultschedules = defaultschedules.schedules.filter(
      (schedule) => {
        let i = schedule.internaljobtypelist?.findIndex(
          (t) => t === booking.internaljobtype
        );
        return i >= 0 ? true : false;
      }
    );
    if (filtereddefaultschedules.length) {
      let searchreoprttype: string = "";
      if (filtereddefaultschedules.length == 1) {
        searchreoprttype = filtereddefaultschedules[0].reporttype;
      }

      if (searchreoprttype) {
        const schedule: Schedule | undefined = inspectorschedules.find(
          (s) => s.reporttype === searchreoprttype
        );
        if (schedule && schedule.timinggroups) {
          schedule.timinggroups.forEach((tg) => {
            if (tg.propertytypegroup) {
              const timing = tg.propertytypegroup.find(
                (ptg) => ptg.propertytype === "default"
              );
              if (timing) {
                booking.recommendedtime = timing.timing;
              }
            }
          });
        }
      }
    }
  }
}

onMounted(async () => {
  //await getCustomers();
});

</script>


<style scoped lang="scss">
@import "@/assets/sass/bootstrap/_variables.scss";
.file-upload {
  border: 2px dashed rgba(0, 0, 0, 0);
  border-radius: 0.2rem;
  box-sizing: border-box;
  width: 100%;
  &.drag {
    background-color: $info-semi-opaque;
    border-color: $info;
  }
}

label {
  margin-bottom: 0;
  input[type="file"] {
    display: none;
  }
}
</style>