import _ from "lodash";
import { API } from "aws-amplify";

import { ActionTree, ActionContext } from "vuex";
import { RootState } from "../types";
import { UsermanagementState } from "./types";

import { User, Customer } from "@/models";

/**
 * Actions
 *
 * Actions are similar to mutations, the differences being that:
 * - Instead of mutating the state, actions commit mutations.
 * - Actions can contain arbitrary asynchronous operations.
 * - Actions are triggered with the store.dispatch method e.g. `store.dispatch('getSettings')`
 */
export const actions: ActionTree<UsermanagementState, RootState> = {
  /**
   * Get user
   *
   * @param {UsermanagementState} store
   * @param email string
   * @returns Multiple Users
   */
  async getUser(
    store: ActionContext<UsermanagementState, any>,
    email: string
  ): Promise<User> {
    const options = {
      queryStringParameters: { email: email },
    };

    store.commit("app/addRequest", "getUser", { root: true });

    return API.get("RestAPI", "/userbyemail", options)
      .then((response) => new User(response))
      .finally(() =>
        store.commit("app/removeRequest", "getUser", { root: true })
      );
  },

  /**
   * Get user
   *
   * @param {UsermanagementState} store
   * @param filters
   * @returns Multiple Users
   */
  async getUserForCustomer(
    store: ActionContext<UsermanagementState, any>,
    customer: Customer
  ): Promise<User> {
    let user: User = undefined;
    if (store.state.users?.length) {
      const index = store.state.users.findIndex((u: User) => {
        let flag = false;
        if (u?.customers?.length) {
          const i = u.customers.findIndex(
            (c: Customer) => c.id === customer.id
          );
          if (i >= 0) flag = true;
        }
        return flag;
      });
      if (index >= 0) {
        user = store.state.users[index];
      }
    }
    return user;
  },

  /**
   * Get user
   *
   * @param {UsermanagementState} store
   * @param filters
   * @returns Multiple Users
   */
  async getUsers(
    store: ActionContext<UsermanagementState, any>,
    email: string
  ): Promise<User[]> {
    store.commit("app/addRequest", "getUsers", { root: true });

    return API.get("RestAPI", "/users", {})
      .then((response) => response.map((x: Partial<User>) => new User(x)))
      .then((users: User[]) => {
        store.commit("setUsers", users);
        return users;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getUsers", { root: true })
      );
  },

  /**
   * Add a User
   *
   * @param {UsermanagementState} store
   * @param User User
   * @returns User
   */
  async addUser(store: ActionContext<UsermanagementState, any>, user: User) {
    store.commit("app/addRequest", "addUser", { root: true });
    return API.post("RestAPI", `/user`, { body: user.toJSON() })
      .then((response) => new User(response))
      .finally(() =>
        store.commit("app/removeRequest", "addUser", { root: true })
      );
  },

  /**
   * Save user changes
   *
   * @param {UsermanagementState} store
   * @param user User
   */
  async saveUser(
    store: ActionContext<UsermanagementState, any>,
    user: User
  ): Promise<void> {
    store.commit("app/addRequest", "saveUser", { root: true });
    API.put("RestAPI", `/user/${user.id}`, {
      body: user.toJSON(),
    })
      .then(() => store.commit("resetUnsavedChanges"))
      .finally(() =>
        store.commit("app/removeRequest", "saveUser", { root: true })
      );
  },

  /**
   * Get Customer list with given starting character
   *
   * - Used for select tag options
   * @param {CustomersState} store
   * @returns Company Name options
   */
  async getCustomers(
    store: ActionContext<UsermanagementState, any>,
    starts_with: string
  ) {
    const options = {
      queryStringParameters: {
        fieldlist: "_id,company_name,branch_name",
        starts_with: starts_with,
      },
    };

    store.commit("app/addRequest", "getCustomers", { root: true });
    return API.get("RestAPI", `/customers`, options)
      .then((response) =>
        response.map((x: Partial<Customer>) => new Customer(x))
      )
      .then((customers: Customer[]) => {
        store.commit("setCustomers", customers);
        return customers;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getCustomers", { root: true })
      );
  },
};
