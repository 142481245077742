/**
 * Getters
 *
 * Vuex allows us to define "getters" in the store.
 * - You can think of them as computed properties for stores.
 * - Like computed properties, a getter's result is cached based on its dependencies,
 *   and will only re-evaluate when some of its dependencies have changed.
 */
import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { CustomersState } from './types';
import { Customer } from '@/models/customer.model';

export const getters: GetterTree<CustomersState, RootState> = {

    /**
     * is the customers list empty?
     * 
     * @param {CustomersState} state 
     * @returns Boolean if list is empty
     */
    isEmpty: (state: CustomersState): boolean => {
        return state.list.length === 0;
    },

    /**
     * The currently-queried list of customers, returned from GET {API_URL}/customers
     * 
     * @param {CustomersState} state 
     * @returns void
     */
    list: (state: CustomersState): Customer[] => state.list,

    /**
     * The current Customer, populated by GET {API_URL}/customer
     * 
     * @param {CustomersState} state 
     * @returns void
     */
    current: (state: CustomersState): Customer => state.current,

    /**
     * The updates which have been made since creating/loading/last-saving the current report
     * 
     * @param {CustomersState} state 
     * @returns void
     */
    updates: (state: CustomersState): string[] => state.updates,

    /**
     * The updates which have been made since creating/loading/last-saving the current report
     * 
     * @param {CustomersState} state 
     * @returns void
     */
    hasUnsavedChanges: (state: CustomersState): boolean => state.updates.length > 0,
};
