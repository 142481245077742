<template>
  <div>
    <header>
      <div class="row">
        <div class="col-sm-6">
          <h1>Rate card</h1>
        </div>
        <div class="col-sm-6 text-right">
          <button class="btn btn-lg btn-primary" @click="addNewRateCard()">
            <i class="fas fa-plus-square"></i>
            New Ratecard
          </button>
        </div>
      </div>
    </header>

    <div v-if="!inspector.ratecards.length && !hasBlockingRequests" class="alert alert-warning text-center">
      <h2 class="mt-1 mb-2">No ratecards found</h2>
      <p class="mb-1">for {{ inspector.name }}, please add</p>
    </div>

    <div v-for="(ratecard, $ratecardindex) in inspector.ratecards" :key="$ratecardindex" class="row">
      <table class="col-md-11 table table-bordered">
        <thead>
          <tr>
            <th class="align-middle">
              <multiselect-text v-model="ratecard.furnishedtype" :value="ratecard.furnishedtype"
                :options="furnishedOptions" class="noborder">
              </multiselect-text>
            </th>
            <th class="align-middle">Inventory (£)</th>
            <th class="align-middle">Check-In (£)</th>
            <th class="align-middle">Check-Out (£)</th>
            <th class="align-middle">
              Inventory Check-In or b2b CO&amp;CI (£)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(pirate, $pirateindex) in ratecard.rates" :key="$pirateindex">
            <td class="align-middle">{{ pirate.propertysize }}</td>
            <td class="align-middle">
              <content-editable :content="pirate.inventory" :decimal="true" @click.prevent @update="
                setInspectorDeep({
                  path: `ratecards[${$ratecardindex}].rates[${$pirateindex}].inventory`,
                  data: $event,
                })
                ">
              </content-editable>
              <div v-if="pirate.propertysize === '6 Bedroom +'">
                Extra per bedroom
              </div>
            </td>
            <td class="align-middle">
              <content-editable :content="pirate.ci" :decimal="true" @click.prevent @update="
                setInspectorDeep({
                  path: `ratecards[${$ratecardindex}].rates[${$pirateindex}].ci`,
                  data: $event,
                })
                ">
              </content-editable>
              <div v-if="pirate.propertysize === '6 Bedroom +'">
                Extra per bedroom
              </div>
            </td>
            <td class="align-middle">
              <content-editable :content="pirate.co" :decimal="true" @click.prevent @update="
                setInspectorDeep({
                  path: `ratecards[${$ratecardindex}].rates[${$pirateindex}].co`,
                  data: $event,
                })
                ">
              </content-editable>
              <div v-if="pirate.propertysize === '6 Bedroom +'">
                Extra per bedroom
              </div>
            </td>
            <td class="align-middle">
              <content-editable :content="pirate.combo" :decimal="true" @click.prevent @update="
                setInspectorDeep({
                  path: `ratecards[${$ratecardindex}].rates[${$pirateindex}].combo`,
                  data: $event,
                })
                ">
              </content-editable>
              <div v-if="pirate.propertysize === '6 Bedroom +'">
                Extra per bedroom
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="col-md-1">
        <button class="btn btn-outline-danger" @click="_delete(ratecard)" v-on:click.stop>
          <i class="fas fa-trash-alt"></i> Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref,inject, computed } from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import ContentEditable from '@/components/ContentEditable.vue';
import { Inspector, RateCard, PiRate } from '@/models';
const store = useStore();

// Define refs and reactive properties
const hasBlockingRequests = computed(() => store.getters['app/hasBlockingRequests']);
const inspector = computed(() =>  store.getters['inspectors/current']);

// Define actions
const addRateCard = async (ratecard: RateCard): Promise<RateCard> => {
  return await store.dispatch('inspectors/addRateCard', ratecard);
};

const removeRateCard = async (ratecard: RateCard): Promise<RateCard> => {
  return await store.dispatch('inspectors/removeRateCard', ratecard);
};

const setInspectorDeep = (payload: { path: string; data: any }): Promise<any> => {
  return store.dispatch('inspectors/setInspectorDeep', payload);
};
const actProperty: any = inject('actProperty');
const toasted = useToast();

const furnishedOptions = ref<string[]>([
  "Unfurnished",
  "Part furnished",
  "Fully furnished",
  "Fully furnished with linen",
]);

const propertySizeOptions = ref<string[]>([
  "Studio",
  "1 Bedroom",
  "2 Bedroom",
  "3 Bedroom",
  "4 Bedroom",
  "5 Bedroom",
  "6 Bedroom +",
]);
const addNewRateCard = () => {
  const filteredFurnishedList = furnishedOptions.value.filter((f) => {
    const index = store.state.inspectors.current.ratecards.findIndex(
      (r: RateCard) => r.furnishedtype === f
    );
    return index < 0;
  });

  if (filteredFurnishedList && filteredFurnishedList.length) {
    const newratecard = new RateCard({
      furnishedtype: filteredFurnishedList[0],
    });
    newratecard.rates = propertySizeOptions.value.map(
      (s) => new PiRate({ propertysize: s })
    );
    addRateCard(newratecard);
  } else {
    // Use your own logic to display an error message
    console.error("All furnished types have rate cards!");
  }
};

const _delete = (ratecard: RateCard) => {
  const confirm = actProperty.confirmPrompt(
    "Are you sure you want to delete this rate card?"
  );

  confirm
    .then(() => removeRateCard(ratecard))
    .then(() => toasted.success(`Deleted`))
    .catch((err: any) => actProperty.displayError(err));
};
</script>

<style scoped lang="scss">
.noborder ::v-deep .multiselect__tags {
  border: none !important;
}
</style>
