import _ from 'lodash';
import { API } from 'aws-amplify';

import { ActionTree, ActionContext } from 'vuex';
import { RootState } from '../types';
import { SysAdminState } from './types';

import { CognitoUser, CognitoGroup } from '@/models';

/**
 * Actions
 *
 * Actions are similar to mutations, the differences being that:
 * - Instead of mutating the state, actions commit mutations.
 * - Actions can contain arbitrary asynchronous operations.
 * - Actions are triggered with the store.dispatch method e.g. `store.dispatch('getSettings')`
 */
export const actions: ActionTree<SysAdminState, RootState> = {

  /**
   * Get multiple Customers
   * 
   * @param {SysAdminState} store 
   * @param filters 
   * @returns Multiple CognitoUsers
   */
  async getUsers(store: ActionContext<SysAdminState, any>, filters: any): Promise<CognitoUser[]> {
    const options = {
      queryStringParameters: _.pickBy(_.pick(filters, ['starts_with']))
    };

    store.commit('app/addRequest', 'getUsers', { root: true });

    return API.get('RestAPI', '/admin/users', options)
      .then((response) => response.map((x: Partial<CognitoUser>) => new CognitoUser(x)))
      .then((users: CognitoUser[]) => {
        store.commit('setUsers', users);
        return users;
      })
      .finally(() => store.commit('app/removeRequest', 'getUsers', { root: true }));
  },

  /**
   * Get multiple CognitoGroup
   * 
   * @param {SysAdminState} store 
   * @param filters 
   * @returns Multiple CognitoGroup
   */
  async getGroups(store: ActionContext<SysAdminState, any>): Promise<CognitoUser[]> {
    store.commit('app/addRequest', 'getGroups', { root: true });

    return API.get('RestAPI', '/admin/groups', null)
      .then((response) => response.Groups.map((x: Partial<CognitoGroup>) => new CognitoGroup(x)))
      .then((groups: CognitoUser[]) => {
        store.commit('setGroups', groups);
        return groups;
      })
      .finally(() => store.commit('app/removeRequest', 'getGroups', { root: true }));
  },

  /**
   * Get multiple CognitoGroup
   * 
   * @param {SysAdminState} store 
   * @param filters 
   * @returns Multiple CognitoGroup
   */
  async getUserGroups(store: ActionContext<SysAdminState, any>, username: any): Promise<CognitoGroup[]> {
    const options = {
      queryStringParameters: { "username": username }
    };

    store.commit('app/addRequest', 'getUserGroups', { root: true });

    return API.get('RestAPI', '/admin/usergroups', options)
      .then((response) => response.Groups.map((x: Partial<CognitoGroup>) => new CognitoGroup(x)))
      .then((groups: CognitoGroup[]) => {
        store.commit('setUserGroups', { username: username, usergroups: groups });
        return groups;
      })
      .finally(() => store.commit('app/removeRequest', 'getUserGroups', { root: true }));
  },

  /**
   * Assign CognitoGroup to CognitoUser
   * 
   * @param {SysAdminState} store 
   * @param payload {username, groupname} 
   */
  async assignUserGroup(store: ActionContext<SysAdminState, any>, payload: { username: any, groupname: any }): Promise<void> {
    const options = {
      queryStringParameters: { "username": payload.username, "groupname": payload.groupname, add_or_remove: "add" }
    };

    store.commit('app/addRequest', 'assignUserGroup', { root: true });

    return API.put('RestAPI', '/admin/usergroup', options)
      .then((response) => {
        store.commit('addUserGroup', payload);
        store.state.current.load(store.state.current);
      })
      .finally(() => store.commit('app/removeRequest', 'assignUserGroup', { root: true }));
  },

  /**
   * Unassign CognitoGroup to CognitoUser
   * 
   * @param {SysAdminState} store 
   * @param payload {username, groupname} 
   */
  async unassignUserGroup(store: ActionContext<SysAdminState, any>, payload: { username: any, groupname: any }): Promise<void> {
    const options = {
      queryStringParameters: { "username": payload.username, "groupname": payload.groupname, add_or_remove: "remove" }
    };

    store.commit('app/addRequest', 'unassignUserGroup', { root: true });

    return API.put('RestAPI', '/admin/usergroup', options)
      .then((response) => {
        store.commit('removeUserGroup', payload);
        store.state.current.load(store.state.current);
      })
      .finally(() => store.commit('app/removeRequest', 'unassignUserGroup', { root: true }));
  },

  /**
   * Get one User
   * 
   * @param {SysAdminState} store 
   * @param id 
   * @returns individual CognitoUser
   */
  async getUser(store: ActionContext<SysAdminState, any>, sub) {
    // New Customer
    if (sub === 'new') {
      const user = new CognitoUser();
      return user;
    }

    let user = store.state.list.find((user: CognitoUser) => user.findAttributeValue('sub') === sub);
    if (user) {
      store.commit('setCurrentUser', user);
      return user;
    }
  },

  /**
   * Change custom attribute
   * 
   * @param {SysAdminState} store 
   * @param payload { username, attributename, attributevalue }
   */
  async changeAttribute(store: ActionContext<SysAdminState, any>, payload: { username: string, attributename: string, attributevalue: string }): Promise<void> {
    const options = {
      queryStringParameters: {
        "username": payload.username,
        "attribute_name": payload.attributename,
        "attribute_value": payload.attributevalue
      }
    };

    store.commit('app/addRequest', 'changeAttribute', { root: true });

    API.put('RestAPI', '/admin/customattribute', options)
      .then(() => {
        //store.commit('setUserSttribute', payload);
        store.state.current.setAttributeValue(payload.attributename, payload.attributevalue);
        store.commit('resetUnsavedChanges');
      })
      .finally(() => store.commit('app/removeRequest', 'changeAttribute', { root: true }));
  },

  /**
   * Force reset password
   * 
   * @param {SysAdminState} store 
   * @param attributename 
   * @param attributevalue
   */
  async resetPassword(store: ActionContext<SysAdminState, any>, username: string): Promise<void> {
    const options = {
      queryStringParameters: {
        "username": username,
      }
    };

    store.commit('app/addRequest', 'resetPassword', { root: true });

    API.put('RestAPI', '/admin/resetuserpassword', options)
      .then(() => {
        //store.commit('setUserStatus', { username: username, status: 'RESET_REQUIRED' });
        store.state.current.UserStatus = 'RESET_REQUIRED';
        store.commit('resetUnsavedChanges');
      })
      .finally(() => store.commit('app/removeRequest', 'resetPassword', { root: true }));
  },

  /**
   * Disable user
   * 
   * @param {SysAdminState} store 
   * @param payload { username, disable} 
   */
  async disableUser(store: ActionContext<SysAdminState, any>, payload: { username: string, disable: boolean }): Promise<void> {
    const options = {
      queryStringParameters: {
        "username": payload.username,
        "disable": payload.disable,
      }
    };

    store.commit('app/addRequest', 'disableUser', { root: true });

    API.put('RestAPI', '/admin/disableuser', options)
      .then(() => {
        //store.commit('disableUser', { username: payload.username, disable: payload.disable });
        store.state.current.Enabled = !payload.disable;
        store.commit('resetUnsavedChanges');
      })
      .finally(() => store.commit('app/removeRequest', 'disableUser', { root: true }));
  },

  /**
   * Add CognitoUser
   * 
   * @param {SysAdminState} store 
   * @param payload {username, temporary_password} 
   */
  async addUser(store: ActionContext<SysAdminState, any>, payload: { username: any, temporary_password: any }): Promise<void> {
    const options = {
      queryStringParameters: { "username": payload.username, "temporary_password": payload.temporary_password }
    };

    store.commit('app/addRequest', 'assignUserGroup', { root: true });

    return API.post('RestAPI', '/admin/user', options)
      .then((data) => new CognitoUser(data))
      .then((userfromserver: CognitoUser) => {
        store.commit('addUser', userfromserver);
      })
      .finally(() => store.commit('app/removeRequest', 'assignUserGroup', { root: true }));
  },
}
