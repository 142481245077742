<template>
  <div class="time-cell">{{ celldisplay }}</div>
</template>
<script setup lang="ts">
import { computed, defineProps, PropType } from 'vue';
import moment from "moment-timezone";

const props = defineProps({
  cellData: { type: Object as PropType<any>, default: () => {} }
});

const celldisplay = computed(() => {
  const time = moment(props.cellData.text, "h:mm A");
  if (time.minutes() === 0) {
    return props.cellData.text;
  } else {
    return "";
  }
});

</script>
<style scoped lang="scss">
.time-cell {
  font-size: 12px;
}
</style>
