import snakeCaseKeys from 'snakecase-keys';
import { v4 as uuidv4 } from 'uuid';

import { omitDeep, sortObjByKeys } from '@/utilities';

export class Base {

  /**
   * Create a JSON representation of this object and it's descedents.
   *
   * @return {Object}
   */
  public toJSON() {
    // Convert object camelCase keys into snake_case
    const json = snakeCaseKeys(this.toObject());

    // key sort
    return sortObjByKeys(json);
  }

  /**
   * Convert class instance back into a plain old JSON object
   * and recursively remove all uuids we've add for Vue.
   *
   * @return {Object}
   */
  public toObject() {
    return omitDeep({ ...this }, ['_uuid']);
  }

  // Add a unique ID to help Vue render & track
  public _uuid: string;

  /**
   * Constructor
   */
  public constructor(data: any) {
    this._uuid = uuidv4();
  }

}
