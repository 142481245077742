import _castArray from 'lodash/castArray';
import _get from 'lodash/get';

import { toCamelCase, omitDeep } from '@/utilities';

import {
  Base,
  Auditlogvaluechange,
} from '@/models';

export class Auditlog extends Base {
  public datetime: string;
  public user: string;
  public valuechanges: Auditlogvaluechange[];
  public notes: string;

  public subtype: string = "";

  public constructor(data?: Partial<Auditlog>) {
    super(data);
    data = toCamelCase(data);

    this.datetime = _get(data, 'datetime', '');
    this.user = _get(data, 'user', '');
    this.valuechanges = _castArray(_get(data, 'valuechanges', [])).map((x: Partial<Auditlogvaluechange>) => new Auditlogvaluechange(x));
    this.notes = _get(data, 'notes', '');
  }

  public toObject() {
    return omitDeep({ ...this }, ['_uuid']);
  }
}