import _get from 'lodash/get';

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

export class OpeningHours extends Base {

  public from: string;
  public to: string;
  public closed: boolean;

  /**
   * Constructor initialises the variables 
   * @param data - the data to construct a type with all properties of <OpeningHours>
   */
  public constructor(data?: Partial<OpeningHours>) {
    super(data);
    data = toCamelCase(data);

    this.from = _get(data, 'from', '00:00');
    this.to = _get(data, 'to', '23:45');
    this.closed = _get(data, 'closed', false);
  }

  public static default() : OpeningHours[] {
    const def: OpeningHours[] = [];
    for(var i = 0; i < 7; i++) {
      const oh: OpeningHours = new OpeningHours;
      oh.from = '00:00';
      oh.to = '23:45';
      oh.closed = false;
      def.push(oh);
    }
    return def;
  }

}
