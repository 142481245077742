<template>
  <tr>
    <td>
      <select class="form-control form-control-sm" v-model.lazy="signatureType">
        <option value=""></option>
        <option value="tenant">Tenant</option>
        <option value="landlord">Landlord</option>
        <option value="pi">Property Inspector</option>
        <option value="other">Other</option>
      </select>
    </td>
    <td>
      <input
        type="text"
        class="form-control form-control-sm"
        v-model.lazy="signatureName"
      />
    </td>
    <td>
      <input
        type="email"
        class="form-control form-control-sm"
        v-model.lazy="signatureEmail"
      />
    </td>
    <td>
      <input
        type="date"
        class="form-control form-control-sm"
        v-model.lazy="signatureDate"
      />
      <div class="image-preview outline mt-2" v-if="signatureImage">
        <img
          :src="`data:image/jpeg;base64,${signatureImage}`"
          class="mt-2 image"
        />
        <div data-v-8a00acfe="" class="button-group" >
          <i class="fas fa-lg fas fa-copy" @click="copyURL()"></i>
        </div>
      </div>
    </td>
    <td class="text-right">
      <button
        class="btn btn-sm btn-outline-danger"
        @click="removeSignature(signature)"
        tabindex="-1"
      >
        <i class="fas fa-trash"></i>
      </button>
    </td>
  </tr>
</template>

<script lang="ts" setup>
import { ref, computed, inject, defineProps } from "vue";
import { useStore } from "vuex";
import { Signature } from "@/models";
import { useToast } from "vue-toastification";

const props = defineProps<{ signature: Signature }>();
const store = useStore();
const actProperty: any = inject("actProperty");
const toasted = useToast();

const removeSignature = async (signature: Signature) => {
  await store.dispatch("reports/removeSignature", signature);
};

const setSignatureProperty = async (property: string, val: string) => {
  await store.dispatch("reports/setSignatureProperty", {
    signature: props.signature,
    property,
    val,
  });
};

const signatureType = computed({
  get: () => props.signature.type,
  set: (val: string) => setSignatureProperty("type", val),
});

const signatureName = computed({
  get: () => props.signature.name,
  set: (val: string) => setSignatureProperty("name", val),
});

const signatureEmail = computed({
  get: () => props.signature.email,
  set: (val: string) => setSignatureProperty("email", val),
});

const signatureDate = computed({
  get: () => actProperty.dateToLocalTime(props.signature.date),
  set: (val: string) =>
    setSignatureProperty("date", actProperty.datetimeToUTC(val)),
});

// signatureImage doesn't need a setter as it's not expected to change from this component
const signatureImage = computed(() => props.signature.image);

const copyURL = async () => {
  await navigator.clipboard.writeText(signatureImage.value);
  toasted.success("Signature copied");
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.image {
  height: auto;
  max-width: 100%;
  padding: 1em 1em 0;
  width: 100%;
}
.button-group {
    right: .3rem;
    position: absolute;
    bottom: .25rem;
}
.image-preview {
    display: inline-block;
    vertical-align: top;
    position: relative;
    z-index: 0;
    cursor: pointer;
}
.outline {
    border: 1px solid #e2e2e2;
    border-radius: 2px;
}
</style>
