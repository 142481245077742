import Vue from "vue";
import _ from "lodash";

import { v4 as uuidv4 } from "uuid";

import { MutationTree } from "vuex";
import { DictionaryState } from "./types";
import {
  Dictionary,
  DictionaryConditionMatrix,
  DictionaryItem,
  DictionaryReportType,
  DictionaryRoom,
  DictionarySection,
  DictionaryType,
  SystemProperty,
} from "@/models";

/**
 * Mutations
 *
 * The only way to actually change state in a Vuex store is by committing a mutation.
 * - Vuex mutations are very similar to events: each mutation has a string type and a handler.
 * - The handler function is where we perform actual state modifications - it receives the state as the first argument:
 * - You cannot directly call a mutation handler.
 * - Think of it more like event registration: "When a mutation with type X is triggered, call this handler."
 * - To invoke a mutation handler, you need to call store.commit with its type e.g. `store.commit('setUser', user)`
 */
export const mutations: MutationTree<DictionaryState> = {
  /**
   * Set the dictionary
   *
   * @param state DictionaryState
   * @param report Report
   * @returns void
   */
  setDictionary(state: DictionaryState, data: Dictionary): void {
    state.current = data;
  },

  /**
   * Set the dictionary (deep properties)
   */
  setDictionaryDeep(
    state: DictionaryState,
    payload: { path: string; data: any }
  ): void {
    state.current = Object.assign(
      new Dictionary(),
      _.set(state.current, payload.path, payload.data)
    );
  },

  /**
   * Set Template Types For Room Category
   *
   * @param payload.flag 1|2|4|8|16
   * @param payload.selected boolean
   */
  setTemplateTypesForCategory(
    state: DictionaryState,
    payload: { flag: number; selected: boolean }
  ): void {
    state.current.rooms.map((room: DictionaryRoom, index: number) => {
      if (room.category !== state.activeRoomCategory) {
        return;
      }
      let msg;
      const slug = `${state.activeSection.slug}/${state.activeType.slug}`;
      let bitmask = _.get(state.current.rooms[index].types, slug, 0);
      if (payload.selected) {
        msg = `➕ ${slug} ${room.slug} ${bitmask} -> `;
        bitmask = bitmask | payload.flag;
      } else {
        msg = `➖ ${slug} ${room.slug} ${bitmask} -> `;
        bitmask = bitmask & ~payload.flag;
      }
      msg += bitmask;
      if (bitmask > 0) {
        state.current.rooms[index].types[slug] = bitmask;
        msg += " (SETTING KEY)";
      } else {
        delete state.current.rooms[index].types[slug];
        msg += " (REMOVING KEY)";
      }
      if (process.env.NODE_ENV === "development") {
        console.log(msg);
      }
    });
  },

  /**
   * Set Template Types For Rooms
   *
   * @param payload.flag 1|2|4|8|16
   * @param payload.selected boolean
   */
  setTemplateTypesForRoom(
    state: DictionaryState,
    payload: { flag: number; selected: boolean }
  ): void {
    const roomIndex = _.findIndex(
      state.current.rooms,
      (room) => room === state.activeRoom
    );
    if (roomIndex < 0) {
      return;
    }
    let msg;
    const slug = `${state.activeSection.slug}/${state.activeType.slug}`;
    let bitmask = _.get(state.current.rooms[roomIndex].types, slug, 0);
    if (payload.selected) {
      msg = `➕ ${slug} ${bitmask} -> `;
      bitmask = bitmask | payload.flag;
    } else {
      msg = `➖ ${slug} ${bitmask} -> `;
      bitmask = bitmask & ~payload.flag;
    }
    msg += bitmask;
    if (bitmask > 0) {
      state.current.rooms[roomIndex].types[slug] = bitmask;
      msg += " (SETTING KEY)";
    } else {
      delete state.current.rooms[roomIndex].types[slug];
      msg += " (REMOVING KEY)";
    }
    if (process.env.NODE_ENV === "development") {
      console.log(msg);
    }
  },

  /**
   * Set a direct Property of the currently selected Item
   */
  setItemProperty(
    state: DictionaryState,
    payload: { property: string; value: any }
  ): void {
    const sectionIndex = _.findIndex(
      state.current.sections,
      (val) => val.slug === state.activeSection.slug
    );
    const typeIndex = _.findIndex(
      state.current.sections[sectionIndex].types,
      (val) => val.slug === state.activeType.slug
    );
    const itemIndex = _.findIndex(
      state.current.sections[sectionIndex].types[typeIndex].items,
      (val) => val.slug === state.activeItem.slug
    );
    // console.log(`Setting ${payload.property} of s${sectionIndex} t${typeIndex} i${itemIndex}`);
    state.current.sections[sectionIndex].types[typeIndex].items[itemIndex][
      payload.property
    ] = Object.assign([], payload.value);
  },

  /**
   * Set a direct Property of the "Misc" Item
   */
  setMiscItemProperty(
    state: DictionaryState,
    payload: { property: string; value: any }
  ): void {
    const sectionIndex = _.findIndex(
      state.current.sections,
      (val) => val.name === "Misc"
    );
    const typeIndex = _.findIndex(
      state.current.sections[sectionIndex].types,
      (val) => val.name === "Misc"
    );
    const itemIndex = _.findIndex(
      state.current.sections[sectionIndex].types[typeIndex].items,
      (val) => val.name === "Misc"
    );
    state.current.sections[sectionIndex].types[typeIndex].items[itemIndex][
      payload.property
    ] = Object.assign([], payload.value);
  },

  /**
   * Set an Item Conditions roperty of the currently selected Item
   */
  setItemConditions(
    state: DictionaryState,
    payload: { property: string; value: any }
  ): void {
    // console.log('setItemConditions()', 'payload', payload);
    const sectionIndex = _.findIndex(
      state.current.sections,
      (val) => val.slug === state.activeSection.slug
    );
    const typeIndex = _.findIndex(
      state.current.sections[sectionIndex].types,
      (val) => val.slug === state.activeType.slug
    );
    const itemIndex = _.findIndex(
      state.current.sections[sectionIndex].types[typeIndex].items,
      (val) => val.slug === state.activeItem.slug
    );
    state.current.sections[sectionIndex].types[typeIndex].items[
      itemIndex
    ].conditions[payload.property] = Object.assign([], payload.value);
  },

  /**
   * Set an Item Conditions property of the "Misc" Item
   */
  setMiscItemConditions(state: DictionaryState, conditions: string[]): void {
    const sectionIndex = _.findIndex(
      state.current.sections,
      (val) => val.name === "Misc"
    );
    const typeIndex = _.findIndex(
      state.current.sections[sectionIndex].types,
      (val) => val.name === "Misc"
    );
    const itemIndex = _.findIndex(
      state.current.sections[sectionIndex].types[typeIndex].items,
      (val) => val.name === "Misc"
    );
    // Order before saving
    conditions = _.orderBy(
      Array.from(new Set(conditions)),
      (text) => text.toLowerCase(),
      ["asc"]
    );
    state.current.sections[sectionIndex].types[typeIndex].items[
      itemIndex
    ].conditions["additional"] = Object.assign([], _.uniq(conditions));
  },

  /**
   * Set Active Section
   */
  setActiveSection(state: DictionaryState, section: DictionarySection): void {
    state.activeSection = section;
  },

  /**
   * Set Active Type
   */
  setActiveType(state: DictionaryState, type: DictionaryType): void {
    state.activeType = type;
  },

  /**
   * Set Active Item
   */
  setActiveItem(state: DictionaryState, item: DictionaryItem): void {
    state.activeItem = item;
  },

  /**
   * Set Active Room
   */
  setActiveRoom(state: DictionaryState, room: DictionaryRoom): void {
    state.activeRoom = room;
  },

  /**
   * Set Active Room Category
   */
  setActiveRoomCategory(state: DictionaryState, category: number): void {
    state.activeRoomCategory = category;
  },

  /**
   * Set Active Report Type
   */
  setActiveReportType(
    state: DictionaryState,
    reporttype: DictionaryReportType
  ): void {
    state.activeReportType = reporttype;
  },

  /**
   * Set the reporttype (deep properties)
   */
  setReportTypeDeep(
    state: DictionaryState,
    payload: { path: string; data: any }
  ): void {
    state.activeReportType = Object.assign(
      new DictionaryReportType(),
      _.set(state.activeReportType, payload.path, payload.data)
    );
  },

  /**
   * Set ReportType list
   */
  setReportTypes(
    state: DictionaryState,
    reporttypes: DictionaryReportType[]
  ): void {
    state.current.reporttypes = reporttypes;
  },

  /**
   * Remove ReportType from list
   */
  removeReportType(
    state: DictionaryState,
    reporttype: DictionaryReportType
  ): void {
    state.current.reporttypes?.splice(
      state.current.reporttypes?.findIndex(
        (t: DictionaryReportType) => reporttype.slug === t.slug
      ),
      1
    );
  },

  /**
   * Set SystemProperty list
   */
  setSystemproperties(
    state: DictionaryState,
    systemproperties: SystemProperty[]
  ): void {
    state.current.systemproperties = systemproperties;
  },

  /**
   * Set the propertykey
   */
  setSystemPropertyKey(
    state: DictionaryState,
    systemproperty: SystemProperty
  ): void {
    if (state.currentsystemproperty) {
      state.currentsystemproperty.propertykey = systemproperty.propertykey;
      state.currentsystemproperty.prompt = systemproperty.prompt;
      state.currentsystemproperty.description = systemproperty.description;
      state.currentsystemproperty.propertytype = systemproperty.propertytype;
    }
  },

  /**
   * Set the reporttype (deep properties)
   */
  setSystemPropertyValue(
    state: DictionaryState,
    payload: { index: number; data: any }
  ): void {
    if (
      state.current.systemproperties &&
      payload.index < state.current.systemproperties?.length
    )
      state.current.systemproperties[payload.index].value = payload.data;
  },

  /**
   * Adds a new system property
   */
  addSystemProperty(
    state: DictionaryState,
    newsystemproperty: SystemProperty
  ): void {
    if (newsystemproperty) {
      state.current.systemproperties?.push(newsystemproperty);
      state.currentsystemproperty = newsystemproperty;
    }
  },

  /**
   * Removes an existing system property with given propertykey
   */
  removeSystemProperty(state: DictionaryState, index: number): void {
    if (
      state.current.systemproperties &&
      index < state.current.systemproperties?.length
    ) {
      state.current.systemproperties?.splice(index, 1);
    }
  },

  /**
   * Adds a reporttype to given system property
   */
  addSystemPropertyReporttype(
    state: DictionaryState,
    payload: { index: number; reporttype: string }
  ): void {
    if (
      state.current.systemproperties &&
      payload.index < state.current.systemproperties?.length
    ) {
      let reporttypes: string[] =
        state.current.systemproperties[payload.index].reporttypes;
      if (!reporttypes) reporttypes = [];
      let index = reporttypes.indexOf(payload.reporttype);
      if (index < 0)
        _.set(
          state.current.systemproperties[payload.index],
          `reporttypes`,
          reporttypes.concat(payload.reporttype)
        );
    }
  },

  /**
   * Remove reporttype from the given list
   *
   * @param state
   * @param flag
   */
  removeSystemPropertyReporttype(
    state: DictionaryState,
    payload: { index: number; reporttype: string }
  ): void {
    if (
      state.current.systemproperties &&
      payload.index < state.current.systemproperties?.length
    ) {
      let reporttypes: string[] =
        state.current.systemproperties[payload.index].reporttypes;
      if (reporttypes) {
        let index = reporttypes.findIndex((type) => type == payload.reporttype);
        if (index >= 0) {
          reporttypes.splice(index, 1);
          _.set(
            state.current.systemproperties[payload.index],
            `reporttypes`,
            reporttypes
          );
        }
      }
    }
  },

  /**
   * Set the reporttype (deep properties)
   */
  setSystemPropertyReportTypes(
    state: DictionaryState,
    payload: { index: number; data: any }
  ): void {
    if (
      state.current.systemproperties &&
      payload.index < state.current.systemproperties?.length
    ) {
      _.set(
        state.current.systemproperties[payload.index],
        `reporttypes`,
        payload.data
      );
    }
  },

  /**
   * Set Clipboard Colours
   */
  setClipboardColours(state: DictionaryState, data: string[]): void {
    state.clipboardColours = data;
  },

  /**
   * Set Clipboard Conditions
   */
  setClipboardConditions(state: DictionaryState, data: string[]): void {
    state.clipboardConditions = data;
  },

  /**
   * Set Clipboard Constituents
   */
  setClipboardConstituents(state: DictionaryState, data: string[]): void {
    state.clipboardConstituents = data;
  },

  /**
   * Set Clipboard Makes
   */
  setClipboardMakes(state: DictionaryState, data: string[]): void {
    state.clipboardMakes = data;
  },

  /**
   * Set Clipboard Condition Matrix
   */
  setClipboardConditionMatrix(
    state: DictionaryState,
    matrix: DictionaryConditionMatrix[]
  ): void {
    state.clipboardConditionMatrix = matrix;
  },

  /**
   * Add Dictionary Type
   */
  addType(state: DictionaryState, type: DictionaryType): void {
    const sectionIndex = _.findIndex(
      state.current.sections,
      (val: DictionarySection) => val.slug === state.activeSection.slug
    );
    // Prevent duplicate names/system names
    const duplicateTypeByName = _.find(
      state.current.sections[sectionIndex].types,
      (val) => val.getSystemName() === type.getSystemName()
    );
    if (duplicateTypeByName) {
      throw Error(
        `Unable to add Type – duplicate name "${duplicateTypeByName.getSystemName()}"`
      );
    }
    // Prevent duplicate slugs
    const duplicateTypeBySlug = _.find(
      state.current.sections[sectionIndex].types,
      (val) => val.slug === type.slug
    );
    if (duplicateTypeBySlug) {
      throw Error(
        `Unable to add Type – duplicate slug "${duplicateTypeBySlug.slug}"`
      );
    }
    state.current.sections[sectionIndex].types.push(type);
  },

  /**
   * Update Dictionary Type
   */
  updateType(state: DictionaryState, type: DictionaryType): void {
    const sectionIndex = _.findIndex(
      state.current.sections,
      (val: DictionarySection) => val.slug === state.activeSection.slug
    );
    const typeIndex = _.findIndex(
      state.current.sections[sectionIndex].types,
      (val: DictionaryType) => val.slug === type.slug
    );
    state.current.sections[sectionIndex].types.splice(typeIndex, 1, type);
  },

  /**
   * Remove Dictionary Type
   */
  removeType(state: DictionaryState, type: DictionaryType): void {
    const sectionIndex = _.findIndex(
      state.current.sections,
      (val: DictionarySection) => val.slug === state.activeSection.slug
    );
    state.current.sections[sectionIndex].types.splice(
      state.current.sections[sectionIndex].types.indexOf(type),
      1
    );
  },

  /**
   * Add Dictionary Item
   */
  addItem(state: DictionaryState, item: DictionaryItem): void {
    const sectionIndex = _.findIndex(
      state.current.sections,
      (val: DictionarySection) => val.slug === state.activeSection.slug
    );
    const typeIndex = _.findIndex(
      state.current.sections[sectionIndex].types,
      (val: DictionaryType) => val.slug === state.activeType.slug
    );
    // Prevent duplicate names
    const duplicateItemByName = _.find(
      state.current.sections[sectionIndex].types[typeIndex].items,
      (val) => val.name === item.name
    );
    if (duplicateItemByName) {
      throw Error(
        `Unable to add Item – duplicate name "${duplicateItemByName.name}"`
      );
    }
    // Prevent duplicate slugs
    const duplicateItemBySlug = _.find(
      state.current.sections[sectionIndex].types[typeIndex].items,
      (val) => val.slug === item.slug
    );
    if (duplicateItemBySlug) {
      throw Error(
        `Unable to add Item – duplicate slug "${duplicateItemBySlug.slug}"`
      );
    }
    state.current.sections[sectionIndex].types[typeIndex].items.push(item);
  },

  /**
   * Update Dictionary Item
   */
  updateItem(state: DictionaryState, item: DictionaryItem): void {
    const sectionIndex = _.findIndex(
      state.current.sections,
      (val: DictionarySection) => val.slug === state.activeSection.slug
    );
    const typeIndex = _.findIndex(
      state.current.sections[sectionIndex].types,
      (val: DictionaryType) => val.slug === state.activeType.slug
    );
    const itemIndex = _.findIndex(
      state.current.sections[sectionIndex].types[typeIndex].items,
      (val: DictionaryItem) => val.slug === item.slug
    );
    state.current.sections[sectionIndex].types[typeIndex].items.splice(
      itemIndex,
      1,
      item
    );
  },

  /**
   * Remove Dictionary Item
   */
  removeItem(state: DictionaryState, item: DictionaryItem): void {
    const sectionIndex = _.findIndex(
      state.current.sections,
      (val: DictionarySection) => val.slug === state.activeSection.slug
    );
    const typeIndex = _.findIndex(
      state.current.sections[sectionIndex].types,
      (val: DictionaryType) => val.slug === state.activeType.slug
    );
    state.current.sections[sectionIndex].types[typeIndex].items.splice(
      state.current.sections[sectionIndex].types[typeIndex].items.indexOf(item),
      1
    );
  },

  /**
   * ++ Unsaved changes
   */
  addUnsavedChange(state: DictionaryState, id: string): void {
    if (_.isEmpty(id)) {
      id = uuidv4();
    }
    state.updates = [...new Set(state.updates.concat([id]))];
  },

  /**
   * Reset Unsaved changes
   */
  resetUnsavedChanges(state: DictionaryState): void {
    state.updates = [];
    state.currentsystemproperty = undefined;
  },
};
