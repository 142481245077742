import _castArray from 'lodash/castArray';
import _get from 'lodash/get';
import { toCamelCase, copyInto } from '@/utilities';
import { Base, EmailTemplate, EmailAttachment, Document } from '@/models';

export class Email extends Base {

  public templateid: string;
  public bookingid: string;
  public fromaddress: string;
  public toaddresses: string[];
  public ccaddresses: string[];
  public bccaddresses: string[];
  public attachments: EmailAttachment[];
  public subject: string;
  public body: string;
  public target: string[];
  public chronorder: string[];
  public cctoagent: boolean;
  public cctolandlord: boolean;

  /**
   * Constructor
   */
  public constructor(data?: Partial<EmailTemplate>) {
    super(data);
    data = toCamelCase(data);

    this.templateid = _get(data, 'id', '');
    this.bookingid = _get(data, 'bookingid', '');
    this.subject = _get(data, 'subject', '');
    this.body = _get(data, 'body', '');
    this.fromaddress = _get(data, 'fromaddress', '');
    this.toaddresses = _castArray(_get(data, 'toaddresses', []));
    this.ccaddresses = _castArray(_get(data, 'ccaddresses', []));
    this.bccaddresses = _castArray(_get(data, 'bccaddresses', []));
    this.attachments = _castArray(_get(data, 'attachments', [])).map((x: any) => new EmailAttachment(x));
    this.target = _castArray(_get(data, 'target', []));
    this.chronorder = _castArray(_get(data, 'chronorder', []));
    this.cctoagent = _get(data, 'cctoagent', false);
    this.cctolandlord = _get(data, 'cctolandlord', false);
  }

  get documents(): Document[] {
    return this.attachments.map((a) => a.document);
  }
}
