<template>
  <div class="report-section comments">
    <fieldset>
      <legend>Tenant Comments</legend>

      <div class="form-group row">
        <div class="col-sm-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Name</label>
            <div class="col-sm-6">
              <input
                type="text"
                class="form-control"
                :value="statereport.tenantcommentsname"
                disabled
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Email</label>
            <div class="col-sm-6">
              <input
                type="text"
                class="form-control"
                :value="statereport.tenantcommentsemail"
                disabled
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Tenant</label>
            <div class="col-sm-9">
              <textarea
                class="form-control"
                rows="10"
                v-model.lazy="tenant"
              ></textarea>
              <Photos :photos="tenantPhotos" :path="'comments.tenantPhotos'" :key="`${tenantPhotos.length}`"/>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div
            v-if="commentedItemsByTenant.length"
            class="feedback-half-section jumbotron selected-item-jumbotron"
          >
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" style="width: 10%" class="align-middle">
                    Ref
                  </th>
                  <th scope="col" style="width: 20%" class="align-middle">
                    Room
                  </th>
                  <th scope="col" style="width: 25%" class="align-middle">
                    Description
                  </th>
                  <th scope="col" style="width: 25%" class="align-middle">
                    PI Comments
                  </th>
                  <th scope="col" style="width: 30%" class="align-middle">
                    Tenant Comments
                  </th>
                  <th scope="col" style="width: 5%" class="align-middle">
                    <button
                      @click="toggleAllCommentsApproved('Tenant')"
                      class="btn btn-sm btn-outline-info"
                      v-bind:class="{
                        approvedcomment: allttcommentsapproved,
                      }"
                    >
                      <i class="fas fa-check"></i>
                    </button>
                  </th>
                  <th scope="col" style="width: 5%" class="align-middle">
                    <button
                      @click="toggleAllCommentsFlagged('Tenant')"
                      class="btn btn-sm btn-outline-info text-danger"
                      v-bind:class="{
                        flaggedcomment: allttcommentsflagged,
                      }"
                    >
                      <i class="fas fa-flag"></i>
                    </button>
                  </th>
                </tr>
              </thead>

              <tbody>
                <template v-for="item in commentedItemsByTenant" :key="item.uuid">
                  <tr>
                    <th scope="row">{{ item.order }}</th>
                    <td>
                      {{ item.roomname }}
                    </td>
                    <td>{{ item.section }}:{{ item.type }}:{{ item.name }}</td>
                    <td>
                      <table class="table table-sm table-borderless">
                        <tr
                          v-for="note in getConditionInNotes(item)"
                          :key="note._uuid"
                        >
                          <td style="padding: 0px">{{ note.note }}</td>
                        </tr>
                      </table>
                    </td>
                    <td>
                      <content-editable
                        :content="item.tenantcomments.comments"
                        :multiline="true"
                        @update="
                          setReportDeep({
                            path: getItemPath(item, 'tenantcomments.comments'),
                            data: $event,
                          })
                        "
                      ></content-editable>
                    </td>
                    <td>
                      <button
                        @click="toggleCommentsApproved('Tenant', item)"
                        class="btn btn-sm btn-outline-info"
                        v-bind:class="{
                          approvedcomment: item.tenantcomments.approved,
                        }"
                      >
                        <i class="fas fa-check"></i>
                      </button>
                    </td>
                    <td>
                      <button
                        @click="toggleCommentsFlagged('Tenant', item)"
                        class="btn btn-sm btn-outline-info text-danger"
                        v-bind:class="{
                          flaggedcomment: item.tenantcomments.flagged,
                        }"
                      >
                        <i class="fas fa-flag"></i>
                      </button>
                    </td>
                  </tr>
                  <tr v-if="item.tenantcomments.photos.length" :key="item._uuid">
                    <td colspan="6">
                      <div class="form-group row">
                        <div class="col-sm-12">
                          <table class="table table-sm table-borderless">
                            <!-- Photos In -->
                            <Photos
                              :key="getCommentsPhotosLength('Tenant', item)"
                              :photos="getCommentsPhotos('Tenant', item)"
                              :path="getCommentsPhotoPath('Tenant', item)"
                            />
                          </table>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <legend>Landlord Comments</legend>
      <div class="form-group row">
        <div class="col-sm-6">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Name</label>
            <div class="col-sm-6">
              <input
                type="text"
                class="form-control"
                :value="statereport.landlordcommentsname"
                disabled
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Email</label>
            <div class="col-sm-6">
              <input
                type="text"
                class="form-control"
                :value="statereport.landlordcommentsemail"
                disabled
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Client/Landlord</label>
            <div class="col-sm-9">
              <textarea
                class="form-control"
                rows="10"
                v-model.lazy="client"
              ></textarea>
              <Photos :photos="clientPhotos" :path="'comments.clientPhotos'" :key="`${clientPhotos.length}`"/>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div
            v-if="commentedItemsByLandlord.length"
            class="feedback-half-section jumbotron selected-item-jumbotron"
          >
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" style="width: 10%" class="align-middle">
                    Ref
                  </th>
                  <th scope="col" style="width: 20%" class="align-middle">
                    Room
                  </th>
                  <th scope="col" style="width: 25%" class="align-middle">
                    Description
                  </th>
                  <th scope="col" style="width: 25%" class="align-middle">
                    PI Comments
                  </th>
                  <th scope="col" style="width: 30%" class="align-middle">
                    Landlord Comments
                  </th>
                  <th scope="col" style="width: 5%" class="align-middle">
                    <button
                      @click="toggleAllCommentsApproved('Landlord')"
                      class="btn btn-sm btn-outline-info"
                      v-bind:class="{
                        approvedcomment: allllcommentsapproved,
                      }"
                    >
                      <i class="fas fa-check"></i>
                    </button>
                  </th>
                  <th scope="col" style="width: 5%" class="align-middle">
                    <button
                      @click="toggleAllCommentsFlagged('Landlord')"
                      class="btn btn-sm btn-outline-info text-danger"
                      v-bind:class="{
                        flaggedcomment: allllcommentsflagged,
                      }"
                    >
                      <i class="fas fa-flag"></i>
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="item in commentedItemsByLandlord" :key="item.uuid">
                  <tr>
                    <th scope="row">{{ item.order }}</th>
                    <td>
                      {{ item.roomname }}
                    </td>
                    <td>{{ item.section }}:{{ item.type }}:{{ item.name }}</td>
                    <td>
                      <table class="table table-sm table-borderless">
                        <tr
                          v-for="note in getConditionInNotes(item)"
                          :key="note._uuid"
                        >
                          <td style="padding: 0px">{{ note.note }}</td>
                        </tr>
                      </table>
                    </td>
                    <td>
                      <content-editable
                        :content="item.landlordcomments.comments"
                        :multiline="true"
                        @update="
                          setReportDeep({
                            path: getItemPath(
                              item,
                              'landlordcomments.comments'
                            ),
                            data: $event,
                          })
                        "
                      ></content-editable>
                    </td>
                    <td>
                      <button
                        @click="toggleCommentsApproved('Landlord', item)"
                        class="btn btn-sm btn-outline-info"
                        v-bind:class="{
                          approvedcomment: item.landlordcomments.approved,
                        }"
                      >
                        <i class="fas fa-check"></i>
                      </button>
                    </td>
                    <td>
                      <button
                        @click="toggleCommentsFlagged('Landlord', item)"
                        class="btn btn-sm btn-outline-info text-danger"
                        v-bind:class="{
                          flaggedcomment: item.landlordcomments.flagged,
                        }"
                      >
                        <i class="fas fa-flag"></i>
                      </button>
                    </td>
                  </tr>
                  <tr
                    v-if="item.landlordcomments.photos.length"
                    :key="item._uuid"
                  >
                    <td colspan="6">
                      <div class="form-group row">
                        <div class="col-sm-12">
                          <table class="table table-sm table-borderless">
                            <!-- Photos In -->
                            <Photos
                              :key="getCommentsPhotosLength('Landlord', item)"
                              :photos="getCommentsPhotos('Landlord', item)"
                              :path="getCommentsPhotoPath('Landlord', item)"
                            />
                          </table>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- ACT don't use this field but let's keep any legacy PI comments just in case -->
      <input type="hidden" v-model="propertyInspector" />
    </fieldset>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, inject, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import _get from "lodash/get";
import moment from "moment";
import { Report, Item, OrderedItem, ShareholderComments, Note } from "@/models";

const instance = getCurrentInstance();

// Components
import ContentEditable from "@/components/ContentEditable.vue";
import Photos from "@/components/photo/Photos.vue";

const store = useStore();

const statereport = computed<Report>(() => store.getters['reports/current']);

const setReportDeep = (payload: { path: string; data: any }): Promise<void>  => {
  return store.dispatch('reports/setReportDeep', payload);
};

const actProperty: any = inject('actProperty');

// Computed properties and getters/setters
const tenant = computed({
  get: () => statereport.value.comments.tenant,
  set: (data) => {
    data = data.trim();
    setReportDeep({ path: "comments.tenant", data });
  }
});

const tenantPhotos = computed({
  get: () => statereport.value.comments.tenantPhotos,
  set: (data) => setReportDeep({ path: "comments.tenantPhotos", data })
});

const client = computed({
  get: () => statereport.value.comments.client,
  set: (data) => {
    data = data.trim();
    setReportDeep({ path: "comments.client", data });
  }
});

// clientPhotos computed property
const clientPhotos = computed({
  get: () => statereport.value.comments.clientPhotos,
  set: (data) => {
    setReportDeep({ path: "comments.clientPhotos", data });
  }
});

// propertyInspector computed property
const propertyInspector = computed({
  get: () => statereport.value.comments.propertyInspector,
  set: (data) => {
    setReportDeep({ path: "comments.propertyInspector", data });
  }
});

// commentedItemsByTenant computed property
const commentedItemsByTenant = computed(() => getCommentedItems("Tenant"));

// commentedItemsByLandlord computed property
const commentedItemsByLandlord = computed(() => getCommentedItems("Landlord"));

// allttcommentsapproved computed property
const allttcommentsapproved = computed(() => {
  let approved = true;
  commentedItemsByTenant.value.forEach((item) => {
    if (!item.tenantcomments.approved) {
      approved = false;
    }
  });
  return approved;
});

// allttcommentsflagged computed property
const allttcommentsflagged = computed(() => {
  let flagged = true;
  commentedItemsByTenant.value.forEach((item) => {
    if (!item.tenantcomments.flagged) {
      flagged = false;
    }
  });
  return flagged;
});

const getConditionInNotes = (item: Item) => {
  const notes = _get(item, "condition.in.notes", false);
  if (notes === false) {
    return [];
  }
  return notes;
}

// allllcommentsapproved computed property
const allllcommentsapproved = computed(() => {
  let approved = true;
  commentedItemsByLandlord.value.forEach((item) => {
    if (!item.landlordcomments.approved) {
      approved = false;
    }
  });
  return approved;
});

// allllcommentsflagged computed property
const allllcommentsflagged = computed(() => {
  let flagged = true;
  commentedItemsByLandlord.value.forEach((item) => {
    if (!item.landlordcomments.flagged) {
      flagged = false;
    }
  });
  return flagged;
});

const getCommentedItems = (shareholdertype: string): OrderedItem[] => {
  let items: OrderedItem[] = [];

  if (statereport.value && statereport.value.rooms) {
    statereport.value.rooms.forEach((room, roomindex) => {
      if (room.sections) {
        room.sections.forEach((section, sectionindex) => {
          if (section.types) {
            section.types.forEach((type, typeindex) => {
              if (type.items) {
                type.items.forEach((item, itemindex) => {
                  let orderedItem = new OrderedItem(item);
                  orderedItem.roomname = room.name;
                  orderedItem.roomindex = roomindex;
                  orderedItem.section = section.name;
                  orderedItem.sectionindex = sectionindex;
                  orderedItem.type = type.name;
                  orderedItem.typeindex = typeindex;
                  orderedItem.item = item;
                  orderedItem.itemindex = itemindex;
                  items.push(orderedItem);
                });
              }
            });
          }
        });
      }
    });
  }

  let commentedItems: OrderedItem[] = [];
  items.forEach((orderedItem, index) => {
    orderedItem.order = index + 1;
    let comments = null;
    if (shareholdertype == "Landlord")
      comments = orderedItem.landlordcomments;
    else if (shareholdertype == "Tenant")
      comments = orderedItem.tenantcomments;
    if (
      comments &&
      (comments.comments || (comments.photos && comments.photos.length))
    ) {
      commentedItems.push(orderedItem);
    }
  });

  return commentedItems;
}

/**
 * Returns Active Item Path
 */
const getCommentsPhotoPath = (shareholdertype: string, item: OrderedItem) => {
  let path = "";
  if (shareholdertype == "Landlord")
    path = `rooms[${item.roomindex}].sections[${item.sectionindex}].types[${item.typeindex}].items[${item.itemindex}].landlordcomments.photos`;
  else if (shareholdertype == "Tenant")
    path = `rooms[${item.roomindex}].sections[${item.sectionindex}].types[${item.typeindex}].items[${item.itemindex}].tenantcomments.photos`;
  return path;
}

/**
 * Return Check-In Photos from Items
 *
 * @param {Item} item
 */
const getCommentsPhotos = (shareholdertype: string, item: Item) => {
  let photos = [];
  if (shareholdertype == "Landlord")
    photos = _get(item, "landlordcomments.photos", []);
  else if (shareholdertype == "Tenant")
    photos = _get(item, "tenantcomments.photos", []);
  return photos;
}

const getCommentsPhotosLength = (shareholdertype: string, item: Item) => {
  let arr = getCommentsPhotos(shareholdertype, item);
  return arr.length;
};

const toggleAllCommentsApproved = (shareholdertype: string) => {
  let items: OrderedItem[] = [];
  let allapproved = false;
  if (shareholdertype == "Landlord") {
    items = commentedItemsByLandlord.value;
    allapproved = allllcommentsapproved.value;
  } else if (shareholdertype == "Tenant") {
    items = commentedItemsByTenant.value;
    allapproved = allttcommentsapproved.value;
  }

  if (items.length) {
    items.forEach((item) => {
      if (allapproved) unapproveComment(shareholdertype, item);
      else approveComment(shareholdertype, item);
    });
  }
}

const approveComment = (shareholdertype: string, item: OrderedItem) => {
  if (
    (shareholdertype == "Tenant" &&
      item.tenantcomments &&
      !item.tenantcomments.approved) ||
    (shareholdertype == "Landlord" &&
      item.landlordcomments &&
      !item.landlordcomments.approved)
  ) {
    toggleCommentsApproved(shareholdertype, item);
  }
}

const unapproveComment = (shareholdertype: string, item: OrderedItem) => {
  if (
    (shareholdertype == "Tenant" &&
      item.tenantcomments &&
      item.tenantcomments.approved) ||
    (shareholdertype == "Landlord" &&
      item.landlordcomments &&
      item.landlordcomments.approved)
  ) {
    toggleCommentsApproved(shareholdertype, item);
  }
}

const toggleCommentsApproved = (
  shareholdertype: string,
  item: OrderedItem
): void => {
  let approved = false;
  if (shareholdertype == "Tenant" && item.tenantcomments) {
    item.tenantcomments.approved = !item.tenantcomments.approved;
    approved = item.tenantcomments.approved;
    setReportDeep({
      path: getItemPath(item, `tenantcomments.approved`),
      data: item.tenantcomments.approved,
    }).then(() => instance?.proxy?.$forceUpdate());
  } else if (shareholdertype == "Landlord" && item.landlordcomments) {
    item.landlordcomments.approved = !item.landlordcomments.approved;
    approved = item.landlordcomments.approved;
    setReportDeep({
      path: getItemPath(item, `landlordcomments.approved`),
      data: item.landlordcomments.approved,
    }).then(() => instance?.proxy?.$forceUpdate());
  }
}

const toggleAllCommentsFlagged = (shareholdertype: string) => {
  let items: OrderedItem[] = [];
  let allflagged = false;
  if (shareholdertype == "Landlord") {
    items = commentedItemsByLandlord.value;
    allflagged = allllcommentsflagged.value;
  } else if (shareholdertype == "Tenant") {
    items = commentedItemsByTenant.value;
    allflagged = allttcommentsflagged.value;
  }

  if (items.length) {
    items.forEach((item) => {
      if (allflagged) unflagComment(shareholdertype, item);
      else flagComment(shareholdertype, item);
    });
  }
}

const flagComment = (shareholdertype: string, item: OrderedItem) => {
  if (
    (shareholdertype == "Tenant" &&
      item.tenantcomments &&
      !item.tenantcomments.flagged) ||
    (shareholdertype == "Landlord" &&
      item.landlordcomments &&
      !item.landlordcomments.flagged)
  ) {
    toggleCommentsFlagged(shareholdertype, item);
  }
}

const unflagComment = (shareholdertype: string, item: OrderedItem) => {
  if (
    (shareholdertype == "Tenant" &&
      item.tenantcomments &&
      item.tenantcomments.flagged) ||
    (shareholdertype == "Landlord" &&
      item.landlordcomments &&
      item.landlordcomments.flagged)
  ) {
    toggleCommentsFlagged(shareholdertype, item);
  }
}

const toggleCommentsFlagged = (
  shareholdertype: string,
  item: OrderedItem
): void => {
  let flagged = false;
  if (shareholdertype == "Tenant" && item.tenantcomments) {
    // Do not flag an item that has not yet been approved
    if (!item.tenantcomments.flagged && !item.tenantcomments.approved) {
      actProperty.displayError(
        "Feedback must be approved before it can be flagged"
      );
      return;
    }

    item.tenantcomments.flagged = !item.tenantcomments.flagged;
    flagged = item.tenantcomments.flagged;
    setReportDeep({
      path: getItemPath(item, `tenantcomments.flagged`),
      data: item.tenantcomments.flagged,
    }).then(() => instance?.proxy?.$forceUpdate());
    if (flagged) {
      insertNote(shareholdertype, item.tenantcomments, item);
    } else {
      removeNote(shareholdertype, item.tenantcomments, item);
    }
  } else if (shareholdertype == "Landlord" && item.landlordcomments) {
    // Do not flag an item that has not yet been approved
    if (!item.landlordcomments.flagged && !item.landlordcomments.approved) {
      actProperty.displayError(
        "Feedback must be approved before it can be flagged"
      );
      return;
    }

    item.landlordcomments.flagged = !item.landlordcomments.flagged;
    flagged = item.landlordcomments.flagged;
    setReportDeep({
      path: getItemPath(item, `landlordcomments.flagged`),
      data: item.landlordcomments.flagged,
    }).then(() => instance?.proxy?.$forceUpdate());
    if (flagged) {
      insertNote(shareholdertype, item.landlordcomments, item);
    } else {
      removeNote(shareholdertype, item.landlordcomments, item);
    }
  }
}

const insertNote = (
  shareholdertype: string,
  comment: ShareholderComments,
  item: OrderedItem
) => {
  const notes: Note[] = _get(item, `condition.in.notes`, []);
  let shprefix = "";
  if (shareholdertype == "Tenant") shprefix = "TT";
  else if (shareholdertype == "Landlord") shprefix = "LL";
  const commentdate: any = moment(comment.updatedAt).format("DD/MM/YYYY");
  const note = new Note();
  note.note = `${shprefix} advised on ${commentdate}: ${comment.comments}`;
  notes.push(note);
  setReportDeep({
    path: getItemPath(item, `condition.in.notes`),
    data: notes,
  }).then(() => instance?.proxy?.$forceUpdate());
}

const removeNote = (
  shareholdertype: string,
  comment: ShareholderComments,
  item: OrderedItem
) => {
  const notes: Note[] = _get(item, `condition.in.notes`, []);
  let shprefix = "";
  if (shareholdertype == "Tenant") shprefix = "TT";
  else if (shareholdertype == "Landlord") shprefix = "LL";
  const commentdate: any = moment(comment.updatedAt).format("DD/MM/YYYY");
  let notetodelete = `${shprefix} adviced on ${commentdate}: ${comment.comments}`;

  const found = notes.findIndex((x) => x.note == notetodelete);
  if (found >= 0) {
    notes.splice(found, 1);
  }

  setReportDeep({
    path: getItemPath(item, `condition.in.notes`),
    data: notes,
  }).then(() => instance?.proxy?.$forceUpdate());
}

/**
 * Returns Active Item Path
 */
const getItemPath = (item: OrderedItem, append: string) => {
  if (append) {
    append = `.${append}`;
  }
  return `rooms[${item.roomindex}].sections[${item.sectionindex}].types[${item.typeindex}].items[${item.itemindex}]${append}`;
}

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.selected-item-table {
  padding: 0px;
}
.feedback-half-section {
  padding-right: 20px;
  padding-left: 20px;
}
.selected-item-jumbotron {
  padding: 0rem 2rem;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 450px;
}
.btn-outline-info:hover {
  color: #17a2b8;
  background-color: rgb(243, 186, 112);
  border-color: #17a2b8;
}
</style>

<style lang="scss">
.approvedcomment {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.flaggedcomment {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
</style>
