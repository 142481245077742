<template>
  <div class="emailtemplate">
    <h1 class="modal-title" id="emailtemplate-modal-label">
      Email template: {{ emailtemplate.title }}
    </h1>

    <!-- Tabs -->
    <div class="report-items">
      <ul class="nav nav-tabs mb-3" role="tablist">
        <li class="nav-item">
          <router-link
            :to="{ name: 'email-template-details'}"
            class="nav-link"
            active-class="active"
          >
            Template
          </router-link>
        </li>
        <li class="nav-item" v-if="!isNewTemplate() && !emailtemplate.defaulttemplate">
          <router-link
            :to="{name: 'email-template-clients', params: {search: route.params.search || 'a'}}"
            class="nav-link"
            active-class="active"
          >
            Customers
          </router-link>
        </li>
      </ul>
    </div>

    <!-- Tab content (child routes) -->
    <router-view :key="$route.name" style="padding-bottom: 60px" />

    <!-- Status Bar (Bottom navbar) -->
    <nav class="navbar fixed-bottom navbar-dark bg-primary">
      <div class="container-fluid">
        <!-- Status Bar Actions -->
        <div id="status-bar-actions" style="margin-left: auto">
          <div class="text-light">
            <span v-if="hasUnsavedChanges">You have unsaved changes</span>
            <span v-else class="text-muted">No unsaved changes</span>
            <div class="btn-group" role="group" aria-label="Secondary Actions">
              <button
                class="btn btn-outline-light"
                :class="{ disabled: !hasUnsavedChanges }"
                @click.prevent="cancel()"
                :disabled="!hasUnsavedChanges"
              >
                <i class="fas fa-ban"></i> Cancel
              </button>
            </div>
            <div class="btn-group" role="group" aria-label="Primary Actions">
              <button
                class="btn btn-light"
                :class="{ disabled: !hasUnsavedChanges }"
                @click.prevent="save()"
                :disabled="isSaving || !hasUnsavedChanges"
              >
                <span v-if="isSaving" class="spinner-border spinner-border-sm">
                  <span class="sr-only">Loading...</span>
                </span>
                <i v-else class="fas fa-save"></i>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, onMounted, inject } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useToast } from "vue-toastification";
import { EmailTemplate, Dictionary } from "@/models";

const store = useStore();
const router = useRouter();
const route = useRoute();
const toasted = useToast();
const actProperty: any = inject('actProperty');

// State
const isSaving = ref(false);

// Computed Properties
const hasUnsavedChanges = computed<boolean>(() => store.getters['emailtemplates/hasUnsavedChanges']);
const emailtemplate = computed<EmailTemplate>(() => store.getters['emailtemplates/current']);

// Methods
const setEmailTemplate = (emailtemplate: EmailTemplate): void => {
 store.commit('emailtemplates/setEmailTemplate', emailtemplate);
};

const getEmailTemplate = (id: string): Promise<any> => {
  return store.dispatch('emailtemplates/getEmailTemplate', id);
};

const addEmailTemplate = (emailtemplate: EmailTemplate): Promise<any> => {
  return store.dispatch('emailtemplates/addEmailTemplate', emailtemplate);
};

const updateEmailTemplate = (emailtemplate: EmailTemplate): Promise<any> => {
  return store.dispatch('emailtemplates/updateEmailTemplate', emailtemplate);
};

const resetUnsavedChanges = (): void => {
  store.commit('emailtemplates/resetUnsavedChanges');
};

const canSave = () => {
  const et = emailtemplate.value;
  return et && et.subject && et.body;
};

const save = () => {
  if (canSave()) {
    if (isNewTemplate()) {
     addEmailTemplate(emailtemplate.value).then(et => {
        toasted.success(`Saved new template`);
        router.push({ name: "emailtemplate", params: { id: et.id }});
      });
    } else {
     updateEmailTemplate(emailtemplate.value).then(() => {
        toasted.success(`Saved`);
        router.push({ name: "emailtemplates" });
      });
    }
  }
};

const isNewTemplate = () => {
  return (route.params.id as string) === "new";
};

const cancel = () => {
  resetUnsavedChanges();
  router.back();
};

onMounted(async () => {
  resetUnsavedChanges();
  if (isNewTemplate()) {
    setEmailTemplate(new EmailTemplate());
    return;
  }
  try {
    const et = await getEmailTemplate(route.params.id as string);
    if (et === undefined) {
      actProperty.displayError(`Could not find email template with ID ${route.params.id as string}`);
      router.push({ name: "emailtemplates" });
    }
  } catch (err) {
    actProperty.displayError(err);
  }
});
</script>


<style lang="scss">
.placeholder {
  background-color: yellow;
}
.body-editor .ck-editor__editable_inline {
  min-height: 350px;
}
.subject-editor .ck-editor__editable_inline {
  max-height: 100px;
}
</style>

<style scoped lang="scss">
#status-bar-buttons,
#status-bar-switches,
#status-bar-actions {
  .btn-group,
  .custom-switch {
    display: inline-block;
    margin: 0 0.5rem;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.status-bar-separator {
  color: #f8f9fa;
}

#status-bar-switches {
  margin-left: 2rem;
}

#status-bar-actions {
  width: 22rem;
  text-align: right;
  span {
    margin-right: 0.25rem;
  }
}
</style>
