<template>
  <!-- "Template Select" Modal -->
  <div
    v-if="hasActiveType"
    class="modal fade"
    id="template-select-modal"
    data-keyboard="true"
    tabindex="-1"
    role="dialog"
    aria-labelledby="template-select-modal-title"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="template-select-modal-title">
            {{ modalTitle() }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-check" v-if="hasActiveRoom">
            <input
              class="form-check-input"
              type="checkbox"
              id="selectAll"
              v-model.lazy="selectAllVal"
            />
            <label class="form-check-label">Select All</label>
          </div>

          <hr v-if="hasActiveRoom" />

          <div v-if="hasActiveRoom" class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model.lazy="defaultVal"
            />
            <label class="form-check-label">Default</label>
          </div>
          <div v-else>
            <button class="btn btn-sm btn-link" @click="defaultVal = true">
              <i class="fas fa-plus"></i></button
            >&nbsp;
            <button class="btn btn-sm btn-link" @click="defaultVal = false">
              <i class="fas fa-minus"></i></button
            >&nbsp; Default
          </div>

          <div v-if="hasActiveRoom" class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model.lazy="unfurnishedVal"
            />
            <label class="form-check-label">Unfurnished</label>
          </div>
          <div v-else>
            <button class="btn btn-sm btn-link" @click="unfurnishedVal = true">
              <i class="fas fa-plus"></i></button
            >&nbsp;
            <button class="btn btn-sm btn-link" @click="unfurnishedVal = false">
              <i class="fas fa-minus"></i></button
            >&nbsp; Unfurnished
          </div>

          <div v-if="hasActiveRoom" class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model.lazy="partFurnishedVal"
            />
            <label class="form-check-label">Part furnished</label>
          </div>
          <div v-else>
            <button
              class="btn btn-sm btn-link"
              @click="partFurnishedVal = true"
            >
              <i class="fas fa-plus"></i></button
            >&nbsp;
            <button
              class="btn btn-sm btn-link"
              @click="partFurnishedVal = false"
            >
              <i class="fas fa-minus"></i></button
            >&nbsp; Part furnished
          </div>

          <div v-if="hasActiveRoom" class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model.lazy="fullyFurnishedVal"
            />
            <label class="form-check-label">Fully furnished</label>
          </div>
          <div v-else>
            <button
              class="btn btn-sm btn-link"
              @click="fullyFurnishedVal = true"
            >
              <i class="fas fa-plus"></i></button
            >&nbsp;
            <button
              class="btn btn-sm btn-link"
              @click="fullyFurnishedVal = false"
            >
              <i class="fas fa-minus"></i></button
            >&nbsp; Fully furnished
          </div>

          <div v-if="hasActiveRoom" class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model.lazy="fullyFurnishedWithLinenVal"
            />
            <label class="form-check-label">Fully furnished with linen</label>
          </div>
          <div v-else>
            <button
              class="btn btn-sm btn-link"
              @click="fullyFurnishedWithLinenVal = true"
            >
              <i class="fas fa-plus"></i></button
            >&nbsp;
            <button
              class="btn btn-sm btn-link"
              @click="fullyFurnishedWithLinenVal = false"
            >
              <i class="fas fa-minus"></i></button
            >&nbsp; Fully furnished with linen
          </div>

          <div>
            <button class="btn btn-sm btn-link" @click="catchAllVal = true">
              <i class="fas fa-plus"></i></button
            >&nbsp;
            <button class="btn btn-sm btn-link" @click="catchAllVal = false">
              <i class="fas fa-minus"></i></button
            >&nbsp; Catch all
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" data-dismiss="modal">
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, ref } from 'vue';
import _ from 'lodash';
import { useStore } from 'vuex';
import { DictionaryRoom, Report } from '@/models';
import { CustomerDictionaryState } from '@/store/customerdictionary/types';

// Store and State
const store = useStore();
const dictionaryState = computed(() => store.state.customerdictionary as CustomerDictionaryState);

// Getters
const hasActiveSection = computed(() => store.getters['customerdictionary/hasActiveSection']);
const hasActiveType = computed(() => store.getters['customerdictionary/hasActiveType']);
const hasActiveRoom = computed(() => store.getters['customerdictionary/hasActiveRoom']);
const hasActiveRoomCategory = computed(() => store.getters['customerdictionary/hasActiveRoomCategory']);
// Methods converted to regular functions
const setFurnitureBit = (flag: number, selected: boolean): void => {
  if (hasActiveRoom.value) {
    store.commit('customerdictionary/setTemplateTypesForRoom', { flag, selected });
  }
  if (hasActiveRoomCategory.value) {
    store.commit('customerdictionary/setTemplateTypesForCategory', { flag, selected });
    if (flag === 32) store.commit('customerdictionary/setTemplateTypesForCatchall', { flag, selected });
  }
};

const checkForFurnitureBit = (num: number): boolean => {
  if (!hasActiveSection.value || !hasActiveType.value) {
    return false;
  }
  const room = _.find(dictionaryState.value.current.rooms, (room: DictionaryRoom) => {
    return room === dictionaryState.value.activeRoom;
  });
  if (!room) {
    return false;
  }
  const slug = `${dictionaryState.value.activeSection.slug}/${dictionaryState.value.activeType.slug}`;
  const hasSlug = _.has(room.types, slug);
  const flagOn = room.types[slug] & num;
  return Boolean(hasSlug && flagOn);
};

const modalTitle = (): string => {
  let room = "ERROR";
  if (hasActiveRoomCategory.value) {
    room = `CAT ${dictionaryState.value.activeRoomCategory}`;
  } else if (dictionaryState.value.activeRoom) {
    room = dictionaryState.value.activeRoom.name;
  }
  return `${room} / ${dictionaryState.value.activeType.getSystemName()}`;
};

// Computed properties with getters and setters
const defaultVal = computed({
  get: () => checkForFurnitureBit(1),
  set: (val: boolean) => setFurnitureBit(1, val)
});

const unfurnishedVal = computed({
  get: () => checkForFurnitureBit(2),
  set: (val: boolean) => setFurnitureBit(2, val)
});

const partFurnishedVal = computed({
  get: () => checkForFurnitureBit(4),
  set: (val: boolean) => setFurnitureBit(4, val)
});

const fullyFurnishedVal = computed({
  get: () => checkForFurnitureBit(8),
  set: (val: boolean) => setFurnitureBit(8, val)
});

const fullyFurnishedWithLinenVal = computed({
  get: () => checkForFurnitureBit(16),
  set: (val: boolean) => setFurnitureBit(16, val)
});

const catchAllVal = computed({
  get: () => checkForFurnitureBit(32),
  set: (val: boolean) => setFurnitureBit(32, val)
});


const selectAllVal = computed({
  get: () => defaultVal.value && unfurnishedVal.value && partFurnishedVal.value && fullyFurnishedVal.value && fullyFurnishedWithLinenVal.value,
  set: (val: boolean) => {
    defaultVal.value = val;
    unfurnishedVal.value = val;
    partFurnishedVal.value = val;
    fullyFurnishedVal.value = val;
    fullyFurnishedWithLinenVal.value = val;
  }
});
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
tr {
  cursor: pointer;
}
</style>
