<template>
  <!-- "General Notes" Modal -->
  <div class="modal fade" id="report-notes-modal" data-backdrop="static" data-keyboard="true" tabindex="-1" role="dialog"
    aria-labelledby="report-notes-modal-title" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">

        <div class="modal-header">
          <h5 class="modal-title" id="report-notes-modal-title">
            Notes
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <!-- <textarea class="form-control" rows="20" v-model.lazy="generalNotes"></textarea> -->
          <fieldset>
            <div v-if="report.type === 'checkout'" class="form-group row">
              <label class="col-sm-3 col-form-label">Check In report date and cleaning status at the beginning of
                tenancy</label>
              <div class="col-sm-9">
                <textarea class="form-control" rows="5" v-model.lazy="historicalCleaningStatus"></textarea>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">General notes</label>
              <div class="col-sm-9">
                <textarea class="form-control" rows="20" v-model.lazy="generalNotes"></textarea>
              </div>
            </div>
          </fieldset>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary mr-auto" data-dismiss="modal">
            Cancel
          </button>
          <button type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
        </div>

      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const report = computed(() => store.getters['reports/current']);

const setReportDeep = (payload: { path: string; data: any }): Promise<any> => {
  return store.dispatch('reports/setReportDeep', payload);
};

const generalNotes = computed({
  get: () => report.value.propertyDetails.generalNotes,
  set: (data: string) => {
    setReportDeep({ path: 'propertyDetails.generalNotes', data });
  },
});

// Computed property for historical_cleaning_status
const historicalCleaningStatus = computed({
  get: () => report.value.propertyDetails.historicalCleaningStatus,
  set: (data) => setReportDeep({ path: 'propertyDetails.historicalCleaningStatus', data })
});

</script>
