<template>
  <div class="customer">
    <div class="user-header">
      <fieldset>
        <div class="form-group row">

          <label class="col-sm-3 col-form-label">Authentication token</label>
          <div class="col-sm-3">
              <input type="text" class="form-control"  v-model.lazy="authToken"/>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue';
import { useStore } from 'vuex';
const store = useStore();

const setCustomerDeep = async (payload: {
  path: string;
  data: any;
}) => {
  await store.dispatch('customers/setCustomerDeep', payload);
}

const customer = computed(() => store.getters['customers/current']);

const authToken = computed({
  get: () => { return customer.value.fixflo.authToken},
  set: (val: string) => { setCustomerDeep({path: 'fixflo.authToken', data: val})}
});

</script>

<style scoped lang="scss">
</style>