import { snakeCase } from 'lodash';
import _ from 'lodash';
import { copyInto } from '@/utilities';

export class DictionaryRoom {

  public id: string;
  public category: number;
  public name: string;
  public priority: number;
  public slug: string;
  public types: {[k: string]: number};

  /**
   * Create an object from a JSON representation
   * @param data
   * @return Report
   */
  public static parse(data: object) {
    const obj = new DictionaryRoom;

    copyInto(obj, data, false); // false param to bypass camelcase-keys

    if (!obj.slug) {
      obj.slug = snakeCase(obj.name);
    }

    return obj;
  }

  /**
   * Constructor
   */
  public constructor() {
    this.category = 1;
    this.name = '';
    this.priority = 1;
    this.slug = '';
    this.types = {};
  }

}
