<template>
  <div class="dictionary conditions">

    <section-type-selector :showSections="true" :showTypes="true" :showItems="true" />

    <div class="row" v-if="hasActiveItem">

      <div class="col-12 text-center mb-3">
        <button class="btn btn-sm btn-outline-primary ml-2 mr-2" @click="copyConditionMatrix()" title="Copy">
          <i class="fas fa-copy"></i> Copy all
        </button>
        <button class="btn btn-sm btn-outline-primary ml-2 mr-2" @click="pasteConditionMatrix()" :disabled="!dictionaryState.clipboardConditionMatrix.length" title="Paste">
          <i class="fas fa-paint-roller"></i> Paste all
        </button>
      </div>

      <div class="col-4">
        <div class="card">
          <div class="card-body">
            <fieldset>
              <legend class="sr-only">Conditions (A)</legend>

              <div class="form-group">
                <label class="h3">Condition A</label>
                <multiselect-text
                  class="mb-2"
                  id='A_lead'
                  v-model=A_lead
                  :options="miscItemConditions"
                  :allowEmpty="true"
                  :taggable="true"
                  @tag="addNewCondition">
                  <template #clear v-if="A_lead">
                    <i @mousedown.prevent.stop="A_lead = ''" class="multiselect__clear fa fa-times" aria-label="Clear"></i>
                  </template>
                  
                </multiselect-text>
                <small class="form-text text-muted">e.g. "New"</small>
              </div>

              <p class="lead text-center"><i class="fas fa-arrow-down"></i></p>

              <div class="form-group">
                <label>1st set</label>
                <multiselect-text
                  class="mb-2"
                  id='A_1_1'
                  v-model=A_1_1
                  :options="miscItemConditions"
                  :allowEmpty="true"
                  :taggable="true"
                  @tag="addNewCondition">
                  <template #clear v-if="A_1_1">
                    <i @mousedown.prevent.stop="A_1_1 = ''" class="multiselect__clear fa fa-times" aria-label="Clear"></i>
                  </template>
                </multiselect-text>
                <multiselect-text
                  class="mb-2"
                  id='A_1_2'
                  v-model=A_1_2
                  :options="miscItemConditions"
                  :allowEmpty="true"
                  :taggable="true"
                  @tag="addNewCondition">
                  <template #clear v-if="A_1_2">
                    <i @mousedown.prevent.stop="A_1_2 = ''" class="multiselect__clear fa fa-times" aria-label="Clear"></i>
                  </template>
                </multiselect-text>
                <multiselect-text
                  id='A_1_3'
                  v-model=A_1_3
                  :options="miscItemConditions"
                  :allowEmpty="true"
                  :taggable="true"
                  @tag="addNewCondition">
                  <template #clear v-if="A_1_3">
                    <i @mousedown.prevent.stop="A_1_3 = ''" class="multiselect__clear fa fa-times" aria-label="Clear"></i>
                  </template>
                </multiselect-text>
              </div>

              <p class="lead text-center"><i class="fas fa-arrow-down"></i></p>

              <div class="form-group">
                <label>2nd set</label>
                <multiselect-text
                  class="mb-2"
                  id='A_2_1'
                  v-model=A_2_1
                  :options="miscItemConditions"
                  :allowEmpty="true"
                  :taggable="true"
                  @tag="addNewCondition">
                  <template #clear v-if="A_2_1">
                    <i @mousedown.prevent.stop="A_2_1 = ''" class="multiselect__clear fa fa-times" aria-label="Clear"></i>
                  </template>
                </multiselect-text>
                <multiselect-text
                  class="mb-2"
                  id='A_2_2'
                  v-model=A_2_2
                  :options="miscItemConditions"
                  :allowEmpty="true"
                  :taggable="true"
                  @tag="addNewCondition">
                  <template #clear v-if="A_2_2">
                    <i @mousedown.prevent.stop="A_2_2 = ''" class="multiselect__clear fa fa-times" aria-label="Clear"></i>
                  </template>
                </multiselect-text>
                <multiselect-text
                  id='A_2_3'
                  v-model=A_2_3
                  :options="miscItemConditions"
                  :allowEmpty="true"
                  :taggable="true"
                  @tag="addNewCondition">
                  <template #clear v-if="A_2_3">
                    <i @mousedown.prevent.stop="A_2_3 = ''" class="multiselect__clear fa fa-times" aria-label="Clear"></i>
                  </template>
                </multiselect-text>
              </div>

            </fieldset>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="card">
          <div class="card-body">
            <fieldset>
              <legend class="sr-only">Conditions (B)</legend>

              <div class="form-group">
                <label class="h3">Condition B</label>
                <multiselect-text
                  class="mb-2"
                  id='B_lead'
                  v-model=B_lead
                  :options="miscItemConditions"
                  :allowEmpty="true"
                  :taggable="true"
                  @tag="addNewCondition">
                  <template #clear v-if="B_lead">
                    <i @mousedown.prevent.stop="B_lead = ''" class="multiselect__clear fa fa-times" aria-label="Clear"></i>
                  </template>
                </multiselect-text>
                <small class="form-text text-muted">e.g. "Good order"</small>
              </div>

              <p class="lead text-center"><i class="fas fa-arrow-down"></i></p>

              <div class="form-group">
                <label>1st set</label>
                <multiselect-text
                  class="mb-2"
                  id='B_1_1'
                  v-model=B_1_1
                  :options="miscItemConditions"
                  :allowEmpty="true"
                  :taggable="true"
                  @tag="addNewCondition">
                  <template #clear v-if="B_1_1">
                    <i @mousedown.prevent.stop="B_1_1 = ''" class="multiselect__clear fa fa-times" aria-label="Clear"></i>
                  </template>
                </multiselect-text>
                <multiselect-text
                  class="mb-2"
                  id='B_1_2'
                  v-model=B_1_2
                  :options="miscItemConditions"
                  :allowEmpty="true"
                  :taggable="true"
                  @tag="addNewCondition">
                  <template #clear v-if="B_1_2">
                    <i @mousedown.prevent.stop="B_1_2 = ''" class="multiselect__clear fa fa-times" aria-label="Clear"></i>
                  </template>
                </multiselect-text>
                <multiselect-text
                  id='B_1_3'
                  v-model=B_1_3
                  :options="miscItemConditions"
                  :allowEmpty="true"
                  :taggable="true"
                  @tag="addNewCondition">
                  <template #clear v-if="B_1_3">
                    <i @mousedown.prevent.stop="B_1_3 = ''" class="multiselect__clear fa fa-times" aria-label="Clear"></i>
                  </template>
                </multiselect-text>
              </div>

              <p class="lead text-center"><i class="fas fa-arrow-down"></i></p>

              <div class="form-group">
                <label>2nd set</label>
                <multiselect-text
                  class="mb-2"
                  id='B_2_1'
                  v-model=B_2_1
                  :options="miscItemConditions"
                  :allowEmpty="true"
                  :taggable="true"
                  @tag="addNewCondition">
                  <template #clear v-if="B_2_1">
                    <i @mousedown.prevent.stop="B_2_1 = ''" class="multiselect__clear fa fa-times" aria-label="Clear"></i>
                  </template>
                </multiselect-text>
                <multiselect-text
                  class="mb-2"
                  id='B_2_2'
                  v-model=B_2_2
                  :options="miscItemConditions"
                  :allowEmpty="true"
                  :taggable="true"
                  @tag="addNewCondition">
                  <template #clear v-if="B_2_2">
                    <i @mousedown.prevent.stop="B_2_2 = ''" class="multiselect__clear fa fa-times" aria-label="Clear"></i>
                  </template>
                </multiselect-text>
                <multiselect-text
                  id='B_2_3'
                  v-model=B_2_3
                  :options="miscItemConditions"
                  :allowEmpty="true"
                  :taggable="true"
                  @tag="addNewCondition">
                  <template #clear v-if="B_2_3">
                    <i @mousedown.prevent.stop="B_2_3 = ''" class="multiselect__clear fa fa-times" aria-label="Clear"></i>
                  </template>
                </multiselect-text>
              </div>

            </fieldset>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="card">
          <div class="card-body">
            <fieldset>
              <legend class="sr-only">Conditions (C)</legend>

              <div class="form-group">
                <label class="h3">Condition C</label>
                <multiselect-text
                  class="mb-2"
                  id='C_lead'
                  v-model=C_lead
                  :options="miscItemConditions"
                  :allowEmpty="true"
                  :taggable="true"
                  @tag="addNewCondition">
                  <template #clear v-if="C_lead">
                    <i @mousedown.prevent.stop="C_lead = ''" class="multiselect__clear fa fa-times" aria-label="Clear"></i>
                  </template>
                </multiselect-text>
                <small class="form-text text-muted">e.g. "Good used order"</small>
              </div>

              <p class="lead text-center"><i class="fas fa-arrow-down"></i></p>

              <div class="form-group">
                <label>1st set</label>
                <multiselect-text
                  class="mb-2"
                  id='C_1_1'
                  v-model=C_1_1
                  :options="miscItemConditions"
                  :allowEmpty="true"
                  :taggable="true"
                  @tag="addNewCondition">
                  <template #clear v-if="C_1_1">
                    <i @mousedown.prevent.stop="C_1_1 = ''" class="multiselect__clear fa fa-times" aria-label="Clear"></i>
                  </template>
                </multiselect-text>
                <multiselect-text
                  class="mb-2"
                  id='C_1_2'
                  v-model=C_1_2
                  :options="miscItemConditions"
                  :allowEmpty="true"
                  :taggable="true"
                  @tag="addNewCondition">
                  <template #clear v-if="C_1_2">
                    <i @mousedown.prevent.stop="C_1_2 = ''" class="multiselect__clear fa fa-times" aria-label="Clear"></i>
                  </template>
                </multiselect-text>
                <multiselect-text
                  id='C_1_3'
                  v-model=C_1_3
                  :options="miscItemConditions"
                  :allowEmpty="true"
                  :taggable="true"
                  @tag="addNewCondition">
                  <template #clear v-if="C_1_3">
                    <i @mousedown.prevent.stop="C_1_3 = ''" class="multiselect__clear fa fa-times" aria-label="Clear"></i>
                  </template>
                </multiselect-text>
              </div>

              <p class="lead text-center"><i class="fas fa-arrow-down"></i></p>

              <div class="form-group">
                <label>2nd set</label>
                <multiselect-text
                  class="mb-2"
                  id='C_2_1'
                  v-model=C_2_1
                  :options="miscItemConditions"
                  :allowEmpty="true"
                  :taggable="true"
                  @tag="addNewCondition">
                  <template #clear v-if="C_2_1">
                    <i @mousedown.prevent.stop="C_2_1 = ''" class="multiselect__clear fa fa-times" aria-label="Clear"></i>
                  </template>
                </multiselect-text>
                <multiselect-text
                  class="mb-2"
                  id='C_2_2'
                  v-model=C_2_2
                  :options="miscItemConditions"
                  :allowEmpty="true"
                  :taggable="true"
                  @tag="addNewCondition">
                  <template #clear v-if="C_2_2">
                    <i @mousedown.prevent.stop="C_2_2 = ''" class="multiselect__clear fa fa-times" aria-label="Clear"></i>
                  </template>
                </multiselect-text>
                <multiselect-text
                  id='C_2_3'
                  v-model=C_2_3
                  :options="miscItemConditions"
                  :allowEmpty="true"
                  :taggable="true"
                  @tag="addNewCondition">
                  <template #clear v-if="C_2_3">
                    <i @mousedown.prevent.stop="C_2_3 = ''" class="multiselect__clear fa fa-times" aria-label="Clear"></i>
                  </template>
                </multiselect-text>
              </div>

            </fieldset>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>


<script lang="ts" setup>
import _ from 'lodash';
import { DictionaryConditionMatrix } from '@/models';
import { ref , computed} from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import SectionTypeSelector from './partials/SectionTypeSelector.vue';

const store = useStore();
const toasted = useToast();

const dictionaryState = computed(() => store.state.dictionary);

const hasActiveItem = computed(() => store.getters['dictionary/hasActiveItem'] as boolean);
const miscItemConditions = computed(() => store.getters['dictionary/miscItemConditions'] as string[]);
const activeConditionMatrix = computed(() => store.getters['dictionary/activeConditionMatrix'] as DictionaryConditionMatrix[]);

const setClipboardConditionMatrix = (matrix: DictionaryConditionMatrix[]) => {
  store.commit('dictionary/setClipboardConditionMatrix', matrix);
}

const setConditionMatrixValue = (payload: {path: string, value: string }): Promise<any> => {
  return store.dispatch('dictionary/setConditionMatrixValue', payload);
}

const setConditionMatrix = (matrix: DictionaryConditionMatrix[]): Promise<any> => {
  return store.dispatch('dictionary/setConditionMatrix', matrix);
}

// Computed properties with getters and setters
const A_lead = computed({
  get: () => _.get(activeConditionMatrix.value, '[0]lead', ''),
  set: (value) => setConditionMatrixValue({ path: '[0].lead', value }),
});

const A_1_1 = computed({
  get: () => _.get(activeConditionMatrix.value, '[0].set_1[0]', ''),
  set: (value) => setConditionMatrixValue({ path: '[0].set_1[0]', value })
});

const A_1_2 = computed({
  get: () => _.get(activeConditionMatrix.value, '[0].set_1[1]', ''),
  set: (value) => setConditionMatrixValue({ path: '[0].set_1[1]', value })
});

const A_1_3 = computed({
  get: () => _.get(activeConditionMatrix.value, '[0].set_1[2]', ''),
  set: (value) => setConditionMatrixValue({ path: '[0].set_1[2]', value })
});

const A_2_1 = computed({
  get: () => _.get(activeConditionMatrix.value, '[0].set_2[0]', ''),
  set: (value) => setConditionMatrixValue({ path: '[0].set_2[0]', value })
});

const A_2_2 = computed({
  get: () => _.get(activeConditionMatrix.value, '[0].set_2[1]', ''),
  set: (value) => setConditionMatrixValue({ path: '[0].set_2[1]', value })
});

const A_2_3 = computed({
  get: () => _.get(activeConditionMatrix.value, '[0].set_2[2]', ''),
  set: (value) => setConditionMatrixValue({ path: '[0].set_2[2]', value })
});

const B_lead = computed({
  get: () => _.get(activeConditionMatrix.value, '[1].lead', ''),
  set: (value) => setConditionMatrixValue({ path: '[1].lead', value })
});

const B_1_1 = computed({
  get: () => _.get(activeConditionMatrix.value, '[1].set_1[0]', ''),
  set: (value) => setConditionMatrixValue({ path: '[1].set_1[0]', value })
});

const B_1_2 = computed({
  get: () => _.get(activeConditionMatrix.value, '[1].set_1[1]', ''),
  set: (value) => setConditionMatrixValue({ path: '[1].set_1[1]', value })
});

const B_1_3 = computed({
  get: () => _.get(activeConditionMatrix.value, '[1].set_1[2]', ''),
  set: (value) => setConditionMatrixValue({ path: '[1].set_1[2]', value })
});

const B_2_1 = computed({
  get: () => _.get(activeConditionMatrix.value, '[1].set_2[0]', ''),
  set: (value) => setConditionMatrixValue({ path: '[1].set_2[0]', value })
});

const B_2_2 = computed({
  get: () => _.get(activeConditionMatrix.value, '[1].set_2[1]', ''),
  set: (value) => setConditionMatrixValue({ path: '[1].set_2[1]', value })
});

const B_2_3 = computed({
  get: () => _.get(activeConditionMatrix.value, '[1].set_2[2]', ''),
  set: (value) => setConditionMatrixValue({ path: '[1].set_2[2]', value })
});

const C_lead = computed({
  get: () => _.get(activeConditionMatrix.value, '[2].lead', ''),
  set: (value) => setConditionMatrixValue({ path: '[2].lead', value })
});

const C_1_1 = computed({
  get: () => _.get(activeConditionMatrix.value, '[2].set_1[0]', ''),
  set: (value) => setConditionMatrixValue({ path: '[2].set_1[0]', value })
});

const C_1_2 = computed({
  get: () => _.get(activeConditionMatrix.value, '[2].set_1[1]', ''),
  set: (value) => setConditionMatrixValue({ path: '[2].set_1[1]', value })
});

const C_1_3 = computed({
  get: () => _.get(activeConditionMatrix.value, '[2].set_1[2]', ''),
  set: (value) => setConditionMatrixValue({ path: '[2].set_1[2]', value })
});

const C_2_1 = computed({
  get: () => _.get(activeConditionMatrix.value, '[2].set_2[0]', ''),
  set: (value) => setConditionMatrixValue({ path: '[2].set_2[0]', value })
});

const C_2_2 = computed({
  get: () => _.get(activeConditionMatrix.value, '[2].set_2[1]', ''),
  set: (value) => setConditionMatrixValue({ path: '[2].set_2[1]', value })
});

const C_2_3 = computed({
  get: () => _.get(activeConditionMatrix.value, '[2].set_2[2]', ''),
  set: (value) => setConditionMatrixValue({ path: '[2].set_2[2]', value })
});

const addNewCondition = (val: string, id: string) => {
  if (!val) {
    val = '';
  }
  const refVal = ref(val);
  refVal.value = val;
};

const copyConditionMatrix = () => {
  const matrix = JSON.parse(
    JSON.stringify(dictionaryState.value.activeItem.conditions.matrix)
  );
  setClipboardConditionMatrix(matrix);
  toasted.success("Copied Condition Matrix");
};

const pasteConditionMatrix = () => {
  const matrix = dictionaryState.value.clipboardConditionMatrix.map((item: DictionaryConditionMatrix) =>
    DictionaryConditionMatrix.parse(item)
  );
  setConditionMatrix(matrix);
  toasted.success("Pasted Condition Matrix");
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
