import _castArray from 'lodash/castArray';
import _get from 'lodash/get';
import { toCamelCase, copyInto } from '@/utilities';
import { Base, Customer } from '@/models';

export class EmailTemplate extends Base {

  public id: string;
  public title: string;
  public reporttypes: string[];
  public emailaddress: string;
  public subject: string;
  public body: string;
  public target: string[];
  public chronorder: string[];
  public daysbefore: number;
  public clients: string[];
  public managedtype: string;
  public defaulttemplate: boolean;
  public cctoagent: boolean;
  public cctolandlord: boolean;

  /**
   * Constructor
   */
  public constructor(data?: Partial<EmailTemplate>) {
    super(data);
    data = toCamelCase(data);

    this.id = _get(data, 'id', '');
    this.title = _get(data, 'title', '');
    this.reporttypes = _get(data, 'reporttypes', []);
    this.emailaddress = _get(data, 'emailaddress', '');
    this.subject = _get(data, 'subject', '');
    this.body = _get(data, 'body', '');
    this.target = _get(data, 'target', []);
    this.chronorder = _get(data, 'chronorder', []);
    this.daysbefore = _get(data, 'daysbefore', 0);
    this.clients = _get(data, 'clients', []);
    this.managedtype = _get(data, 'managedtype', '');
    this.defaulttemplate = _get(data, 'defaulttemplate', true);
    this.cctoagent = _get(data, 'cctoagent', false);
    this.cctolandlord = _get(data, 'cctolandlord', false);
  }

  /**
   * Create an object from a JSON representation
   * @param data
   * @return EmailTemplate
   */
  public static parse(data?: Partial<EmailTemplate>) {
    const obj = new EmailTemplate;
    copyInto(obj, data);

    data = toCamelCase(data);
    obj.id = _get(data, 'id', '');
    obj.title = _get(data, 'title', '');
    obj.reporttypes = _get(data, 'reporttypes', []);
    obj.emailaddress = _get(data, 'emailaddress', '');
    obj.subject = _get(data, 'subject', '');
    obj.body = _get(data, 'body', '');
    obj.target = _get(data, 'target', []);
    obj.chronorder = _get(data, 'chronorder', []);
    obj.clients = _get(data, 'clients', []);
    obj.managedtype = _get(data, 'managedtype', '');
    obj.defaulttemplate = _get(data, 'defaulttemplate', true);
    obj.cctoagent = _get(data, 'cctoagent', false);
    obj.cctolandlord = _get(data, 'cctolandlord', false);

    return obj;
  }

}
