<template>
  <div class="customer">
    <div class="user-header">
      <form>
        <fieldset>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">C/O</label>
            <div class="col-sm-4">
              <input type="text" class="form-control" v-model.lazy="customer.co" @change="editCurrentCustomer()" />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Address</label>
            <div class="col-sm-6">
              <input type="text" class="form-control" v-model.lazy="customer.address.line1"
                @change="editCurrentCustomer()" />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label"><span class="sr-only">Address Line 2</span></label>
            <div class="col-sm-6">
              <input type="text" class="form-control" v-model.lazy="customer.address.line2"
                @change="editCurrentCustomer()" />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">City</label>
            <div class="col-sm-3">
              <input type="text" class="form-control" v-model.lazy="customer.address.town"
                @change="editCurrentCustomer()" />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">County</label>
            <div class="col-sm-3">
              <input type="text" class="form-control" v-model.lazy="customer.address.county"
                @change="editCurrentCustomer()" />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Postcode</label>
            <div class="col-sm-2">
              <input type="text" class="form-control" v-model.lazy="customer.address.postcode"
                @change="editCurrentCustomer()" />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Phone</label>
            <div class="col-sm-2">
              <input type="text" class="form-control" v-model.lazy="customer.address.phone"
                @change="editCurrentCustomer()" />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">HQ Branch</label>
            <div class="col-sm-4">

              <DxSwitch :value="customer.hqbranch"
                @value-changed="setCustomerDeep({ path: 'hqbranch', data: $event.value })" />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Editable</label>
            <div class="col-sm-4">
              <DxSwitch :value="customer.editable"
                @value-changed="setCustomerDeep({ path: 'editable', data: $event.value })" />
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { Customer } from "@/models";
import { DxSwitch } from 'devextreme-vue/switch';

const store = useStore();
const customer = computed(() => store.getters['customers/current']);
const editCustomer = async (customer: Customer) => await store.dispatch('customers/editCustomer', customer);
const setCustomerDeep = async (payload: {
  path: string;
  data: any;
}) => {
  await store.dispatch('customers/setCustomerDeep', payload);
}
const editCurrentCustomer = () => {
  editCustomer(customer.value);
};
</script>


<style scoped lang="scss"></style>