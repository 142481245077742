import { MutationTree } from "vuex";
import { PropertyVisitManagementState } from "./types";
import { PmTenancy } from "@/models";

/**
 * Mutations
 *
 * The only way to actually change state in a Vuex store is by committing a mutation.
 * - Vuex mutations are very similar to events: each mutation has a string type and a handler.
 * - The handler function is where we perform actual state modifications - it receives the state as the first argument:
 * - You cannot directly call a mutation handler.
 * - Think of it more like event registration: "When a mutation with type X is triggered, call this handler."
 * - To invoke a mutation handler, you need to call store.commit with its type
 * - e.g. `store.commit('setCustomers', customer)`
 */
export const mutations: MutationTree<PropertyVisitManagementState> = {
  /**
   * Set the tenancies list
   *
   * @param {PropertyVisitManagementState} state
   * @param {PmTenancy[]} tenancies
   * @returns void
   */
  setTenancies(state: PropertyVisitManagementState, tenancies: PmTenancy[]): void {
    state.tenancies = tenancies;
  },

  /**
   * Set the current Tenancy ID
   *
   * @param {PropertyVisitManagementState} state
   * @param {string} id
   * @returns void
   */
  setCurrentOfficeId(state: PropertyVisitManagementState, id: string): void {
    state.currentTenancyId = id;
  },
};
