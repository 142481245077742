import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';
import { PiRate } from '@/models';

export class RateCard extends Base {

  public id: string;
  public furnishedtype: string;
  public rates: PiRate[];

  public constructor(data?: Partial<RateCard>) {
    super(data);
    data = toCamelCase(data);

    this.id = _get(data, 'id', '');
    this.furnishedtype = _get(data, 'furnishedtype', '');
    this.rates = _castArray(_get(data, 'rates', [])).map((r: Partial<PiRate>) => new PiRate(r));
  }
}