<template>
  <div class="list">
    <div>
      <label class="col-sm-3 col-form-label">Front page guide</label>
      <tiptap-editor
        :content="frontpageguide"
        :key="customerdictionary.company_name"
        @blur="updateFrontpageguide($event)"
      />
    </div>
    <div
      v-if="!maintenanceflags.length"
      class="alert alert-warning text-center"
      style="margin-top: 10px"
    >
      <h2 class="mt-1 mb-2">Maintenance flags</h2>
      <h5 class="mt-1 mb-2">No items found</h5>
      <p class="mb-1"></p>
    </div>

    <table v-else class="table table-hover">
      <thead>
        <tr>
          <th class="flag">Flag</th>
          <th class="colour">Colour</th>
          <th class="backgroundcolour">Background Colour</th>
          <th class="icon">Icon</th>
          <th class="description">Description</th>
          <th class="pdfdisplay">PDF Display</th>
          <th class="delete"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="flag in maintenanceflags" :key="flag.slug">
          <td>{{ flag.flag }}</td>
          <td>
            <i class="fas fa-circle fa-lg" :style="{ color: flag.colour }"></i>
          </td>
          <td>
            <i
              v-if="flag.backgroundcolour"
              class="fas fa-circle fa-lg"
              :style="{ color: flag.backgroundcolour }"
            ></i>
          </td>
          <td>
            <div v-if="flag.symbol" :class="flag.symbol" class="flag-icon" />
          </td>
          <td>{{ flag.description }}</td>
          <td>{{ flag.pdfDisplay }}</td>
          <td>
            <button
              class="btn btn-outline-danger"
              @click="remove(flag)"
              v-on:click.stop
            >
              <i class="fas fa-trash-alt"></i>
            </button>
            <button
              class="btn btn-outline-primary"
              @click="edit(flag)"
              v-on:click.stop
            >
              <i class="fas fa-pencil-alt"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modals -->
    <MaintenanceflagModal
      :id="`maintenance-flag-modal-edit`"
      ref="maintenanceFlagModalEdit"
      :operation="'edit'"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { CustomerDictionary } from '@/models/customerdictionary.model';
import { Maintenanceflag } from '@/models';
import MaintenanceflagModal from '@/components/modals/MaintenanceflagModal.vue';
import TiptapEditor from '@/components/editor/TiptapEditor.vue';

const store = useStore();
const toasted = useToast();

const actProperty: any = inject('actProperty');

const customerdictionary = computed(() => store.getters['customerdictionary/current'] as CustomerDictionary);
const maintenanceflags = computed(() => store.getters['customerdictionary/maintenanceflags'] as Maintenanceflag[]);

const frontpageguide = computed({
  get: () => customerdictionary.value.report_configuration.frontpageguide,
  set: (value: string) => {
    setCustomerDictionaryDeep({
      path: "report_configuration.frontpageguide",
      data: value,
    });
  }
});

const setCustomerDictionaryDeep = async (payload: { path: string; data: any }) => {
  await store.dispatch('customerdictionary/setCustomerDictionaryDeep', payload);
};

const removeFromMaintenanceflags = (flag: Maintenanceflag) => {
  store.commit('customerdictionary/removeFromMaintenanceflags', flag);
};

const maintenanceFlagModalEdit = ref(null);

const setSelectedMaintenanceflag = (flag: Maintenanceflag) => {
  store.commit('customerdictionary/setSelectedMaintenanceflag', flag);
};

const updateFrontpageguide = (value: string) => {
  setCustomerDictionaryDeep({
    path: "report_configuration.frontpageguide",
    data: value,
  });
};

const edit = (flag: Maintenanceflag) => {
  setSelectedMaintenanceflag(flag);
  const modal = maintenanceFlagModalEdit.value;
  modal.show();
};

const remove = async (flag: Maintenanceflag) => {
  actProperty
    .confirmPrompt()
    .then(() => removeFromMaintenanceflags(flag))
    .then(() => toasted.success("Flag deleted"))
    .catch((err: any) => actProperty.displayError(err));
};

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
th {
  &.flag {
    width: 1.5rem;
  }
  &.colour {
    width: 1.5rem;
  }
  &.backgroundcolour {
    width: 2.5rem;
  }
  &.icon {
    width: 1.5rem;
  }
  &.description {
    width: 13.5rem;
  }
  &.pdfdisplay {
    width: 5rem;
  }
  &.delete {
    width: 3rem;
  }
}
</style>

<style lang="scss">
.acteditor {
  min-height: 50px !important;
}
</style>
