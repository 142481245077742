<template>
  <div class="emailtemplate">
    <fieldset>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Title</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" v-model="title" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Report type</label>
        <div class="col-sm-4">
          <multiselect-text
            v-model="reporttypes"
            :options="reportTypes"
            :allow-empty="true"
            :taggable="false"
            :custom-label="formatReportType"
            multiple
            @select="onSelectReporttype"
            @change="editTemplate('type', $event)"
          >
          </multiselect-text>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Target</label>
        <div class="col-sm-4">
          <multiselect-text
            v-model="target"
            :options="targetList"
            :allow-empty="true"
            :taggable="false"
            multiple
            @select="onSelectTarget"
            @change="editTemplate('target', $event)"
          >
          </multiselect-text>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Chron order</label>
        <div class="col-sm-4">
          <multiselect-text
            v-model="chronorder"
            :options="sortedChronorderlist"
            :allow-empty="true"
            :taggable="false"
            multiple
            @select="onSelectChronorder"
            @change="editTemplate('chronorder', $event)"
          >
          </multiselect-text>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label"
          >To be sent before (in days)</label
        >
        <div class="col-sm-1">
          <input type="number" class="form-control" v-model="daysbefore" />
        </div>

        <label class="col-sm-2 col-form-label text-right">Managed/Non-managed</label>
        <div class="col-sm-2">
          <singleselect-text
            v-model="managedtype"
            :options="managedtypeOptions"
            >
              <template 
                  #clear
                  v-if="managedtype">
                <i
                  @mousedown.prevent.stop="managedtype = ''"
                  class="multiselect__clear fa fa-times"
                  aria-label="Clear"
                ></i>
              </template>
            </singleselect-text>
        </div>

        <div class="col-sm-2">
          <div class="dx-field">
            <div class="dx-field-label">Default</div>
            <div class="dx-field-value">
              <DxSwitch :value="defaulttemplate" @value-changed="setEmailTemplateDeep({path: 'defaulttemplate', data: $event.value})"/>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label"></label>
        <div class="col-sm-2">
          <div class="dx-field">
            <div class="dx-field-label">CC to agent</div>
            <div class="dx-field-value">
              <DxSwitch :value="cctoagent" @value-changed="setEmailTemplateDeep({path: 'cctoagent', data: $event.value})"/>
            </div>
          </div>
        </div>

        <div class="col-sm-2">
          <div class="dx-field">
            <div class="dx-field-label">CC to landlord</div>
            <div class="dx-field-value">
              <DxSwitch :value="cctolandlord" @value-changed="setEmailTemplateDeep({path: 'cctolandlord', data: $event.value})"/>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Subject</label>
        <div class="col-sm-9 subject-editor">
          <ckeditor
            :editor="subjecteditor"
            v-model="subject"
            :config="subjectEditorConfig"
          ></ckeditor>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Message</label>
        <div class="col-sm-9 body-editor">
          <ckeditor
            :editor="bodyeditor"
            v-model="body"
            :config="bodyEditorConfig"
          ></ckeditor>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { EmailTemplate, Dictionary } from "@/models";
import Editor from "ckeditor5/ckeditor";
import { DxSwitch} from 'devextreme-vue/switch';

const store = useStore();
const router = useRouter();
const route = useRoute();

// State and Getters
const dictionary = computed((): Dictionary => store.getters['dictionary/current']);
const emailtemplate = computed(() => store.getters['emailtemplates/current']);
const actProperty: any = inject('actProperty');

// Methods
const setEmailTemplate = (emailtemplate: EmailTemplate): void => {
  store.commit('emailtemplates/setEmailTemplate', emailtemplate);
};
const setEmailTemplateDeep = (payload: { path: string; data: any; }): Promise<any> => {
  return store.dispatch('emailtemplates/setEmailTemplateDeep', payload);
};

const addReporttype = (reporttype: string): Promise<any> => {
  return store.dispatch('emailtemplates/addReporttype', reporttype);
};
const setReporttypes = (reporttypes: string[]): Promise<any> => {
  return store.dispatch('emailtemplates/setReporttypes', reporttypes);
};

const addTarget = (target: string): Promise<any> => {
  return store.dispatch('emailtemplates/addTarget', target);
};

const addChronorder = (chronorder: string): Promise<any> => {
  return store.dispatch('emailtemplates/addChronorder', chronorder);
};

onMounted(async () => {
  if (isNewTemplate()) {
    setEmailTemplate(new EmailTemplate());
    return;
  }
});

// Computed properties
const bodyeditor = computed(() => Editor);
const bodyEditorConfig = computed(() => {
  return {
      placeholderConfig: {
        types: [
          "Type of job external",
          "Address",
          "Booked with name",
          "Appointment time",
          "Client",
          "Branch",
          "Access Branch",
          "Client notes",
          "Landlord name",
          "Tenant name",
          "Date",
          "Start time",
          "End time",
          "Invoice amount",
          "Confirm Yes Button",
          "Confirm No Button",
          "Confirm Callback Button",
          "Option 1 Button",
          "Option 2 Button",
          "Option 3 Button",
          "Option 4 Button",
          "Dataentry Report reference",
          "Acknowledge Button",
          "Download Dataentry Files Button",
          "Dataentry delivery date/time",
          "Previous CI date",
          "Previous CI reference",
        ],
      },
      toolbar: {
        items: ['heading','|','bold','italic','link','|','alignment:left', 'alignment:right', 'alignment:center', 
        'alignment:justify','|','bulletedList','numberedList','|','insertTable','|','outdent', 'indent',
        '|','fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor','|','undo','redo','|', 'placeholder'],
      },
    };
});
const subjecteditor = computed(() => Editor);
const subjectEditorConfig = computed(() => {
  return {
      placeholderConfig: {
        types: [
          "Type of job external",
          "Address",
          "Booked with name",
          "Appointment time",
          "Client",
          "Branch",
          "Access Branch",
          "Date",
          "Start time",
          "End time",
          "Invoice amount",
          "Dataentry Report reference",
        ],
      },
      toolbar: {
        items: ["placeholder"],
      },
    };
});

const body = computed({
  get: () => emailtemplate.value.body,
  set: (data: string) => {
    setEmailTemplateDeep({
      path: 'body',
      data,
    });
  }
});

// Computed property for `reporttypes`
const reporttypes = computed({
  get: () => emailtemplate.value.reporttypes,
  set: (types: string[]) => {
    setReporttypes(types);
  }
});
const onSelectReporttype = (type: string) => {
  addReporttype(type);
}

// Computed property for `title`
const title = computed({
  get: () => emailtemplate.value.title,
  set: (data: string) => {
    setEmailTemplateDeep({
      path: 'title',
      data,
    });
  }
});

// Computed property for `subject`
const subject = computed({
  get: () => emailtemplate.value.subject,
  set: (data: string) => {
    setEmailTemplateDeep({
      path: 'subject',
      data,
    });
  }
});


const managedtypeOptions: string[] = ["Managed", "Non-managed"];

// Computed property for `managedtype`
const managedtype = computed({
  get: () => emailtemplate.value.managedtype,
  set: (data: string) => {
    setEmailTemplateDeep({
      path: 'managedtype',
      data,
    });
  }
});

// Computed property for `defaulttemplate`
const defaulttemplate = computed({
  get: () => emailtemplate.value.defaulttemplate,
  set: (data: boolean) => {
    setEmailTemplateDeep({
      path: 'defaulttemplate',
      data,
    });
  }
});

// Computed property for `cctoagent`
const cctoagent = computed({
  get: () => emailtemplate.value.cctoagent,
  set: (data: boolean) => {
    setEmailTemplateDeep({
      path: 'cctoagent',
      data,
    });
  }
});

// Computed property for `cctolandlord`
const cctolandlord = computed({
  get: () => emailtemplate.value.cctolandlord,
  set: (data: boolean) => {
    setEmailTemplateDeep({
      path: 'cctolandlord',
      data,
    });
  }
});

const editTemplate = (field: string, $event: any) => {
  setEmailTemplateDeep({
    path: field,
    data: $event.target.value,
  });
};
const reportTypes = computed((): string[] => {
  let list = ["inventory", "soc", "checkin", "checkout", "property visit"];
  if (dictionary.value && dictionary.value.reporttypes) {
    list = dictionary.value.reporttypes.map((t) => t.slug);
  }
  return list;
});

const formatReportType = (type: string) => {
  return actProperty.formatReportType(type, dictionary.value);
};

const targetList: string[] = [
  "Booked By",
  "Data Entry",
  "Managed Client",
  "Non-Managed Client",
  "Tenant",
  "Landlord",
  "Invoice",
];
const chronorderList: string[] = [
  "Initial confirmation",
  "First Chaser",
  "Second Chaser",
  "Third Chaser",
  "Fourth Chaser",
  "Fifth Chaser",
  "Sixth Chaser",
  "Last Minute",
  "Reminder",
  "Change Of Date",
  "Restore Booking",
  "Confirmed",
  "Not Confirmed",
  "Delete Booking",
  "Correspondance/Feedback to Client",
  "Issue Report"
];
const sortedChronorderlist = computed(() => chronorderList.sort())

// Computed property for `target`
const target = computed({
  get: () => emailtemplate.value.target,
  set: (data: string[]) => {
    setEmailTemplateDeep({
      path: 'target',
      data,
    });
  }
});

const onSelectTarget = (type: string) => {
  addTarget(type);
}

// Computed property for `chronorder`
const chronorder = computed({
  get: () => emailtemplate.value.chronorder,
  set: (data: string[]) => {
    setEmailTemplateDeep({
      path: 'chronorder',
      data,
    });
  }
});

const onSelectChronorder = (type: string) => {
  addChronorder(type);
}

// Computed property for `daysbefore`
const daysbefore = computed({
  get: () => emailtemplate.value.daysbefore,
  set: (data: number) => {
    setEmailTemplateDeep({
      path: 'daysbefore',
      data,
    });
  }
});

// Helper functions
const canSave = () => {
  return emailtemplate.value.subject && emailtemplate.value.body;
};

const isNewTemplate = () => {
  return (route.params.id as string) === "new";
};

</script>


<style lang="scss">
.placeholder {
  background-color: yellow;
}
.body-editor .ck-editor__editable_inline {
  min-height: 400px;
}
.subject-editor .ck-editor__editable_inline {
  max-height: 100px;
}

.dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button) {
  width: 30%;
}
.dx-field-label {
  color: #212529;
}
</style>