<template>
  <div class="card bg-white" :id="`item-${props.item._uuid}`">
    <div class="card-header">
      <h4 class="h5 mb-1 mt-1">{{ itemNumber }}. {{ typeName }}</h4>
    </div>

    <div class="card-body">
      <table class="table">
        <tbody>
          <tr>
            <td
              width="15%"
              v-if="
                !actProperty.isFactoryreport(report.type) || props.item.locallyadded
              "
            >
              <!-- Item -->
              <div>{{ props.item.name }}</div>
            </td>
            <td width="5%" v-if="!actProperty.isFactoryreport(report.type)">
              <!-- Qty -->
              <div>{{ props.item.qty }}</div>
            </td>
            <td width="7%" v-if="!actProperty.isFactoryreport(report.type)">
              <!-- Colour -->
              <div>{{ props.item.colour }}</div>
            </td>
            <td width="23%">
              <!-- Condition In -->
              <table class="table table-sm table-borderless">
                <tr v-for="note in getConditionInNotes()" :key="note._uuid">
                  <td width="90%">
                    <div>{{ note.note }}</div>
                  </td>
                  <td></td>
                </tr>
              </table>
            </td>
            <td width="50%">
              <!-- Condition Out -->
              <table
                v-if="isCheckOut()"
                class="table table-sm table-borderless"
              >
                <tbody>
                  <tr v-if="isSameAsCheckIn()">
                    <td width="30%">
                      <!-- Same as Check-In Note -->
                      <div>{{ getSaciNote() }}</div>
                    </td>
                    <td width="30%"></td>
                    <td width="40%">
                      <!-- Same as Check-In Responsibility -->
                      <condition-responsibilities
                        :note-path="
                          getItemPath('condition.out.sameAsCheckin.note')
                        "
                      />
                    </td>
                  </tr>
                  <tr
                    v-for="(note, $noteIndex) in getConditionOutNotes()"
                    :key="note._uuid"
                  >
                    <td width="30%">
                      <div>{{ note.note }}</div>
                    </td>
                    <td width="30%" style="vertical-align: middle">
                      <!-- Depository deductions -->
                      <deductions
                        :item="item"
                        :note="note"
                        :noteIndex="$noteIndex"
                      />
                    </td>
                    <td width="40%">
                      <!-- Responsibility -->
                      <condition-responsibilities
                        :note-path="
                          getItemPath(`condition.out.notes[${$noteIndex}]`)
                        "
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                v-if="
                  isCheckOut() && report.flagged && hasResponsibilities(props.item)
                "
                class="float-right text-danger"
              >
                <i class="far fa-flag"></i>
              </div>
            </td>
          </tr>
          <tr v-if="isCheckOut() && showPhotos">
            <td colspan="3"></td>
            <td>
              <!-- Photos In -->
              <Photos
                :key="`item${props.item._uuid}in${props.item.condition.in.photos.length}`"
                :photos="getInPhotos(props.item)"
                :path="getItemPath('condition.in.photos')"
                :shownewbutton="false"
                :showrotatebutton="false"
                :showdeletebutton="false"
              />
            </td>
            <td>
              <!-- Photos Out -->
              <div v-if="isCheckOut()">
                <Photos
                  :key="`item${props.item._uuid}out${props.item.condition.out.photos.length}`"
                  :photos="getOutPhotos(props.item)"
                  :path="getItemPath('condition.out.photos')"
                  :shownewbutton="false"
                  :showrotatebutton="false"
                  :showdeletebutton="false"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, ref, provide, PropType, defineProps } from 'vue';
import { useStore } from 'vuex';
import { Report, Item, Type, Condition, Note, Maintenanceflag } from '@/models';
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";

import Photos from '@/components/photo/Photos.vue';

import ConditionResponsibilities from "@/components/depository/ConditionResponsibilities.vue";
import Deductions from "@/components/depository/Deductions.vue";

const actProperty: any = inject('actProperty');

const props = defineProps({
  index: { type: Number as PropType<number> },
  item: { type: Item, required: true },
});

const roomIndex = inject<number>('roomIndex');
const sectionIndex = inject<number>('sectionIndex');
const typeIndex = inject<number>('typeIndex');
const typeName = inject<string>('typeName');
const store = useStore();

provide('itemIndex', props.index);

const report = computed(() => store.state.reports.current);
const item = computed(() => props.item);
const itemNumber = computed(() => store.getters['reports/itemNumber'](item.value));
const showPhotos = ref(true);

const isCheckOut = () => {
  return report.value.type == "checkout";
};

const hasResponsibilities = (item: Item): boolean => {
  return item.condition.out.notes.some(
    (note: Note) => false === _isEmpty(note.responsibility)
  );
};

const getItemPath = (append: string) => {
  if (append) {
    append = `.${append}`;
  }
  return `rooms[${roomIndex}].sections[${sectionIndex}].types[${typeIndex}].items[${props.index}]${append}`;
};

const getInPhotos = (item: Item) => {
  return _get(item, "condition.in.photos", []);
};

const getOutPhotos = (item: Item) => {
  return _get(item, "condition.out.photos", []);
};

const isSameAsCheckIn = (): boolean => {
  return _get(item.value, "condition.out.sameAsCheckin.active", false);
};

const getSaciNote = (): string => {
  return _get(
    item.value,
    "condition.out.sameAsCheckin.note.note",
    "Same as Check-In"
  );
};

const getConditionInNotes = () => {
  const notes = _get(item.value, "condition.in.notes", false);
  if (notes === false) {
    return [];
  }
  return notes;
};

const getConditionOutNotes = () => {
  let notes = _get(item.value, "condition.out.notes", false);
  if (notes === false) {
    return [];
  }
  notes = notes.filter((note: Note) => {
    return note?.responsibility?.filter(
      (responsibility: string) => responsibility
    );
  });
  return notes;
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/bootstrap/_variables.scss";

.card {
  margin-bottom: 1rem;
  // &:last-child {
  //   margin-bottom: 0;
  // }
}

.card-header {
  .button-group {
    float: right;
    .btn {
      &.disabled {
        opacity: 0.35;
      }
      + .btn {
        margin-left: 0.5rem;
      }
    }
  }
}

.img-100w {
  display: block;
  margin-bottom: 0.5rem;
  max-width: 100%;
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
}

table {
  margin-bottom: 0;
  td {
    border-top: none;
  }
  table {
    margin-bottom: 0.5rem;
    th,
    td {
      padding: 0;
      vertical-align: top;
      // border-bottom: 1px dashed rgba(0,0,0,0.15);
    }
  }
}

.photo-out {
  &:before {
    content: "\f061";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    left: -6px;
    top: 46%;
    opacity: 0.75;
  }
}

.tenant-feedback-jumbotron {
  padding: 0rem 2rem;
}

.approvedcomment {
  background-color: #a3e23f;
}

.flaggednote {
  color: #dc3545;
}

.no-border {
  border: 0px;
}

.maintenanceflagbutton {
  margin-left: 2px;
  margin-right: 2px;
}
</style>
