<template>
  <div class="modal" data-keyboard="true" tabindex="-1" role="dialog" id="google-job-modal"
    aria-labelledby="`personal-job-modal-label`" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="google-job-modal-label">
            {{ booking.summary }}
          </h5>
          <button
            id="close"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click.prevent="hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <form>
            <fieldset>
              <div class="form-group row">
                <label class="col-md-2 col-form-label">Date</label>
                <div class="col-md-6">
                  <DxDateBox
                    v-model="subjobdate"
                    display-format="dd/MM/yyyy"
                    type="date"
                    picker-type="calendar"
                    apply-value-mode="instantly"
                    :calendarOptions="{firstDayOfWeek:1}"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-md-2 col-form-label"
                  >Inspector</label
                >
                <div class="col-sm-8">
                  <singleselect-text v-model="inspector" :options="inspectorlistinstring" :allow-empty="true"
                    :taggable="false" @search-change="inspectorinstringsearchfilter = $event"
                    :ref="el => { dynamicRefs['inspectorDropDown'] = el }"
                    @open="dropDownOpened('inspectorDropDown')">
                    <template #clear v-if="selectedinspector && selectedinspector.id">
                      <i @mousedown.prevent.stop="resetInspector" class="multiselect__clear fa fa-times"
                        aria-label="Clear inspector"></i>
                    </template>
                  </singleselect-text>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-md-2 col-form-label">Timing</label>
                <div class="col-md-4 pr-0">
                  <singleselect-text v-model="_startdate" :options="starttimeoptions" :customLabel="customLabel" :allow-empty="true"
                    :taggable="false" :ref="el => { dynamicRefs['startDateDropDown'] = el }"
                    @open="dropDownOpened('startDateDropDown')">
                    <template #clear v-if="_startdate">
                      <i @mousedown.prevent.stop="_startdate = ''" class="multiselect__clear fa fa-times"
                        aria-label="Clear Start Time"></i>
                    </template>
                  </singleselect-text>
                </div>
                <div class="col-md-4 pl-0">
                  <singleselect-text v-model="_enddate" :options="endtimeoptions" :customLabel="customLabel" :allow-empty="true"
                    :taggable="false" :ref="el => { dynamicRefs['endDateDropDown'] = el }"
                    @open="dropDownOpened('endDateDropDown')">
                    <template #clear v-if="_enddate">
                      <i @mousedown.prevent.stop="_enddate = ''" class="multiselect__clear fa fa-times"
                        aria-label="Clear End Time"></i>
                    </template>
                  </singleselect-text>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-2 col-form-label">Summary</label>
                <div class="col-md-8 pr-0">
                  <input
                    v-model.lazy="summary"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-2 col-form-label">Description</label>
                <div class="col-md-8 pr-0">
                  <tiptap-editor
                    :key="tiptapeditorkey"
                    :content="description"
                    @blur="setDescription"
                  />
                </div>
              </div>
            </fieldset>
          </form>
        </div>

        <div class="modal-footer">
          <div id="status-bar-switches" style="margin-right: auto" v-if="booking.id">
            <div
              class="custom-control custom-switch"
            >
              <input
                type="checkbox"
                id="issuedCheckBox"
                class="custom-control-input"
                :checked="props.booking.issued"
                @change="toggleIssued()"
                
              />
              <label class="custom-control-label" for="issuedCheckBox">
                Issued
              </label>
            </div>
          </div>
          <button
            type="button"
            class="btn btn-outline-secondary"
            data-dismiss="modal"
            @click.prevent="hide()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            @click.prevent="save()"
            :disabled="isSaving || !savingAllowed"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import { computed, ref, onMounted, inject, watch, nextTick, defineEmits, defineProps, defineExpose } from 'vue';
import moment from "moment-timezone";
import { v4 as uuidv4 } from 'uuid';
import { DxDateBox } from "devextreme-vue/date-box";
import TiptapEditor from "@/components/editor/TiptapEditor.vue";
import { Booking, Inspector, Location, SelectOption, ReportTiming } from "@/models";
import { useStore } from 'vuex';

const props = defineProps<{
  booking: Booking;
}>();

const store = useStore();
const actProperty: any = inject('actProperty');
const emit = defineEmits(['hide'])

const googlebooking = ref(new Booking());
const selectedinspector = ref(new Inspector());
const subjobdate = ref(new Date());
const description = ref("");
const tiptapeditorkey = ref(uuidv4());
const isSaving = ref(false);

const dynamicRefs = ref({});

const realtime: any = inject('realtime');
const channel = realtime.channels.get('diary');
const deviceid = actProperty.getDeviceId();

const inspectors = computed((): Inspector[] => store.getters['diary/inspectorlist']);
const inspectorinstringsearchfilter = ref('');

const saveBooking = async (booking: Booking): Promise<Booking> => {
  return store.dispatch('diary/saveBooking', booking);
};

onMounted(async () => {
  Promise.all([setStartTimeOptions(), setEndTimeOptions()])
    .then((values) => { })
    .catch((err) => {
      actProperty.displayError(err);
    });
});

const init = () => {
  googlebooking.value = new Booking(props.booking);
  subjobdate.value = props.booking.startdateAsDate;
  selectedinspector.value = props.booking.inspector;
  description.value = props.booking.description;
  tiptapeditorkey.value = uuidv4();
}

const edit = () => {

}

const starttimeoptions = ref<string[]>([]);
const endtimeoptions = ref<string[]>([]);
const appointmenttimeoptions = ref<string[]>([]);
const setStartTimeOptions = () => {
  starttimeoptions.value = [];
  let startmoment = moment().set("hour", 6).set("minute", 0);
  for (let i = 0; i < 62; i++) {
    const hourMinutes = startmoment.format("hh:mm A");
    starttimeoptions.value.push(hourMinutes);
    appointmenttimeoptions.value.push(hourMinutes);
    startmoment.add(15, "minutes");
  }
}

const setEndTimeOptions = () => {
  endtimeoptions.value = [];
  let startmoment = moment().set("hour", 6).set("minute", 0);
  if (_startdate.value) {
    startmoment = moment(_startdate.value, "hh:mm A");
    startmoment.add(15, "minutes");
  }
  for (let i = 0; i < 62; i++) {
    const hourMinutes = startmoment.format("hh:mm A");
    endtimeoptions.value.push(hourMinutes);
    startmoment.add(15, "minutes");
    if (hourMinutes === "09:00 PM ") break;
  }
}

const _startdate = computed({
  get: () => {
    let value = googlebooking.value.startdate
      ? moment(googlebooking.value.startdate).utc().format("hh:mm A")
      : "";
    const index = starttimeoptions.value.findIndex(
      (option: string) => option === value
    );
    if (index < 0) value = "";
    return value;
  },
  set: (val: string) => {
    googlebooking.value.startdate = subjobendDate(
      val,
      googlebooking.value.startdateAsDate
    );
  }
});

const _enddate = computed({
  get: () => {
    let value = googlebooking.value.enddate
      ? moment(googlebooking.value.enddate).utc().format("hh:mm A")
      : "";
    const index = endtimeoptions.value.findIndex(
      (option: string) => option === value
    );
    if (index < 0) value = "";
    return value;
  },
  set: (val: string) => {
    googlebooking.value.enddate = subjobendDate(
      val,
      googlebooking.value.startdateAsDate
    );
    let duration = googlebooking.value.duration;
    if (duration === googlebooking.value.recommendedtime) duration = 0;
    googlebooking.value.preferredduration = duration;
  }
});

const subjobendDate = (time: string, date: Date) => {
  let value = "";
  if (time) {
    let dt: Date = date ? date : subjobdate.value;
    let justdate = moment(dt).utc().format("YYYY-MM-DD");
    let justtime = moment(time, "hh:mm A").format("HH:mm");
    value = `${justdate}T${justtime}:00.000Z`;
  }
  return value;
}

watch(() => _startdate.value, (newValue) => {
  setEndTimeOptions();
});

const customLabel = (option: string) => {
  return option.replace(/^0/, '');
}

const inspectorlistinstring = computed(() => {
  return inspectors.value
    .sort((i1, i2) => i1.name.toLowerCase().localeCompare(i2.name.toLowerCase()))
    .filter(i => i.name.toLowerCase() != "default" && (!inspectorinstringsearchfilter.value || i.name.toLowerCase().startsWith(inspectorinstringsearchfilter.value.toLowerCase())))
    .map(i => i.name);
});

const inspector = computed({
  get: () => {
    const insp = inspectors.value.find(i => i.id === selectedinspector.value.id);
    return insp?.name ?? '';
  },
  set: (val: string) => {
    if (val) {
      const insp = inspectors.value.find(i => i.name === val);
      if (insp) {
        selectedinspector.value = insp;
      }
    }
  }
});

const resetInspector = () => {
  selectedinspector.value = new Inspector();
};

const summary = computed({
  get: () => googlebooking.value.summary,
  set: (val: string) => {
    googlebooking.value.summary = val;
  }
});

const pinotes = computed({
  get: () => googlebooking.value.pinotes,
  set: (val: string) => {
    googlebooking.value.pinotes = val;
  }
});

const setDescription = (val: string) => {
  description.value = val;
}

const issued = computed(() => {
  return googlebooking.value.issued;
});
const toggleIssued = () => {
  googlebooking.value.issued = !googlebooking.value.issued;
}

const convertToHoursMinutes = (val: number) => {
  let hourMinutes = moment
    .utc()
    .startOf("day")
    .add(val, "minutes")
    .format("HH [Hours] mm [Minutes]");
  hourMinutes = hourMinutes.replaceAll("00 Hours", "");
  hourMinutes = hourMinutes.replaceAll("00 Minutes", "");
  return hourMinutes;
}

const dropDownOpened = (ref: string) =>{
  nextTick(() => {
    // Get the reference to the dropdown list container
    const multiselect = dynamicRefs.value[ref] as any;
    const dropdownList = multiselect.$el.querySelector('.multiselect__content-wrapper');

    // Get the reference to the selected option element
    const selectedOption = multiselect.$el.querySelector('.multiselect__option--selected');

    // Scroll to the position of the selected element
    if(selectedOption?.offsetTop)
      dropdownList.scrollTop = selectedOption.offsetTop;
  });
}

const savingAllowed = computed(() => {
  let result = false;
  if (selectedinspector.value && selectedinspector.value.id && subjobdate.value)
    result = true;
  return result;
});

const save = async () => {
  hide();
  if (selectedinspector.value.id) {
    isSaving.value = true;
    googlebooking.value.inspector = selectedinspector.value;
    googlebooking.value.startdate = subjobendDate(
      googlebooking.value.starttime,
      subjobdate.value
    );
    googlebooking.value.enddate = subjobendDate(
      googlebooking.value.endtime,
      subjobdate.value
    );
    if (props.booking.googleid) {
      googlebooking.value.description = description.value;
    }
    try {
      await saveBooking(googlebooking.value);
      channel.publish('appointmentChanged', { deviceid: deviceid, bookingid: googlebooking.value.id, source: 'Scheduler' });
    } catch (err: any) {
      actProperty.displayError(err);
    } finally {
      isSaving.value = false;
    }
  }
};

const show = () => {
  if ($('#google-job-modal-Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="google-job-modal-Backdrop"></div>');
    $('body').append(backdropDiv);
    $(`#google-job-modal`).show();
  }
}
const hide = () => {
  if ($('#google-job-modal-Backdrop').length > 0) {
    $('#google-job-modal-Backdrop').remove();
    $(`#google-job-modal`).hide();
  }
};

defineExpose({show, init, edit, hide})
</script>


<style scoped lang="scss">
.text-yellow {
  color: #e9db4b;
}

.no-padding ::v-deep .multiselect__option {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  min-height: 0px !important;
  line-height: 0px !important;
}
</style>

<style lang="scss">
#status-bar-buttons,
#status-bar-switches,
#status-bar-actions {
  .btn-group,
  .custom-switch {
    display: inline-block;
    margin: 0 0.5rem;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #4caf50;
  border-color: #4caf50;
}
</style>