import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

export class TextValueObject extends Base {

  public text: string;
  public value: number;

  public constructor(data?: Partial<TextValueObject>) {
    super(data);
    data = toCamelCase(data);

    this.text = _get(data, 'text', '');
    this.value = _get(data, 'value', -1);
  }

  public static getSample(max: number) {
    const val = new TextValueObject();
    val.text = ""
    val.value = Math.round(Math.random() *  max);
    return val;
  }
}