import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { Base, Document } from '@/models';
import { toCamelCase } from '@/utilities';

interface GetaddressResponseInterface {
  latitude?: number;
  longitude?: number;
  addresses?: string[];
  suggestions?: string[];
}

export class GetaddressResponse extends Base implements GetaddressResponseInterface {

  public latitude: number;
  public longitude: number;
  public addresses: string[];
  public suggestions: string[];

  public constructor(data?: Partial<GetaddressResponse>) {
    super(data);
    data = toCamelCase(data);

    this.latitude = _get(data, 'latitude', 0);
    this.longitude = _get(data, 'bedrooms', 0);
    this.addresses = _get(data, 'addresses', []);
    this.suggestions = _get(data, 'suggestions', []);
  }

}
