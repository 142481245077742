<template>
  <div class="remotesupport">
    <header>
      <div class="row">
        <div class="col-sm-9">
          <h3 class="pageheader"><b>Send commands to remote devices</b></h3>
        </div>
      </div>
    </header>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">User id</label>
          <input type="text" class="col-sm-8 form-control" v-model="userid"/>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label">Command</label>
          <input type="text" class="col-sm-8 form-control" v-model="commandvalue"/>
        </div>
        <div class="form-group row">
          <div class="col-sm-12">
            <button type="submit" class="btn btn-primary float-end" @click.prevent="send" :disabled="!canSend">Send</button>
          </div>
        </div>
      </div>

      <div class="col-sm-8" v-if="commandlist?.length">
        <div class="table-responsive table-container">
          <table class="table table-hover">
            <thead>
              <tr>
                <th class="time">Time</th>
                <th class="user">User</th>
                <th class="command">Command</th>
                <th class="status">Status</th>
                <th class="action">Action</th>
              </tr>
            </thead>
            <tbody class="table-body-scroll">
              <tr v-for="(c, index) in commandlist" :key="index" @click="setCurrentcommand(c)" :class="{ selected: c.id === currentCommand?.id }">
                <td>{{ formatDateTime(c.createdAt) }}</td>
                <td>{{ c.userid }}</td>
                <td>{{ c.command }}</td>
                <td>{{ c.status }}</td>
                <td>
                  <button class="btn btn-danger btn-sm" @click.stop="deleteCommandItem(c.id)">Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <hr class="border border-secondary border-3">

    <div v-if="currentCommand?.idcopy">
      <div class="row">
        <div class="col-md-2">Time</div>
        <div class="col-md-10">{{ formatDateTime(currentCommand.createdAt) }}</div>
      </div>
      <div class="row" v-if="currentCommand.userid">
        <div class="col-md-2">User</div>
        <div class="col-md-10">{{ currentCommand.userid }}</div>
      </div>
      <div class="row" v-if="currentCommand.command">
        <div class="col-md-2">Command</div>
        <div class="col-md-10">{{ currentCommand.command }}</div>
      </div>
      <div class="row" v-if="currentCommand.status">
        <div class="col-md-2">Status</div>
        <div class="col-md-10">{{ currentCommand.status }}</div>
      </div>
      <div class="row" v-if="currentCommand.response">
        <div class="col-md-2">Response</div>
        <div class="col-md-10">
          <div v-if="isDatabaseResults">
            <div class="table-outer-container">
              <table class="table">
                <thead>
                  <tr>
                    <th v-for="(header, index) in parsedResponse[0]" :key="index">
                      {{ header }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(line, lineIndex) in parsedResponse.slice(1)" :key="lineIndex">
                    <td v-for="(item, itemIndex) in line" :key="itemIndex">
                      {{ item }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else-if="isExportCommand">
            <div v-for="(line, index) in responseLines" :key="index" class="response-line">
              <span>{{ line }}</span>
              <button v-if="isImageUrl(line)" @click="downloadImage(line)" class="btn btn-primary btn-sm">
                Download
              </button>
            </div>
          </div>
          <div v-else>
            <pre style="font-size: large;">{{ currentCommand.response }}</pre>
              
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, inject } from 'vue'
import { useStore } from 'vuex'
import { Remotecommand } from '@/models'
import Ably from "ably";

interface State {
  remotesupport: {
    currentcommand: Remotecommand;
    commandlist: Remotecommand[];
  }
}

const store = useStore();
const userid = ref('')
const commandvalue = ref('')

const realtime: Ably.Realtime = inject("realtime");
const channel = realtime.channels.get("act-app");

const currentCommand = computed<Remotecommand>(() => 
  store.getters['remotesupport/currentcommand'] || new Remotecommand()
)
const commandlist = computed<Remotecommand[]>(() => 
  store.getters['remotesupport/commandlist'] || []
)

// Computed properties
const isDatabaseResults = computed(() => 
  currentCommand.value?.command?.toLowerCase().startsWith("select") &&
  !currentCommand.value.response?.toLowerCase().includes("exception")
)

const isExportCommand = computed(() => 
  currentCommand.value?.command?.toLowerCase().startsWith("export")
)

const parsedResponse = computed(() => {
  if (!currentCommand.value?.response) return []
  return currentCommand.value.response.split('\n').map(line => line.split(','))
})

const responseLines = computed(() => 
  currentCommand.value?.response?.split('\n') || []
)

const canSend = computed(() => 
  Boolean(userid.value && commandvalue.value)
)

const setCurrentcommand = (command: Remotecommand) => {
  store.commit('remotesupport/setCurrentcommand', command)
}

const send = async () => {
  if (canSend.value) {
    const newCommand = new Remotecommand({ 
      userid: userid.value, 
      command: commandvalue.value 
    })
    try {
      const commandFromServer = await store.dispatch('remotesupport/addRemotecommand', newCommand)
      store.commit('remotesupport/addRemotecommand', commandFromServer)
      commandvalue.value = ''
      setCurrentcommand(commandFromServer)
    } catch (error) {
      console.error("Error sending command:", error)
      alert("Failed to send command. Please try again.")
    }
  }
}

const deleteCommandItem = async (commandId: string) => {
  if (confirm("Are you sure you want to delete this command?")) {
    try {
      await store.dispatch('remotesupport/deleteCommand', commandId)
      store.commit('remotesupport/removeRemotecommand', commandId)
      if (currentCommand.value.id === commandId) {
        setCurrentcommand(new Remotecommand())
      }
    } catch (error) {
      console.error("Error deleting command:", error)
      alert("Failed to delete command. Please try again.")
    }
  }
}

const isImageUrl = (url: string): boolean => {
  return /\.(jpeg|jpg|gif|png)$/i.test(url)
}

const getFullUrl = (url: string): string => {
  if (url.startsWith('http')) return url
  return `${process.env.VUE_APP_DOWNLOADS_URL || ''}${url}`
}

const downloadImage = (url: string) => {
  const fullUrl = getFullUrl(url)
  const fileName = url.split('/').pop() || 'image.jpg'

  fetch(fullUrl)
    .then(response => {
      if (!response.ok) throw new Error('Network response was not ok')
      return response.blob()
    })
    .then(blob => {
      const blobUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = blobUrl
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(blobUrl)
    })
    .catch(error => {
      console.error('Error downloading image:', error)
      alert('Failed to download the image. Please try again.')
    })
}

const formatDateTime = (date: string | Date | undefined): string => {
  if (!date) return ''
  const d = new Date(date)
  return d.toLocaleString()
}

const setupRealtimeChannel = () => {
  channel.subscribe("remotecommandresponse", async (message: any) => {
    if (message?.data?.id) {
      try {
        const updatedCommand = await store.dispatch('remotesupport/getRemotecommand', message.data.id)
        const index = commandlist.value.findIndex(c => c.idcopy === updatedCommand.id)
        if (index >= 0) {
          // Create a new array to trigger reactivity
          const newList = [...commandlist.value]
          newList[index] = updatedCommand
          if (currentCommand.value.id === updatedCommand.id) {
            setCurrentcommand(updatedCommand)
          }
        }
      } catch (error) {
        console.error("Error updating command:", error)
      }
    }
  })
}

onMounted(() => {
  setCurrentcommand(new Remotecommand())
  setupRealtimeChannel()
})
</script>

<style scoped lang="scss">
.table-container {
  max-height: 200px;
  overflow-y: auto;
}

.table-container table {
  width: 100%;
}

th {
  &.time { width: 7rem; }
  &.user { width: 7rem; }
  &.command { width: 10rem; }
  &.status { width: 5rem; }
  &.action { width: 5rem; }
}

.table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

td button.btn {
  padding: 0.25rem 0.5rem;
}

.response-line {
  margin-bottom: 0.5rem;
  word-break: break-all;
  display: flex;
  align-items: center;
  justify-content: normal;
}

.response-line span {
  margin-right: 0.5rem;
}

.table-outer-container {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 1rem;
}

.table {
  width: auto;
  min-width: 100%;
  
  th, td {
    padding: 8px 12px;
    border: 1px solid #dee2e6;
    min-width: 120px;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th {
    background-color: #f8f9fa;
    font-weight: bold;
  }

  .command-output {
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #dee2e6;
  font-family: monospace;
  margin: 0;
  max-height: 500px;
  overflow-y: auto;
}
}
</style>