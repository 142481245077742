<template>
  <div class="customers list">
    <div
      v-if="!customers.length && !hasBlockingRequests"
      class="alert alert-warning text-center"
    >
      <h2 class="mt-1 mb-2">No customers found</h2>
      <p class="mb-1">
        beginning with &ldquo;<strong>{{
          (route.params.search as string).toUpperCase()
        }}</strong
        >&rdquo;
      </p>
    </div>

    <table v-else class="table table-hover">
      <thead>
        <th width="7%"><span class="sr-only">Select</span></th>
        <th>Company</th>
        <th>Branch</th>
        <th>AC Client Code</th>
        <th>Nominal Code</th>
        <th>Region</th>
      </thead>
      <tbody>
        <tr v-for="customer in sortedcustomers" :key="customer.id">
          <td class="align-middle">
            <i
              v-if="selected(customer)"
              class="fas fa-check text-success fa-2x"
              @click="unselect(customer)"
            ></i>
            <i v-else class="fas fa-check fa-2x" @click="select(customer)"></i>
          </td>
          <td>{{ customer.companyName }}</td>
          <td>{{ customer.branchName }}</td>
          <td>{{ customer.acClientCode }}</td>
          <td>{{ customer.nominalCode }}</td>
          <td>{{ getRegion(customer.office) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { Customer, Office, EmailTemplate } from "@/models";
import { sortCustomerList } from "@/utilities";

const store = useStore();

const route = useRoute();

const hasBlockingRequests = computed(() => store.getters['app/hasBlockingRequests']);
const customers = computed(() => store.getters['customers/list']);
const emailtemplate = computed(() => store.getters['emailtemplates/current']);
const offices = computed(() => store.getters['offices/getOffices']);

const addClient = async (customerid: String) => {
  await store.dispatch('emailtemplates/addClient', customerid);
};

const removeClient = async (customerid: string) => {
  await store.dispatch('emailtemplates/removeClient', customerid);
};

const selected = (c: Customer): Customer | undefined => {
  let index = emailtemplate.value.clients.findIndex((clientid: string) => clientid === c.id);
  return index >= 0 ? c : undefined;
};

const select = (c: Customer) => {
  addClient(c.id);
};

const unselect = (c: Customer) => {
  let customer = selected(c);
  if (customer) removeClient(c.id);
};

const getRegion = (id: string): string => {
  let office: Office | undefined = offices.value.find((o: Office) => o.id == id);
  return office ? office.name : "";
};

const sortedcustomers = computed(() => sortCustomerList(customers.value));
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
