<template>
  <!-- "Migrate Report" Modal -->
  <div class="modal fade" id="report-migrate-modal" data-keyboard="true" tabindex="-1" role="dialog" aria-labelledby="report-migrate-modal-title" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">

        <div class="modal-header">
          <h5 class="modal-title" id="report-migrate-modal-title">
            Migrate Report
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="reset">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="alert alert-danger">
            <h4>Warning</h4>
            <p class="mb-2">
              If you provide a Report ID <strong>which has already been migrated</strong>, then the Report will be overwritten.
            </p>
            <p class="mb-0">
              Any existing migrated images will be overwritten too.
            </p>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Report ID</label>
            <div class="col-sm-9">
              <input class="form-control" v-model="reportRef" />
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary mr-auto" data-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" @click="migrate" >Migrate</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed, inject, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import { useRouter } from 'vue-router';

const instance = getCurrentInstance();
const store = useStore();
const router = useRouter();
const toasted = useToast();
const actProperty: any = inject('actProperty');

// Reactive State
const reportRef = ref('');

// Vuex Getters and Actions
const report = computed(() => store.getters['reports/current']);

const migrateReport = async (ref: string): Promise<string> => {
  return store.dispatch('reports/migrateReport', ref);
};

// Methods
const reset = () => {
  reportRef.value = '';
  instance?.proxy?.$forceUpdate();
};

const migrate = async () => {
  migrateReport(reportRef.value)
    .then((ref: string) => {
      toasted.success('Report migrated OK');
      router.push(`/reports/${ref}`);
    })
    .catch((err: any) => {
      actProperty.displayError(err)
    });
};

// Lifecycle Hooks
onMounted(() => {
  reset();
});
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
