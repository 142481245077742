import _get from 'lodash/get';

import { Base } from '@/models';
import { datetimeToUTC, toCamelCase } from '@/utilities';

export class Fee extends Base {

  public amount: string;
  public name: string;
  public paymentdate: string;
  public paid: boolean;

  /**
   * Constructor initialises the variables from the class Fee
   * @param data - the data to construct a type with all properties of <Fee>
   */
  public constructor(data?: Partial<Fee>) {
    super(data);
    data = toCamelCase(data);

    this.amount = _get(data, 'amount', '');
    this.name = _get(data, 'name', '');
    this.paymentdate = _get(data, 'paymentdate', datetimeToUTC());
    this.paid = _get(data, 'paid', false);
  }

}
