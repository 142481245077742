<template>
  <div class="containerx doc-container text-right" v-if="showdocument">
    <button
      class="btn btn-sm btn-danger mb-2"
      @click.stop="onDelete"
      alt="Delete"
    >
      <i class="fas fa-trash-alt fa-2x"></i>
      <span style="font-size: 1rem"> Delete</span>
    </button>
    <div class="row">
      <div class="col-sm-12 document-wrapper">
        <div class="pdf-document">
          <iframe
            v-if="floorplan && floorplan.src"
            :src="floorplan.src"
            style="height: 80vh; width: 100%; margin-bottom: 49px"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="container">
    <div class="row">
      <div class="col-sm-12 document-wrapper">
        <div>
          <i class="far fa-4x" :class="fileicon"></i>
        </div>
        <div class="button-group" style="z-index: 0" v-if="!readonly">
          <button class="btn btn-sm btn-danger" @click.stop="onDelete" style="padding: 0px 2px">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { Document } from "@/models";
import { defineEmits, defineProps,computed,PropType} from 'vue';

const props = defineProps({
  floorplan: { type: Document, required: false },
  showdocument: {type: Boolean as PropType<boolean>, default: false, required: false},
  readonly: {type: Boolean as PropType<boolean>, default: false, required: false},
});

const emit = defineEmits(['delete'])

const onDelete = (event: Event) => {
  emit("delete", props.floorplan);
};

const fileicon = computed(() => {
  let icon = 'fa-file';
  let filextension = props.floorplan?.src?.toLocaleLowerCase();
  if (filextension?.endsWith("pdf")) {
    icon = 'fa-file-pdf';
  } else if (filextension?.endsWith("doc") || filextension?.endsWith("docx")) {
    icon = 'fa-file-word';
  } else if (filextension?.endsWith("jpg") || filextension?.endsWith("png")) {
    icon = 'fa-image';
  } else if (filextension?.endsWith("msg") || filextension?.endsWith("eml")) {
    icon = 'fa-envelope';
  }
  return icon;
});

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/bootstrap/_variables.scss";

.file-preview {
  display: inline-block;
  vertical-align: top;
  margin: 0 0.5rem 0.5rem 0;
  width: 100px;
  position: relative;
  z-index: 0;
}

.document-wrapper {
  padding: 0px;
  position: relative;
}

.button-group {
  left: 0.15rem;
  position: absolute;
  top: 0.15rem;
  .btn + .btn {
    margin-left: 0.25rem;
  }
  z-index: 10000;
}
</style>