<template>
  <div>
    <singleselect-text
      v-model="selectedoption"
      :options="options"
      :allow-empty="true"
      :taggable="false"
      @select="onSelect"
      placeholder="Status"
    >
    <template #clear v-if="selectedoption">
      <i @mousedown.prevent.stop="onSelect('')"
        class="multiselect__clear fa fa-times"
        aria-label="Clear"
      ></i>
    </template>
    </singleselect-text>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

const store = useStore();
const router = useRouter();
const route = useRoute();

const options = ['All', 'Not Acknowledged', 'Acknowledged', 'In Progress', 'Upload Complete'];
const selectedoption = ref('');

const resetCurrentPage = async () => {
  await store.dispatch('reports/resetCurrentPage');
};

const onSelect = async (selected: string) => {
  let query = { ...route.query, dataentrystatus: selected === 'All' ? '' : selected };
  if (selected !== null) {
    selectedoption.value = selected;
    await router.push({
      path: "dereports",
      query,
    });
    await resetCurrentPage();
  }
};

// Watcher for the route query parameter 'dataentrystatus'
watch(() => route.query.dataentrystatus, (newVal, oldVal) => {
  if (typeof newVal === 'string') {
    selectedoption.value = newVal;
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
