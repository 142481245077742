<template>
  <div class="report">
    <div class="jumbotron">
      <h1 class="display-4">All saved!</h1>
      <p class="lead">
        Thank you for your comments. You will receive a confirmation email from
        us in a few minutes.
      </p>
      <p class="lead">
        We will then come back to you once your comments have been checked and
        verified.
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
</style>