import { API } from 'aws-amplify';

import { ActionTree, ActionContext } from 'vuex';
import { RootState } from '../types';
import { PropertyVisitManagementState } from './types';
import { PmTenancy } from '@/models';
/**
 * Actions
 *
 * Actions are similar to mutations, the differences being that:
 * - Instead of mutating the state, actions commit mutations.
 * - Actions can contain arbitrary asynchronous operations.
 * - Actions are triggered with the store.dispatch method e.g. `store.dispatch('getSettings')`
 */
export const actions: ActionTree<PropertyVisitManagementState, RootState> = {

  /**
   * Get all Tenancies
   * 
   * @param {PropertyVisitManagementState} store 
   * @param filters 
   * @returns All Tenancies
   */
  async getTenancies(store: ActionContext<PropertyVisitManagementState, any>, data: any): Promise<PmTenancy[]> {
    store.commit('app/addRequest', 'getTenancies', {root: true});
    const options = {
      queryStringParameters: {
        startdate: data.startdate,
        enddate:data.enddate  
      },
    };
    return API.get('RestAPI', '/pmtenancies', options)
      .then((response) => response.map((x: Partial<PmTenancy>) => new PmTenancy(x)))
      .then((tenancies: PmTenancy[]) => {
        store.commit('setTenancies', tenancies);
        return tenancies;
      })
      .finally(() => store.commit('app/removeRequest', 'getTenancies', { root: true }));
  },

};
