import { copyInto } from '@/utilities';

export class EmailSettings {

  public smsactive: boolean;

  /**
   * Create an object from a JSON representation
   * @param data
   * @return CustomerAccountSettings
   */
  public static parse(data?: Partial<EmailSettings>) {
    const obj = new EmailSettings;
    copyInto(obj, data);

    return obj;
  }

  /**
   * Constructor
   */
  public constructor() {
    this.smsactive = false;
  }

}
