import _get from "lodash/get";

import { Base } from "@/models";
import { toCamelCase } from "@/utilities";

export class Keysreturn extends Base {
  public returntype: string;
  public name: string;
  public description: string;
  public photos: string[];

  /**
   * Constructor initialises the variables from the class Keysreturn
   * @param data - the data to construct a type with all properties of <Keysreturn>
   */
  public constructor(data?: Partial<Keysreturn>) {
    super(data);
    data = toCamelCase(data);

    this.returntype = _get(data, "returntype", "");
    this.name = _get(data, "name", "");
    this.photos = _get(data, "photos", []);
    this.description = _get(data, "description", "");
  }
}
