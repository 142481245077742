<template>
    <div class="dictionary section-type-selector">
  
      <div class="card bg-light mb-3">
        <div class="card-body">
          <div class="row">
  
            <div class="col-3" v-if="showSections">
              <h2>Section</h2>
              <multiselect
                v-model="activeSection"
                track-by="slug"
                :options="allSections">
                <template v-if="hasActiveSection"
                    #clear>
                  <i
                    @mousedown.prevent.stop="unsetActiveSection"
                    class="multiselect__clear fa fa-times"
                    aria-label="Clear Job Type"
                  ></i>
                </template>
              </multiselect>
              <!--button class="btn btn-link pb-0" :class="{'text-muted': !hasActiveSection}" @click="unsetActiveSection">
                Clear
              </button-->
            </div>
  
            <div class="col-3" v-if="showTypes && hasActiveSection">
              <button class="btn btn-outline-secondary float-right" data-toggle="modal" data-target="#dictionary-types-modal">
                <i class="fas fa-ellipsis-h"></i>
              </button>
              <h2>Type</h2>
              <multiselect
                v-model="activeType"
                track-by="slug"
                :options="currentTypes">
                <template v-if="hasActiveType"
                    #clear>
                  <i
                    @mousedown.prevent.stop="unsetActiveType"
                    class="multiselect__clear fa fa-times"
                    aria-label="Clear Job Type"
                  ></i>
                </template>
                <template #singleLabel="props">
                  <div class="row">
                    <div>
                      <i
                        v-if="props.option.fixflocategory && props.option.fixflosubcategory"
                        class="fa fa-check success"
                      ></i>
                    </div>
                    <div class="col-md-10">{{ props.option.getSystemName() }}</div>
                  </div>
                </template>
                <template #option="props">
                  <div class="row">
                    <div>
                      <i
                        v-if="props.option.fixflocategory && props.option.fixflosubcategory"
                        class="fa fa-check success"
                      ></i>
                    </div>
                    <div class="col-md-10">{{ props.option.getSystemName() }}</div>
                  </div>
                </template>
                <!-- <template #multiselect="props">
                  <div>
                   <i
                      v-if="props.model && props.model.fixflocategory && props.model.fixflosubcategory"
                      class="fa fa-check success"
                   ></i>
                 </div>
                </template> -->
              </multiselect>
              <singleselect-text
                v-if="hasActiveType"
                class="mt-2"
                v-model="fixfloCategoryForType"
                :options="fixfloCategoryOptions">
                <template v-if="fixfloCategoryForType"
                    #clear>
                  <i
                    @mousedown.prevent.stop="fixfloCategoryForType = ''"
                    class="multiselect__clear fa fa-times"
                    aria-label="Clear Job Type"
                  ></i>
                </template>
              </singleselect-text>
              <singleselect-text
                class="mt-2"
                v-if="fixfloCategoryForType"
                v-model="fixfloSubCategoryForType"
                :options="fixfloSubCategoryOptions">
                <template v-if="fixfloSubCategoryForType"
                    #clear>
                  <i
                    @mousedown.prevent.stop="fixfloSubCategoryForType = ''"
                    class="multiselect__clear fa fa-times"
                    aria-label="Clear Job Type"
                  ></i>
                </template>
              </singleselect-text>
              <!-- <button class="btn btn-link pb-0" :class="{'text-muted': !hasActiveType}" @click="unsetActiveType">
                Clear
              </button> -->
            </div>
  
            <div class="col-6" v-if="showItems && hasActiveType">
              <button class="btn btn-outline-secondary float-right" data-toggle="modal" data-target="#dictionary-items-modal">
                <i class="fas fa-ellipsis-h"></i>
              </button>
              <h2>Item</h2>
              <multiselect
                v-model="activeItem"
                track-by="slug"
                :options="currentItems">
                <template v-if="hasActiveItem"
                    #clear>
                  <i
                    @mousedown.prevent.stop="unsetActiveItem"
                    class="multiselect__clear fa fa-times"
                    aria-label="Clear Job Type"
                  ></i>
                </template>
                <template #singleLabel="props">
                  <div class="row">
                    <div>
                      <i
                        v-if="props.option.fixflocategory && props.option.fixflosubcategory"
                        class="fa fa-check success"
                      ></i>
                    </div>
                    <div class="col-md-8">{{ props.option.name}}</div>
                  </div>
                </template>
                <template #option="props">
                  <div class="row">
                    <div>
                      <i
                        v-if="props.option.fixflocategory && props.option.fixflosubcategory"
                        class="fa fa-check success"
                      ></i>
                    </div>
                    <div class="col-md-8">{{ props.option.name }}</div>
                  </div>
                </template>
              </multiselect>
              <singleselect-text
                v-if="hasActiveItem"
                class="mt-2"
                v-model="fixfloCategoryForItem"
                :options="fixfloItemCategoryOption">
                <template v-if="fixfloCategoryForItem"
                    #clear>
                  <i
                    @mousedown.prevent.stop="fixfloCategoryForItem = ''"
                    class="multiselect__clear fa fa-times"
                    aria-label="Clear Job Type"
                  ></i>
                </template>
              </singleselect-text>
              <singleselect-text
                class="mt-2"
                v-if="fixfloCategoryForItem"
                v-model="fixfloSubCategoryForItem"
                :options="fixfloSubCategoryOption">
                <template v-if="fixfloSubCategoryForItem"
                    #clear>
                  <i
                    @mousedown.prevent.stop="fixfloSubCategoryForItem = ''"
                    class="multiselect__clear fa fa-times"
                    aria-label="Clear Job Type"
                  ></i>
                </template>
              </singleselect-text>
              <!-- <button class="btn btn-link pb-0" :class="{'text-muted': !hasActiveItem}" @click="unsetActiveItem">
                Clear
              </button> -->
            </div>
  
          </div>
        </div>
      </div>
  
      <!-- Modals -->
      <DictionaryTypesModal />
      <DictionaryItemsModal />
  
    </div>
  </template>
  
  <script lang="ts" setup>
  import { computed, ref, defineProps } from 'vue';
  import { useStore } from 'vuex';
  import DictionaryTypesModal from '@/components/modals/DictionaryTypesModal.vue';
  import DictionaryItemsModal from '@/components/modals/DictionaryItemsModal.vue';
  
  import fixflocategories from '@/store/dictionary/json/fixflocategories.json';
  
  // Models can remain the same
  import { DictionarySection, DictionaryType, DictionaryItem } from '@/models';
  import { DictionaryState } from '@/store/dictionary/types';
  
  // Define props with type annotations
  const props = defineProps<{
    showSections: boolean;
    showTypes: boolean;
    showItems: boolean;
  }>();
  
  const store = useStore();
  
  // Computed properties for getting state values
  const dictionaryState = computed(() => store.state.dictionary as DictionaryState);
  
  // Computed properties with getters and setters for activeSection, activeType, and activeItem
  const activeSection = computed<DictionarySection>({
    get: () => dictionaryState.value.activeSection,
    set: (val: DictionarySection) => {
      store.commit('dictionary/setActiveSection', val);
      unsetActiveType();
    },
  });
  
  const activeType = computed<DictionaryType>({
    get: () => dictionaryState.value.activeType,
    set: (val: DictionaryType) => {
      store.commit('dictionary/setActiveType', val);
      unsetActiveItem();
    },
  });
  
  const activeItem = computed<DictionaryItem>({
    get: () => dictionaryState.value.activeItem,
    set: (val: DictionaryItem) => {
      store.commit('dictionary/setActiveItem', val);
    },
  });
  
  // Methods are simply converted to functions
  function unsetActiveSection() {
    store.commit('dictionary/setActiveSection', new DictionarySection());
    unsetActiveType();
  }
  
  function unsetActiveType() {
    store.commit('dictionary/setActiveType', new DictionaryType());
    unsetActiveItem();
  }
  
  function unsetActiveItem() {
    store.commit('dictionary/setActiveItem', new DictionaryItem());
  }
  
  function getSystemName(type: DictionaryType): string {
    return type.getSystemName();
  }
  
  // Computed properties for the getters
  const allSections = computed(() => store.getters['dictionary/allSections'] as DictionarySection[]);
  const currentTypes = computed(() => store.getters['dictionary/currentTypes'] as DictionaryType[]);
  const currentItems = computed(() => store.getters['dictionary/currentItems'] as DictionaryItem[]);
  
  const hasActiveSection = computed(() => store.getters['dictionary/hasActiveSection'] as boolean);
  const hasActiveType = computed(() => store.getters['dictionary/hasActiveType'] as boolean);
  const hasActiveItem = computed(() => store.getters['dictionary/hasActiveItem'] as boolean);
  
  //for activeType
  const fixfloCategoryForType = computed({
    get: () => {
      return activeType.value.fixflocategory;
    },
    set:(val: string) => {
      activeType.value.fixflocategory = val;
      activeType.value.fixflosubcategory = '';
    }
  });
  const fixfloSubCategoryForType = computed({
    get: () => {
      return activeType.value.fixflosubcategory;
    },
    set:(val: string) => {
      activeType.value.fixflosubcategory = val;
    }
  });
  
  const fixfloCategoryOptions = computed(() => {
    return fixflocategories.map((c: any) => c.name).sort();
  });
  const fixfloSubCategoryOptions = computed(() => {
    const index = fixflocategories.findIndex((c: any) => c.name === fixfloCategoryForType.value);
    var list: string[] = [];
    if(index >= 0) {
      list = fixflocategories[index].subcategories;
    }
    return list.sort();
  });
  
  //for activeItem
  const fixfloCategoryForItem = computed({
    get: () => {
      return activeItem.value.fixflocategory;
    },
    set:(val: string) => {
      activeItem.value.fixflocategory = val;
      activeItem.value.fixflosubcategory = '';
    }
  });
  
  const fixfloSubCategoryForItem = computed({
    get: () => {
      return activeItem.value.fixflosubcategory;
    },
    set:(val: string) => {
      activeItem.value.fixflosubcategory = val;
    }
  });
  
  const fixfloItemCategoryOption = computed(() => {
    return fixflocategories.map((c: any) => c.name).sort();
  });
  const fixfloSubCategoryOption = computed(() => {
    const index = fixflocategories.findIndex((c: any) => c.name === fixfloCategoryForItem.value);
    var list: string[] = [];
    if(index >= 0) {
      list = fixflocategories[index].subcategories;
    }
    return list.sort();
  });
  
  </script>
  
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  .btn-sm {
    font-size: 0.4rem;
  }
  .list-group-item {
    cursor: pointer;
  }
  .list-group-item-active {
    .list-group-sections & { background-color: #42A5F5; }
    .list-group-types & { background-color: #9575CD; }
    .list-group-items & { background-color: #FFB74D; }
  }
  .list-group {
    max-height: 155px;
    overflow: scroll;
  }
  .highlight {
    border-color: red;
  }
  .text-muted {
    opacity: 0.5;
  }

  .fa-check.success {
    position: relative;
    left: 10px;
    color: green;
}
  </style>
  