import { API } from 'aws-amplify';

import { ActionTree, ActionContext } from 'vuex';
import { RootState } from '../types';
import { OfficesState } from './types';

import { Office } from '@/models';

/**
 * Actions
 *
 * Actions are similar to mutations, the differences being that:
 * - Instead of mutating the state, actions commit mutations.
 * - Actions can contain arbitrary asynchronous operations.
 * - Actions are triggered with the store.dispatch method e.g. `store.dispatch('getSettings')`
 */
export const actions: ActionTree<OfficesState, RootState> = {

  /**
   * Get all Offices
   * 
   * @param {OfficesState} store 
   * @param filters 
   * @returns All Offices
   */
  async getOffices(store: ActionContext<OfficesState, any>, filters: any): Promise<Office[]> {
    store.commit('app/addRequest', 'getOffices', {root: true});

    return API.get('RestAPI', '/offices', {})
      .then((response) => response.map((x: Partial<Office>) => new Office(x)))
      .then((offices: Office[]) => {
        store.commit('setOffices', offices);
        return offices;
      })
      .finally(() => store.commit('app/removeRequest', 'getOffices', { root: true }));
  },

};
