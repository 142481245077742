import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

export class PiRate extends Base {

  public id: string;
  public propertysize: string;
  public inventory: number;
  public ci: number;
  public co: number;
  public combo: number;

  public constructor(data?: Partial<PiRate>) {
    super(data);
    data = toCamelCase(data);

    this.id = _get(data, 'id', '');
    this.propertysize = _get(data, 'propertysize', '');
    this.inventory = _get(data, 'inventory', 0.0);
    this.ci = _get(data, 'ci', 0.0);
    this.co = _get(data, 'co', 0.0);
    this.combo = _get(data, 'combo', 0.0);
  }
}