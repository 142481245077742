<template>
  <!-- "New Item" Modal -->
  <div
    class="modal fade"
    id="report-item-modal"
    data-keyboard="true"
    tabindex="-1"
    role="dialog"
    aria-labelledby="add-room-item-modal-label"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      :class="{
        'modal-xl': showXtraLargeModal,
        'modal-lg': !showXtraLargeModal,
      }"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="add-room-item-modal-label">New Item</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <fieldset>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Room</label>
              <div class="col-sm-9">
                <multiselect
                  v-model="room"
                  track-by="slug"
                  label="name"
                  :options="roomOptions"
                >
                </multiselect>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Section</label>
              <div class="col-sm-9">
                <multiselect
                  v-model="section"
                  :options="sectionOptions"
                  :disabled="!room.slug"
                  :taggable="true"
                  group-values="options"
                  group-label="label"
                  :group-select="false"
                  track-by="slug"
                  label="name"
                  @tag="newSection"
                >
                </multiselect>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Type</label>
              <div class="col-sm-9">
                <multiselect
                  v-model="type"
                  :options="typeOptions"
                  :disabled="!section.slug"
                  :taggable="true"
                  track-by="slug"
                  label="name"
                  @tag="newType"
                >
                </multiselect>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Item</label>
              <div class="col-sm-9">
                <multiselect
                  v-model="item"
                  :options="itemOptions"
                  :disabled="!type.slug"
                  :taggable="true"
                  track-by="slug"
                  label="name"
                  @tag="newItem"
                  @select="onSelectedItem"
                >
                </multiselect>
              </div>
            </div>
            <div class="form-group row" v-if="isDataentry">
              <label class="col-sm-3 col-form-label"></label>
              <div class="col-sm-9">
                <textarea
                  v-model="description"
                  class="form-control"
                  rows="4"
                ></textarea>
              </div>
            </div>
            <div
              class="form-group row"
              v-if="!actProperty.isFactoryreport(report.type)"
            >
              <label class="col-sm-3 col-form-label">Quantity</label>
              <div class="col-sm-4">
                <input
                  type="number"
                  class="form-control"
                  v-model.number="qty"
                  min="0"
                  :disabled="!type.name"
                />
              </div>
            </div>
            <div
              class="form-group row"
              v-if="!actProperty.isFactoryreport(report.type)"
            >
              <label class="col-sm-3 col-form-label">Colour</label>
              <div class="col-sm-9">
                <multiselect-text
                  v-model="colour"
                  :options="colourOptions"
                  :disabled="!type.name"
                  :taggable="true"
                  @tag="newColour"
                >
                </multiselect-text>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Condition</label>

              <div
                class="col-sm-9"
                v-if="isDataentry && report.type !== 'checkout'"
              >
                <div class="row" v-if="localconditionlist.length">
                  <div class="col-sm-12">
                    <label
                      class="col-form-label"
                      v-if="!actProperty.isFactoryreport(report.type)"
                      >Check-In</label
                    >
                    <multiselect-text
                      v-model="conditionInNotes"
                      :options="localconditionlist"
                    >
                    </multiselect-text>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <textarea
                      v-model="conditionInNotes"
                      class="form-control"
                      rows="4"
                      :disabled="!type.name"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div class="col-sm-9" v-else>
                <div class="row">
                  <div class="col-sm-6">
                    <label
                      class="col-form-label"
                      v-if="!actProperty.isFactoryreport(report.type)"
                      >Check-In</label
                    >
                    <textarea
                      v-model="conditionInNotes"
                      class="form-control"
                      rows="4"
                      :disabled="!type.name"
                    ></textarea>
                  </div>
                  <div class="col-sm-6">
                    <div v-if="report.type === 'checkout'">
                      <label class="col-form-label">Check-Out</label>
                      <textarea
                        v-model="conditionOutNotes"
                        class="form-control"
                        rows="4"
                        :disabled="!type.name"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary mr-auto"
            data-dismiss="modal"
            @click.prevent="reset()"
          >
            Back
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            @click.prevent="add()"
            :disabled="!canAdd()"
          >
            Add item
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch, inject, nextTick } from 'vue';
import { snakeCase, get } from 'lodash';
import { useStore } from 'vuex';
import {
  Condition,
  Dictionary,
  DictionaryItem,
  DictionaryRoom,
  DictionarySection,
  DictionaryType,
  Item,
  Note,
  Report,
  Room,
  Section,
  Type,
} from "@/models";
import { useToast } from 'vue-toastification';
import { DictionaryState } from '@/store/dictionary/types';

/**
 * A common format for Vue Multiselect Options
 */
 class SelectOption {
  name: string;
  slug: string;
}

/**
 * An Option Group for Vue Multiselect Options
 */
class SelectGroup {
  label: string;
  options: SelectOption[];
}

const store = useStore();
const toasted = useToast();
const actProperty: any = inject('actProperty');

const dictionaryState = computed(() => store.state.dictionary);
const customerdictionaryState = computed(() => store.state.customerdictionary);
const isDataentry = computed(() => store.getters['auth/isDataentry']);
const allColours = computed(() => store.getters['dictionary/allColours']);
const report = computed((): Report => store.getters['reports/current']);
const currentReportRooms = computed((): Room[] => store.getters['reports/currentReportRooms']);
const currentroom = computed(() => store.getters['reports/currentroom']);

const room = ref(new SelectOption());
const section = ref(new SelectOption());
const type = ref(new SelectOption());
const item = ref(new Item());
const description = ref('');
const qty = ref(0);
const colour = ref('');
const localconditionlist = ref<string[]>([]);

const conditionInNotes = ref('');
const conditionOutNotes = ref('');

watch(currentroom, () => {
  room.value = {
    name: currentroom.value.name,
    slug: currentroom.value.type,
  };
});

const dicState = computed((): DictionaryState => {
  let state = dictionaryState.value;
  if (actProperty.isFactoryreport(report.value.type))
    state = customerdictionaryState.value;
  return state;
});

const setReportDeep = async (payload: { path: string; data: any }) => {
  await store.dispatch('reports/setReportDeep', payload);
};

const addItem = async (data: {
  room: SelectOption;
  section: SelectOption;
  type: SelectOption;
  item: Item;
}) => {
  await store.dispatch('reports/addItem', data);
};

const onSelectedItem = (val: SelectOption) => {
  itemDescription.value = val.name ? val.name : itemDescription.value;
};

const setItemDescription = ($event: any) => {
  itemDescription.value = $event.target.value;
};

const itemDescription = computed({
  get: () => description.value,
  set: (val: string) => {
    description.value = val;
  }
});

const showXtraLargeModal = computed((): boolean => {
  return isDataentry.value;
});

const roomOptions = computed(() => {
  return currentReportRooms.value.map((room) => ({
    name: room.name,
    // We use Room.type as SelectOption.slug, because there is no Room.slug
    slug: room.type,
  }));
});

const sectionOptions = computed(() => {
  const room = getSelectedDictionaryRoom();
  if (room === null) {
    return [];
  }

  item.value = new Item();
  qty.value = 0;
  colour.value = "";
  conditionInNotes.value = "";
  conditionOutNotes.value = "";

  const selectedReportRoom = currentReportRooms.value.find(x => x.type === room.slug);
  let thisReportOptions: SelectOption[] = [];

  if (selectedReportRoom) {
    thisReportOptions = selectedReportRoom.sections.map(section => ({
      name: section.name,
      slug: section.slug,
    }));
  }

  const sectionSlugs = getSectionSlugsForDictionaryRoom(room); // Assuming this is a separate function

  const dictionarySections = dicState.value.current.sections.filter(dictionarySection => {
    const isCorrectCategory = sectionSlugs.indexOf(dictionarySection.slug) > -1;
    const notInSelectedRoom = thisReportOptions.every(option => option.slug !== dictionarySection.slug);

    return isCorrectCategory && notInSelectedRoom;
  });

  return [
    {
      label: "Existing",
      options: thisReportOptions,
    },
    {
      label: "New",
      options: dictionarySections.map(dictionarySection => ({
        name: dictionarySection.name,
        slug: dictionarySection.slug,
      })),
    },
  ];
});

const getSelectedDictionaryRoom = (): DictionaryRoom | null => {
  if (undefined === room.value.slug) {
    return null;
  }

  // find the room user has selected
  const roomIndex = dicState.value.current.rooms.findIndex(
    (dictionaryRoom: DictionaryRoom) => {
      return dictionaryRoom.slug === room.value.slug;
    }
  );

  if (-1 === roomIndex) {
    return null;
  }

  return dicState.value.current.rooms[roomIndex];
};

const getSectionSlugsForDictionaryRoom = (dictionaryRoom: DictionaryRoom): string[] => {
  type CategorySections = {
    [key: string]: string[];
  };

  const categoryKey = `category_${dictionaryRoom.category}`;

  const roomCategorySections: CategorySections = {
    category_1: [
      "doors",
      "ceiling",
      "walls",
      "woodwork",
      "flooring",
      "windows",
      "curtains_blinds",
      "furniture_decorative_items",
      "furniture_bedroom",
      "furniture_office",
      "electrical_items",
      "overview",
      "misc",
    ],
    category_2: [
      "doors",
      "ceiling",
      "walls",
      "woodwork",
      "flooring",
      "windows",
      "curtains_blinds",
      "furniture_decorative_items",
      "furniture_bedroom",
      "furniture_office",
      "electrical_items",
      "furniture_kitchen",
      "appliances",
      "units",
      "sink",
      "worktop",
      "worktop_item",
      "overview",
      "misc",
    ],
    category_3: [
      "doors",
      "ceiling",
      "walls",
      "woodwork",
      "flooring",
      "windows",
      "curtains_blinds",
      "furniture_decorative_items",
      "electrical_items",
      "furniture_bathroom",
      "appliances",
      "sanitary_items",
      "overview",
      "misc",
    ],
    category_4: [
      "doors",
      "ceiling",
      "walls",
      "flooring",
      "windows",
      "garden_structures",
      "garden_items",
      "garden_furniture",
      "overview",
      "misc",
    ],
  };

  return roomCategorySections[categoryKey] || [];
};

const getRoomCategory = (room: Room): number | false => {
  const roomCategories: { [key: string]: number } = {
    entrance: 1,
    entrance_hallway: 1,
    landing: 1,
    stairs: 1,
    stairs_and_landing: 1,
    stairs_and_landing_basement: 1,
    bedroom: 1,
    dining_room: 1,
    lounge: 1,
    lounge_diner: 1,
    reception: 1,
    airing_cupboard: 1,
    conservatory: 1,
    cupboard: 1,
    dressing_room: 1,
    external: 1,
    hallway: 1,
    inner_hallway: 1,
    larder: 1,
    loft: 1,
    mezzanine: 1,
    office: 1,
    other: 1,
    outside: 1,
    store_cupboard: 1,
    study: 1,
    vestibule: 1,
    basement: 2,
    kitchen: 2,
    kitchen_diner: 2,
    shower_room: 2,
    studio: 2,
    utility: 2,
    cellar: 2,
    sun_room: 2,
    utility_room: 2,
    bathroom: 3,
    ensuite: 3,
    toilet: 3,
    cloakroom: 3,
    nursery_room: 3,
    wc: 3,
    balcony: 4,
    bike_store: 4,
    bin_store: 4,
    driveway: 4,
    front_of_property: 4,
    garage: 4,
    garden: 4,
    greenhouse: 4,
    key_exchange: 4,
    outhouse: 4,
    pantry: 4,
    parking: 4,
    pool_house: 4,
    porch: 4,
    rear_garden: 4,
    rear_lobby: 4,
    rear_of_property: 4,
    shed: 4,
    stables: 4,
    summer_house: 4,
    swimming_pool: 4,
    terrace: 4,
    workshop: 4,
  };

  const roomCategory = roomCategories[room.type];
  if (roomCategory && Number.isInteger(roomCategory)) {
    return roomCategory;
  }

  return 1;
};

const getFilteredTypes = (types: DictionaryType[], roomCategory: number): DictionaryType[] => {
  var filteredTypes: DictionaryType[] = [];
  const roomIsInternal = [1, 2, 3].includes(roomCategory);
  const roomIsExternal = roomCategory === 4;

  // Internal and external variants
  const internalVariants = [
    "internal_door_type",
    "internal_lock",
    "internal_decor",
    "internal_lighting",
    "internal_electric",
    "internal_fixtures_and_fittings",
    "type",
    "internal_appliances_type",
  ];
  const externalVariants = [
    "external_door_type",
    "external_lock",
    "decor_external",
    "external_lighting",
    "external_electric",
    "external_fixtures_and_fittings",
    "type_external",
    "external_appliances_type",
  ];

  const property = roomIsInternal ? "internal" : roomIsExternal ? "external" : null;
  if (null === property) {
    return [];
  }

  if (roomIsInternal) {
    // Filter out all of the external types from the list of types if room is internal
    types.forEach((type) => {
      if (!externalVariants.includes(type.slug)) {
        filteredTypes.push(type);
      }
    });
  } else if (roomIsExternal) {
    // Filter out all of the internal types from the list of types if room is external
    types.forEach((type) => {
      if (!internalVariants.includes(type.slug)) {
        filteredTypes.push(type);
      }
    });
  }

  return filteredTypes;
};

const newSection = (name: string): void => {
  section.value = new SelectOption();
  section.value.name = name;
  section.value.slug = snakeCase(name);
};

const newType = (name: string): void => {
  type.value = new SelectOption();
  type.value.name = name;
  type.value.slug = snakeCase(name);
};

const newItem = (name: string): void => {
  item.value = new Item({
    name: name,
    slug: snakeCase(name),
  });
};

const typeOptions = computed(() => {
  const room = getSelectedDictionaryRoom();

  if (room === null || section.value.name === undefined) {
    return [];
  }

  type.value = new SelectOption();
  item.value = new Item();
  qty.value = 0;
  colour.value = "";
  conditionInNotes.value = "";
  conditionOutNotes.value = "";

  let sectionIndex = dicState.value.current.sections.findIndex(s => s.slug === section.value.slug);

  if (sectionIndex === -1) {
    const r = report.value.rooms.find(x => x.name === room.name);
    if (r) {
      const s = r.sections.find(x => x.name === section.value.name);
      if (s) {
        return s.types.map(t => ({ name: t.name, slug: t.slug }));
      }
    }
    return [];
  }

  const dictionarySection = dicState.value.current.sections[sectionIndex];
  const filteredTypes = getFilteredTypes(dictionarySection.types, room.category);

  return filteredTypes.length > 0 ? filteredTypes : dictionarySection.types;
});

const itemOptions = computed(() => {
  const room = getSelectedDictionaryRoom(); // Same assumption as above

  if (
    room === null ||
    section.value.name === undefined ||
    type.value.name === undefined
  ) {
    return [];
  }

  let dictionarySection = dicState.value.current.sections.find(x => x.slug === section.value.slug);
  if (!section.value) {
    dictionarySection = dicState.value.current.sections.find(x => x.slug === "misc");
  }

  if (!dictionarySection) {
    throw new Error('Unable to find "Misc" Section in the Dictionary');
  }

  let dictionaryType = dictionarySection.types.find(x => x.slug === type.value.slug);
  if (!type.value) {
    dictionaryType = dictionarySection.types.find(x => x.slug === "misc");
  }

  if (!dictionaryType) {
    throw new Error('Unable to find "Misc" Type in the Dictionary');
  }

  return dictionaryType.items.map(item => ({ name: item.name, slug: item.slug }));
});

const colourOptions = computed((): string[] => {
  return allColours.value;
});

const newColour = (val: string): void => {
  colour.value = val;
}

const reset = () => {
  if (actProperty.isFactoryreport(report.value.type)) {
    room.value = new SelectOption();
    section.value = new SelectOption();
    type.value = new SelectOption();
  }
  item.value = new Item();
  qty.value = 0;
  colour.value = "";
  conditionInNotes.value = "";
  conditionOutNotes.value = "";
  description.value = "";
};

const canAdd = () => {
  return (
    get(room.value, "name.length") &&
    get(section.value, "name.length") &&
    get(type.value, "name.length") &&
    (get(item.value, "name.length") || description.value)
  );
};

const add = async () => {
  let itemname = item.value.name;
  if (itemDescription.value) {
    itemname = itemDescription.value;
  }

  if (conditionInNotes.value) {
    const index = localconditionlist.value.findIndex(c => c === conditionInNotes.value);
    if (index < 0) localconditionlist.value.push(conditionInNotes.value);
  }

  const inputData = {
    room: room.value,
    section: section.value,
    type: type.value,
    item: new Item({
      _uuid: item.value._uuid,
      name: itemname,
      slug: item.value.slug,
      colour: colour.value,
      qty: qty.value,
      condition: {
        responsibility: [],
        previousresponsibility: [],
        in: new Condition({
          notes: conditionInNotes.value.split("\n").map(x => new Note({ note: x })),
        }),
        out: new Condition({
          notes: conditionOutNotes.value.split("\n").map(x => new Note({ note: x })),
        }),
      },
      locallyadded: actProperty.isFactoryreport(report.value.type),
    }),
  };

  addItem(inputData)
    .then(async () => await nextTick())
    .then(() => scrollToElement(`#item-${inputData.item._uuid}`))
    .then(() => toasted.success("Item added"))
    .then(() => reset())
    .catch((err: any) => actProperty.displayError(err));
};

const scrollToElement = (selector: string) => {
  const element = document.querySelector(selector);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
