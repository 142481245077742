<template>
  <div class="user">
    <div class="row">
      <div class="col-1" style="text-align: start;">
        <i class="fas fa-arrow-left fa-2x" @click="back()"></i>
      </div>
      <h1 class="col-md-10">
        <h1 v-if="user.name">{{user.name}}</h1>
        <h1 v-else-if="user.email">{{user.email}}</h1>
        <h1 v-else>New user</h1>
      </h1> 
    </div>

    <!-- Tabs -->
    <div>
      <ul class="nav nav-tabs mb-3" role="tablist">
        <li class="nav-item">
          <router-link to="userdetails" class="nav-link" active-class="active">
            Details
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="customerallocations" class="nav-link" active-class="active">
            Customers
          </router-link>
        </li>
      </ul>
    </div>

    <!-- Tab content (child routes) -->
    <router-view :key="$route.name" style="padding-bottom: 60px" />

    <!-- Status Bar (Bottom navbar) -->
    <nav class="navbar fixed-bottom navbar-dark bg-primary">
      <div class="container-fluid">
        <!-- Status Bar Actions -->
        <div id="status-bar-actions" style="margin-left: auto">
          <div class="text-light">
            <span v-if="hasUnsavedChanges">You have unsaved changes</span>
            <span v-else class="text-muted">No unsaved changes</span>
            <div class="btn-group" role="group" aria-label="Secondary Actions">
              <button class="btn btn-outline-light" :class="{ disabled: !hasUnsavedChanges }" @click.prevent="cancel()" :disabled="!hasUnsavedChanges">
                <i class="fas fa-ban"></i> Cancel
              </button>
            </div>
            <div class="btn-group" role="group" aria-label="Primary Actions">
              <button class="btn btn-light" :class="{ disabled: !hasUnsavedChanges }" @click.prevent="saveAll()"
                :disabled="isSaving || !hasUnsavedChanges">
                <span v-if="isSaving" class="spinner-border spinner-border-sm">
                  <span class="sr-only">Loading...</span>
                </span>
                <i v-else class="fas fa-save"></i>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, inject, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { User} from '@/models';
import _ from 'lodash';
import { useToast } from "vue-toastification";

const actProperty: any = inject('actProperty');

const router = useRouter();
const route = useRoute();
const store = useStore();
const toasted = useToast();

const unsavedChanges = ref(false);
const isSaving = ref(false);
const toast = useToast();
const hasUnsavedChanges = computed(() => store.getters['usermanagement/hasUnsavedChanges']);

const user = computed((): User => store.getters['usermanagement/current']);
const getUser = async (email: string): Promise<User> => {
  return store.dispatch('usermanagement/getUser', email);
};
const setUser = async (user: User) => {
  return store.commit('usermanagement/setCurrentUser', user);
};
const saveUser = async (user: User): Promise<void> => {
  return store.dispatch('usermanagement/saveUser', user)
};
const getCognitouser = async (sub: any) => {
  return store.dispatch('sysadmin/getUser', sub)
}
const resetUnsavedChanges = async () => {
  return store.commit('usermanagement/resetUnsavedChanges');
};


onMounted(async () => {
  const cognitouser = await getCognitouser(router.currentRoute.value.params.sub);
  let email = '';
  if(cognitouser) {
    email = cognitouser.findAttributeValue('email');
    if(email) {
      getUser(email)
      .then(async (u: User) => {
        if(u?.id) {
          setUser(u);
        }
        else {
          addNewUser();
        }
      })
      .catch((err: any) => {
        if(err?.response?.status === 404) {
          addNewUser();
        }
      })
    }
  }

  setTimeout(()=> resetUnsavedChanges(), 1000);
});

const addNewUser = async () => {
  let email = '';
  const cognitouser = await getCognitouser(router.currentRoute.value.params.sub);
  if(cognitouser) {
    email = cognitouser.findAttributeValue('email');
    const newuser: User = new User({email: email});
    const u: User = await store.dispatch('usermanagement/addUser', newuser);
    setUser(u);
  }
}

const saveAll = () => {
  saveUser(user.value)
  .then(() => toasted.success("Saved"));
}

const back = () => {
  const c = user.value.email.substring(0,1);
  router.push({path: `/sysadmin/userlist/${c}`});
}

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/bootstrap/_variables.scss";

.nav-item {
  a {
    cursor: pointer;
  }
}
</style>

        
