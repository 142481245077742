<template>
  <div class="auth reset-password">

    <div v-if="sentTo.length" class="alert alert-success" role="alert">
      Check {{sentTo}} for your reset code.
    </div>

    <div v-if="error.length" class="alert alert-danger" role="alert">
      {{error}}
    </div>

    <div class="form-group row">
      <label class="col-sm-3 col-form-label">Email</label>
      <div class="col-sm-6">
        <input type="email" class="form-control" v-model.trim="username" />
      </div>
    </div>

    <div v-if="step == 2" class="form-group row">
      <label class="col-sm-3 col-form-label">Code</label>
      <div class="col-sm-6">
        <input type="text" class="form-control" v-model.trim="code" />
      </div>
    </div>

    <div v-if="step == 2" class="form-group row">
      <label class="col-sm-3 col-form-label">New Password</label>
      <div class="col-sm-6">
        <input type="password" class="form-control" v-model="newPassword" />
        <small id="passwordHelp" class="form-text text-muted">
          Min. 8 chars with at least 1 uppercase letter, lowercase letter and digit.
        </small>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-6 offset-sm-3">
        <button v-if="step == 1" class="btn btn-primary" @click="step1()" :disabled="!username.length">
          Send reset code
        </button>
        <button v-if="step == 2" class="btn btn-primary" @click="step2()" :disabled="!username.length">
          Set password
        </button>
        <span class="text-muted ml-4">or</span>
        <button class="btn btn-link ml-3" @click="signIn()">
          Sign in
        </button>
      </div>
    </div>

  </div>
</template>

<script lang="ts" setup>
import { ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import Auth from '@aws-amplify/auth';
import { useToast } from "vue-toastification";

const router = useRouter();
const toasted = useToast();

const actProperty: any = inject('actProperty');

// Reactive state for form data and control flow
const step = ref(1);
const error = ref('');
const sentTo = ref('');
const username = ref('');
const code = ref('');
const newPassword = ref('');

// Methods for each step in the reset password flow
const step1 = async () => {
  error.value = '';
  sentTo.value = '';
    Auth.forgotPassword(username.value)
      .then((data: any) => {
        sentTo.value = data.CodeDeliveryDetails.Destination;
        step.value = 2;
      })
      .catch((err: any) => actProperty.displayError(err));
};

const step2 = async () => {
  Auth.forgotPasswordSubmit(username.value, code.value, newPassword.value)
    .then((data: any) => {
      step.value = 1;
      username.value = '';
      code.value = '';
      newPassword.value = '';
      toasted.success('Password reset');
      signIn();
    })
    .catch((err: any) => actProperty.displayError(err));
};

// Helper method for navigation
const signIn = () => {
  router.push({ name: 'sign-in' });
};


</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.alert {
  a {
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
