import _castArray from 'lodash/castArray';
import _get from 'lodash/get';
import { toCamelCase, copyInto } from '@/utilities';
import { Base, Document } from '@/models';

export class EmailAttachment extends Base {

  public filename: string;
  public path: string;
  public generatingstatus: string;
  public generatingid: string;

  get document() {
    const doc: Document = new Document();
    doc.src = this.path;
    return doc;
  }
  set document(data: any) {
    this.path = _get(data, 'src', '');
    const lastindex = this.path.lastIndexOf("/");
    this.filename = lastindex >= 0 ? this.path.substring(lastindex + 1) : "";
  }
  /**
   * Constructor
   */
  public constructor(data?: Partial<EmailAttachment>) {
    super(data);
    data = toCamelCase(data);

    this.filename = _get(data, 'filename', '');
    this.path = _get(data, 'path', '');
    this.generatingid = _get(data, 'generating', '');
  }
}
