<template>
  <div class="reports list">
    <div
      v-if="!reports.length && !hasBlockingRequests"
      class="alert alert-warning text-center"
    >
      <h2 class="mt-1 mb-2">No reports found</h2>
      <p class="mb-1">for the selected month or search</p>
    </div>

    <table v-else class="table table-hover">
      <thead>
        <tr>
          <th class="assignedtime">Assigned Time</th>
          <th class="assignedby">Assigned By</th>
          <th class="deadline">Deadline</th>
          

          <th class="date">Date</th>
          <th class="ref">Ref</th>
          <th class="type">Type</th>
          <th class="address">Address</th>
          <th class="status">Status</th>

          <th class="inputfiles">Input files</th>
          
          <th class="report">Report</th>
          <th>
            <span class="sr-only">Actions</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(report, $index) in reports" :key="$index" :class="getDataentryReportStyleClass(report)">
          <td>{{ formatBookingDatetimeForDisplay(report) }}</td>
          <td><span v-if="report && report.booking">{{ findCreatedBy(report.booking) }}</span></td>
          <td>{{ formatBookingStartdateForDisplay(report) }}</td>
          

          <td>{{ actProperty.formatDateForDisplay(report.date) }}</td>
          <td>{{ report.ref }}</td>
          <td>{{ actProperty.formatReportType(report.type, dictionary) }}</td>
          <td v-html="actProperty.formatAddress(report.address)"></td>

          <td> 
            <DataentryStatus 
              :report="report" 
              :changestatusreportid="changestatusreportid" 
              @statusreportselected="changestatusreportid = $event"/>
          </td>

          <td>
            <span v-if="report.booking && report.booking.dataentrydocuments">
              <span v-for="(document, $index) in report.booking.dataentrydocuments" :key="$index">
                <button
                  class="btn btn-outline-primary"
                  title="Download dataentry input file"
                  @click="downloadDataentryInput(document)"
                >
                  <i class="far" :class="getInputfileiconclass(document)"></i>
                </button>
              </span>
            </span>
          </td>
          
          <td>
            <div
              class="btn-group"
              role="group"
              aria-label="Download Report PDFs"
            >
              <!-- New generate report buttons to get around the pdf timeout issues -->
              <ProgressButton
                @click="actProperty.copyDownloadButton(report, 'report')"
                @dblclick="generateReportPdf(report, $event)"
                class="btn btn-outline-primary"
                :title="actProperty.formatReportType(report.type, dictionary)"
              >
                <i class="far fa-file-pdf"></i>
              </ProgressButton>

              <ProgressButton
                v-if="report.type == 'checkout'"
                @click="actProperty.copyDownloadButton(report, 'summary')"
                @dblclick="generateReportSummaryPdf(report, $event)"
                :disabled="!isCOSMActive(report)"
                class="btn btn-outline-primary"
                title="Check-Out Summary"
              >
                <i class="far fa-file-pdf"></i>
              </ProgressButton>
            </div>
          </td>

          <td class="text-right">
            <div class="btn-group" role="group" aria-label="Actions">
              <button
                v-if="isDataentryReportEditable(report)"
                class="btn btn-outline-primary"
                @click="gotoReport(report)"
                title="View"
              >
                <i class="fas fa-eye"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <ReportPagination
      :total="reportsState.monthlyReportCount"
      :current="reportsState.currentPage"
      :limit="reportsState.pageLimit"
      @pageReports="doReportsPaging"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, inject, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { Report, Auditlog, Booking, Document } from '@/models';
import { useToast } from "vue-toastification";
import ProgressButton from "@/components/ProgressButton.vue";
import ReportPagination from "@/components/report/ReportPagination.vue";
import DataentryStatus from '@/components/dataentry/DataentryStatus.vue';
const route = useRoute();
const router = useRouter();
const store = useStore();
const toasted = useToast();

const actProperty: any = inject('actProperty');

const reportsState = computed(() => store.state.reports);
const dictionary = computed(() => store.getters['dictionary/current']);
const hasBlockingRequests = computed(() => store.getters['app/hasBlockingRequests']);
const reports = computed(() => store.getters['reports/list']);

const generateReportPdfAction = async (report: Report): Promise<any> => {
  await store.dispatch('reports/generateReportPdf', report);
};

const generateReportSummaryPdfAction = async (report: Report): Promise<any> => {
  await store.dispatch('reports/generateReportSummaryPdf', report);
};

const setCurrentPage = async (page: number) => {
  await store.dispatch('reports/setCurrentPage', page);
};

const removeRequest = (id: string) => {
  store.commit('app/removeRequest', id);
};

const setReports = (reports: Report[]) => {
  store.commit('app/setReports', reports);
};

const changestatusreportid = ref('');

function isDataentryReportEditable(report: Report) {
  if(report?.booking?.cancelled) return false;
  return true;
}

function getDataentryReportStyleClass(report: Report) {
  if(report?.booking?.cancelled) return 'cancelled-booking';
  return '';
}

function isCOSMActive(report: Report) {
  let result = true;
  if (report && report.customer && report.customer.reportsettings) {
    result =
      report &&
      report.customer &&
      report.customer.reportsettings.cosummaryactive;
  }
  return result;
}

function gotoReport(report: Report): void {
  router.push({ name: "dereport", params: { id: report.id || "" } });
}

function doReportsPaging(page: number): void {
  setCurrentPage(page);
  const queryParams = { ...route.query };
  queryParams.page = page + "";
  router.push({ path: "dereports", query: queryParams });
}

function generateReportPdf(report: Report, requestId: string): void {
  generateReportPdfAction(report)
    .then((key: string) => {
      actProperty.downloadAttachment(key);
      toasted.success(`Report ${report.ref} PDF downloaded`);
      nextTick(() => {
        setReports([...reports.value]);
      });
    })
    .finally(() => removeRequest(requestId))
    .catch((err: any) => actProperty.displayError(err));
}

function generateReportSummaryPdf(report: Report, requestId: string): void {
  generateReportSummaryPdfAction(report)
    .then((key: string) => {
      actProperty.downloadAttachment(key);
      toasted.success(`Report ${report.ref} Summary PDF downloaded`);
    })
    .finally(() => removeRequest(requestId))
    .catch((err: any) => actProperty.displayError(err));
}

function findCreatedBy(booking: Booking) {
  let createdby = '';
  if(booking?.auditlogs?.length) {
    let log = booking.auditlogs.find((l: Auditlog) => l.notes === 'Added booking');
    if(log) createdby = log.user;
  }
  return createdby;
}

function downloadDataentryInput(document: Document) {
  actProperty.downloadAttachment(document.src);
}

function getInputfileiconclass(document: Document) {
  let icon = 'fa-file';
  if(document.src.endsWith('pdf')) icon = 'fa-file-pdf';
  else if(document.src.endsWith('doc') || document.src.endsWith('docx')) icon = 'fa-file-word';
  return icon;
}

function formatBookingDatetimeForDisplay(report: Report) {
  if(report?.booking?.createdAt)
    return actProperty.formatUtcDatetimeForDisplay(report.booking.createdAt);
  else 
    return '';
}

function formatBookingStartdateForDisplay(report: Report) {
  if(report?.booking?.startdate)
    return actProperty.formatUtcDatetimeForDisplay(report.booking.startdate);
  else 
    return '';
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
tr {
  cursor: pointer;
}
.btn.disabled {
  cursor: not-allowed;
}

th {
  &.assignedtime {
    width: 7rem;
  }
  &.assignedby {
    width: 9rem;
  }
  &.deadline {
    width: 7rem;
  }
  &.status {
    width: 7rem;
  }
  &.inputfiles {
    width: 7rem;
  }

  &.date {
    width: 7rem;
  }
  &.ref {
    width: 6rem;
  }
  &.type {
    width: 8rem;
  }
  &.exported {
    width: 9rem;
  }
  &.report {
    width: 7rem;
  }
  &.invoice {
    width: 9rem;
  }
  &.actions {
    width: 10rem;
  }
}

.fee-totals {
  font-weight: 500;
  span {
    font-weight: normal;
  }
}

.green-color {
  color: #28a745;
}

.amber-color {
  color: orange;
}

.grey-color {
  color: rgb(136, 136, 136);
}
</style>
