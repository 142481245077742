import { snakeCase } from 'lodash';
import { copyInto } from '@/utilities';

export class DictionaryReportType {

  public slug: string;
  public name: string;
  public displayname: string;
  public dictionaryflag: boolean;
  public createallowed: boolean;
  public accessiblebyactpi: boolean;
  public accessiblebyactpicompanynamelist: string[];

  /**
   * Create an object from a JSON representation
   * @param data
   * @return DictionaryReportType
   */
  public static parse(data: object) {
    const obj = new DictionaryReportType;

    copyInto(obj, data);
    if (!obj.slug) {
      obj.slug = snakeCase(obj.slug) || snakeCase(obj.name);
    }

    return obj;
  }

  /**
   * Constructor
   */
  public constructor() {
    this.slug = '';
    this.name = '';
    this.displayname = '';
    this.dictionaryflag = false;
    this.createallowed = false;
    this.accessiblebyactpi = true;
    this.accessiblebyactpicompanynamelist = [];
  }

  public getSystemName() {
    return this.slug || this.name;
  }

}
