<template>
  <!-- "Report Conflict" Modal -->
  <div class="modal fade" id="report-conflict-modal" data-keyboard="true" tabindex="-1" role="dialog" aria-labelledby="report-conflict-modal-title" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">

        <div class="modal-header">
          <h5 class="modal-title" id="report-conflict-modal-title">
            Conflict detected
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <p>This Report has been updated since you last saved/opened it.</p>
          <ul class="list-unstyled alert alert-danger">
            <li><strong>Use mine</strong> to overwrite the saved Report with your current version.</li>
            <li>Any changes <strong>someone else</strong> has made to this report will be lost.</li>
          </ul>
          <ul class="list-unstyled alert alert-danger">
            <li><strong>Use theirs</strong> to discard your edits and download the latest Report.</li>
            <li>Any changes <strong>you</strong> have made to this report may be lost.</li>
          </ul>
          <p class="lead">What do you want to do?</p>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-danger mr-auto" data-dismiss="modal" @click="useMine">Use mine</button>
          <button type="button" class="btn btn-danger" data-dismiss="modal" @click="useTheirs">Use theirs</button>
        </div>

      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import { computed, inject, ref, defineExpose } from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import _ from 'lodash';

const store = useStore();
const toasted = useToast();
const actProperty: any = inject('actProperty');

const report = computed(() => store.getters['reports/current']);

const getReport = async (ref: string): Promise<any> => {
  return await store.dispatch('reports/getReport', ref);
};

const updateReport = async (payload: {force: boolean}): Promise<any> => {
  return await store.dispatch('reports/updateReport', payload);
};

const useMine = async (): Promise<void> => {
  actProperty.confirmPrompt('Use mine?')
    .then(() => updateReport({force: true}))
    .then(() => toasted.success('Report saved'))
    .catch((err: any) => actProperty.displayError(err));
};

const useTheirs = async (): Promise<void> => {
  actProperty.confirmPrompt('Use theirs?')
    .then(() => getReport(report.value.ref))
    .then(() => toasted.success('Report downloaded'))
    .catch((err: any) => actProperty.displayError(err));
};


const show = () => {
  if ($('#report-conflict-modal-Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="report-conflict-modal-Backdrop"></div>');
    $('body').append(backdropDiv);
    $(`#report-conflict-modal`).show();
  }
}
const hide = () => {
  if ($('#report-conflict-modal-Backdrop').length > 0) {
    $('#report-conflict-modal-Backdrop').remove();
    $(`#report-conflict-modal`).hide();
  }
};
defineExpose({show, hide });

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
