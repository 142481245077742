<template>
  <div class="card bg-light reports--section">
    <div class="card-header section-header">
      <button
        @click="remove()"
        class="btn btn-sm btn-outline-danger float-right"
      >
        <i class="fas fa-trash"></i>
      </button>
      <code class="float-right text-muted mt-1 mb-1 mr-2">
        {{ section.slug }}
      </code>
      <h3 class="h4 mb-0">
        {{ section.name }}
      </h3>
    </div>

    <div class="card-body">
      <div v-for="(type, $index) in section.types" :key="type._uuid">
        <report-room-type :type="type" :index="$index" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Report, Section } from '@/models';
import { computed, inject, defineProps, provide, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';
import ReportRoomType from "@/components/report/Type.vue";

const actProperty: any = inject('actProperty');
const roomIndex = inject<number>('roomIndex');
const instance = getCurrentInstance();

const props = defineProps<{
  index: number;
  section: Section;
}>();

provide('sectionIndex', props.index);

const store = useStore();

const reportsState = computed(() => store.state.reports);

const report = computed<Report>({
  get: () => reportsState.value.current,
  set: (data: Report) => {
    setReport(data);
  },
});

const removeFromRooms = async (payload: { roomIndex: number; sectionIndex: number }) => {
  await store.dispatch('reports/removeFromRooms', payload);
};

const setReport = async (report: Report) => {
  await store.dispatch('reports/setReport', report);
};

const remove = async () => {
  actProperty
    .confirmPrompt()
    .then(() =>
      removeFromRooms({
        roomIndex: roomIndex!,
        sectionIndex: props.index,
      })
    )
    .then(() => instance?.proxy?.$forceUpdate());
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/bootstrap/_variables.scss";

.card {
  margin-bottom: 1rem;
  // &:last-child {
  //   margin-bottom: 0;
  // }
}

.reports--section {
  border: none;
  background-color: #fff !important;
}

.section-header {
  background-color: #fff;
  border: none;
  padding: 0rem 0.5rem;
}

.btn {
  padding-bottom: 0.2rem;
  &.disabled {
    opacity: 0.35;
  }
}
</style>
