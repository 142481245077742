<template>
  <div class="dictionary items">

    <section-type-selector :showSections="true" :showTypes="true" :showItems="true" />

    <div class="row" v-if="hasActiveItem">

      <div class="col-6">
        <div class="card">
          <div class="card-body">
            <fieldset>
              <label class="h3">Colours</label>
              <button class="btn btn-sm btn-outline-primary float-right ml-2" @click="pasteColours()" :disabled="!dictionaryState.clipboardColours.length" title="Paste">
                <i class="fas fa-paint-roller"></i>
              </button>
              <button class="btn btn-sm btn-outline-primary float-right ml-2" @click="copyColours()" :disabled="!itemColours.length" title="Copy">
                <i class="fas fa-copy"></i>
              </button>
              <multiselect-text 
                v-model=itemColours
                :options="miscItemColours"
                multiple
                :close-on-select="false"
                :allowEmpty="true"
                taggable
                @tag=addColour>
              </multiselect-text>
            </fieldset>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="card">
          <div class="card-body">
            <fieldset>
              <label class="h3">Conditions <small class="text-muted">(Additional)</small></label>
              <button class="btn btn-sm btn-outline-primary float-right ml-2" @click="pasteConditions()" :disabled="!dictionaryState.clipboardConditions.length" title="Paste">
                <i class="fas fa-paint-roller"></i>
              </button>
              <button class="btn btn-sm btn-outline-primary float-right ml-2" @click="copyConditions()" :disabled="!itemConditions.length" title="Copy">
                <i class="fas fa-copy"></i>
              </button>
              <multiselect-text 
                v-model=itemConditions
                :options="miscItemConditions"
                multiple
                :close-on-select="false"
                :allowEmpty="true"
                taggable
                @tag=addCondition>
              </multiselect-text>
            </fieldset>
          </div>
        </div>
      </div>

    </div>

    <div class="row mt-3" v-if="hasActiveItem">

      <div class="col-6">
        <div class="card">
          <div class="card-body">
            <fieldset>
              <label class="h3">Constituents</label>
              <button class="btn btn-sm btn-outline-primary float-right ml-2" @click="pasteConstituents()" :disabled="!dictionaryState.clipboardConstituents.length" title="Paste">
                <i class="fas fa-paint-roller"></i>
              </button>
              <button class="btn btn-sm btn-outline-primary float-right ml-2" @click="copyConstituents()" :disabled="!itemConstituents.length" title="Copy">
                <i class="fas fa-copy"></i>
              </button>
              <multiselect-text 
                v-model=itemConstituents
                :options="miscItemConstituents"
                multiple
                :close-on-select="false"
                :allowEmpty="true"
                taggable
                @tag=addConstituent>
              </multiselect-text>
            </fieldset>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="card">
          <div class="card-body">
            <fieldset>
              <label class="h3">Makes</label>
              <button class="btn btn-sm btn-outline-primary float-right ml-2" @click="pasteMakes()" :disabled="!dictionaryState.clipboardMakes.length" title="Paste">
                <i class="fas fa-paint-roller"></i>
              </button>
              <button class="btn btn-sm btn-outline-primary float-right ml-2" @click="copyMakes()" :disabled="!itemMakes.length" title="Copy">
                <i class="fas fa-copy"></i>
              </button>
              <multiselect-text 
                v-model=itemMakes
                :options="miscItemMakes"
                multiple
                :close-on-select="false"
                :allowEmpty="true"
                taggable
                @tag=addMake>
              </multiselect-text>
            </fieldset>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script lang="ts" setup>
import { computed, inject, ref } from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import SectionTypeSelector from './partials/SectionTypeSelector.vue';

// Props
// Assuming props are passed accordingly, if there are any

// Store
const store = useStore();

// State
const dictionaryState = computed(() => store.state.dictionary);
const hasActiveItem = computed(() => store.getters['dictionary/hasActiveItem']);

// Getters
const miscItemColours = computed(() => store.getters['dictionary/miscItemColours']);
const miscItemConditions = computed(() => store.getters['dictionary/miscItemConditions']);
const miscItemConstituents = computed(() => store.getters['dictionary/miscItemConstituents']);
const miscItemMakes = computed(() => store.getters['dictionary/miscItemMakes']);

// Computed properties for item fields
const itemColours = computed({
  get: () => dictionaryState.value.activeItem.colours,
  set: (val: string[]) => setColours(val),
});

const itemConditions = computed({
  get: () => dictionaryState.value.activeItem.conditions.additional,
  set: (val: string[]) => setConditions(val),
});

const itemConstituents = computed({
  get: () => dictionaryState.value.activeItem.constituents,
  set: (val: string[]) => setConstituents(val),
});

const itemMakes = computed({
  get: () => dictionaryState.value.activeItem.makes,
  set: (val: string[]) => setMakes(val),
});

// Methods
const setColours = async (value: string[]) => {
  await store.dispatch('dictionary/setColours', value);
};

const setConditions = async (value: string[]) => {
  await store.dispatch('dictionary/setConditions', value);
};

const setConstituents = async (value: string[]) => {
  await store.dispatch('dictionary/setConstituents', value);
};

const setMakes = async (value: string[]) => {
  await store.dispatch('dictionary/setMakes', value);
};

// Actions
const addColour = (val: string) => {
  itemColours.value = [...itemColours.value, val];
};

const addCondition = (val: string) => {
  itemConditions.value = [...itemConditions.value, val];
};

const addConstituent = (val: string) => {
  itemConstituents.value = [...itemConstituents.value, val];
};

const addMake = (val: string) => {
  itemMakes.value = [...itemMakes.value, val];
};

// Toast
const toasted = useToast();

const copyColours = () => {
  store.commit('dictionary/setClipboardColours', itemColours.value);
  toasted.success('Copied Colours');
};

const pasteColours = () => {
  itemColours.value = dictionaryState.value.clipboardColours;
};

const copyConditions = () => {
  store.commit('dictionary/setClipboardConditions', itemConditions.value);
  toasted.success('Copied Conditions');
};

const pasteConditions = () => {
  itemConditions.value = dictionaryState.value.clipboardConditions;
};

const copyConstituents = () => {
  store.commit('dictionary/setClipboardConstituents', itemConstituents.value);
  toasted.success('Copied Constituents');
};

const pasteConstituents = () => {
  itemConstituents.value = dictionaryState.value.clipboardConstituents;
};

const copyMakes = () => {
  store.commit('dictionary/setClipboardMakes', itemMakes.value);
  toasted.success('Copied Makes');
};

const pasteMakes = () => {
  itemMakes.value = dictionaryState.value.clipboardMakes;
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
