<template>
  <!-- "Linked Reports" Modal -->
  <div
    class="modal fade"
    id="linked-reports-modal"
    data-keyboard="true"
    tabindex="-1"
    role="dialog"
    aria-labelledby="linked-reports-modal-title"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="linked-reports-modal-title">
            Linked Reports
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form>
          <table class="table table-hover mb-0">
            <thead>
              <tr>
                <th width="25%">Date</th>
                <th width="20%">Ref</th>
                <th width="30%">Type</th>
                <th width="25%">Download</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(linkedReport, $index) in linkedReports" :key="$index">
                <td>
                  {{ actProperty.formatDateForDisplay(linkedReport.date) }}
                </td>
                <td>{{ linkedReport.ref }}</td>
                <td>
                  {{
                    actProperty.formatReportType(linkedReport.type, dictionary)
                  }}
                </td>
                <td>
                  <ProgressButton
                    @click="generateReportPdf(linkedReport, $event)"
                    @dblclick="generateReportPdf(linkedReport, $event)"
                    class="btn btn-sm btn-outline-primary"
                    title="Linked Report"
                    data-dismiss="modal"
                  >
                    <i class="far fa-file-pdf"></i>
                  </ProgressButton>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, ref } from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import ProgressButton from "@/components/ProgressButton.vue";
import { Report, Dictionary } from "@/models";

const store = useStore();
const actProperty: any = inject('actProperty');
const toasted = useToast();

// Accessing Vuex getters
const report = computed(() => store.getters['reports/current'] as Report);
const linkedReports = computed(() => store.getters['reports/linked'] as Report[]);
const dictionary = computed(() => store.getters['dictionary/current'] as Dictionary);

// Methods converted to regular functions
const removeRequest = (id: string) => {
  store.commit('app/removeRequest', id);
};

const generateReportPdfAction = async (report: Report): Promise<any> => {
  return await store.dispatch('reports/generateReportPdf', report);
};

const generateReportPdf = async (report: Report, requestId: string) => {
  generateReportPdfAction(report)
    .then((key: string) => {
      actProperty.downloadAttachment(key);
      toasted.success(`Report ${report.ref} PDF downloaded`);
    })
    .finally(() => removeRequest(requestId))
    .catch((err: any) => actProperty.displayError(err));
};

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
