import { Module } from 'vuex';
import { RootState } from '../types';
import { ReportsState } from './types';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

import { Report, Room, LinkedPhoto, Photo } from '@/models';

// Default state
export const state: ReportsState = {
  current: new Report(),
  currentoffice: "",
  currentroom: new Room(),
  photos: [],
  inphotos: [],
  outphotos: [],
  currentinphoto: new LinkedPhoto(new Photo()),
  currentoutphoto: new LinkedPhoto(new Photo()),
  linked: [],
  list: [],
  postcodesearchlist: [],
  mergeSource: null,
  mergeTargetRef: '',
  month: undefined,
  monthlyFeesTotal: null,
  monthlyReportCount: null,
  search: undefined,
  source: new Report(),
  updates: [],
  currentPage: 1,
  pageLimit: 20,
  gallerydisplaymode: '',
  dataentrystatus: undefined,
  filter: undefined,
};

// Use namespaced Vuex modules
const namespaced: boolean = true;

// Module export
const reports: Module<ReportsState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};

export default reports;
