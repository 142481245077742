import _get from 'lodash/get';

import { Base, Note } from '@/models';
import { toCamelCase } from '@/utilities';

export class SameAsCheckin extends Base {

  public active: boolean;

  public note?: Note;

  /**
   * Constructor initialises the variables from the class SameAsCheckin
   * @param data - the data to construct a type with all properties of <SameAsCheckin>
   */
  public constructor(data?: Partial<SameAsCheckin>) {
    super(data);
    data = toCamelCase(data);

    this.active = _get(data, 'active', false);

    if (_get(data, 'note')) {
      this.note = new Note(_get(data, 'note'));
    }
  }

}
