<template>
  <!-- "QC Notes" Modal -->
  <div class="modal fade" id="report-qc-notes-modal" data-backdrop="static" data-keyboard="true" tabindex="-1"
    role="dialog" aria-labelledby="report-qc-notes-modal-title" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="report-qc-notes-modal-title">QC Notes</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <!-- Tabs -->

          <ul class="nav nav-tabs mb-3" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="qc-notes-tab-warning" data-toggle="tab" href="#qc-notes-warning" role="tab"
                aria-controls="new-room-add" aria-selected="true" @click="setTab('warning')">
                Warning Notes
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="qc-notes-tab-critical" data-toggle="tab" href="#qc-notes-critical" role="tab"
                aria-controls="new-room-copy" aria-selected="false" @click="setTab('critical')">
                Critical Notes
              </a>
            </li>
          </ul>
          <div class="tab-content mt-4" id="qc-notes-tabs-content">
            <div class="tab-pane fade show active" id="qc-notes-warning" role="tabpanel"
              aria-labelledby="qc-notes-tab-warning">
              <textarea class="form-control" rows="20" v-model.lazy="warningNotes"></textarea>
            </div>
            <div class="tab-pane fade" id="qc-notes-critical" role="tabpanel" aria-labelledby="qc-notes-tab-critical">
              <textarea class="form-control" rows="20" v-model.lazy="criticalNotes"></textarea>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary mr-auto" data-dismiss="modal">
            Cancel
          </button>
          <button type="button" class="btn btn-primary" data-dismiss="modal">
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, inject } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

// Reactive State
const tab = ref('warning');

// Vuex Getters and Actions
const report = computed(() => store.getters['reports/current']);

const setReportDeep = async (payload: { path: string; data: any }): Promise<any> => {
  return store.dispatch('reports/setReportDeep', payload);
};

// Computed Properties with Getters and Setters
const warningNotes = computed({
  get: () => report.value.propertyDetails.qcWarningNotes,
  set: (data: string) => {
    setReportDeep({ path: 'propertyDetails.qcWarningNotes', data });
  }
});

const criticalNotes = computed({
  get: () => report.value.propertyDetails.qcCriticalNotes,
  set: (data: string) => {
    setReportDeep({ path: 'propertyDetails.qcCriticalNotes', data });
  }
});

// Methods
const setTab = (val: string) => {
  tab.value = val;
};
</script>
