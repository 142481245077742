<template>
  <div class="card bg-light reports--section">
    <div class="card-header section-header">
      <h3 class="h4 mb-0">
        {{ section.name }}
      </h3>
    </div>

    <div class="card-body">
      <div v-for="type in typeswithdeductions" :key="type._uuid">
        <report-room-type
          :room="room"
          :section="section"
          :type="type"
          :index="getTypeIndex(type)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps, provide } from 'vue';
import { checkitem } from "@/utilities";
import { Room, Section, Type, Item } from "@/models";
import ReportRoomType from "@/components/depository/Type.vue";

const props = defineProps<{
  index: number;
  room: Room;
  section: Section;
}>();

provide('sectionIndex', props.index);

const typeswithdeductions = computed(() => {
  let types: Type[] = props.section?.types.filter((type: Type) =>
    checktype(props.room, props.section, type)
  );
  return types;
});
const getTypeIndex = (type: Type): number => {
  return props.section?.types.findIndex((t: Type) => t._uuid === type._uuid) ?? -1;
};
const checktype = (room: Room, section: Section, type: Type): boolean => {
  let result = type?.items.filter((item: Item) => checkitem(room, section, type, item));
  return result?.length > 0;
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/bootstrap/_variables.scss";

.card {
  margin-bottom: 1rem;
  // &:last-child {
  //   margin-bottom: 0;
  // }
}

.reports--section {
  border: none;
  background-color: #fff !important;
}

.section-header {
  background-color: #fff;
  border: none;
  padding: 0rem 0.5rem;
}

.btn {
  padding-bottom: 0.2rem;
  &.disabled {
    opacity: 0.35;
  }
}
</style>
