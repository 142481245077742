<template>
  <!-- "New Item" Modal -->
  <div :id="id" class="modal" data-keyboard="true" tabindex="-1" role="dialog"
    aria-labelledby="`midterm-question-modal-label`" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="add-room-item-modal-label" v-if="operation == 'add'">
            New Flag
          </h5>
          <h5 class="modal-title" id="add-room-item-modal-label" v-else>
            {{ selectedmaintenanceflag.flag }}
          </h5>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <fieldset>
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Flag</label>
              <div class="col-sm-4">
                <input type="text" class="form-control" v-model="selectedmaintenanceflag.flag" />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Symbol</label>
              <div class="col-sm-3">
                <multiselect v-model="selectedmaintenanceflag.symbol" track-by="slug" :options="symbolOptions"
                  @select="onSelectSymbol" :show-labels="false">
                  <template v-slot:singleLabel="{ option }">
                    <div :class="option" class="flag-icon" />
                  </template>
                  <template v-slot:option="{ option }">
                    <div :class="option.icon" class="flag-icon" />
                  </template>
                </multiselect>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Description</label>
              <div class="col-sm-9">
                <input type="text" class="form-control" v-model="selectedmaintenanceflag.description" />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">PDF Guide</label>
              <div class="col-sm-9">
                <tiptap-editor :content="displayguide" @blur="updateDisplayguide($event)"
                  :key="selectedmaintenanceflag.flag" />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Colour</label>
              <div class="col-sm-9 d-flex align-items-center">
                <input type="color" v-model="selectedmaintenanceflagcolour" class="form-control" style="width: 75px; margin-right: 2px;">
                <input type="text"  v-model.lazy="selectedmaintenanceflagcolour" class="form-control" style="width: 100px;">
                <!-- <DxColorBox v-model="selectedmaintenanceflag.colour" width="125px" /> -->
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Background colour</label>
                <div class="col-sm-9 d-flex align-items-center">
                  <input type="color" v-model="selectedmaintenanceflagbackgroundcolour" class="form-control" style="width: 75px; margin-right: 2px;">
                  <input type="text"  v-model.lazy="selectedmaintenanceflagbackgroundcolour" class="form-control" style="width: 100px;">
                   <!-- <DxColorBox v-model="selectedmaintenanceflag.backgroundcolour" width="125px" /> -->
                </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">PDF Display type</label>
              <div class="col-sm-9">
                <multiselect v-model="pdfDisplay" track-by="slug" label="name" :options="typeOptions"
                  @select="onSelectPdfDisplay">
                </multiselect>
              </div>
            </div>
          </fieldset>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary mr-auto" data-dismiss="modal" @click.prevent="hide()">
            Back
          </button>
          <button type="submit" class="btn btn-primary" @click.prevent="add()" :disabled="!canAdd()"
            v-if="operation == 'add'">
            Add question
          </button>
          <button type="submit" class="btn btn-primary" @click.prevent="save()" :disabled="!canAdd()" v-else>
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import { computed, inject, ref, defineProps, defineExpose } from 'vue';
import { useStore } from 'vuex';
import { snakeCase } from 'lodash';
import { useToast } from 'vue-toastification';
import { Maintenanceflag, SelectOption } from '@/models';
import TiptapEditor from '@/components/editor/TiptapEditor.vue';
// Define props
const props = defineProps<{
  id: string,
  operation: string;
}>();

const store = useStore();
const toasted = useToast();
const actProperty: any = inject('actProperty');
const validColorCode = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i;

const selectedmaintenanceflag = computed(() => store.getters['customerdictionary/selectedmaintenanceflag']);
const maintenanceflags = computed((): Maintenanceflag[] => store.getters['customerdictionary/maintenanceflags']);


const addToMaintenanceflags = (flag: {}): Promise<any> => {
  return store.dispatch('customerdictionary/addToMaintenanceflags', flag);
};

const setSelectedMaintenanceflag = (flag: Maintenanceflag): void => {
  store.commit('customerdictionary/setSelectedMaintenanceflag', flag);
};

const setCustomerdictionaryDeep = (payload: { path: string; data: any }): Promise<any> => {
  return store.dispatch('customerdictionary/setCustomerdictionaryDeep', payload);
};

const typeOptions: SelectOption[] = [
  { name: 'Extract', slug: 'extract' },
  { name: 'Embed', slug: 'embed' },
];

const symbolOptions: SelectOption[] = [
  {
    name: "fa-bell-exclamation-red",
    slug: "fa-bell-exclamation-red",
    icon: "fa-bell-exclamation-red",
  },
  {
    name: "fa-bell-on",
    slug: "fa-bell-on",
    icon: "fa-bell-on",
  },
  {
    name: "fa-brake-warning",
    slug: "fa-brake-warning",
    icon: "fa-brake-warning",
  },
  {
    name: "fa-brush",
    slug: "fa-brush",
    icon: "fa-brush",
  },
  {
    name: "fa-hammer",
    slug: "fa-hammer",
    icon: "fa-hammer",
  },
  {
    name: "fa-lightbulb-exclamation",
    slug: "fa-lightbulb-exclamation",
    icon: "fa-lightbulb-exclamation",
  },
  {
    name: "fa-screwdriver-wrench",
    slug: "fa-screwdriver-wrench",
    icon: "fa-screwdriver-wrench",
  },
  {
    name: "fa-triangle-exclamation",
    slug: "fa-triangle-exclamation",
    icon: "fa-triangle-exclamation",
  },
  {
    name: "fa-wrench",
    slug: "fa-wrench",
    icon: "fa-wrench",
  },
];

const pdfDisplay = computed({
  get: () => typeOptions.find(option => option.slug === selectedmaintenanceflag.value.pdfDisplay),
  set: (value: SelectOption | undefined) => {
    if (value) selectedmaintenanceflag.value.pdfDisplay = value.slug;
  }
});

const displayguide = computed({
  get: () => selectedmaintenanceflag.value.displayguide,
  set: (value: string) => {
    selectedmaintenanceflag.value.displayguide = value;
  }
});

const selectedmaintenanceflagcolour = computed({
  get: () => selectedmaintenanceflag.value.colour,
  set: value => {
    if(validColorCode.test(value)){
      selectedmaintenanceflag.value.colour = value;
    }
  }
});

const selectedmaintenanceflagbackgroundcolour = computed({
  get: () => selectedmaintenanceflag.value.backgroundcolour,
  set: value => {
    if(validColorCode.test(value)){
      selectedmaintenanceflag.value.backgroundcolour = value;
    }
  }
});

function onSelectPdfDisplay(value: SelectOption) {
  selectedmaintenanceflag.value.pdfDisplay = value.slug;
}

function onSelectSymbol(value: SelectOption) {
  selectedmaintenanceflag.value.symbol = value.icon ? value.icon : '';
}

function updateDisplayguide(value: string) {
  selectedmaintenanceflag.value.displayguide = value;
}

function canAdd(): boolean {
  let result = true;
  return result;
}

async function add() {
  selectedmaintenanceflag.value.slug = snakeCase(selectedmaintenanceflag.value.flag);
  addToMaintenanceflags(selectedmaintenanceflag.value)
    .then(() =>
      toasted.success(
        `Added "${selectedmaintenanceflag.value.description}"`
      )
    )
    .then(() => reset())
    .catch((err: any) => {
      actProperty.displayError(err);
    });
  hide();

}

async function save() {
  let index = maintenanceflags.value.findIndex(f => f.slug === selectedmaintenanceflag.value.slug);
  if (index >= 0) {
    selectedmaintenanceflag.value.slug = snakeCase(selectedmaintenanceflag.value.flag);
    setCustomerdictionaryDeep({
      path: `maintenance_list[${index}]`,
      data: selectedmaintenanceflag.value,
    })
      .then(() =>
        toasted.warning(`Locally saved "${selectedmaintenanceflag.value.description}. Please remember to press final Save"`)

      )
      .then(() => reset())
      .catch((err: any) => {
        actProperty.displayError(err);
      });
  }

  hide();
}

function reset() {
  setSelectedMaintenanceflag(new Maintenanceflag());
}
const show = () => {
  if ($(`#${props.id}` + 'Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.id + 'Backdrop"></div>');
    $('body').append(backdropDiv);
    $(`#${props.id}`).show();
  }
}
const hide = () => {
  if ($(`#${props.id}`).length > 0) {
    $(`#${props.id}` + 'Backdrop').remove();
    $(`#${props.id}`).hide();
  }
};
defineExpose({ add, show, hide });
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>

<style lang="scss">
.acteditor {
  min-height: 50px !important;
}
</style>
