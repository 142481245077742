<template>
  <div @focusout="closeDropdown">
    <singleselect-text
      v-model="inspector"
      track-by="slug"
      label="name"
      :options="inspectorlist"
      :allow-empty="true"
      :taggable="false"
      class="statusdtopdown"
      v-show="changestatuspvmid === pvm.id"
      :ref="'destatusdropdown-' + pvm.id"
      @select="onSelectInspector(pvm.id, $event)"
    >
      <template #clear v-if="pvm.id">
        <i
          @mousedown.prevent.stop="onSelectInspector(pvm.id, $event)"
          class="multiselect__clear fa fa-times"
          aria-label="Clear"
        ></i>
      </template>
    </singleselect-text>
    <div
      v-show="changestatuspvmid != pvm.id"
      :class="{ 'transparent-text': !pvm.inspector.id }"
      @click="changeStatusReport()"
    >
      {{ pvm.inspector.id ? pvm.inspector.name : "Select Dropdown" }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, toRefs, defineEmits, defineProps } from "vue";
import { useStore } from "vuex";
import { Inspector, PmTenancy, SelectOption } from "@/models";

const store = useStore();
const inspectorlist = computed(() => store.getters["diary/inspectorlist"]);
const propertyvisitmanagement = computed(
  () => store.getters["propertyvisitmanagement/getTenancies"]
);
const props = defineProps<{
  pvm: PmTenancy;
  changestatuspvmid: string;
}>();
const emit = defineEmits(["statuspvmselected"]);
const dynamicRefs = ref<{ [key: string]: HTMLElement | undefined }>({});
const setTenanciesData = (data: PmTenancy[]) =>
  store.commit("propertyvisitmanagement/setTenancies", data);
const { pvm, changestatuspvmid } = toRefs(props);
const changeStatusReport = () => {
  emit("statuspvmselected", pvm.value.id);

  const dropdown = dynamicRefs.value[`destatusdropdown-${pvm.value.id}`] as any;
  if (dropdown) {
    dropdown.activate();
  }
};
const onSelectInspector = async (id: string | undefined, val: string) => {
  const pmIndex = propertyvisitmanagement.value.findIndex(
    (r: PmTenancy) => r.id === pvm.value.id
  );
  propertyvisitmanagement.value[pmIndex].inspector = val;
  setTenanciesData(propertyvisitmanagement.value);
  pvm.value.id = id;
  emit("statuspvmselected", "");
};
const closeDropdown = () => {
  emit("statuspvmselected", "");
};
const inspector = ref<SelectOption>(new SelectOption());
</script>

<style scoped lang="scss">
.emptystatus {
  color: rgb(255, 255, 255);
  background-color: tomato;
}
.transparent-text {
  color: transparent;
}
</style>

<style lang="scss">
.statusdtopdown > .multiselect__content-wrapper {
  width: fit-content;
}
</style>
