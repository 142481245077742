<template>
  <nav>
    <ul class="pagination">
      <li
        v-for="(link, $index) in links"
        :key="$index"
        class="page-item"
        :class="{ active: $route.params.search == link }"
      >
        <router-link :to="`/inspectorlist/${link}`" class="page-link">
          {{ link.toUpperCase() }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import { computed, onMounted, watch, inject } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { Inspector } from '@/models/inspector.model';
import { useToast } from "vue-toastification";

// Define props if there are any, for this example there are none

// Inject global properties or methods
const actProperty: any = inject('actProperty');

// Using the store
const store = useStore();
const route = useRoute();
// Converted actions from Vuex decorators
const getInspectorsAction = (payload?: { starts_with?: string }): Promise<Inspector[]> => {
  return store.dispatch('inspectors/getInspectors', payload);
};
// Converted data properties
const links = [
  "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", 
  "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "0-9",
];

// Converted methods
const init = async () => {
  await getInspectorsAction({ starts_with: (route.params.search as string) });
};

// Watch for route changes to re-initialize
watch(() => route.params.search, (newVal, oldVal) => {
  if (newVal) {
    init();
  }
});

// Lifecycle hook
onMounted(() => {
  if (route.params.search) {
    init();
  }
});
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
