<template>
  <div class="file-preview" v-bind:class="{ logo: isLogo }">
    <a @click.stop="openPhotoInGallery()" class="photo-link">
      <img
        :src="actProperty.resizedPhotoSrc(photo)"
        :alt="actProperty.formatDatetimeForDisplay(photo.createdAt)"
        :title="actProperty.formatDatetimeForDisplay(photo.createdAt)"
      />
      <div class="button-group">
        <button
          v-if="showdeletebutton"
          class="btn btn-sm btn-danger"
          @click.stop="onDelete()"
        >
          <i class="fas fa-times"></i>
        </button>
        <button
          v-if="!isLogo && showrotatebutton"
          class="btn btn-sm btn-info rotate-btn-clockwise"
          @click.stop="onRotate()"
        >
          <i class="fas fa-redo"></i>
        </button>
      </div>
    </a>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, defineEmits, defineProps, PropType } from 'vue';
import { useStore } from 'vuex';
import { Photo } from '@/models';
import { ReportsState } from '@/store/reports/types';

const emit = defineEmits(['rotate', 'delete'])

const store = useStore();

// Props
const props = defineProps({
  photo: { type: Object as PropType<Photo>},
  galleryType: { type: String },
  isLogo: { type: Boolean, default: false  },
  showdeletebutton: { type: Boolean, default: true  },
  showrotatebutton: { type: Boolean, default: true  },
});

// Defaults for optional props
const isLogo = props.isLogo ?? false;

// Injects
const actProperty: any = inject('actProperty');

// Methods
const onRotate = (event: Event) => {
  emit('rotate', props.photo);
};

const onDelete = (event: Event) => {
  emit('delete', props.photo);
};

const openPhotoInGallery = () => {
  if (props.isLogo) return;
  const url = actProperty.photoGalleryUrl(
    props.photo,
    store.state.reports.current,
    props.galleryType
  );
  window.open(url, "_blank");
};

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/bootstrap/_variables.scss";

.file-preview {
  display: inline-block;
  vertical-align: top;
  margin: 0 0.5rem 0.5rem 0;
  width: 100px;
  position: relative;
  z-index: 0;
}

img {
  height: auto;
  image-orientation: from-image;
  width: 100%;
}

.button-group {
  left: 0.3rem;
  position: absolute;
  top: 0.25rem;
  .btn + .btn {
    margin-left: 0.25rem;
  }
}

.photo-link {
  cursor: pointer;
}

.photo-link.not-saved-to-report {
  cursor: not-allowed;
}

.logo {
  width: 300px !important;
}
</style>