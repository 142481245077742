import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

export class Bookedby extends Base {

  public bbname: string;
  public bbemail: string;
  public bbmobile: string;

  public constructor(data?: Partial<Bookedby>) {
    super(data);
    data = toCamelCase(data);

    this.bbname = _get(data, 'bbname', '');
    this.bbemail = _get(data, 'bbemail', '');
    this.bbmobile = _get(data, 'bbmobile', '');
    this.bbmobile = this.bbmobile.replaceAll(' ', '');
  }
}