import _get from "lodash/get";
import _castArray from "lodash/castArray";
import moment from "moment-timezone";

import { Base } from "@/models";
import { toCamelCase, secondsToHoursMinutes } from "@/utilities";

export class TravelTime extends Base {
  public from: number;
  public fromstart: string;
  public fromfirstleg: number;
  public fromsecondleg: number;
  public fromthirdleg: number;
  public to: number;
  public tostart: string;
  public tofirstleg: number;
  public tosecondleg: number;
  public tothirdleg: number;

  public frompostcodes: string[];
  public topostcodes: string[];

  public fromdifference: number;
  public todifference: number;

  public constructor(data?: Partial<TravelTime>) {
    super(data);
    data = toCamelCase(data);

    this.from = _get(data, "from", 0);
    this.fromstart = _get(data, "fromstart", "");
    //this.from = _get(data, 'from', Math.round(Math.random() *  1800));
    this.fromfirstleg = _get(data, "fromfirstleg", 0);
    this.fromsecondleg = _get(data, "fromsecondleg", 0);
    this.fromthirdleg = _get(data, "fromthirdleg", 0);
    this.to = _get(data, "to", 0);
    this.tostart = _get(data, "tostart", "");
    // this.to = _get(data, 'to', Math.round(Math.random() *  1800));
    this.tofirstleg = _get(data, "tofirstleg", 0);
    this.tosecondleg = _get(data, "tosecondleg", 0);
    this.tothirdleg = _get(data, "tothirdleg", 0);

    this.frompostcodes = _get(data, "frompostcodes", []);
    this.topostcodes = _get(data, "topostcodes", []);

    this.fromdifference = _get(data, "fromdifference", 0);
    this.todifference = _get(data, "todifference", 0);
  }

  get fromduration(): string {
    return moment().startOf("day").seconds(this.from).format("H:mm");
  }
  get fromfirstlegduration(): string {
    return moment().startOf("day").seconds(this.fromfirstleg).format("H:mm");
  }
  get fromsecondlegduration(): string {
    return moment().startOf("day").seconds(this.fromsecondleg).format("H:mm");
  }
  get fromthirdlegduration(): string {
    return moment().startOf("day").seconds(this.fromthirdleg).format("H:mm");
  }

  get toduration(): string {
    return moment().startOf("day").seconds(this.to).format("H:mm");
  }
  get tofirstlegduration(): string {
    return moment().startOf("day").seconds(this.tofirstleg).format("H:mm");
  }
  get tosecondlegduration(): string {
    return moment().startOf("day").seconds(this.tosecondleg).format("H:mm");
  }
  get tothirdlegduration(): string {
    return moment().startOf("day").seconds(this.tothirdleg).format("H:mm");
  }

  get fromdifferenceduration(): string {
    var text = moment()
      .startOf("day")
      .add(Math.abs(this.fromdifference), "seconds")
      .format("H:mm");
    if (this.fromdifference > 0) text = `${text} extra time`;
    else text = `${text} less time`;
    return text;
  }

  get todifferenceduration(): string {
    var text = moment()
      .startOf("day")
      .add(Math.abs(this.todifference), "seconds")
      .format("H:mm");
    if (this.todifference > 0) text = `${text} extra time`;
    else text = `${text} less time`;
    return text;
  }

  get fromMins() {
    let value: string = "";
    if (this.from) {
      value = `${Math.round(this.from / 60)}`;
    }
    return value;
  }
  get fromText() {
    return secondsToHoursMinutes(this.from);
  }

  get toMins() {
    let value: string = "0";
    if (this.to) {
      value = `${Math.round(this.to / 60)}`;
    }
    return value;
  }
  get toText() {
    return secondsToHoursMinutes(this.to);
  }
}
