<template>
  <!-- "Dictionary Items Modal -->
  <div
    class="modal fade"
    :id="`customerdictionary-items-modal`"
    data-keyboard="true"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="`customerdictionary-items-modal-title`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="`customerdictionary-items-modal-title`">
            Dictionary Items
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <table class="table w-100">
          <thead class="thead-dark">
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th width="60"></th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-primary">
              <td>
                <input
                  class="form-control"
                  v-model="newItem.slug"
                  placeholder="Auto-generated"
                  readonly
                  autocomplete="off"
                />
              </td>
              <td>
                <input
                  class="form-control"
                  @keyup.enter="add"
                  v-model="newItem.name"
                  placeholder="Required"
                  autocomplete="off"
                />
              </td>
              <td>
                <button
                  class="btn btn-primary"
                  @click="add"
                  :disabled="!newItem.name.length"
                >
                  <i class="fas fa-plus"></i>
                </button>
              </td>
            </tr>
          </tbody>
          <tbody class="fixed-height">
            <tr v-for="item in currentItemsSorted" :key="`${item.slug}`">
              <td>
                <input
                  class="form-control"
                  :value="item.slug"
                  readonly
                  autocomplete="off"
                />
              </td>
              <td>
                <input
                  class="form-control"
                  :value="item.name"
                  @blur="updateName($event, item)"
                  autocomplete="off"
                />
              </td>
              <td>
                <button class="btn btn-outline-danger" @click="remove(item)">
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <!--
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" data-dismiss="modal">
            OK
          </button>
        </div>
        -->
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, inject } from 'vue';
import { useStore } from 'vuex';
import { DictionaryItem } from "@/models";
import { useToast } from "vue-toastification";
import _ from "lodash";

const store = useStore();
const toasted = useToast();

const actProperty: any = inject('actProperty');

const newItem = ref(new DictionaryItem());

const currentItemsSorted = computed(() => store.getters['customerdictionary/currentItemsSorted']);

const setActiveItem = (item: DictionaryItem): void => {
  store.commit('customerdictionary/setActiveItem', item);
};

const addItem = (item: DictionaryItem): Promise<any> => {
  return store.dispatch('customerdictionary/addItem', item);
};

const updateItem = (item: DictionaryItem): Promise<any> => {
  return store.dispatch('customerdictionary/updateItem', item);
};

const removeItem = (item: DictionaryItem): Promise<any> => {
  return store.dispatch('customerdictionary/removeItem', item);
};

const reset = () => {
  newItem.value = new DictionaryItem();
};

const add = async () => {
  newItem.value.slug = _.snakeCase(newItem.value.name);
  addItem(newItem.value)
      .then(() => setActiveItem(newItem.value))
      .then(() => toasted.success(`Added "${newItem.value.name}"`))
      .then(() => reset())
      .catch((err: any) => {
        actProperty.displayError(err);
      });
};

const updateName = async ($event: Event, item: DictionaryItem) => {
  const input = $event.target as HTMLInputElement;
  item.name = input.value;
  updateItem(item).catch((err: any) =>
    actProperty.displayError(err)
  );
};

const remove = async (item: DictionaryItem) => {
  actProperty
    .confirmPrompt()
    .then(() => removeItem(item))
    .then(() => toasted.success(`Removed "${item.name}"`))
    .then(() => setActiveItem(new DictionaryItem()))
    .then(() => reset())
    .catch((err: any) => actProperty.displayError(err));
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
::placeholder {
  color: #ddd;
}
</style>
