import { snakeCase } from 'lodash';
import { copyInto } from '@/utilities';
import { DictionaryType } from '@/models';

export class DictionarySection {

  public id: any;
  public name: any;
  public types: DictionaryType[];
  public slug: string;

  /**
   * Create an object from a JSON representation
   * @param data
   * @return Report
   */
  public static parse(data: object) {
    const obj = new DictionarySection;

    copyInto(obj, data);

    obj.types = obj.types ? obj.types.map(DictionaryType.parse) : [];

    if (!obj.slug) {
      obj.slug = snakeCase(obj.name);
    }

    return obj;
  }

  /**
   * Constructor
   */
  public constructor() {
    this.name = '';
    this.types = [];
    this.slug = '';
  }

}
