<template>
  <div
    class="modal"
    data-keyboard="true"
    tabindex="-1"
    role="dialog"
    id="google-event-modal"
    aria-labelledby="`google-event-label`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="google-event-label">
            {{ booking.summary }}
          </h5>

          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click.prevent="hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <h6 v-if="booking.startdate || booking.enddate">
                <strong>Date: </strong>{{ booking.bookingdate }} from
                {{ booking.starttime }} to
                {{ booking.endtime }}
              </h6>
            </div>
          </div>
          <div v-html="booking.description"></div>
        </div>

        <div class="modal-footer">
          <button
            v-if="booking.cancelled"
            type="button"
            class="btn btn-outline-secondary mr-auto"
            data-dismiss="modal"
            @click.prevent="restore()"
          >
            Restore
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary"
            data-dismiss="modal"
            @click.prevent="hide()"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import { computed, ref, inject, defineProps, defineExpose } from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import { Booking } from "@/models";
import { emitEvent } from '@/eventBus';
import Ably from "ably";

// Props
const props = defineProps<{ booking: Booking }>();

// Vuex store
const store = useStore();
const realtime: Ably.Realtime = inject('realtime');
const toasted = useToast();
const actProperty: any = inject('actProperty');

// Computed and Ref
const channel: Ably.Types.RealtimeChannelCallbacks = realtime.channels.get('diary');
const deviceid = actProperty.getDeviceId();

// Methods
const restoreBookingInStore = async (booking: Booking): Promise<Booking> => {
  return await store.dispatch('diary/restoreBooking', booking);
};

const restore = async () => {
  try {
    await restoreBookingInStore(props.booking);
    toasted.success("Booking restored");
    channel.publish('appointmentRestored', { deviceid: deviceid, bookingid: props.booking.id });
    emitEvent(
          "appointmentRestoredInBookingTemplate",
          props.booking
        );
  } catch (err: any) {
    actProperty.displayError(err);
  }
};

const show = () => {
  const backdropDiv = $('<div class="modal-backdrop fade show" id="myModalBackdrop"></div>');
  $('body').append(backdropDiv);
  $(`#google-event-modal`).show();
}

const hide = () => {
  $('#myModalBackdrop').remove();
  $(`#google-event-modal`).hide();
};
defineExpose({show, hide });
</script>


<style scoped lang="scss">
.modal-body {
  max-height: 50vh !important;
  overflow-y: auto !important;
}
</style>