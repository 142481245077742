import Vue from 'vue';
import { API } from 'aws-amplify';
import _ from 'lodash';
import moment from "moment-timezone";

import { ActionTree, ActionContext } from 'vuex';
import { RootState } from '../types';
import { AuditState } from './types';
import { Auditlog } from '@/models';
import { bookingdateformat } from '@/utilities';

/**
 * Actions
 *
 * Actions are similar to mutations, the differences being that:
 * - Instead of mutating the state, actions commit mutations.
 * - Actions can contain arbitrary asynchronous operations.
 * - Actions are triggered with the store.dispatch method e.g. `store.dispatch('getSettings')`
 */
export const actions: ActionTree<AuditState, RootState> = {

};
