<template>
  <div>
    <tiptap-editor
      :key="customerdictionary.company_name"
      :content="content"
      @update="update($event)"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import TiptapEditor from "@/components/editor/TiptapEditor.vue";

import { CustomerDictionary } from "@/models";

const store = useStore();

const customerdictionary = computed(() => store.getters['customerdictionary/current'] as CustomerDictionary);
const tnc = computed(() => store.getters['customerdictionary/tnc'] as string);

const content = computed({
  get: () => tnc.value,
  set: (value: string) => {
    store.dispatch('customerdictionary/setTNC', value);
  }
});

const update = (value: string) => {
  store.dispatch('customerdictionary/setTNC', value);
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
