<template>
  <div class="customers list">
    <div
      v-if="!customers.length && !hasBlockingRequests"
      class="alert alert-warning text-center"
    >
      <h2 class="mt-1 mb-2">No customers found</h2>
      <p class="mb-1">
        beginning with &ldquo;<strong>{{
          (route.params.search as string).toUpperCase()
        }}</strong
        >&rdquo;
      </p>
    </div>

    <table v-else class="table table-hover">
      <thead>
        <th width="7%"><span class="sr-only">Select</span></th>
        <th>Company</th>
        <th>Branch</th>
        <th>AC Client Code</th>
        <th>Nominal Code</th>
        <th>Region</th>
      </thead>
      <tbody>
        <tr v-for="customer in sortedCustomers" :key="customer.id">
          <td class="align-middle">
            <i
              v-if="selected(customer)"
              class="fas fa-check text-success fa-2x"
              @click="unselect(customer)"
            ></i>
            <i v-else class="fas fa-check fa-2x" @click="select(customer)"></i>
          </td>
          <td>{{ customer.companyName }}</td>
          <td>{{ customer.branchName }}</td>
          <td>{{ customer.acClientCode }}</td>
          <td>{{ customer.nominalCode }}</td>
          <td>{{ getRegion(customer.office) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { Customer, Office, SMSTemplate } from '@/models';
import { sortCustomerList } from '@/utilities';

const store = useStore();
const router = useRouter();
const route = useRoute();
const searchQuery = ref(router.currentRoute.value.params.search);

const customers = computed((): Customer[] => store.getters['customers/list']);
const hasBlockingRequests = computed((): boolean => store.getters['app/hasBlockingRequests']);
const smsTemplate = computed((): SMSTemplate => store.getters['smstemplates/current']);
const offices = computed((): Office[] => store.getters['offices/getOffices']);

const sortedCustomers = computed(() => sortCustomerList(customers.value));

function addClient(id: string) {
  store.dispatch('smstemplates/addClient', id);
}

function removeClient(id: string) {
  store.dispatch('smstemplates/removeClient', id);
}

function selected(customer: Customer) {
  return smsTemplate.value.clients.includes(customer.id);
}

const select = (c: Customer) => {
  addClient(c.id);
}
const unselect = (c: Customer) => {
  let customer = selected(c);
  if (customer) removeClient(c.id);
}

const getRegion = (id: string) => {
  const office = offices.value.find(o => o.id === id);
  return office ? office.name : '';
}

onMounted(() => {
  store.dispatch('customers/getCustomers', { starts_with: searchQuery.value });
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
