import _get from "lodash/get";
import _castArray from "lodash/castArray";

import { Base, Customer } from "@/models";
import { toCamelCase } from "@/utilities";

export class User extends Base {
  public id: string;
  public firstName: string;
  public lastName: string;
  public name: string;
  public email: string;
  public signature: string;
  public customers: Customer[];
  public deleted: boolean;

  /**
   * Constructor initialises the variables from the class User
   * @param data - the data to construct a type with all properties of <User>
   */
  public constructor(data?: Partial<User>) {
    super(data);
    data = toCamelCase(data);

    this.id = _get(data, "id", "");
    this.firstName = _get(data, "firstName", "");
    this.lastName = _get(data, "lastName", "");
    this.name = _get(data, "name", "");
    this.email = _get(data, "email", "");
    this.signature = _get(data, "signature", "");
    this.customers = _castArray(_get(data, "customers", [])).map(
      (r: Partial<Customer>) => new Customer(r)
    );
    this.deleted = _get(data, "deleted", false);
  }
}
