<template>
  <div>
    <header>
      <div class="row">
        <div class="col-sm-6">
          <h1>Report Types</h1>
        </div>
        <div class="col-sm-6 text-right">
          <button
            class="btn btn-outline-primary"
            @click.prevent="showAddDialog()"
          >
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
    </header>

    <div
      v-if="(!reportTypes || !reportTypes.length) && !hasBlockingRequests"
      class="alert alert-warning text-center"
    >
      <h2 class="mt-1 mb-2">No report types found, please add</h2>
    </div>

    <table v-else class="table table-fixed">
      <thead>
        <tr>
          <th class="align-middle" width="20%">System Name</th>
          <th class="align-middle" width="25%">Name</th>
          <th class="align-middle" width="30%">Display Name</th>
          <th class="align-middle text-center">Create Allowed</th>
          <th class="align-middle text-center">Dictionary</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(reporttype, $typeindex) in reportTypes" :key="$typeindex">
          <td>{{ reporttype.slug }}</td>
          <td>{{ reporttype.name }}</td>
          <td>{{ reporttype.displayname }}</td>
          <td class="text-center">
            <i
              class="fas fa-check"
              :class="{ 'text-success': reporttype.createallowed }"
            ></i>
          </td>
          <td class="text-center">
            <i
              class="fas fa-check"
              :class="{ 'text-success': reporttype.dictionaryflag }"
            ></i>
          </td>
          <td class="text-right">
            <div class="btn-group" role="group" aria-label="Actions">
              <button
                class="btn btn-outline-danger"
                @click="remove(reporttype)"
                v-on:click.stop
              >
                <i class="fas fa-trash-alt"></i>
              </button>
              <button
                class="btn btn-outline-primary"
                @click="edit(reporttype)"
                v-on:click.stop
              >
                <i class="fas fa-pencil-alt"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <ReportTypeModal
      :id="`dictionary-reporttype-modal-add`"
      ref="reportTypeModalAdd"
      :operation="'add'"
    />

    <ReportTypeModal
      :id="`dictionary-reporttype-modal-edit`"
      ref="reportTypeModalEdit"
      :operation="'edit'"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import { DictionaryReportType } from "@/models";
import ReportTypeModal from "@/components/modals/ReportTypeModal.vue";

const store = useStore();
const toasted = useToast();
const actProperty: any = inject('actProperty');

const reportTypeModalAdd = ref(null);
const reportTypeModalEdit = ref(null);

const hasBlockingRequests = computed(() => store.getters['app/hasBlockingRequests']);
const reportTypes = computed(() => store.getters['dictionary/reportTypes'] as DictionaryReportType[] | undefined);

const setActiveReportType = (reporttype: DictionaryReportType) => {
  store.commit('dictionary/setActiveReportType', reporttype);
};

const removeReportType = (reporttype: DictionaryReportType) => {
  store.commit('dictionary/removeReportType', reporttype);
};

const showAddDialog = () => {
  setActiveReportType(new DictionaryReportType());
  const modal = reportTypeModalAdd.value;
  modal.init();
  modal.show();
};

const edit = (reporttype: DictionaryReportType) => {
  setActiveReportType(DictionaryReportType.parse(reporttype));
  const modal = reportTypeModalEdit.value;
  modal.init();
  modal.show();
};

const remove = async (reporttype: DictionaryReportType) => {
  actProperty
    .confirmPrompt()
    .then(() => removeReportType(reporttype))
    .then(() => toasted.success("Flag deleted"))
    .catch((err: any) => actProperty.displayError(err));
};
</script>

<style lang="scss" scoped>
</style>
