<template><div></div></template>

<script setup lang="ts">
import _get from "lodash/get";
import { onMounted, ref, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

import * as XLSX from "xlsx-js-style";
import { WorkSheet } from "xlsx-js-style";
import { Note, OrderedItem, Report } from "@/models";

const store = useStore();
const router = useRouter()
const route = useRoute();
const reportRef = ref('');

const actProperty: any = inject('actProperty');
const report = computed(() => store.getters['reports/current'] as Report);

// Your previous data properties turned into refs
const checkoutheadings = [
  "Item Ref",
  "Description",
  "Check In Comments",
  "Check Out Comments",
  "Liability",
  "Quote",
  "Proposed Decuction",
  "Notes",
];
const pvheadings = [
  "Item Ref",
  "Item",
  "Condition",
  "Proposed Decuction",
];

const getReport = (id: string) => {
  return store.dispatch('reports/getReportUnauthenticated', id);
};

onMounted(() => {
  reportRef.value = (route.params.ref as string);
  init();
});

// Mimic the previous method's behavior
const init = async() => {
  getReport(reportRef.value)
  .then((report: Report) => {
    if (report === undefined) {
      router.push({
        name: "downloadmaintenancesummaryreportnotfound",
      });
    } else {
      download();
    }
  })
  .catch((err: any) => {
    router.push({ name: "downloadmaintenancesummaryreportnotfound" });
  });
}

const download = () => {
  var ws: WorkSheet = XLSX.utils.json_to_sheet(sheets.value, {
    skipHeader: true,
  });
  addStyle(ws);
  if (report.value.flagged) delete_cols(ws, 4, 1);

  // A workbook is the name given to an Excel file
  var wb = XLSX.utils.book_new(); // make Workbook of Excel

  // add Worksheet to Workbook
  // Workbook contains one or more worksheets
  XLSX.utils.book_append_sheet(wb, ws, "Sheet 1"); // sheetAName is name of Worksheet

  // export Excel file
  XLSX.writeFile(wb, filename.value); // name of the file is 'book.xlsx'
}

const addStyle = (ws: WorkSheet) => {
  const headstyle = {
    font: {
      color: { rgb: "D5D000" },
      bold: true,
    },
    fill: {
      // background color
      patternType: "solid",
      bgColor: { rgb: "253746" },
      fgColor: { rgb: "253746" },
    },
    alignment: { horizontal: "center" },
  };
  const cellstyle = {
    alignment: {
      vertical: "top",
      wrapText: "1",
    },
  };
  var wscols = [];
  if (report.value && report.value.type === "checkout") {
    let columns = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
    styleColumnHeading(ws, headstyle, columns);
    styleSectionHeading(ws, headstyle, columns, "CLEANING SCHEDULE");
    styleSectionHeading(
      ws,
      headstyle,
      columns,
      "DILAPIDATIONS AND MAINTENANCE SCHEDULE"
    );
    styleColumn(
      ws,
      { alignment: { vertical: "top", horizontal: "center" } },
      columns,
      0
    );
    styleCells(ws, cellstyle, columns);
    createPhotogallerylinks(ws, ["A"]);

    // Set column widths
    wscols = [
      { wch: 9 },
      { wch: 50 },
      { wch: 30 },
      { wch: 30 },
      { wch: 10 },
      { wch: 17 },
      { wch: 17 },
      { wch: 66 },
    ];
    ws["!cols"] = wscols;
  } else if (
    report.value &&
    actProperty.isFactoryreport(report.value.type)
  ) {
    let columns = ["A", "B", "C", "D"];
    styleColumnHeading(ws, cellstyle, columns);
    styleSectionHeading(
      ws,
      headstyle,
      columns,
      "DILAPIDATIONS (Red Flags)"
    );
    styleSectionHeading(
      ws,
      headstyle,
      columns,
      "MAINTENANCE (Amber Flags)"
    );
    styleColumn(
      ws,
      { alignment: { vertical: "top", horizontal: "center" } },
      columns,
      0
    );
    styleCells(ws, cellstyle, columns);

    // Set column widths
    wscols = [{ wch: 6 }, { wch: 20 }, { wch: 20 }, { wch: 40 }];
    ws["!cols"] = wscols;
  }
}

const styleColumnHeading = (ws: WorkSheet, style: any, columns: string[]) => {
  for (let col = 0; col < columns.length; col++) {
    if (ws[`${columns[col]}1`]) ws[`${columns[col]}1`].s = style;
  }
}

const styleColumn = (
  ws: WorkSheet,
  style: any,
  columns: string[],
  col: number
) => {
  let total_rows = findTotalRows(ws);
  for (let row = 1; row <= total_rows; row++) {
    if (ws[`${columns[col]}${row}`]) {
      if (ws[`${columns[col]}${row}`].s) {
        ws[`${columns[col]}${row}`].s = {
          ...ws[`${columns[col]}${row}`].s,
          style,
        };
      } else {
        ws[`${columns[col]}${row}`].s = style;
      }
    }
  }
}

const styleSectionHeading = (
  ws: WorkSheet,
  style: any,
  columns: string[],
  heading: string
) => {
  let cell_ref: any = findCell(ws, heading);
  if (cell_ref) {
    let merge = { s: { r: cell_ref.r, c: 0 }, e: { r: cell_ref.r, c: 7 } };
    if (!ws["!merges"]) ws["!merges"] = [];
    ws["!merges"].push(merge);
    ws[`A${cell_ref.r + 1}`].s = style;
  }
}

const findCell = (ws: WorkSheet, value: string): Object | undefined => {
  if (ws["!ref"]) {
    let total_rows = findTotalRows(ws);
    if (total_rows) {
      let columns = ["A", "B", "C", "D", "E", "F", "G", "H"];
      for (let row = 1; row <= total_rows; row++) {
        for (let col = 0; col < columns.length; col++) {
          if (
            ws[`${columns[col]}${row}`] &&
            ws[`${columns[col]}${row}`].v &&
            ws[`${columns[col]}${row}`].v === value
          ) {
            return { r: row - 1, c: col };
          }
        }
      }
    }
  }
  return undefined;
}

const styleCells = (ws: WorkSheet, style: any, columns: string[]) => {
  let total_rows = findTotalRows(ws);
  for (let col = 0; col < columns.length; col++) {
    for (let row = 1; row <= total_rows; row++) {
      if (ws[`${columns[col]}${row}`]) {
        if (ws[`${columns[col]}${row}`].s) {
          ws[`${columns[col]}${row}`].s = {
            ...ws[`${columns[col]}${row}`].s,
            style,
          };
        } else {
          ws[`${columns[col]}${row}`].s = style;
        }
      }
    }
  }
}

const findTotalRows = (ws: WorkSheet): number => {
  let total: number = 0;
  if (ws["!ref"]) {
    let ref = ws["!ref"];
    let end_range = ref.split(":")[1];
    total = parseInt(end_range.replace("H", ""));
  }
  return total;
}

const createPhotogallerylinks = (ws: WorkSheet, columns: string[]) => {
  let total_rows = findTotalRows(ws);
  for (let col = 0; col < columns.length; col++) {
    for (let row = 1; row <= total_rows; row++) {
      if (ws[`${columns[col]}${row}`]) {
        const itemref = ws[`${columns[col]}${row}`].v;
        if (!isNaN(itemref)) {
          const photolinkurl = `${window.location.origin}/photos/${report.value.id}/-/-/${itemref}`;
          ws[`${columns[col]}${row}`].l = {
            Target: photolinkurl,
            Tooltip: "Open photographs for this item",
          };
          const hyperlinkstyle = {
            font: {
              color: { rgb: "0000EE" },
              bold: true,
              underline: true,
            },
          };
          ws[`${columns[col]}${row}`].s = {
            ...ws[`${columns[col]}${row}`].s,
            ...hyperlinkstyle,
          };
        }
      }
    }
  }
}

const sheets = computed({
  get: () => {
    let data: Object[] = [];

  if (report.value && report.value.type === "checkout") {
    let cleaningdata = filterCOItems(orderedlist.value, ["CLN"]);
    if (cleaningdata.length) {
      cleaningdata.unshift(["CLEANING SCHEDULE", "", "", "", "", "", "", ""]);
      cleaningdata.unshift(checkoutheadings);
      cleaningdata.forEach((r) => data.push(r));
    }

    let maintenancedata = filterCOItems(orderedlist.value, ["TT", "LL"]);
    if (maintenancedata.length) {
      maintenancedata.unshift([
        "DILAPIDATIONS AND MAINTENANCE SCHEDULE",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]);
      maintenancedata.forEach((r) => data.push(r));
    }
  } else if (
    report.value &&
    actProperty.isFactoryreport(report.value.type)
  ) {
    let amberflagitems = filterPVItems(orderedlist.value, ["B"]);
    if (amberflagitems.length) {
      amberflagitems.unshift(pvheadings);
      amberflagitems.unshift(["", "MAINTENANCE (Amber Flags)", "", ""]);
      amberflagitems.forEach((r) => data.push(r));
    }

    let redflagitems = filterPVItems(orderedlist.value, ["A"]);
    if (redflagitems.length) {
      redflagitems.unshift(["", "DILAPIDATIONS (Red Flags)", "", ""]);
      redflagitems.forEach((r) => data.push(r));
    }
  }

  if(!data.length) data.push([
        "No items!",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ])
  return data;    
  },
  set: (newValue) => {}
});

const orderedlist = computed({
  get: () => {
    let items: OrderedItem[] = [];
    if (report.value) {
      if (report.value && report.value.rooms) {
        report.value.rooms.forEach((room, roomindex) => {
          if (room.sections) {
            room.sections.forEach((section, sectionindex) => {
              if (section.types) {
                section.types.forEach((type, typeindex) => {
                  if (type.items) {
                    type.items.forEach((item, itemindex) => {
                      let orderedItem = new OrderedItem(item);
                      orderedItem.roomname = room.name;
                      orderedItem.roomindex = roomindex;
                      orderedItem.section = section.name;
                      orderedItem.sectionindex = sectionindex;
                      orderedItem.type = type.name;
                      orderedItem.typeindex = typeindex;
                      orderedItem.item = item;
                      orderedItem.itemindex = itemindex;
                      items.push(orderedItem);
                    });
                  }
                });
              }
            });
          }
        });
      }
    }
    const list = items.map((item, order) => {
      item.order = order + 1;
      return item;
    });
    return list;},
  set: () => {}
});

const getNotes = (condition: any, inOrOut: string) => {
  let notes: Note[] = [];
  if (condition) {
    if (inOrOut === "in" && condition.in && condition.in.notes) {
      condition.in.notes.forEach((note: Note) => notes.push(note));
    } else if (inOrOut === "out" && condition.out) {
      if (condition.out.sameAsCheckin && condition.out.sameAsCheckin.note) {
        notes.push(condition.out.sameAsCheckin.note);
      }
      condition.out.notes.forEach((note: Note) => notes.push(note));
    }
  }
  return notes;
};

const filterNotesByResponsibility = (
    notes: Note[],
    responsibilities: string[]
  ) => {
    return notes.filter((note) =>
      _get(note, "responsibility", []).some((x) => responsibilities.includes(x))
    );
  };

// Methods
const filterCOItems = (list: OrderedItem[], filterresponsibilities: string[]) => {
  let returnlist: any[][] = [];
  list.forEach((ordereditem: OrderedItem) => {
    if (ordereditem.condition && ordereditem.condition.out) {
      let x = filterNotesByResponsibility(
        ordereditem.condition.out.notes,
        filterresponsibilities
      );
      let sacFlag = _get(
        ordereditem.condition.out.sameAsCheckin?.note,
        "responsibility",
        []
      ).some((x) => filterresponsibilities.includes(x));
      if (x.length || sacFlag) {
        let description =
          ordereditem.roomname +
          "/" +
          ordereditem.section +
          "/" +
          ordereditem.type +
          "/" +
          ordereditem.name;
        let ci_notes = getNotes(ordereditem.condition, "in");
        let ci_comment: string = "";
        if (ci_notes)
          ci_notes.forEach((n: Note, index: number) => {
            ci_comment = ci_comment + n.note;
            if (ci_comment && ci_notes.length - 1 != index)
              ci_comment = ci_comment + ", ";
          });

        let co_notes = getNotes(ordereditem.condition, "out");

        let filtered_co_notes = co_notes.filter((co_note: Note) => {
          if (co_note.responsibility) {
            let filtered_responsibility = co_note.responsibility.find((r) =>
              filterresponsibilities.includes(r)
            );
            if (!filtered_responsibility) return false;
          }
          return co_note.note != "Same as checkin";
        });
        filtered_co_notes.forEach((co_note: Note, index: number) => {
          let co_comment: string = co_note.note;

          let responsibilities =
            co_note.responsibility && co_note.responsibility.length
              ? co_note.responsibility.reduce((r1, r2) => r1 + " " + r2)
              : "";

          responsibilities = responsibilities.replaceAll(
            "STTA",
            "Subject To Tenancy Agreement"
          );
          responsibilities = responsibilities.replaceAll("LL", "Landlord");
          responsibilities = responsibilities.replaceAll("TT", "Tenant");
          responsibilities = responsibilities.replaceAll("CLN", "Cleaning");
          responsibilities = responsibilities.replaceAll(
            "FWT",
            "Fair Wear & Tear"
          );
          responsibilities = responsibilities.replaceAll(
            "INF",
            "Information Only"
          );
          if (index == 0) {
            returnlist.push([
              ordereditem.order + "",
              description,
              ci_comment,
              co_comment,
              responsibilities.trim(),
              "£",
              "£",
            ]);
          } else {
            returnlist.push([
              "",
              "",
              "",
              co_comment,
              responsibilities.trim(),
              "",
              "",
            ]);
          }
        });
      }
    }
  });

  return returnlist;
};

const pv_item_index = ref(1);
const filterPVItems = (list: OrderedItem[], responsibilities: string[]) => {
  let returnlist: string[][] = [];
  list.forEach((ordereditem: OrderedItem) => {
    if (ordereditem.condition && ordereditem.condition.responsibility) {
      let found = ordereditem.condition.responsibility.some((x) =>
        responsibilities.includes(x)
      );
      if (found) {
        let description =
          ordereditem.roomname +
          "/" +
          ordereditem.section +
          "/" +
          ordereditem.type;
        let ci_notes = getNotes(ordereditem.condition, "in");
        let ci_comment: string = "";
        if (ci_notes)
          ci_notes.forEach((n: Note, index: number) => {
            ci_comment = ci_comment + n.note;
            if (ci_comment && ci_notes.length - 1 != index)
              ci_comment = ci_comment + ", ";
          });
        returnlist.push([
          pv_item_index.value++ + "",
          description,
          ci_comment,
          "",
        ]);
      }
    }
  });

  return returnlist;
}

const delete_cols = (ws: WorkSheet, start_col: number, ncols: number) => {
  function clamp_range(range: any) {
    if (range.e.r >= 1 << 20) range.e.r = (1 << 20) - 1;
    if (range.e.c >= 1 << 14) range.e.c = (1 << 14) - 1;
    return range;
  }

  var crefregex =
    /(^|[^._A-Z0-9])([$]?)([A-Z]{1,2}|[A-W][A-Z]{2}|X[A-E][A-Z]|XF[A-D])([$]?)([1-9]\d{0,5}|10[0-3]\d{4}|104[0-7]\d{3}|1048[0-4]\d{2}|10485[0-6]\d|104857[0-6])(?![_.\(A-Za-z0-9])/g;
  if (!ws) throw new Error("operation expects a worksheet");
  var dense = Array.isArray(ws);
  if (!ncols) ncols = 1;
  if (!start_col) start_col = 0;

  /* extract original range */
  var range = XLSX.utils.decode_range(ws["!ref"] ? ws["!ref"] : "");
  var R = 0,
    C = 0;

  var formula_cb = function (
    $0: any,
    $1: any,
    $2: any,
    $3: any,
    $4: any,
    $5: any
  ) {
    var _R = XLSX.utils.decode_row($5),
      _C = XLSX.utils.decode_col($3);
    if (_C >= start_col) {
      _C -= ncols;
      if (_C < start_col) return "#REF!";
    }
    return (
      $1 +
      ($2 == "$" ? $2 + $3 : XLSX.utils.encode_col(_C)) +
      ($4 == "$" ? $4 + $5 : XLSX.utils.encode_row(_R))
    );
  };

  var addr, naddr;
  /* move cells and update formulae */
  if (dense) {
    true;
  } else {
    for (C = start_col + ncols; C <= range.e.c; ++C) {
      for (R = range.s.r; R <= range.e.r; ++R) {
        addr = XLSX.utils.encode_cell({ r: R, c: C });
        naddr = XLSX.utils.encode_cell({ r: R, c: C - ncols });
        if (!ws[addr]) {
          delete ws[naddr];
          continue;
        }
        if (ws[addr].f)
          ws[addr].f = ws[addr].f.replace(crefregex, formula_cb);
        ws[naddr] = ws[addr];
      }
    }
    for (C = range.e.c; C > range.e.c - ncols; --C) {
      for (R = range.s.r; R <= range.e.r; ++R) {
        addr = XLSX.utils.encode_cell({ r: R, c: C });
        delete ws[addr];
      }
    }
    for (C = 0; C < start_col; ++C) {
      for (R = range.s.r; R <= range.e.r; ++R) {
        addr = XLSX.utils.encode_cell({ r: R, c: C });
        if (ws[addr] && ws[addr].f)
          ws[addr].f = ws[addr].f.replace(crefregex, formula_cb);
      }
    }
  }

  /* write new range */
  range.e.c -= ncols;
  if (range.e.c < range.s.c) range.e.c = range.s.c;
  ws["!ref"] = XLSX.utils.encode_range(clamp_range(range));

  /* merge cells */
  if (ws["!merges"])
    ws["!merges"].forEach(function (merge, idx) {
      var mergerange;
      switch (typeof merge) {
        case "string":
          mergerange = XLSX.utils.decode_range(merge);
          break;
        case "object":
          mergerange = merge;
          break;
        default:
          throw new Error("Unexpected merge ref " + merge);
      }
      if (mergerange.s.c >= start_col) {
        mergerange.s.c = Math.max(mergerange.s.c - ncols, start_col);
        if (mergerange.e.c < start_col + ncols) {
          if (ws["!merges"]) delete ws["!merges"][idx];
          return;
        }
        mergerange.e.c -= ncols;
        if (mergerange.e.c < mergerange.s.c) {
          if (ws["!merges"]) delete ws["!merges"][idx];
          return;
        }
      } else if (mergerange.e.c >= start_col) mergerange.e.c = Math.max(mergerange.e.c - ncols, start_col);
      clamp_range(mergerange);
      if (ws["!merges"]) ws["!merges"][idx] = mergerange;
    });
  if (ws["!merges"])
    ws["!merges"] = ws["!merges"].filter(function (x) {
      return !!x;
    });

  /* cols */
  if (ws["!cols"]) ws["!cols"].splice(start_col, ncols);
}

// Computed properties or methods
const filename = computed(() => {
  return report.value ? `${report.value.ref}.xlsx` : "summary.xlsx";
});

</script>

<style scoped lang="scss">
</style>
