<template>
  <div class="list">
    <div v-if="!compliancelist.length" class="alert alert-warning text-center">
      <h2 class="mt-1 mb-2">No items found</h2>
      <p class="mb-1"></p>
    </div>

    <table v-else class="table table-hover" style="padding-bottom: 60px">
      <thead>
        <tr>
          <th class="prompt">Prompt</th>
          <th class="type">Type</th>
          <th class="linked">Linked</th>
          <th class="conditional">Conditional</th>
          <th class="conditionalvalue">Conditional Value</th>
          <th class="flag">Flag</th>
          <th class="flagvalue">Flag Value</th>
          <th class="required">Required</th>
          <th class="photo">Photo</th>
          <th class="notes">Notes</th>
          <th class="delete"></th>
        </tr>
      </thead>

      <Container
        @drop="onDrop"
        tag="tbody"
        lock-axis="y"
        drag-class="opacity-ghost"
        :animation-duration="500"
      >
        <Draggable
          v-for="question in compliancelist"
          :key="question.slug"
          tag="tr"
        >
          <td class="prompt draggable-item">{{ question.prompt }}</td>
          <td class="type draggable-item">
            {{ getDisplayType(question.questionType) }}
          </td>
          <td class="linked draggable-item">
            {{ getDisplayLink(question.link) }}
          </td>
          <td class="conditional draggable-item">
            {{ getDisplayBoolean(question.conditional) }}
          </td>
          <td class="conditionalvalue draggable-item">
            {{ getDisplayBoolean(question.linkconditionvalue) }}
          </td>
          <td class="flag draggable-item">
            {{ getDisplayBoolean(question.flag) }}
          </td>
          <td class="flagvalue draggable-item">{{ question.flagvalue }}</td>
          <td class="required draggable-item">
            {{ getDisplayBoolean(question.required) }}
          </td>
          <td class="photo draggable-item">
            {{ getDisplayBoolean(question.photo) }}
          </td>
          <td class="notes draggable-item">
            {{ getDisplayBoolean(question.notes) }}
          </td>
          <td class="delete draggable-item">
            <div class="btn-group" role="group" aria-label="Actions">
              <button
                class="btn btn-outline-danger"
                @click="remove(question)"
                v-on:click.stop
              >
                <i class="fas fa-trash-alt"></i>
              </button>
              <button
                class="btn btn-outline-primary"
                @click="edit(question)"
                v-on:click.stop
              >
                <i class="fas fa-pencil-alt"></i>
              </button>
            </div>
          </td>
        </Draggable>
      </Container>
    </table>

    <!-- Modals -->
    <MidTermQuestionModal
      :id="`midterm-question-modal-edit`"
      ref="midTermQuestionModalEdit"
      :operation="'edit'"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import { Compliancequestion, QuestionWrapper, QuestionOption, SelectOption } from "@/models";
import { snakeCase } from "lodash";

import { Container, Draggable } from "vue-dndrop";
import MidTermQuestionModal from "@/components/modals/MidTermQuestionModal.vue";

const midTermQuestionModalEdit = ref(null);

const store = useStore();
const toasted = useToast();

const compliancelist = computed(() => store.getters['customerdictionary/compliancelist'] as Compliancequestion[]);

const typeOptions = computed(() => {
  return [
    { name: "Select List", slug: "select" },
    { name: "Text", slug: "text" },
    { name: "Text Area", slug: "textarea" },
    { name: "Heading", slug: "heading" },
    { name: "Sub Heading", slug: "subheading" },
  ];
});

const linkOptions = computed(() => {
  return compliancelist.value.map((question) => {
    return { name: question.prompt, slug: question.slug } as SelectOption;
  });
});

const getDisplayType = (val: string) => {
  const found = typeOptions.value.find((option) => option.slug == val);
  return found ? found.name : val;
};

const getDisplayBoolean = (val: any) => val ? "Yes" : "No";

const getDisplayLink = (val: string) => val ? "Yes" : "No";

const edit = (question: Compliancequestion) => {
  setSelectedQuestion(question);
    {
      let wrapper = new QuestionWrapper();
      wrapper.slug = question.slug;
      wrapper.prompt = question.prompt;
      let typeOpt: SelectOption | undefined = typeOptions.value.find(
        (option) => option.slug == question.questionType
      );
      if (typeOpt) {
        wrapper.type = {
          name: typeOpt.name,
          slug: typeOpt.slug,
        };
      }

      wrapper.options = question.optionList.map((option) => {
        let qOptions = new QuestionOption({});
        qOptions.slug = snakeCase(option);
        qOptions.value = option;
        return qOptions;
      });

      let linkOpt: SelectOption | undefined = linkOptions.value.find(
        (option) => option.slug == question.link
      );
      if (linkOpt) {
        wrapper.link = {
          name: linkOpt.name,
          slug: linkOpt.slug,
        };
      }

      wrapper.conditional = question.conditional;
      wrapper.linkconditionvalue = question.linkconditionvalue;
      wrapper.flag = question.flag;
      wrapper.flagvalue = question.flagvalue;
      wrapper.required = question.required;
      wrapper.photo = question.photo;
      wrapper.notes = question.notes;
      wrapper.fixflocategory = question.fixflocategory;
      wrapper.fixflosubcategory = question.fixflosubcategory;

      setQuestionWrapper(wrapper);
    }
    const modal = midTermQuestionModalEdit.value;
    modal.show();
};

const remove = async (question: Compliancequestion) => {
  try {
    // Use confirmation modal logic
    await store.commit('customerdictionary/removeFromComplianceList', question);
    toasted.success("Question deleted");
  } catch (err: any) {
    // Display error
  }
};

const onDrop = (dragResult: any) => {
  store.dispatch('customerdictionary/setComplianceList', applyDrag(compliancelist.value, dragResult));
};

const applyDrag = (
  list: Compliancequestion[],
  dragResult: any
): Compliancequestion[] => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return list;

  const result = [...list];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};
const setSelectedQuestion = (question: Compliancequestion) => {
  store.commit('customerdictionary/setSelectedQuestion', question);
};

const setQuestionWrapper = (questionwrapper: QuestionWrapper) => {
  store.commit('customerdictionary/setQuestionWrapper', questionwrapper);
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
table {
  display: table;
}

tr {
  cursor: pointer;
  display: table-row !important;
}
tr[style*="transform"] {
  border-collapse: separate !important;
}

.btn.disabled {
  cursor: not-allowed;
}

.draggable-item {
  flex-direction: row !important;
  justify-content: space-between;
  cursor: pointer;
}

.opacity-ghost {
  transition: none !important;
  opacity: 0.8;
  background-color: #6495ed;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.3);
}

th,
td {
  &.prompt {
    width: 10rem;
  }
  &.type {
    width: 6rem;
  }
  &.linked {
    width: 3rem;
  }
  &.conditional {
    width: 3rem;
  }
  &.conditionalvalue {
    width: 6rem;
  }
  &.flag {
    width: 3rem;
  }
  &.flagvalue {
    width: 6rem;
  }
  &.required {
    width: 3rem;
  }
  &.photo {
    width: 3rem;
  }
  &.notes {
    width: 3rem;
  }
  &.delete {
    width: 3rem;
  }
}
</style>
