<template>
  <div class="inspectors list">
    <div
      v-if="!inspectors.length && !hasBlockingRequests"
      class="alert alert-warning text-center"
    >
      <h2 class="mt-1 mb-2">No inspectors found</h2>
      <p class="mb-1">
        beginning with &ldquo;<strong>{{
          (route.params.search as string).toUpperCase()
        }}</strong
        >&rdquo;
      </p>
    </div>

    <table v-else class="table table-hover">
      <thead>
        <th width="7%"></th>
        <th>Name</th>
        <th>Mobile</th>
        <th>Email</th>
        <th width="7%"><span class="sr-only">Actions</span></th>
      </thead>
      <tbody>
        <tr
          v-for="inspector in inspectors"
          :key="inspector.id"
          @click="edit(inspector)"
        >
          <td class="align-middle">
            <img
              v-if="
                inspector.picture &&
                inspector.picture.length &&
                inspector.picture[0].src
              "
              :src="actProperty.s3Origin(inspector.picture[0].src)"
              class="avatar"
            />
            <img v-else src="@/assets/images/avatar.jpg" class="avatar" />
          </td>
          <td class="align-middle">{{ inspector.name }}</td>
          <td class="align-middle">{{ inspector.mobile }}</td>
          <td class="align-middle">{{ inspector.email }}</td>
          <td class="align-middle text-right">
            <ul class="list-unstyled mb-0">
              <li class="list-item" @click.stop>
                <button
                  class="btn btn-outline-danger"
                  @click="remove(inspector)"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useToast } from "vue-toastification";
import { Inspector } from '@/models';

// Injected properties
const actProperty: any = inject('actProperty');
const store = useStore();
const router = useRouter();
const route = useRoute();
const toasted = useToast();

// Vuex getters and actions
const hasBlockingRequests = computed(() => store.getters['app/hasBlockingRequests']);
const inspectors = computed(() => store.getters['inspectors/list']);

const deleteInspector = async (inspector: Inspector) => {
  await store.dispatch('inspectors/deleteInspector', inspector);
};

// Methods
const edit = (inspector: Inspector) => {
  if (inspector.hasOwnProperty("id")) {
    router.push({ name: 'inspector', params: { id: inspector.id } });
  } else {
    actProperty.displayError("Error");
  }
};

const remove = async (inspector: Inspector) => {
  actProperty
    .confirmPrompt()
    .then(() => deleteInspector(inspector))
    .then(() => toasted.success("Inspector deleted"))
    .catch((err: any) => actProperty.displayError(err));
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
</style>
