<template>
  <div class="customer">
    <div class="user-header">
      <fieldset>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Activation staus </label>
          <div class="col-sm-4">
            <div v-if="customer.depository && customer.depository.authToken" class="status-enabled chip">
              <i class="fas fa-check-circle"></i> Active
            </div>
            <div v-else class="status-disabled chip">
              <i class="fas fa-minus-circle"></i> Not active
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Copy activation button for email</label>
          <div class="col-sm-4">
            <button class="btn btn-success" @click="actProperty.copyDepositoryActivationButton(customer.id)">
              Activate ACT
            </button>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue';
import { useStore } from 'vuex';
const store = useStore();
const actProperty: any = inject('actProperty');

const customer = computed(() => store.getters['customers/current']);
</script>

<style scoped lang="scss">
.status-enabled {
  display: table-cell;
  vertical-align: middle;
  color: green;
  background-color: rgb(209, 241, 209) !important;
}

.status-disabled {
  display: table-cell;
  vertical-align: middle;
  color: gray;
}

.chip {
  display: inline-block;
  padding: 0 10px;
  height: 35px;
  font-size: 14px;
  line-height: 35px;
  border-radius: 20px;
  background-color: #f1f1f1;
  margin: 2px 0px 0px 0px;
}
</style>