import { snakeCase } from 'lodash';
import { copyInto } from '@/utilities';

export class Maintenanceflag {
    public slug: string;
    public flag: string;
    public symbol: string;
    public colour: string;
    public backgroundcolour: string;
    public description: string;
    public displayguide: string;
    public pdfDisplay: string;

    /**
     * Create an object from a JSON representation
     * @param data
     * @return Report
     */
    public static parse(data: object) {
        const obj = new Maintenanceflag;

        copyInto(obj, data);

        if (!obj.slug) {
            obj.slug = snakeCase(obj.description);
        }

        return obj;
    }

    /**
     * Constructor
     */
    public constructor() {
        this.slug = '';
        this.flag = '';
        this.symbol = '';
        this.colour = '';
        this.backgroundcolour = '';
        this.description = '';
        this.displayguide = '';
        this.pdfDisplay = '';
    }

}
