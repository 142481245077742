<template>
  <div
    class="modal fade"
    data-keyboard="true"
    tabindex="-1"
    role="dialog"
    id="add-establishment-modal"
    aria-labelledby="`add-establishment-modal-label`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="add-establishment-modal-label">
            Development
          </h5>

          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <form>
            <fieldset>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label offset-2">Company</label>
                <div class="col-sm-6">
                  <multiselect-text
                    v-model="customerCompanyName"
                    :value="customerCompanyName"
                    :options="companyNameOptions"
                    :allow-empty="true"
                    :taggable="true"
                    @tag="onTagCompanyName"
                    @select="onSelectCompanyName"
                  >
                    <template #clear
                      v-if="customerCompanyName.length">
                      <i @mousedown.prevent.stop="onTagCompanyName('')"
                        class="multiselect__clear fa fa-times"
                        aria-label="Clear Company Name"
                      ></i>
                    </template>
                  </multiselect-text>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label offset-2">Branch</label>
                <div class="col-sm-6">
                  <multiselect-text
                    v-model="customerBranchName"
                    :value="customerBranchName"
                    :options="branchNameOptions"
                    :allow-empty="true"
                    :disabled="customerCompanyName.length < 1"
                    :taggable="true"
                    @tag="onTagBranchName"
                    @select="onSelectBranchName"
                  >
                    <template #clear v-if="customerBranchName.length">
                      <i @mousedown.prevent.stop="onTagBranchName('')"
                        class="multiselect__clear fa fa-times"
                        aria-label="Clear Branch Name"
                      ></i>
                    </template>
                  </multiselect-text>
                  <p v-if="errorMessage" class="error-message">
                    {{ errorMessage }}
                  </p>
                </div>
              </div>
            </fieldset>
          </form>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary mr-auto"
            data-dismiss="modal"
          >
            Back
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            data-dismiss="modal"
            @click.prevent="add()"
            :disabled="!canAdd()"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Customer } from '@/models';
import { ref, computed, onMounted, inject } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const customer = computed(() => store.getters['customers/current']);
const companyNameOptions = ref<string[]>([]);
const branchNameOptions = ref<string[]>([]);
const selectedCompanyName = ref<string>("");
const selectedBranchName = ref<string>("");
const selectedCustomer = ref<Customer | undefined>();
const errorMessage = ref<string>("");

// Injecting actProperty
const actProperty: any = inject('actProperty');

// Using the Composition API actions
const getCustomers = async (payload?: {
  starts_with?: string;
  company_name?: string;
  branch_name?: string;
}) => {
  return await store.dispatch('customers/getCustomers', payload);
};

const getCompanyNameOptions = (): Promise<any> => {
  return store.dispatch('customers/getCompanyNameOptions');
};

const getBranchNameOptions = async (companyName?: string) => {
  return await store.dispatch('customers/getBranchNameOptions', companyName);
};

const addEstablishment = async (establishment: Customer) => {
  await store.dispatch('customers/addEstablishment', establishment);
};

const customerCompanyName = computed({
  get: () => selectedCompanyName.value,
  set: (name: string) => {
    selectedCompanyName.value = name;
  },
});

const customerBranchName = computed({
  get: () => selectedBranchName.value,
  set: (name: string) => {
    selectedBranchName.value = name;
  },
});

const getErrorMessage = computed({
  get: () => errorMessage.value,
  set: (val: string) => {
    errorMessage.value = val;
  },
});

onMounted(() => {
  getCompanyNameOptions()
  .then((values) => {
    let names = values.filter(
      (c: string) => c != customer.value.companyName
    );
    companyNameOptions.value = names;
  })
  .catch((err) => {
    actProperty.displayError(err);
  });
});

const onSelectCompanyName = async (companyName: string) => {
  if (companyName) {
    selectedCompanyName.value = companyName;
    selectedBranchName.value = "";
    branchNameOptions.value = await getBranchNameOptions(companyName);
  }
};

const onTagCompanyName = async (val: string) => {
  selectedCompanyName.value = val;
  if (!selectedCompanyName.value) {
    selectedBranchName.value = "";
  }
  errorMessage.value = "";
};

const onSelectBranchName = async (branchName: string) => {
  if (branchName) {
    const customers: Customer[] = await getCustomers({
      company_name: customerCompanyName.value,
      branch_name: branchName,
    });

    if (customers.length) {
      selectedBranchName.value = branchName;
    }

    if (customers && customers.length === 1) {
      selectedCustomer.value = customers[0];
      errorMessage.value = "";
    } else if (customers && customers.length > 1) {
      selectedCustomer.value = undefined;
      errorMessage.value = `${customerCompanyName.value} has ${customers.length} branches with name ${selectedBranchName.value}`;
    }
  }
};

const onTagBranchName = async (val: string) => {
  selectedBranchName.value = val;
  errorMessage.value = "";
};

const canAdd = () => {
  return (
    customerCompanyName.value && selectedBranchName.value && !errorMessage.value
  );
};

const add = async () => {
  if (selectedCustomer.value) {
    await addEstablishment(selectedCustomer.value);
  }
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.error-message {
  color: #dc3545;
}
</style>