<template>
  <div class="download">
    <div class="jumbotron">
      <h1 class="display-4">Download</h1>
      <div v-if="documents">
        <span>Click on the link to download</span>
        <ul>
          <li v-for="(document, $index) in documents" :key="$index">
            <a :href="document.src">{{ getFilename(document.src) }}</a>
          </li>
        </ul>
      </div>
      <div v-else>There are currently no documents with this job!</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { Booking } from "@/models";

// Replace class property with a reactive reference
const booking = ref(new Booking());

// Use the store
const store = useStore();
const route = useRoute();

// Convert getBooking action
const getBooking = (id: string): Promise<Booking> => {
  return store.dispatch('diary/getBooking', id);
};

// Mounted lifecycle hook
onMounted(async () => {
  getBooking(route.params.id as string)
  .then((b: Booking) => booking.value = b);
});

// Convert computed property
const documents = computed(() => {
  return booking.value?.dataentrydocuments ?? [];
});

// Convert method
const getFilename = (src: string) => {
  let filename = '';
  if (src) {
    const lastindex = src.lastIndexOf("/");
    if (lastindex >= 0) {
      filename = src.substring(lastindex + 1);
    }
  }
  return filename;
};
</script>


