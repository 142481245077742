import _get from 'lodash/get';

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

export class Signature extends Base {

  public type: string;
  public name: string;
  public email: string;
  public date: string;
  public image: string;

  /**
   * Constructor initialises the variables from the class Signature
   * @param data - the data to construct a type with all properties of <Signature>
   */
  public constructor(data?: Partial<Signature>) {
    super(data);
    data = toCamelCase(data);

    this.type = _get(data, 'type', '');
    this.name = _get(data, 'name', '');
    this.email = _get(data, 'email', '');
    this.date = _get(data, 'date', '');
    this.image = _get(data, 'image', '');

    // Default to "other" if e.g. legacy "guarantor" is passed
    if (false === ['tenant', 'landlord', 'pi', 'other'].includes(this.type)) {
      this.type = 'other';
    }
  }

}
