import { Module } from 'vuex';
import { RootState } from '../types';
import { PropertyState } from './types';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

import { User } from '@/models';

/**
 * Default state
 */
export const state: PropertyState = {
};

/**
 * Use namespaced Vuex modules
 */
const namespaced: boolean = true;

/**
 * Module export
 */
const auth: Module<PropertyState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};

export default auth;
