import _castArray from 'lodash/castArray';
import _get from 'lodash/get';
import { toCamelCase, copyInto } from '@/utilities';
import { Base, SMSTemplate } from '@/models';

export class SMS extends Base {

  public templateid: string;
  public bookingid: string;
  public to: string[];
  public body: string;

  /**
   * Constructor
   */
  public constructor(data?: Partial<SMSTemplate>) {
    super(data);
    data = toCamelCase(data);

    this.templateid = _get(data, 'id', '');
    this.bookingid = _get(data, 'bookingid', '');
    this.body = _get(data, 'body', '');
    this.to = _get(data, 'to', []);
  }
}
