<template>
  <div class="report-section meter-readings">
    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th width="15%">Name</th>
          <th width="15%">Supplier</th>
          <th width="15%">Reference</th>
          <th width="15%">Location</th>
          <th width="15%">Readings</th>
          <th width="20%">Reason for non-inspection</th>
          <th width="5%"><span class="sr-only">Actions</span></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(meter, $index) in meters" :key="meter._uuid">
          <td>
            <!--input
              class="form-control form-control-sm"
              v-model.lazy="meter.name"
            /-->
            <multiselect-text
              v-model="meter.name"
              :options="meterOptions"
              :allow-empty="true"
              :taggable="true"
              :id="$index"
              @tag="onTagMeter"
              @select="editMeter(meter,$index)" 
            >
              <template #clear v-if="meter.name">
                <i
                  @mousedown.prevent.stop="clearMeterName($index)"
                  class="multiselect__clear fa fa-times"
                  aria-label="Clear Meter"
                ></i>
              </template>
            </multiselect-text>
            <div>
              <Photos
                :key="`meter${meter._uuid}in${meter.photos.length}`"
                :photos="meter.photos"
                :path="`meters[${$index}].photos`"
              />
            </div>
          </td>
          <td>
            <input
              class="form-control form-control-sm"
              v-model.lazy="meter.supplier"
              @change="editMeter(meter,$index)" 
            />
          </td>
          <td>
            <input
              class="form-control form-control-sm"
              v-model.lazy="meter.reference"
              @change="editMeter(meter,$index)" 
            />
          </td>
          <td>
            <input
              class="form-control form-control-sm"
              v-model.lazy="meter.location"
              @change="editMeter(meter,$index)" 
            />
          </td>
          <td>
            <input
              v-for="(reading, $readingIndex) in meter.readings"
              :key="$readingIndex"
              class="form-control form-control-sm"
              :value="reading"
              @change="setReading($event, $index, $readingIndex)"
              @keyup.delete="
                removeReadingIfEmpty($event, $index, $readingIndex)
              "
            />
            <button
              class="btn btn-sm btn-primary mt-2"
              @click="addReading($index)"
            >
              <i class="fas fa-plus"></i> Reading
            </button>
          </td>
          <td>
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text">Reason</span>
              </div>
              <input
                class="form-control form-control-sm"
                v-model.lazy="meter.reason"
                @change="editMeter(meter,$index)" 
              />
            </div>
          </td>
          <td>
            <button
              class="btn btn-sm btn-outline-danger"
              @click="removeMeter($index)"
              tabindex="-1"
            >
              <i class="fas fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <button class="btn btn-primary" @click="addNewMeter()">
      <i class="fas fa-plus"></i> Meter
    </button>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, ref } from 'vue';
import { Report, Meter } from "@/models";
import Photos from "@/components/photo/Photos.vue";
import { useStore } from 'vuex';

const store = useStore();
const report = computed((): Report => store.getters['reports/current']);
const actProperty: any = inject('actProperty');

const setReportDeep = (payload: { path: string; data: any }): Promise<any> => {
  return store.dispatch('reports/setReportDeep', payload);
};
const meterOptions = ref(["Electric", "Gas", "Heat", "Water", "Hot Water"]);

const meters = computed({
  get: () => report.value.meters,
  set: (data) => setReportDeep({ path: "meters", data })
});

const addNewMeter = () => {
  meters.value = meters.value.concat([new Meter()]);
};

const removeMeter = (index: number) => {
  actProperty
      .confirmPrompt()
      .then(
        () =>
          (meters.value = actProperty.removeItemByIndex(
            meters.value,
            index
          ))
      );
};

const addReading = (meterIndex: number) => {
  const updatedReadings = meters.value[meterIndex].readings.concat(['']);
  setReportDeep({ path: `meters[${meterIndex}].readings`, data: updatedReadings });
};

const setReading = ($event: any, meterIndex: number, readingIndex: number) => {
  setReportDeep({
    path: `meters[${meterIndex}].readings[${readingIndex}]`,
    data: $event.target.value,
  });
};

const removeReadingIfEmpty = ($event: any, meterIndex: number, readingIndex: number) => {
  let readings = meters.value[meterIndex].readings;
  if ($event.target.value === '' && readings.length > 1) {
    readings.splice(readingIndex, 1);
    setReportDeep({ path: `meters[${meterIndex}].readings`, data: readings });
  }
};

const onTagMeter = (val: string, index: number) => {
  if (index >= 0) {
    setReportDeep({ path: `meters[${index}].name`, data: val });
  }
};

const clearMeterName = (index: number) => {
  if (index >= 0) {
    setReportDeep({ path: `meters[${index}].name`, data: '' });
  }
};
const editMeter = (meter:Meter,index:Number) => {
 setReportDeep({
    path: `meters[${index}]`,
    data:meter
  });
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
input + input {
  margin-top: 0.25rem;
}
</style>
