<template>
  <!-- "CustomerDictionary Types Modal -->
  <div
    class="modal fade"
    :id="`customerdictionary-types-modal`"
    data-keyboard="true"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="`customerdictionary-types-modal-title`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="`customerdictionary-types-modal-title`">
            Dictionary Types
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <table class="table w-100">
          <thead class="thead-dark">
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>System Name</th>
              <th width="60"></th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-primary">
              <td>
                <input
                  class="form-control"
                  v-model="newType.slug"
                  placeholder="Auto-generated"
                  readonly
                  autocomplete="off"
                />
              </td>
              <td>
                <input
                  class="form-control"
                  @keyup.enter="add"
                  v-model="newType.name"
                  placeholder="Required"
                  autocomplete="off"
                />
              </td>
              <td>
                <input
                  class="form-control"
                  @keyup.enter="add"
                  v-model="newType.systemName"
                  placeholder="Optional"
                  autocomplete="off"
                />
              </td>
              <td>
                <button
                  class="btn btn-primary"
                  @click="add"
                  :disabled="!newType.name"
                >
                  <i class="fas fa-plus"></i>
                </button>
              </td>
            </tr>
          </tbody>
          <tbody class="fixed-height">
            <tr v-for="type in currentTypesSorted" :key="type.slug">
              <td>
                <input
                  class="form-control"
                  :value="type.slug"
                  readonly
                  autocomplete="off"
                />
              </td>
              <td>
                <input
                  class="form-control"
                  :value="type.name"
                  @blur="updateName($event, type)"
                  autocomplete="off"
                />
              </td>
              <td>
                <input
                  class="form-control"
                  :value="type.systemName"
                  @blur="updateSystemName($event, type)"
                  :placeholder="type.name"
                  autocomplete="off"
                />
              </td>
              <td>
                <button class="btn btn-outline-danger" @click="remove(type)">
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <!--
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" data-dismiss="modal">
            OK
          </button>
        </div>
        -->
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed, inject } from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';
import { DictionaryType } from '@/models';
import { useToast } from "vue-toastification";

const store = useStore();
const actProperty: any = inject('actProperty');
const newType = ref(new DictionaryType());
const currentTypesSorted = computed(() => store.getters['customerdictionary/currentTypesSorted']);

const addType = (type: DictionaryType): Promise<any> => {
  return store.dispatch('customerdictionary/addType', type);
};

const updateType = (type: DictionaryType): Promise<any> => {
  return store.dispatch('customerdictionary/updateType', type);
};

const setActiveType = (type: DictionaryType): void => {
  store.commit('customerdictionary/setActiveType', type);
};

const removeType = (type: DictionaryType): Promise<any> => {
  return store.dispatch('customerdictionary/removeType', type);
};

// Created lifecycle hook
onMounted(() => {
  reset();
});

// Reset Dictionary Type
const reset = () => {
  newType.value = new DictionaryType();
};

const add = () => {
  newType.value.slug = _.snakeCase(newType.value.getSystemName());
  addType(newType.value)
    .then(() => setActiveType(newType.value))
    .then(() => useToast().success(`Added "${newType.value.name}"`))
    .then(() => reset())
    .catch((err: any) => actProperty.displayError(err));
};

const updateName = ($event: any, type: DictionaryType) => {
  type.name = $event.target.value;
  updateType(type).catch((err: any) => actProperty.displayError(err));
};

const updateSystemName = ($event: any, type: DictionaryType) => {
  type.systemName = $event.target.value;
  updateType(type).catch((err: any) => actProperty.displayError(err));
};

const remove = (type: DictionaryType) => {
  actProperty.confirmPrompt()
    .then(() => removeType(type))
    .then(() => useToast().success(`Removed "${type.name}"`))
    .then(() => reset())
    .catch((err: any) => actProperty.displayError(err));
};

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
::placeholder {
  color: #ddd;
}
</style>
