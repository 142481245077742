import _get from "lodash/get";
import _castArray from "lodash/castArray";
import { copyInto } from "@/utilities";

import { Base } from "@/models";
import { toCamelCase, datetimeToUTC } from "@/utilities";

export class PresetError extends Base {
  public message: string = "";
  public presetnumber: number = -1;
  public category: string = "";   // Either primary or secondary

  /**
   * Constructor
   */
  public constructor(data?: Partial<PresetError>) {
    super(data);
    data = toCamelCase(data);
    this.message = _get(data, "message", "");
    this.presetnumber = _get(data, "presetnumber", -1);
    this.category = _get(data, "category", "");
  }
}
