<template>
  <div class="text-left p-4">
    <div class="jobtype font-weight-bold" v-if="booking.jobtype">
      <h5>
        <span v-if="booking.prep">Prep</span>
        <span v-if="booking.share">Shared</span>
        {{ actProperty.shortReportType(booking.jobtype, booking.subtype, dictionary) }}
      </h5>
      <span v-if="prepdate">(Prep on {{ prepdate }})</span>
      <span v-if="cidate">(CI on {{ cidate }})</span>
    </div>

    <div
      class="address mb-1"
      v-if="booking.addressPreviewLine1 || booking.addressPreviewLine2"
    >
      {{ booking.address.postcode }}
      <br />{{ booking.addressPreviewLine1 }}
    </div>

    <div
      class="customer"
      v-if="
        booking.customer &&
        (booking.customer.companyName || booking.customer.branchName)
      "
    >
      {{ booking.customer.companyName }} -
      {{ booking.customer.branchName }}
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, inject, onMounted, ref, defineProps, defineEmits } from 'vue';
import { useStore } from 'vuex';

import { Dictionary, Inspector, SelectOption, Booking } from "@/models";

const store = useStore();
const actProperty: any = inject('actProperty');

const dictionary = computed((): Dictionary => store.getters['dictionary/current']);

const getBookingWithoutStoringInState = (payload: { id: string, cancelled: string }): Promise<Booking> => {
  return store.dispatch('diary/getBookingWithoutStoringInState', payload);
};

const props = defineProps({
  templateModel: { type: Object, default: () => {} }
});

const booking = computed(() => props.templateModel?.appointmentData);

const prepdate = ref('');
const cidate = ref('');

onMounted(() => {
  if (booking.value.prepbase && booking.value.connectedbooking) {
    getBookingWithoutStoringInState({id: booking.value.connectedbooking, cancelled: 'false'})
      .then((b: Booking) => {
        if (b.id) prepdate.value = b.bookingdate;
      })
      .catch((err) => {});
  } else if (booking.value.prep && booking.value.connectedbooking) {
    getBookingWithoutStoringInState({id: booking.value.connectedbooking, cancelled: 'false'})
      .then((b: Booking) => {
        if (b.id) cidate.value = b.bookingdate;
      })
      .catch((err) => {});
  }
});

</script>

<style scoped lang="scss">
</style>