import _get from "lodash/get";

export class SelectOption {
  name: string;
  slug: string;
  icon?: string;
  date?: string;

  public constructor(data?: Partial<SelectOption>) {
    this.name = _get(data, "name", "");
    this.slug = _get(data, "slug", "");
    this.icon = _get(data, "icon", "");
    this.date = _get(data, "date", "");
  }
}
