/**
 * Getters
 *
 * Vuex allows us to define "getters" in the store.
 * - You can think of them as computed properties for stores.
 * - Like computed properties, a getter's result is cached based on its dependencies,
 *   and will only re-evaluate when some of its dependencies have changed.
 */
import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { InspectorsState } from './types';
import { Inspector } from '@/models/inspector.model';

export const getters: GetterTree<InspectorsState, RootState> = {

    /**
     * is the inspector list empty?
     * 
     * @param {InspectorsState} state 
     * @returns Boolean if list is empty
     */
    isEmpty: (state: InspectorsState): boolean => {
        return state.list.length === 0;
    },

    /**
     * The currently-queried list of inspectors, returned from GET {API_URL}/inspectors
     * 
     * @param {InspectorsState} state 
     * @returns void
     */
    list: (state: InspectorsState): Inspector[] => state.list,

    /**
     * The current Inspector, populated by GET {API_URL}/inspector
     * 
     * @param {InspectorsState} state 
     * @returns void
     */
    current: (state: InspectorsState): Inspector => state.current,

    /**
     * The default Inspector, populated by getDefaultInspector
     * 
     * @param {InspectorsState} state 
     * @returns void
     */
    default: (state: InspectorsState): Inspector => state.default,


    /**
     * The updates which have been made since creating/loading/last-saving the current report
     * 
     * @param {InspectorsState} state 
     * @returns void
     */
    updates: (state: InspectorsState): string[] => state.updates,

    /**
     * The updates which have been made since creating/loading/last-saving the current report
     * 
     * @param {InspectorsState} state 
     * @returns void
     */
    hasUnsavedChanges: (state: InspectorsState): boolean => state.updates.length > 0,
};
