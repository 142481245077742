import _get from 'lodash/get';

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

import { Address } from './address.model';

export class Office extends Base {

  public id: string;
  public name: string;
  public address: Address;
  public email: string;
  public phone: string;

  /**
   * Constructor initialises the variables from the class Office
   * @param data - the data to construct a type with all properties of <Office>
   */
  public constructor(data?: Partial<Office>) {
    super(data);
    data = toCamelCase(data);

    this.id = _get(data, 'id', '');
    this.name = _get(data, 'name', '');
    this.address = new Address(_get(data, 'address'));
    this.email = _get(data, 'email', '');
    this.phone = _get(data, 'phone', '');
  }

}
