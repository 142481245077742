<template>
  <div
    ref="modalElement"
    class="modal"
    data-backdrop="false"
    data-keyboard="true"
    tabindex="-1"
    role="dialog"
    aria-labelledby="`depository-propertylist-modal-label`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="depository-propertylist-modal-label">
            List of tenancies on Depository
          </h5>

          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="row mb-2">
            <td class="col-md-3">
              <input
                type="text"
                class="form-control"
                v-model.lazy="addressLine1"
                placeholder="Address 1"
              />
            </td>
            <td class="col-md-3">
              <input
                type="text"
                class="form-control"
                v-model.lazy="addressLine2"
                placeholder="Address 2"
              />
            </td>
            <td class="col-md-3">
              <input
                type="text"
                class="form-control"
                v-model.lazy="town"
                placeholder="Town"
              />
            </td>
            <td class="col-md-2">
              <input
                type="text"
                class="form-control"
                v-model.lazy="postcode"
                placeholder="Postcode"
              />
            </td>
            <td class="col-md-1 mt-auto mb-auto">
              <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
              <i v-else class="fas fa-sync-alt" @click="load()"></i>
            </td>
          </div>

          <div v-if="errormessage" class="row mb-2 ml-4 text-danger">
            {{ errormessage }}
          </div>

          <div class="row">
            <div class="col-md-12 scrollable">
              <table class="table table-hover">
                <thead>
                  <th width="20%">Move out date</th>
                  <th width="20%">Address</th>
                  <th width="20%"></th>
                  <th width="20%">Town</th>
                  <th width="20%">Postocde</th>
                </thead>
                <tbody>
                  <tr
                    v-for="(tenancy, $index) in tenancylist"
                    :key="$index"
                    @click="select(tenancy)"
                    :class="{ selected: (tenancy.addressLine1 === selectedtenancy.addressLine1
                      && tenancy.addressLine2 === selectedtenancy.addressLine2
                      && tenancy.city === selectedtenancy.city
                      && tenancy.postcode === selectedtenancy.postcode
                      && tenancy.moveOutDate === selectedtenancy.moveOutDate) }"
                  >
                    <td>{{ tenancy.codate }}</td>
                    <td>{{ tenancy.addressLine1 }}</td>
                    <td>{{ tenancy.addressLine2 }}</td>
                    <td>{{ tenancy.city }}</td>
                    <td>{{ tenancy.postcode }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary mr-auto"
            data-bs-dismiss="modal"
            @click="hide()"
          >
            Close
          </button>
          <span
            v-if="
              report && report.depositorytenancy && report.depositorytenancy.uid
            "
            ><strong>Existing tenancy</strong> Move out date:
            {{ report.depositorytenancy.codate }} Address:
            {{ report.depositorytenancy.displayaddress }}</span
          >
          <button type="button" 
            v-if="report.depositorytenancy && report.depositorytenancy.uid"
            class="btn btn-primary ml-auto" 
            :disabled="isSubmitting"
            @click="submit">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import { computed, ref, inject, defineEmits, defineExpose } from 'vue';
import moment from 'moment-timezone';
import _isEmpty from 'lodash/isEmpty';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";

// Importing required utilities
import { checkitem } from "@/utilities";

// Importing models
import {
  Report, Room, Section, Type, Item, Note, Photo, Dictionary, DictionaryRoom, DepositoryTenancy, DepositoryDeduction
} from "@/models";

// Setup
const store = useStore();
const actProperty: any = inject('actProperty');
const toasted = useToast();

// Reactive properties
const addressLine1 = ref('');
const addressLine2 = ref('');
const town = ref('');
const postcode = ref('');
const tenancylist = ref<DepositoryTenancy[]>([]);
const errormessage = ref('');
const loading = ref(false);
const isSubmitting = ref(false);

const modalElement = ref(null);

const emit = defineEmits(['depositorysubmitsuccess']);

// Computed properties
const report = computed((): Report => store.getters['reports/current']);
const dictionary = computed((): Dictionary => store.getters['dictionary/current']);
const itemNumber = computed(() => store.getters['reports/itemNumber']);
const selectedtenancy = computed(() => report.value?.depositorytenancy);

// Methods
const getReport = async (id: string) => {
  return await store.dispatch('reports/getReport', id);
};

const setReportDeep = async (payload: { path: string; data: any }) => {
  await store.dispatch('reports/setReportDeep', payload);
};

const updateReport = async () => {
  return await store.dispatch('reports/updateReportWithAttachments');
};

const submitDeductionsToState = async (id: string) => {
  return await store.dispatch('reports/submitDeductions', id);
};

const uploadReportToState = async (id: string) => {
  return await store.dispatch('reports/uploadReport', id);
};

const updateReportInReportsList = (report: Report) => {
  store.commit('reports/updateReportInReportsList', report);
};

const getDepositoryPropertylist = async (payload: {
  customerid: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postcode: string;
}) => {
  return await store.dispatch('reports/getDepositoryPropertylist', payload);
};

const init = async (report: Report) => {
  if (report?.id) {
    await getReport(report.id).then((fullreport: Report) => {
      errormessage.value = "";
      addressLine1.value = report.address?.line1;
      addressLine2.value = report.address?.line2;
      town.value = report.address?.town;
      postcode.value = report.address?.postcode;
      load();
      preparedeductions();
    });
  }
};

const load = () => {
  loading.value = true;
  errormessage.value = "";
  getDepositoryPropertylist({
    customerid: `${report.value?.customerId}`,
    addressLine1: addressLine1.value,
    addressLine2: addressLine2.value,
    city: town.value,
    postcode: postcode.value,
  })
    .then((tenancylistData) => {
      tenancylist.value = tenancylistData;
      loading.value = false;
    })
    .catch((error) => {
      loading.value = false;
      errormessage.value =
        "Error while trying to retrieve tenancies from Depository: " +
        error.message;
    });
};

const preparedeductions = () => {
  let liabilityflags = ["CLN", "TT", "STTA", "CLR", "INF"];

  if (report.value && report.value.rooms) {
    report.value.rooms.forEach((room, $roomIndex) => {
      if (room.sections) {
        room.sections.forEach((section, $sectionIndex) => {
          if (section.types) {
            section.types.forEach((type, $typeIndex) => {
              if (type.items) {
                type.items.forEach((item, $itemIndex) => {
                  if (item.condition && item.condition.out && item.condition.out.notes) {
                    item.condition.out.notes.forEach((note, $noteIndex) => {

                      let liabilityindex = -1;
                      if (note?.responsibility?.length) {
                        liabilityindex = note?.responsibility?.findIndex((l: string) => {
                          let i: number = liabilityflags.indexOf(l);
                          return i >= 0;
                        });
                      }

                      const path = `rooms[${$roomIndex}].sections[${$sectionIndex}].types[${$typeIndex}].items[${$itemIndex}].condition.out.notes[${$noteIndex}].depositoryDeductions`;
                      if (liabilityindex < 0) {
                        // There is no liability selected
                        // delete any uncommitted deductions
                        setReportDeep({path,data: []});
                      } else if (isCategoryFourRoom(room)) {
                        ensureDeduction(room, section, type, item, note, "GARDENING", path);
                      } else {
                        note?.responsibility?.forEach((responsibility: string) => {
                          switch (responsibility) {
                            case "CLN":
                              ensureDeduction(room, section, type, item, note, "CLEANING", path);
                              break;
                            case "TT":
                            case "STTA":
                              // If section is ceiling, wall and type is decore
                              // or if section is woodwork
                              // create REDECORATION type of deduction
                              let sectionName = section?.name;
                              let typeName = type?.name;
                              if (
                                ((sectionName === "Ceiling" || sectionName === "Wall") &&
                                  typeName === "Décor") ||
                                sectionName === "Woodwork"
                              ) {
                                ensureDeduction(room, section, type, item, note, "REDECORATION", path);
                              } else {
                                ensureDeduction(room, section, type, item, note, "DAMAGE", path);
                              }
                              break;
                            case "CLR":
                            case "INF":
                              ensureDeduction(room, section, type, item, note, "OTHER", path);
                              break;
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  }
};

const isCategoryFourRoom = (room: Room) => {
  let currentroomtype = room?.type;
  let found = dictionary.value.rooms.findIndex(
    (dr: DictionaryRoom) => dr.category === 4 && dr.slug === currentroomtype
  );
  return found >= 0;
};

const ensureDeduction = (room: Room, section: Section, type: Type, item: Item, note: Note, deductiontype: string, path: string) => {
  if (!note?.depositoryDeductions) note.depositoryDeductions = [];
  let index: number = note?.depositoryDeductions?.findIndex(
    (deduction: DepositoryDeduction) => deduction.type === deductiontype
  );

  let existingdeduction: DepositoryDeduction | undefined = undefined;
  let deduction: DepositoryDeduction = new DepositoryDeduction();
  if (index >= 0) {
    deduction = note.depositoryDeductions[index];
    existingdeduction = deduction;
    note.depositoryDeductions.splice(index, 1);
  }
  note.depositoryDeductions.push(deduction);

  deduction.name = "";
  if (room?.name) deduction.name = room.name;
  if (section?.name)
    deduction.name = `${deduction.name}-${section?.name}`;
  if (type?.name)
    deduction.name = `${deduction.name}-${type?.name}`;
  if (item?.name)
    deduction.name = `${deduction.name}-${item?.name}`;
  if (item?.colour)
    deduction.name = `${deduction.name}-${item?.colour}`;

  deduction.description = note.note;
  deduction.type = deductiontype;
  deduction.externalCreationTime = moment()
    .utc()
    .format("YYYY-MM-DD[T]HH:mm:ss.SSSZ");
  let reportref: number = itemNumber.value(item);
  if (reportref) deduction.checkoutReportRef = `${reportref}`;
  if (item?.condition?.out?.photos?.length)
    deduction.evidenceUrls = item?.condition?.out?.photos?.map(
      (photo: Photo) => actProperty.s3Origin(photo.src)
    );

  if (!existingdeduction || !deduction.equals(existingdeduction)) {
    setReportDeep({
      path,
      data: note.depositoryDeductions,
    });
  }
};

const formatAddress = (tenancy: DepositoryTenancy, separator: string = "<br />") => {
  const items = [
      tenancy.addressLine1,
      tenancy.addressLine2,
      tenancy.city,
      tenancy.county,
      tenancy.postcode,
    ];
    return items.filter((el) => !_isEmpty((el || "").trim())).join(separator);
};

const select = (tenancy: DepositoryTenancy) => {
  setReportDeep({ path: "depositorytenancy", data: tenancy });
};

const submit = () => {
  if (report.value.id && report.value.depositorytenancy?.uid) {
      isSubmitting.value = true; 
      updateReport().then(async (report: Report) => {
        if (report?.id) {
          const reportAfterDeductionSubmit = await submitDeductionsToState(report.id);
          const reportAfterReportSubmit = await uploadReportToState(report.id);
          reportAfterReportSubmit.depositorydeductionssubmitdate = reportAfterDeductionSubmit.depositorydeductionssubmitdate;
          isSubmitting.value = false;
          toasted.success(
            `Posted report, meter reading and deductions to Depositary`
          );

          const modal = (modalElement.value as any).$el;
          (modal as any).modal("hide");
          emit('depositorysubmitsuccess', report.id)
        }
      });
    }
};

const hide = () => {
  $(modalElement.value).hide();
}

defineExpose({modalElement, init});

</script>


<style scoped lang="scss">
.scrollable {
  max-height: 40vh;
  min-height: 40vh;
  overflow-y: auto;
}

.selected {
  color: #212529;
  background-color: rgb(229, 251, 231);
}
</style>