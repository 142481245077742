<template>
  <div class="user-header">
    <!--header>
      <div class="row">
        <div class="col-sm-6">
          <h1></h1>
        </div>
        <div class="col-sm-6 text-right">
          <button class="btn btn-outline-primary" data-toggle="modal" data-target="#allocate-customer-to-user-modal">
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </div>
    </!--header-->

    <CustomersPagination class="mt-2"/>

    <table class="table table-fixed mt-2">
      <thead>
        <tr>
          <th width="30%" class="align-middle">Company</th>
          <th width="70%" class="align-middle">Branch</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(group, $groupindex) in filteredgroups" :key="$groupindex">
          <td>
            <div class="multiselecttags">
              <span
                class="multiselecttag"
                :class="{active: isEntireCustomerAllocated(group.name)}"
                @click="toggleAllocateAll(group.name)"
                >{{ group.name }}</span
              >
            </div>
          </td>
          <td>
            <div class="multiselecttags">
              <span class="multiselecttag" 
                :class="{active: brachAllocationUser(group.name, branch) === user.name, passive: brachAllocationUser(group.name, branch) != '' && brachAllocationUser(group.name, branch) != user.name}"
                :title="brachAllocationUser(group.name, branch)"
                v-for="(branch, $branchindex) in group.branches" 
                @click="toggleAllocate(group.name, branch)"
                :key="$branchindex">{{ branch }}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, defineProps } from 'vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import { User, Customer} from '@/models';
import CustomersPagination from './CustomerPagination.vue';

const customers = computed(() => store.getters['usermanagement/customers']);

const store = useStore();
const toasted = useToast();
const user = computed((): User => store.getters['usermanagement/current']);
const users = computed((): User[] => store.getters['usermanagement/users']);
const setUserDeep = (payload: {
  path: string;
  data: any;
}) => {
  store.commit("usermanagement/setUserDeep", payload);
  store.commit('usermanagement/addUnsavedChange', payload.path)
};

const filteredgroups = computed(() => {
  let list = [];
  if (customers.value?.length) {
    customers.value
      .forEach((c: Customer) => {
        const i = list.findIndex((cg: any) => cg.name === c.companyName);
        if (i >= 0) {
          list[i].branches.push(c.branchName);
        } else {
          list.push({ name: c.companyName, branches: [c.branchName] });
        }
      });
  }
  return list;
});

const remove = async (customer: Customer) => {
  if (customer?.id) {
    let newlist = user.value.customers.filter((c: Customer) => c.id != customer.id);
    setUserDeep({path: 'customers', data: [...newlist]})
  }
};

const isEntireCustomerAllocated = (companyName: string) => {
  const branches: Customer[] = customers.value.filter((c: Customer) => c.companyName === companyName);
  let result = false;
  if(user.value?.customers?.length) {
    const unallocated: Customer[] = branches.filter((c: Customer) => {
      const index = user.value.customers.findIndex((uc: Customer) => uc.companyName === companyName && uc.branchName === c.branchName);
      return index < 0;
    });
    result = (unallocated.length === 0);
  }
  return result;
}

const brachAllocationUser = (companyName: string, branchName: string) => {
  let allocatedToCurrentUser = allocatedToUser(user.value, companyName, branchName);
  let result = '';
  if(allocatedToCurrentUser) {
    result = user.value.name
  }
  else if(users.value?.length) {
    const index = users.value.findIndex((u: User) => {
      if(u.id === user.value.id) return false;
      const flag = allocatedToUser(u, companyName, branchName);
      return flag;
    });
    if(index >= 0) {
      result = users.value[index].name;
    }
  }
  return result;
}

const allocatedToUser = (u: User, companyName: string, branchName: string):boolean => {
  let result = false;
  if(u?.customers?.length) {
    const index = u.customers.findIndex((uc: Customer) => uc.companyName === companyName && uc.branchName === branchName);
    if(index >= 0) result = true;
  }
  return result;
}

const toggleAllocate = (companyName: string, branchName: string) => {
  const index = user.value.customers.findIndex((uc: Customer) => uc.companyName === companyName && uc.branchName === branchName);
  if(index >= 0) {
    user.value.customers.splice(index, 1);
    setUserDeep({path: 'customers', data: [...user.value.customers]});
  }
  else {
    const allocatedTo = brachAllocationUser(companyName, branchName);
    if(!allocatedTo) {
      const i = customers.value.findIndex((c: Customer) => c.companyName === companyName && c.branchName === branchName);
      if(i >= 0) {
        setUserDeep({path: 'customers', data: [...user.value.customers, new Customer(customers.value[i])]});
      }
    }
    else {
      toasted.warning(`Currently allocated to ${allocatedTo}`);
    }
  }
}

const toggleAllocateAll = (companyName: string) => {
  const list: Customer[] = customers.value.filter((uc: Customer) => uc.companyName === companyName);
  if(list.length) {
    let all = isEntireCustomerAllocated(companyName);
    let someunallocated = false;
    if(!all) {
      const i = list.findIndex((c: Customer) => {
        const allocatedTo = brachAllocationUser(c.companyName, c.branchName);
        if(!allocatedTo) return true;
        return false;
      });
      if(i >= 0) someunallocated = true;
    }

    if(all || !someunallocated) {
      list.forEach((c: Customer) => {
        const index = user.value.customers.findIndex((uc: Customer) => uc.companyName === c.companyName && uc.branchName === c.branchName);
        if(index >= 0) {
          user.value.customers.splice(index, 1);
        }
      });
    }
    else {
      list.forEach((c: Customer) => {
        const allocatedTo = brachAllocationUser(c.companyName, c.branchName);
        if(!allocatedTo) {
          const index = user.value.customers.findIndex((uc: Customer) => uc.companyName === c.companyName && uc.branchName === c.branchName);
          if(index < 0) {
            user.value.customers.push(new Customer(c));
          }
        }
      });
    }
    setUserDeep({path: 'customers', data: [...user.value.customers]});
  }
}

onMounted(async () => {
});

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.multiselecttag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: rgb(255, 255, 255);
  line-height: 1;
  background: #b6b7b7;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.active {
  background: #41b883;
}

.passive {
  background: #cbffe7;
  color: rgb(143, 140, 140);
}

.multiselecttags {
  display: block;
  font-size: 14px;
}
</style>

        
        