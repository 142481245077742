import { findIndex, get, remove } from 'lodash';

import { MutationTree } from 'vuex';
import { AppRequest, AppState } from './types';

/**
 * Mutations
 *
 * The only way to actually change state in a Vuex store is by committing a mutation.
 * - Vuex mutations are very similar to events: each mutation has a string type and a handler.
 * - The handler function is where we perform actual state modifications - it receives the state as the first argument:
 * - You cannot directly call a mutation handler.
 * - Think of it more like event registration: "When a mutation with type X is triggered, call this handler."
 * - To invoke a mutation handler, you need to call store.commit with its type e.g. `store.commit('setUser', user)`
 */
export const mutations: MutationTree<AppState> = {

  /**
   * Add (start) a request.
   *
   * @param {AppState}          state   AppState
   * @param {AppRequest|string} payload AppRequest, or request ID
   *
   * @returns void
   */
  addRequest(state: AppState, payload: AppRequest | string): void {
    let request: AppRequest;

    if (typeof payload === 'string') {
      // Default to a blocking request when only the id is passed
      request = {
        id: payload,
        blocking: true,
      };
    } else {
      request = payload;
    }

    state.requests.push(request);
  },

  /**
   * Remove (finish) a request.
   *
   * @param {AppState}          state   AppState
   * @param {AppRequest|string} payload AppRequest, or request ID
   *
   * @returns void
   */
  removeRequest(state: AppState, payload: AppRequest | string): void {
    let id: string;

    if (typeof payload === 'string') {
      // Default to a blocking request when only the id is passed
      id = payload;
    } else {
      id = payload.id;
    }

    const index = findIndex(state.requests, (r) => r.id === id);
    state.requests.splice(index, 1);
  },

  /**
   * Sets the app options (<select> <options> from db data)
   *
   * @param state AppState
   * @returns void
   */
  setOptions(state: AppState, options): void {
    state.options = options;
  },

};
