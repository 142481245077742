import moment from "moment-timezone";
import { omitDeep, prependDate } from "@/utilities";

import { Booking, PropertySpec, ReportTiming } from "@/models";

export function convertcommon(
  sourcebooking: Booking,
  targetbooking: Booking,
  deepfunction: any
) {
  deepfunction({
    path: "address",
    data: omitDeep(sourcebooking.address, ["uuid"]),
  });
  deepfunction({
    path: "propertyspec",
    data: omitDeep(sourcebooking.propertyspec, ["uuid"]),
  });
  deepfunction({ path: "managedtype", data: sourcebooking.managedtype });
  deepfunction({
    path: "customer",
    data: omitDeep(sourcebooking.customer, ["uuid"]),
  });
  deepfunction({
    path: "landlords",
    data: omitDeep(sourcebooking.landlords, ["id", "uuid"]),
  });
  if (sourcebooking.propertyspec.floorplan)
    deepfunction({
      path: "propertyspec.floorplan",
      data: omitDeep(sourcebooking.propertyspec.floorplan, ["id", "uuid"]),
    });
  if (sourcebooking?.report?.id) {
    var notes = `Ref ${sourcebooking.report.ref}`;
    if (sourcebooking?.subreports?.length > 0) {
      const subrefs = sourcebooking.subreports.map((r) => r.ref);
      notes = `Ref ${sourcebooking.report.ref} (${subrefs.join(",")})`;
    }
    deepfunction({
      path: "cleaningnotes",
      data: notes,
    });
  }

  if(sourcebooking?.longtermnotes) {
    deepfunction({
      path: "longtermnotes",
      data: sourcebooking.longtermnotes,
    });
  }
}

export function convert(
  sourcebooking: Booking,
  targetbooking: Booking,
  deepfunction: any
) {
  switch (targetbooking.jobtype) {
    case "inventory":
      convertToInv(sourcebooking, targetbooking, deepfunction);
      break;
    case "checkin":
      convertToCI(sourcebooking, targetbooking, deepfunction);
      break;
    case "checkout":
      convertToCO(sourcebooking, targetbooking, deepfunction);
      break;
    case "soc":
      convertToSOC(sourcebooking, targetbooking, deepfunction);
      break;
  }
}

export function convertToInv(
  sourcebooking: Booking,
  targetbooking: Booking,
  deepfunction: any
) {
  switch (sourcebooking.jobtype) {
    case "inventory":
      deepfunction({
        path: "inspector",
        data: omitDeep(sourcebooking.inspector, ["uuid"]),
      });
      // Inv to Inv
      break;
    case "checkin":
      // CI to Inv
      break;
    case "checkout":
    case "soc":
      // CO/SOC to Inv
      break;
  }
}

export function convertToCI(
  sourcebooking: Booking,
  targetbooking: Booking,
  deepfunction: any
) {
  switch (sourcebooking.jobtype) {
    case "inventory":
      // Inv to CI
      deepfunction({
        path: "inspector",
        data: omitDeep(sourcebooking.inspector, ["uuid"]),
      });
      break;
    case "checkin":
      // CI to CI
      break;
    case "checkout":
    case "soc":
      // CO/SOC to CI
      if (targetbooking.internaljobtype === Booking.B2BCI) {
        const inspector = omitDeep(sourcebooking.inspector, ["uuid"]);
        deepfunction({ path: "inspector", data: inspector });
        targetbooking.inspector = inspector;

        const propertyspec = omitDeep(sourcebooking.propertyspec, ["uuid"]);
        deepfunction({ path: "propertyspec", data: propertyspec });
        targetbooking.propertyspec = propertyspec;

        deepfunction({ path: "startdate", data: sourcebooking.enddate });

        const apptime = prependDate(
          moment(sourcebooking.enddate)
            .utc()
            .subtract(15, "minutes")
            .format("hh:mm A"),
          targetbooking.startdate,
          targetbooking.enddateAsDate
        );

        deepfunction({ path: "appointmenttime", data: apptime });
      }

      break;
  }
}

export function convertToCO(
  sourcebooking: Booking,
  targetbooking: Booking,
  deepfunction: any
): Booking {
  sourcebooking.tenants.forEach(tenant => {
    tenant.attending = '';
  });
  
  switch (sourcebooking.jobtype) {
    // Inv/CI to CO
    // Copy over tenants if CO date is after the selected source booking
    case "inventory":
      deepfunction({
        path: "tenants",
        data: omitDeep(sourcebooking.tenants, ["id", "uuid"]),
      });
      break;
    case "checkin":
      deepfunction({
        path: "tenants",
        data: omitDeep(sourcebooking.tenants, ["id", "uuid"]),
      });
      break;
    case "checkout":
      deepfunction({
        path: "inspector",
        data: omitDeep(sourcebooking.inspector, ["uuid"]),
      });
      // CO to CO
      break;
    case "soc":
      // SOC to CO
      break;
  }
  return targetbooking;
}

export function convertToSOC(
  sourcebooking: Booking,
  targetbooking: Booking,
  deepfunction: any
): Booking {
  switch (sourcebooking.jobtype) {
    case "inventory":
    case "checkin":
      // Inv/CI to SOC
      // Copy over tenants if CO date is after the selected source booking
      const sourcedatemoment = moment.utc(sourcebooking.startdateAsDate);
      const targetdatemoment = moment.utc(targetbooking.startdateAsDate);
      if (targetdatemoment.isAfter(sourcedatemoment)) {
        sourcebooking.tenants.forEach(tenant => {
          tenant.attending = '';
        });
        deepfunction({
          path: "tenants",
          data: omitDeep(sourcebooking.tenants, ["id", "uuid"]),
        });
      }
      break;
    case "checkout":
      // CO to SOC
      break;
    case "soc":
      // SOC to SOC
      break;
  }
  return targetbooking;
}
