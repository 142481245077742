<template>
  <form class="form" @submit.prevent="onSubmit">
    <div class="input-group">
      <label for="report-search" class="sr-only">Search</label>
      <input
        id="report-search"
        type="search"
        class="form-control"
        v-model="search"
        :placeholder="placeholder"
      />
      <span class="input-group-btn ml-2">
        <button type="submit" class="btn btn-outline-primary">
          <i class="fas fa-search"></i>
        </button>
      </span>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { computed, defineProps } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ReportsState } from '@/store/reports/types';

const props = defineProps<{
  placeholder: string;
}>();

const store = useStore();
const router = useRouter();

const reportsState = computed(() => store.state.reports as ReportsState);

const search = computed({
  get: () => reportsState.value.search,
  set: (val: string | undefined) => store.commit('reports/setSearch', val)
});

const resetCurrentPage = async () => {
  await store.dispatch('reports/resetCurrentPage');
};

const onSubmit = async () => {
  await router.push({ path: "dereports", query: { search: search.value } });
  await resetCurrentPage();
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
