/**
 * Getters
 *
 * Vuex allows us to define "getters" in the store.
 * - You can think of them as computed properties for stores.
 * - Like computed properties, a getter's result is cached based on its dependencies,
 *   and will only re-evaluate when some of its dependencies have changed.
 */
import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { AuditState } from './types';

export const getters: GetterTree<AuditState, RootState> = {
  auditentitylist: (state: AuditState) => state.auditentitylist,
};
