<template>
  <div class="report">
    <header>
      <div class="row">
        <div class="col-sm-6">
          <h1>System Admin</h1>
        </div>
        <div class="col-sm-6 text-right">
          <button
            class="btn btn-light"
            data-toggle="modal"
            data-target="#add-newuser-modal"
            title="Add User"
          >
            <i class="fas fa-plus"></i> New User
          </button>
        </div>
      </div>
    </header>

    <!-- Tabs -->
    <ul class="nav nav-tabs mb-3" role="tablist">
      <li class="nav-item">
        <router-link
          to="/sysadmin/userlist"
          class="nav-link"
          active-class="active"
        >
          Users
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          to="/sysadmin/grouplist"
          class="nav-link"
          active-class="active"
        >
          Groups
        </router-link>
      </li>
    </ul>

    <!-- Tab content (child routes) -->
    <router-view :key="$route.name" />

    <NewUserModal />
  </div>
</template>

<script setup lang="ts">
import { onMounted }  from 'vue';
import { useStore } from 'vuex';

import NewUserModal from "@/components/modals/NewUserModal.vue";

const store = useStore();

const getGroups = () => {
  store.dispatch('sysadmin/getGroups');
};

onMounted(async () => {
  getGroups();
});
</script>
