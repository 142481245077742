import _get from 'lodash/get';

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

export class Comments extends Base {
  /**
   * client stores the text added by the client
   */
  public client: string;
  public propertyInspector: string;

  /**
   * tenant stores the text added by the tenant
   */
  public tenant: string;

  /**
   * tenantPhotos stores the uploaded image src and upload timestamp added by the tenant
   */
  public tenantPhotos: string[];

  /**
   * clientPhotos stores the uploaded image src and upload timestamp added by the client
   */
  public clientPhotos: string[];

  /**
   * Constructor initialises the variables from the class Comments
   * @param data - the data to construct a type with all properties of <Comments>
   */
  public constructor(data?: Partial<Comments>) {
    super(data);
    data = toCamelCase(data);

    this.client = _get(data, 'client', '');
    this.propertyInspector = _get(data, 'propertyInspector', '');
    this.tenant = _get(data, 'tenant', '');
    this.tenantPhotos = _get(data, 'tenantPhotos', []);
    this.clientPhotos = _get(data, 'clientPhotos', []);
  }

}
