<template>
  <nav>
    <ul class="pagination">
      <li
        v-for="(link, $index) in links"
        :key="$index"
        class="page-item"
        :class="{ active: $route.params.search == link }"
      >
        <router-link :to="`/sysadmin/userlist/${link}`" class="page-link">
          {{ link.toUpperCase() }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import { CognitoUser } from '@/models';
import { onMounted, watch, inject } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const route = useRoute();

const actProperty: any = inject('actProperty');

const links = [
  "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", 
  "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", 
  "0-9",
];

const getUsersAction = (starts_with: string): Promise<CognitoUser[]> => store.dispatch('sysadmin/getUsers', { starts_with });
const getUsers = (starts_with: string) => {
  getUsersAction(starts_with)
  .catch((err: any) =>
    actProperty.displayError(err)
  );
}

const init = () => {
  const search = route.params.search as string;
  if (search) {
    getUsers(search);
  }
};

onMounted(() => {
  init();
});

watch(() => route.params.search, (val, oldVal) => {
  if (val !== oldVal) {
    init();
  }
});
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
