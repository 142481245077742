<template>
  <div>
    <div
      v-for="(deduction, $deductionIndex) in deductions"
      :key="$deductionIndex"
    >
      <div class="chip">
        <div class="chip-head" :class="{ committed: deduction.externalId }">
          {{ deduction.type[0] }}
        </div>
        <div
          class="chip-content"
          :class="{ 'committed-text': deduction.externalId }"
        >
          {{ deduction.type }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, onMounted, defineProps } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment-timezone';
import {
  Report,
  Section,
  Type,
  Item,
  Note,
  DepositoryDeduction,
  Room,
  Photo,
  Dictionary,
  DictionaryRoom,
} from "@/models";

const actProperty: any = inject('actProperty');

const props = defineProps({
  item: { type: Item },
  note:{ type: Note, required: true},
  noteIndex: { type: Number},
});

const store = useStore();
const report = computed(() => store.getters['reports/current'] as Report);
const dictionary = computed(() => store.getters['dictionary/current'] as Dictionary);
const itemNumber = store.getters['reports/itemNumber'];

const roomIndex = inject<number>('roomIndex');
const sectionIndex = inject<number>('sectionIndex');
const typeIndex = inject<number>('typeIndex');
const typeName = inject<string>('typeName');
const typeSlug = inject<string>('typeSlug');
const itemIndex = inject<number>('itemIndex');

const setReportDeep = async (payload: { path: string; data: any }) => {
  await store.dispatch('reports/setReportDeepWithoutCounting', payload);
};

const room = computed(() => {
  let r = new Room();
  if (roomIndex !== undefined && report.value?.rooms) {
    r = report.value.rooms[roomIndex];
  }
  return r;
});

const section = computed(() => {
  let s = new Section();
  if (room.value && sectionIndex !== undefined) {
    s = room.value.sections[sectionIndex];
  }
  return s;
});

const type = computed(() => {
  let t = new Type();
  if (section.value && typeIndex !== undefined) {
    t = section.value.types[typeIndex];
  }
  return t;
});

const deductions = computed(() => {
  return props.note?.depositoryDeductions || [];
});

const isCategoryFourRoom = () => {
  let currentRoomType = room.value.type;
  let found = dictionary.value.rooms.findIndex(
    (dr: DictionaryRoom) => dr.category === 4 && dr.slug === currentRoomType
  );
  return found >= 0;
};

// Logic inside mounted lifecycle hook
onMounted(() => {
  let liabilityflags: string[] = ["CLN", "TT", "STTA", "CLR", "INF"];
    let liabilityindex = -1;
    if (props.note?.responsibility?.length) {
      liabilityindex = props.note?.responsibility?.findIndex((l: string) => {
        let i: number = liabilityflags.indexOf(l);
        return i >= 0;
      });
    }
    if (liabilityindex < 0) {
      // There is no liability selected
      // delete any uncommitted deductions
      setReportDeep({
        path: `rooms[${roomIndex}].sections[${sectionIndex}].types[${typeIndex}].items[${itemIndex}].condition.out.notes[${props.noteIndex}].depositoryDeductions`,
        data: [],
      });
    } else if (isCategoryFourRoom()) {
      ensureDeduction("GARDENING");
    } else {
      props.note?.responsibility?.forEach((responsibility: string) => {
        switch (responsibility) {
          case "CLN":
            ensureDeduction("CLEANING");
            break;
          case "TT":
          case "STTA":
            // If section is ceiling, wall and type is decore
            // or if section is woodwork
            // create REDECORATION type of deduction
            let sectionName = section.value?.name;
            let typeName = type.value?.name;
            if (
              ((sectionName === "Ceiling" || sectionName === "Wall") &&
                typeName === "Décor") ||
              sectionName === "Woodwork"
            ) {
              ensureDeduction("REDECORATION");
            } else {
              ensureDeduction("DAMAGE");
            }
            break;
          case "CLR":
          case "INF":
            ensureDeduction("OTHER");
            break;
        }
      });
    }
});

const ensureDeduction = (deductiontype: string) => {
  if (!props.note?.depositoryDeductions) props.note!.depositoryDeductions = [];
    let index: number | undefined = props.note?.depositoryDeductions?.findIndex(
      (deduction: DepositoryDeduction) => deduction.type === deductiontype
    );

    let existingdeduction: DepositoryDeduction | undefined = undefined;
    let deduction: DepositoryDeduction = new DepositoryDeduction();
    if (index && index >= 0) {
      deduction = props.note!.depositoryDeductions[index];
      existingdeduction = deduction;
      props.note!.depositoryDeductions.splice(index, 1);
    }
    props.note!.depositoryDeductions.push(deduction);

    deduction.name = "";
    if (room.value?.name) deduction.name = room.value.name;
    if (section.value?.name)
      deduction.name = `${deduction.name}-${section.value?.name}`;
    if (type.value?.name)
      deduction.name = `${deduction.name}-${type.value?.name}`;
    if (props.item?.name)
      deduction.name = `${deduction.name}-${props.item?.name}`;
    if (props.item?.colour)
      deduction.name = `${deduction.name}-${props.item?.colour}`;

    deduction.description = props.note!.note;
    deduction.type = deductiontype;
    deduction.externalCreationTime = moment()
      .utc()
      .format("YYYY-MM-DD[T]HH:mm:ss.SSSZ");
    let reportref: number = itemNumber(props.item);
    if (reportref) deduction.checkoutReportRef = `${reportref}`;
    if (props.item?.condition?.out?.photos?.length)
      deduction.evidenceUrls = props.item?.condition?.out?.photos?.map(
        (photo: Photo) => actProperty.s3Origin(photo.src)
      );

    if (!existingdeduction || !deduction.equals(existingdeduction)) {
      setReportDeep({
        path: `rooms[${roomIndex}].sections[${sectionIndex}].types[${typeIndex}].items[${itemIndex}].condition.out.notes[${props.noteIndex}].depositoryDeductions`,
        data: props.note!.depositoryDeductions,
      });
    }
};

</script>


<style scoped lang="scss">
.chip {
  display: inline-flex;
  flex-direction: row;
  background-color: #e5e5e5;
  color: rgb(127, 127, 127);
  border: none;
  cursor: default;
  height: 20px;
  outline: none;
  padding: 0;
  font-size: 10px;
  font-color: #333333;
  font-family: "Open Sans", sans-serif;
  white-space: nowrap;
  align-items: center;
  border-radius: 10px;
  vertical-align: middle;
  text-decoration: none;
  justify-content: center;
}
.chip-head {
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: #969696;
  font-size: 1.25rem;
  flex-shrink: 0;
  align-items: center;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
  width: 20px;
  color: #fff;
  height: 20px;
  font-size: 10px;
  margin-right: -4px;
}

.committed {
  background-color: #32c5d2;
}
.committed-text {
  color: black;
}
.chip-content {
  cursor: inherit;
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
}
</style>