<template>
  <div class="card bg-white" :id="`item-${item._uuid}`">
    <div class="card-header">
      <div class="button-group">
        <button @click="hideImages()" class="btn btn-sm btn-outline-info">
          <i class="fas fa-camera"></i>
        </button>
        <button @click="remove()" class="btn btn-sm btn-outline-danger">
          <i class="fas fa-trash"></i>
        </button>
      </div>
      <code class="float-right text-muted mt-2 mb-1 mr-2">
        {{ typeSlug }}
      </code>
      <h4 class="h5 mb-1 mt-1">{{ itemNumber(item) }}. {{ typeName }}</h4>
    </div>

    <div class="card-body">
      <table class="table">
        <tbody>
          <tr>
            <td
              width="15%"
              v-if="
                !actProperty.isFactoryreport(report.type) || item.locallyadded
              "
            >
              <!-- Item -->
              <content-editable
                :content="item.name"
                :multiline="true"
                @update="
                  setReportDeep({
                    path: getItemPath('name'),
                    data: $event,
                  })
                "
              ></content-editable>
            </td>
            <td width="5%" v-if="!actProperty.isFactoryreport(report.type)">
              <!-- Qty -->
              <content-editable
                :content="item.qty"
                :integer="true"
                @update="
                  setReportDeep({
                    path: getItemPath('qty'),
                    data: $event,
                  })
                "
              ></content-editable>
            </td>
            <td width="7%" v-if="!actProperty.isFactoryreport(report.type)">
              <!-- Colour -->
              <content-editable
                :content="item.colour"
                @update="
                  setReportDeep({
                    path: getItemPath('colour'),
                    data: $event,
                  })
                "
              ></content-editable>
            </td>
            <td width="23%">
              <!-- Condition In -->
              <table class="table table-sm table-borderless">
                <tr v-if="!getConditionInNotes().length">
                  <button
                    @click="insertNote('in', 0, $event)"
                    class="btn btn-sm btn-outline-secondary"
                  >
                    <i class="fas fa-plus"></i> Note
                  </button>
                </tr>
                <tr
                  v-for="(note, $noteIndex) in getConditionInNotes()"
                  :key="note._uuid"
                >
                  <td width="90%">
                    <content-editable
                      :ref="el => { dynamicRefs['in-notes'][$noteIndex] = el }"
                      :content="note.note"
                      :data-uuid="note._uuid"
                      @insert="insertNote('in', $noteIndex, $event)"
                      @update="updateNote('in', $noteIndex, $event)"
                      @delete="deleteNote('in', $noteIndex)"
                    >
                    </content-editable>
                  </td>
                  <td>
                    <button
                      v-if="
                        !actProperty.isFactoryreport(report.type) &&
                        note.originalnote &&
                        note.originalnote != note.note
                      "
                      @click="toggleNotesFlagged('in', $noteIndex, note)"
                      class="btn btn-sm btn-outline-secondary no-border"
                      v-bind:class="{
                        flaggednote: note.flagged,
                      }"
                    >
                      <i class="fas fa-flag"></i>
                    </button>
                  </td>
                </tr>
                <tr v-if="actProperty.isFactoryreport(report.type)">
                  <td style="padding-top: 5px">
                    <button
                      class="btn btn-light maintenanceflagbutton"
                      v-for="maintenanceflag in maintenanceflags"
                      :key="maintenanceflag.slug"
                      :style="getMaintenanceButtonStyle(maintenanceflag)"
                      @click="setMaintenanceflag(maintenanceflag)"
                    >
                      {{ maintenanceflag.flag }}
                    </button>
                  </td>
                </tr>
              </table>
            </td>
            <td v-if="!isCheckOut()" width="50%">
              <table v-if="showPhotos" class="table table-sm table-borderless">
                <!-- Photos In -->
                <Photos
                  :key="`item${item._uuid}in${inPhotoCount(item)}`"
                  :photos="getInPhotos(item)"
                  :path="getItemPath('condition.in.photos')"
                />
              </table>
            </td>
            <td v-if="isCheckOut()" width="50%">
              <!-- Condition Out -->
              <table
                v-if="isCheckOut()"
                class="table table-sm table-borderless"
              >
                <tr>
                  <condition-same-as-check-in />
                </tr>
                <tr v-if="isSameAsCheckIn()">
                  <td>
                    <!-- Same as Check-In Note -->
                    <content-editable
                      :content="saciNote"
                      @update="
                        setReportDeep({
                          path: getItemPath(
                            'condition.out.sameAsCheckin.note.note'
                          ),
                          data: $event,
                        })
                      "
                    >
                    </content-editable>
                  </td>
                  <td></td>
                  <td>
                    <!-- Same as Check-In Responsibility -->
                    <condition-responsibilities
                      :note-path="
                        getItemPath('condition.out.sameAsCheckin.note')
                      "
                    />
                  </td>
                </tr>
                <tr v-if="!getConditionOutNotes().length">
                  <button
                    @click="insertNote('out', 0, $event)"
                    class="btn btn-sm btn-outline-secondary"
                  >
                    <i class="fas fa-plus"></i> Note
                  </button>
                </tr>
                <tr
                  v-for="(note, $noteIndex) in getConditionOutNotes()"
                  :key="note._uuid"
                >
                  <td>
                    <content-editable
                      :ref="el => { dynamicRefs['out-notes'][$noteIndex] = el }"
                      :content="note.note"
                      :data-uuid="note._uuid"
                      @insert="insertNote('out', $noteIndex, $event)"
                      @update="updateNote('out', $noteIndex, $event)"
                      @delete="deleteNote('out', $noteIndex)"
                    >
                    </content-editable>
                  </td>
                  <td>
                    <button
                      v-if="note.originalnote && note.originalnote != note.note"
                      @click="toggleNotesFlagged('out', $noteIndex, note)"
                      class="btn btn-sm btn-outline-secondary no-border"
                      v-bind:class="{
                        flaggednote: note.flagged,
                      }"
                    >
                      <i class="fas fa-flag"></i>
                    </button>
                  </td>
                  <td>
                    <!-- Responsibility -->
                    <condition-responsibilities
                      :note-path="
                        getItemPath(`condition.out.notes[${$noteIndex}]`)
                      "
                    />
                  </td>
                </tr>
              </table>
              <div
                v-if="
                  isCheckOut() && report.flagged && hasResponsibilities(item)
                "
                class="float-right text-danger"
              >
                <i class="far fa-flag"></i>
              </div>
            </td>
          </tr>
          <tr v-if="isCheckOut() && showPhotos">
            <td colspan="3"></td>
            <td>
              <!-- Photos In -->
              <Photos
                :key="`item${item._uuid}in${item.condition.in.photos.length}`"
                :photos="getInPhotos(item)"
                :path="getItemPath('condition.in.photos')"
              />
            </td>
            <td>
              <!-- Photos Out -->
              <div v-if="isCheckOut()">
                <Photos
                  :key="`item${item._uuid}out${outPhotoCount(item)}`"
                  :photos="getOutPhotos(item)"
                  :path="getItemPath('condition.out.photos')"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import _isEmpty from "lodash/isEmpty";
import ContentEditable from '../ContentEditable.vue';
import ConditionResponsibilities from "@/components/report/ConditionResponsibilities.vue";
import ConditionSameAsCheckIn from "@/components/report/ConditionSameAsCheckIn.vue";
import Photos from '@/components/photo/Photos.vue';

import { ref, PropType, nextTick, toRefs, computed, inject, provide, getCurrentInstance, defineProps, defineExpose } from 'vue';
import { useStore } from 'vuex';
import _get from 'lodash/get';
import { Condition, Item, Maintenanceflag, Note, Report } from '@/models';
import { ReportsState } from '@/store/reports/types';


// Injects
const roomIndex = inject<number>('roomIndex')!;
const sectionIndex = inject<number>('sectionIndex')!;
const typeIndex = inject<number>('typeIndex')!;
const typeName = inject<string>('typeName')!;
const typeSlug = inject<string>('typeSlug')!;

const props = defineProps({
  index: {type: Number, default: -1},
  item: {type: Object as PropType<Item>, default: new Item},
});

provide('itemIndex', props.index);

const store = useStore();
const actProperty: any = inject('actProperty');
const instance = getCurrentInstance();

const reportsState = computed(() => store.state.reports as ReportsState);

// Convert props to refs
const { index, item } = toRefs(props);
provide("itemIndex", index?.value);

const currentReportItems = computed((): Item[] => store.getters['reports/currentReportItems']);
const itemNumber = (item: Item): number => {
  return currentReportItems.value.findIndex((x: Item) => x._uuid === item._uuid) + 1;
};

const maintenanceflags = computed((): Maintenanceflag[] => store.getters['customerdictionary/maintenanceflags']);

const setReport = (report: Report): Promise<any> => {
  return store.dispatch('reports/setReport', report)
}

const setReportDeep = (payload: { path: string; data: any }): Promise<any> => {
  return store.dispatch('reports/setReportDeep', payload)
}

const removeFromRooms = (payload: {
    roomIndex: number;
    sectionIndex: number;
    typeIndex: number;
    itemIndex: number;
    inOrOut?: string;
    noteIndex?: number;
  }): Promise<any> => {
  return store.dispatch('reports/removeFromRooms', payload)
}

const showPhotos = ref(true);
const dynamicRefs = ref({});
dynamicRefs.value = {'in-notes': [], 'out-notes': []};

const report = computed({
  get: (): Report => reportsState.value.current,
  set: (data: Report) => {
    setReport(data).then(() => {
    nextTick(() => {
      console.log("Updated Item");
    });
  });
  }
});


/**
 * Hide/show images
 */
const hideImages = (): void => {
  showPhotos.value = !showPhotos.value;
}

/**
 * Generate hashes from objects
 */
const getHash = (obj: any, index: number) => {
  return `${index}-${actProperty.hashObj([obj, index])}`;
}

/**
 * Check if report type is Check-Out
 */
const isCheckOut = () => {
  return report.value.type == "checkout";
}

const getMaintenanceButtonStyle = (maintenanceflag: Maintenanceflag) => {
  let style = "";
  if (
    item?.value &&
    item?.value.condition &&
    item?.value.condition.responsibility
  ) {
    if (Array.isArray(item?.value.condition.responsibility)) {
      style += item?.value.condition.responsibility.includes(
        maintenanceflag.flag
      )
        ? "background-color: " + maintenanceflag.colour
        : "";
    } else {
      actProperty.displayError(
        Error(
          `The Note Responsibility is not an array: ${JSON.stringify(
            item?.value.condition.responsibility
          )}`
        )
      );
    }
  }
  return style;
}

const setMaintenanceflag = (maintenanceflag: Maintenanceflag) => {
  if (item?.value) {
    if (!item?.value.condition) {
      item.value.condition = {
        responsibility: [],
        previousresponsibility: [],
        in: new Condition(),
        out: new Condition(),
      };
    }
    // Toggle flag if it is already selected
    if (item?.value.condition.responsibility[0] === maintenanceflag.flag)
      item.value.condition.responsibility[0] = "";
    else item.value.condition.responsibility[0] = maintenanceflag.flag;
    setReportDeep({
      path: getItemPath(`condition`),
      data: item?.value.condition,
    });
  }
}

/**
 * Returns boolean value if condition out notes contains responsibilities
 *
 * @param {Item} item
 */
const hasResponsibilities = (item: Item): boolean => {
  return item.condition.out.notes.some(
    (note: Note) => false === _isEmpty(note.responsibility)
  );
}

/**
 * Remove Item from Room
 */
const remove = () => {
  actProperty.confirmPrompt().then(() =>
    removeFromRooms({
      roomIndex: roomIndex,
      sectionIndex: sectionIndex,
      typeIndex: typeIndex,
      itemIndex: index.value,
    })
  );
}

/**
 * Returns Selected Class Name
 *
 * @param {String} responsibility
 * @param {Note} note
 */
const responsibilitySelectedClassName = (responsibility: string, note: Note) => {
  let className = "";

  if (note.responsibility === undefined) {
    note.responsibility = [];
  } else if (Array.isArray(note.responsibility)) {
    className =
      note.responsibility.indexOf(responsibility) > -1 ? "selected" : "";
  } else if (typeof note.responsibility === "string") {
    className = note.responsibility === responsibility ? "selected" : "";
    note.responsibility = [note.responsibility];
  }

  return className;
}

/**
 * Returns Active Item Path
 */
const getItemPath = (append: string) => {
  if (append) {
    append = `.${append}`;
  }
  return `rooms[${roomIndex}].sections[${sectionIndex}].types[${typeIndex}].items[${index?.value}]${append}`;
}

/**
 * Return Check-In Photos from Items
 *
 * @param {Item} item
 */
const getInPhotos = (item: Item) => {
  return _get(item, "condition.in.photos", []);
}
const inPhotoCount = (item: Item) => {
  const photos = _get(item, "condition.in.photos", []);
  return photos.length;
}

/**
 * Return Check-Out Photos from Items
 *
 * @param {Item} item
 */
const getOutPhotos = (item: Item) => {
  return _get(item, "condition.out.photos", []);
}
const outPhotoCount = (item: Item) => {
  const photos = _get(item, "condition.out.photos", []);
  return photos.length;
}

/**
 * Some special stuff is required here cos we are using contenteditable.
 */
const setCaret = (el: any) => {
  if (!el) {
    return;
  }

  const selection = window.getSelection();

  if (selection) {
    selection.removeAllRanges();
  }

  const range = document.createRange();

  range.selectNodeContents(el);

  // if (el.firstChild) {
  //   range.setStart(el.firstChild, el.innerText.length);
  //   range.setEnd(el.firstChild, el.innerText.length);
  // } else {
  //   range.setStart(el, el.innerText.length);
  //   range.setEnd(el, el.innerText.length);
  // }

  if (selection) {
    selection.addRange(range);
  }
}

/**
 *  Place cursor at the end of given ediable div element
 */
const setCursorAtEnd = (el: any) => {
  if (!el) {
    return;
  }

  const range = document.createRange();
  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
  }

  if (el.firstChild) {
    range.setStart(el.firstChild, el.firstChild.length);
  } else {
    range.setStart(el, 0);
  }
  range.collapse(true);
  if (selection) {
    selection.removeAllRanges();
    selection.addRange(range);
  }
}

/**
 * Add New Condition Note to a Room
 *
 * @param {String} inOrOut
 * @param {Number} noteIndex
 */
const insertNote = (inOrOut: string, noteIndex: number, event: any) => {
  const notes: Note[] = _get(item?.value, `condition[${inOrOut}].notes`, []);

  let insertAtIndex = 0;

  if (typeof noteIndex === "number") {
    insertAtIndex = noteIndex + 1;
  }

  const note = new Note();

  let cursorPos = -1;
  let selection: Selection | null = window.getSelection();
  if (selection) {
    cursorPos = selection.anchorOffset;
  }

  if (notes.length > noteIndex) {
    let currentNote = notes[noteIndex];
    let currentNoteValue = event.currentTarget.innerText;
    if (
      currentNoteValue &&
      cursorPos >= 0 &&
      currentNoteValue.length > cursorPos
    ) {
      let contentsBeforeCursor = currentNoteValue.substring(0, cursorPos);
      let contentsAfterCursor = currentNoteValue.substring(
        cursorPos,
        currentNoteValue.length
      );
      currentNote.note = contentsBeforeCursor;
      note.note = contentsAfterCursor;
      const ref = dynamicRefs.value[`${inOrOut}-notes`][noteIndex] as any;
      if (ref) {
        ref.$el.innerHTML = contentsBeforeCursor;
      }
    }
  }

  notes.splice(insertAtIndex, 0, note);

  setReportDeep({
    path: getItemPath(`condition[${inOrOut}].notes`),
    data: notes,
  })
    .then(() => instance?.proxy?.$forceUpdate())
    .then(() =>
      nextTick(() => {
        const ref = dynamicRefs.value[`${inOrOut}-notes`][insertAtIndex] as any;
        if (ref) {
          setCursorAtEnd(ref.$el);
        }
      })
    );
}

/**
 * Update Condition Note
 *
 * @param {String} inOrOut
 * @param {Number} noteIndex
 * @param {$event}
 */
const updateNote = (inOrOut: string, noteIndex: number, $event: any) => {
  const notes: Note[] = _get(item?.value, `condition[${inOrOut}].notes`, []);
  let currentNote = notes[noteIndex];
  if (!currentNote?.originalnote) {
    let onote = currentNote?.note;
    if (!onote) {
      onote = " ";
    }
    setReportDeep({
      path: getItemPath(
        `condition[${inOrOut}].notes[${noteIndex}].originalnote`
      ),
      data: onote,
    }).then(() => instance?.proxy?.$forceUpdate());
  }
  setReportDeep({
    path: getItemPath(`condition[${inOrOut}].notes[${noteIndex}].note`),
    data: $event,
  }).then(() => instance?.proxy?.$forceUpdate());
}

/**
 * Flag Note for feedback by QC
 */
const toggleNotesFlagged = (
  inOrOut: string,
  noteIndex: number,
  note: Note
): void => {
  note.flagged = !note.flagged;
  setReportDeep({
    path: getItemPath(
      `condition[${inOrOut}].notes[${noteIndex}].flagged`
    ),
    data: note.flagged,
  }).then(() => instance?.proxy?.$forceUpdate());
}

/**
 * Delete Condition Note from Rooms
 *
 * @param {String} inOrOut
 * @param {Number} noteIndex
 */
const deleteNote = (inOrOut: string, noteIndex: number) => {
  removeFromRooms({
    roomIndex: roomIndex,
    sectionIndex: sectionIndex,
    typeIndex: typeIndex,
    itemIndex: index.value,
    inOrOut,
    noteIndex,
  })
    .then(() => instance?.proxy?.$forceUpdate())
    .then(() =>
      nextTick(() => {
        const refs = dynamicRefs.value[`${inOrOut}-notes`] as any[];
        if (refs.length) {
          if (noteIndex > 0) {
            setCursorAtEnd(refs[noteIndex - 1].$el);
          } else {
            setCursorAtEnd(refs[0].$el);
          }
        }
      })
    );
}

/**
 * Return boolean value if SACI button is active
 */
const isSameAsCheckIn = (): boolean => {
  return _get(item?.value, "condition.out.sameAsCheckin.active", false);
}

/**
 * Return 'Same as Check-In' String in CO Notes
 */
const saciNote = computed((): string => {
  return _get(
    item.value,
    "condition.out.sameAsCheckin.note.note",
    "Same as Check-In"
  );
});

/**
 * Returns Check-In Condition Notes
 */
const getConditionInNotes = () => {
  const notes = _get(item.value, "condition.in.notes", false);
  if (notes === false) {
    return [];
  }
  return notes;
}

/**
 * Returns Check-Out Condition Notes
 */
const getConditionOutNotes = () => {
  const notes = _get(item.value, "condition.out.notes", false);
  if (notes === false) {
    return [];
  }
  return notes;
}

defineExpose({dynamicRefs});
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/bootstrap/_variables.scss";

.card {
  margin-bottom: 1rem;
  // &:last-child {
  //   margin-bottom: 0;
  // }
}

.card-header {
  .button-group {
    float: right;
    .btn {
      &.disabled {
        opacity: 0.35;
      }
      + .btn {
        margin-left: 0.5rem;
      }
    }
  }
}

.img-100w {
  display: block;
  margin-bottom: 0.5rem;
  max-width: 100%;
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
}

table {
  margin-bottom: 0;
  td {
    border-top: none;
  }
  table {
    margin-bottom: 0.5rem;
    th,
    td {
      padding: 0;
      vertical-align: top;
      // border-bottom: 1px dashed rgba(0,0,0,0.15);
    }
  }
}

.photo-out {
  &:before {
    content: "\f061";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    left: -6px;
    top: 46%;
    opacity: 0.75;
  }
}

.tenant-feedback-jumbotron {
  padding: 0rem 2rem;
}

.approvedcomment {
  background-color: #a3e23f;
}

.flaggednote {
  color: #dc3545;
}

.no-border {
  border: 0px;
}

.maintenanceflagbutton {
  margin-left: 2px;
  margin-right: 2px;
}
</style>
