import _get from 'lodash/get';

import { Base } from '@/models';
import { toCamelCase } from '@/utilities';

export class Key extends Base {

  public code: string;
  public name: string;
  public notes: string;
  public photos: {
    in: string[],
    out: string[]
  };
  public qty: {
    in: number,
    out: number
  };
  public type: string;

  /**
   * Constructor initialises the variables from the class Key
   * @param data - the data to construct a type with all properties of <Key>
   */
  public constructor(data?: Partial<Key>) {
    super(data);
    data = toCamelCase(data);

    this.code = _get(data, 'code', '');
    this.name = _get(data, 'name', '');
    this.photos = {
      in: _get(data, 'photos.in', []),
      out: _get(data, 'photos.out', []),
    };
    this.notes = _get(data, 'notes', '');
    this.qty = {
      in: _get(data, 'qty.in', 0),
      out: _get(data, 'qty.out', 0),
    };
    this.type = _get(data, 'type', '');
  }

}
