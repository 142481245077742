import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { Base, Document } from '@/models';
import { toCamelCase } from '@/utilities';

export class SearchAddressResponse extends Base {

  public addresses: string[];
  public status: string;

  public constructor(data?: Partial<SearchAddressResponse>) {
    super(data);
    data = toCamelCase(data);

    this.status = _get(data, 'status', '');
    this.addresses = _get(data, 'predictions', []).map((p: any) => p.description);
  }

}
