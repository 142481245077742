<template>
  <div class="dictionary section-type-selector">
    <div class="card bg-light mb-3">
      <div class="card-body">
        <div class="row">
          <div class="col-3" v-if="showSections">
            <h2>Section</h2>
            <multiselect
              v-model="activeSection"
              track-by="slug"
              :options="allSections"
            >
            </multiselect>
            <button
              class="btn btn-link pb-0"
              :class="{ 'text-muted': !hasActiveSection }"
              @click="unsetActiveSection"
            >
              Clear
            </button>
          </div>

          <div class="col-3" v-if="showTypes && hasActiveSection">
            <button
              class="btn btn-outline-secondary float-right"
              data-toggle="modal"
              data-target="#customerdictionary-types-modal"
            >
              <i class="fas fa-ellipsis-h"></i>
            </button>
            <h2>Type</h2>
            <multiselect
              v-model="activeType"
              track-by="slug"
              :custom-label="getSystemName"
              :options="currentTypes"
            >
            </multiselect>
            <button
              class="btn btn-link pb-0"
              :class="{ 'text-muted': !hasActiveType }"
              @click="unsetActiveType"
            >
              Clear
            </button>
          </div>

          <div class="col-6" v-if="showItems && hasActiveType">
            <button
              class="btn btn-outline-secondary float-right"
              data-toggle="modal"
              data-target="#customerdictionary-items-modal"
            >
              <i class="fas fa-ellipsis-h"></i>
            </button>
            <h2>Item</h2>
            <multiselect
              v-model="activeItem"
              track-by="slug"
              :options="currentItems"
            >
            </multiselect>
            <button
              class="btn btn-link pb-0"
              :class="{ 'text-muted': !hasActiveItem }"
              @click="unsetActiveItem"
            >
              Clear
            </button>
          </div>
        </div>
        <div
          class="row"
          v-if="showTypes && hasActiveSection && activeType.name == 'Photo'"
        >
          <div class="offset-md-3 col-3">
            <h2 style="margin-bottom: 0em">Minimum required</h2>
            <multiselect
              v-model="minimumRequired"
              track-by="slug"
              :options="minRequiredOptions"
            >
            </multiselect>
            <button
              class="btn btn-link pb-0"
              :class="{ 'text-muted': !hasActiveType }"
              @click="unsetMinimumRequired"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <CustomerDictionaryTypesModal />
    <CustomerDictionaryItemsModal />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watchEffect, defineProps } from 'vue';
import { useStore } from 'vuex';
import { DictionarySection, DictionaryType, DictionaryItem, SelectOption } from '@/models';
import SectionTypeSelector from './partials/SectionTypeSelector.vue';
import CustomerDictionaryTypesModal from '@/components/modals/CustomerDictionaryTypesModal.vue';
import CustomerDictionaryItemsModal from '@/components/modals/CustomerDictionaryItemsModal.vue';
import { useToast } from "vue-toastification";

// Props declaration with types
const props = defineProps<{
  showSections: boolean;
  showTypes: boolean;
  showItems: boolean;
}>();

const store = useStore();
const toasted = useToast();

// State from store
const dictionaryState = computed(() => store.state.customerdictionary);

// Getters
const allSections = computed(() => store.getters['customerdictionary/allSections']);
const currentTypes = computed(() => store.getters['customerdictionary/currentTypes']);
const currentItems = computed(() => store.getters['customerdictionary/currentItems']);
const hasActiveSection = computed(() => store.getters['customerdictionary/hasActiveSection']);
const hasActiveType = computed(() => store.getters['customerdictionary/hasActiveType']);
const hasActiveItem = computed(() => store.getters['customerdictionary/hasActiveItem']);

// Methods converted to arrow functions
const setActiveSection = (section: DictionarySection) => {
  store.commit('customerdictionary/setActiveSection', section);
  unsetActiveType();
};

const setActiveType = (type: DictionaryType) => {
  store.commit('customerdictionary/setActiveType', type);
  unsetActiveItem();
};

const setActiveItem = (item: DictionaryItem) => {
  store.commit('customerdictionary/setActiveItem', item);
};

const activeSection = computed({
  get: () =>  dictionaryState.value.activeSection, // Getter
  set: (newValue) => {
    dictionaryState.value.activeSection = newValue; // Setter
  }
});

const activeType = computed({
  get: () =>  dictionaryState.value.activeType, // Getter
  set: (newValue) => {
    dictionaryState.value.activeType = newValue; // Setter
  }
});

const activeItem = computed({
  get: () =>  dictionaryState.value.activeItem, // Getter
  set: (newValue) => {
    dictionaryState.value.activeItem = newValue; // Setter
  }
});

const minimumRequired = computed({
  get: () =>  {
    let str = activeType.value.minimumRequired.toString();
    return { name: str, slug: str };
  }, // Getter
  set: (newValue) => {
    activeType.value.minimumRequired = parseInt(newValue.name);
    updateType(activeType.value);
  }
});

const updateType = async (type: DictionaryType) => {
  await store.dispatch('customerdictionary/updateType', type);
};

const unsetActiveSection = () => {
  setActiveSection(new DictionarySection());
  unsetActiveType();
};

const unsetActiveType = () => {
  setActiveType(new DictionaryType());
  unsetActiveItem();
};

const unsetActiveItem = () => {
  setActiveItem(new DictionaryItem());
};

const getSystemName = (type: DictionaryType): string => {
  return type.getSystemName();
};

// Computed property for minRequiredOptions
const minRequiredOptions = computed(() => {
  const options: SelectOption[] = [];
  for (let i = 1; i <= 10; i++) {
    let str = i.toString();
    options.push({ name: str, slug: str });
  }
  return options;
});

const unsetMinimumRequired = () => {
  activeType.value.minimumRequired = 0;
  updateType(activeType.value);
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.btn-sm {
  font-size: 0.4rem;
}
.list-group-item {
  cursor: pointer;
}
.list-group-item-active {
  .list-group-sections & {
    background-color: #42a5f5;
  }
  .list-group-types & {
    background-color: #9575cd;
  }
  .list-group-items & {
    background-color: #ffb74d;
  }
}
.list-group {
  max-height: 155px;
  overflow: scroll;
}
.highlight {
  border-color: red;
}
.text-muted {
  opacity: 0.5;
}
</style>
