<template>
  <div class="smstemplate">
    <h1 class="modal-title" id="smstemplate-modal-label">
      SMS template: {{ smstemplate.title }}
    </h1>

    <!-- Tabs -->
    <div class="report-items">
      <ul class="nav nav-tabs mb-3" role="tablist">
        <li class="nav-item">
          <router-link
            :to="{ name: 'sms-template-details'}"
            class="nav-link"
            active-class="active"
          >
            Template
          </router-link>
        </li>
        <li class="nav-item" v-if="!isNewTemplate() && !smstemplate.defaulttemplate">
          <router-link
            :to="{name: 'sms-template-clients',params: {search: route.params.search || 'a'}}"
            class="nav-link"
            active-class="active"
          >
            Customers
          </router-link>
        </li>
      </ul>
    </div>

    <!-- Tab content (child routes) -->
    <router-view :key="$route.name" style="padding-bottom: 60px" />

    <!-- Status Bar (Bottom navbar) -->
    <nav class="navbar fixed-bottom navbar-dark bg-primary">
      <div class="container-fluid">
        <!-- Status Bar Actions -->
        <div id="status-bar-actions" style="margin-left: auto">
          <div class="text-light">
            <span v-if="hasUnsavedChanges">You have unsaved changes</span>
            <span v-else class="text-muted">No unsaved changes</span>
            <div class="btn-group" role="group" aria-label="Secondary Actions">
              <button
                class="btn btn-outline-light"
                :class="{ disabled: !hasUnsavedChanges }"
                @click.prevent="cancel()"
                :disabled="!hasUnsavedChanges"
              >
                <i class="fas fa-ban"></i> Cancel
              </button>
            </div>
            <div class="btn-group" role="group" aria-label="Primary Actions">
              <button
                class="btn btn-light"
                :class="{ disabled: !hasUnsavedChanges }"
                @click.prevent="save()"
                :disabled="isSaving || !hasUnsavedChanges"
              >
                <span v-if="isSaving" class="spinner-border spinner-border-sm">
                  <span class="sr-only">Loading...</span>
                </span>
                <i v-else class="fas fa-save"></i>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps, inject, ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { useToast } from "vue-toastification";
import { SMSTemplate, Dictionary } from "@/models";

const isSaving = ref(false);

const store = useStore();
const toast = useToast();
const router = useRouter();
const route = useRoute();

const smstemplate = computed((): SMSTemplate => store.getters['smstemplates/current']);
const hasUnsavedChanges = computed(() => store.getters['smstemplates/hasUnsavedChanges']);

const actProperty: any = inject('actProperty');

const setSMSTemplate = (smstemplate: SMSTemplate): void => {
  store.commit('smstemplates/setSMSTemplate', smstemplate);
};

const getSMSTemplate = (id: string): Promise<SMSTemplate> => {
  return store.dispatch('smstemplates/getSMSTemplate', id);
};

const addSMSTemplate = (smstemplate: SMSTemplate): Promise<SMSTemplate> => {
  return store.dispatch('smstemplates/addSMSTemplate', smstemplate);
};

const updateSMSTemplate = (smstemplate: SMSTemplate): Promise<SMSTemplate> => {
  return store.dispatch('smstemplates/updateSMSTemplate', smstemplate);
};

const resetUnsavedChanges = (): void => {
  store.commit('smstemplates/resetUnsavedChanges');
};

// Lifecycle hooks
onMounted(async () => {
  resetUnsavedChanges();
  if (isNewTemplate()) {
    setSMSTemplate(new SMSTemplate());
    return;
  }
  await getSMSTemplate(route.params.id as string)
    .then((smstemplate: SMSTemplate) => {
      if (smstemplate === undefined) {
        actProperty.displayError(
          `Could not find smstemplate with ID ${route.params.id as string}`
        );
        router.push({ name: "smstemplates" });
      }
    })
    .catch((err: any) => actProperty.displayError(err));
});

const isNewTemplate = () => {
  return route.params.id == "new";
};

const canSave = () => {
  return smstemplate.value.body;
};

const save = async () => {
  if (canSave()) {
      if (isNewTemplate()) {
        addSMSTemplate(smstemplate.value).then(
          (smstemplate: SMSTemplate) => {
            toast.success(`Saved`);
            router.push({
              name: "smstemplate",
              params: { id: smstemplate.id },
            });
          }
        );
      } else {
        updateSMSTemplate(smstemplate.value).then(
          (smstemplate: SMSTemplate) => {
            router.push({ name: "smstemplates" });
          }
        );
      }
    }
};

const cancel = () => {
  resetUnsavedChanges();
  router.back();
};

</script>


<style lang="scss">
.placeholder {
  background-color: yellow;
}
.body-editor .ck-editor__editable_inline {
  min-height: 400px;
}
.subject-editor .ck-editor__editable_inline {
  max-height: 100px;
}
</style>

<style scoped lang="scss">
#status-bar-buttons,
#status-bar-switches,
#status-bar-actions {
  .btn-group,
  .custom-switch {
    display: inline-block;
    margin: 0 0.5rem;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.status-bar-separator {
  color: #f8f9fa;
}

#status-bar-switches {
  margin-left: 2rem;
}

#status-bar-actions {
  width: 22rem;
  text-align: right;
  span {
    margin-right: 0.25rem;
  }
}
</style>
