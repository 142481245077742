import { ActionTree, ActionContext } from 'vuex';
import { RootState } from '../types';
import { AuthState } from './types';

import { User } from '@/models';

/**
 * Actions
 *
 * Actions are similar to mutations, the differences being that:
 * - Instead of mutating the state, actions commit mutations.
 * - Actions can contain arbitrary asynchronous operations.
 * - Actions are triggered with the store.dispatch method e.g. `store.dispatch('getSettings')`
 */
export const actions: ActionTree<AuthState, RootState> = {

};
