import _get from 'lodash/get';
import moment from "moment-timezone";

import { Base } from '@/models';
import { toCamelCase, datetimeToUTC } from '@/utilities';

export class Alarm extends Base {

  public name: string;
  public location: string;
  public floor: string;
  public condition: string;
  public expirydate: string;
  public expirydatecondition: string;
  public photos: string[];

  /**
   * Constructor initialises the variables from the class Alarm
   * @param data - the data to construct a type with all properties of <Alarm>
   */
  public constructor(data?: Partial<Alarm>) {
    super(data);
    data = toCamelCase(data);

    this.name = _get(data, 'name', '');
    this.location = _get(data, 'location', '');
    this.floor = _get(data, 'floor', '');
    this.condition = _get(data, 'condition', '');
    this.expirydate = _get(data, 'expirydate', '');
    this.expirydatecondition = _get(data, 'expirydatecondition', '');
    this.photos = _get(data, 'photos', []);
  }

  get edate(): Date | undefined {
    let date: Date | undefined = undefined;
    if (this.expirydate) date = moment(this.expirydate, "MMM YYYY").toDate();
    return date;
  }
  set edate(val: Date | undefined) {
    if (val) {
      // Because of daylight saving time, adding 1 hour after midnight to prevent it from showing previous day
      this.expirydate = moment(val).format("MMM YYYY");
    }
    else {
      this.expirydate = '';
    }
  }

}
