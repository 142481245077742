import _get from "lodash/get";
import _castArray from "lodash/castArray";

import { Base, DistanceMatrixRow } from "@/models";
import { toCamelCase } from "@/utilities";

export class DistanceMatrixResponse extends Base {
  public destinationAddresses: string[];
  public originAddresses: string[];
  public rows: DistanceMatrixRow[];
  public status: string;
  public errorMessage: string;

  public constructor(data?: Partial<DistanceMatrixResponse>) {
    super(data);
    data = toCamelCase(data);

    this.destinationAddresses = _get(data, "destinationAddresses", []);
    this.originAddresses = _get(data, "originAddresses", []);
    this.rows = _castArray(_get(data, "rows", [])).map(
      (r: Partial<DistanceMatrixRow>) => {
        return new DistanceMatrixRow(r);
      }
    );
    this.status = _get(data, "status", "");
    this.errorMessage = _get(data, "errorMessage", "");
  }

  // static getSample(from: string, to: string) {
  //   let matrix = new DistanceMatrixResponse();
  //   let rows: DistanceMatrixRow[] = [];
  //   matrix.originAddresses = this.parsePostcodeslist(from);
  //   matrix.destinationAddresses = this.parsePostcodeslist(to);
  //   matrix.rows = [];
  //   matrix.status = "true";
  //   matrix.originAddresses.forEach((postcode) => {
  //     matrix.rows.push(
  //       DistanceMatrixRow.getSample(matrix.destinationAddresses)
  //     );
  //   });
  //   return matrix;
  // }

  // static parsePostcodeslist(val: string) {
  //   let list: string[] = [];
  //   if (val) {
  //     list = val.split("|");
  //   }
  //   return list;
  // }
}
