import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { Base, ReportTiming } from '@/models';
import { toCamelCase } from '@/utilities';

export class TimingGroup extends Base {

  public id: string;
  public propertytypegroup: ReportTiming[];


  public constructor(data?: Partial<TimingGroup>) {
    super(data);
    data = toCamelCase(data);

    this.id = _get(data, 'id', '');
    this.propertytypegroup = _castArray(_get(data, 'propertytypegroup', [])).map((x: Partial<ReportTiming>) => new ReportTiming(x));
  }
}