import _get from 'lodash/get';

import { Base } from '@/models';

export class CognitoGroup extends Base {

    public GroupName: string;
    public Description: string;
    public Precedence: number;

    /**
     * Constructor initialises the variables from the class User
     * @param data - the data to construct a type with all properties of <CognotoGroup>
     */
    public constructor(data?: Partial<CognitoGroup>) {
        super(data);

        this.GroupName = _get(data, 'GroupName', '');
        this.Description = _get(data, 'Description', '');
        this.Precedence = _get(data, 'Precedence', 1);
    }

}
