<template>
  <div>
    <header>
      <div class="row">
        <div class="col-sm-6">
          <h1>Preferred locations</h1>
        </div>
      </div>
    </header>

    <LocationsPagination @filter="filterLocations" />

    <table class="table">
      <thead>
        <tr>
          <th width="10%" class="align-middle">Select</th>
          <th class="align-middle" @click="togglesort('name')">
            Name
            <i class="fas" :class="{
              'fa-angle-up': sortby === 'name' && ascdesc === 'asc',
              'fa-angle-down': sortby === 'name' && ascdesc === 'desc',
            }"></i>
          </th>
          <th class="align-middle" @click="togglesort('code')">
            Code
            <i class="fas" :class="{
              'fa-angle-up': sortby === 'code' && ascdesc === 'asc',
              'fa-angle-down': sortby === 'code' && ascdesc === 'desc',
            }"></i>
          </th>
          <th class="align-middle" @click="togglesort('rating')">
            Rating
            <i class="fas" :class="{
              'fa-angle-up': sortby === 'rating' && ascdesc === 'asc',
              'fa-angle-down': sortby === 'rating' && ascdesc === 'desc',
            }"></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(location, $locationindex) in filteredlocations" :key="$locationindex"
          :class="{ 'selected-location': selected(location) }">
          <td class="align-middle">
            <i v-if="selected(location)" class="fas fa-check text-success fa-2x" @click="unselect(location)"></i>
            <i v-else class="fas fa-check fa-2x" @click="select(location)"></i>
          </td>
          <td>{{ location.name }}</td>
          <td>{{ location.code }}</td>
          <td v-if="selected(location)">
            <i class="fas fa-star fa-2x" @click="rating(location, 1)" :class="{
              'selected-star': isRating(location, 1),
              'unselected-star': !isRating(location, 1),
            }"></i>
            <i class="fas fa-star fa-2x" @click="rating(location, 2)" :class="{
              'selected-star': isRating(location, 2),
              'unselected-star': !isRating(location, 2),
            }"></i>
            <i class="fas fa-star fa-2x" @click="rating(location, 3)" :class="{
              'selected-star': isRating(location, 3),
              'unselected-star': !isRating(location, 3),
            }"></i>
          </td>
          <td v-else>
            <i class="fas fa-star fa-2x disabled-start" @click="rating(location, 1)"></i>
            <i class="fas fa-star fa-2x disabled-start" @click="rating(location, 2)"></i>
            <i class="fas fa-star fa-2x disabled-start" @click="rating(location, 3)"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { Inspector, Location } from '@/models';
import LocationsPagination from "@/components/inspectors/LocationsPagination.vue";
import locations from '@/assets/json/locations.json';

const store = useStore();

// Getters
const inspector = computed(() => store.getters['inspectors/current']);

// Actions
const addLocation = async (location: Location) => {
  await store.dispatch('inspectors/addLocation', location);
};

const removeLocation = async (location: Location) => {
  await store.dispatch('inspectors/removeLocation', location);
};

const setInspectorDeep = async (payload: { path: string; data: any }) => {
  await store.dispatch('inspectors/setInspectorDeep', payload);
};
// Reactive variables
const sortby = ref('code');
const ascdesc = ref('asc');


// State
const _locationindex = ref<number>(-1);
const ratingOptions = ["1", "2", "3"];
const filteredlocations = ref<any[]>([]);

// Methods
const addNewLocation = () => {
  const newlocation = new Location();
  addLocation(newlocation);
};
const _delete = (l: Location) => {
  const confirm = window.confirm("Are you sure you want to delete this location?");
  if (confirm) {
    removeLocation(l)
      .then(() => console.log('Deleted')) // Replace with your desired action
      .catch((err: any) => console.error(err)); // Replace with your desired error handling
  }
};

const selected = (l: any): Location | undefined => {
  const index = inspector.value.locations.findIndex(
    (location) => location.code === l.code
  );
  return index >= 0 ? inspector.value.locations[index] : undefined;
};

const selectedindex = (l: any): number => {
  const index = inspector.value.locations.findIndex(
    (location) => location.code === l.code
  );
  return index;
};
const select = (l: any): Promise<any> => {
  const newlocation = new Location();
  newlocation.rating = 1;
  newlocation.code = l.code;
  newlocation.name = l.name;
  return addLocation(newlocation);
};

const unselect = (l: any) => {
  const location = selected(l);
  if (location) removeLocation(location);
};

const rating = (l: any, r: number) => {
  select(l).then(() => {
    const index = selectedindex(l);
    if (index >= 0) {
      if(inspector.value.locations[index].rating === 1) r = 0;
      setInspectorDeep({ path: `locations[${index}].rating`, data: r });
    }
  });
};

const isRating = (l: any, r: number): boolean => {
  const location = selected(l);
  return location && location.rating >= r;
};

// Methods
const togglesort = (by: string) => {
  if (sortby.value === by) {
    ascdesc.value = ascdesc.value === 'asc' ? 'desc' : 'asc';
  } else {
    sortby.value = by;
    ascdesc.value = 'asc';
  }
};

// Methods
const filterLocations = (starts_with: string) => {
  filteredlocations.value = locations
    .filter((l: any) => {
      return String(l.code).toLowerCase().startsWith(starts_with);
    })
    .sort((l1: any, l2: any) => l1.code.localeCompare(l2.code));
};
</script>

<style scoped lang="scss">
.noborder ::v-deep .multiselect__tags {
  border: none !important;
}

.selected-star {
  color: #ffd700;
}

.unselected-star {
  color: #fff5bd;
}

.disabled-start {
  color: #b9b9b9;
}

.selected-location {
  background-color: #f2f9ff;
}
</style>