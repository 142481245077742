<template>
  <div class="wrapper">
    <div
      class="btn-group btn-group-sm"
      role="group"
      aria-label="Responsibility"
    >
      <button
        v-for="option in options"
        :key="option.code"
        type="button"
        :class="responsibilitySelectedClassName(option)"
        :title="option.name"
        v-html="option.code"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, inject, defineProps } from 'vue';
import { useStore } from 'vuex';
import _get from "lodash/get";
import { ReportsState } from '@/store/reports/types';
import { Note } from '@/models';

const store = useStore();
const actProperty: any = inject('actProperty');

// Props and injections
const props = defineProps<{
  notePath: string;
}>();
const reportsState = computed(() => store.state.reports as ReportsState);

const setReportDeep = async (payload: { path: string; data: any }) => {
  await store.dispatch('reports/setReportDeep', payload);
};

const note = computed<Note>(() => {
  return _get(reportsState.value.current, props.notePath);
});

const responsibility = computed<string[]>({
  get: () => _get(note.value, 'responsibility', []),
  set: (data) => {
    setReportDeep({
      path: `${props.notePath}.responsibility`,
      data,
    });
  },
});

/**
 * Interface defines the syntax properties which the Responsibilities must follow
 */
 interface IResponsibilityOption {
  code: string;
  name: string;
}

const options = [
  { code: "TT", name: "Tenant" },
  { code: "STTA", name: "Subject To Tenancy Agreement" },
  { code: "CLN", name: "Cleaning" },
  { code: "FWT", name: "Fair Wear and Tear" },
  { code: "CLR", name: "Clarify" },
  { code: "INF", name: "Information Only" },
  { code: "LL", name: "Landlord" },
];

// Methods converted to regular arrow functions
const responsibilitySelectedClassName = (option: IResponsibilityOption) => {
  let className = `btn btn-${option.code.toLowerCase()} `;

  if (responsibility.value === undefined) {
    return className;
  }

  if (Array.isArray(responsibility.value)) {
    className += responsibility.value.includes(option.code.toUpperCase())
      ? "selected"
      : "";
  } else {
    actProperty.displayError(
    Error(
      `The Note Responsibility is not an array: ${JSON.stringify(
        responsibility.value
      )}`
    ));
  }

  return className;
};

</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/bootstrap/_variables.scss";

.wrapper {
  text-align: right;
}

.btn-group {
  padding-bottom: 0.2rem;
}

.btn-tt.selected {
  background-color: $red;
  color: $white;
}
.btn-stta.selected {
  background-color: $orange;
  color: $black;
}
.btn-cln.selected {
  background-color: $yellow;
  color: $black;
}
.btn-fwt.selected {
  background-color: $green;
  color: $white;
}
.btn-clr.selected {
  background-color: $info;
  color: $white;
}
.btn-inf.selected {
  background-color: $black;
  color: $white;
}
.btn-ll.selected {
  background-color: $grey;
  color: $white;
}
</style>
