import _get from "lodash/get";
import snakeCaseKeys from "snakecase-keys";

import { copyInto } from "@/utilities";
import {
  DictionaryRoom,
  DictionarySection,
  Compliancequestion,
  Maintenanceflag,
  Reportconfiguration,
  DictionaryCategory,
  DictionaryReportType,
} from "@/models";
import { Customer } from "../customer.model";
import { CustomerDictionaryInterface } from "../customerdictionaryinterface.model";

export class ReportTypeDictionary implements CustomerDictionaryInterface {
  public reporttype: string;

  public rooms: DictionaryRoom[];
  public sections: DictionarySection[];
  public categories: DictionaryCategory[];

  public compliance_list: Compliancequestion[];
  public maintenance_flags: Maintenanceflag[];
  public report_configuration: Reportconfiguration;

  public reporttypedictionaries: ReportTypeDictionary[];
  public reporttypes?: DictionaryReportType[];
  public company_name?: string;
  public branch_name?: string;
  public customer: Customer;

  /**
   * Create an object from a JSON representation
   * @param data
   * @return Dictionary
   */
  public static parse(data: object) {
    const obj = new ReportTypeDictionary();

    copyInto(obj, data, false); // false param to bypass camelcase-keys

    obj.reporttype = _get(data, "reporttype", "");
    obj.rooms = obj.rooms ? obj.rooms.map(DictionaryRoom.parse) : [];
    obj.sections = obj.sections
      ? obj.sections.map(DictionarySection.parse)
      : [];
    obj.categories = obj.categories
      ? obj.categories.map(DictionaryCategory.parse)
      : [];
    obj.compliance_list = obj.compliance_list
      ? obj.compliance_list.map(Compliancequestion.parse)
      : [];
    obj.maintenance_flags = obj.maintenance_flags
      ? obj.maintenance_flags.map(Maintenanceflag.parse)
      : [];
    obj.customer = new Customer(_get(data, "customer"));
    obj.report_configuration = _get(
      data,
      "report_configuration",
      {} as Reportconfiguration
    );

    return obj;
  }

  /**
   * Empty Item Colours And Conditions, this was required when migrating the reporttypedictionary
   * data from the old app XML file
   *
   * @param {ReportTypeDictionary} reporttypedictionary
   * @return {ReportTypeDictionary}
   */
  public static emptyItemColoursAndConditions(
    reporttypedictionary: ReportTypeDictionary
  ) {
    reporttypedictionary.sections.map((section) => {
      return section.types.map((type) => {
        return type.items.map((item) => {
          // Keep the "Misc" Item values
          if (
            section.name === "Misc" &&
            type.name === "Misc" &&
            item.name === "Misc"
          ) {
            return item;
          }
          // Reset all other Items
          item.conditions.additional = [];
          item.colours = [];
          return item;
        });
      });
    });
    return reporttypedictionary;
  }

  /**
   * Constructor
   */
  public constructor() {
    this.reporttype = "";
    this.rooms = [];
    this.sections = [];
    this.categories = [
      { order: 1, types: {} },
      { order: 2, types: {} },
      { order: 3, types: {} },
      { order: 4, types: {} },
    ];
    this.compliance_list = [];
    this.maintenance_flags = [];
    this.customer = new Customer();
    this.report_configuration = new Reportconfiguration();
  }

  /**
   * Create a JSON representation of this object
   * @param data
   * @return ReportTypeDictionary
   */
  public toJSON() {
    return {
      rooms: this.rooms,
      sections: snakeCaseKeys(this.sections),
      categories: this.categories,
      compliance_list: snakeCaseKeys(this.compliance_list),
      maintenance_flags: snakeCaseKeys(this.maintenance_flags),
      report_configuration: this.report_configuration,
      reporttype: this.reporttype,
      customer: this.customer,

      // These are fields from CustomerDictionary type
      // Here only for superclass compliance, must never be set
      reporttypes: [],
      company_name: "",
      branch_name: "",
      reporttypedictionaries: [],
    };
  }
}
