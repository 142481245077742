import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { Base, TimingGroup } from '@/models';
import { toCamelCase } from '@/utilities';

export class Schedule extends Base {

  public id: string;
  public reporttype: string;
  public caption: string;
  public timinggroups: TimingGroup[];

  public constructor(data?: Partial<Schedule>) {
    super(data);
    data = toCamelCase(data);

    this.id = _get(data, 'id', '');
    this.reporttype = _get(data, 'reporttype', '');
    this.caption = _get(data, 'caption', '');
    this.timinggroups = _castArray(_get(data, 'timinggroups', [])).map((x: Partial<TimingGroup>) => new TimingGroup(x));
  }
}